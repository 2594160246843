import { toast } from "react-toastify";

import request from "../apiRequest";
import * as ACTIONS from "../contexts/actions/account";
import * as TRADE_ACTIONS from "../contexts/actions/trade";

export const checkLoginStatus = async (dispatch) => {
    try {
        const response = await request("/account", "GET");

        delete response.data.type;

        dispatch(ACTIONS.authSuccess({ ...response.data, ip: response?.ip }));

        dispatch(TRADE_ACTIONS.updateLayoutConfiguration(response.data || {}));

        // If all roles are X, reject the login as this user is disabled.
        if (response.data.roles === "XXXXX") return rejectLogin(dispatch);

        // if there is roles, parse the roles. Otherwise, give the dispatch a empty string.
        dispatch(ACTIONS.parseRoles(response.data.roles || ""));
    } catch (err) {
        if (err.reason !== "User not logged in") {
            toast.error(err.reason);
        }

        dispatch(ACTIONS.clearState());
        dispatch(ACTIONS.authError(err));
    }
};

export const logoutUser = async (dispatch, ws) => {
    try {
        await request("/account/logout", "POST");

        ws.send(JSON.stringify({ type: "logout" }));
        ws.close();

        dispatch(ACTIONS.clearState());
    } catch (err) {
        toast.error(err.reason);
    }
};

export const loginUser = async (dispatch, user, verify = undefined) => {
    try {
        dispatch(ACTIONS.authRequest());

        const response =
            verify ?? (await request("/account/login", "POST", user));

        if (response.data.token) {
            delete response.data.type;

            dispatch(
                ACTIONS.authSuccess({ ...response.data, ip: response.ip })
            );

            dispatch(
                TRADE_ACTIONS.updateLayoutConfiguration(response.data || {})
            );

            // If all roles are X, reject the login as this user is disabled.
            if (response.data.roles === "XXXXX") return rejectLogin(dispatch);

            // if there is roles, parse the roles. Otherwise, give the dispatch a empty string.
            dispatch(ACTIONS.parseRoles(response.data.roles || ""));
        }
    } catch (err) {
        if (err.reason !== "Invalid 2fa token") {
            toast.error(err.reason);
            dispatch(ACTIONS.clearState());
        }

        dispatch(ACTIONS.authError(err, user));
    }
};

export const send2fa = async (dispatch, user) => {
    try {
        await request("/account/send2fa", "POST", user);

        toast.success("QR code has been sent to your email.");
    } catch (err) {
        toast.error(err.reason);
    }
};

export const rejectLogin = async (dispatch) => {
    try {
        await request("/account/logout", "POST");

        window.location.reload();
    } catch (err) {
        toast.error(err.reason);

        dispatch(ACTIONS.clearState());
        dispatch(ACTIONS.authError(err));
    }
};

export const forgotPassword = async (email) => {
    try {
        await request("/account/forgot-password", "POST", { email });

        toast.success("Reset email sent. Please check your inbox.");
    } catch (err) {
        toast.error(err.reason);
    }
};

export const resetPassword = async (email, token, newPassword) => {
    try {
        await request("/account/reset-password", "POST", {
            email,
            token,
            password: newPassword,
        });

        toast.success("Your password has been reset. Please login.");
    } catch (err) {
        toast.error(err.reason);
    }
};
