import { Fragment, memo, useEffect, useState } from "react";
import {
    Button,
    Col,
    Modal,
    OverlayTrigger,
    Popover,
    Row,
    Table,
    Tooltip,
} from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import { AiOutlineDelete } from "react-icons/ai";
import { BsPencilFill } from "react-icons/bs";
import { toast } from "react-toastify";

import {
    correctFieldType,
    deleteUserFile,
    getUserFile,
} from "../../helpers/other";
import { useStore } from "../../Store";
import { useSocket } from "../../Websocket";
import { renderField } from "../admin/onboarding/FormRenderer";

// eslint-disable-next-line react/display-name
const TableModal = memo(
    ({ show, handleSave, handleClose, existingTable, editingRow }) => {
        const [data, setData] = useState(editingRow);

        const validateData = () =>
            existingTable?.subfields?.every((subfield, i) => {
                return subfield.required ? data[i] : true;
            });

        const toastSave = () => toast.success("Item saved.");

        return (
            <Modal
                show={show}
                onHide={() => {
                    if (
                        Object.keys(editingRow)?.length > 0 &&
                        JSON.stringify(editingRow) !== JSON.stringify(data)
                    ) {
                        handleSave(data);
                        toastSave();
                    }

                    handleClose(data);
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add a {existingTable?.label}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="pb-4">
                    {existingTable?.subfields.map((subfield, i) => {
                        const nextField = existingTable.subfields?.[i + 1];
                        const splitFields =
                            nextField &&
                            (nextField?.subgroup || "B") !==
                                (subfield?.subgroup || "B");

                        return (
                            <Fragment key={i}>
                                <div className="">
                                    <Row noGutters className="mb-2 size-sm">
                                        {subfield.fieldName}
                                    </Row>
                                    {renderField(
                                        {
                                            ...subfield,
                                            value: data?.[i],
                                        },
                                        (value) => {
                                            const newData = { ...data };
                                            newData[i] = value;

                                            setData(newData);
                                        }
                                    )}

                                    {subfield?.fieldDescription &&
                                        subfield.fieldDescription.length > 0 &&
                                        subfield.fieldDescription !==
                                            "<p><br></p>" &&
                                        subfield.fieldType !== "Table" && (
                                            <Col className="pr-0 pl-1 mt-1 size-sm text-gray">
                                                {ReactHtmlParser(
                                                    subfield.fieldDescription
                                                )}
                                            </Col>
                                        )}
                                </div>
                                {splitFields && <hr />}
                            </Fragment>
                        );
                    })}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={() => {
                            if (validateData()) {
                                handleSave(data);
                                toastSave();
                                handleClose();
                            }
                        }}
                        variant="primary"
                        className="w-100"
                        size="sm"
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
);

// eslint-disable-next-line react/display-name
export const OnboardingTableInput = memo((props) => {
    const { field, onChange, disabled } = props;
    const { required, subfields, label, value, max } = field;

    const ws = useSocket();
    const { state } = useStore();
    const {
        account: {
            account: { data: loggedInUser },
        },
    } = state;

    const [showTableModal, setShowTableModal] = useState(false);
    const [editIndex, setEditIndex] = useState(null);

    const isMaxReached = value?.length >= (max || 1);

    const [filePreviews, setFilePreviews] = useState({});

    useEffect(() => {
        if (field?.value) {
            for (let i = 0; i < value.length; i++) {
                const currentValue = value[i];

                const newFilePreviews = subfields.map((subfield, j) => {
                    if (
                        correctFieldType(subfield.fieldType) ===
                            "File Upload" &&
                        currentValue[j]
                    ) {
                        return getUserFile(
                            ws,
                            currentValue[j],
                            loggedInUser.userid,
                            true
                        ).then((res) => ({ key: `${i}-${j}`, value: res }));
                    }
                    return Promise.resolve(null);
                });

                Promise.all(newFilePreviews).then((results) => {
                    const previews = results.reduce((acc, preview) => {
                        if (preview) {
                            acc[preview.key] =
                                typeof preview.value === "string"
                                    ? preview.value
                                    : URL.createObjectURL(preview.value);
                        }
                        return acc;
                    }, {});

                    setFilePreviews(previews);
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [field.value]);

    const removeItemFromTable = (rowToDelete, i) => {
        // Check if there is a subfield that is a file upload; if so, and there is a file, delete it

        subfields.forEach((subfield, j) => {
            if (
                correctFieldType(subfield.fieldType) === "File Upload" &&
                rowToDelete[j]
            ) {
                deleteUserFile(ws, rowToDelete[j], loggedInUser.userid);
                setFilePreviews({
                    ...filePreviews,
                    [`${i}-${j}`]: null,
                });
            }
        });

        // Remove item from table
        const newValue = value.filter((_, index) => index !== i);
        onChange(newValue);
    };

    return (
        <Row noGutters className="position-relative w-100">
            {showTableModal && (
                <TableModal
                    show={showTableModal}
                    handleSave={(newValue) => {
                        const newData = value.map((item, index) =>
                            index === editIndex ? newValue : item
                        );

                        if (editIndex === null) {
                            newData.push(newValue);
                        }

                        onChange(newData);
                    }}
                    editingRow={value?.[editIndex] || {}}
                    existingTable={{
                        label,
                        subfields,
                    }}
                    handleClose={(data) => {
                        // Check for image and delete it
                        // If there is a editIndex, dont delete it.
                        if (data && editIndex === null) {
                            Object.keys(data || {}).forEach((key) => {
                                if (
                                    correctFieldType(
                                        subfields[key]?.fieldType
                                    ) === "File Upload" &&
                                    data?.[key]
                                ) {
                                    deleteUserFile(
                                        ws,
                                        data?.[key],
                                        loggedInUser.userid
                                    );
                                }
                            });
                        }

                        if (editIndex) setEditIndex(null);
                        setShowTableModal(false);
                    }}
                />
            )}

            <div className="onboarding-table-field position-relative w-100">
                <Table
                    striped
                    bordered
                    hover
                    size="sm"
                    className="bg-white px-2"
                >
                    <thead>
                        <tr>
                            {subfields.map((subfield, i) => (
                                <th key={i}>{subfield.fieldName}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className="">
                        {value?.length > 0 &&
                            value?.map((row, i) => (
                                <tr key={i}>
                                    {subfields.map((subfield, j) => (
                                        <td key={j}>
                                            {correctFieldType(
                                                subfield.fieldType
                                            ) === "File Upload" && row?.[j] ? (
                                                <OverlayTrigger
                                                    overlay={
                                                        <Popover>
                                                            <Popover.Title as="h3">
                                                                {
                                                                    subfield.fieldName
                                                                }
                                                            </Popover.Title>
                                                            <Popover.Content>
                                                                <embed
                                                                    type={
                                                                        field
                                                                            .value
                                                                            .type
                                                                    }
                                                                    src={`${
                                                                        filePreviews[
                                                                            `${i}-${j}`
                                                                        ]
                                                                    }#toolbar=0&navpanes=0&scrollbar=0`}
                                                                    key={`${
                                                                        filePreviews[
                                                                            `${i}-${j}`
                                                                        ]
                                                                    }#toolbar=0&navpanes=0&scrollbar=0`}
                                                                    height="250px"
                                                                    width="100%"
                                                                    style={{
                                                                        objectFit:
                                                                            "contain",
                                                                        minWidth:
                                                                            "200px",
                                                                    }}
                                                                />
                                                            </Popover.Content>
                                                        </Popover>
                                                    }
                                                >
                                                    <span>
                                                        <u>{row?.[j]}</u>
                                                    </span>
                                                </OverlayTrigger>
                                            ) : (
                                                row?.[j]
                                            )}
                                        </td>
                                    ))}

                                    <td className="text-center">
                                        <Button
                                            onClick={() => {
                                                // Edit value
                                                setEditIndex(i);
                                                setShowTableModal(true);
                                            }}
                                            disabled={disabled}
                                            className="mr-1"
                                            size="sm"
                                            variant="primary"
                                        >
                                            <BsPencilFill />
                                        </Button>
                                        <Button
                                            onClick={() =>
                                                removeItemFromTable(row, i)
                                            }
                                            disabled={disabled}
                                            className=""
                                            size="sm"
                                            variant="outline-danger"
                                        >
                                            <AiOutlineDelete />
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </Table>
                <div className="h-100 d-flex justify-content-center flex-column">
                    <Row noGutters className="d-flex justify-content-center">
                        {field?.fieldDescription &&
                            field.fieldDescription.length > 0 &&
                            field.fieldDescription !== "<p><br></p>" &&
                            ReactHtmlParser(field.fieldDescription)}
                    </Row>

                    <Row
                        noGutters
                        className="position-relative d-flex justify-content-center pb-4 "
                    >
                        <OverlayTrigger
                            overlay={
                                isMaxReached ? (
                                    <Tooltip>
                                        You have reached the maximum number of
                                        allowed {label}s ({max || 1}).
                                    </Tooltip>
                                ) : (
                                    <Tooltip>Click to add a {label}.</Tooltip>
                                )
                            }
                            placement="right"
                        >
                            <span className="d-inline-block">
                                <Button
                                    onClick={() => {
                                        setEditIndex(null);
                                        setShowTableModal(true);
                                    }}
                                    disabled={disabled || isMaxReached}
                                    className="px-3 py-2"
                                    size="sm"
                                    variant="outline-primary"
                                >
                                    <b>+ Add a {label}</b>
                                </Button>
                            </span>
                        </OverlayTrigger>
                    </Row>
                </div>
                <br />
            </div>
            {required && (
                <span
                    className="required-icon"
                    style={{ top: "-1.5rem", right: "1rem" }}
                >
                    *
                </span>
            )}
        </Row>
    );
});
