/* eslint-disable react/display-name */

import { memo } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import Select from "react-select";

import { addUserSetTrader, emailIssuer } from "../../../../config";
import { SELECT_SMALL_SIZE_STYLE } from "../../../../helpers/other";

export const AddUserModal = memo(
    ({
        loggedInUser,
        user,
        show = false,
        type = "add",
        handleCloseModal,
        handleSubmit,
        firms,
    }) => {
        const isEdit = type === "edit";
        const [userData, setUserData] = useState(
            isEdit
                ? {
                      ...user,
                      pass: undefined,
                      risk_type: user?.risk_type || "A",
                  }
                : {
                      userid: "",
                      attr: {
                          first_name: "",
                          last_name: "",
                          email: "",
                          email_confirmed: "N",
                      },
                      // If user is a firm admin, we assume that they want to create a sub user.
                      firm:
                          loggedInUser.user_type === "F"
                              ? loggedInUser?.firm
                              : "",
                      risk_type: "A",
                      complianceid: "",
                      roles: "OOOOO",
                      use2fa: "N",
                      active: "N",
                      wash_check: false,
                  }
        );

        const [roles, setRoles] = useState({
            risk_admin: "O",
            onboarding_admin: "O",
            custody_admin: "O",
            trading: "O",
            asset_builder: "O",
        });

        const handleOnChange = ({ target: { name, value } }) =>
            setUserData({
                ...userData,
                [name]: value,
            });

        const handleOnChangeInputAttr = ({ target: { name, value } }) =>
            setUserData({
                ...userData,
                attr: {
                    ...userData?.attr,
                    [name]: value,
                },
            });

        const handleOnChangeCheckAttr = ({ target: { name, checked } }) =>
            setUserData({
                ...userData,
                attr: {
                    ...userData?.attr,
                    [name]: checked,
                },
            });

        const handleOnChangeRole = ({ target: { name, id } }) =>
            setRoles({ ...roles, [name]: id });

        // const checkRoleDefault = (i, value) =>
        //     user?.roles && user?.roles[i] === value;

        useEffect(() => {
            if (user?.roles) {
                let existingRoles = {};

                Object.keys(roles).forEach((key, i) => {
                    existingRoles = {
                        ...existingRoles,
                        [key]: user.roles[i],
                    };
                });

                setRoles(existingRoles);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        const disabled = isEdit
            ? userData?.userid && userData?.firm
            : userData?.attr?.email &&
              userData?.attr?.first_name &&
              userData?.attr?.last_name &&
              userData?.firm &&
              userData?.pass;

        return (
            <Modal show={show} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {type === "add" ? "Create" : "Edit"} User
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label className="size-sm">
                                First Name
                            </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="First Name"
                                value={userData?.attr?.first_name}
                                name="first_name"
                                onChange={handleOnChangeInputAttr}
                                size="sm"
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label className="size-sm">
                                Last Name
                            </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Last Name"
                                value={userData?.attr?.last_name}
                                name="last_name"
                                onChange={handleOnChangeInputAttr}
                                size="sm"
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label className="size-sm">Email</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Email"
                                value={userData?.attr?.email}
                                name="email"
                                onChange={handleOnChangeInputAttr}
                                required
                                size="sm"
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label className="size-sm">User ID</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="User ID"
                                value={
                                    userData?.userid || userData?.attr?.email
                                }
                                name="userid"
                                onChange={handleOnChange}
                                disabled
                                required
                                size="sm"
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label className="size-sm">
                                Password
                            </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Password"
                                value={userData?.pass || ""}
                                name="pass"
                                onChange={handleOnChange}
                                required
                                size="sm"
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label className="size-sm">Firm</Form.Label>
                            <Select
                                defaultValue={{
                                    value: userData?.firm,
                                    label: userData?.firm,
                                }}
                                onChange={(e) => {
                                    setUserData({
                                        ...userData,
                                        firm: e.value,
                                    });
                                }}
                                options={firms.map((firm) => ({
                                    value: firm.firm,
                                    label: firm.firm,
                                }))}
                                isDisabled={
                                    loggedInUser.user_type !== "S" ||
                                    type === "edit"
                                }
                                styles={SELECT_SMALL_SIZE_STYLE}
                            />
                        </Form.Group>
                        {addUserSetTrader && (
                            <Form.Group>
                                <Form.Label className="size-sm">
                                    Trader
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Trader"
                                    value={userData?.complianceid}
                                    name="complianceid"
                                    onChange={handleOnChange}
                                    disabled={type !== "add"}
                                    required
                                    size="sm"
                                />
                            </Form.Group>
                        )}
                        <Row className="size-sm" noGutters>
                            Roles
                        </Row>
                        <Row noGutters className="mx-2">
                            <Col className="size-sm">Risk Admin</Col>
                            <Col>
                                <Form.Check
                                    type="radio"
                                    name="risk_admin"
                                    id="O"
                                    label="Disabled"
                                    onChange={handleOnChangeRole}
                                    defaultChecked={
                                        user?.roles
                                            ? user?.roles[0] === "O"
                                            : true
                                    }
                                    inline
                                    disabled={isEdit}
                                    className="size-sm"
                                />
                                <Form.Check
                                    type="radio"
                                    name="risk_admin"
                                    id="S"
                                    label="Enabled"
                                    onChange={handleOnChangeRole}
                                    defaultChecked={
                                        user?.roles && user?.roles[0] === "S"
                                    }
                                    inline
                                    disabled={isEdit}
                                    className="size-sm"
                                />
                            </Col>
                        </Row>
                        <Row noGutters className="mx-2">
                            <Col className="size-sm">Onboarding Admin</Col>
                            <Col>
                                <Form.Check
                                    type="radio"
                                    name="onboarding_admin"
                                    id="O"
                                    label="Disabled"
                                    onChange={handleOnChangeRole}
                                    defaultChecked={
                                        user?.roles
                                            ? user?.roles[1] === "O"
                                            : true
                                    }
                                    disabled={isEdit}
                                    inline
                                    className="size-sm"
                                />
                                <Form.Check
                                    type="radio"
                                    name="onboarding_admin"
                                    id="S"
                                    label="Enabled"
                                    onChange={handleOnChangeRole}
                                    defaultChecked={
                                        user?.roles && user?.roles[1] === "S"
                                    }
                                    disabled={isEdit}
                                    inline
                                    className="size-sm"
                                />
                            </Col>
                        </Row>
                        <Row noGutters className="mx-2">
                            <Col className="size-sm">Custody Admin</Col>
                            <Col>
                                <Form.Check
                                    type="radio"
                                    name="custody_admin"
                                    id="O"
                                    label="Disabled"
                                    onChange={handleOnChangeRole}
                                    defaultChecked={
                                        user?.roles
                                            ? user?.roles[2] === "O"
                                            : true
                                    }
                                    disabled={isEdit}
                                    inline
                                    className="size-sm"
                                />
                                <Form.Check
                                    type="radio"
                                    name="custody_admin"
                                    id="S"
                                    label="Enabled"
                                    onChange={handleOnChangeRole}
                                    defaultChecked={
                                        user?.roles && user?.roles[2] === "S"
                                    }
                                    disabled={isEdit}
                                    inline
                                    className="size-sm"
                                />
                            </Col>
                        </Row>
                        <Row noGutters className="mx-2">
                            <Col className="size-sm">Trading Admin</Col>
                            <Col>
                                <Form.Check
                                    type="radio"
                                    name="trading"
                                    id="O"
                                    label="Disabled"
                                    onChange={handleOnChangeRole}
                                    defaultChecked={
                                        user?.roles
                                            ? user?.roles[3] === "O"
                                            : true
                                    }
                                    disabled={isEdit}
                                    inline
                                    className="size-sm"
                                />
                                <Form.Check
                                    type="radio"
                                    name="trading"
                                    id="S"
                                    label="Enabled"
                                    onChange={handleOnChangeRole}
                                    defaultChecked={
                                        user?.roles && user?.roles[3] === "S"
                                    }
                                    disabled={isEdit}
                                    inline
                                    className="size-sm"
                                />
                            </Col>
                        </Row>
                        <Row noGutters className="mx-2">
                            <Col className="size-sm">Asset Builder Admin</Col>
                            <Col>
                                <Form.Check
                                    type="radio"
                                    name="asset_builder"
                                    id="O"
                                    label="Disabled"
                                    onChange={handleOnChangeRole}
                                    defaultChecked={
                                        user?.roles
                                            ? user?.roles[4] === "O"
                                            : true
                                    }
                                    disabled={isEdit}
                                    inline
                                    className="size-sm"
                                />
                                <Form.Check
                                    type="radio"
                                    name="asset_builder"
                                    id="S"
                                    label="Enabled"
                                    onChange={handleOnChangeRole}
                                    defaultChecked={
                                        user?.roles && user?.roles[4] === "S"
                                    }
                                    disabled={isEdit}
                                    inline
                                    className="size-sm"
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row noGutters>
                            <Col>
                                <Form.Label className="size-sm">
                                    Disable Position Requirements
                                </Form.Label>
                            </Col>
                            <Col>
                                <Form.Check
                                    type="checkbox"
                                    name="risk_type"
                                    onChange={({ target: { checked } }) =>
                                        setUserData({
                                            ...userData,
                                            risk_type: checked ? "R" : "A",
                                        })
                                    }
                                    defaultChecked={userData?.risk_type === "R"}
                                    required
                                />
                            </Col>
                        </Row>
                        <Row noGutters>
                            <Col>
                                <Form.Label className="size-sm">
                                    Two-Factor Authentication
                                </Form.Label>
                            </Col>
                            <Col>
                                <Form.Check
                                    type="checkbox"
                                    name="use2fa"
                                    onChange={({ target: { checked } }) =>
                                        setUserData({
                                            ...userData,
                                            use2fa: checked ? "Y" : "N",
                                        })
                                    }
                                    defaultChecked={userData?.use2fa === "Y"}
                                    disabled={
                                        !isEdit || userData?.use2fa !== "Y"
                                    }
                                    required
                                />
                            </Col>
                        </Row>
                        <Row noGutters>
                            <Col>
                                <Form.Label className="size-sm">
                                    Active
                                </Form.Label>
                            </Col>
                            <Col>
                                <Form.Check
                                    type="checkbox"
                                    name="active"
                                    onChange={({ target: { checked } }) =>
                                        setUserData({
                                            ...userData,
                                            active: checked ? "Y" : "N",
                                        })
                                    }
                                    defaultChecked={userData?.active === "Y"}
                                    required
                                />
                            </Col>
                        </Row>
                        <Row noGutters>
                            <Col>
                                <Form.Label className="size-sm">
                                    Wash Check
                                </Form.Label>
                            </Col>
                            <Col>
                                <Form.Check
                                    type="checkbox"
                                    name="wash_check"
                                    onChange={({ target: { checked } }) =>
                                        setUserData({
                                            ...userData,
                                            wash_check: checked ? true : false,
                                        })
                                    }
                                    defaultChecked={
                                        userData?.wash_check === true
                                    }
                                    required
                                />
                            </Col>
                        </Row>
                        <Row noGutters>
                            <Col>
                                <Form.Label className="size-sm">
                                    Email Confirmed
                                </Form.Label>
                            </Col>
                            <Col>
                                <Form.Check
                                    type="checkbox"
                                    name="active"
                                    onChange={({ target: { checked } }) =>
                                        setUserData({
                                            ...userData,
                                            attr: {
                                                ...userData?.attr,
                                                email_confirmed: checked
                                                    ? "Y"
                                                    : "N",
                                            },
                                        })
                                    }
                                    defaultChecked={
                                        userData?.attr?.email_confirmed === "Y"
                                    }
                                    required
                                />
                            </Col>
                        </Row>
                        <Row noGutters>
                            <Col>
                                <Form.Label className="size-sm">
                                    View All Orders/Trades
                                </Form.Label>
                            </Col>
                            <Col>
                                <Form.Check
                                    type="checkbox"
                                    name="active"
                                    onChange={({ target: { checked } }) =>
                                        setUserData({
                                            ...userData,
                                            view_all: checked,
                                        })
                                    }
                                    defaultChecked={userData?.view_all}
                                    required
                                />
                            </Col>
                        </Row>
                        {emailIssuer && (
                            <>
                                <Row noGutters>
                                    <Col>
                                        <Form.Label className="size-sm">
                                            Firm symbol was sold
                                        </Form.Label>
                                    </Col>
                                    <Col>
                                        <Form.Check
                                            type="checkbox"
                                            name="emailFirmSymbolTraded"
                                            onChange={handleOnChangeCheckAttr}
                                            defaultChecked={
                                                userData?.attr
                                                    ?.emailFirmSymbolTraded
                                            }
                                            required
                                        />
                                    </Col>
                                </Row>
                                <Row noGutters>
                                    <Col>
                                        <Form.Label className="size-sm">
                                            An order was made for my symbol
                                        </Form.Label>
                                    </Col>
                                    <Col>
                                        <Form.Check
                                            type="checkbox"
                                            name="emailSymbolOrder"
                                            onChange={handleOnChangeCheckAttr}
                                            defaultChecked={
                                                userData?.attr?.emailSymbolOrder
                                            }
                                            required
                                        />
                                    </Col>
                                </Row>
                                <Row noGutters>
                                    <Col>
                                        <Form.Label className="size-sm">
                                            Receive End Of Day Prices
                                        </Form.Label>
                                    </Col>
                                    <Col>
                                        <Form.Check
                                            type="checkbox"
                                            name="emailEndOfDayPrices"
                                            onChange={handleOnChangeCheckAttr}
                                            defaultChecked={
                                                userData?.attr
                                                    ?.emailEndOfDayPrices
                                            }
                                            required
                                        />
                                    </Col>
                                </Row>
                                <Row noGutters>
                                    <Col>
                                        <Form.Label className="size-sm">
                                            Receive Announcements
                                        </Form.Label>
                                    </Col>
                                    <Col>
                                        <Form.Check
                                            type="checkbox"
                                            name="emailAnnouncements"
                                            onChange={handleOnChangeCheckAttr}
                                            defaultChecked={
                                                userData?.attr
                                                    ?.emailAnnouncements
                                            }
                                            required
                                        />
                                    </Col>
                                </Row>
                            </>
                        )}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="dark"
                        onClick={() => {
                            const roleString = Object.keys(roles)
                                .map((role) => roles[role])
                                .join("");

                            handleSubmit({ ...userData, roles: roleString });
                        }}
                        disabled={!disabled}
                        size="sm"
                    >
                        {type === "edit" ? "Save" : "Create"}
                    </Button>
                    <Button
                        variant="light"
                        onClick={handleCloseModal}
                        size="sm"
                    >
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
);
