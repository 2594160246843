import { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { v4 as uuid } from "uuid";

import { CreateTemplateForm } from "../../../components/admin/asset-builder/CreateTemplateForm";
import { GoBackModal } from "../../../components/admin/asset-builder/GoBackModal";
import { FullScreenLoading } from "../../../components/Loading";
import {
    getAssetTemplatesProfiles,
    updateAssetTemplateProfile,
} from "../../../helpers/asset-builder";
import { useStore } from "../../../Store";
import { useSocket } from "../../../Websocket";

const CreateTemplatePage = () => {
    const ws = useSocket();
    const { state, dispatch } = useStore();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    const [copiedTemplate, setCopiedTemplate] = useState();

    const { templates, profiles } = state.asset_builder;

    const createTemplate = (newTemplate) => {
        const identifier = uuid();

        return updateAssetTemplateProfile(ws, dispatch, {
            templates: templates.concat([{ ...newTemplate, id: identifier }]),
            profiles: profiles,
        }).then(() => {
            toast.success("Template successfully created.");
            navigate("/admin/asset-templates");
        });
    };

    useEffect(() => {
        // If copy param exists, then fetch the existing
        // template from state and use it as the base.
        if (templates.length > 0) {
            for (const entry of searchParams.entries()) {
                const [param, value] = entry;

                if (param === "copy") {
                    const copyTemplate = JSON.parse(
                        JSON.stringify(
                            templates.find((temp) => temp.id === value)
                        )
                    );

                    if (Object.keys(copyTemplate).length > 0) {
                        delete copyTemplate.name;
                        delete copyTemplate.id;

                        toast.success("Template successfully copied.");

                        setCopiedTemplate(copyTemplate);
                    }
                }
            }
        }
    }, [searchParams, templates]);

    useEffect(() => {
        if (templates.length === 0) {
            getAssetTemplatesProfiles(ws, dispatch).finally(() =>
                setLoading(false)
            );
        } else {
            setLoading(false);
        }

        return () => setLoading(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [showGoBackModal, setShowGoBackModal] = useState(false);
    const handleHideGoBackModal = () => setShowGoBackModal(false);
    const handleShowGoBackModal = () => setShowGoBackModal(true);

    return (
        <div className="m-4">
            <Row noGutters className="forms-header">
                <AiOutlineArrowLeft
                    className="asset-back-button"
                    onClick={handleShowGoBackModal}
                />
                <h4>Create Template</h4>
            </Row>
            <GoBackModal
                show={showGoBackModal}
                handleClose={handleHideGoBackModal}
                handleConfirm={() => {
                    handleHideGoBackModal();
                    navigate("/admin/asset-templates");
                }}
            />
            {loading ? (
                <FullScreenLoading />
            ) : (
                <CreateTemplateForm
                    existingTemplate={copiedTemplate}
                    submitData={createTemplate}
                />
            )}
        </div>
    );
};

export default CreateTemplatePage;
