import * as CONSTANTS from "../constants/risk";

export const initialRiskState = {
    currentRisks: [],
    riskSettings: [],
    cases: [],
    alertsMentioned: [],
};

export const riskReducer = (state, action) => {
    switch (action.type) {
        case CONSTANTS.ADD_CURRENT_RISK: {
            const isExist = state.currentRisks.some(
                (risk) => risk.trader == action.data.trader
            );
            if (isExist) {
                // If risk exists, overwrite.
                return {
                    ...state,
                    currentRisks: state.currentRisks.map((risk) =>
                        risk.trader === action.data.trader ? action.data : risk
                    ),
                };
            } else {
                // If risk doesnt exist, concat to existing risks
                return {
                    ...state,
                    currentRisks: state.currentRisks.concat(action.data),
                };
            }
        }
        case CONSTANTS.ADD_RISK_SETTING: {
            const isExist = state.riskSettings.some(
                (risk) => risk.trader == action.data.trader
            );
            if (isExist) {
                // If risk exists, overwrite.
                return {
                    ...state,
                    riskSettings: state.riskSettings.map((risk) =>
                        risk.trader === action.data.trader ? action.data : risk
                    ),
                };
            } else {
                // If risk doesnt exist, concat to existing risks
                return {
                    ...state,
                    riskSettings: state.riskSettings.concat(action.data),
                };
            }
        }
        case CONSTANTS.GET_RISK_CASES:
            return {
                ...state,
                cases: action.cases.map((riskCase) => ({
                    ...riskCase,
                    details: JSON.parse(riskCase.details),
                })),
            };
        case CONSTANTS.CLEAR_STATE:
            return initialRiskState;
        default:
            return state;
    }
};
