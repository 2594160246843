/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { useEffect } from "react";
import { useState } from "react";
import {
    Button,
    Card,
    Col,
    Container,
    FormControl,
    Image,
    OverlayTrigger,
    Row,
    Tooltip,
} from "react-bootstrap";
import {
    AiFillEye,
    AiFillEyeInvisible,
    AiOutlineQuestionCircle,
} from "react-icons/ai";
import { Link, NavLink } from "react-router-dom";

import { alt_full_logo, enableRegisterPhoneNumber, full_logo } from "../config";
import { getRoleList, registerUser } from "../helpers/register";
import {
    passwordErrorStr,
    validateComplexPassword,
    validateEmail,
    // validatePassword,
    validatePhoneNumber,
} from "../helpers/validate";
import { useStore } from "../Store";
const Register = () => {
    const { state, dispatch } = useStore();

    const roles = state.account.registerRoles || [];

    const [form, setForm] = useState({
        first: "",
        last: "",
        email: "",
        password: "",
        confirm_password: "",
        role: "",
        phone_number: "",
    });

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const togglePasswordVisibility = (type = "password") => {
        const passwordBox = document.getElementsByName(type)[0];

        passwordBox.type = passwordBox.type === "text" ? "password" : "text";

        type === "password"
            ? setShowPassword(!showPassword)
            : setShowConfirmPassword(!showConfirmPassword);
    };

    const [emailError, setEmailError] = useState(false);
    const checkEmailFormat = ({ target: { value } }) =>
        setEmailError(value.length > 0 && validateEmail(value));

    const [passwordError, setPasswordError] = useState(false);
    const checkPasswordFormat = ({ target: { value } }) =>
        setPasswordError(value.length > 0 && validateComplexPassword(value));

    const [confirmPasswordError, setConfirmPasswordError] = useState(false);
    const checkConfirmPassword = ({ target: { value } }) =>
        setConfirmPasswordError(value.length > 0 && form.password !== value);

    const [phoneNumberError, setPhoneNumberError] = useState(false);
    const checkPhoneNumber = ({ target: { value } }) =>
        setPhoneNumberError(value.length > 0 && validatePhoneNumber(value));

    const disabled =
        form.first &&
        form.last &&
        form.email &&
        form.password &&
        form.role &&
        form.password === form.confirm_password &&
        !emailError &&
        !passwordError &&
        !confirmPasswordError &&
        (enableRegisterPhoneNumber ? form.phone_number : true) &&
        (enableRegisterPhoneNumber ? !phoneNumberError : true)
            ? false
            : true;

    const handleOnChange = ({ target: { name, value } }) =>
        setForm({ ...form, [name]: value });

    const handleRegister = async () => {
        await registerUser(dispatch, { ...form, confirm_password: undefined });
    };

    useEffect(() => {
        if (roles.length === 0) {
            getRoleList(dispatch);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Container fluid className="register-container content-pane">
            <Row className="login-row g-0">
                <Col sm={8} md={6} lg={4} xl={4} className="login-col">
                    <Card className="login-box">
                        <Row className="g-0">
                            <Col className="login-content-col">
                                <Row className="login-header g-0">
                                    <Link to={"/"}>
                                        <Image
                                            fluid
                                            src={alt_full_logo}
                                            onError={(img) => {
                                                img.target.onerror = null;
                                                img.target.src = full_logo;
                                            }}
                                            className="login-logo"
                                        />
                                    </Link>
                                </Row>

                                <Row className="login-box-padding login-formcontrol-row g-0">
                                    <Col className="login-box-col">
                                        <Row className="login-welcome  g-0">
                                            <Col>
                                                <h1>Welcome</h1>
                                                <p>
                                                    Register your account to
                                                    continue
                                                </p>
                                            </Col>
                                        </Row>
                                        <Row className="login-formcontrol-container g-0">
                                            <div
                                                className={`login-formcontrol-box`}
                                            >
                                                <div className="login-formcontrol-text">
                                                    First Name
                                                </div>
                                                <FormControl
                                                    size="sm"
                                                    name="first"
                                                    value={form.first}
                                                    onChange={handleOnChange}
                                                    className="login-formcontrol"
                                                />
                                            </div>
                                        </Row>
                                        <Row className="login-formcontrol-container g-0">
                                            <div
                                                className={`login-formcontrol-box`}
                                            >
                                                <div className="login-formcontrol-text">
                                                    Last Name
                                                </div>
                                                <FormControl
                                                    size="sm"
                                                    name="last"
                                                    value={form.last}
                                                    onChange={handleOnChange}
                                                    className="login-formcontrol"
                                                />
                                            </div>
                                        </Row>
                                        <Row className="login-formcontrol-container g-0">
                                            <div
                                                className={`login-formcontrol-box ${
                                                    emailError &&
                                                    "formcontrol-error"
                                                }`}
                                            >
                                                <div className="login-formcontrol-text">
                                                    Email
                                                </div>
                                                <FormControl
                                                    size="sm"
                                                    name="email"
                                                    value={form.email}
                                                    onBlur={checkEmailFormat}
                                                    onChange={handleOnChange}
                                                    className="login-formcontrol"
                                                />
                                                {emailError && (
                                                    <div className="system-form-control-error-text">
                                                        Invalid email format
                                                    </div>
                                                )}
                                            </div>
                                        </Row>

                                        <Row className="login-formcontrol-container g-0">
                                            <div
                                                className={`login-formcontrol-box ${
                                                    passwordError &&
                                                    "formcontrol-error"
                                                }`}
                                            >
                                                <div className="login-formcontrol-text">
                                                    Password
                                                </div>
                                                <FormControl
                                                    size="sm"
                                                    name="password"
                                                    type="password"
                                                    value={form.password}
                                                    onChange={handleOnChange}
                                                    onBlur={checkPasswordFormat}
                                                    className="login-formcontrol"
                                                />
                                                {passwordError && (
                                                    <div className="system-form-control-error-text">
                                                        {passwordErrorStr(
                                                            form.password
                                                        )}
                                                    </div>
                                                )}
                                                <div
                                                    className="password-icon"
                                                    onClick={() =>
                                                        togglePasswordVisibility(
                                                            "password"
                                                        )
                                                    }
                                                    role="button"
                                                >
                                                    {showPassword ? (
                                                        <AiFillEyeInvisible />
                                                    ) : (
                                                        <AiFillEye />
                                                    )}
                                                </div>
                                                <OverlayTrigger
                                                    key="role-description"
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip>
                                                            Your password must
                                                            be a minimum of
                                                            eight characters
                                                            long and contain at
                                                            least one letter and
                                                            one number.
                                                        </Tooltip>
                                                    }
                                                >
                                                    <AiOutlineQuestionCircle className="individual-form-icon password-format-icon" />
                                                </OverlayTrigger>
                                            </div>
                                        </Row>
                                        <Row className="login-formcontrol-container g-0">
                                            <div
                                                className={`login-formcontrol-box ${
                                                    confirmPasswordError &&
                                                    "formcontrol-error"
                                                }`}
                                            >
                                                <div className="login-formcontrol-text">
                                                    Confirm Password
                                                </div>
                                                <FormControl
                                                    size="sm"
                                                    name="confirm_password"
                                                    type="password"
                                                    value={
                                                        form.confirm_password
                                                    }
                                                    onChange={handleOnChange}
                                                    onBlur={
                                                        checkConfirmPassword
                                                    }
                                                    className="login-formcontrol"
                                                />
                                                {confirmPasswordError && (
                                                    <div className="system-form-control-error-text">
                                                        Passwords must match!
                                                    </div>
                                                )}
                                                <div
                                                    className="password-icon"
                                                    onClick={() =>
                                                        togglePasswordVisibility(
                                                            "confirm_password"
                                                        )
                                                    }
                                                    role="button"
                                                >
                                                    {showConfirmPassword ? (
                                                        <AiFillEyeInvisible />
                                                    ) : (
                                                        <AiFillEye />
                                                    )}
                                                </div>
                                            </div>
                                        </Row>
                                        {enableRegisterPhoneNumber && (
                                            <Row className="login-formcontrol-container g-0">
                                                <div
                                                    className={`login-formcontrol-box ${
                                                        phoneNumberError &&
                                                        "formcontrol-error"
                                                    }`}
                                                >
                                                    <div className="login-formcontrol-text">
                                                        Phone Number
                                                    </div>
                                                    <FormControl
                                                        size="sm"
                                                        name="phone_number"
                                                        value={
                                                            form.phone_number
                                                        }
                                                        onChange={
                                                            handleOnChange
                                                        }
                                                        onBlur={
                                                            checkPhoneNumber
                                                        }
                                                        className="login-formcontrol"
                                                    />
                                                    {phoneNumberError && (
                                                        <div className="system-form-control-error-text">
                                                            Invalid Phone Number
                                                        </div>
                                                    )}
                                                </div>
                                            </Row>
                                        )}
                                        <Row className="login-formcontrol-container g-0">
                                            <div
                                                className={`login-formcontrol-box`}
                                            >
                                                <div className="login-formcontrol-text">
                                                    Select Role
                                                </div>

                                                <select
                                                    name="role"
                                                    className="custom-select custom-select-sm login-formcontrol"
                                                    onChange={handleOnChange}
                                                >
                                                    <option value={""}>
                                                        Role...
                                                    </option>
                                                    {roles.map((role, i) => (
                                                        <option
                                                            value={JSON.stringify(
                                                                {
                                                                    name: role.name,
                                                                    id: role.id,
                                                                }
                                                            )}
                                                            key={i}
                                                        >
                                                            {role.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </Row>
                                        <Row className="login-button-row g-0">
                                            <Button
                                                size="sm"
                                                disabled={disabled}
                                                onClick={handleRegister}
                                                className="teal-button"
                                            >
                                                REGISTER
                                            </Button>
                                        </Row>

                                        <div className="register-navlink">
                                            Already have an account?
                                            <NavLink to="/login">Login</NavLink>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default Register;
