import { useEffect, useState } from "react";
import { FormControl, Row } from "react-bootstrap";
import { AiOutlineClockCircle, AiOutlineEdit } from "react-icons/ai";

import { AuditChangesModal } from "../../../components/admin/risk";
import { RiskEditModal } from "../../../components/admin/risk/RiskEditModal";
import { ConfigureDisplayWrapper } from "../../../components/ConfigureDisplayTableWrapper";
import { TableRenderer } from "../../../components/portal/TableRenderer";
import { isSimpleRiskSetting } from "../../../config";
import { editRisk } from "../../../helpers/risk";
import { useStore } from "../../../Store";
import { useSocket } from "../../../Websocket";

const RiskSetting = () => {
    const { state } = useStore();
    const ws = useSocket();

    const {
        risk: { riskSettings },
    } = state;

    const [searchValue, setSearchValue] = useState("");
    const [filteredRisks, setFilteredRisks] = useState([]);
    const [editSetting, setEditSetting] = useState({});

    const [showModal, setShowModal] = useState(false);
    const handleCloseModal = () => setShowModal(false);

    const getFormatter = (value, field, trader, firm) => {
        return (
            <Row
                noGutters
                className="d-flex justify-content-between align-items-center"
            >
                <span>{value}</span>
                <AiOutlineEdit
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        setShowModal(true);
                        setEditSetting({
                            trader,
                            field,
                            value,
                            firm,
                        });
                    }}
                />
            </Row>
        );
    };

    useEffect(() => {
        setFilteredRisks(riskSettings);
    }, [riskSettings]);

    const onSearch = (e) => {
        setSearchValue(e.target.value);
        const showRisks = riskSettings.filter((risk) =>
            risk.trader.toLowerCase().includes(e.target.value.toLowerCase())
        );
        setFilteredRisks(showRisks);
    };

    const onEdit = (value) => {
        handleCloseModal();
        editRisk(ws, editSetting, value);
    };

    const [selectedTrader, setSelectedTrader] = useState();

    const [auditChangesModalView, setAuditChangesModalView] = useState(false);
    const handleHideAuditChangesModal = () => {
        setAuditChangesModalView(false);
        setSelectedTrader(undefined);
    };
    const handleShowAuditChangesModal = (trdr) => {
        setAuditChangesModalView(true);
        setSelectedTrader(trdr);
    };

    const defaultRiskOptions = isSimpleRiskSetting ? [
        "firm",
        "trader",
        "maxordershares",
        "maxdollaramount",
        "totalallowqty",
        "totalallowamount",
        "DupOrd",
        "SpeedChk",
        "active_account",
    ] : [
        "firm",
        "trader",
        "maxdollaramount",
        "totalallowamount",
        "totalallowqty",
        "DupOrd",
        "SpeedChk",
        "active_account",
        "percentagelimit",
    ]

    const blacklistedAttr = isSimpleRiskSetting ? [
        "optiontotalallowamount",
        "optiontotalallowqty",
        "maxnumpos",
        "equitymaxordershares",
        "type",
        "equitymaxdollaramount",
        "shortsale_monitor",
        "blockoc",
        "maxpossize",
        "washcheck",
        "percentagelimit",
        "maxopenorders",
        "optionmaxordershares",
        "cumnetotvalue",
        "haltcheck",
        "optionmarginlimit",
        "isocheck",
        "symbolfilter",
        "optionmaxdollaramount"
    ] : [];

    return (
        <div className="m-4">
            <Row noGutters className="forms-header">
                <h4>Risk Control Settings</h4>

                <Row noGutters className="pb-2">
                    <FormControl
                        placeholder="Look up a trader..."
                        value={searchValue}
                        onChange={onSearch}
                    />
                </Row>
            </Row>

            {auditChangesModalView && (
                <AuditChangesModal
                    show={auditChangesModalView}
                    handleClose={handleHideAuditChangesModal}
                    {...selectedTrader}
                />
            )}
            <ConfigureDisplayWrapper
                keyType={"risk_preferences"}
                optionKey={"setting_list"}
                defaultOptions={defaultRiskOptions}
                data={filteredRisks}
                blacklistedAttr={blacklistedAttr}
                render={(preferredColumns) => (
                    <TableRenderer
                        keyField="trader"
                        wrapperClassses="size-sm"
                        data={filteredRisks}
                        columns={[
                            ...preferredColumns.map((column) =>
                                ["firm", "trader"].includes(column.dataField)
                                    ? column
                                    : {
                                        ...column,
                                        formatExtraData: column.dataField,
                                        formatter: (rowContent, row, i, formatExtraDataAlias) =>
                                            getFormatter(
                                                row[formatExtraDataAlias],
                                                formatExtraDataAlias,
                                                row.trader,
                                                row.firm
                                            ),
                                    }
                            ),
                            {
                                dataField: "action",
                                text: "",
                                formatter: (cell, { firm, trader }) => (
                                    <AiOutlineClockCircle
                                        className="cursor-pointer"
                                        onClick={() =>
                                            handleShowAuditChangesModal({
                                                frm: firm,
                                                trdr: trader,
                                            })
                                        }
                                    />
                                ),
                            },
                        ]}
                        noDataIndication="No Risk Settings Available"
                        pagination
                        striped
                    />
                )}
            />

            <RiskEditModal
                show={showModal}
                handleClose={handleCloseModal}
                handleConfirm={onEdit}
                editSetting={editSetting}
            />
        </div>
    );
};

export default RiskSetting;
