/* eslint-disable react/display-name */
import { memo, useEffect, useState } from "react";
import { Button } from "react-bootstrap";

import { storeOptions } from "../helpers/display-options";
import { ConfigureSequence } from "./ConfigureSequenceModal";

export const ConfigureSequenceButton = memo(
    ({
        ws,
        dispatch,
        user,
        keys,
        preexisting,
        type,
        optionKey,
        enableNewTextFormatting = false,
    }) => {
        const [options, setOptions] = useState([]);
        const [showModal, setShowModal] = useState(false);

        useEffect(() => {
            setOptions(
                preexisting.reduce(
                    (prev, curr) => (
                        (prev[curr] = keys.find((existing) => existing === curr)
                            ? true
                            : false),
                        prev
                    ),
                    {}
                )
            );
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [preexisting]);

        const handleOpenModal = () => setShowModal(true);
        const handleCloseModal = () => setShowModal(false);

        const saveColumns = (columns) => {
            storeOptions(
                ws,
                dispatch,
                user,
                type,
                optionKey,
                columns,
                "columns"
            );
            return handleCloseModal();
        };

        return (
            <>
                <ConfigureSequence
                    show={showModal}
                    handleCloseModal={handleCloseModal}
                    options={options}
                    saveColumns={saveColumns}
                    enableNewTextFormatting={enableNewTextFormatting}
                />
                <Button onClick={handleOpenModal} size="sm">
                    Configure Sequence
                </Button>
            </>
        );
    }
);
