/* eslint-disable react/display-name */

import moment from "moment";
import { memo } from "react";
import { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";

import { clientDateFormat } from "../../../../config";


export const AddEditHolidayModal = memo(
    ({ show = false, handleCloseModal, handleSubmit, type = "add", holiday }) => {
        const [holidayData, setHolidayData] = useState(type === "add" ? {
            holidaydate: "",
            holidaytype: "",
            opentime: "",
            closetime: "",
        } : { ...holiday, isEdit: undefined }
        );

        const handleOnChange = ({ target: { name, value } }) =>
            setHolidayData({ ...holidayData, [name]: value });

        const disabled = holidayData.holidaytype === "H" ? !(holidayData.holidaytype && holidayData.holidaydate && (holidayData.opentime || holidayData.closetime)) : !holidayData.holidaydate || !holidayData.holidaytype;

        return (
            <Modal show={show} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {type === "add" ? "Create" : "Edit"} Holiday
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Row noGutters >
                                <Col>
                                    <Form.Label className="size-sm">Date</Form.Label>
                                </Col>
                                <Col>
                                    <DatePicker
                                        selected={holidayData.holidaydate ? new Date(holidayData.holidaydate) : ""}
                                        onChange={(date) => {
                                            setHolidayData({ ...holidayData, holidaydate: moment(date).format("MM/DD/YY") });
                                        }}
                                        disabled={type === "edit"}
                                        dateFormat={clientDateFormat}
                                    />
                                </Col>
                            </Row>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label className="size-sm">Type</Form.Label>
                            <Form.Control
                                as="select"
                                name="holidaytype"
                                className="custom-select custom-select-sm w-50 ml-2"
                                onChange={handleOnChange}
                                value={holidayData.holidaytype}
                            >
                                <option value="" disabled>Select your holiday type</option>
                                <option value="C">Closed</option>
                                <option value="H">Alternate Schedule</option>
                            </Form.Control>
                        </Form.Group>
                        {holidayData.holidaytype === "H" && <>
                            <Form.Group>
                                <Form.Label className="size-sm">Alternate Open Time</Form.Label>
                                <Form.Control
                                    type="time"
                                    name="opentime"
                                    value={holidayData?.opentime || ""}
                                    step="1"
                                    onChange={handleOnChange}
                                    size="sm"
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label className="size-sm">Alternate Close Time</Form.Label>
                                <Form.Control
                                    type="time"
                                    name="closetime"
                                    value={holidayData?.closetime || ""}
                                    step="1"
                                    onChange={handleOnChange}
                                    size="sm"
                                />
                            </Form.Group>
                        </>}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="dark"
                        onClick={() => handleSubmit(holidayData, type)}
                        disabled={disabled}
                        size="sm"
                    >
                        {type === "add" ? "Create" : "Edit"}
                    </Button>
                    <Button
                        variant="light"
                        onClick={handleCloseModal}
                        size="sm"
                    >
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
);
