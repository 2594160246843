import { Button, Modal } from "react-bootstrap";

export const DeleteCompanyDocModal = ({ show, onClose, doc, handleDelete }) => {
    return (
        <Modal show={show} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Delete Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Are you sure you want to delete {doc.title}?
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="danger"
                    onClick={() => handleDelete(doc)}
                    size="sm"
                >
                    Delete
                </Button>
                <Button variant="light" onClick={onClose} size="sm">
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
