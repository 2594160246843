/* eslint-disable react/display-name */
import { memo, useEffect, useState } from "react";
import { Button, Container, Form, Row } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { toast } from "react-toastify";

export const EnterOrderForm = memo(
    ({ users, symbols, submitOrder, onChangeUser }) => {
        const [user, setUser] = useState([]);
        const [symbol, setSymbol] = useState([]);
        const [quantity, setQuantity] = useState("");
        const [price, setPrice] = useState("");

        const handleSubmit = (type) => {
            if (user.length === 0 || symbol.length === 0)
                return toast.error("Missing user/symbol selection!");
            if (isNaN(parseFloat(quantity)) || parseFloat(quantity) === 0)
                return toast.error("Quantity cannot not be 0.00! ");
            if (isNaN(parseFloat(price)) || parseFloat(price) === 0)
                return toast.error("Price cannot not be 0.00! ");

            submitOrder(type, user[0], symbol[0], quantity, price);
        };

        useEffect(() => {
            if (user.length === 1) onChangeUser(user[0]);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [user]);

        return (
            <Container fluid>
                <Form>
                    <Form.Group>
                        <Form.Label className="size-sm">User</Form.Label>
                        <Typeahead
                            id="user"
                            labelKey="userid"
                            onChange={setUser}
                            options={users}
                            placeholder="Please select.."
                            selected={user}
                            size="sm"
                            required
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label className="size-sm">Symbol</Form.Label>
                        <Typeahead
                            id="symbol"
                            labelKey="security"
                            onChange={setSymbol}
                            options={symbols}
                            placeholder="Please select.."
                            selected={symbol}
                            size="sm"
                            required
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label className="size-sm">Quantity</Form.Label>
                        <Form.Control
                            size="sm"
                            name="quantity"
                            value={quantity}
                            onChange={({ target: { value } }) =>
                                setQuantity(value)
                            }
                            required
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label className="size-sm">Price</Form.Label>
                        <Form.Control
                            size="sm"
                            name="price"
                            value={price}
                            onChange={({ target: { value } }) =>
                                setPrice(value)
                            }
                            required
                        />
                    </Form.Group>
                    <Form.Group>
                        <Row className="justify-content-between mx-2">
                            <Button
                                // variant="dark"
                                type="submit"
                                onClick={(ev) => {
                                    ev.preventDefault();
                                    handleSubmit("buy");
                                }}
                                size="sm"
                            >
                                BUY
                            </Button>
                            <Button
                                // variant="dark"
                                type="submit"
                                onClick={(ev) => {
                                    ev.preventDefault();
                                    handleSubmit("sell");
                                }}
                                size="sm"
                            >
                                SELL
                            </Button>
                        </Row>
                    </Form.Group>
                </Form>
            </Container>
        );
    }
);
