import { useState } from "react";
import { useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useSearchParams } from "react-router-dom";

import { SubmissionsTable } from "../../../components/admin/onboarding/SubmissionsTable";
import { getUsersFromWebsocket } from "../../../helpers/firm";
import { getRegistrationSubmissions } from "../../../helpers/onboarding";
import { useStore } from "../../../Store";
import { useSocket } from "../../../Websocket";

const ReviewSubmissions = () => {
    const ws = useSocket();
    const { state, dispatch } = useStore();
    const [searchParams] = useSearchParams();

    const {
        firm: { existing_users: users },
        onboarding: { submissions },
    } = state;

    const [loading, setLoading] = useState(true);

    const [filteredSubmissions, setFilteredSubmissions] = useState(submissions);
    const [filteredUsers, setFilteredUsers] = useState(users);

    const [filterEmail, setFilterEmail] = useState("");
    const [filterFormType, setFilterFormType] = useState("");
    const [filterStatus, setFilterStatus] = useState("");

    const onFilter = () => {
        const filterByEmail =
            filterEmail.length > 0
                ? submissions.filter((frm) => frm.userid === filterEmail)
                : submissions;

        const filterByFormType =
            filterFormType.length > 0
                ? filterByEmail.filter(
                      (frm) => frm.formTypeId === filterFormType
                  )
                : filterByEmail;

        const filterByStatus =
            filterStatus.length > 0
                ? filterByFormType.filter((frm) => frm.status === filterStatus)
                : filterByFormType.filter((frm) => frm.status !== "PENDING");

        setFilteredSubmissions(filterByStatus);
    };

    useEffect(() => {
        if (users.length === 0) {
            getUsersFromWebsocket(ws, dispatch);
        }

        if (submissions.length === 0) {
            getRegistrationSubmissions(ws, dispatch, undefined).then(() =>
                setLoading(false)
            );
        } else {
            setLoading(false);
        }

        return () => setLoading(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (users.length > 0) {
            if (searchParams.toString().includes("email")) {
                const email = searchParams.get("email");
                setFilterEmail(decodeURIComponent(email));
            }

            if (searchParams.toString().includes("status")) {
                const status = searchParams.get("status");
                setFilterStatus(status);
            }

            setFilteredUsers(
                users
                    .filter((usr) => usr?.attr?.status)
                    .map((usr) => ({
                        ...usr,
                        attr: {
                            ...usr.attr,
                            role: usr?.attr?.role
                                ? JSON.parse(usr.attr.role)
                                : "",
                        },
                    }))
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [users]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(onFilter, [submissions]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(onFilter, [filterEmail, filterFormType, filterStatus]);

    return (
        <div className="m-4">
            <Row noGutters className="forms-header">
                <Col>
                    <h4>Review Submissions</h4>
                </Col>
            </Row>
            <Row className="size-sm mb-2">
                <Col xs={4}>
                    <Form.Label>User:</Form.Label>
                    <Typeahead
                        id="user"
                        labelKey="userid"
                        onChange={(val) =>
                            setFilterEmail(val[0]?.attr?.email || "")
                        }
                        options={filteredUsers}
                        placeholder="All"
                        size="sm"
                        selected={filterEmail.length > 0 ? [filterEmail] : []}
                        disabled={users.length === 0 || loading}
                    />
                </Col>
                <Col xs={4}>
                    <Form.Label>Form Type:</Form.Label>
                    <Form.Control
                        as="select"
                        size="sm"
                        disabled={submissions.length === 0 || loading}
                        onChange={({ target: { value } }) =>
                            setFilterFormType(value)
                        }
                    >
                        <option value="">Choose a form type...</option>
                        {Array.from(
                            // eslint-disable-next-line no-undef
                            new Set(
                                submissions.map((submission) =>
                                    JSON.stringify({
                                        name:
                                            submission?.data?.form?.name ||
                                            "Unnamed form",
                                        id: submission?.data?.form?.id,
                                    })
                                )
                            )
                        ).map((subm, i) => {
                            const parsedSubm = JSON.parse(subm);
                            return (
                                <option value={parsedSubm.id} key={i}>
                                    {parsedSubm.name}
                                </option>
                            );
                        })}
                    </Form.Control>
                </Col>
                <Col xs={4}>
                    <Form.Label>Status:</Form.Label>
                    <Form.Control
                        as="select"
                        size="sm"
                        onChange={({ target: { value } }) =>
                            setFilterStatus(value)
                        }
                        disabled={loading}
                        value={filterStatus}
                    >
                        <option value="">Choose a status...</option>
                        <option value="APPROVED">Approved</option>
                        <option value="REJECTED">Rejected</option>
                        <option value="SUBMITTED">Submitted</option>
                        <option value="PENDING">Pending</option>
                        <option value="MANUAL">Manual</option>
                    </Form.Control>
                </Col>
            </Row>
            <br />

            <SubmissionsTable
                submissions={filteredSubmissions}
                loading={loading}
            />
        </div>
    );
};

export default ReviewSubmissions;
