import { cloneDeep } from "lodash";
import { Row } from "react-bootstrap";

export const DropdownInput = (props) => {
    const { field, onChange, classes, disabled } = props;
    // eslint-disable-next-line no-unused-vars
    const { required, value } = field;

    const otherProps = cloneDeep(props);

    delete otherProps.onChange;
    delete otherProps.field;
    delete otherProps.options;

    return (
        <Row className={classes} noGutters style={{ position: "relative" }}>
            <select
                {...otherProps}
                className="custom-select custom-select-sm"
                onChange={({ target: { value } }) => onChange(value)}
                value={value}
                disabled={disabled}
            >
                <option
                    value=""
                    // selected={
                    //     (required && !value) ||
                    //     (required && value.length === 0)
                    // }
                >
                    Select an option...
                </option>
                {field.options.map((opt, i) => (
                    <option key={i} value={opt}>
                        {opt}
                    </option>
                ))}
            </select>
            {required && <span className="required-icon">*</span>}
        </Row>
    );
};
