import { useEffect, useState } from "react";
import { Button, Col, FormControl, FormLabel, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";

import { AssetTemplateTable } from "../../../components/admin/asset-builder/AssetTemplateTable";
import {
    getAssetTemplatesProfiles,
    updateAssetTemplateProfile,
} from "../../../helpers/asset-builder";
import { useStore } from "../../../Store";
import { useSocket } from "../../../Websocket";

const AssetTemplates = () => {
    const { state, dispatch } = useStore();
    const ws = useSocket();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const { templates, profiles } = state.asset_builder;

    const statusOptions = [
        { value: null, label: "All" },
        { value: true, label: "True" },
        { value: false, label: "False" },
    ];

    const [searchValue, setSearchValue] = useState("");
    const [filterStatus, setFilterStatus] = useState(statusOptions[0]);
    const [filteredTemplates, setFilteredTemplates] = useState(templates);

    const onSearch = (name, status) => {
        const showTemplates = templates.filter((template) =>
            status === null
                ? template.name.toLowerCase().includes(name.toLowerCase())
                : template.name.toLowerCase().includes(name.toLowerCase()) &&
                  template.isPublished === status
        );
        setFilteredTemplates(showTemplates);
    };

    const deleteAssetTemplate = ({ id }) =>
        updateAssetTemplateProfile(ws, dispatch, {
            templates: templates.filter((temp) => temp.id !== id),
            profiles: profiles,
        }).then(() => toast.success("Template successfully deleted."));

    useEffect(() => {
        setFilteredTemplates(templates);
    }, [templates]);

    useEffect(() => {
        if (templates.length === 0) {
            getAssetTemplatesProfiles(ws, dispatch).finally(() =>
                setLoading(false)
            );
        } else {
            setLoading(false);
        }

        return () => setLoading(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="m-4">
            <Row noGutters className="forms-header">
                <h4>Asset Templates</h4>
            </Row>

            <Row noGutters className="pb-2">
                <Col xs={12} md={4} className="pr-2 pb-2">
                    <FormLabel>Search:</FormLabel>
                    <FormControl
                        placeholder="Search..."
                        value={searchValue}
                        onChange={(e) => {
                            setSearchValue(e.target.value);
                            onSearch(e.target.value, filterStatus.value);
                        }}
                    />
                </Col>
                <Col xs={12} md={4} className="pr-2 pb-2">
                    <FormLabel>Published Status:</FormLabel>
                    <Select
                        value={filterStatus}
                        onChange={(e) => {
                            setFilterStatus({ value: e.value, label: e.label });
                            onSearch(searchValue, e.value);
                        }}
                        options={statusOptions}
                    />
                </Col>
                <Col
                    xs={12}
                    md={4}
                    className="pb-2 d-flex justify-content-end align-items-end"
                >
                    <Button
                        variant="primary"
                        onClick={() =>
                            navigate("/admin/asset-templates/create")
                        }
                        size="sm"
                    >
                        Create Template
                    </Button>
                </Col>
            </Row>
            <hr />
            <AssetTemplateTable
                deleteAssetTemplate={deleteAssetTemplate}
                templates={filteredTemplates}
                loading={loading}
            />
        </div>
    );
};

export default AssetTemplates;
