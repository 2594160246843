import { Col, FormControl, Row } from "react-bootstrap";

export const RenderDropdown = ({
    options = [],
    displayName = "",
    val = "",
    setDropdown,
}) => (
    <Row className="d-flex order-ticket-flex-item pb-2 dropdown-field">
        <Col xs={4} className="">
            {displayName}
        </Col>
        <Col xs={8}>
            <FormControl
                value={val}
                size="sm"
                placeholder={displayName}
                onChange={(e) => setDropdown(e.target.value)}
                as="select"
            >
                <option value="">Select {displayName}</option>
                {options.map((opt, i) => (
                    <option key={i} value={opt.value}>
                        {opt.displayName || opt.value}
                    </option>
                ))}
            </FormControl>
        </Col>
    </Row>
);
