/* eslint-disable no-unused-vars */
import { createChart, CrosshairMode } from "lightweight-charts";
import { useEffect, useMemo, useRef, useState } from "react";
import { Form } from "react-bootstrap";

import { convertStylingVar, getStylingHex } from "../../../helpers/other";
import { mergelsHistoryAnditradeLsHistory } from "../../../helpers/trade";
import { useStore } from "../../../Store";

export const LastSaleChart = ({ layoutKey, title = "Chart" }) => {
    const { state } = useStore();
    const {
        layoutConfigurationOptions,
        lsHistory,
        subscribedSecurity,
        itradeLsHistory,
        currentSymbol,
    } = state.trade;

    const tradeChartContainerRef = useRef();
    const tradeChartRef = useRef();

    const fontColor = getStylingHex(
        layoutConfigurationOptions.styling.fontColor
    );

    const [chartTimeFrame, setChartTimeFrame] = useState("30Days");
    const [series, setSeries] = useState();
    const [price, setPrice] = useState({
        lastPrice: lsHistory?.[0]?.execprice,
    });
    const [filteredLsHistory, setFilteredLsHistory] = useState(
        lsHistory.filter((hist) => hist.security === currentSymbol?.security)
    );
    const [timedData, setTimedData] = useState([]);
    const [fullLsHistory, setFullLsHistory] = useState([]);

    const makeTimeUnique = (arr) => {
        const seenTimes = new Set();

        for (let obj of arr) {
            while (seenTimes.has(parseInt(obj.time))) {
                obj.time = parseInt(obj.time) + 1 + "";
            }
            seenTimes.add(parseInt(obj.time));
        }

        return arr;
    };

    useEffect(() => {
        setPrice({
            lastPrice: lsHistory.filter(
                (hist) => hist.security === currentSymbol?.security
            )?.[0]?.execprice,
        });

        setFilteredLsHistory(
            lsHistory.filter(
                (hist) => hist.security === currentSymbol?.security
            )
        );
    }, [currentSymbol, lsHistory]);

    useEffect(() => {
        setFullLsHistory(
            makeTimeUnique(
                mergelsHistoryAnditradeLsHistory(
                    filteredLsHistory,
                    itradeLsHistory[subscribedSecurity] || []
                )
            )
        );
    }, [filteredLsHistory, itradeLsHistory, subscribedSecurity]);

    const chartRenderer = useMemo(
        () => {
            return (
                <div
                    ref={tradeChartContainerRef}
                    className="chart-container"
                ></div>
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [fullLsHistory]
    );

    const securityRenderer = useMemo(
        () => (
            <div className="size-sm">
                <p>
                    <b>{subscribedSecurity}</b> Price: {price.lastPrice}
                </p>
                {/* <p>Volume: {ohlc.value}</p> */}
            </div>
        ),
        [subscribedSecurity, price]
    );

    useEffect(() => {
        // Render chart on mount.
        tradeChartRef.current = createChart(tradeChartContainerRef.current, {
            autoSize: true,
            layout: {
                backgroundColor: "rgba(0, 0, 0, 0.1)",
                textColor: fontColor,
            },
            grid: {
                vertLines: {
                    color: "rgba(255, 255, 255, 0.25)",
                },
                horzLines: {
                    color: "rgba(255, 255, 255, 0.25)",
                },
            },
            crosshair: {
                mode: CrosshairMode.Normal,
            },
            priceScale: {
                borderColor: fontColor,
            },
            timeScale: {
                borderColor: fontColor,
                timeVisible: true,
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const beginingOfToday = new Date().setHours(0, 0, 0, 0);
        const today = new Date();
        const sevenDaysAgo = new Date(
            new Date().setDate(today.getDate() - 7)
        ).setHours(0, 0, 0, 0);
        switch (chartTimeFrame) {
            case "Today":
                return setTimedData(
                    fullLsHistory.filter(
                        (ele) => parseInt(ele.time) >= beginingOfToday
                    )
                );
            case "7Days":
                return setTimedData(
                    fullLsHistory.filter(
                        (ele) => parseInt(ele.time) >= sevenDaysAgo
                    )
                );
            case "30Days":
                return setTimedData(fullLsHistory);
            default:
                break;
        }
    }, [fullLsHistory, chartTimeFrame]);

    useEffect(() => {
        if (series) {
            tradeChartRef.current.removeSeries(series);
            setSeries(null);
        }

        if (timedData.length > 0) {
            const transformedTimedData = timedData.map((element) => {
                return {
                    time: parseInt(
                        (parseFloat(element.time) / 1000).toFixed(0)
                    ),
                    value: parseFloat(element.execprice),
                };
            });
            const data = transformedTimedData.filter(
                (element, index) =>
                    index ===
                    transformedTimedData.findIndex(
                        (other) => element.time === other.time
                    )
            );

            const currentChartType = tradeChartRef.current.addLineSeries();
            currentChartType.setData(data);

            setSeries(currentChartType);
            tradeChartRef.current.timeScale().fitContent();
            tradeChartRef.current.subscribeCrosshairMove((param) => {
                const price = param.seriesPrices.get(currentChartType);
                if (price) {
                    setPrice({
                        lastPrice: price,
                    });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timedData]);

    const resizeObserver = useRef();
    useEffect(() => {
        resizeObserver.current = new ResizeObserver((entries) => {
            const { width, height } = entries[0].contentRect;
            tradeChartRef.current.applyOptions({ width, height });
            setTimeout(() => {
                tradeChartRef.current.timeScale().fitContent();
            }, 0);
        });

        resizeObserver.current.observe(tradeChartContainerRef.current);

        return () => resizeObserver.current.disconnect();
    }, []);

    return (
        <div
            style={{
                padding: layoutConfigurationOptions?.padding,
                overflow: "hidden",
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                background: convertStylingVar(
                    layoutConfigurationOptions.styling.backgroundColor
                ),
                color: fontColor,
            }}
        >
            <div className="d-flex justify-content-between">
                <h6
                    style={{
                        color: convertStylingVar(
                            layoutConfigurationOptions.styling.headerFontColor
                        ),
                    }}
                >
                    <b>{title}</b>
                </h6>
                {fullLsHistory.length > 0 && (
                    <Form.Control
                        size="sm"
                        as="select"
                        onChange={({ target: { value } }) =>
                            setChartTimeFrame(value)
                        }
                        value={chartTimeFrame}
                        className="series-select w-25"
                    >
                        <option value="Today">Today</option>
                        <option value="7Days">7 Days</option>
                        <option value="30Days">30 Days</option>
                    </Form.Control>
                )}
            </div>
            {fullLsHistory.length > 0 ? (
                securityRenderer
            ) : (
                <p>No History Found for {chartTimeFrame}</p>
            )}
            {chartRenderer}
        </div>
    );
};
