/* eslint-disable react/display-name */
import lodash from "lodash";
import { memo, useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";

import { marketCapCurrencyEnabled } from "../../../config";
import { countDecimals, isMultipleDot } from "../../../helpers/other";
import { validateNoFloatsAllSigns } from "../../../helpers/validate";
import { SectionRenderer } from "./SectionRenderer";

export const CreateProfileForm = memo(
    ({ existingProfile, template, symbols = [], submitData, type = "add" }) => {
        const [profile, setProfile] = useState({
            name: "",
            templateId: {
                id: template?.id || "",
                name: template?.name || "",
            },
            isPublished: false,
            sections: template?.sections || [],
            symbol: "",
            description: "",
            numberOfShares: 0,
            lastFinancialYearEPS: 0,
            last12MonthsGrossDividend: 0,
            // location: "",
            // industry: "",
            // contractTerms: "",
            // serialNumber: "",
            ...existingProfile,
        });

        useEffect(
            () =>
                template &&
                setProfile({
                    ...profile,
                    sections: lodash.merge(
                        profile?.sections,
                        template?.sections
                    ),
                }),
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [template]
        );

        const [image, setImage] = useState(existingProfile?.image || null);

        return (
            <>
                <Row>
                    <Col md={3}>
                        <Row noGutters className="justify-content-between">
                            <Col>
                                <Form.Label className="size-sm">
                                    Image
                                </Form.Label>
                                {!image ? (
                                    <Form.File
                                        custom
                                        label="Upload an image..."
                                        className="size-sm"
                                        accept="image/jpeg, image/png, image/jpg, image/gif"
                                        onChange={({ target: { files } }) =>
                                            setImage(files[0])
                                        }
                                        disabled={profile.isPublished}
                                    />
                                ) : (
                                    <Row
                                        noGutters
                                        className="justify-content-center"
                                    >
                                        <img
                                            src={
                                                typeof image === "string"
                                                    ? image
                                                    : URL.createObjectURL(image)
                                            }
                                            alt=""
                                            height={"125px"}
                                        />
                                    </Row>
                                )}
                            </Col>
                            {image && (
                                <Col className="text-right">
                                    <Button
                                        variant="danger"
                                        size="sm"
                                        className="px-3"
                                        onClick={() => setImage(null)}
                                        disabled={profile.isPublished}
                                    >
                                        X
                                    </Button>
                                </Col>
                            )}
                        </Row>
                    </Col>
                    <Col>
                        <Row noGutters>
                            <Col md={6}>
                                <Form.Group className="mr-2">
                                    <Form.Label className="size-sm">
                                        Name
                                    </Form.Label>
                                    <Form.Control
                                        name="name"
                                        onChange={({ target: { value } }) =>
                                            setProfile({
                                                ...profile,
                                                name: value,
                                            })
                                        }
                                        value={profile.name}
                                        size="sm"
                                        disabled={profile.isPublished}
                                        placeholder="Name..."
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label className="size-sm">
                                        Symbol
                                    </Form.Label>
                                    <select
                                        className="custom-select custom-select-sm"
                                        onChange={({ target: { value } }) =>
                                            setProfile({
                                                ...profile,
                                                symbol: value,
                                            })
                                        }
                                        value={profile.symbol}
                                        disabled={profile.isPublished}
                                    >
                                        <option value="">
                                            Select an symbol...
                                        </option>
                                        {symbols
                                            .filter(
                                                (symb) =>
                                                    !symb?.attr
                                                        ?.profile_exists ||
                                                    symb.security ===
                                                        profile.symbol
                                            )
                                            .sort(
                                                (a, b) =>
                                                    a.security > b.security
                                            )
                                            .map((opt, i) => (
                                                <option
                                                    key={i}
                                                    value={opt.security}
                                                >
                                                    {opt.security}
                                                </option>
                                            ))}
                                    </select>
                                </Form.Group>
                            </Col>
                            <Col md={12} className="pb-2">
                                <Form.Label className="size-sm">
                                    Description
                                </Form.Label>
                                <Form.Control
                                    name="description"
                                    onChange={({ target: { value } }) =>
                                        setProfile({
                                            ...profile,
                                            description: value,
                                        })
                                    }
                                    value={profile.description}
                                    as="textarea"
                                    rows={4}
                                    size="sm"
                                    disabled={profile.isPublished}
                                    placeholder="Description..."
                                />
                            </Col>
                            {marketCapCurrencyEnabled && (
                                <>
                                    <Col md={4}>
                                        <Form.Group className="mr-2">
                                            <Form.Label className="size-sm">
                                                Number Of Shares
                                            </Form.Label>
                                            <Form.Control
                                                name="numberOfShares"
                                                onChange={({
                                                    target: { value },
                                                }) =>
                                                    setProfile({
                                                        ...profile,
                                                        numberOfShares: value,
                                                    })
                                                }
                                                type="number"
                                                onKeyDown={
                                                    validateNoFloatsAllSigns
                                                }
                                                value={profile.numberOfShares}
                                                size="sm"
                                                disabled={profile.isPublished}
                                                placeholder="Number Of Shares..."
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group className="mr-2">
                                            <Form.Label className="size-sm">
                                                Last Financial Year EPS
                                            </Form.Label>
                                            <Form.Control
                                                name="lastFinancialYearEPS"
                                                onChange={({
                                                    target: { value },
                                                }) =>
                                                    !isMultipleDot(value) &&
                                                    countDecimals(value) <= 4 &&
                                                    setProfile({
                                                        ...profile,
                                                        lastFinancialYearEPS:
                                                            value,
                                                    })
                                                }
                                                type="number"
                                                value={
                                                    profile.lastFinancialYearEPS
                                                }
                                                size="sm"
                                                disabled={profile.isPublished}
                                                placeholder="Last Financial Year EPS..."
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group>
                                            <Form.Label className="size-sm">
                                                Last 12 Months Gross Dividend
                                            </Form.Label>
                                            <Form.Control
                                                name="last12MonthsGrossDividend"
                                                onChange={({
                                                    target: { value },
                                                }) =>
                                                    !isMultipleDot(value) &&
                                                    countDecimals(value) <= 4 &&
                                                    setProfile({
                                                        ...profile,
                                                        last12MonthsGrossDividend:
                                                            value,
                                                    })
                                                }
                                                type="number"
                                                value={
                                                    profile.last12MonthsGrossDividend
                                                }
                                                size="sm"
                                                disabled={profile.isPublished}
                                                placeholder="Last 12 Months Gross Dividend..."
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group className="mr-2">
                                            <Form.Label className="size-sm">
                                                ISIN number
                                            </Form.Label>
                                            <Form.Control
                                                name="isinNumber"
                                                onChange={({
                                                    target: { value },
                                                }) =>
                                                    setProfile({
                                                        ...profile,
                                                        isinNumber: value,
                                                    })
                                                }
                                                value={
                                                    profile?.isinNumber || ""
                                                }
                                                size="sm"
                                                disabled={profile.isPublished}
                                                placeholder="ISIN number..."
                                            />
                                        </Form.Group>
                                    </Col>
                                </>
                            )}
                        </Row>
                    </Col>
                </Row>

                <Row className="text-right mt-1" noGutters>
                    <Col>
                        <Button
                            size="sm"
                            className="px-4 mr-1"
                            onClick={() => {
                                // setProfile({
                                //     ...profile,
                                //     isPublished: !profile.isPublished,
                                // });
                                submitData(
                                    {
                                        ...profile,
                                        isPublished: !profile.isPublished,
                                    },
                                    image
                                );
                            }}
                            disabled={!profile?.symbol}
                        >
                            {profile.isPublished ? "Unpublish" : "Publish"} &
                            Save
                        </Button>
                        <Button
                            size="sm"
                            className="px-4"
                            onClick={() => submitData(profile, image)}
                        >
                            {type === "add" ? "Create" : "Save"}
                        </Button>
                    </Col>
                </Row>
                <hr />
                {profile.sections.map((section, i) => (
                    <SectionRenderer
                        key={i}
                        section={section}
                        setSection={(data) =>
                            setProfile({
                                ...profile,
                                sections: profile.sections.map((item, j) =>
                                    i === j
                                        ? {
                                              ...item,
                                              ...data,
                                          }
                                        : item
                                ),
                            })
                        }
                        disabled={profile.isPublished}
                    />
                ))}
            </>
        );
    }
);
