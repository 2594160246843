import { useState } from "react";
import { memo } from "react";
import { Col, Row } from "react-bootstrap";
import { AiOutlineCopy, AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

import { TableRenderer } from "../../portal/TableRenderer";
import { DeleteFormModal } from "./DeleteFormModal";

// eslint-disable-next-line react/display-name
export const OnboardingFormsTable = memo(({ deleteForm, forms, loading }) => {
    const navigate = useNavigate();
    const [selectedForm, setSelectedForm] = useState();

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const handleShowDeleteModal = (form) => {
        setSelectedForm(form);
        setShowDeleteModal(true);
    };

    const handleHideDeleteModal = () => setShowDeleteModal(false);

    const columns = [
        {
            dataField: "name",
            text: "Form Name",
            sort: true,
        },
        { dataField: "id", text: "ID" },
        {
            dataField: "isPublished",
            text: "Published Status",
            formatter: (rowContent, row) => {
                return (
                    <Row noGutters>
                        <Col
                            className={
                                row.isPublished ? "text-success" : "text-danger"
                            }
                        >
                            {row.isPublished ? "Published" : "Not Published"}
                        </Col>
                        <Col className="text-right">
                            <AiOutlineEdit
                                onClick={() =>
                                    navigate(`/admin/forms/${row?.id}/edit`)
                                }
                                className="mr-2 cursor-pointer"
                            />
                            <AiOutlineCopy
                                onClick={() =>
                                    navigate(
                                        `/admin/forms/create?copy=${row?.id}`
                                    )
                                }
                                className="mr-2 cursor-pointer"
                            />
                            <AiOutlineDelete
                                onClick={() =>
                                    !row.isPublished &&
                                    handleShowDeleteModal(row)
                                }
                                className={
                                    !row.isPublished
                                        ? "cursor-pointer"
                                        : "text-gray"
                                }
                            />
                        </Col>
                    </Row>
                );
            },
        },
    ];

    return (
        <>
            {showDeleteModal && (
                <DeleteFormModal
                    show={showDeleteModal}
                    handleDelete={() =>
                        deleteForm(selectedForm).then(handleHideDeleteModal)
                    }
                    handleClose={handleHideDeleteModal}
                    form={selectedForm}
                />
            )}
            <TableRenderer
                keyField="id"
                data={forms}
                columns={columns}
                sortBy="isPublished"
                sortOrder="desc"
                noDataIndication="No forms available"
                loading={loading}
                wrapperClasses="size-sm"
                pagination
                striped
            />
        </>
    );
});
