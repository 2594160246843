import { useEffect, useState } from "react";
import { Button, Col, FormControl, FormLabel, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { OnboardingFormsTable } from "../../../components/admin/onboarding/OnboardingFormsTable";
import {
    getOnboardingForms,
    updateOnboardingForms,
} from "../../../helpers/onboarding";
import { useStore } from "../../../Store";
import { useSocket } from "../../../Websocket";

const Forms = () => {
    const ws = useSocket();
    const { state, dispatch } = useStore();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const {
        onboarding: { forms },
    } = state;

    const [searchValue, setSearchValue] = useState("");
    const [filterStatus, setFilterStatus] = useState("");
    const [filteredForms, setFilteredForms] = useState(forms);

    const onSearch = () => {
        const isPublished = filterStatus === "true";
        const showForms = forms.filter((frm) =>
            frm.name.toLowerCase().includes(searchValue.toLowerCase())
        );

        const showPublishedForms =
            filterStatus.length === 0
                ? showForms
                : showForms.filter((frm) => frm.isPublished === isPublished);

        setFilteredForms(showPublishedForms);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(onSearch, [filterStatus, searchValue]);

    useEffect(() => setFilteredForms(forms), [forms]);

    const deleteForm = ({ id }) =>
        updateOnboardingForms(ws, dispatch, {
            forms: forms.filter((frm) => frm.id !== id),
        }).then(() => toast.success("Form successfully deleted."));

    useEffect(() => {
        if (forms.length === 0) {
            getOnboardingForms(ws, dispatch).finally(() => setLoading(false));
        } else {
            setLoading(false);
        }

        return () => setLoading(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="m-4">
            <Row noGutters className="forms-header">
                <h4>Forms</h4>
            </Row>
            <Row>
                <Col xs={12} md={3} className="pr-2 pb-2">
                    <FormLabel className="size-sm">
                        Filter By Verification:
                    </FormLabel>
                    <FormControl
                        as="select"
                        size="sm"
                        onChange={({ target: { value } }) =>
                            setFilterStatus(value)
                        }
                    >
                        <option value="">Status</option>
                        <option value="true">Published</option>
                        <option value="false">Unpublished</option>
                    </FormControl>
                </Col>
                <Col xs={12} md={3} className="pr-2 pb-2">
                    <FormLabel className="size-sm">Search:</FormLabel>
                    <FormControl
                        size="sm"
                        placeholder="Search by form name..."
                        onChange={({ target: { value } }) =>
                            setSearchValue(value)
                        }
                    />
                </Col>
                <Col
                    xs={12}
                    md={6}
                    className="pb-2 d-flex justify-content-end align-items-end"
                >
                    <Button
                        variant="primary"
                        size="sm"
                        onClick={() => navigate("/admin/forms/create")}
                    >
                        Create Form
                    </Button>
                </Col>
            </Row>
            <OnboardingFormsTable
                deleteForm={deleteForm}
                loading={loading}
                forms={filteredForms}
            />
        </div>
    );
};

export default Forms;
