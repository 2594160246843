import * as CONSTANTS from "../constants/asset-builder";

export const queryAssetTemplates = (templates) => ({
    type: CONSTANTS.QUERY_ASSET_TEMPLATES,
    templates,
});

export const queryAssetProfiles = (profiles) => ({
    type: CONSTANTS.QUERY_ASSET_PROFILES,
    profiles,
});

export const getPublicProfiles = (profiles) => ({
    type: CONSTANTS.GET_PUBLIC_PROFILES,
    profiles,
});

export const clearState = () => ({
    type: CONSTANTS.CLEAR_STATE,
});
