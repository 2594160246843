import { useEffect, useState } from "react";
import { Button, Col, FormControl, FormLabel, Row } from "react-bootstrap";
import { AiOutlineDelete } from "react-icons/ai";
import { toast } from "react-toastify";
import { v4 as uuid } from "uuid";

import { CreateRoleForm } from "../../../components/admin/onboarding/CreateRoleForm";
import { Loading } from "../../../components/Loading";
import {
    getOnboardingForms,
    getUserRoles,
    updateUserRoles,
} from "../../../helpers/onboarding";
import { useStore } from "../../../Store";
import { useSocket } from "../../../Websocket";

const DefineRolesPage = () => {
    const ws = useSocket();
    const { state, dispatch } = useStore();

    const {
        onboarding: { roles, forms },
    } = state;
    const [loading, setLoading] = useState(true);

    const [selectedRole, setSelectedRole] = useState(undefined);
    const mappedForms = selectedRole
        ? forms
              .filter((frm) => frm.isPublished)
              .map((frm) => ({
                  name: frm.name,
                  id: frm.id,
              }))
              .filter(
                  (frm) => !selectedRole.forms.some((fm) => fm.id === frm.id)
              )
        : [];

    const defineNewRole = () => {
        const identifier = uuid();

        const formattedRole = {
            name: "",
            description: "",
            forms: [],
            id: identifier,
            isPublished: false,
        };

        setSelectedRole(undefined);

        return updateUserRoles(ws, dispatch, {
            roles: roles.concat([formattedRole]),
        }).then(() => {
            toast.success("Role successfully created.");

            setSelectedRole(formattedRole);
        });
    };

    const editRole = (role) =>
        updateUserRoles(ws, dispatch, {
            roles: roles.filter((rle) => rle.id !== role.id).concat([role]),
        }).then(() => {
            toast.success("Role successfully edited.");

            setSelectedRole(role);
        });

    const deleteRole = (role) =>
        updateUserRoles(ws, dispatch, {
            roles: roles.filter((rle) => rle.id !== role.id),
        }).then(() => {
            toast.success("Role successfully deleted.");

            setSelectedRole(undefined);
        });

    useEffect(() => {
        if (forms.length === 0) getOnboardingForms(ws, dispatch);

        if (roles.length === 0) {
            getUserRoles(ws, dispatch).finally(() => setLoading(false));
        } else {
            setLoading(false);
        }

        return () => setLoading(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="m-4">
            <Row noGutters className="forms-header">
                <h4>Define Roles</h4>
            </Row>
            <Row noGutters className="d-flex">
                <Col xs={12} sm={6} className="d-inline-flex">
                    <FormLabel className="size-sm mt-1 ">
                        Select a role to edit:
                    </FormLabel>
                    <FormControl
                        as="select"
                        size="sm"
                        className="w-50 mx-2"
                        onChange={({ target: { value } }) =>
                            setSelectedRole(
                                roles.find((rle) => rle.id === value)
                            )
                        }
                        value={selectedRole?.id || ""}
                        disabled={loading}
                    >
                        <option value="">Select a role...</option>
                        {roles.map((rle, i) => (
                            <option key={i} value={rle.id}>
                                {rle.name || "Unnamed Role"}
                            </option>
                        ))}
                    </FormControl>
                    {selectedRole && (
                        <AiOutlineDelete
                            onClick={() => deleteRole(selectedRole)}
                            className={`${
                                selectedRole && !loading
                                    ? "cursor-pointer"
                                    : "text-light"
                            } mt-2`}
                        />
                    )}
                </Col>
                <Col
                    className="text-right d-flex justify-content-end "
                    style={{ flexDirection: "row" }}
                >
                    <Button
                        size="sm"
                        onClick={defineNewRole}
                        disabled={loading}
                    >
                        Define a new role
                    </Button>
                </Col>
            </Row>
            <hr />
            {loading && <Loading />}
            {selectedRole && (
                <CreateRoleForm
                    forms={mappedForms}
                    role={selectedRole}
                    setRole={setSelectedRole}
                    submitData={editRole}
                />
            )}
        </div>
    );
};

export default DefineRolesPage;
