import { useEffect, useState } from "react";
import { Col, Row, Tab, Tabs } from "react-bootstrap";
import { toast } from "react-toastify";

import { PositionsList } from "../../../components/admin/firm/transfers/";
import {
    BreakTradeModal,
    CreditsDebits,
    Fills,
    LoadForm,
    Orders,
} from "../../../components/admin/market/activity";
import {
    ConfirmOrderForm,
    EnterOrderForm,
    Responses,
} from "../../../components/admin/market/activity/enter-order/";
import { FullScreenLoading } from "../../../components/Loading";
import {
    enableBreakTrade,
    hideCreditsDebits,
    hideFillsTab,
} from "../../../config";
import { getUsersFromWebsocket } from "../../../helpers/firm";
import {
    breakTrade,
    getAdminDeposits,
    getAdminMultipleOrders,
    getAdminTrades,
    getUserPos,
    submitOrder,
} from "../../../helpers/market";
import { useStore } from "../../../Store";
import { useSocket } from "../../../Websocket";

const Activity = () => {
    const ws = useSocket();
    const { dispatch, state } = useStore();
    const [usersLoading, setUsersLoading] = useState(true);

    const { existing_users: users, firms } = state.firm;
    const { symbols, orders, cancels, fills, balances } = state.market;

    const [pendingConf, setPendingConf] = useState(false);
    const [pendingOrder, setPendingOrder] = useState({});
    const [selectedUserPositions, setSelectedUserPositions] = useState([]);
    const [orderResponse, setOrderResponse] = useState([]);
    const [tab, setCurrentTab] = useState("orders");
    const [showBreakTradeModal, setShowBreakTradeModal] = useState(false);
    const [managedTrade, setManagedTrade] = useState({});

    const handleCloseBreakTradeModal = () => setShowBreakTradeModal(false);

    const showBreakTradePopup = (refno, exchangeref) => {
        setManagedTrade({
            refno,
            exchangeref,
        });
        setShowBreakTradeModal(true);
    };

    const getMultipleOrders = (
        symbol,
        user,
        fromtime,
        firm,
        filterCompliance,
        side
    ) => {
        const options = {
            fromtime: fromtime ? fromtime : new Date(1262304000000),
            firm: firm?.firm,
            complianceid: filterCompliance
                ? user?.complianceid || "*"
                : undefined,
            userid: !filterCompliance ? user?.userid || "*" : undefined,
            security: symbol?.security,
            side: side || undefined,
        };

        state.market[tab] = [];

        if (["orders", "cancels"].includes(tab)) {
            getAdminMultipleOrders(ws, dispatch, tab, options);
        } else if (tab === "fills") {
            getAdminTrades(ws, dispatch, options);
        } else if (tab === "balances") {
            getAdminDeposits(ws, dispatch, options);
        }
    };

    const handleSubmitOrder = (type, user, symbol, quantity, price) => {
        setPendingOrder({ type, user, symbol, quantity, price });
        setPendingConf(true);
    };

    const handleConfirmOrder = () => {
        setPendingConf(false);
        submitOrder(ws, {
            firm: pendingOrder.user.firm,
            complianceid: pendingOrder.user.userid,
            qty: pendingOrder.quantity,
            price: pendingOrder.price,
            security: pendingOrder.symbol.security,
            ordertype: "LMT",
            tif: "GTC",
            side: pendingOrder.type === "buy" ? "B" : "S",
        }).then((res) => {
            const {
                ordertype,
                firm,
                price,
                qty,
                refno,
                security,
                side,
                tif,
                userid,
            } = res;
            setOrderResponse([
                ...orderResponse,
                {
                    ordertype,
                    firm,
                    price,
                    qty,
                    refno,
                    security,
                    side,
                    tif,
                    userid,
                },
            ]);
        });
    };
    const onChangeOrderUser = (user) => {
        getUserPos(ws, user.userid, user.firm).then((e) =>
            setSelectedUserPositions(e)
        );
    };

    const LoadFormWrapper = () => {
        return !usersLoading ? (
            <LoadForm
                users={users}
                symbols={symbols}
                firms={firms}
                getData={getMultipleOrders}
            />
        ) : null;
    };

    useEffect(() => {
        users.length === 0
            ? getUsersFromWebsocket(ws, dispatch).finally(() =>
                  setUsersLoading(false)
              )
            : setUsersLoading(false);

        return () => {
            setUsersLoading(true);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (usersLoading) return <FullScreenLoading />;

    return (
        <div className="m-4">
            {!hideFillsTab && enableBreakTrade && (
                <BreakTradeModal
                    show={showBreakTradeModal}
                    handleCloseModal={handleCloseBreakTradeModal}
                    handleDelete={() => {
                        breakTrade(ws, dispatch, managedTrade.exchangeref).then(
                            (success) => {
                                toast.success(
                                    `Trades exchangeref: ${success.exchangeref} was broken`
                                );
                                success && handleCloseBreakTradeModal();
                            }
                        );
                    }}
                    trade={managedTrade}
                />
            )}
            <Row noGutters className="forms-header">
                <Col>
                    <h4>Activity</h4>
                </Col>
            </Row>
            <Tabs
                className="mb-3"
                onSelect={(tab) => setCurrentTab(tab)}
                transition={false}
                unmountOnExit={true}
            >
                <Tab eventKey="orders" title="Orders" className="">
                    {LoadFormWrapper()}
                    <Orders orders={orders} />
                </Tab>
                <Tab eventKey="cancels" title="Cancels" className="">
                    {LoadFormWrapper()}
                    <Orders orders={cancels} />
                </Tab>
                {!hideFillsTab && (
                    <Tab eventKey="fills" title="Fills" className="">
                        {LoadFormWrapper()}
                        <Fills
                            fills={fills}
                            isAdmin={true}
                            showBreakTradePopup={showBreakTradePopup}
                        />
                    </Tab>
                )}
                {!hideCreditsDebits && (
                    <Tab
                        eventKey="balances"
                        title="Credits/Debits"
                        className=""
                    >
                        {LoadFormWrapper()}
                        <CreditsDebits balances={balances} />
                    </Tab>
                )}
                <Tab eventKey="enter-order" title="Enter Order" className="">
                    <Row>
                        <Col lg={2}>
                            {!pendingConf ? (
                                <>
                                    <EnterOrderForm
                                        symbols={symbols}
                                        users={users}
                                        submitOrder={handleSubmitOrder}
                                        onChangeUser={onChangeOrderUser}
                                    />
                                </>
                            ) : (
                                <ConfirmOrderForm
                                    confirmOrder={handleConfirmOrder}
                                    cancelOrder={() => setPendingConf(false)}
                                />
                            )}
                        </Col>
                        <Col>
                            <h4>Credits/Debits</h4>
                            <PositionsList positions={selectedUserPositions} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Responses responses={orderResponse} />
                        </Col>
                    </Row>
                </Tab>
            </Tabs>
        </div>
    );
};

export default Activity;
