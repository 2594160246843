/* eslint-disable react/display-name */
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

import moment from "moment";
import { memo } from "react";
import { Button, Row } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
    CSVExport,
    Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

import { exportToCSVFromJSON } from "../helpers/other";
import { ConfigureDisplayWrapper } from "./ConfigureDisplayTableWrapper";
import { Loading } from "./Loading";

export const TableRenderer = memo(
    ({
        keyField,
        columns = undefined,
        data,
        noDataIndication,
        pagination,
        striped = false,
        bordered = true,
        hover = true,
        searchBar = false,
        rowEvents = {},
        rowClasses = undefined,
        wrapperClasses = undefined,
        expandRow = undefined,
        loading = false,
        hideConfigureButtons = false,
        // ConfigureDisplayWrapper options
        keyType,
        optionKey,
        blacklistedAttr = [],
        defaultOptions = [],
        settingsColumn = [],
        interactColumn = [],
        exportCSVMapFunc = undefined,
        exportCSVName = undefined,
        exportCSVIgnoredColumns = [],
    }) => {
        const { ExportCSVButton } = CSVExport;
        const { SearchBar } = Search;

        return loading ? (
            <Loading />
        ) : (
            <ConfigureDisplayWrapper
                data={data}
                defaultOptions={defaultOptions}
                keyType={keyType}
                optionKey={optionKey}
                blacklistedAttr={blacklistedAttr}
                overrideColumns={columns}
                returnButtons
                render={(preferredColumns, configureButtons) => (
                    <ToolkitProvider
                        keyField={keyField}
                        data={data}
                        columns={interactColumn.concat(
                            preferredColumns.concat(settingsColumn)
                        )}
                        exportCSV={{
                            fileName: exportCSVName
                                ? `${exportCSVName}-${moment().format(
                                      "YYYYMMDD"
                                  )}.csv`
                                : undefined,
                        }}
                        search
                    >
                        {(toolkitProviderProps) => (
                            <>
                                <Row
                                    noGutters
                                    className="mb-2 d-flex justify-content-end"
                                >
                                    {!hideConfigureButtons && configureButtons}
                                    <ExportCSVButton
                                        {...toolkitProviderProps.csvProps}
                                        className="btn btn-primary btn-sm ml-1"
                                    >
                                        Export CSV
                                    </ExportCSVButton>
                                    {exportCSVMapFunc && (
                                        <Button
                                            className="btn btn-primary btn-sm ml-1"
                                            onClick={() =>
                                                exportToCSVFromJSON(
                                                    exportCSVMapFunc(data),
                                                    `${exportCSVName}-${moment().format(
                                                        "YYYYMMDD"
                                                    )}.csv`,
                                                    exportCSVIgnoredColumns
                                                )
                                            }
                                        >
                                            Export CSV (All)
                                        </Button>
                                    )}
                                </Row>
                                {searchBar && (
                                    <SearchBar
                                        {...toolkitProviderProps.searchProps}
                                    />
                                )}
                                <hr />
                                <BootstrapTable
                                    {...toolkitProviderProps.baseProps}
                                    noDataIndication={noDataIndication}
                                    pagination={
                                        pagination
                                            ? paginationFactory()
                                            : undefined
                                    }
                                    striped={striped}
                                    bordered={bordered}
                                    hover={hover}
                                    rowEvents={rowEvents}
                                    rowClasses={rowClasses}
                                    wrapperClasses={wrapperClasses}
                                    expandRow={expandRow}
                                    defaultSorted={[
                                        {
                                            dataField: keyField,
                                            order: "asc",
                                        },
                                    ]}
                                />
                            </>
                        )}
                    </ToolkitProvider>
                )}
            />
        );
    }
);
