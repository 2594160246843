import { useState } from "react";
import { AiOutlineForm } from "react-icons/ai";

import { formatTableColumn } from "../../../helpers/other";
import { TableRenderer } from "../../portal/TableRenderer";
import { ReviewSubmissionModal } from "./ReviewSubmissionModal";

export const SubmissionsTable = ({ submissions, loading }) => {
    const [selectedSubmission, setSelectedSubmission] = useState();

    const [showReviewModal, setShowReviewModal] = useState(false);
    const handleShowReviewModal = (submission) => {
        setSelectedSubmission(submission);
        setShowReviewModal(true);
    };
    const handleHideReviewModal = () => {
        setSelectedSubmission(undefined);
        setShowReviewModal(false);
    };

    return (
        <>
            <ReviewSubmissionModal
                show={showReviewModal}
                handleClose={handleHideReviewModal}
                submission={selectedSubmission}
                setSubmission={setSelectedSubmission}
            />
            <TableRenderer
                columns={[
                    ...[
                        "userid",
                        "data.form.name",
                        "status",
                        "data.form.updatedAt",
                    ].map((col) => formatTableColumn(col)),
                    {
                        dataField: "action",
                        isDummyField: true,
                        text: "",
                        formatter: (cell, rowContent) => (
                            <AiOutlineForm
                                className="cursor-pointer"
                                onClick={() =>
                                    handleShowReviewModal(rowContent)
                                }
                            />
                        ),
                    },
                ]}
                data={submissions}
                wrapperClasses="size-sm"
                keyField="submission_id"
                loading={loading}
                pagination
                striped
            />
        </>
    );
};
