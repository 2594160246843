import { useEffect } from "react";
import { useState } from "react";
import { Col, FormControl, FormLabel, Row } from "react-bootstrap";
import { AiOutlineCheckCircle } from "react-icons/ai";

import { useStore } from "../../../Store";

const MarketSymbols = () => {
    const { state } = useStore();

    const { symbols } = state.market;

    const [searchValue, setSearchValue] = useState("");
    const [filteredSymbols, setFilteredSymbols] = useState(symbols);

    const onSearch = (security) => {
        const showSymbols = symbols.filter((symbol) =>
            symbol.security.toLowerCase().includes(security.toLowerCase())
        );
        setFilteredSymbols(showSymbols);
    };

    useEffect(() => {
        setFilteredSymbols(symbols);
    }, [symbols]);

    const renderTable = () => (
        <div className="bg-white">
            <h6 className="pt-4 pb-3 px-2 traded-symbols-table-header mb-0">
                <b>Security</b>
            </h6>
            <Row noGutters className="traded-symbols-table-row">
                {filteredSymbols.length > 0 ? (
                    filteredSymbols.map((item) => renderTableItem(item))
                ) : (
                    <Row noGutters className="table-loading-row">
                        No symbols found
                    </Row>
                )}
            </Row>
        </div>
    );

    const renderTableItem = (item) => {
        const { security } = item;

        return (
            <Col
                sm={6}
                md={4}
                lg={3}
                xl={2}
                className="traded-symbols-card"
                key={security}
            >
                <Row noGutters className="td-row">
                    {security || "No Security"}
                    <AiOutlineCheckCircle
                        className="mentionedIn-icon"
                        id={item?.attr?.profile_exists ? "true" : "false"}
                    />
                </Row>
            </Col>
        );
    };

    return (
        <div className="m-4">
            <Row noGutters className="forms-header">
                <h4>Market Symbols</h4>
            </Row>

            <Row noGutters className="">
                <Col xs={12} className="pr-2 ">
                    <FormLabel>Search:</FormLabel>
                    <FormControl
                        placeholder="Search..."
                        value={searchValue}
                        onChange={(e) => {
                            setSearchValue(e.target.value);
                            onSearch(e.target.value);
                        }}
                    />
                </Col>
            </Row>
            <hr />
            {renderTable()}
        </div>
    );
};

export default MarketSymbols;
