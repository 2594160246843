/* eslint-disable react/display-name */
import { memo } from "react";
import { Container, Row } from "react-bootstrap";

import { FullScreenLoading } from "../components/Loading";
import { useStore } from "../Store";

const LoadingWrapper = memo(({ auth, isLoading, children }) => {
    const {
        state: { other },
    } = useStore();

    if ((!isLoading && auth && other.connected) || (!isLoading && !auth))
        return (
            <Container fluid className="app-container overflow-hidden">
                <Row noGutters className="flex-nowrap h-100">
                    {children}
                </Row>
            </Container>
        );
    return <FullScreenLoading />;
});

export default LoadingWrapper;
