import { roleParser } from "../../helpers/roles";
import * as CONSTANTS from "../constants/account";

export const initialAuthState = {
    auth: {
        status: false,
        loading: false,
        error: "",
        account_info: {
            email: "",
            password: "",
            keepMeLoggedIn: false,
        },
    },
    account: {
        wsdata: {},
        data: {},
        loading: false,
        loaded: false,
        error: "",
    },
    roles: {
        risk_admin: false,
        onboarding_admin: false,
        trading: false,
        // trading: {
        //     investor: false,
        //     issuer: false,
        //     marker_maker: false,
        // },
        custody: false,
        asset_builder: false,
        ordinary_user: false,
    },
    registerRoles: [],
    publicKey: "",
    fontURL: null,
};

export const authReducer = (state, action) => {
    switch (action.type) {
        case CONSTANTS.AUTH_REQUEST:
            return {
                ...state,
                auth: {
                    ...state.auth,
                    status: false,
                    loading: true,
                    error: "",
                },

                account: {
                    ...state.account,
                    data: {},
                    loading: true,
                    loaded: false,
                },
            };

        case CONSTANTS.AUTH_SUCCESS: {
            const preferenceObjs =
                action.data?.config_setting &&
                Object.keys(action.data?.config_setting).length > 0
                    ? JSON.parse(action.data?.config_setting?.attr)
                    : {};
            const formattedObject = {};

            Object.keys(preferenceObjs).forEach((key) => {
                if (
                    ["order_ticket", "trade_screen_configuration"].includes(key)
                )
                    formattedObject[key] = JSON.parse(preferenceObjs[key]);
            });

            return {
                ...state,
                auth: {
                    ...state.auth,
                    status: true,
                    loading: false,
                },
                account: {
                    ...state.account,
                    data: {
                        ...action.data,
                        config_setting: {
                            ...action.data?.config_setting,
                            attr: formattedObject,
                        },
                    },
                    loading: false,
                    loaded: true,
                },
            };
        }
        case CONSTANTS.AUTH_ERROR:
            return {
                ...state,
                auth: {
                    ...state.auth,
                    status: false,
                    loading: false,
                    error: action.error,
                    account_info: {
                        ...action.account,
                    },
                },
                account: {
                    ...state.account,
                    loaded: true,
                },
            };
        case CONSTANTS.ADD_PUBLIC_KEY:
            return {
                ...state,
                publicKey: action.publicKey,
            };
        case CONSTANTS.ADD_WS_DATA:
            return {
                ...state,
                account: {
                    ...state.account,
                    data: action.wsdata,
                },
            };
        case CONSTANTS.UPDATE_WS_2FA_DATA:
            return {
                ...state,
                account: {
                    ...state.account,
                    data: {
                        ...state.account.data,
                        use2fa: action.use2fa,
                    },
                },
            };
        case CONSTANTS.PARSE_ROLES:
            return {
                ...state,
                roles: roleParser(action.roles),
            };
        case CONSTANTS.UPDATE_SYSTEM_PREFERENCES:
            return {
                ...state,
                account: {
                    ...state.account,
                    data: {
                        ...state.account.data,
                        config_setting: {
                            ...state.account.data.config_setting,
                            attr: action.preferences,
                        },
                    },
                },
            };
        case CONSTANTS.GET_ROLE_LIST:
            return {
                ...state,
                registerRoles: action.roles,
            };
        case CONSTANTS.SET_FONT_URL:
            return {
                ...state,
                fontURL: action.url,
            };
        case CONSTANTS.CLEAR_STATE:
            return initialAuthState;
        default:
            return state;
    }
};
