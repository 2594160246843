import { memo } from "react";
import { Button, Row } from "react-bootstrap";

import { TableRenderer } from "./TableRenderer";

// eslint-disable-next-line react/display-name
export const CompanyDocumentsTable = memo(({ docs }) => {
    return (
        <>
            <TableRenderer
                data={docs}
                keyField="id"
                columns={[
                    {
                        dataField: "title",
                        text: "Title",
                        style: {
                            width: "20%",
                        },
                    },
                    {
                        dataField: "description",
                        text: "Description",
                        style: {
                            width: "60%",
                            whiteSpace: "pre-wrap",
                            maxWidth: "60%",
                        },
                    },
                    {
                        dataField: "action",
                        text: "Document ",
                        isDummyField: true,
                        formatter: (cell, row) => (
                            <>
                                {/* <Row noGutters>
                                    <Button
                                        size="sm"
                                        className="w-100 mb-1"
                                        onClick={() =>
                                            window.open(row.file, "_blank")
                                        }
                                    >
                                        Preview
                                    </Button>
                                </Row> */}
                                <Row noGutters>
                                    <Button
                                        size="sm"
                                        className="w-100"
                                        onClick={async () => {
                                            // Download file
                                            const blob = await fetch(
                                                row.file
                                            ).then((r) => r.blob());
                                            const url =
                                                URL.createObjectURL(blob);

                                            const a =
                                                document.createElement("a");

                                            a.href = url;
                                            a.download = `${row.title}.${
                                                row.file.match(/\/.+(\..+)$/)[1]
                                            }`;

                                            a.click();
                                        }}
                                    >
                                        Download
                                    </Button>
                                </Row>
                            </>
                        ),
                    },
                ]}
                noDataIndication="No documents available."
                pagination
                wrapperClasses="size-sm"
            />
        </>
    );
});
