import * as CONSTANTS from "../constants/portfolio";

export const initialPortfolioState = {
    wallets: {},
    depositWallets: {},
};

export const portfolioReducer = (state, action) => {
    switch (action.type) {
        case CONSTANTS.ADD_WALLETS:
            return {
                wallets: {
                    [action.symbol]: action.wallets,
                },
                depositWallets: state.depositWallets,
            };
        case CONSTANTS.ADD_DEPOSIT_WALLET:
            return {
                wallets: state.wallets,
                depositWallets: {
                    [action.symbol]: action.wallet,
                },
            };
        default:
            return state;
    }
};
