import { useEffect } from "react";
import { useState } from "react";
import { Button, Modal, Row } from "react-bootstrap";
import { IoMdRefresh } from "react-icons/io";

import { formatTableColumn } from "../../../helpers/other";
import { queryRiskSettingHistory } from "../../../helpers/risk";
import { useSocket } from "../../../Websocket";
import { TableRenderer } from "../../portal/TableRenderer";

export const AuditChangesModal = ({ show, handleClose, frm, trdr }) => {
    const ws = useSocket();
    const [riskSettingChanges, setRiskSettingChanges] = useState([]);

    const searchForHistory = () =>
        queryRiskSettingHistory(ws, frm, trdr).then((res) =>
            setRiskSettingChanges(res)
        );

    useEffect(
        () =>
            queryRiskSettingHistory(ws, frm, trdr).then((res) =>
                setRiskSettingChanges(res)
            ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Audit Changes To Risk Settings</Modal.Title>
            </Modal.Header>
            <Modal.Body className="size-sm">
                <Row noGutters className="d-flex justify-content-end mb-2">
                    <Button
                        size="sm"
                        className="px-4"
                        onClick={searchForHistory}
                    >
                        <IoMdRefresh />
                    </Button>
                </Row>
                <Row noGutters>
                    Viewing Risk Settings Changes for&nbsp;<b>{trdr}</b>
                    &nbsp;from firm&nbsp;<b>{frm}</b>
                </Row>
                <TableRenderer
                    columns={["time", "data"].map((col) =>
                        formatTableColumn(col)
                    )}
                    keyField="rec_no"
                    wrapperClasses="size-sm my-4 audit-modal-table"
                    data={riskSettingChanges}
                    noDataIndication="No History Available."
                    pagination
                    striped
                />
            </Modal.Body>
            <Modal.Footer>
                <Button size="sm" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
