import { ParentSidebarItem, SidebarWrappper } from "../SidebarWrapper";

export const LandingSidebar = () => {
    return (
        <SidebarWrappper
            homePath="/assets"
            headerName="SYSTEM"
            style={{
                color: "var(--AssetsSidebar-color)",
                background: "var(--AssetsSidebar-background)",
            }}
        >
            <ParentSidebarItem name="Assets" path="/assets" />
        </SidebarWrappper>
    );
};
