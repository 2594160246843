import { Button, Modal } from "react-bootstrap";

export const BreakTradeModal = ({
    show = false,
    handleCloseModal,
    handleDelete,
    trade,
}) => {
    return (
        <Modal show={show} onHide={handleCloseModal}>
            <Modal.Header closeButton>
                <Modal.Title>Break Trade</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Are you sure you want to break this trade?
                <br />
                refno: {JSON.stringify(trade.refno)}
                <br />
                exchangeref:  {JSON.stringify(trade.exchangeref)}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={handleDelete} size="sm">
                    Break
                </Button>
                <Button variant="light" onClick={handleCloseModal} size="sm">
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
