/* eslint-disable react/display-name */
import { memo } from "react";
import { Row } from "react-bootstrap";

import { FieldRenderer } from "./FieldRenderer";

export const SectionRenderer = memo(({ section, setSection, disabled }) => {
    return (
        <div className="asset-section">
            <Row noGutters>
                <b>{section.name}</b>
            </Row>
            {section.fields.map((field, i) => (
                <FieldRenderer
                    key={i}
                    field={field}
                    setField={(data) =>
                        setSection({
                            ...section,
                            fields: section.fields.map((item, j) =>
                                i === j
                                    ? {
                                          ...item,
                                          ...data,
                                      }
                                    : item
                            ),
                        })
                    }
                    disabled={disabled}
                />
            ))}
        </div>
    );
});
