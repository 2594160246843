import { Fragment, memo, useEffect, useState } from "react";
import { Col, FormControl, InputGroup, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";

import {
    clientDateFormat,
    clientTimeFormat,
    enableGttEodDefault,
} from "../../../../config";
import {
    countDecimals,
    getZeroes,
    isMultipleDot,
} from "../../../../helpers/other";
import { validatePosNumber } from "../../../../helpers/validate";

// eslint-disable-next-line react/display-name
export const OrderTifTypeDropdownRenderer = memo(
    ({
        currentOrder,
        onChange,
        tabConfig,
        symbol,
        currency,
        security,
        renderType,
        typeLabel,
    }) => {
        const [additionalFields, setAdditionalFields] = useState([]);

        useEffect(() => {
            if (currentOrder[renderType]) {
                additionalFields.forEach((fld) =>
                    onChange(fld.fieldName, undefined)
                );

                const allAdditionalFields =
                    tabConfig.find(
                        (tab) =>
                            tab[
                                renderType === "ordertype"
                                    ? renderType
                                    : "value"
                            ] === currentOrder[renderType]
                    )?.additionalFields || [];

                // Add fixed value fields to our current order.
                const toNotRenderFields = allAdditionalFields.filter(
                    (fld) => fld.fieldValue
                );
                toNotRenderFields.forEach((fld) =>
                    onChange(fld.fieldName, fld.fieldValue)
                );

                // IF ENV VAR FOR DEFAULT VALUES
                if (enableGttEodDefault) {
                    // Incase we ever want to set deualts for others
                    allAdditionalFields.forEach((fld) => {
                        if (
                            // ENV var for default GTT
                            enableGttEodDefault &&
                            currentOrder[renderType] === "GTT" &&
                            fld.fieldName === "exptime"
                        ) {
                            onChange(
                                fld.fieldName,
                                new Date().setHours(24, 0, 0, 0) - 1000
                            );
                        }
                    });
                }

                setAdditionalFields(allAdditionalFields);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [currentOrder[renderType], tabConfig]);

        const renderInput = (fld) => {
            const { zeroCount, zeroes } = getZeroes(fld.fieldSync, symbol);

            const fieldSyncOnChange = (e) => {
                const isMultipleDots = !isMultipleDot(e.target.value);

                fld.fieldSync &&
                isMultipleDots &&
                countDecimals(e.target.value) <= zeroCount
                    ? onChange(fld.fieldName, e.target.value)
                    : !fld.fieldSync && isMultipleDots
                    ? onChange(fld.fieldName, e.target.value)
                    : () => {};
            };

            switch (fld.fieldType) {
                case "Date":
                    return (
                        <DatePicker
                            className="custom-time-input"
                            showTimeInput
                            selected={
                                currentOrder?.[fld.fieldName]
                                    ? new Date(currentOrder?.[fld.fieldName])
                                    : null
                            }
                            onChange={(date) =>
                                onChange(fld.fieldName, date.getTime())
                            }
                            dateFormat={`${clientDateFormat} ${clientTimeFormat}`}
                        />
                    );
                case "Boolean":
                    return (
                        <FormControl
                            value={currentOrder?.[fld.fieldName] || ""}
                            defaultChecked
                            type="checkbox"
                            onChange={fieldSyncOnChange}
                            size="sm"
                        />
                    );
                case "Number": {
                    return (
                        <FormControl
                            value={currentOrder?.[fld.fieldName] || ""}
                            onChange={fieldSyncOnChange}
                            onKeyDown={validatePosNumber}
                            min={0}
                            placeholder={fld.fieldSync ? `0${zeroes}` : ""}
                            size="sm"
                        />
                    );
                }
                default:
                    return (
                        // Fallback rendering for fieldTypes that arent supported yet.
                        <></>
                    );
            }
        };

        return (
            <>
                <Row
                    className={`d-flex pb-2 order-ticket-flex-item ${typeLabel.toLowerCase()}-field`}
                >
                    <Col xs={4}>{typeLabel}</Col>
                    <Col xs={8}>
                        <FormControl
                            as={"select"}
                            size="sm"
                            onChange={({ target: { value } }) =>
                                onChange(renderType, value)
                            }
                            value={currentOrder[renderType]}
                            disabled={
                                tabConfig.filter((tif) => !tif?.isHidden)
                                    .length === 1
                            }
                        >
                            {tabConfig
                                .filter((tif) => !tif?.isHidden)
                                .map((opt, i) => (
                                    <option key={i} value={opt[renderType]}>
                                        {opt.displayName}
                                    </option>
                                ))}
                        </FormControl>
                    </Col>
                </Row>
                {additionalFields.map((fld, i) => (
                    <Fragment key={i}>
                        {!fld.isFixed &&
                            !fld.fieldValue &&
                            (currentOrder?.strategy &&
                            fld.fieldName === "exptime" ? (
                                <></>
                            ) : (
                                <Row
                                    className={`d-flex pb-2 order-ticket-flex-item ${fld.fieldName.toLowerCase()}-field`}
                                >
                                    <Col xs={4} className="">
                                        {fld.fieldName}
                                    </Col>
                                    <Col xs={8}>
                                        <InputGroup size="sm">
                                            {renderInput(fld)}
                                            {fld?.fieldSync && (
                                                <InputGroup.Append>
                                                    <InputGroup.Text>
                                                        {fld.fieldSync ===
                                                        "Price"
                                                            ? currency?.type
                                                            : fld.fieldSync ===
                                                              "Amount"
                                                            ? security?.type
                                                            : ""}
                                                    </InputGroup.Text>
                                                </InputGroup.Append>
                                            )}
                                        </InputGroup>
                                    </Col>
                                </Row>
                            ))}
                    </Fragment>
                ))}
            </>
        );
    }
);
