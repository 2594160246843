import { Col, Row } from "react-bootstrap";

import { clientName } from "../../config";

const InstructionsPage = () => {
    return (
        <div className="instructions-page">
            <Row noGutters className="forms-header" style={{ color: "black" }}>
                <Col>
                    <h4>
                        <b>{clientName} MATCHING SERVICE</b>
                    </h4>
                </Col>
            </Row>
            {clientName} has established this Qualified Matching Service (the
            “QMS”) to provide a platform for Company members to post indications
            of interest to sell or purchase membership units of {clientName},
            either individually or together with units of {clientName} Financial
            Services (“Indications of Interest”). <br />
            <br />
            The following instructions and FAQs are provided to assist members
            of {clientName} in using the QMS and arranging for transfers of
            membership units posted on the QMS.
            <br />
            <br />
            <h5>
                <u>
                    <b>Instructions</b>
                </u>
            </h5>
            <ol>
                <li>
                    A member wishing to potentially transfer membership units
                    should complete a Unit Transfer Notice in the form provided
                    in the “Company Documents” section of the QMS and return it
                    to the Company.
                </li>
                <li>
                    To enter a new indication of interest to sell or purchase
                    membership units of {clientName}, either individually or
                    together with units of {clientName} Financial Services,
                    click on the “<b>Indications of Interest</b>” link on the
                    left side of the “Welcome to the QMS” screen, and select “
                    <b>Enter Indications of Interest</b>”. This will take you to
                    a unit selection page.
                </li>
                <li>
                    Select whether you want to enter a new indication of
                    interest to sell or purchase: (i) membership units of{" "}
                    {clientName} (PPL), individually; or (ii) membership
                    units of{" "}
                    {clientName} Financial Services, (PPFS), individually;
                    or (iii) membership units of{" "}
                    {clientName} together with units of {clientName}{" "}
                    Financial Services
                    . This selection will take you to an Indication of Interest entry
                    page.
                </li>
                <li>
                    In the “Enter Indication of Interest” box, select whether
                    you want to enter (i) a new indication of interest to{" "}
                    <u>sell</u> membership units; or (ii) a new indication of
                    interest to <u>purchase</u> membership units.
                </li>
                <li>
                    Enter the amount of units that you are interested in selling
                    or purchasing.
                </li>
                <li>
                    If you wish to indicate a price at which you are interested
                    in selling or purchasing units, enter that price. Entering a
                    price is not required. If you do not wish to enter a price,
                    leave the price screen unchanged at $0.000.
                </li>
                <li>
                    Once you submit the Indication of Interest, you will be
                    asked to accept the Terms of Use for the QMS system. These
                    Terms of Use are set forth in full in the FAQs and must be
                    accepted for your Indication of Interest to be posted.
                </li>
                <li>
                    Among other items, the Terms of Use provide that (i)
                    Indications of Interest posted on the QMS are not binding;
                    (ii) the QMS does not automatically process transactions;
                    (iii) participating members must directly communicate with
                    each other outside of the QMS to arrange for any transfers
                    of membership units that were posted on the QMS; (iv) no
                    agreement for any transfers of membership units can be made
                    for at least 15 days after the units were first listed on
                    the QMS; (v) no transfers of membership units can be
                    completed and closed until at least 45 days after the units
                    were first listed on the QMS; and (vi) all transfers of
                    membership units are subject to the prior approval of the{" "}
                    {clientName} Board of Directors (and the Board of Directors
                    of {clientName}, if applicable).
                </li>
                <li>
                    Upon accepting the Terms of Use, your Indication of Interest
                    will be posted and appear on the QMS in both the “My
                    Indications of Interest” display area and the “All Posted
                    Indications of Interest” display area. The “My Indications
                    of Interest” display area shows all Indications of Interest
                    that you personally have submitted. The “All Posted
                    Indications of Interest” display area shows all Indications
                    of Interest that have been submitted by all {clientName}{" "}
                    members (including you).
                </li>
                <li>
                    You may remove any Indication of Interest that you have
                    posted by clicking the “Cancel” box next to the applicable
                    Indication of Interest in the “My Indications of Interest”
                    display area.
                </li>
                <li>
                    Indications of Interest posted on the QMS are not binding.
                    If you see an Indication of Interest to sell or purchase
                    membership units and you wish to discuss entering into a
                    binding agreement for the transfer of the membership units
                    based on that Indication of Interest, you should contact the
                    posting member directly and independently, outside of the
                    QMS. Contact information for each posting member is provided
                    in the “MPID” column of the “All Posted Indications of
                    Interest” display area.
                </li>
                <li>
                    You cannot enter into a binding agreement to sell or
                    purchase membership units until at least 15 days after those
                    membership units were first posted on the QMS.
                </li>
                <li>
                    You cannot close on a transaction to sell or purchase
                    membership units until at least 45 days after those
                    membership units were first posted on the QMS. All transfers
                    of membership units are subject to the prior approval of the{" "}
                    {clientName} Board of Directors.
                </li>
                <li>
                    If you and another member reach an agreement to transfer
                    membership units (the “Proposed Transfer”), you should
                    complete and submit the following documents to the Company
                    to assist the Board in assessing the Proposed Transfer (the
                    “Review Documents”). All of the Review Documents are
                    accessible in the “Company Documents” section of the QMS:
                    <ul>
                        <li>
                            A QMS Agreement Notice to be completed by both the
                            transferring and receiving members, providing the
                            Company with notice that the members have reached an
                            agreement with respect to the Proposed Transfer.
                        </li>
                        <li>
                            An “Investor Questionnaire” to be completed by the
                            proposed receiving member, to assist the Board in
                            determining whether to approve the Proposed
                            Transfer.
                        </li>
                        <li>
                            An “Investment Letter Agreement” to be completed by
                            the proposed receiving member, in which that member
                            would make certain representations, warranties and
                            covenants to the Company in connection with the
                            Proposed Transfer.
                        </li>
                        <li>
                            A “Seller Questionnaire” to be completed by the
                            proposed transferring member, to assist the Board in
                            determining whether to approve the Proposed
                            Transfer.
                        </li>
                        <li>
                            One or more agreements (the “Transfer Agreements”)
                            though which the Units would be transferred from the
                            selling member to the receiving member.
                            <ol type="i">
                                <li>
                                    For the Transfer Agreements, the parties can
                                    use the template Assignment of LLC Producer
                                    Units (to be completed by the selling
                                    member) and Acceptance of LLC Producer Units
                                    (to be completed by the receiving member)
                                    that have been prepared by the Company (and
                                    are included in the Company Documents)
                                </li>
                                <li>
                                    The parties can alternatively use their own
                                    form of Transfer Agreement so long as it is
                                    approved by the Company.
                                </li>
                                <li>
                                    The parties should complete but not execute
                                    the Transfer Agreement(s) for the Proposed
                                    Transfer (in whatever form chosen by the
                                    parties) and submit the unexecuted Transfer
                                    Agreement(s) to the Board for its review.
                                </li>
                            </ol>
                        </li>
                        <li>
                            A Qualified Matching Service Compliance
                            Certification, to be executed by both the selling
                            and receiving members, certifying that each of the
                            parties complied with the QMS Rules in reaching
                            their agreement regarding the Proposed Transfer.
                        </li>
                    </ul>
                </li>
                <li>
                    If the Board approves the Proposed Transfer, the selling and
                    purchasing members, as applicable, should execute and submit
                    the following documents to the Company:
                    <ul>
                        <li>
                            The Transfer Agreements (to be executed by both
                            selling and purchasing members) to finalize the
                            Proposed Transfer between the parties;
                        </li>
                        <li>
                            A Joinder Agreement to be executed by the receiving
                            member to become a party to the Company&apos;s
                            Operating Agreement and the PPFS Operating Agreement
                            (in each case to the extent not already a party);
                            and
                        </li>
                        <li>
                            The Uniform Marketing Agreement with the Company, to
                            be executed by the receiving member (to the extent
                            not already a party).
                        </li>
                    </ul>
                </li>
                <li>
                    The Proposed Transfer will only be effective upon the
                    Company&apos;s receipt of each of these fully executed
                    agreements.
                </li>
            </ol>
            <h5>
                <b>FAQ</b>
            </h5>
            <u>Unit Transfer Notices</u>
            <ol>
                <li>
                    <b>Who should complete a Unit Transfer Notice?</b>
                    <div className="ml-3">
                        Answer: A Unit Transfer Notice should be completed and
                        returned to the Company by any member potentially
                        wishing to sell membership units of {clientName}, either
                        individually or together with units of {clientName}{" "}
                        Financial Services. Members who are interested in
                        posting indications of interest to buy units on the QMS
                        do not need to complete a Unit Transfer Notice.
                    </div>
                </li>
                <li>
                    <b>When should I complete a Unit Transfer Notice?</b>
                    <div className="ml-3">
                        Answer: A Unit Transfer Notice should be completed and
                        returned to the Company at or prior to the time you post
                        an indication of interest on the QMS to sell membership
                        units.
                    </div>
                </li>
                <li>
                    <b>
                        How do I return the Unit Transfer Notice to the Company?
                    </b>
                    <div className="ml-3">
                        Answer: A Unit Transfer Notice should be returned to the
                        Company by either email (Transfers@premiumpnut.com) or
                        by delivery to the following address: {clientName}, 311
                        Barrington Road, Douglas, Georgia 31535, Attention:
                        Transfer Liaison
                    </div>
                </li>
                <li>
                    <b>
                        Does completing a Unit Transfer Notice to the Company
                        obligate me to sell any units?
                    </b>
                    <div className="ml-3">
                        Answer: No. A Unit Transfer Notice does not bind or
                        commit you to selling any units. You will not be
                        contractually committed to any transaction until (i) you
                        enter into a separate binding agreement with an
                        interested buyer on mutually agreed upon, definitive
                        terms; and (ii) the transaction is approved by the
                        Company&apos;s board of directors.
                    </div>
                </li>
            </ol>
            <u>QMS Terms Of Use</u>
            <ol>
                <li>
                    <b>
                        What are the “Terms of Use” for the QMS platform that I
                        must acknowledge and accept in order to post indications
                        of interest to sell or purchase units?
                    </b>
                    <div className="ml-3 mt-2">
                        Answer: The QMS Terms of Use are as follows:
                        <br />
                        <br />
                        <b>Nature / Terms of Indications of Interest</b>
                        <ul>
                            <li>
                                Indications of interests posted on the QMS are
                                not binding.
                            </li>
                            <li>
                                Units can be posted for potential sale or
                                purchase on the QMS without committing to a
                                transaction.
                            </li>
                            <li>
                                The QMS post can display either:
                                <ul>
                                    <li className="p-0">
                                        A non-committal price quote for buying
                                        or selling units; or
                                    </li>
                                    <li className="p-0">
                                        An interest in selling or buying without
                                        a price quote.
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <b>QMS Transaction Rules</b>
                        <ul>
                            <li>
                                The QMS does not support automatic transactions.
                            </li>
                            <li>
                                To commit to a transfer of membership units,
                                participating members should communicate
                                independently, outside the QMS. Contact
                                information for each posting member is included
                                in the member&apos;s MPID link
                            </li>
                            <li>
                                No binding agreement to transfer membership
                                units can be entered into by participating
                                members until at least 15 days after the date
                                the applicable units were first posted for sale.
                            </li>
                            <li>
                                No transaction to transfer membership units can
                                be closed until at least 45 days after the date
                                the applicable units were first posted for sale.
                            </li>
                            <li>
                                Each post listing units must be removed within
                                120 days of posting. If the posted units were
                                not sold during that time, a new post for the
                                applicable units can&apos;t be made for 60 days
                                after removal.
                            </li>
                            <li>
                                All transfers or membership units are subject to
                                approval by the {clientName} Board of Directors.
                                If an agreement to transfer membership units is
                                reached between members, they should submit
                                &apos;Review Documents&apos; to the Company for
                                assessment of the proposed transaction by the
                                Board. The &apos;Review Documents&apos; are
                                available in the “Company Documents” section of
                                the QMS. No transfer between members may be
                                completed unless and until Board approval is
                                obtained.
                            </li>
                        </ul>
                    </div>
                </li>
            </ol>
            <u>Indications of Interest</u>
            <ol>
                <li>
                    <b>
                        If I own both PPL and PPFS Units, can I submit a listing
                        on the QMS to transfer them either individually or as a
                        unit?
                    </b>
                    <div className="ml-3">
                        Answer: Yes. Any member owning units of both Premium
                        Peanut LLC (“PPL”) and Premium Peanut Financial Services
                        LLC (“PPFS”) can post indications of interest on the QMS
                        to sell or purchase either (i) PPL units, on a
                        stand-alone basis; (ii) PPFS units, on a stand-alone
                        basis; or (iii) both PPL and PPFS units together.
                    </div>
                </li>
                <li>
                    <b>
                        Do I need to provide a price quote at which I am
                        interested in selling or purchasing units when I submit
                        an indication of interest on the QMS?
                    </b>
                    <div className="ml-3">
                        Answer: No. Members entering posts on the QMS to sell or
                        purchase units are permitted to display a price quote
                        for those units, but they are not required to do so.
                        Members can simply express an interest in selling or
                        buying units without an accompanying price quote. If a
                        member does display a price quote, that price quote is
                        non-binding and does not commit the posting member (or
                        any person) to buy or sell units at the quoted price. A
                        commitment to purchase at a given price can only be made
                        through a binding agreement reached between two members
                        through direct communications outside of the QMS.
                    </div>
                </li>
                <li>
                    <b>
                        Does submitting an indication of interest on the QMS
                        commit me to selling or purchasing units?
                    </b>
                    <div className="ml-3">
                        Answer: No. Entering an indication of interest on the
                        QMS to sell units or to purchase units does not commit
                        the posting member to any transaction. The post only
                        serves to provide an indication to other members that
                        the posting member may be interested in selling or
                        purchasing units, as applicable. Similarly, any price
                        quote provided on the QMS is not binding, and only
                        serves to provide an indication to other members of the
                        price at which the posting member may be interested in
                        selling or purchasing units. A commitment to purchase at
                        a given price can only be made through a binding
                        agreement reached between two members through direct
                        communications outside of the QMS.
                    </div>
                </li>
                <li>
                    <b>
                        Can I withdraw an indication of interest after posting
                        it on the QMS?
                    </b>
                    <div className="ml-3">
                        Answer: Yes. Any Indication of interest can be withdrawn
                        by the submitting member at any time by pressing the
                        “Cancel” button next to the applicable Indication of
                        Interest in the “My Indications of Interest” display
                        area on the QMS.
                    </div>
                </li>
                <li>
                    <b>
                        What should I do if I am interested in learning more
                        about an indication of interest to sell or purchase
                        units that haves been posted on the QMS?
                    </b>
                    <div className="ml-3">
                        Answer: You should communicate directly with the member
                        that submitted the indication of interest. Contact
                        information for that posting member is available on the
                        QMS by clicking on the link in the “MPID” column for the
                        applicable Indication of Interest in the “All Posted
                        Indications of Interest” display area on the QMS.
                    </div>
                </li>
                <li>
                    <b>
                        When can I enter into an agreement with a member to sell
                        or purchase units that were posted on the QMS?
                    </b>
                    <div className="ml-3">
                        Answer: You can enter into a binding agreement to sell
                        or purchase units that were posted for sale on the QMS
                        beginning 15 days after the units were first posted. You
                        may not enter into a binding agreement to purchase or
                        sell those units prior to that 15 day period. Any
                        agreement entered into before that time will not be
                        honored.
                    </div>
                </li>
                <li>
                    <b>
                        Once I have entered into a binding agreement, when can I
                        complete and close on the sale or purchase of units that
                        were posted on the QMS?
                    </b>
                    <div className="ml-3">
                        Answer: You can complete and close on the sale or
                        purchase of units that were posted for sale on the QMS
                        beginning 45 days after the units were first posted,
                        provided that the Board of Directors of Premium Peanut
                        and/or of Premium Peanut Financial Services has
                        previously approved the transfer. You may not close on
                        the purchase or sale of those units prior to that 45 day
                        period or prior to Board approval. Any transaction
                        purportedly closed prior to that time or prior to Board
                        approval will not be honored.
                    </div>
                </li>
                <li>
                    <b>
                        When can I enter into an agreement with a member to sell
                        or purchase units that were posted on the QMS?
                    </b>
                    <div className="ml-3">
                        Answer: You can enter into a binding agreement to sell
                        or purchase units that were posted for sale on the QMS
                        beginning 15 days after the units were first posted. You
                        may not enter into a binding agreement to purchase or
                        sell those units prior to that 15 day period. Any
                        agreement entered into before that time will not be
                        honored.
                    </div>
                </li>
                <li>
                    <b>
                        What documents do I need to complete and provide to the
                        Company in connection with any proposed transfer of
                        units?
                    </b>
                    <div className="ml-3">
                        Answer: If you and another member reach an agreement to
                        transfer membership units (the “Proposed Transfer”), you
                        should complete and submit the following documents to
                        the Company to assist the Board in assessing the
                        Proposed Transfer (the “<u>Review Documents</u>”). All
                        of the Review Documents are accessible in the “Company
                        Documents” section of the QMS:
                        <ul>
                            <li>
                                A QMS Agreement Notice to be completed by both
                                the transferring and receiving members,
                                providing the Company with notice that the
                                members have reached an agreement with respect
                                to the Proposed Transfer.
                            </li>
                            <li>
                                An “Investor Questionnaire” to be completed by
                                the proposed receiving member, to assist the
                                Board in determining whether to approve the
                                Proposed Transfer.
                            </li>
                            <li>
                                An “Investment Letter Agreement” to be completed
                                by the proposed receiving member, in which that
                                member would make certain representations,
                                warranties and covenants to the Company in
                                connection with the Proposed Transfer.
                            </li>
                            <li>
                                A “Seller Questionnaire” to be completed by the
                                proposed transferring member, to assist the
                                Board in determining whether to approve the
                                Proposed Transfer.
                            </li>
                            <li>
                                One or more agreements (the “
                                <u>Transfer Agreements</u>”) though which the
                                Units would be transferred from the selling
                                member to the receiving member.
                                <ol type="i">
                                    <li>
                                        For the Transfer Agreements, the parties
                                        can use the template Assignment of LLC
                                        Producer Units (to be completed by the
                                        selling member) and Acceptance of LLC
                                        Producer Units (to be completed by the
                                        receiving member) that have been
                                        prepared by the Company (and are
                                        included in the Company Documents)
                                    </li>
                                    <li>
                                        The parties can alternatively use their
                                        own form of Transfer Agreement so long
                                        as it is approved by the Company.
                                    </li>
                                    <li>
                                        The parties should complete but not
                                        execute the Transfer Agreement(s) for
                                        the Proposed Transfer (in whatever form
                                        chosen by the parties) and submit the
                                        unexecuted Transfer Agreement(s) to the
                                        Board for its review.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                A Qualified Matching Service Compliance
                                Certification, to be executed by both the
                                selling and receiving members, certifying that
                                each of the parties complied with the QMS Rules
                                in reaching their agreement regarding the
                                Proposed Transfer.
                            </li>
                        </ul>
                    </div>
                </li>
            </ol>
        </div>
    );
};

export default InstructionsPage;
