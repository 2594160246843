/* eslint-disable react/display-name */
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

import { memo } from "react";
import { Button, ButtonGroup, Row } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
    Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

import { Loading } from "../../../Loading";

export const SymbolStatusTable = memo(
    ({ symbols, showStatusPopup, loading = false }) => {
        const columns = [
            {
                dataField: "security",
                text: "Security",
                formatter: (rowContent, { security }) => rowContent || security,
                sort: true,
            },
            {
                dataField: "action",
                text: "Action",
                isDummyField: true,
                // eslint-disable-next-line no-unused-vars
                formatter: (rowContent, row) => (
                    <ButtonGroup>
                        <Button
                            size="sm"
                            onClick={() => showStatusPopup(row, "Open")}
                        >
                            Unhalt Symbol
                        </Button>
                    </ButtonGroup>
                ),
            },
        ];

        const { SearchBar } = Search;

        return loading ? (
            <Loading />
        ) : (
            <ToolkitProvider
                keyField="security"
                data={symbols}
                columns={columns}
                search
            >
                {(props) => (
                    <>
                        <Row noGutters>
                            <SearchBar {...props.searchProps} />
                        </Row>
                        <BootstrapTable
                            {...props.baseProps}
                            pagination={paginationFactory()}
                            noDataIndication="No symbols available."
                            defaultSorted={[
                                {
                                    dataField: "security",
                                    order: "asc",
                                },
                            ]}
                            striped
                            wrapperClasses="size-sm"
                        />
                    </>
                )}
            </ToolkitProvider>
        );
    }
);
