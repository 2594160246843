/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { memo, Suspense, useEffect } from "react";
import { useState } from "react";
import {
    Col,
    Dropdown,
    FormCheck,
    Image,
    ListGroup,
    Row,
} from "react-bootstrap";
import {
    AiFillCheckCircle,
    AiFillExclamationCircle,
    AiTwotoneSetting,
} from "react-icons/ai";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

import { full_logo, small_logo } from "../config";

export const LogoLink = ({ children, isURL, path }) =>
    isURL ? (
        <a href={path}>{children}</a>
    ) : (
        <NavLink to={path}>{children}</NavLink>
    );

export const ParentSidebarItem = ({
    path,
    name,
    onboarding = false,
    completed = false,
}) => (
    <NavLink to={path} className={"list-item-nodropdown"}>
        {onboarding &&
            (completed ? (
                <AiFillCheckCircle className="registration-icon confirmed" />
            ) : (
                <AiFillExclamationCircle className="registration-icon pending" />
            ))}
        <div className={onboarding ? "registration-checklist-name" : ""}>
            {name}
        </div>
    </NavLink>
);

// eslint-disable-next-line react/display-name
export const SidebarItem = memo(
    ({ path, name, className = "", onboarding, completed }) => (
        <NavLink
            //  If path is the same as current path do not navigate
            onClick={(e) => {
                if (window.location.pathname === path) e.preventDefault();
            }}
            to={path}
            className={`list-item-title ${className} ${
                onboarding && "onboarding-group-item"
            }`}
        >
            <li>
                <div> {name}</div>
                <div>
                    {onboarding && (
                        <Suspense
                            fallback={
                                <AiFillExclamationCircle className="registration-icon pending" />
                            }
                        >
                            {completed ? (
                                <AiFillCheckCircle className="registration-icon confirmed" />
                            ) : (
                                <AiFillExclamationCircle className="registration-icon pending" />
                            )}
                        </Suspense>
                    )}
                </div>
            </li>
        </NavLink>
    )
);

export const SidebarCheckboxItem = ({
    name,
    className = "",
    value,
    onChange,
}) => (
    <div className={`list-item-title list-item-checkbox ${className}`}>
        <li>
            <div>{name}</div>
            <FormCheck
                defaultChecked={value}
                onChange={({ target: { checked } }) => onChange(checked)}
            />
        </li>
    </div>
);

export const DropdownMenuWrapper = ({ name, children, className = "" }) => (
    <ListGroup.Item className="list-item">
        <Dropdown className="list-accordion">
            <Dropdown.Toggle style={{ width: "100%" }}>
                <Row
                    noGutters
                    style={{ whiteSpace: "normal", wordWrap: "break-word" }}
                >
                    {name}
                </Row>
            </Dropdown.Toggle>

            <Dropdown.Menu
                className={"list-accordion-collapse" + ` ${className}`}
            >
                <ul>{children}</ul>
            </Dropdown.Menu>
        </Dropdown>
    </ListGroup.Item>
);

export const MenuListWrapper = ({
    name,
    children,
    className = "",
    groupClassName = "",
    path,
    onboarding,
    completed,
}) => (
    <ListGroup.Item className={`list-item portal ${groupClassName}`}>
        <NavLink to={path} className="list-item-nodropdown d-inline-flex py-0">
            {onboarding &&
                (completed ? (
                    <AiFillCheckCircle className="registration-icon confirmed" />
                ) : (
                    <AiFillExclamationCircle className="registration-icon pending" />
                ))}
            <div className={onboarding ? "registration-checklist-name" : ""}>
                {name}
            </div>
        </NavLink>

        <div className={"list-accordion-collapse" + ` ${className}`}>
            <ul>{children}</ul>
        </div>
    </ListGroup.Item>
);

export const SidebarWrappper = ({
    sidebarName,
    homePath = "/",
    headerName = "SYSTEM",
    headerClass = "",
    children,
    style,
    className,
    hideCollapse = false,
    settingsIconEnabled = false,
    settingsOnClick = () => {},
    settingsBottomLinkEnabled = false,
    settingsBottomLink = "/portal/configure",
    settingsBottomLinkLabel = "Configure",
    isURL = false,
    bottomQuickLinkName = "",
    bottomQuickLinkPath = "/portal",
}) => {
    const [collapseSidebar, setCollapseSidebar] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    let sidebarToggle;
    if (collapseSidebar) {
        sidebarToggle = "collapsed-sidebar";
    }

    const handleToggleCollapseSidebar = () => {
        setCollapseSidebar(!collapseSidebar);
    };

    const renderSidebarToggleButton = () => (
        <Row noGutters className="sidebar-collapse-toggle-row pb-3">
            <div
                onClick={handleToggleCollapseSidebar}
                className="sidebar-collapse-toggle-aura"
                style={
                    collapseSidebar && window.innerWidth <= 768
                        ? {
                              background:
                                  style?.background ||
                                  "var(--SidebarBackground)",
                          }
                        : {}
                }
            >
                {collapseSidebar ? (
                    <FiChevronRight className="sidebar-collapse-toggle-button" />
                ) : (
                    <FiChevronLeft className="sidebar-collapse-toggle-button" />
                )}
            </div>
        </Row>
    );

    const renderSettingButton = () => (
        <Row noGutters className="sidebar-collapse-toggle-row">
            <div
                onClick={settingsOnClick}
                className="sidebar-collapse-toggle-aura"
            >
                <AiTwotoneSetting className="sidebar-collapse-toggle-button" />
            </div>
        </Row>
    );

    useEffect(() => {
        if (
            location.pathname.startsWith("/portal/trade") ||
            location.pathname.startsWith("/portal/configure")
        )
            setCollapseSidebar(true);
        if (window.innerWidth <= 768) setCollapseSidebar(true);
    }, [location.pathname]);

    return (
        <div
            className={`${sidebarName} sidebar h-100 ${className}`}
            style={style}
            id={sidebarToggle}
        >
            <Col>
                <Row noGutters className="logo-row">
                    <LogoLink path={homePath} isURL={isURL}>
                        <Image
                            className="logo"
                            src={!collapseSidebar ? full_logo : small_logo}
                            alt="logo"
                        />
                    </LogoLink>
                </Row>
                <Row noGutters>
                    <div className={`system-header ${headerClass}`}>
                        {headerName}
                    </div>
                </Row>
                <ListGroup variant="flush" className="list-group">
                    {children}
                </ListGroup>
                {bottomQuickLinkName && (
                    <>
                        <hr className="sidebar-divider" />

                        <div
                            className="size-sm sidebar-bottom-quicklink"
                            onClick={() => {
                                navigate(bottomQuickLinkPath);
                            }}
                        >
                            {bottomQuickLinkName}
                        </div>
                    </>
                )}
                <hr className="sidebar-divider" />

                {!hideCollapse && renderSidebarToggleButton()}
                {settingsIconEnabled && renderSettingButton()}
                {settingsBottomLinkEnabled && (
                    <div
                        className="size-sm sidebar-bottom-link"
                        onClick={
                            settingsBottomLink === "/portal/trade"
                                ? () => window.history.go(-1)
                                : () => navigate(settingsBottomLink)
                        }
                    >
                        {settingsBottomLinkLabel}
                    </div>
                )}
            </Col>
        </div>
    );
};

export const SidebarNoPathItem = ({
    name,
    className = "",
    onClick,
    disabled,
}) => (
    <div
        className={`list-item-nodropdown no-select  ${className} ${
            disabled ? "text-muted cursor-not-allowed" : "cursor-pointer"
        }`}
        onClick={disabled ? () => {} : onClick}
    >
        {name}
    </div>
);
