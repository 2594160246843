/* eslint-disable react/display-name */
import { memo } from "react";
import { Button, Modal } from "react-bootstrap";

export const AttachmentDetailsModal = memo(
    ({ show, handleClose, attachment, deleteAttachment }) => (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h6>Name</h6>
                <p className="size-sm ml-1">{attachment.filename}</p>
                <h6>Description</h6>
                <p className="size-sm ml-1">{attachment.description}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    size="sm"
                    variant="danger"
                    onClick={() => deleteAttachment(attachment)}
                >
                    Delete Attachment
                </Button>
                <Button size="sm" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
);
