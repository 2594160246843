/* eslint-disable react/display-name */
import { memo, useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";

import { auctionEnabled, hideHaltedSymbols } from "../../../../config";

export const SymbolStatus = memo(
    ({ status = "Open", updateStatus, isHidden }) => {
        const [newStatus, setNewStatus] = useState("O");

        useEffect(
            () =>
                setNewStatus(
                    status === "Normal"
                        ? "O"
                        : isHidden
                        ? "HH"
                        : status.charAt(0)
                ),
            [status, isHidden]
        );

        return (
            <Col>
                <Row noGutters>
                    <Col className="mr-1 ">
                        <Form.Control
                            as="select"
                            value={newStatus}
                            className="custom-select custom-select-sm"
                            onChange={(e) => setNewStatus(e.target.value)}
                        >
                            <option value="O">Open</option>
                            <option value="P">Pre-Open</option>
                            <option value="H">Halt</option>
                            {hideHaltedSymbols && (
                                <option value="HH">Halt / Hidden</option>
                            )}
                            {auctionEnabled && (
                                <option value="R">Permanent Halt</option>
                            )}
                        </Form.Control>
                    </Col>
                    <Col className="col-lg-auto">
                        <Button
                            className="px-5"
                            onClick={() => updateStatus(newStatus)}
                            size="sm"
                        >
                            Set
                        </Button>
                    </Col>
                </Row>
            </Col>
        );
    }
);
