import {
    validateOnlyString,
    validatePhoneNumber,
    validateSSN,
} from "../../helpers/validate";
import { InputTemplate } from "./InputTemplate";

export const StringInput = ({ field, onChange, classes, disabled }) => {
    const validationFunc = (e) => {
        switch (field.fieldName) {
            case "Phone":
            case "Primary Phone":
                return e?.length !== 10 || validatePhoneNumber(e);
            case "City":
                return validateOnlyString(e);
            case "State":
                return e?.length !== 2 || validateOnlyString(e);
            case "SSN":
                return validateSSN(e);
            default:
                break;
        }
    };

    return (
        <InputTemplate
            field={field}
            onChange={onChange}
            classes={classes}
            disabled={disabled}
            onValidate={validationFunc}
        />
    );
};
