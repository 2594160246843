import { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { GoBackModal } from "../../../components/admin/asset-builder/GoBackModal";
import { CreateFormForm } from "../../../components/admin/onboarding/CreateFormForm";
import { FullScreenLoading } from "../../../components/Loading";
import {
    getOnboardingForms,
    updateOnboardingForms,
} from "../../../helpers/onboarding";
import { useStore } from "../../../Store";
import { useSocket } from "../../../Websocket";

const EditFormPage = () => {
    const ws = useSocket();
    const { state, dispatch } = useStore();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const {
        onboarding: { forms },
    } = state;

    const { form: formId } = useParams();
    const [existingForm, setExistingForm] = useState({});

    const editForm = (newForm) => {
        const filteredForms = forms.filter((frm) => frm.id !== existingForm.id);

        const formattedForm = {
            ...newForm,
            updatedAt: new Date().getTime(),
            groups: newForm.groups.map((group) => ({
                ...group,
                fields: group?.fields?.map((fld) => {
                    fld.showFieldDescription = undefined;
                    return fld;
                }),
            })),
        };

        return updateOnboardingForms(ws, dispatch, {
            forms: filteredForms.concat([formattedForm]),
        }).then(() => {
            toast.success("Form successfully edited");

            navigate("/admin/forms");
        });
    };

    useEffect(() => {
        if (forms.length > 0) {
            const fm = JSON.parse(
                JSON.stringify(forms.find((frm) => frm.id === formId))
            );

            setExistingForm(fm);
        }
    }, [forms, formId]);

    useEffect(() => {
        if (forms.length === 0) {
            getOnboardingForms(ws, dispatch).then(() => setLoading(false));
        } else {
            setLoading(false);
        }

        return () => setLoading(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [showGoBackModal, setShowGoBackModal] = useState(false);
    const handleHideGoBackModal = () => setShowGoBackModal(false);
    const handleShowGoBackModal = () => setShowGoBackModal(true);

    return (
        <div className="m-4">
            <Row noGutters className="forms-header">
                <AiOutlineArrowLeft
                    className="asset-back-button"
                    onClick={handleShowGoBackModal}
                />
                <h4>Create Form</h4>
            </Row>
            <GoBackModal
                show={showGoBackModal}
                handleClose={handleHideGoBackModal}
                handleConfirm={() => {
                    handleHideGoBackModal();
                    navigate("/admin/forms");
                }}
            />
            {loading ? (
                <FullScreenLoading />
            ) : (
                <CreateFormForm
                    existingForm={existingForm}
                    submitData={editForm}
                    type="edit"
                />
            )}
        </div>
    );
};

export default EditFormPage;
