import * as CONSTANTS from "../constants/trade";

export const subscribeToSymbols = () => ({
    type: CONSTANTS.SUBSCRIBED_TO_SYMBOLS,
});

export const addOrder = (order) => ({
    type: CONSTANTS.ADD_ORDER,
    order,
});

export const cancelOrder = (order) => ({
    type: CONSTANTS.CANCEL_ORDER,
    order,
});

export const rejectOrder = (order) => ({
    type: CONSTANTS.REJECT_ORDER,
    order,
});

export const executeOrder = (order) => ({
    type: CONSTANTS.EXECUTE_ORDER,
    order,
});

export const queryTrade = (data) => ({
    type: CONSTANTS.QUERY_TRADE,
    data,
});

export const reset = () => ({
    type: CONSTANTS.RESET,
});

export const addBook = (book) => ({
    type: CONSTANTS.ADD_BOOK,
    book,
});

export const removeBook = (key) => ({
    type: CONSTANTS.REMOVE_BOOK,
    key,
});

export const resetBooks = () => ({
    type: CONSTANTS.RESET_BOOKS,
});

export const addPosition = (position) => ({
    type: CONSTANTS.ADD_POSITION,
    position,
});

export const addTradeSymbols = (symbols) => ({
    type: CONSTANTS.ADD_TRADE_SYMBOLS,
    symbols,
});

export const addPrevClosePrice = (
    security,
    prevcloseprice,
    high,
    low,
    last
) => ({
    type: CONSTANTS.ADD_SYMBOL_PREV_CLOSE_PRICE,
    security,
    prevcloseprice,
    high,
    low,
    last,
});

export const addBidOffer = (security, bid, ask) => ({
    type: CONSTANTS.ADD_SYMBOL_BID_OFFER,
    security,
    bid,
    ask,
});

export const activeOrder = (order) => ({
    type: CONSTANTS.ACTIVE_ORDER,
    order,
});

export const clearActiveOrder = () => ({
    type: CONSTANTS.CLEAR_ACTIVE_ORDER,
});

export const subscribedSecurity = (security) => ({
    type: CONSTANTS.SUBSCRIBED_SECURITY,
    security,
});

export const addTradeHistory = (history, security) => ({
    type: CONSTANTS.ADD_TRADE_HISTORY,
    history,
    security,
});

export const queryMultipleOrders = (orders) => ({
    type: CONSTANTS.TRADE_QUERY_MULTIPLE_ORDERS,
    orders,
});

export const queryCancels = (cancels) => ({
    type: CONSTANTS.TRADE_QUERY_CANCELS,
    cancels,
});

export const queryActivityTrade = (fills) => ({
    type: CONSTANTS.TRADE_QUERY_ACTIVITY_TRADE,
    fills,
});

export const queryDeposit = (balances) => ({
    type: CONSTANTS.TRADE_QUERY_DEPOSIT,
    balances,
});

export const queryPosition = (positions) => ({
    type: CONSTANTS.TRADE_QUERY_POSITION,
    positions,
});

export const queryLsHistory = (lsHistory) => ({
    type: CONSTANTS.QUERY_LS_HISTORY,
    lsHistory,
});

export const addLsHistory = (data) => ({
    type: CONSTANTS.ADD_LS_HISTORY,
    data,
});

export const setCurrentSymbol = (symbol) => ({
    type: CONSTANTS.SET_CURRENT_SYMBOL,
    symbol,
});

export const updateSymbolStatus = (symbols) => ({
    type: CONSTANTS.UPDATE_SYMBOL_STATUS,
    symbols,
});

export const updateLayout = (layout) => ({
    type: CONSTANTS.UPDATE_LAYOUT,
    layout,
});

export const removeComponentFromLayout = (componentName) => ({
    type: CONSTANTS.REMOVE_COMPONENT_FROM_LAYOUT,
    componentName,
});

export const updateLayoutConfiguration = (newConfig) => ({
    type: CONSTANTS.UPDATE_LAYOUT_CONFIGURATION,
    newConfig,
});

export const resetLayoutConfiguration = () => ({
    type: CONSTANTS.RESET_LAYOUT_CONFIGURATION,
});

export const addAuctionInformation = (auctionInformation) => ({
    type: CONSTANTS.ADD_AUCTION_INFORMATION,
    auctionInformation,
});

export const addPortalAuctionInformation = (auctionInformation) => ({
    type: CONSTANTS.ADD_PORTAL_AUCTION_INFORMATION,
    auctionInformation,
});

export const changeSubscribedBookSymbol = (previousSymbol, newSymbol) => ({
    type: CONSTANTS.CHANGE_SUBSCRIBED_BOOK_SYMBOL,
    previousSymbol,
    newSymbol,
});