import { Fragment, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { AiOutlineDelete } from "react-icons/ai";

import { Fields } from "../Fields";
import { ConfigureModalTemplate } from "./ConfigureModalTemplate";

const defaultFieldConfig = {
    fieldName: "",
    fieldType: "String",
    required: false,
};

export const BranchConfigureModal = ({
    show = false,
    handleSave,
    existingBranches = [],
    rejected = [],
    handleSaveRejected,
}) => {
    const [branches, setBranches] = useState(existingBranches);
    const [hideBranchModal, setHideBranchModal] = useState(false);

    const updateRejectedArray = (checked, i) => {
        const newRejected = rejected;

        if (checked && branches[i].name.length > 0) {
            newRejected.splice(i, 0, branches[i].name);
        } else {
            const index = newRejected.indexOf(branches[i].name);

            if (index > -1) {
                newRejected.splice(i, 1);
            }
        }

        handleSaveRejected(newRejected);
    };

    return (
        <ConfigureModalTemplate
            className={hideBranchModal && "d-none"}
            size="lg"
            show={show}
            handleConfirm={() =>
                setBranches([...branches, { name: "", data: [] }])
            }
            handleClose={() => handleSave("subfields", branches)}
            confirmText="Add Branch"
            body={branches.map((branch, i) => {
                const rejectExists = rejected.find(
                    (rej) => rej === branch.name
                );

                return (
                    <Fragment key={i}>
                        <Row noGutters className="mx-2">
                            <Form.Label className="size-sm">
                                Branch Name
                            </Form.Label>
                        </Row>
                        <Row noGutters className="mx-2">
                            <Col md={6}>
                                <Form.Control
                                    size="sm"
                                    value={branch.name}
                                    onChange={({ target: { value } }) => {
                                        const newBranches =
                                            Array.from(branches);
                                        newBranches[i].name = value;

                                        setBranches(newBranches);
                                    }}
                                    disabled={rejectExists}
                                />
                            </Col>
                            <Col
                                md={4}
                                className="size-sm mt-1 ml-2 text-right"
                            >
                                <div className="d-inline-flex d-flex">
                                    <div className="mr-3">
                                        Reject this value?
                                    </div>
                                    <Form.Check
                                        type="checkbox"
                                        defaultChecked={rejectExists}
                                        disabled={branch.name.length === 0}
                                        onChange={({ target: { checked } }) =>
                                            updateRejectedArray(checked, i)
                                        }
                                    />
                                </div>
                            </Col>
                            <Col className="text-right">
                                <AiOutlineDelete
                                    className="cursor-pointer"
                                    onClick={() => {
                                        handleSaveRejected(
                                            rejected.filter(
                                                (item) => item !== branch.name
                                            )
                                        );

                                        setBranches(
                                            branches.filter(
                                                (item, j) => i !== j
                                            )
                                        );
                                    }}
                                />
                            </Col>
                        </Row>
                        <div
                            className={`asset-section mx-2 my-2 pt-2 ${
                                branch.data.length === 0 ? "pb-0" : "pt-3"
                            }`}
                        >
                            <div className="size-sm d-flex justify-content-between">
                                <div className="text-dark mt-1">Subfields</div>
                                <Button
                                    size="sm"
                                    className={
                                        branch.data.length === 0 ? "mb-2" : ""
                                    }
                                    onClick={() => {
                                        const newBranches =
                                            Array.from(branches);
                                        newBranches[i].data.push(
                                            defaultFieldConfig
                                        );

                                        setBranches(newBranches);
                                    }}
                                >
                                    Add Subfield
                                </Button>
                            </div>
                            <Fields
                                section={branch}
                                setSection={(data) => {
                                    const newBranches = Array.from(branches);

                                    newBranches[i] = data;

                                    setBranches(newBranches);
                                }}
                                storeKey={"data"}
                                setParentModal={setHideBranchModal}
                                onboarding
                                typeBranch
                            />
                        </div>
                        <hr />
                    </Fragment>
                );
            })}
        />
    );
};
