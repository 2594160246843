import * as CONSTANTS from "../constants/onboarding";

export const queryOnboardingForms = (forms) => ({
    type: CONSTANTS.QUERY_ONBOARDING_FORMS,
    forms,
});

export const queryOnboardingSubmissions = (submissions) => ({
    type: CONSTANTS.QUERY_ONBOARDING_SUBMISSIONS,
    submissions,
});

export const queryUserRoles = (roles) => ({
    type: CONSTANTS.QUERY_USER_ROLES,
    roles,
});

export const queryExistingAccounts = (accounts) => ({
    type: CONSTANTS.QUERY_EXISTING_ACCOUNTS,
    accounts,
});

export const clearState = () => ({
    type: CONSTANTS.CLEAR_STATE,
});
