/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Row } from "react-bootstrap";

import { showTradePresets } from "../../config";
import { storeOptions } from "../../helpers/display-options";
import { convertStylingVar } from "../../helpers/other";
import { useStore } from "../../Store";
import { useSocket } from "../../Websocket";
import { UserAccountDropdown } from "../UserAccountDropdown";

export const PortalTopbar = () => {
    const ws = useSocket();
    const { state, dispatch } = useStore();

    const {
        trade: {
            layoutConfigurationOptions: { styling },
        },
    } = state;

    const selectedPage =
        state.trade.layoutConfigurationOptions?.pageSelected || "trade";
    const isTradeScreenConfigure =
        window.location.pathname.includes("/portal/configure");
    const isTradeScreen = window.location.pathname.includes("/portal/trade");

    // const setLayout = (layoutName) =>
    //     layoutName !== selectedLayout &&
    //     storeOptions(
    //         ws,
    //         dispatch,
    //         state.account.account?.data,
    //         "trade_preferences",
    //         "trade_screen_configuration",
    //         {
    //             ...state.trade.layoutConfigurationOptions,
    //             layoutSelected: layoutName,
    //         },
    //         undefined,
    //         true
    //     ).then(() => {});

    const setSelectedPage = (pageName) =>
        pageName !== selectedPage &&
        storeOptions(
            ws,
            dispatch,
            state.account.account?.data,
            "trade_preferences",
            "trade_screen_configuration",
            {
                ...state.trade.layoutConfigurationOptions,
                pageSelected: pageName,
            },
            undefined,
            true
        ).then(() => {});

    return (
        <div
            className={"topbar"}
            style={{
                background:
                    (isTradeScreen || isTradeScreenConfigure) &&
                    convertStylingVar(styling.primaryBackgroundColor),
            }}
        >
            <Row noGutters className="topbar-row">
                <div className="text-white pl-3 size-sm ">
                    {isTradeScreen ? (
                        <>
                            View:
                            <span
                                className={`ml-2 ${
                                    selectedPage === "trade"
                                        ? "font-weight-bold"
                                        : "text-decoration-underline text-secondary cursor-pointer"
                                }`}
                                onClick={() => setSelectedPage("trade")}
                            >
                                Trading
                            </span>
                            <span
                                className={`ml-2 ${
                                    selectedPage === "market-watch"
                                        ? "font-weight-bold"
                                        : "text-decoration-underline text-secondary cursor-pointer"
                                }`}
                                onClick={() => setSelectedPage("market-watch")}
                            >
                                Market Watch
                            </span>
                            {showTradePresets && (
                                <>
                                    <span
                                        className={`ml-2 ${
                                            selectedPage === "preset1"
                                                ? "font-weight-bold"
                                                : "text-decoration-underline text-secondary cursor-pointer"
                                        }`}
                                        onClick={() =>
                                            setSelectedPage("preset1")
                                        }
                                    >
                                        Preset 1
                                    </span>
                                    <span
                                        className={`ml-2 ${
                                            selectedPage === "preset2"
                                                ? "font-weight-bold"
                                                : "text-decoration-underline text-secondary cursor-pointer"
                                        }`}
                                        onClick={() =>
                                            setSelectedPage("preset2")
                                        }
                                    >
                                        Preset 2
                                    </span>
                                    <span
                                        className={`ml-2 ${
                                            selectedPage === "preset3"
                                                ? "font-weight-bold"
                                                : "text-decoration-underline text-secondary cursor-pointer"
                                        }`}
                                        onClick={() =>
                                            setSelectedPage("preset3")
                                        }
                                    >
                                        Preset 3
                                    </span>
                                </>
                            )}
                        </>
                    ) : (
                        <></>
                    )}
                </div>
                <UserAccountDropdown />
            </Row>
        </div>
    );
};
