import { Col, Container } from "react-bootstrap";

import { LandingSidebar } from "../../components/assets/LandingSidebar";
import { LandingTopbar } from "../../components/assets/LandingTopbar";

const AssetsLayout = ({ children }) => (
    <>
        <Col className="flex-grow-0">
            <LandingSidebar />
        </Col>
        <Col className="d-flex flex-column overflow-hidden">
            <LandingTopbar />
            <div className="content-pane">
                <Container fluid>{children}</Container>
            </div>
        </Col>
    </>
);

export default AssetsLayout;
