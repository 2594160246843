/* eslint-disable react/display-name */
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

import { memo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

import { Loading } from "../Loading";

export const TableRenderer = memo(
    ({
        keyField,
        sortBy = keyField,
        sortOrder = "asc",
        columns,
        data,
        noDataIndication,
        pagination,
        striped = false,
        bordered = true,
        hover = true,
        rowEvents = {},
        rowClasses = undefined,
        rowStyle = undefined,
        wrapperClasses = undefined,
        expandRow = undefined,
        loading = false,
    }) => {
        return loading ? (
            <Loading />
        ) : (
            <BootstrapTable
                keyField={keyField}
                data={data}
                columns={columns}
                noDataIndication={noDataIndication}
                pagination={pagination ? paginationFactory() : undefined}
                striped={striped}
                bordered={bordered}
                hover={hover}
                rowEvents={rowEvents}
                rowClasses={rowClasses}
                rowStyle={rowStyle}
                wrapperClasses={wrapperClasses}
                expandRow={expandRow}
                defaultSorted={[
                    {
                        dataField: sortBy,
                        order: sortOrder,
                    },
                ]}
            />
        );
    }
);
