import { useEffect, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import { useParams } from "react-router-dom";

import {
    getRegistrationSubmissions,
    validateFormGroup,
} from "../../helpers/onboarding";
import { useStore } from "../../Store";
import { useSocket } from "../../Websocket";
import {
    MenuListWrapper,
    ParentSidebarItem,
    SidebarItem,
    SidebarWrappper,
} from "../SidebarWrapper";

export const OnboardingSidebar = () => {
    const ws = useSocket();
    const { state, dispatch } = useStore();

    const {
        onboarding: { submissions },
        account: {
            account: { data: loggedInUser },
        },
    } = state;

    const emailConfirmed = loggedInUser?.attr?.email_confirmed === "Y";
    const [progressVariant, setProgressVariant] = useState("danger");
    const [progress, setProgress] = useState();
    const { formId } = useParams();

    const determineProgress = () => {
        const totalItems = 1 + submissions.length;
        let totalCompleted = 0;

        if (emailConfirmed) totalCompleted += 1;

        for (let i = 0; i < submissions.length; i++) {
            const submission = submissions[i];

            if (submission.status !== "PENDING") totalCompleted += 1;
        }

        return totalCompleted === 0
            ? "0"
            : `${Math.round((totalCompleted / totalItems) * 100)}`;
    };

    useEffect(() => {
        setProgressVariant(
            parseInt(progress) < 50
                ? "danger"
                : parseInt(progress) === 100
                ? "success"
                : "warning"
        );
    }, [progress]);

    useEffect(
        () => {
            submissions.length > 0 && setProgress(determineProgress());
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [submissions, loggedInUser]
    );

    useEffect(() => {
        if (submissions.length === 0) {
            getRegistrationSubmissions(
                ws,
                dispatch,
                loggedInUser?.userid || loggedInUser?.userId
            );
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [renderedGroups, setRenderedGroups] = useState([]);
    const [completedGroups, setCompletedGroups] = useState({});

    useEffect(() => {
        const filteredSubmissions = {};

        const filterGroups = async () => {
            for (const submission of submissions) {
                const filteredGroups =
                    await submission.data.form?.groups?.reduce(
                        async (prev, group, i) => {
                            const curr = await prev;
                            const [isValid] = await validateFormGroup(
                                group,
                                i,
                                true
                            );

                            if (isValid && group?.visited) curr.push(i);

                            return curr;
                        },
                        Promise.resolve([])
                    );

                filteredSubmissions[submission.submission_id] = filteredGroups;
            }

            setCompletedGroups(filteredSubmissions);
        };

        filterGroups();

        return () => setCompletedGroups({});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submissions]);

    useEffect(() => {
        setRenderedGroups(
            submissions.find((s) => s.submission_id === formId)?.data.form
                .groups
        );

        return () => setRenderedGroups([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formId, submissions]);

    return (
        <SidebarWrappper
            homePath="/"
            headerName="REGISTRATION CHECKLIST"
            sidebarName="portal-sidebar onboarding-sidebar"
            style={{
                color: "var(--OnboardingSidebar-color)",
                background: "var(--OnboardingSidebar-background)",
            }}
            // hideCollapse
        >
            <ParentSidebarItem
                name="Confirm your email"
                path="/"
                onboarding
                completed={emailConfirmed}
            />
            {submissions.map((submission, i) =>
                "groups" in submission.data.form &&
                !(
                    submission.data.form.groups.length === 1 &&
                    !submission.data.form.groups[0].name
                ) &&
                formId === submission.submission_id ? (
                    <MenuListWrapper
                        key={i}
                        onboarding
                        completed={submission.status !== "PENDING"}
                        path={`/forms/${submission.submission_id}`}
                        name={submission.data.form.name}
                        groupClassName="pb-0"
                    >
                        {renderedGroups?.map((group, j) => (
                            <SidebarItem
                                key={j}
                                name={group?.name || `Group ${j}`}
                                onboarding
                                path={`/forms/${submission.submission_id}?grp=${j}`}
                                completed={
                                    completedGroups?.[
                                        submission.submission_id
                                    ]?.includes(j) && group?.visited
                                }
                            />
                        ))}
                    </MenuListWrapper>
                ) : (
                    <ParentSidebarItem
                        name={submission.data.form.name}
                        path={`/forms/${submission.submission_id}`}
                        key={i}
                        onboarding
                        completed={submission.status !== "PENDING"}
                    />
                )
            )}
            <br />
            <div className="registration-progress-bar-container ">
                <div className="progress-bar-label">PROGRESS</div>
                <ProgressBar
                    striped
                    variant={progressVariant}
                    now={progress}
                    label={`${progress}%`}
                    className="registration-progress-bar"
                />
            </div>
        </SidebarWrappper>
    );
};
