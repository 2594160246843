import { useEffect, useState } from "react";
import { Row } from "react-bootstrap";

import { auctionEnabled } from "../../../config";
import { convertStylingVar } from "../../../helpers/other";
import { mergelsHistoryAnditradeLsHistory } from "../../../helpers/trade";
import { useStore } from "../../../Store";
import { useSubStore } from "../../../SubscriptionData";
import { StatisticItem } from "./StatisticItem";

// eslint-disable-next-line no-unused-vars
export const MarketStatistic = ({ layoutKey }) => {
    const { state } = useStore();

    const { layoutConfigurationOptions } = state.trade;

    const { currentSymbol, lsHistory, auctionInformation } = state.trade;
    const fontColor = convertStylingVar(
        layoutConfigurationOptions.styling.fontColor
    );

    const subscriptionContext = useSubStore();
    const { refChangeEmitter } = subscriptionContext;

    // eslint-disable-next-line no-unused-vars
    const [timedData, setTimedData] = useState([]);
    const [lastQty, setLastQty] = useState("N/A");
    const [dayQty, setdayQty] = useState("N/A");

    const [symbolAuctionInfo, setSymbolAuctionInfo] = useState({});

    const [itradeLsHistory, setitradeLsHistory] = useState(
        subscriptionContext.tradeDataRef.current
    );

    useEffect(() => {
        const onTradeChange = (updatedData) => {
            setitradeLsHistory(updatedData);
        };

        refChangeEmitter.on("tradeChange", onTradeChange);

        return () => {
            refChangeEmitter.removeListener("tradeChange", onTradeChange);
        };
    }, [refChangeEmitter]);

    useEffect(() => {
        setSymbolAuctionInfo(
            auctionInformation.find(
                (elem) => elem.security === currentSymbol.security
            )
        );
    }, [auctionInformation, currentSymbol]);

    useEffect(() => {
        const beginingOfToday = new Date().setHours(0, 0, 0, 0);
        const mergedTrades = mergelsHistoryAnditradeLsHistory(
            lsHistory,
            itradeLsHistory[currentSymbol?.security] || []
        );
        setTimedData(
            mergedTrades.filter((ele) => parseInt(ele.time) > beginingOfToday)
        );
    }, [lsHistory, itradeLsHistory, currentSymbol]);

    useEffect(() => {
        if (timedData.length > 0) {
            setLastQty(timedData[timedData.length - 1]?.execqty);
            setdayQty(
                timedData.reduce((acc, o) => acc + parseInt(o.execqty), 0)
            );
        }
    }, [timedData]);

    return (
        <Row
            noGutters
            className="size-sm py-1 px-2 flex-nowrap overflow-auto"
            style={{
                border: layoutConfigurationOptions?.componentBorders
                    ? `1px solid ${fontColor}`
                    : 0,
            }}
        >
            <>
                <StatisticItem
                    name="Close"
                    value={currentSymbol?.prevcloseprice}
                />
                <StatisticItem name="Best Bid" value={currentSymbol?.bid} />
                <StatisticItem name="Best Offer" value={currentSymbol?.offer} />
                <StatisticItem name="High" value={currentSymbol?.high} />
                <StatisticItem name="Low" value={currentSymbol?.low} />
                <StatisticItem name="Last Price" value={currentSymbol?.last} />
                <StatisticItem name="Last Qty" value={lastQty} />
                <StatisticItem name="Today's Qty" value={dayQty} />
            </>
            {auctionEnabled && symbolAuctionInfo && (
                <>
                    <StatisticItem
                        name="Opening Price"
                        value={symbolAuctionInfo?.openingprice}
                    />
                    <StatisticItem
                        name="Executed Quantity"
                        value={
                            symbolAuctionInfo?.execqty /
                            currentSymbol?.fractionbase
                        }
                    />
                    <StatisticItem
                        name="Imbalance"
                        value={
                            symbolAuctionInfo?.imbalance
                                ? `${
                                      symbolAuctionInfo?.imbalance_side === "S"
                                          ? "-"
                                          : ""
                                  }${
                                      symbolAuctionInfo?.imbalance /
                                      currentSymbol?.fractionbase
                                  }`
                                : "N/A"
                        }
                    />
                </>
            )}
        </Row>
    );
};
