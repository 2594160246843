import {
    enableUserAccounts,
    hideCreditsDebits,
    hideTradeReports,
    showAnnouncments,
    showApprovalsPage,
    showAssetBuilder,
    showCompanyDocumentsPage,
    showOnboarding,
    showRisk,
    showSymbolActivationPage,
    showTradingPortal,
} from "../../config";
import { useStore } from "../../Store";
import {
    DropdownMenuWrapper,
    SidebarItem,
    SidebarWrappper,
} from "../SidebarWrapper";

export const AdminSidebar = () => {
    const {
        state: { account: user },
    } = useStore();

    const SuperUserOnly = ({ children }) =>
        user.account.data.user_type === "S" ? children : null;
    // const FirmUserOnly = ({ children }) =>
    //     user.account.data.user_type === "F" ? children : null;
    const CustodyAdminOnly = ({ children }) =>
        user.roles.custody ? children : null;
    const CustodyAdminOrSuperUser = ({ children }) =>
        user.roles.custody || user.account.data.user_type === "S"
            ? children
            : null;
    const TradeAdminOnly = ({ children }) =>
        user.roles.trading ? children : null;
    const AssetBuilderAdminOnly = ({ children }) =>
        user.roles.asset_builder ? children : null;
    const OnboardingAdminOnly = ({ children }) =>
        user.roles.onboarding_admin ? children : null;
    const RiskAdminOnly = ({ children }) =>
        user.roles.risk_admin ? children : null;
    const TradeOrFirmAdmin = ({ children }) =>
        user.roles.trading || user.roles.firm_admin ? children : null;
    const NotFirmAdmin = ({ children }) =>
        !user.roles.firm_admin && user?.account?.data?.user_type !== "F"
            ? children
            : null;

    const renderSettingOptions = () => (
        <SuperUserOnly>
            <DropdownMenuWrapper name="Settings">
                <SidebarItem name="Status" path="/admin/status" />
                <SidebarItem name="Preferences" path="/admin/preferences" />
                <SidebarItem name="Holidays" path="/admin/holidays" />
                {showCompanyDocumentsPage && (
                    <SidebarItem name="Company Documents" path="/admin/docs" />
                )}
            </DropdownMenuWrapper>
        </SuperUserOnly>
    );

    const renderFirmOptions = () => (
        <CustodyAdminOrSuperUser>
            <DropdownMenuWrapper name="Firms">
                <SuperUserOnly>
                    <SidebarItem name="Firms" path="/admin/firms" />
                </SuperUserOnly>
                <SuperUserOnly>
                    <SidebarItem name="Users" path="/admin/users" />
                </SuperUserOnly>
                {/* <FirmUserOnly>
                <SidebarItem name="Transfer Funds" path="/admin/transfers" />
            </FirmUserOnly> */}
                {showApprovalsPage && (
                    <CustodyAdminOnly>
                        <SidebarItem name="Approvals" path="/admin/approvals" />
                    </CustodyAdminOnly>
                )}
            </DropdownMenuWrapper>
        </CustodyAdminOrSuperUser>
    );

    const renderMarketOptions = () => (
        <DropdownMenuWrapper name="Market">
            <TradeAdminOnly>
                <SidebarItem name="Activity" path="/admin/activity" />
            </TradeAdminOnly>
            {!hideCreditsDebits && (
                <CustodyAdminOnly>
                    <SidebarItem
                        name="Deposit / Withdraw"
                        path="/admin/transactions"
                    />
                </CustodyAdminOnly>
            )}
            <TradeAdminOnly>
                <SidebarItem name="Symbols" path="/admin/symbols" />
            </TradeAdminOnly>
            <TradeOrFirmAdmin>
                <SidebarItem name="Symbol Status" path="/admin/symbol-status" />
                {showSymbolActivationPage && (
                    <SidebarItem
                        name="Symbol Activation"
                        path="/admin/symbol-activation"
                    />
                )}
            </TradeOrFirmAdmin>
            <TradeAdminOnly>
                {!hideTradeReports && (
                    <SidebarItem
                        name="Trade Report"
                        path="/admin/trade-report"
                    />
                )}
            </TradeAdminOnly>
            {!hideCreditsDebits && (
                <CustodyAdminOnly>
                    <SidebarItem name="Positions" path="/admin/positions" />
                </CustodyAdminOnly>
            )}
        </DropdownMenuWrapper>
    );

    const renderRiskOptions = () => (
        <RiskAdminOnly>
            <DropdownMenuWrapper name="Risk Management">
                <SidebarItem
                    name="Case Management"
                    path="/admin/case-management"
                />
                <SidebarItem name="Risk Monitor" path="/admin/risk-monitor" />
                <SidebarItem name="Risk Setting" path="/admin/risk-setting" />
                <SidebarItem
                    name="Pre-Trade Risk Alerts"
                    path="/admin/risk-alert"
                />
                <SidebarItem
                    name="Pattern Detection Alerts"
                    path="/admin/pattern-detection-alert"
                />
            </DropdownMenuWrapper>
        </RiskAdminOnly>
    );

    const renderAssetTemplatesOptions = () => (
        <AssetBuilderAdminOnly>
            <DropdownMenuWrapper name="Asset Builder">
                <SidebarItem
                    name="Asset Templates"
                    path="/admin/asset-templates"
                />
                <SidebarItem
                    name="Asset Profiles"
                    path="/admin/asset-profiles"
                />
                <SidebarItem
                    name="Market Symbols"
                    path="/admin/market-symbols"
                />
                {showAnnouncments && (
                    <SidebarItem
                        name="Announcement Templates"
                        path="/admin/announcement-templates"
                    />
                )}
            </DropdownMenuWrapper>
        </AssetBuilderAdminOnly>
    );

    const renderOnboardingOptions = () => (
        <OnboardingAdminOnly>
            <DropdownMenuWrapper name="Onboarding">
                <SidebarItem name="Forms" path="/admin/forms" />
                <SidebarItem name="Roles" path="/admin/roles" />

                <NotFirmAdmin>
                    <SidebarItem
                        name="Manage Users"
                        path="/admin/manage-users"
                    />
                    <SidebarItem
                        name="Review Submissions"
                        path="/admin/submissions"
                    />
                    {enableUserAccounts && (
                        <SidebarItem
                            name="Manage Accounts"
                            path="/admin/manage-accounts"
                        />
                    )}
                </NotFirmAdmin>
            </DropdownMenuWrapper>
        </OnboardingAdminOnly>
    );

    return (
        <SidebarWrappper
            sidebarName="admin-sidebar"
            bottomQuickLinkName={showTradingPortal ? "Portal" : null}
        >
            {renderSettingOptions()}
            {renderFirmOptions()}
            {renderMarketOptions()}
            {showRisk && renderRiskOptions()}
            {showAssetBuilder && renderAssetTemplatesOptions()}
            {showOnboarding && renderOnboardingOptions()}
        </SidebarWrappper>
    );
};
