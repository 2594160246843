export const roleParser = (roles) => {
    const roleArr = [...roles];

    const permissions = {
        risk_admin: false,
        onboarding_admin: false,
        trading: false,
        custody: false,
        asset_builder: false,
        ordinary_user: false,
        firm_admin: false,
    };

    roleArr.forEach((role, i) => {
        switch (i) {
            case 0:
                // risk admin
                if (role === "S") {
                    permissions.risk_admin = true;
                }
                if (role === "O") permissions.ordinary_user = true;

                break;
            case 1:
                // onboarding admin
                if (role === "S") {
                    permissions.onboarding_admin = true;
                }
                if (role === "O") permissions.ordinary_user = true;

                break;
            case 2:
                // trading privileges
                if (role === "S") {
                    permissions.trading = true;
                } else if (role === "F") {
                    permissions.firm_admin = true;
                }

                if (role === "O") permissions.ordinary_user = true;

                break;
            case 3:
                // custody
                if (role === "S") {
                    permissions.custody = true;
                }

                if (role === "O") permissions.ordinary_user = true;

                break;
            case 4:
                // asset builder/research
                if (role === "S") {
                    permissions.asset_builder = true;
                }
                if (role === "O") permissions.ordinary_user = true;

                break;
        }
    });

    return permissions;
};
