import * as ACTIONS from "../contexts/actions/resources";
import { sendMessageQuery } from "./websocket";

export const getPositions = (ws, dispatch, userid, firm) =>
    sendMessageQuery(ws, "querypendingpos", { userid, firm }).then((resp) =>
        dispatch(ACTIONS.getPositions(resp, userid))
    );

export const getAccountPositions = (ws, account, firm) =>
    sendMessageQuery(ws, "querypendingpos", { account, firm });
