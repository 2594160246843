const positiveNumberRegEx = /^\d*(\.\d+)?$/g;
const generalEmailRegEx =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const allSignedNumberRegex = /^(|-?\d+|-)$/gm;
// Password regex, at least one letter and one number, minimum length 8 characters.
const passwordRegEx = /^(?=.*[A-Za-z@$!%*?&])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/;
const letterRegex = /[A-Za-z]+/gm;
const phoneNumberRegex =
    /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;
const URLRegex =
    /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/;
// ensure the password has at least 14 characters, includes at least one special character, at least one number, and at least one letter (uppercase or lowercase)
const complexPasswordRegex =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*()_+{}[\]:;"'|\\,.<>/?`~-])[A-Za-z\d!@#$%^&*()_+{}[\]:;"'|\\,.<>/?`~]{14,}$/;

export const validatePosNumber = (ev) =>
    !ev.key.match(positiveNumberRegEx) &&
    ev.key !== "Backspace" &&
    ev.key !== "." &&
    ev.key !== "Tab" &&
    ev.key !== "ArrowUp" &&
    ev.key !== "ArrowDown" &&
    ev.key !== "ArrowLeft" &&
    ev.key !== "ArrowRight" &&
    ev.preventDefault();

export const validateNoFloats = (ev) =>
    (ev.key === "." || !ev.key.match(positiveNumberRegEx)) &&
    ev.key !== "Backspace" &&
    ev.key !== "Tab" &&
    ev.preventDefault();

export const validateNoFloatsAllSigns = (ev) =>
    ((!ev.key.match(allSignedNumberRegex) && ev.key.match(letterRegex)) ||
        ev.key === ".") &&
    ev.key !== "Backspace" &&
    ev.key !== "Tab" &&
    ev.preventDefault();

export const validateOnlyNumbersLetters = (ev) =>
    !ev.key.match(/[a-zA-Z0-9]/g) &&
    ev.key !== "Backspace" &&
    ev.key !== "Tab" &&
    ev.preventDefault();

export const validateEmail = (email) => !generalEmailRegEx.test(email);
export const validatePassword = (password) => !passwordRegEx.test(password);
export const validateSSN = (ssn) => !/^\d{3}-\d{2}-\d{4}$/g.test(ssn);
export const validatePhoneNumber = (phoneNumber) =>
    !phoneNumberRegex.test(phoneNumber);
export const validateOnlyString = (string) => !/[a-zA-Z\s]*/gm.test(string);
export const validateURL = (url) => URLRegex.test(url);
export const validateComplexPassword = (password) =>
    !complexPasswordRegex.test(password);
// 2. At Least One Letter (Uppercase or Lowercase)
export const hasLetter = (password) => {
    return /[A-Za-z]/.test(password);
};

// 3. At Least One Number
export const hasNumber = (password) => {
    return /\d/.test(password);
};

// 4. At Least One Special Character
export const hasSpecialCharacter = (password) => {
    return /[!@#$%^&*()_+{}[\]:;"'|\\,.<>/?`~-]/.test(password);
};

export const passwordErrorStr = (password) => {
    if (password.length < 14) {
        return "Password must have atleast 14 characters";
    } else if (
        !hasLetter(password) ||
        !hasNumber(password) ||
        !hasSpecialCharacter(password)
    ) {
        return "Password must have at least 1 letter & 1 special character & 1 number";
    } else {
        return;
    }
};
