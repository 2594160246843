/* eslint-disable react/display-name */
import { memo, useState } from "react";
import { Button, FormCheck, Modal, Row } from "react-bootstrap";

import { formatDisplayTextForConfigure } from "../helpers/display-options";

export const ConfigureDisplay = memo(
    ({
        show = false,
        handleCloseModal,
        keys,
        selectedKeys,
        // changeOption,
        saveOptions,
        enableNewTextFormatting = false,
    }) => {
        const [options, setOptions] = useState(selectedKeys);

        const handleChange = ({ target: { checked, name } }) => {
            const newSelectedKeys = checked
                ? [...selectedKeys, name]
                : selectedKeys.filter((key) => key !== name);

            saveOptions(newSelectedKeys);
            setOptions(newSelectedKeys);
        };

        return (
            <Modal show={show} onHide={handleCloseModal}>
                <Modal.Header closeButton>Configure Display</Modal.Header>
                <Modal.Body>
                    <ul style={{ columns: "200px 2", listStyle: "none" }}>
                        {keys.map((key) => (
                            <li key={key}>
                                <Row>
                                    <FormCheck
                                        type="checkbox"
                                        name={key}
                                        onChange={handleChange}
                                        defaultChecked={options.includes(key)}
                                    />
                                    {enableNewTextFormatting
                                        ? formatDisplayTextForConfigure(key)
                                        : key}
                                </Row>
                            </li>
                        ))}
                    </ul>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="dark"
                        onClick={() => {
                            saveOptions(Array.from(options)).then(() => {
                                handleCloseModal();
                            });
                        }}
                        size="sm"
                    >
                        Save
                    </Button>
                    <Button
                        variant="light"
                        onClick={handleCloseModal}
                        size="sm"
                    >
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
);
