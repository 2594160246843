import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";

import { ConfigureModalTemplate } from "./ConfigureModalTemplate";

export const TableConfigureModal = ({
    show = false,
    handleSave,
    existingHeaders,
}) => {
    const [headers, setHeaders] = useState(existingHeaders);

    return (
        <ConfigureModalTemplate
            show={show}
            handleConfirm={() => setHeaders([...headers, ""])}
            handleClose={() => handleSave("headers", headers)}
            confirmText="Add Header"
            body={
                <>
                    {headers.map((hdr, i) => (
                        <Row noGutters key={i} className="my-2">
                            <Col md={10} className="mr-1">
                                <Form.Control
                                    size="sm"
                                    defaultValue={hdr}
                                    onChange={({ target: { value } }) => {
                                        const newHeaders = headers;
                                        newHeaders[i] = value;

                                        setHeaders(newHeaders);
                                    }}
                                />
                            </Col>
                            <Col>
                                <Button
                                    size="sm"
                                    variant="danger"
                                    onClick={() =>
                                        setHeaders(
                                            headers.filter((item, y) => y !== i)
                                        )
                                    }
                                >
                                    Delete
                                </Button>
                            </Col>
                        </Row>
                    ))}
                </>
            }
        />
    );
};
