/* eslint-disable no-unused-vars */
import { useEffect } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";

export const BookQMSModal = ({
    show = false,
    handleClose,
    qMSUserDetails,
}) => {


    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title className="pl-2 my-0" style={{ fontSize: "18px" }}>
                    User Details
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="order-detail-modal size-sm">
                <Row noGutters>
                    <Col>Name</Col>
                    <Col className="text-right">{qMSUserDetails?.name}</Col>
                </Row>
                <Row noGutters>
                    <Col>Email Address</Col>
                    <Col className="text-right">{qMSUserDetails?.email}</Col>
                </Row>
                <Row noGutters>
                    <Col>Phone Number</Col>
                    <Col className="text-right">{qMSUserDetails?.phone_number}</Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button size="sm" onClick={handleClose}>
                    OK
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
