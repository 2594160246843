import { Button, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { TableRenderer } from "../../components/portal/TableRenderer";
import { useStore } from "../../Store";

export const AssetTable = ({ profiles }) => {
    const navigate = useNavigate();
    const { state } = useStore();
    const { account } = state;

    const columns = [
        {
            dataField: "image",
            text: "",
            formatter: (content) => (
                <img src={content} alt="" height={"50px"} />
            ),
        },
        {
            dataField: "symbol",
            text: "Name",
            sort: true,
        },

        {
            dataField: "tradeButton",
            text: "Description",
            sort: true,
            isDummyField: true,
            formatter: (content, { symbol, description }) => (
                <Row noGutters className="justify-content-between">
                    <Col>
                        {description.length > 200
                            ? description.substr(0, 200) + "..."
                            : description}
                    </Col>

                    {(account?.account?.data && account?.account?.data?.active === "Y") &&
                        Object.keys(account?.account?.data).length > 0 && (
                            <Button
                                className="px-4"
                                variant="primary"
                                onClick={() =>
                                    navigate(`/portal/trade/${symbol}`)
                                }
                            >
                                Trade
                            </Button>
                        )}
                </Row>
            ),
        },
    ];

    return (
        <TableRenderer
            keyField="id"
            sortOrder="desc"
            data={profiles || []}
            columns={columns}
            wrapperClasses="w-100 mt-2 bg-white size-sm"
            noDataIndication="No Assets Available."
        />
    );
};
