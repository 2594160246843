import { toast } from "react-toastify";

import * as ACCOUNT_ACTIONS from "../contexts/actions/account";
import * as SETTING_ACTIONS from "../contexts/actions/settings";
import { sendMessageQuery } from "./websocket";

/*
    update_config
    This function will update new system preferences on the websocket (config_setting)
*/
export const storeNewPreferences = (
    ws,
    dispatch,
    preferences,
    prefKey,
    otherPreferences
) => {
    const formattedPreferences = {
        ...otherPreferences,
        [prefKey]: preferences,
    };

    const attributes = {};

    Object.keys(formattedPreferences).forEach((key) => {
        attributes[key] = JSON.stringify(formattedPreferences[key]);
    });

    return sendMessageQuery(ws, "update_config", {
        attr: attributes,
    }).then(() => {
        toast.success("System preferences succesfully updated.");

        dispatch(ACCOUNT_ACTIONS.updateSystemPreferences(formattedPreferences));
    });
};

export const getHolidays = (ws, dispatch) =>
    sendMessageQuery(ws, "queryholiday").then((holidaysData) => {
        dispatch(SETTING_ACTIONS.getHolidays(holidaysData));
    });

export const deleteHoliday = (ws, dispatch, holiday) =>
    sendMessageQuery(ws, "scheduleholiday", {
        ...holiday,
        isdelete: true,
    }).then(() => {
        toast.success("Holiday successfully deleted");
        getHolidays(ws, dispatch);
    });

export const createHoliday = (ws, dispatch, holiday, type) =>
    sendMessageQuery(ws, "scheduleholiday", { ...holiday }).then(() => {
        toast.success(
            `Holiday successfully ${type === "add" ? "created" : "edited"}`
        );
        getHolidays(ws, dispatch);
    });

export const getCompanyDocs = (ws, dispatch) =>
    sendMessageQuery(
        ws,
        "querybankinfo",
        {
            userid: "CompanyDocs",
            bank_type: "docs",
        },
        true
    )
        .then((resp) => {
            const { docs } = JSON.parse(resp.bank_detail);

            dispatch(SETTING_ACTIONS.queryCompanyDocs(docs || []));

            return { docs };
        })
        .catch(() => { });

export const updateCompanyDocs = (ws, dispatch, { docs }) =>
    sendMessageQuery(ws, "updatebankinfo", {
        userid: "CompanyDocs",
        bank_type: "docs",
        bank_detail: {
            docs,
        },
    }).then(() => getCompanyDocs(ws, dispatch));

