import { memo, useEffect, useState } from "react";
import { Row } from "react-bootstrap";

import { formatTableColumn } from "../../../helpers/other";
import { queryTrade } from "../../../helpers/trade";
import { useStore } from "../../../Store";
import { useSocket } from "../../../Websocket";
import { FullScreenLoading } from "../../Loading";
import { OrderDetailModal } from "../../portal/OrderDetailModal";
import { TableRenderer } from "../../portal/TableRenderer";

// eslint-disable-next-line react/display-name
export const InvestigateExecutionsTable = memo(({ trader }) => {
    const ws = useSocket();
    const { dispatch } = useStore();
    const [loading, setLoading] = useState(false);

    const [executions, setExecutions] = useState([]);

    const [selectedOrder, setSelectedOrder] = useState(null);
    const [showOrderDetailModal, setShowOrderDetailModal] = useState(false);
    const handleOpenOrderDetailModal = () => setShowOrderDetailModal(true);
    const handleCloseOrderDetailModal = () => setShowOrderDetailModal(false);

    useEffect(() => {
        if (trader) {
            setLoading(true);

            queryTrade(ws, dispatch, undefined, 0, trader, false).then(
                (res) => {
                    setExecutions(res);
                    setLoading(false);
                }
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trader]);

    return (
        <>
            <Row noGutters className="mb-3">
                <h6>
                    <b>Executions</b>
                </h6>
            </Row>

            <OrderDetailModal
                show={showOrderDetailModal}
                selectedOrder={selectedOrder}
                handleClose={handleCloseOrderDetailModal}
            />

            {loading ? (
                <FullScreenLoading />
            ) : (
                <TableRenderer
                    keyField="refno"
                    wrapperClasses="risk-investigate-table"
                    data={executions}
                    columns={[
                        {
                            dataField: "refno",
                            text: "Ref no.",
                            sort: true,
                            formatter: (rowContent, row) => {
                                return (
                                    <span
                                        aria-hidden="true"
                                        style={{
                                            paddingBottom: "2px",
                                            borderBottom: "1px solid",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => {
                                            handleOpenOrderDetailModal();
                                            setSelectedOrder(row);
                                        }}
                                    >
                                        {row.refno}
                                    </span>
                                );
                            },
                        },
                        ...["security", "side", "price", "execqty"].map((col) =>
                            formatTableColumn(col)
                        ),
                    ]}
                    noDataIndication="No orders found."
                />
            )}
        </>
    );
});
