/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";

export const SearchBars = ({ searchApprovals }) => {
    const [firm, setFirm] = useState("");
    const [userId, setUserId] = useState("");

    return (
        <Form>
            <Row>
                <Col>
                    <Form.Control
                        type="search"
                        placeholder="Firm"
                        onChange={({ target }) => setFirm(target.value)}
                        value={firm}
                        size="sm"
                    />
                    <div
                        className="clear-search-bar"
                        onClick={() => setFirm("")}
                    ></div>
                </Col>

                <Col>
                    <Form.Control
                        type="search"
                        placeholder="User ID"
                        onChange={({ target }) => setUserId(target.value)}
                        value={userId}
                        size="sm"
                    />
                    <div
                        className="clear-search-bar"
                        onClick={() => setUserId("")}
                    ></div>
                </Col>

                <Col>
                    <Button
                        variant="dark"
                        onClick={() => searchApprovals(firm, userId)}
                        size="sm"
                    >
                        Search
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};
