import { FormCheck, Row, Table } from "react-bootstrap";

export const CheckboxInput = (props) => {
    const { field, onChange, classes, disabled, preview } = props;
    const { required } = field;

    return (
        <Row noGutters className={classes ?? "position-relative"}>
            <Table response="sm" striped>
                <thead>
                    <tr>
                        <th>Option</th>
                        {field.options.map((opt, i) => (
                            <th key={i}>{opt}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {field.subfields.map((subfield, i) => (
                        <tr key={i}>
                            <td>{subfield.fieldName}</td>
                            {field.options.map((opt, j) => (
                                <td key={j}>
                                    <FormCheck
                                        checked={
                                            field.subfields[i].value === opt
                                        }
                                        onChange={() => {
                                            if (!preview) {
                                                const newSubfields = Array.from(
                                                    field.subfields
                                                );

                                                newSubfields[i].value =
                                                    newSubfields[i].value ===
                                                    opt
                                                        ? undefined
                                                        : opt;

                                                onChange({
                                                    ...field,
                                                    subfields: newSubfields,
                                                });
                                            }
                                        }}
                                        disabled={disabled}
                                    />
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </Table>
            {required && (
                <span
                    className="required-icon"
                    style={{ top: "-1.5rem", right: "1rem" }}
                >
                    *
                </span>
            )}
        </Row>
    );
};
