import combineReducers from "react-combine-reducers";

import { authReducer, initialAuthState } from "./reducers/account";
import {
    announcementBuilderReducer,
    initialAnnouncementBuilderState,
} from "./reducers/announcement-builder";
import {
    assetBuilderReducer,
    initialAssetBuilderState,
} from "./reducers/asset-builder";
import { firmReducer, initialFirmState } from "./reducers/firm";
import { initialMarketState, marketReducer } from "./reducers/market";
import {
    initialOnboardingState,
    onboardingReducer,
} from "./reducers/onboarding";
import { initialOtherState, otherReducer } from "./reducers/other";
import { initialPortfolioState, portfolioReducer } from "./reducers/portfolio";
import { initialResourcesState, resourcesReducer } from "./reducers/resources";
import { initialRiskState, riskReducer } from "./reducers/risk";
import { initialSettingsState, settingsReducer } from "./reducers/settings";
import { initialTradeState, tradeReducer } from "./reducers/trade";

export const [userReducers, initialUserState] = combineReducers({
    account: [authReducer, initialAuthState],
    firm: [firmReducer, initialFirmState],
    market: [marketReducer, initialMarketState],
    other: [otherReducer, initialOtherState],
    settings: [settingsReducer, initialSettingsState],
    trade: [tradeReducer, initialTradeState],
    risk: [riskReducer, initialRiskState],
    resources: [resourcesReducer, initialResourcesState],
    portfolio: [portfolioReducer, initialPortfolioState],
    asset_builder: [assetBuilderReducer, initialAssetBuilderState],
    onboarding: [onboardingReducer, initialOnboardingState],
    announcement_builder: [announcementBuilderReducer, initialAnnouncementBuilderState],
});
