import { validatePosNumber } from "../../helpers/validate";
import { InputTemplate } from "./InputTemplate";

export const NumberInput = ({ field, onChange, classes, disabled }) => (
    <InputTemplate
        type="number"
        min={0}
        onKeyDown={validatePosNumber}
        field={field}
        onChange={onChange}
        classes={classes}
        disabled={disabled}
    />
);
