/* eslint-disable react/display-name */

import { memo } from "react";

import { formatNumber } from "../../../../helpers/other";
import { Loading } from "../../../Loading";
import { TableRenderer } from "../../../portal/TableRenderer";

export const PositionsList = memo(({ positions, loading = false }) => {
    const columns = [
        {
            dataField: "security",
            text: "Security",
        },
        {
            dataField: "curpos",
            text: "Amount",
            formatter: (curpos, row) =>
                `${
                    row.security === "USD"
                        ? `$${formatNumber(curpos)}`
                        : formatNumber(curpos)
                }`,
        },
    ];

    return loading ? (
        <Loading />
    ) : (
        <TableRenderer
            keyField="security"
            data={positions}
            columns={columns}
            noDataIndication="No positions available."
            classes=""
            striped
            wrapperClasses="size-sm"
        />
    );
});
