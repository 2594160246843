import * as CONSTANTS from "../constants/settings";

export const initialSettingsState = {
    statuses: [],
    holidays: [],
    docs: []
};

export const settingsReducer = (state, action) => {
    switch (action.type) {
        case CONSTANTS.UPDATE_SYSTEM_STATUS:
            return {
                ...state,
                statuses: action.statuses,
            };
        case CONSTANTS.GET_HOLIDAYS:
            return {
                ...state,
                holidays: action.holidays,
            };
        case CONSTANTS.QUERY_COMPANY_DOCS:
            return {
                ...state,
                docs: action.companyDocs,
            };
        default:
            return state;
    }
};
