import { debounce } from "lodash";
import { toast } from "react-toastify";
import { v4 as uuid } from "uuid";

import {
    dingSound,
    enableDetailedNotification,
    onLoginUseComplianceID,
    useUnsecureWebsocket,
} from "../config";
import * as ACCOUNT_ACTIONS from "../contexts/actions/account";
import * as MARKET_ACTIONS from "../contexts/actions/market";
import * as OTHER_ACTIONS from "../contexts/actions/other";
import * as RISK_ACTIONS from "../contexts/actions/risk";
import * as SETTINGS_ACTIONS from "../contexts/actions/settings";
import * as TRADE_ACTIONS from "../contexts/actions/trade";
import { rejectLogin } from "./login";
import { getSymbols } from "./market";
import { removeUnusedKeys, Timer } from "./other";

const OrderStatus = {
    PENDING: "Pending",
    OPEN: "Open",
    CANCELED: "Canceled",
    EXECUTED: "Executed",
    REJECTED: "Rejected",
};

const ActType = {
    ADD: "U",
    REMOVE: "R",
};

const soundEffect = new Audio(dingSound);

const negotiateDebounceTimers = {};

const debounceNegotiateOrderDispatch = (message, dispatch) => {
    negotiateDebounceTimers[message.refno] = setTimeout(() => {
        dispatch(
            TRADE_ACTIONS.addOrder({
                ...message,
                inbound: true,
            })
        );
        negotiateDebounceTimers[message.refno] = "DONE";
    }, 2000);
};

const establishWebsocketConnection = (state, dispatch, subStore) => {
    const isUnsecure = useUnsecureWebsocket === true ? "ws" : "wss";
    const host = `${isUnsecure}://${state.account.account.data.endpoint}`;
    const mySocket = new WebSocket(host);
    const timer = new Timer({ dispatch });

    let debouncedExecutedTrades = new Set();
    let debouncedBrokenTrades = new Set();

    const toastDebounced = debounce(() => {
        if (debouncedExecutedTrades.size > 0)
            toast.success(
                `Order${debouncedExecutedTrades.size === 1 ? "" : "s"} ${[
                    ...debouncedExecutedTrades,
                ].join(", ")} ${debouncedExecutedTrades.size === 1 ? "was" : "were"
                } executed`,
                { autoClose: 2500 }
            );
        if (debouncedBrokenTrades.size > 0)
            toast.error(
                `Orders${debouncedBrokenTrades.size === 1 ? "" : "s"} ${[
                    ...debouncedBrokenTrades,
                ].join(", ")} ${debouncedBrokenTrades.size === 1 ? "was" : "were"
                } broken`,
                { autoClose: 2500 }
            );

        isDebounced = false;
        debouncedExecutedTrades = new Set();
        debouncedBrokenTrades = new Set();
    }, 2000);
    let isDebounced = false;

    mySocket.onopen = () => {
        console.log("Opening connection...");

        const challenge = JSON.stringify({ type: "challenge" });
        mySocket.send(challenge);
    };

    mySocket.onerror = (err) => {
        const disconnectErr = err.target.readyState;

        if (disconnectErr === 3) {
            toast.error("Can not connect to backend!");
            setTimeout(() => rejectLogin(dispatch), 1500);
        }
    };

    const convertBigIntString = (str) => {
        if (str.includes('"type":"book"')) {
            /**
             * /"id":\s*(\d{15,})/g looks for the id key followed by any number with 15 or more digits
             * (you can adjust this threshold for your use case).
             * The large numbers are what we want to treat as strings to avoid precision loss.
             * The large number is captured and replaced with the same value wrapped in quotes, so it becomes a string.
             */
            const modifiedStr = str.replace(/"id":\s*(\d{15,})/g, '"id": "$1"');

            return JSON.parse(modifiedStr);
        } else return JSON.parse(str);
    };

    const handleEventListener = (e) => {
        const message = convertBigIntString(e.data) || "";

        switch (message.type) {
            case "challenge": {
                const userData = JSON.stringify({
                    type: "login",
                    token: state.account.account.data.token,
                    data: state.account.account.data,
                });

                mySocket.send(userData);

                dispatch(ACCOUNT_ACTIONS.addPublicKey(message.key));

                timer.reset();
                break;
            }
            case "login": {
                dispatch(OTHER_ACTIONS.connectSocket());

                // Start our system and symbol subscriptions.
                sendMessageQuery(
                    { socket: mySocket },
                    "subscribesystemstatus",
                    {},
                    true,
                    true
                );

                getSymbols({ socket: mySocket }, dispatch, null, subStore).then(
                    () =>
                        sendMessageQuery(
                            { socket: mySocket },
                            "subscribesymbolstatus",
                            {},
                            true,
                            true
                        )
                );

                // get querytrade on login
                // 1. This is a DB query so we should limit how many times we call this
                // 2. We constantly update this data on type: "sale", so if we get the data at the start we never need to requery
                sendMessageQuery(
                    { socket: mySocket },
                    "querytrade",
                    {
                        firm: state.account.account.data.firm,
                        fromtime: new Date().setHours(0, 0, 0, 0).toString(),
                        lastfirst: true,
                        maxreturn: 1000,
                        userid: onLoginUseComplianceID
                            ? state.account.account.data.complianceid
                            : state.account.account.data.userid,
                    },
                    true
                ).then((resp) => {
                    dispatch(TRADE_ACTIONS.queryTrade(resp || []));
                });

                break;
            }
            case "heartbeat": {
                // Reset connection timer.
                mySocket.send(JSON.stringify({ type: "heartbeat" }));
                timer.reset();
                break;
            }
            case "position": {
                dispatch(TRADE_ACTIONS.addPosition(message));
                break;
            }
            case "symbolstatus": {
                if (message?.data && message?.data.length > 0) {
                    dispatch(MARKET_ACTIONS.updateSymbolStatus(message.data));
                    subStore.updateTradeSymbolStatus(message.data);
                    // dispatch(TRADE_ACTIONS.updateSymbolStatus(message.data));
                }

                break;
            }
            case "systemstatus": {
                dispatch(SETTINGS_ACTIONS.updateSystemStatus(message.data));
                break;
            }
            case "tradehistory": {
                dispatch(
                    TRADE_ACTIONS.addTradeHistory(
                        message.data,
                        message.security
                    )
                );
                break;
            }
            // case "lshistory": {
            //     message?.data &&
            //         dispatch(TRADE_ACTIONS.queryLsHistory(message?.data));
            //     break;
            // }
            case "book": {
                message.books.forEach((book) => {
                    const { act } = book;
                    switch (act) {
                        case ActType.ADD:
                            subStore.addBook({ ...book, security: message.security });
                            // dispatch(TRADE_ACTIONS.addBook(book));
                            break;
                        case ActType.REMOVE:
                            subStore.removeBook({ ...book, security: message.security });
                            // dispatch(TRADE_ACTIONS.removeBook(book.key));
                            break;
                        default:
                            break;
                    }
                });
                break;
            }
            case "order": {
                const { orderstatus: orderStatus } = message;
                switch (orderStatus) {
                    case OrderStatus.PENDING:
                    case OrderStatus.OPEN:
                        dispatch(TRADE_ACTIONS.addOrder(message));
                        if (message.inbound === true) {
                            if (
                                negotiateDebounceTimers[message.refno] &&
                                negotiateDebounceTimers[message.refno] !==
                                "DONE"
                            ) {
                                clearTimeout(
                                    negotiateDebounceTimers[message.refno]
                                );
                            }
                            negotiateDebounceTimers[message.refno] = "DONE";
                        } else if (
                            message.inbound === false &&
                            message.firm === message.brokers
                        ) {
                            if (!negotiateDebounceTimers[message.refno]) {
                                debounceNegotiateOrderDispatch(
                                    message,
                                    dispatch
                                );
                            }
                        }
                        break;
                    case OrderStatus.CANCELED:
                        dispatch(TRADE_ACTIONS.cancelOrder(message));
                        !state.trade.layoutConfigurationOptions
                            .surpressCancelledNotifications &&
                            toast.success(
                                `Order ${message.refno} was Canceled`
                            );
                        break;
                    case OrderStatus.EXECUTED:
                        dispatch(TRADE_ACTIONS.executeOrder(message));
                        break;
                    case OrderStatus.REJECTED:
                        dispatch(TRADE_ACTIONS.cancelOrder(message));
                        // reject does the same thing as cancel EXCEPT it doesnt add it to the cancelled tab
                        // dispatch(TRADE_ACTIONS.rejectOrder(message));
                        if (message.text.includes("wash sale prohibited"))
                            toast.error("Order rejected. Wash sale prohibited");
                        break;
                    default:
                        break;
                }
                break;
            }
            case "sale": {
                if (new Date().getTime() - parseInt(message.trdtime) < 60000) {
                    if (state.trade.layoutConfigurationOptions.playSoundOnTrade)
                        soundEffect.play();
                    dispatch(TRADE_ACTIONS.executeOrder(message));
                    if (enableDetailedNotification) {
                        // Deatiled view per execution
                        const messageDate = new Date(parseInt(message.trdtime));
                        if (message.isbroken === true) {
                            toast.success(
                                <div>
                                    Trade {message.refno} was broken <br />{" "}
                                    Symbol: {message.security} <br /> Time:{" "}
                                    {messageDate.toLocaleDateString()}{" "}
                                    {messageDate.toLocaleTimeString()} <br />{" "}
                                    Price: {message.execprice} <br /> Quantity:{" "}
                                    {message.execqty}{" "}
                                </div>,
                                {
                                    toastId: message.refno,
                                }
                            );
                        } else {
                            !state.trade.layoutConfigurationOptions
                                .surpressExecutedNotifications &&
                                toast.success(
                                    <div>
                                        Order {message.refno} was executed{" "}
                                        <br /> Symbol: {message.security} <br />{" "}
                                        Side:{" "}
                                        {message.side === "S" ? "Sell" : "Buy"}{" "}
                                        <br /> Time:{" "}
                                        {messageDate.toLocaleDateString()}{" "}
                                        {messageDate.toLocaleTimeString()}{" "}
                                        <br /> Price: {message.execprice} <br />{" "}
                                        Quantity: {message.execqty}{" "}
                                    </div>,
                                    {
                                        toastId: message.refno,
                                        autoClose: 60000,
                                    }
                                );
                        }
                    } else {
                        // BATCH messsages to prevent high load
                        if (message.isbroken === true)
                            debouncedBrokenTrades.add(message.refno);
                        else
                            !state.trade.layoutConfigurationOptions
                                .surpressExecutedNotifications;
                        debouncedExecutedTrades.add(message.refno);

                        if (
                            !isDebounced &&
                            (!state.trade.layoutConfigurationOptions
                                .surpressExecutedNotifications ||
                                message.isbroken === true)
                        ) {
                            isDebounced = true;

                            setTimeout(() => {
                                toastDebounced();
                            }, 1000);
                        }
                    }
                }
                break;
            }
            case "highlow": {
                subStore.addSymbolPrevClosePrice(message);
                // dispatch(
                //     TRADE_ACTIONS.addPrevClosePrice(
                //         message.security,
                //         message.prevcloseprice,
                //         message.highprice,
                //         message.lowprice,
                //         message.lastprice
                //     )
                // );
                break;
            }
            case "nbbo":
            case "tob": {
                subStore.addBidOffer(message);
                // dispatch(
                //     TRADE_ACTIONS.addBidOffer(
                //         message.security,
                //         message.bid,
                //         message.ask
                //     )
                // );
                break;
            }
            case "current_risk": {
                dispatch(RISK_ACTIONS.addCurrentRisk(message));
                break;
            }
            case "risk_setting": {
                dispatch(RISK_ACTIONS.addRiskSetting(message));
                break;
            }
            case "trade": {
                subStore.addTradeDataForTob({
                    ...message,
                    execqty: message.qty,
                    execprice: message.price,
                });
                subStore.addTradeData({
                    ...message,
                    execqty: message.qty,
                    execprice: message.price,
                });
                // dispatch(
                //     TRADE_ACTIONS.addLsHistory({
                //         ...message,
                //         execqty: message.qty,
                //         execprice: message.price,
                //     })
                // );
                break;
            }
            case "onopen": {
                dispatch(TRADE_ACTIONS.addAuctionInformation(message));
                break;
            }
            default:
                break;
        }
    };

    mySocket.addEventListener("message", handleEventListener);

    return mySocket;
};
/*
    sendMessageQuery 

    ws - websocket connection
    queryType - message query to send
    messageProps - additional options necessary for the query.
    hideError - Don't toast message on error

    returns the message/data on success, returns toast error otherwise.
*/
export const sendMessageQuery = (
    ws,
    queryType,
    messageProps = {},
    hideError = false,
    isSubscription = false
) =>
    // eslint-disable-next-line no-undef
    new Promise((resolve, reject) => {
        // Create unique identifier to prevent getting the wrong message that shares a similar query.
        const identifier = uuid();
        // Initialize message body
        const message = {
            type: queryType,
            //Remove key-value from messageProps if value is empty
            ...(removeUnusedKeys(messageProps) || undefined),
        };
        // Add seperately to avoid shallow copying.
        message.CLT_REQID = identifier;

        let records = [];
        let totalRecords = 1;

        const handleEventListener = (e) => {
            // Parse message from websocket to JSON
            const message = JSON.parse(e.data) || "";

            if (message.CLT_REQID === identifier) {
                if (
                    // queryschedulesymbolstatus is specified here to make sure we ignore the first message.
                    (message.type === "queryschedulesymbolstatus" &&
                        message.data) ||
                    (message.type !== "queryschedulesymbolstatus" &&
                        message.type === queryType &&
                        ["no history found", "OK"].includes(message.result))
                ) {
                    // Parse total record count to make sure we get all of the data.
                    totalRecords = message?.total_rec || 0;
                    records = records.concat(
                        message?.contents || message?.data || []
                    );

                    if (
                        // 1. If all records have been obtained (Multiple messages)
                        // 2. If a file or riskcase and there is no moredata to get (Multiple messages)
                        // 3. If no total_rec count exists but there is records (One message)
                        // 4. If no total_rec count or nested data, just other props within the message (One message)
                        // 5. if lshistory and no data (One message)
                        totalRecords === records.length ||
                        ([
                            "getuserfile",
                            "getriskcase",
                            "queryonboardinfo",
                            "queryholiday",
                        ].includes(queryType) &&
                            !message?.moredata) ||
                        (![
                            "getuserfile",
                            "getriskcase",
                            "queryonboardinfo",
                            "queryholiday",
                        ].includes(queryType) &&
                            !message?.total_rec &&
                            records.length > 0) ||
                        (![
                            "getuserfile",
                            "getriskcase",
                            "queryonboardinfo",
                            "queryholiday",
                        ].includes(queryType) &&
                            !message?.total_rec &&
                            !message?.contents &&
                            !message?.data) ||
                        (["lshistory", "tradehistory"].includes(queryType) &&
                            !message?.data)
                    ) {
                        // Remove message listener.
                        ws.socket.removeEventListener(
                            "message",
                            handleEventListener
                        );

                        delete message.type;
                        delete message.CLT_REQID;

                        // Resolve with our data.
                        return resolve(
                            // If no results or there is records, otherwise, return the entire message.
                            message?.total_rec === 0 ||
                                message?.data ||
                                message?.contents ||
                                records.length > 0
                                ? records
                                : message
                        );
                    }
                } else if (message.result !== "OK") {
                    // Remove message listener.
                    ws.socket.removeEventListener(
                        "message",
                        handleEventListener
                    );

                    // add different error messages based on intial result message.
                    let rejectErrorMsg = message.result;
                    const ifStatementString = message.result;
                    if (message.type === "addorder") {
                        if (
                            ifStatementString.startsWith(
                                "not enough available bloc clearing pos"
                            )
                        )
                            // Not enough total position
                            rejectErrorMsg =
                                "Order rejected. Insufficient funds";
                        else if (ifStatementString.includes("Max Qty Exceeded"))
                            // Amount is higher than per order qty Risk setting
                            rejectErrorMsg =
                                "Order rejected. Amount is too high for this order";
                        else if (
                            ifStatementString.includes(
                                "Exceeds margin limit Exceed total qty"
                            )
                        )
                            // Amount is higher than daily qty Risk setting
                            rejectErrorMsg =
                                "Order rejected. Amount is too high for the day";
                        else if (
                            ifStatementString.includes(
                                "max dollar amount exceeded"
                            )
                        )
                            // Total price is higher than price per order Risk setting
                            rejectErrorMsg =
                                "Order rejected. Total price is too high for this order";
                        else if (
                            ifStatementString.includes(
                                "Exceeds margin limit Exceed total Amount"
                            )
                        )
                            // Total price is higher than price per day Risk setting
                            rejectErrorMsg =
                                "Order rejected. Total price is too high for the day";
                    }

                    // Reject with toast error.
                    return reject(!hideError && toast.error(rejectErrorMsg));
                }
            }
        };
        // Add message handler to detect responses from our query.
        !isSubscription
            ? ws.socket.addEventListener("message", handleEventListener)
            : resolve();
        // Send message.
        ws.socket.send(JSON.stringify(message));
    });

export default establishWebsocketConnection;
