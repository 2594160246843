import { useEffect } from "react";

export const useResizeObserver = (ref, callback, dependencies = []) => {
    useEffect(() => {
        if (ref.current) {
            const observer = new ResizeObserver(callback);
            observer.observe(ref.current);
            return () => observer.disconnect();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...dependencies, ref]);
}