export let baseUrl = process.env.REACT_APP_BACKEND;

export const clientCurrency = process.env.REACT_APP_CLIENT_CURRENCY || "USD";
export const clientDateFormat =
    process.env.REACT_APP_DATE_FORMAT || "MM/dd/yyyy";
export const clientTimeFormat =
    process.env.REACT_APP_CLIENT_TIME_FORMAT || "h:mm:ss aa";
export const clientName =
    process.env.REACT_APP_CLIENT_NAME || "Fundamental Interactions";

export const logo = `${process.env.PUBLIC_URL}/clients/${process.env.REACT_APP_CLIENT_VERSION}/logo.png`;
export const full_logo = `${process.env.PUBLIC_URL}/clients/${process.env.REACT_APP_CLIENT_VERSION}/full_logo.png`;
export const alt_full_logo = `${process.env.PUBLIC_URL}/clients/${process.env.REACT_APP_CLIENT_VERSION}/alt_full_logo.png`;
export const small_logo = `${process.env.PUBLIC_URL}/clients/${process.env.REACT_APP_CLIENT_VERSION}/small_logo.png`;
export const brassica_logo = `${process.env.PUBLIC_URL}/brassica_logo.png`;


export const defaultSymbolIcon = `${process.env.PUBLIC_URL}/default_symbol.png`;
export const portalLogoURL = process.env.REACT_APP_PORTAL_LOGO_URL;

export let cookieName;

if (process.env.REACT_APP_COOKIE_NAME) {
    cookieName = process.env.REACT_APP_COOKIE_NAME;
}

export const showAssetBuilder =
    process.env.REACT_APP_SHOW_ASSET_BUILDER === "true";
export const showTradingPortal =
    process.env.REACT_APP_SHOW_TRADING_PORTAL === "true";
export const showOnboarding = process.env.REACT_APP_SHOW_ONBOARDING === "true";
export const showRisk = process.env.REACT_APP_SHOW_RISK_MANAGEMENT === "true";

export const showFundsPage = process.env.REACT_APP_SHOW_FUNDS_PAGE === "true";
export const showAnnouncments =
    process.env.REACT_APP_SHOW_ANNOUNCEMENT === "true";
export const showPortfolio = process.env.REACT_APP_SHOW_PORTFOLIO === "true";
export const showMPIDWithSameFirm =
    process.env.REACT_APP_ENABLE_SHOW_MPID_WTH_SAME_FIRM === "true";
export const showCompanyDocumentsPage =
    process.env.REACT_APP_SHOW_COMPANY_DOCS === "true";
export const showApprovalsPage =
    process.env.REACT_APP_SHOW_APPROVALS_PAGE === "true";

export const disableRegister =
    process.env.REACT_APP_DISABLE_REGISTER === "true";
export const enforceEmailForUserID = process.env.REACT_APP_ENFORCE_EMAIL_USERID
    ? process.env.REACT_APP_ENFORCE_EMAIL_USERID === "true"
    : true;

export const useExternAccount =
    process.env.REACT_APP_USE_EXTERN_ACCOUNT === "true";

export const allowIOIOrderWithoutPrice =
    process.env.REACT_APP_ALLOW_IOI_ORDER_WITHOUT_PRICE === "";

export const orderAlertEnabled =
    process.env.REACT_APP_ORDER_ALERT_ENABLED === "true";
export const orderAlertPercentage =
    process.env.REACT_APP_ORDER_ALERT_PERCENTAGE || 5;
export const orderPrefixMessage =
    process.env.REACT_APP_ORDER_PREFIX_MESSAGE ||
        typeof process.env.REACT_APP_ORDER_PREFIX_MESSAGE === "string"
        ? // If empty string is provided the env var, we shouldnt add a space
        process.env.REACT_APP_ORDER_PREFIX_MESSAGE.length > 0
            ? // Default to Send if no env var is provided.
            process.env.REACT_APP_ORDER_PREFIX_MESSAGE + " "
            : ""
        : "Send ";
export const orderMessageTitle =
    process.env.REACT_APP_ORDER_MESSAGE_TITLE || "Add Order";
export const orderMessage = process.env.REACT_APP_ORDER_MESSAGE;
export const orderPreMessage = process.env.REACT_APP_ORDER_PRE_MESSAGE;
export const orderAlertMessage = process.env.REACT_APP_ORDER_ALERT_MESSAGE;
export const emailIssuer = process.env.REACT_APP_EMAIL_ISSUER === "true";
export const customTradeEnabled =
    process.env.REACT_APP_ENABLE_CUSTOM_TRADE === "true";
export const addUserSetTrader =
    process.env.REACT_APP_ADD_USER_SET_TRADER === "true";
export const modifyOrderEnabled =
    process.env.REACT_APP_ENABLE_MODIFY_ORDER === "true";
export const auctionEnabled = process.env.REACT_APP_ENABLE_AUCTION === "true";
export const fastCancelEnabled =
    process.env.REACT_APP_ENABLE_FAST_CANCEL === "true";
export const marketCapCurrencyEnabled =
    process.env.REACT_APP_ENABLE_MARKET_CAP_CURRENCY === "true";
export const hideHaltedSymbols =
    process.env.REACT_APP_HIDE_HALTED_SYMBOLS === "true";
export const hideTradeScreenWhenInactive =
    process.env.REACT_APP_HIDE_TRADE_WHEN_INACTIVE === "true";
export const hideMyFundsPage =
    process.env.REACT_APP_HIDE_MY_FUNDS_PAGE === "true";
export const hideCreditsDebits =
    process.env.REACT_APP_HIDE_CREDITS_DEBITS === "true";
export const hideTradeReports =
    process.env.REACT_APP_HIDE_TRADE_REPORTS === "true";
export const hideFillsTab = process.env.REACT_APP_HIDE_FILLS_TAB === "true";
export const enableExternalTrade = process.env.REACT_APP_ENABLE_EXTERNAL_TRADE === "true";

export const enableFirmAdditionalInfo =
    process.env.REACT_APP_ENABLE_ADDITIONAL_FIRM_INFO === "true";
export const enableAdminCreateOnboardingUser =
    process.env.REACT_APP_ENABLE_ADMIN_CREATE_ONBOARDING_USER === "true";
export const enableUserAccounts =
    process.env.REACT_APP_ENABLE_USER_ACCOUNTS === "true";
export const enableRegisterPhoneNumber =
    process.env.REACT_APP_ENABLE_REGISTER_PHONE_NUMBER === "true";
export const enableQMSUserData =
    process.env.REACT_APP_ENABLE_QMS_MPID_USER_DATA === "true";
export const enableBreakTrade =
    process.env.REACT_APP_ENABLE_BREAK_TRADE === "true";
export const enableSimpleMarketWatch =
    process.env.REACT_APP_ENABLE_SIMPLE_MARKET_WATCH === "true";
export const enableNewMarketWatch = process.env.REACT_APP_ENABLE_NEW_MARKET_WATCH === "true";
export const hideMarketWatchList =
    process.env.REACT_APP_HIDE_MARKET_WATCH_LIST === "true";
export const enableMarketWatchPriceDecimals =
    process.env.REACT_APP_ENABLE_MARKET_WATCH_PRICE_DECIMALS === "true";
export const showSymbolActivationPage =
    process.env.REACT_APP_SHOW_SYMBOL_ACTIVATION_PAGE === "true";
export const enableSplitBooksTable = process.env.REACT_APP_ENABLE_SPLIT_BOOKS_TABLE === "true";
export const allowIncompleteOnboardingForms = process.env.REACT_APP_ALLOW_INCOMPLETE_ONBOARDING_FORMS === "true";

export const dingSound = `${process.env.PUBLIC_URL}/ding.mp3`;

export const clientLoginPageText =
    process.env.REACT_APP_CLIENT_LOGIN_PAGE_TEXT || undefined;

export const emailOnboardingAdmin =
    process.env.REACT_APP_CLIENT_EMAIL_ONBOARDING_ADMIN === "true";

export const isQMSClient = process.env.REACT_APP_QMS_CLIENT === "true";

export const isSimpleRiskSetting =
    process.env.REACT_APP_ENABLE_SIMPLE_RISK_SETTINGS === "true";

export const isMarkerAsMarketMaker =
    process.env.REACT_APP_ENABLE_MARKER_AS_MARKET_MAKER === "true";

export const assetProfilePageTradeHistoryLink =
    process.env.REACT_APP_ASSET_PROFILE_PAGE_TRADE_HISTORY_LINK;

export const usePrevClosePriceAsLastPriceWhenLPIsNA =
    process.env.REACT_APP_USE_PREV_CLOSE_FOR_NA_LAST_PRICE === "true";

export const useUnsecureWebsocket =
    process.env.REACT_APP_UNSECURE_WEBSOCKET === "true";

export const autoSelectBuyOrder =
    process.env.REACT_APP_AUTO_SELECT_BUY_ORDER === "true";
export const enableUserAnnouncement =
    process.env.REACT_APP_ENABLE_USER_ANNOUNCEMENT === "true";

export const emptySymbolList =
    process.env.REACT_APP_EMPTY_SYMBOL_LIST === "true";

export const dispalyStageSystemStatus =
    process.env.REACT_APP_DISPLAY_STAGE_SYSTEM_STATUS === "true";

export const isBrassicaSandbox =
    process.env.REACT_APP_IS_BRASSICA_SANDBOX === "true";

export const enableCustodianSelection =
    process.env.REACT_APP_ENABLE_CUSTODIAN_SELECTION === "true";
export const custodians = process.env.REACT_APP_ENABLE_CUSTODIANS
    ? process.env.REACT_APP_ENABLE_CUSTODIANS.split(",")
    : [];
export const custodianLink = process.env.REACT_APP_CUSTODIAN_LINK_URL;

export const enableDetailedNotification =
    process.env.REACT_APP_ENABLE_DETAILED_NOTIFICATION === "true";

export const enableGttEodDefault =
    process.env.REACT_APP_ENABLE_GTT_EOD_DEFAULT === "true";

export const onLoginUseComplianceID =
    process.env.REACT_APP_ON_LOGIN_USE_COMPLIANCE_ID === "true";

export const tradeDestinations = process.env.REACT_APP_TRADE_DESTINATIONS
    ? process.env.REACT_APP_TRADE_DESTINATIONS.split(",")
    : [];
export const activeMarketTypes = process.env.REACT_APP_MARKET_TYPES
    ? process.env.REACT_APP_MARKET_TYPES.split(",")
    : ["ShowAll"];

export const hideHotKeyTitle =
    process.env.REACT_APP_HIDE_HOT_KEY_TITLE === "true";

export const hotKeyAutoMatch =
    process.env.REACT_APP_ENABLE_HOTKEY_MATCH === "true";

export const showTradePresets = process.env.REACT_APP_SHOW_TRADE_PRESETS === "true";

export const presetTradeLayout1 = {
    "pageSelected": "preset1",
    "styling": {
        "primaryBackgroundColor": "--DarkModePortal-background",
        "button": {
            "primaryFontColor": "--White",
            "secondaryBGColor": "--warning",
            "cancelBGColor": "--danger",
            "cancelFontColor": "--White",
            "primaryBGColor": "--MainButtonBG",
            "secondaryFontColor": "#212529"
        },
        "backgroundColor": "#0a2a2c",
        "tradeHistory": {
            "positiveChangeColor": "#00FF00",
            "neutralChangeColor": "#FFFFFF",
            "negativeChangeColor": "#FF0000"
        },
        "bookGradient": {
            "bookGradientActive": true,
            "buyBidColor": "#00008B",
            "sellAskTextColor": "--White",
            "sellAskColor": "#8B0000",
            "buyBidTextColor": "--White"
        },
        "fontSize": 2,
        "rowSizing": "normal",
        "fontColor": "--White",
        "headerFontColor": "--White"
    },
    "layout": [
        {
            "static": false,
            "w": 5,
            "moved": false,
            "h": 15,
            "x": 0,
            "y": 0,
            "i": "market-watch-1",
            "isResizable": true
        },
        {
            "static": false,
            "w": 15,
            "moved": false,
            "h": 9,
            "x": 11,
            "y": 1,
            "i": "trade-chart-1",
            "isResizable": true
        },
        {
            "static": false,
            "w": 6,
            "moved": false,
            "h": 14,
            "x": 5,
            "y": 1,
            "i": "books-table-0",
            "isResizable": true
        },
        {
            "static": false,
            "w": 15,
            "moved": false,
            "h": 1,
            "x": 11,
            "y": 0,
            "i": "market-statistic-1",
            "isResizable": true
        },
        {
            "static": false,
            "w": 15,
            "moved": false,
            "h": 5,
            "x": 11,
            "y": 10,
            "i": "orders-table-0",
            "isResizable": true
        },
        {
            "static": false,
            "w": 6,
            "moved": false,
            "h": 1,
            "x": 5,
            "y": 0,
            "i": "symbol-select-1",
            "isResizable": false
        }
    ],
    "itemLimits": {
        "symbol-select": 1,
        "market-watch": 1,
        "market-statistic": 1,
        "trade-chart": 1,
        "hotkey-select": 1,
        "orders-table": 2,
        "order-ticket": 1,
        "books-table": 1
    },
    "ordersTableTabs": {
        "orders-table-0": [
            "orders",
            "positions",
            "cancelled",
            "fills",
            "negotiate"
        ],

    },
    "configureEnabled": {
        "books-table-0": false,
        "orders-table-1": false,
        "books-table-1": false,
        "orders-table-0": false
    },
    "booksTableTabs": {
        "books-table-0": [
            "books",
            "history",
        ],

    },
    "lastSelectedTabs": {
        "books-table-0": "books",
        "orders-table-1": "orders",
        "order-ticket-0": "LIMIT"
    },
    "spacing": [
        2,
        2
    ],
    "padding": 6,
    "playSoundOnTrade": false,
    "useExternalBookFeed": false,
    "showConfigureOnMarketWatch": false,
    "componentBorders": true,
    "expandedOrderTicket": {
        "order-ticket-0": false,
        "order-ticket-1": false
    },
    "destinationFieldOnMain": {
        "order-ticket-0": true,
        "order-ticket-1": false
    },
    "activeOrderTableFiltering": {
        "orders-table-0": {
            "symbol": "",
            "side": "",
            "destination": ""
        }
    },
    "customSymbols": [
        "TESTJ",
        "TEST",
        "TEST10",
        "TEST9",
        "TEST8",
        "TEST7",
        "TEST6",
        "TEST5",
        "TEST4",
        "TEST3"
    ],
    "surpressExecutedNotifications": false,
    "surpressCancelledNotifications": false,
    "useExternalTobFeed": false,
    "fontSize": 1.85,
    "rowSizing": "compact"
}

export const presetTradeLayout2 = {
    "pageSelected": "preset2",
    "styling": {
        "primaryBackgroundColor": "--DarkModePortal-background",
        "button": {
            "primaryFontColor": "--White",
            "secondaryBGColor": "--warning",
            "cancelBGColor": "--danger",
            "cancelFontColor": "--White",
            "primaryBGColor": "--MainButtonBG",
            "secondaryFontColor": "#212529"
        },
        "backgroundColor": "#0a2a2c",
        "tradeHistory": {
            "positiveChangeColor": "#00FF00",
            "neutralChangeColor": "#FFFFFF",
            "negativeChangeColor": "#FF0000"
        },
        "bookGradient": {
            "bookGradientActive": true,
            "buyBidColor": "#00008B",
            "sellAskTextColor": "--White",
            "sellAskColor": "#8B0000",
            "buyBidTextColor": "--White"
        },
        "fontSize": 2,
        "rowSizing": "normal",
        "fontColor": "--White",
        "headerFontColor": "--White"
    },
    "layout": [
        {
            "static": false,
            "w": 5,
            "moved": false,
            "h": 15,
            "x": 0,
            "y": 0,
            "i": "market-watch-1",
            "isResizable": true
        },
        {
            "static": false,
            "w": 15,
            "moved": false,
            "h": 9,
            "x": 5,
            "y": 1,
            "i": "trade-chart-1",
            "isResizable": true
        },
        {
            "static": false,
            "w": 6,
            "moved": false,
            "h": 14,
            "x": 20,
            "y": 1,
            "i": "books-table-0",
            "isResizable": true
        },
        {
            "static": false,
            "w": 15,
            "moved": false,
            "h": 1,
            "x": 5,
            "y": 0,
            "i": "market-statistic-1",
            "isResizable": true
        },
        {
            "static": false,
            "w": 15,
            "moved": false,
            "h": 5,
            "x": 5,
            "y": 10,
            "i": "orders-table-0",
            "isResizable": true
        },
        {
            "static": false,
            "w": 6,
            "moved": false,
            "h": 1,
            "x": 20,
            "y": 0,
            "i": "symbol-select-1",
            "isResizable": false
        }
    ],
    "itemLimits": {
        "symbol-select": 1,
        "market-watch": 1,
        "market-statistic": 1,
        "trade-chart": 1,
        "hotkey-select": 1,
        "orders-table": 2,
        "order-ticket": 1,
        "books-table": 1
    },
    "ordersTableTabs": {
        "orders-table-0": [
            "orders",
            "positions",
            "cancelled",
            "fills",
            "negotiate"
        ],

    },
    "configureEnabled": {
        "books-table-0": false,
        "orders-table-1": false,
        "books-table-1": false,
        "orders-table-0": false
    },
    "booksTableTabs": {
        "books-table-0": [
            "books",
            "history",
            "qms"
        ],

    },
    "lastSelectedTabs": {
        "books-table-0": "books",
        "orders-table-1": "orders",
        "order-ticket-0": "LIMIT"
    },
    "spacing": [
        2,
        2
    ],
    "padding": 6,
    "playSoundOnTrade": false,
    "useExternalBookFeed": false,
    "showConfigureOnMarketWatch": false,
    "componentBorders": true,
    "expandedOrderTicket": {
        "order-ticket-0": false,
        "order-ticket-1": false
    },
    "destinationFieldOnMain": {
        "order-ticket-0": true,
        "order-ticket-1": false
    },
    "activeOrderTableFiltering": {
        "orders-table-0": {
            "symbol": "",
            "side": "",
            "destination": ""
        }
    },
    "customSymbols": [
        "TESTJ",
        "TEST",
        "TEST10",
        "TEST9",
        "TEST8",
        "TEST7",
        "TEST6",
        "TEST5",
        "TEST4",
        "TEST3"
    ],
    "surpressExecutedNotifications": false,
    "surpressCancelledNotifications": false,
    "useExternalTobFeed": false,
    "fontSize": 1.85,
    "rowSizing": "compact"
}

export const presetTradeLayout3 = {
    "pageSelected": "preset3",
    "styling": {
        "primaryBackgroundColor": "--DarkModePortal-background",
        "button": {
            "primaryFontColor": "--White",
            "secondaryBGColor": "--warning",
            "cancelBGColor": "--danger",
            "cancelFontColor": "--White",
            "primaryBGColor": "--MainButtonBG",
            "secondaryFontColor": "#212529"
        },
        "backgroundColor": "#0a2a2c",
        "tradeHistory": {
            "positiveChangeColor": "#00FF00",
            "neutralChangeColor": "#FFFFFF",
            "negativeChangeColor": "#FF0000"
        },
        "bookGradient": {
            "bookGradientActive": true,
            "buyBidColor": "#00008B",
            "sellAskTextColor": "--White",
            "sellAskColor": "#8B0000",
            "buyBidTextColor": "--White"
        },
        "fontSize": 2,
        "rowSizing": "normal",
        "fontColor": "--White",
        "headerFontColor": "--White"
    },
    "layout": [
        {
            "static": false,
            "w": 5,
            "moved": false,
            "h": 25,
            "x": 21,
            "y": 0,
            "i": "market-watch-1",
            "isResizable": true
        },
        {
            "static": false,
            "w": 15,
            "moved": false,
            "h": 9,
            "x": 6,
            "y": 1,
            "i": "trade-chart-1",
            "isResizable": true
        },
        {
            "static": false,
            "w": 6,
            "moved": false,
            "h": 14,
            "x": 0,
            "y": 11,
            "i": "books-table-0",
            "isResizable": true
        },
        {
            "static": false,
            "w": 15,
            "moved": false,
            "h": 1,
            "x": 6,
            "y": 0,
            "i": "market-statistic-1",
            "isResizable": true
        },
        {
            "static": false,
            "w": 15,
            "moved": false,
            "h": 15,
            "x": 6,
            "y": 10,
            "i": "orders-table-0",
            "isResizable": true
        },
        {
            "static": false,
            "w": 6,
            "moved": false,
            "h": 1,
            "x": 0,
            "y": 0,
            "i": "symbol-select-1",
            "isResizable": false
        },
        {
            "static": false,
            "minW": 6,
            "w": 6,
            "moved": false,
            "h": 10,
            "x": 0,
            "y": 1,
            "i": "order-ticket-1",
            "isResizable": true
        }
    ],
    "itemLimits": {
        "symbol-select": 1,
        "market-watch": 1,
        "market-statistic": 1,
        "trade-chart": 1,
        "hotkey-select": 1,
        "orders-table": 2,
        "order-ticket": 1,
        "books-table": 1
    },
    "ordersTableTabs": {
        "orders-table-0": [
            "orders",
            "positions",
            "cancelled",
            "fills",
            "negotiate"
        ],

    },
    "configureEnabled": {
        "books-table-0": false,
        "orders-table-1": false,
        "books-table-1": false,
        "orders-table-0": false
    },
    "booksTableTabs": {
        "books-table-0": [
            "books",
            "history",
            "qms"
        ],

    },
    "lastSelectedTabs": {
        "books-table-0": "books",
        "orders-table-1": "orders",
        "order-ticket-0": "LIMIT"
    },
    "spacing": [
        2,
        2
    ],
    "padding": 6,
    "playSoundOnTrade": false,
    "useExternalBookFeed": false,
    "showConfigureOnMarketWatch": false,
    "componentBorders": true,
    "expandedOrderTicket": {
        "order-ticket-0": false,
        "order-ticket-1": false
    },
    "destinationFieldOnMain": {
        "order-ticket-0": true,
        "order-ticket-1": false
    },
    "activeOrderTableFiltering": {
        "orders-table-0": {
            "symbol": "",
            "side": "",
            "destination": ""
        }
    },
    "customSymbols": [
        "TESTJ",
        "TEST",
        "TEST10",
        "TEST9",
        "TEST8",
        "TEST7",
        "TEST6",
        "TEST5",
        "TEST4",
        "TEST3"
    ],
    "surpressExecutedNotifications": false,
    "surpressCancelledNotifications": false,
    "useExternalTobFeed": false,
    "fontSize": 1.85,
    "rowSizing": "compact"
}