import { useState } from "react";
import { Button, Modal } from "react-bootstrap";

export const SelectTemplateModal = ({
    show = false,
    handleConfirm,
    handleClose,
    templateOptions,
}) => {
    const [selectedTemplate, setSelectedTemplate] = useState();

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Create Profile</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Select a asset template for this profile
                <br />
                <br />
                <select
                    className="custom-select custom-select-sm"
                    onChange={({ target: { value } }) => {
                        setSelectedTemplate(value);
                    }}
                >
                    <option value={undefined}>Select a Asset Type...</option>
                    {templateOptions
                        .filter((symb) => symb.value)
                        .sort((a, b) => a.label > b.label)
                        .map((template, i) => (
                            <option key={i} value={template.value}>
                                {template.label}
                            </option>
                        ))}
                </select>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="dark"
                    onClick={() => handleConfirm(selectedTemplate)}
                    disabled={
                        !selectedTemplate ||
                        selectedTemplate === "Select a Asset Type..."
                    }
                >
                    Confirm
                </Button>
                <Button variant="light" onClick={handleClose}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
