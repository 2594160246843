import { useState } from "react";
import { Button, Row } from "react-bootstrap";
import { styled } from "styled-components";

import {
    customTradeEnabled,
    hideHotKeyTitle,
    hotKeyAutoMatch,
} from "../../../config";
import { storeOptions } from "../../../helpers/display-options";
import { convertStylingVar } from "../../../helpers/other";
import { useStore } from "../../../Store";
import { useSocket } from "../../../Websocket";
import { HotKeyModal } from "./HotKeyModal";

const CreateButton = styled(({ className, onClick }) => (
    <Button
        variant="primary"
        onClick={onClick}
        className={`${className} hotkey-create-button`}
    >
        Create
    </Button>
))`
    &:not(.dropdown-toggle) {
        background: ${(props) => props.buttonBackground} !important;
        border-color: ${(props) => props.buttonBackground} !important;
        color: ${(props) => props.fontColor} !important;
        &:hover {
            filter: brightness(0.75) !important;
            background: ${(props) => props.buttonBackground} !important;
            color: ${(props) => props.fontColor} !important;
        }
    }
`;

const HotKeyButton = styled(({ className, onClick, label }) => (
    <Button
        variant="secondary"
        onClick={onClick}
        className={`${className} hotkey-button`}
        size="sm"
    >
        {label}
    </Button>
))`
    &:not(.dropdown-toggle) {
        background: ${(props) => props.buttonBackground} !important;
        border-color: ${(props) => props.buttonBackground} !important;
        color: ${(props) => props.fontColor} !important;
        &:hover {
            filter: brightness(0.75) !important;
            background: ${(props) => props.buttonBackground} !important;
            color: ${(props) => props.fontColor} !important;
        }
    }
`;

// eslint-disable-next-line no-unused-vars
export const HotKeySavedOrders = ({ layoutKey }) => {
    const { state, dispatch } = useStore();
    const ws = useSocket();

    const { layoutConfigurationOptions, currentSymbol } = state.trade;
    const keyType = "trade_preferences";
    const optionKey = "trade_screen";
    const savedOrders =
        state.account?.account?.data?.attr?.[keyType]?.[optionKey]?.hotkey ||
        [];

    const [currentHotKeyOrder, setCurrentHotKeyOrder] = useState({});
    const [showHotKeyModal, setShowHotKeyModal] = useState(false);
    const [isCreate, setIsCreate] = useState(true);

    const handleShowHotKeyModal = (savedOrder, create) => {
        setCurrentHotKeyOrder(
            hotKeyAutoMatch &&
                !customTradeEnabled &&
                !create &&
                !savedOrder.security &&
                !savedOrder.price
                ? {
                      ...savedOrder,
                      security: currentSymbol.security,
                      //   price: parseFloat(price) === 0 ? "" : price,
                  }
                : savedOrder
        );
        setShowHotKeyModal(true);
        setIsCreate(create);
    };
    const handleCloseHotKeyModal = () => setShowHotKeyModal(false);

    const saveNewHotKeyOrder = (currentOrder) =>
        storeOptions(
            ws,
            dispatch,
            state.account?.account?.data,
            keyType,
            optionKey,
            [...savedOrders, currentOrder],
            "hotkey"
        );

    const updateExistingHotKeyOrder = (currentOrder) =>
        storeOptions(
            ws,
            dispatch,
            state.account?.account?.data,
            keyType,
            optionKey,
            savedOrders.map((order) =>
                order.id === currentOrder.id ? currentOrder : order
            ),
            "hotkey"
        );

    const deleteExistingHotKeyOrder = (currentOrder) =>
        storeOptions(
            ws,
            dispatch,
            state.account?.account?.data,
            keyType,
            optionKey,
            savedOrders.filter((order) => order.id !== currentOrder.id),
            "hotkey"
        );

    const isAccountNotActive = state?.account?.account?.data?.active !== "Y";

    const primaryButtonColor = convertStylingVar(
        layoutConfigurationOptions.styling.button.primaryBGColor
    );
    const primaryFontColor = convertStylingVar(
        layoutConfigurationOptions.styling.button.primaryFontColor
    );
    const fontColor = convertStylingVar(
        layoutConfigurationOptions.styling.fontColor
    );

    const secondaryButtonColor = convertStylingVar(
        layoutConfigurationOptions.styling.button.secondaryBGColor
    );
    const secondaryFontColor = convertStylingVar(
        layoutConfigurationOptions.styling.button.secondaryFontColor
    );

    return (
        <div
            style={{
                padding: layoutConfigurationOptions?.padding,
                overflowY: "scroll",
                border: layoutConfigurationOptions?.componentBorders
                    ? `1px solid ${fontColor}`
                    : 0,
                height: "100%",
            }}
            className="size-sm"
        >
            {isAccountNotActive && (
                <div className="order-ticket-overlay">
                    <div>Your account is not active.</div>
                </div>
            )}

            <div
                style={{
                    borderBottom: `1px solid ${convertStylingVar(
                        layoutConfigurationOptions.styling.fontColor
                    )} `,
                }}
                className="pb-2 d-flex justify-content-between flex-wrap"
            >
                <div
                    className="d-flex"
                    style={{ flexDirection: "column", justifyContent: "end" }}
                >
                    <h6
                        style={{
                            color: convertStylingVar(
                                layoutConfigurationOptions.styling
                                    .headerFontColor
                            ),
                        }}
                    >
                        {hideHotKeyTitle === true ? (
                            <></>
                        ) : (
                            <b>Hot Button Orders</b>
                        )}
                    </h6>
                </div>
                <CreateButton
                    fontColor={primaryFontColor}
                    buttonBackground={primaryButtonColor}
                    onClick={() => handleShowHotKeyModal({}, true)}
                />
            </div>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    flexDirection: "row",
                    gap: "8px",
                }}
            >
                {savedOrders.length > 0 ? (
                    savedOrders.map((order, i) => (
                        <Row
                            noGutters
                            key={i}
                            className="cursor-pointer pt-2 px-1 hotkey-order"
                            onClick={() => handleShowHotKeyModal(order, false)}
                        >
                            <HotKeyButton
                                fontColor={
                                    order?.textColor ||
                                    (order?.side === "S"
                                        ? secondaryFontColor
                                        : primaryFontColor)
                                }
                                buttonBackground={
                                    order?.backgroundColor ||
                                    (order?.side === "S"
                                        ? secondaryButtonColor
                                        : primaryButtonColor)
                                }
                                className="w-100"
                                label={order?.title || "Untitled Order"}
                            />
                        </Row>
                    ))
                ) : (
                    <>No saved orders available.</>
                )}
            </div>
            {showHotKeyModal && !isAccountNotActive && (
                <HotKeyModal
                    show={showHotKeyModal}
                    handleClose={handleCloseHotKeyModal}
                    savedHotKeyOrder={currentHotKeyOrder}
                    isCreate={isCreate}
                    saveNewHotKeyOrder={saveNewHotKeyOrder}
                    updateExistingHotKeyOrder={updateExistingHotKeyOrder}
                    deleteExistingHotKeyOrder={deleteExistingHotKeyOrder}
                />
            )}
        </div>
    );
};
