import { memo, useEffect } from "react";
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";

import {
    allowIncompleteOnboardingForms,
    disableRegister,
    emailIssuer,
    enableUserAccounts,
    enableUserAnnouncement,
    hideCreditsDebits,
    hideMyFundsPage,
    hideTradeReports,
    hideTradeScreenWhenInactive,
    isQMSClient,
    showAnnouncments,
    showApprovalsPage,
    showAssetBuilder,
    showCompanyDocumentsPage,
    showFundsPage,
    showOnboarding,
    showPortfolio,
    showRisk,
    showSymbolActivationPage,
    showTradingPortal,
} from "./config";
import {
    AccountDetail,
    Activity,
    AddSymbolPage,
    AdminHome,
    AdminLayout,
    AnnouncementTemplates,
    Approvals,
    AssetProfiles,
    AssetTemplates,
    CaseBuilder,
    CaseManagement,
    CreateProfilePage,
    CreateTemplatePage,
    DefineRolesPage,
    DepositWithdraw,
    EditAnnouncementTemplate,
    EditFormPage,
    EditProfilePage,
    EditSymbolPage,
    EditTemplatePage,
    Firms,
    FormBuilder,
    Forms,
    HolidaysPage,
    ManageAccountsPage,
    ManageUsersPage,
    MarketSymbols,
    PatternDetectionAlert,
    Positions,
    Preferences,
    ReviewSubmissions,
    RiskAlert,
    RiskMonitor,
    RiskSetting,
    StatusPage,
    SymbolPage,
    Symbols,
    SymbolStatus,
    TradeReport,
    // Transfers,
    Users,
} from "./containers/admin";
import SymbolActivation from "./containers/admin/market/SymbolActivation";
import CompanyDocumentsPage from "./containers/admin/settings/CompanyDocuments";
import {
    AssetProfilePage,
    AssetsLandingPage,
    AssetsLayout,
} from "./containers/assets";
import ForgotPassword from "./containers/ForgotPassword";
import LoadingWrapper from "./containers/LoadingWrapper";
import Login from "./containers/Login";
import {
    ConfirmationPage,
    OnboardingHome,
    OnboardingLayout,
} from "./containers/onboarding";
import {
    DashboardPage,
    Funds,
    InstructionsPage,
    MarketWatch,
    MyFunds,
    Notifications,
    PortalLayout,
    Portfolio,
    Profile,
    TradeScreen,
    TradeScreenConfigurePage,
    Transactions,
} from "./containers/portal/";
import CompanyDocs from "./containers/portal/CompanyDocs";
import PortalHome from "./containers/portal/PortalHome";
import Register from "./containers/Register";
import ResetPasswordPage from "./containers/ResetPasswordPage";
import VerifyEmailPage from "./containers/VerifyEmailPage";
import * as ACTIONS from "./contexts/actions/account";
import { checkLoginStatus } from "./helpers/login";
import { isAdmin } from "./helpers/other";
import { useStore } from "./Store";
import { DataProvider } from "./SubscriptionData"; // Ensure DataProvider is imported
import { WebsocketProvider } from "./Websocket";

const AppRoutes = () => {
    const { state, dispatch } = useStore();
    const { account } = state;

    const isLoggedIn = state.account.auth.status;
    const { user_type, status, email_confirmed } = state.account.account.data;
    const { roles } = state.account;

    useEffect(() => {
        dispatch(ACTIONS.authRequest());
        checkLoginStatus(dispatch);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <DataProvider>
            <WebsocketProvider isLoggedIn={isLoggedIn} roles={roles}>
                <LoadingWrapper
                    auth={isLoggedIn}
                    isLoading={!account.account.loaded}
                >
                    <RoutesContainer
                        isLoggedIn={isLoggedIn}
                        roles={roles}
                        user_type={user_type}
                        status={status}
                        isUserActive={account.account.data.active === "Y"}
                        emailConfirmed={email_confirmed}
                    />
                </LoadingWrapper>
            </WebsocketProvider>
        </DataProvider>
    );
};

// eslint-disable-next-line react/display-name
const RoutesContainer = memo(
    ({
        isLoggedIn,
        roles,
        user_type,
        status,
        // eslint-disable-next-line no-unused-vars
        emailConfirmed = "N",
        isUserActive,
    }) => {
        const location = useLocation();
        const isUserAdmin = isAdmin(user_type, roles);

        const OnboardingRedirectHandler = ({ children }) => {
            if (showOnboarding) {
                if (["open", "rejected"].includes(status)) {
                    return location.pathname === "/" ||
                        location.pathname.startsWith("/forms/") || allowIncompleteOnboardingForms ? (
                        children
                    ) : (
                        <Navigate to="/onboarding" />
                    );
                } else if (status === "approved") {
                    return children;
                }
            }

            return children;
        };

        // Route handlers
        const LoginRequired = ({ children }) =>
            isLoggedIn ? (
                <OnboardingRedirectHandler>
                    {children}
                </OnboardingRedirectHandler>
            ) : (
                <Navigate to="/login" />
            );

        const ActiveUserOnly = ({ children }) =>
            isUserActive ? children : <Navigate to="/portal" />;

        const NoLoggedIn = ({ children }) => {
            if (isLoggedIn) {
                return <Navigate to={"/portal"} />;
            }

            return children;
        };

        const AdminOnly = ({ children }) => {
            if (isLoggedIn) {
                // If user is a admin, allow them to visit this page.
                if (isUserAdmin) {
                    return children;
                }

                return <Navigate to="/" />;
            }
            return <Navigate to="/login" />;
        };

        // const FirmUserOnly = ({ children }) => {
        //     if (isLoggedIn) {
        //         // If user is a firm admin/user, allow them to visit this page.
        //         if (user_type === "F") {
        //             return children;
        //         }
        //         return <Navigate to="/" />;
        //     }
        //     return <Navigate to="/login" />;
        // };

        const CustodyAdminOnly = ({ children }) => {
            if (isLoggedIn) {
                // If custody admin, allow them to visit this page.
                if (roles.custody) {
                    return children;
                }
                return <Navigate to="/" />;
            }

            return <Navigate to="/login" />;
        };

        const TradeAdminOnly = ({ children }) => {
            if (isLoggedIn) {
                // if trade admin, allow them to visit this page.
                if (roles.trading) {
                    return children;
                }
                return <Navigate to="/" />;
            }
            return <Navigate to="/login" />;
        };

        const FirmOrTradeAdminOnly = ({ children }) => {
            if (isLoggedIn) {
                if (roles.trading || roles.firm_admin) {
                    return children;
                }
                return <Navigate to="/" />;
            }
            return <Navigate to="/login" />;
        };

        const AssetBuilderAdminOnly = ({ children }) => {
            if (isLoggedIn) {
                // if asset builder admin, allow them to visit this page.
                if (roles.asset_builder) {
                    return children;
                }
                return <Navigate to="/" />;
            }
            return <Navigate to="/login" />;
        };

        const OnboardingAdminOnly = ({ children }) => {
            if (isLoggedIn) {
                // if onboarding admin, allow them to visit this page.
                if (roles.onboarding_admin) {
                    return children;
                }
                return <Navigate to="/" />;
            }
            return <Navigate to="/login" />;
        };

        const RiskAdminOnly = ({ children }) => {
            if (isLoggedIn) {
                // if risk admin, allow them to visit this page.
                if (roles.risk_admin) {
                    return children;
                }
                return <Navigate to="/" />;
            }
            return <Navigate to="/login" />;
        };
        // Routes
        const AdminAssetBuilderRoutes = () => (
            <Route
                element={
                    <AssetBuilderAdminOnly>
                        <Outlet />
                    </AssetBuilderAdminOnly>
                }
            >
                <Route
                    exact
                    path="/admin/asset-templates"
                    element={<AssetTemplates />}
                />
                <Route
                    exact
                    path="/admin/asset-templates/create"
                    element={<CreateTemplatePage />}
                />
                <Route
                    exact
                    path="/admin/asset-templates/:template/edit"
                    element={<EditTemplatePage />}
                />
                <Route
                    exact
                    path="/admin/asset-profiles"
                    element={<AssetProfiles />}
                />
                <Route
                    exact
                    path="/admin/asset-profiles/create"
                    element={<CreateProfilePage />}
                />
                <Route
                    exact
                    path="/admin/asset-profiles/:profile/edit"
                    element={<EditProfilePage />}
                />
                <Route
                    exact
                    path="/admin/market-symbols"
                    element={<MarketSymbols />}
                />
            </Route>
        );

        const AdminOnboardingRoutes = () => (
            <Route
                element={
                    <OnboardingAdminOnly>
                        <Outlet />
                    </OnboardingAdminOnly>
                }
            >
                <Route exact path="/admin/forms" element={<Forms />} />
                <Route
                    exact
                    path="/admin/forms/create"
                    element={<FormBuilder />}
                />
                <Route
                    exact
                    path="/admin/forms/:form/edit"
                    element={<EditFormPage />}
                />
                <Route
                    exact
                    path="/admin/roles"
                    element={<DefineRolesPage />}
                />
                {!roles.firm_admin && user_type !== "F" && (
                    <>
                        <Route
                            exact
                            path="/admin/manage-users"
                            element={<ManageUsersPage />}
                        />
                        <Route
                            exact
                            path="/admin/submissions"
                            element={<ReviewSubmissions />}
                        />
                        {enableUserAccounts && (
                            <>
                                <Route
                                    exact
                                    path="/admin/manage-accounts"
                                    element={<ManageAccountsPage />}
                                />
                                <Route
                                    exact
                                    path="/admin/manage-accounts/:id"
                                    element={<AccountDetail />}
                                />
                            </>
                        )}
                    </>
                )}
            </Route>
        );

        const AdminRiskRoutes = () => (
            <Route
                element={
                    <RiskAdminOnly>
                        <Outlet />
                    </RiskAdminOnly>
                }
            >
                <Route
                    exact
                    path="/admin/case-management"
                    element={<CaseManagement />}
                />
                <Route
                    exact
                    path="/admin/case-management/:id/edit"
                    element={<CaseBuilder />}
                />
                <Route
                    exact
                    path="/admin/risk-monitor"
                    element={<RiskMonitor />}
                />
                <Route
                    exact
                    path="/admin/risk-setting"
                    element={<RiskSetting />}
                />
                <Route exact path="/admin/risk-alert" element={<RiskAlert />} />
                <Route
                    exact
                    path="/admin/pattern-detection-alert"
                    element={<PatternDetectionAlert />}
                />
            </Route>
        );

        const AdminRoutes = () => (
            <Route
                element={
                    <AdminOnly>
                        <AdminLayout>
                            <Outlet />
                        </AdminLayout>
                    </AdminOnly>
                }
            >
                <Route
                    path={isUserAdmin ? "*" : "/admin/*"}
                    element={<Navigate to="/" />}
                />
                {isUserAdmin && <Route path="/" element={<AdminHome />} />}
                <Route exact path="/admin/firms" element={<Firms />} />
                <Route exact path="/admin/users" element={<Users />} />

                {/* <Route
                    exact
                    path="/admin/transfers"
                    element={
                        <FirmUserOnly>
                            <Transfers />
                        </FirmUserOnly>
                    }
                /> */}
                {showApprovalsPage && (
                    <Route
                        exact
                        path="/admin/approvals"
                        element={
                            <CustodyAdminOnly>
                                <Approvals />
                            </CustodyAdminOnly>
                        }
                    />
                )}
                {!hideCreditsDebits && (
                    <Route
                        exact
                        path="/admin/transactions"
                        element={
                            <CustodyAdminOnly>
                                <DepositWithdraw />
                            </CustodyAdminOnly>
                        }
                    />
                )}
                <Route
                    exact
                    path="/admin/activity"
                    element={
                        <TradeAdminOnly>
                            <Activity />
                        </TradeAdminOnly>
                    }
                />
                <Route
                    exact
                    path="/admin/symbols"
                    element={
                        <TradeAdminOnly>
                            <Symbols />
                        </TradeAdminOnly>
                    }
                />
                <Route
                    exact
                    path="/admin/symbol-status"
                    element={
                        <FirmOrTradeAdminOnly>
                            <SymbolStatus />
                        </FirmOrTradeAdminOnly>
                    }
                />
                <Route
                    exact
                    path="/admin/symbols/:security"
                    element={
                        <FirmOrTradeAdminOnly>
                            <SymbolPage />
                        </FirmOrTradeAdminOnly>
                    }
                />
                <Route
                    exact
                    path="/admin/symbols/create"
                    element={
                        <TradeAdminOnly>
                            <AddSymbolPage />
                        </TradeAdminOnly>
                    }
                />
                <Route
                    exact
                    path="/admin/symbols/:security/edit"
                    element={
                        <TradeAdminOnly>
                            <EditSymbolPage />
                        </TradeAdminOnly>
                    }
                />
                {!hideTradeReports && (
                    <Route
                        exact
                        path="/admin/trade-report"
                        element={
                            <TradeAdminOnly>
                                <TradeReport />
                            </TradeAdminOnly>
                        }
                    />
                )}
                <Route exact path="/admin/status" element={<StatusPage />} />
                <Route
                    exact
                    path="/admin/preferences"
                    element={<Preferences />}
                />
                <Route
                    exact
                    path="/admin/holidays"
                    element={<HolidaysPage />}
                />
                {showSymbolActivationPage && (
                    <Route
                        exact
                        path="/admin/symbol-activation"
                        element={<SymbolActivation />}
                    />
                )}
                {showCompanyDocumentsPage && (
                    <Route
                        exact
                        path="/admin/docs"
                        element={<CompanyDocumentsPage />}
                    />
                )}
                {!hideCreditsDebits && (
                    <Route
                        exact
                        path="/admin/positions"
                        element={
                            <CustodyAdminOnly>
                                <Positions />
                            </CustodyAdminOnly>
                        }
                    />
                )}
                {showAnnouncments && (
                    <>
                        <Route
                            exact
                            path="/admin/announcement-templates"
                            element={
                                <RiskAdminOnly>
                                    <AnnouncementTemplates />
                                </RiskAdminOnly>
                            }
                        />
                        <Route
                            exact
                            path="/admin/announcement-templates/:id"
                            element={
                                <RiskAdminOnly>
                                    <EditAnnouncementTemplate />
                                </RiskAdminOnly>
                            }
                        />
                    </>
                )}
                {showRisk && AdminRiskRoutes()}
                {showAssetBuilder && AdminAssetBuilderRoutes()}
                {showOnboarding && AdminOnboardingRoutes()}
            </Route>
        );

        const PortalRoutes = () => (
            <Route
                element={
                    <LoginRequired>
                        <PortalLayout>
                            <Outlet />
                        </PortalLayout>
                    </LoginRequired>
                }
            >
                {!isUserAdmin && (
                    <Route
                        exact
                        path="/"
                        element={<Navigate to={"/portal"} />}
                    />
                )}
                <Route path="/portal/*" element={<Navigate to="/portal" />} />
                {/* <Route exact path="/portal" element={<TradeList />} /> */}
                (
                <Route
                    exact
                    path="/portal/trade/:security"
                    element={
                        hideTradeScreenWhenInactive ? (
                            <ActiveUserOnly>
                                <TradeScreen />
                            </ActiveUserOnly>
                        ) : (
                            <TradeScreen />
                        )
                    }
                />
                )
                <Route
                    exact
                    path="/portal/configure"
                    element={<TradeScreenConfigurePage />}
                />
                <Route
                    exact
                    path={isQMSClient ? "/portal/market-watch" : "/portal"}
                    element={<MarketWatch />}
                />
                {showCompanyDocumentsPage && (
                    <Route
                        exact
                        path={"/portal/docs"}
                        element={<CompanyDocs />}
                    />
                )}
                {isQMSClient && (
                    <>
                        <Route
                            exact
                            path={"/portal"}
                            element={<PortalHome />}
                        />
                        <Route
                            exact
                            path={"/portal/instructions"}
                            element={<InstructionsPage />}
                        />
                    </>
                )}
                {!isQMSClient && (
                    <Route
                        exact
                        path="/portal/transactions"
                        element={<Transactions />}
                    />
                )}
                {!hideMyFundsPage && (
                    <Route
                        exact
                        path="/portal/my-funds"
                        element={<MyFunds />}
                    />
                )}
                <Route exact path="/profile" element={<Profile />} />
                {showPortfolio && (
                    <Route
                        exact
                        path="/portal/portfolio"
                        element={<Portfolio />}
                    />
                )}
                {showFundsPage && (
                    <Route exact path="/portal/funds" element={<Funds />} />
                )}
                {isLoggedIn && showAssetBuilder && (
                    <>
                        <Route
                            exact
                            path="/assets"
                            element={<AssetsLandingPage />}
                        />
                        <Route
                            path="/assets/:symbol"
                            element={<AssetProfilePage />}
                        />
                    </>
                )}
                {/* Portal Dashboard, temporary URL */}
                <Route exact path="/portal/dashboard" element={<DashboardPage />} />
                {emailIssuer && (
                    <Route
                        exact
                        path="/portal/notifications"
                        element={<Notifications />}
                    />
                )}
                {showAnnouncments && enableUserAnnouncement && (
                    <Route
                        exact
                        path="/assets/announcement-templates/:id"
                        element={<EditAnnouncementTemplate />}
                    />
                )}
            </Route>
        );

        const LandingRoutes = () => (
            <Route
                element={
                    <AssetsLayout>
                        <Outlet />
                    </AssetsLayout>
                }
            >
                {!showOnboarding && !isLoggedIn && (
                    <Route exact path="/" element={<Navigate to="/login" />} />
                )}

                {showAssetBuilder ? (
                    <>
                        <Route
                            exact
                            path="/assets"
                            element={<AssetsLandingPage />}
                        />
                        <Route
                            path="/assets/:symbol"
                            element={<AssetProfilePage />}
                        />
                    </>
                ) : (
                    <Route
                        exact
                        path="/assets"
                        element={<Navigate to="/login" />}
                    />
                )}
            </Route>
        );

        const OnboardingRoutes = () => (
            <Route
                element={
                    <LoginRequired>
                        <OnboardingLayout>
                            <Outlet />
                        </OnboardingLayout>
                    </LoginRequired>
                }
            >
                {!allowIncompleteOnboardingForms && <Route path="/*" element={<Navigate to="/" />} />}
                <Route
                    exact
                    path="/forms/:formId"
                    element={<OnboardingHome />}
                />
                <Route exact path={
                    allowIncompleteOnboardingForms ? "/onboarding" : "/"
                } element={<ConfirmationPage />} />
            </Route>
        );

        return (
            <Routes>
                <Route
                    exact
                    path="/login"
                    element={
                        <NoLoggedIn>
                            <Login />
                        </NoLoggedIn>
                    }
                />
                <Route
                    exact
                    path="/forgot-password"
                    element={
                        <NoLoggedIn>
                            <ForgotPassword />
                        </NoLoggedIn>
                    }
                />
                <Route
                    exact
                    path="/reset-password"
                    element={
                        <NoLoggedIn>
                            <ResetPasswordPage />
                        </NoLoggedIn>
                    }
                />
                <Route exact path="/verify" element={<VerifyEmailPage />} />
                {!disableRegister && (
                    <Route
                        exact
                        path="/register"
                        element={
                            <NoLoggedIn>
                                <Register />
                            </NoLoggedIn>
                        }
                    />
                )}
                {AdminRoutes()}
                {showOnboarding && OnboardingRoutes()}
                {showTradingPortal && PortalRoutes()}
                {!isLoggedIn && LandingRoutes()}
            </Routes>
        );
    }
);

export default AppRoutes;
