import { Button, Modal } from "react-bootstrap";

export const ConfirmModal = ({
    show = false,
    handleConfirm,
    handleClose,
    title,
    body,
    size = "md",
}) => {
    return (
        <Modal show={show} onHide={handleClose} size={size} className="trade-confirm-modal">
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{body}</Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleConfirm}>
                    Confirm
                </Button>
                <Button variant="light" onClick={handleClose}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
