import { Row } from "react-bootstrap";

const DashboardPage = () => {
    return (
        <div className="m-4 d-flex justify-content-center">
            <div className="w-100 ">
                <Row noGutters className="forms-header">
                    <h4>My Dashboard</h4>
                </Row>
                test
            </div>
        </div>
    );
};

export default DashboardPage;
