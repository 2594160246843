import { Modal } from "react-bootstrap";

import { AdditionalFlagsForm } from "./trade/order-ticket/AdditionalFlagsForm";

export const AdditionalFlagsModal = ({
    show = false,
    handleClose,
    currentOrder,
    additionalFlags,
    onChange,
    layoutKey,
}) => {
    return (
        <Modal
            size="lg"
            show={show}
            onHide={handleClose}
            className="trade-confirm-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title>Additional Flags</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="order-ticket-flex-parent active">
                    <AdditionalFlagsForm
                        layoutKey={layoutKey}
                        currentOrder={currentOrder}
                        additionalFlags={additionalFlags}
                        onChange={(key, val, key1, val2, additionalFields) =>
                            onChange(
                                key,
                                val || undefined,
                                key1,
                                val2 || undefined,
                                additionalFields
                            )
                        }
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
};
