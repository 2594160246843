/* eslint-disable react/display-name */

import { memo } from "react";

import { Loading } from "../../../Loading";
import { TableRenderer } from "../../../TableRenderer";

export const CreditsDebits = memo(({ balances, loading = false }) => {
    return loading ? (
        <Loading />
    ) : (
        <TableRenderer
            keyField="traderefno"
            keyType={"market_preferences"}
            optionKey={"creditsdebits"}
            defaultOptions={[
                "firm",
                "userid",
                "type",
                "security",
                "amount",
                "time",
            ]}
            exportCSVName="credit_debits"
            data={balances}
            noDataIndication="No balances available."
            pagination
            striped
            wrapperClasses="size-sm"
        />
    );
});
