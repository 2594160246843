import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";

import { DepositWithdrawForm } from "../../../components/admin/market/depositwithdraw";
import { getUsersFromWebsocket } from "../../../helpers/firm";
import { submitTransaction } from "../../../helpers/market";
import { queryExistingAccounts } from "../../../helpers/onboarding";
import { useStore } from "../../../Store";
import { useSocket } from "../../../Websocket";

const DepositWithdraw = () => {
    const { dispatch, state } = useStore();
    const ws = useSocket();
    const {
        market: { symbols },
        firm: { firms, existing_users: users },
        onboarding: { accounts },
    } = state;

    useEffect(() => {
        if (users.length === 0) {
            getUsersFromWebsocket(ws, dispatch);
        }
        if (accounts.length === 0) {
            queryExistingAccounts(ws, dispatch);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmitTransaction = ({ type, ...props }) =>
        submitTransaction(ws, type, props).then(() =>
            toast.success(
                type === "deposit"
                    ? "Succesfully deposited."
                    : "Withdraw request submitted."
            )
        );

    return (
        <div className="m-4">
            <Row noGutters className="forms-header justify-content-between">
                <Col>
                    <h4>Deposit & Withdraw</h4>
                </Col>
            </Row>
            <DepositWithdrawForm
                accounts={accounts}
                users={users}
                securities={symbols}
                firms={firms}
                submitTransaction={handleSubmitTransaction}
            />
        </div>
    );
};

export default DepositWithdraw;
