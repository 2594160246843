import { memo, useState } from "react";
import { Col, FormControl, Row } from "react-bootstrap";

import { useStore } from "../../../Store";
import { InvestigateExecutionsTable } from "./InvestigateExecutionsTable";
import { InvestigateOpenOrdersTable } from "./InvestigateOpenOrdersTable";
import { InvestigatePreviousRejectsTable } from "./InvestigatePreviousRejectsTable";

// eslint-disable-next-line react/display-name
export const InvestigateTab = memo(({ tradersMentioned = [] }) => {
    const { state } = useStore();

    const [trader, setTrader] = useState();
    const [symbol, setSymbol] = useState("");
    const [firm, setFirm] = useState();

    const { existing_users: users } = state.firm;

    return (
        <>
            <Row noGutters className="mt-4 mb-2">
                <Col xs={3} className="mt-1">
                    Select a trader to investigate:
                </Col>
                <Col xs={3}>
                    <FormControl
                        as={"select"}
                        onChange={({ target: { value } }) => {
                            setTrader(value);
                            const matchedUser = users.find(
                                (usr) =>
                                    usr.complianceid === value ||
                                    usr.userid === value
                            );
                            setFirm(matchedUser.firm);
                        }}
                        size="sm"
                    >
                        <option value="">
                            Traders mentioned in this case...
                        </option>
                        {tradersMentioned.map((trdr, i) => (
                            <option key={i} value={trdr}>
                                {trdr}
                            </option>
                        ))}
                    </FormControl>
                </Col>
            </Row>
            <InvestigateOpenOrdersTable
                trader={trader}
                symbol={symbol}
                setSymbol={setSymbol}
                firm={firm}
            />
            <br />
            <InvestigateExecutionsTable trader={trader} />
            <br />
            <InvestigatePreviousRejectsTable trader={trader} />
        </>
    );
});
