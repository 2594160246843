import * as CONSTANTS from "../constants/other";

export const queryLinks = (links) => ({
    type: CONSTANTS.QUERY_LINKS,
    links,
});

export const connectSocket = () => ({
    type: CONSTANTS.CONNECT_SOCKET,
});

export const disconnectSocket = () => ({
    type: CONSTANTS.DISCONNECT_SOCKET,
});

export const toggleDimLoading = () => ({ type: CONSTANTS.TOGGLE_DIM_LOADING });

export const clearState = () => ({
    type: CONSTANTS.CLEAR_STATE,
});
