import lodash from "lodash";
import moment from "moment";
import { toast } from "react-toastify";

import request from "../apiRequest";
import {
    clientCurrency,
    clientDateFormat,
    orderAlertPercentage,
} from "../config";
import * as ACCOUNT_ACTIONS from "../contexts/actions/account";
import * as ACTIONS from "../contexts/actions/other";
import { sendMessageQuery } from "./websocket";

export const momentDateFormat = clientDateFormat.toUpperCase();

export const formatResultString = (string) =>
    (string.charAt(0).toUpperCase() + string.slice(1)).replace("_", " ");

export const formatNumber = (number, decimal = 2) =>
    // Format number with commas, and decimal
    parseFloat(number)
        .toFixed(decimal)
        .replace(/(?<!\.\d*)(\d)(?=(?:\d{3})+(?:\.|$))/gm, "$1,");

// Same as formatNumber, add commas and decimal points but dont remove trailing zeroes
export const formatNumberWithDecimal = (number, decimal = 2) => {
    const withDecimal = parseFloat(number).toFixed(Math.max(number.split('.')[1]?.length, decimal) || decimal);
    const withCommas = withDecimal.replace(/(?<!\.\d*)(\d)(?=(?:\d{3})+(?:\.|$))/gm, "$1,");

    return withCommas;

}

export const formatDate = (stamp) => {
    const today = moment();
    const date = moment(parseInt(stamp));

    const A_WEEK_OLD = today.clone().subtract(7, "days").startOf("day");
    const YESTERDAY = today.clone().subtract(1, "days").startOf("day");

    // if before this year
    if (date.isBefore(today.startOf("year"))) {
        return date.format("L");
    } else if (date.isSame(YESTERDAY, "d")) {
        // If yesterday
        return "Yesterday";
    } else if (
        !date.isAfter(A_WEEK_OLD) &&
        date.isAfter(today.startOf("year"))
    ) {
        // If more than a week old and this year
        return date.format("MMM Do");
    }
    // Return time if its today
    return date.format("LT");
};

export const formatTimestamp = (stamp) => {
    const date = moment(Date.parse(stamp) || parseInt(stamp));
    if (!date.isValid()) return stamp;
    return date.format(`${momentDateFormat}, HH:mm:ss A`);
};

export const thirtyDaysAgo = () => {
    const today = new Date();
    const thirtyDays = new Date(new Date().setDate(today.getDate() - 30));

    thirtyDays.setHours(0, 0, 0, 0);

    return thirtyDays;
};

export const sevenDaysAgo = () => {
    const today = new Date();
    const sevenDays = new Date(new Date().setDate(today.getDate() - 7));

    sevenDays.setHours(0, 0, 0, 0);

    return sevenDays;
};

export const formatTableColumn = (
    header,
    style,
    customStyling,
    customFormatter = undefined,
    sortSetting = true,
    tableOrigin = undefined
) => {
    //  ["liveqty", "price", "execprice", "execqty", "qty"];
    const commaType = [
        // Overlap with risk_setting doesnt matter since they use their own getFormatter()
        "optiontotalallowamount",
        "optioncumopenvalue",
        "lastminorders",
        "currentoptionmaxdollaramount",
        "optiontotalallowqty",
        "totaltrades",
        "maxspeed",
        "currentqty",
        "currenttotalallowqtyo",
        "currentcumlongqty",
        "leveragebpvalue",
        "currenttotalallowqtye",
        "currenttotalallowqtyf",
        "cumnetqty",
        "currentspeed2",
        "currentoptioncumopenvalue",
        "optioncumnetqty",
        "currentcumshortvalue",
        "optioncumshortvalue",
        "currenttotalallowamounto",
        "currentamount",
        "currentoptionmargin",
        "optionmaxspeed",
        "currenttotalallowamountf",
        "maxdailyqty",
        "currentoptioncumnetqty",
        "currenttotalallowamounte",
        "currentoptioncumnetvalue",
        "currentoptioncumlongqty",
        "rbhvalue",
        "optionmarginlimit",
        "totalrejectedorders",
        "totalpendingorders",
        "currentcumnetotvalue",
        "optionmaxdollaramount",
        "accountcheck",
        "currentoptionmaxordershares",
        "cumshortvalue",
        "optioncumnetvalue",
        "cumlongvalue",
        "cumlongqty",
        "optioncumlongqty",
        "currentcumnetqty",
        "totalallowamounte",
        "currentspeed",
        "currentcumnetvalue",
        "totalallowamountf",
        "cumshortqty",
        "currentcumopenvalue",
        "optioncumopenqty",
        "totalallowamounto",
        "currentcumlongvalue",
        "currentoptiontotalallowqty",
        "cumopenqty",
        "currentoptioncumshortqty",
        "currentoptioncumlongvalue",
        "totalallowqtyo",
        "maxspeed2",
        "currentoptioncumopenqty",
        "optioncumshortqty",
        "currentoptiontotalallowamount",
        "totalallowqtyf",
        "totalallowqtye",
        "currentcumshortqty",
        "cumnetotvaluethreshold",
        "currentoptionspeed",
        "totalorders",
        "lastmintrades",
        "numofriskrejection",
        "cumopenvalue",
        "optionmaxordershares",
        "rbhthreshold",
        "leveragebpthreshold",
        "maxdailyamount",
        "currentmargin",
        "optioncumlongvalue",
        "cumnetvalue",
        "currentcumopenqty",
        "currentoptioncumshortvalue",
        "maxmargin",
        // EVERYTHING ABOVE IS FOR RISK MONITOR
        "liveqty",
        "price",
        "execprice",
        "execqty",
        "qty",
    ];
    const numTypes = ["amount", "curpos", "cost", "broker_comm_rate"];
    const dateTypes = [
        "time",
        "priority",
        "enttime",
        "updtime",
        "exptime",
        "trdtime",
        "details.date_created",
        "data.form.updatedAt",
        "effective_time",
        "datePublished",
    ];
    const dataType = numTypes.includes(header)
        ? // number
        "number"
        : dateTypes.includes(header)
            ? // date
            "date"
            : // string
            "string";

    const formatValue = (cell, type, fractionbase = null, isTif = false) => {
        switch (type) {
            case "number":
                return cell
                    ? formatNumber(
                        cell,
                        fractionbase ? fractionbase.toString().length - 1 : 0
                    )
                    : "0.00";
            case "date":
                return formatTimestamp(cell);
            default:
                // Parse role string that can be json, nested json, or just a string.
                if (header === "attr.role") {
                    let role = cell;
                    while (
                        typeof role === "string" &&
                        isStringifiedJson(role)
                    ) {
                        role = JSON.parse(role);
                    }
                    return role?.name || role;
                }
                return commaType.includes(header)
                    ? addCommas(cell)
                    : isTif && cell === undefined
                        ? "DAY"
                        : cell;
        }
    };

    const formatHeader = (hdr) => {
        const regex = /\.|_|^attr\./g;
        const capitalizeRegex = /(^\w{1})|(\s{1}\w{1})/g;

        let header = "";

        switch (hdr) {
            case "liveqty":
                header = "Leaves";
                break;
            case "qty":
                header = "Quantity";
                break;
            case "execprice":
                header = "Price";
                break;
            case "userid":
                header = "User ID";
                break;
            case "clientorderid":
                header = "Client Order ID";
                break;
            case "execqty":
                header =
                    tableOrigin === "trade-history"
                        ? "Quantity"
                        : "Quantity Executed";
                break;
            case "errordetails":
                header = "Error Details";
                break;
            case "details.name":
                header = "Name";
                break;
            case "details.date_created":
                header = "Date Created";
                break;
            case "data.form.updatedAt":
                header = "Last Updated";
                break;
            case "data.form.name":
                header = "Form Name";
                break;
            case "curpos":
                header = "Positions";
                break;
            case "security":
                header = "Symbol";
                break;
            case "ordertype":
                header = "Ordertype";
                break;
            case "tif":
                header = "TIF";
                break;
            case "ismaker":
                header = "Maker/Taker";
                break;
            case "displayqty":
                header = "Display QTY";
                break;
            case "keepmin":
                header = "Iceberg + Stay";
                break;
            case "mpid":
                header =
                    tableOrigin === "trade-book"
                        ? "MPID"
                        : "Market Participant ID (MPID)";
                break;
            case "datePublished":
                header = "Date Published";
                break;
            case "maxdollaramount":
                header = "Max order, notional";
                break;
            case "maxordershares":
                header = "Max order, units";
                break;
            case "totalallowamount":
                header = "Max daily, notional";
                break;
            case "totalallowqty":
                header = "Max daily, units";
                break;
            case "DupOrd":
                header = "Duplicate order check";
                break;
            case "SpeedChk":
                header = "Speed check";
                break;
            case "active_account":
                header = "Active / Inactive";
                break;
            case "marginlimit":
                header = "Margin Buy Power";
                break;
            case "percentagelimit":
                header = "Percentage from the inside";
                break;
            case "category":
                header = "Destination";
                break;
            default:
                header = hdr;
                break;
        }

        return header
            .replace(regex, " ")
            .replace(capitalizeRegex, (match) => match.toUpperCase());
    };

    const styling = {
        ...customStyling,
        width: style?.style?.width && `${style?.style?.width}`,
    };

    const formatter =
        customFormatter && customFormatter?.dataField === header
            ? customFormatter.formatter
            : (cell, rowContent) =>
                formatValue(
                    cell,
                    dataType,
                    header === "curpos" ? rowContent.fractionbase : null,
                    header === "tif"
                );

    return {
        dataField: header,
        text: formatHeader(header),
        sort: sortSetting,
        type: dataType,
        headerStyle: styling,
        style: {
            minWidth: style?.style?.width && `${style?.style?.width}`,
            maxWidth: style?.style?.width && `${style?.style?.width}`,
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
        },
        formatter: formatter,
        csvFormatter: dataType === "date" ? undefined : formatter,
    };
};

export const getTopbarLinks = async (dispatch) => {
    try {
        const response = await request("/links");

        dispatch(ACTIONS.queryLinks(response));
    } catch (err) {
        toast.error(err.reason);
    }
};

export class Timer {
    constructor(props) {
        super.constructor(props);
        this.dispatch = props.dispatch;
        this.reset = this.reset.bind(this);
    }
    reset() {
        clearInterval(this.timer);
        this.timer = this.newTimer();
    }
    newTimer() {
        return setInterval(() => {
            this.dispatch(ACTIONS.disconnectSocket());
        }, [70000]);
    }
}
//  This function will map an array to proper values and
//  labels for use with react-select, Select component.
export const mapToSelect = (arr, key) =>
    arr.map((elem) => ({
        value: elem[key],
        label: elem[key],
    }));

export const removeUnusedKeys = (data) => {
    // Remove empty keys
    Object.keys(data).forEach(
        (i) =>
            !data[i] &&
            data[i] !== undefined &&
            i !== "scheduletime" &&
            data[i] !== 0 &&
            typeof data[i] !== "boolean" &&
            i !== "risk_type" &&
            delete data[i]
    );
    return data;
};

export const formatFromTime = (time) => {
    // Set to beginning of day.
    time.setHours(0, 0, 0, 0);
    return `${time.getTime()}`;
};

export const handleGetRefno = (errordetails) => {
    if (errordetails?.includes("refno")) {
        const firstStep = errordetails.split("refno: ");
        const secondStep = firstStep[1].split(",");
        const refno = secondStep[0];

        return refno;
    } else {
        return errordetails;
    }
};

export const isPriceAlert = (last, current) => {
    if (!last) return;
    const rangePrice = (last * orderAlertPercentage) / 100;
    return current >= last - rangePrice && current <= last + rangePrice;
};

export const splitStringIntoArraysByNCharacters = (string, n) => {
    return string.match(new RegExp("(.|[\r\n]){1," + n + "}", "g"));
};

export const isAdmin = (user_type, roles) =>
    user_type === "S" ||
    Object.keys(roles).some((role) => role !== "ordinary_user" && roles[role]);

export const getPositionValue = (securityParam, positions, selectedAccount) => {
    const position = positions.find(
        (position) =>
            position.security === securityParam &&
            (selectedAccount ? selectedAccount === position.account : true)
    );
    if (position) return addCommas(position.curpos);
    return "N/A";
};

export const getCurrency = (
    currentSymbol,
    securitySlug,
    positions,
    selectedAccount
) => {
    const getFormattedPositionValue = (sym) =>
        positions && {
            value: getPositionValue(sym, positions, selectedAccount),
        };

    const symbolKeys = currentSymbol
        ? currentSymbol?.pair
            ? [currentSymbol["pair_first"], currentSymbol["pair_second"]]
            : [currentSymbol.security, currentSymbol["clear_inst"]]
        : [securitySlug, clientCurrency];

    return [
        {
            type: symbolKeys[0],
            ...getFormattedPositionValue(symbolKeys[0]),
        },
        {
            type: symbolKeys[1],
            ...getFormattedPositionValue(symbolKeys[1]),
        },
    ];
};

export const addCommas = (str) => {
    const decimals = str?.toString().split(".")[1];
    const commaStr = str
        ?.toString()
        .split(".")[0]
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    if (decimals !== undefined) return [commaStr, decimals].join(".");
    return commaStr;
};

export const removeCommas = (str) => str.replace(/,/g, "");

export const multiplyWithPrecision = (a, x) => {
    const aSplit = a.split(".");
    const xSplit = x.split(".");

    // (a+b)*(x+y) where final decimal is fixed digits of b+y
    // a and x is the whole number for each variable
    // b and y are the decimal portions of those variables

    // if both b and y exist add their lengths
    // i.e. a = (3.15) x = (1.25)
    // a === 3, b === 15, x === 1, y === 25
    // "15".length + "25".length === 4
    // if only b or y exists use that length
    const precisionBase =
        aSplit[1] && xSplit[1]
            ? aSplit[1].length + xSplit[1].length
            : aSplit[1]
                ? aSplit[1].length
                : xSplit[1]
                    ? xSplit[1].length
                    : 1;

    return parseFloat(a * x).toFixed(precisionBase);
};

export const removeNonNumeric = (str) => {
    return str.replace(/[^\d.-]+/g, "");
};

export const isMultipleDot = (str) =>
    str.charAt(str.length - 1) === "." && str.slice(0, -1).includes(".");

export const countDecimals = (str) => {
    const decimals = str.split(".")[1];
    if (decimals) {
        return removeCommas(decimals).length;
    }
    return 0;
};

export const getZeroes = (type, symbol) => {
    let zeroCount;
    if (type === "Quantity") {
        zeroCount = symbol?.fractionbase
            ? symbol?.fractionbase.toString().length - 1
            : 0;
    } else {
        zeroCount = symbol?.priceprecision
            ? symbol?.priceprecision.toString().length - 1
            : 4;
    }
    const zeroes =
        zeroCount === 0 ? "" : "." + new Array(zeroCount + 1).join("0");
    return { zeroCount, zeroes };
};
export const uploadUserFile = async (
    ws,
    dispatch,
    file,
    userid = undefined,
    fileName = null
) => {
    // Add handling to interrupt file uploading process if file is over 25mb
    // Set file input field to undefined

    dispatch(ACTIONS.toggleDimLoading());

    const getBase64 = (file) =>
        // eslint-disable-next-line no-undef
        new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    const fileInB64 = await getBase64(file);
    const splitFilePieces = splitStringIntoArraysByNCharacters(
        fileInB64,
        50000
    );

    const promises = [];

    for (let i = 0; i < splitFilePieces.length; i++) {
        const data = splitFilePieces[i];

        promises.push(
            // eslint-disable-next-line no-undef
            new Promise((resolve) =>
                sendMessageQuery(ws, "uploaduserfile", {
                    filename: fileName ? fileName : file.name,
                    data,
                    userid,
                    moredata: i !== splitFilePieces.length - 1,
                }).then((res) => resolve(res))
            )
        );
    }

    // eslint-disable-next-line no-undef
    return Promise.all(promises).then(() => {
        dispatch(ACTIONS.toggleDimLoading());

        toast.success("File successfully uploaded.");

        return file.name;
    });
};

export const deleteUserFile = (ws, filename, userid = undefined) =>
    sendMessageQuery(ws, "uploaduserfile", {
        isdelete: true,
        filename,
        userid,
    });

export const getUserFile = (
    ws,
    filename,
    userid = undefined,
    preview = false,
    hideError = false
) =>
    sendMessageQuery(ws, "getuserfile", { filename, userid }, hideError)
        .then((res) => {
            let resFile = "";

            for (let i = 0; i < res.length; i++) {
                const piece = res[i];
                resFile += piece;
            }

            return resFile;
        })
        .then((res) => {
            if (preview) {
                return res;
            } else {
                const blob = fetch(res).then((res) => res.blob());

                const link = document.createElement("a");

                link.setAttribute("href", URL.createObjectURL(blob));
                link.setAttribute("download", filename);

                link.click();
            }
        });

export const getFontFile = (ws, dispatch, userid) => {
    getUserFile(ws, "CustomFontFile", userid, true, true)
        .then((res) => {
            const storedFont = res.split(";base64,")[1];
            const binaryString = window.atob(storedFont);
            const len = binaryString.length;
            const bytes = new Uint8Array(len);

            for (let i = 0; i < len; i++) {
                bytes[i] = binaryString.charCodeAt(i);
            }

            const fontBlob = new Blob([bytes.buffer], {
                type: "font/ttf",
            });
            const fontUrl = URL.createObjectURL(fontBlob);
            dispatch(ACCOUNT_ACTIONS.setFontURL(fontUrl));
        })
        .catch(() => {
            dispatch(ACCOUNT_ACTIONS.setFontURL(""));
        });
};

export const stringToUpperCase = (str) => str.toUpperCase();

export const correctFieldType = (fieldType) =>
    ["e-signature", "e-Signature"].includes(fieldType)
        ? "e-Signature"
        : ["image", "Image Upload", "File Upload", "file"].includes(fieldType)
            ? "File Upload"
            : ["checkbox", "Checkbox"].includes(fieldType)
                ? "Checkbox"
                : ["idm-plugin", "IDM-Plugin"].includes(fieldType)
                    ? "IDM-Plugin"
                    : fieldType.charAt(0).toUpperCase() + fieldType.slice(1);

export const getDateTimeHelper = (date, time) => {
    const [hh, mm, ss] = time.split(":");
    const targetDate = date instanceof Date && !isNaN(date) ? date : new Date();
    targetDate.setHours(Number(hh) || 0, Number(mm) || 0, Number(ss) || 0);
    return targetDate;
};

export const SELECT_SMALL_SIZE_STYLE = {
    control: (provided) => ({
        ...provided,
        minHeight: "30px",
        height: "30px",
    }),
    valueContainer: (provided) => ({
        ...provided,
        height: "30px",
        padding: "0 6px",
    }),
    input: (provided) => ({
        ...provided,
        margin: "0px",
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        height: "30px",
    }),
};

export const arraysEqual = (a1, a2) =>
    a1.length === a2.length &&
    a1.every((o, idx) => {
        return lodash.isEqual(o, a2[idx]);
    });

export const getPercentageChange = (val1, val2) => {
    return val1
        ? val2
            ? ((val2 * 100) / val1 - 100).toFixed(3) + "%"
            : "0%"
        : "0%";
};

export const isStringifiedJson = (str) => {
    try {
        return JSON.parse(str);
    } catch (error) {
        return false;
    }
};

export const getRandomStringCharAndNum = () => {
    return (Math.random() + 1).toString(36).substring(2);
};

export const convertStylingVar = (stylingVar) =>
    stylingVar.startsWith("--") ? `var(${stylingVar})` : stylingVar;

export const getStylingHex = (value) =>
    value?.startsWith("--")
        ? getComputedStyle(document.documentElement)
            .getPropertyValue(value)
            .trim()
        : value;

export const componentToHex = (c) => {
    const hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
};

export const rgbToHex = (r, g, b) => {
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
};

export const hexToRgb = (hex) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
        ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
        }
        : null;
};

export const exportToCSVFromJSON = (data, filename, keysToIgnore) => {
    // Flatten data to a single array of objects so that fields
    // from attr are included in the csv.
    const headers = new Set();
    const addHeader = (header) => {
        if (!keysToIgnore?.includes(header)) {
            headers.add(header);
        }
    };
    const csvData = data.map((row) => {
        const flatRow = {};
        Object.keys(row).forEach((key) => {
            if (key === "attr") {
                Object.keys(row[key]).forEach((attrKey) => {
                    addHeader(attrKey);
                    flatRow[attrKey] = row[key][attrKey];
                });
            } else {
                addHeader(key);
                flatRow[key] = row[key];
            }
        });
        return flatRow;
    });

    const csvString = [
        Array.from(headers).join(","),
        ...csvData.map((row) =>
            Array.from(headers)
                .map((header) => row[header] || "")
                .join(",")
        ),
    ].join("\n");

    // Create link to download csv
    const link = document.createElement("a");
    link.setAttribute(
        "href",
        "data:text/csv;charset=utf-8," + encodeURIComponent(csvString)
    );
    link.setAttribute("download", filename);
    link.click();
};
