/* eslint-disable react/display-name */

import { memo } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import {
    IoIosArrowDropdownCircle,
    IoIosArrowDropupCircle,
} from "react-icons/io";

import { Fields } from "../Fields";

const defaultFieldConfig = {
    fieldName: "",
    fieldType: "String",
    fieldDescription: "<p></p>",
    required: false,
    showFieldDescription: false,
};

export const FieldGroup = memo(
    ({ group, setGroup, deleteGroup, disabled, index, length, setIndex }) => {
        const moveUpDisabled = !disabled && index !== 0;
        const moveDownDisabled = !disabled && index !== length - 1;

        return (
            <div className="asset-section">
                <Row noGutters>
                    <Form.Label className="size-sm">Group Name</Form.Label>
                </Row>
                <Row noGutters>
                    <Col>
                        <Form.Control
                            placeholder="Group Name"
                            size="sm"
                            onChange={({ target: { value } }) =>
                                setGroup({ ...group, name: value })
                            }
                            value={group.name}
                            required
                            disabled={disabled}
                        />
                    </Col>
                    <Col md={4} className="text-right">
                        <IoIosArrowDropupCircle
                            onClick={() =>
                                moveUpDisabled && setIndex(index, index - 1)
                            }
                            className={`${moveUpDisabled && "cursor-pointer"}`}
                            color={moveUpDisabled ? "grey" : "silver"}
                            style={{
                                height: "18px",
                                width: "18px",
                            }}
                        />
                        <IoIosArrowDropdownCircle
                            onClick={() =>
                                moveDownDisabled && setIndex(index, index + 1)
                            }
                            className={` ml-2 mr-3 ${
                                moveDownDisabled && "cursor-pointer"
                            }`}
                            color={moveDownDisabled ? "grey" : "silver"}
                            style={{
                                height: "18px",
                                width: "18px",
                            }}
                        />
                        {/* <Button
                            size="sm"
                            onClick={() =>
                                setGroup({
                                    ...group,
                                    display:
                                        group.display === "List"
                                            ? "Tile"
                                            : "List",
                                })
                            }
                            disabled={disabled}
                        >
                            {group.display}
                        </Button> */}
                        <Button
                            size="sm"
                            onClick={() => {
                                setGroup({
                                    ...group,
                                    fields: group?.fields?.concat([
                                        defaultFieldConfig,
                                    ]),
                                });
                            }}
                            className="mx-1"
                            disabled={disabled}
                        >
                            Add Field
                        </Button>

                        <Button
                            size="sm"
                            variant="danger"
                            onClick={deleteGroup}
                            disabled={disabled}
                        >
                            Delete Group
                        </Button>
                    </Col>
                </Row>
                {/* Fields */}
                {group?.fields?.length > 0 && (
                    <Fields
                        section={group}
                        setSection={setGroup}
                        disabled={disabled}
                        onboarding
                        isGroupField
                    />
                )}
            </div>
        );
    }
);
