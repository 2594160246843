import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { AiOutlineDelete } from "react-icons/ai";

import { ConfigureModalTemplate } from "./ConfigureModalTemplate";

export const CheckboxConfigureModal = ({
    show = false,
    handleSave,
    existingOptions = [],
    existingSubfields = [],
}) => {
    const [options, setOptions] = useState(existingOptions);
    const [subfields, setSubfields] = useState(existingSubfields);

    const saveValues = () => {
        handleSave("options", options, "subfields", subfields);
    };

    return (
        <ConfigureModalTemplate
            show={show}
            handleConfirm={saveValues}
            handleClose={saveValues}
            confirmText="Save"
            body={
                <>
                    <div className="d-flex justify-content-between mb-3">
                        <h6>Options</h6>
                        <Button
                            size="sm"
                            onClick={() => setOptions([...options, ""])}
                            disabled={options.length >= 10}
                        >
                            + Add Option
                        </Button>
                    </div>
                    {options.map((opt, i) => (
                        <div
                            key={i}
                            className="my-2 d-flex justify-content-between"
                        >
                            <Form.Control
                                size="sm"
                                className="mr-4"
                                defaultValue={options[i]}
                                onChange={({ target: { value } }) => {
                                    const newOptions = options;
                                    newOptions[i] = value;

                                    setOptions(newOptions);
                                }}
                            />

                            <AiOutlineDelete
                                className="mt-2 cursor-pointer"
                                onClick={() =>
                                    setOptions(
                                        options.filter((item, y) => y !== i)
                                    )
                                }
                            />
                        </div>
                    ))}
                    <hr />
                    <div className="d-flex justify-content-between mb-3">
                        <h6>Subfields</h6>
                        <Button
                            size="sm"
                            onClick={() =>
                                setSubfields([
                                    ...subfields,
                                    { fieldName: "", fieldType: "String" },
                                ])
                            }
                            disabled={subfields.length >= 10}
                        >
                            + Add Subfield
                        </Button>
                    </div>
                    {subfields.map((opt, i) => (
                        <div
                            key={i}
                            className="my-2 d-flex justify-content-between"
                        >
                            <Form.Control
                                size="sm"
                                className="mr-4"
                                defaultValue={subfields[i].fieldName}
                                onChange={({ target: { value } }) => {
                                    const newSubfields = subfields;
                                    newSubfields[i].fieldName = value;

                                    setSubfields(newSubfields);
                                }}
                            />
                            <AiOutlineDelete
                                className="mt-2 cursor-pointer"
                                onClick={() =>
                                    setSubfields(
                                        subfields.filter((item, y) => y !== i)
                                    )
                                }
                            >
                                Delete
                            </AiOutlineDelete>
                        </div>
                    ))}
                </>
            }
        />
    );
};
