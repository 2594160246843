import { memo } from "react";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";

import { formatTableColumn } from "../../../../helpers/other";
import { TableRenderer } from "../../../portal/TableRenderer";

// eslint-disable-next-line react/display-name
export const CompanyDocsTable = memo(
    ({ docs, handleShowEditModal, handleShowDeleteModal }) => {
        return (
            <>
                <TableRenderer
                    data={docs}
                    keyField="id"
                    columns={[
                        ...["title"].map((field) => formatTableColumn(field)),
                        {
                            dataField: "description",
                            text: "Description",
                            style: {
                                width: "60%",
                                whiteSpace: "pre-wrap",
                                maxWidth: "60%",
                            },
                        },
                        {
                            dataField: "isPublished",
                            text: "Published Status",
                            formatter: (cell) => (
                                <div
                                    className={
                                        cell ? "text-success" : "text-danger"
                                    }
                                >
                                    {cell ? "Published" : "Not Published"}
                                </div>
                            ),
                        },
                        {
                            dataField: "action",
                            text: "",
                            isDummyField: true,
                            formatter: (cell, row) => (
                                <>
                                    <AiOutlineEdit
                                        onClick={() =>
                                            handleShowEditModal(row, true)
                                        }
                                        className="cursor-pointer mr-2"
                                    />
                                    <AiOutlineDelete
                                        onClick={() =>
                                            handleShowDeleteModal(row)
                                        }
                                        className="cursor-pointer mr-2 text-danger"
                                    />
                                </>
                            ),
                        },
                    ]}
                    noDataIndication="No documents available."
                    pagination
                    wrapperClasses="size-sm"
                />
            </>
        );
    }
);
