/* eslint-disable react/display-name */
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

import { memo } from "react";
import { Button, ButtonGroup, Row } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
    CSVExport,
    Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { useNavigate } from "react-router-dom";

import { Loading } from "../../../Loading";

export const SymbolsTable = memo(
    ({ symbols, showDeletePopup, loading = false }) => {
        const navigate = useNavigate();

        const columns = [
            {
                dataField: "attr.name",
                text: "Security",
                formatter: (rowContent, { security }) => rowContent || security,
                sort: true,
            },
            {
                dataField: "security",
                text: "Symbol",
                sort: true,
            },
            {
                dataField: "pairs",
                text: "Symbol Pairs",
                sort: true,
                formatter: (rowContent) =>
                    (rowContent && rowContent.toString()) || "",
            },
            {
                dataField: "action",
                text: "",
                isDummyField: true,
                // eslint-disable-next-line no-unused-vars
                formatter: (rowContent, row) => (
                    <ButtonGroup>
                        <Button
                            size="sm"
                            onClick={() =>
                                navigate(`/admin/symbols/${row.security}`)
                            }
                        >
                            Info
                        </Button>
                        <Button
                            size="sm"
                            className="mx-1"
                            onClick={() =>
                                navigate(`/admin/symbols/${row.security}/edit`)
                            }
                        >
                            Edit
                        </Button>
                        <Button
                            size="sm"
                            variant="danger"
                            onClick={() => showDeletePopup(row)}
                        >
                            Delete
                        </Button>
                    </ButtonGroup>
                ),
            },
        ];

        const { SearchBar } = Search;
        const { ExportCSVButton } = CSVExport;

        return loading ? (
            <Loading />
        ) : (
            <ToolkitProvider
                keyField="security"
                data={symbols}
                columns={columns}
                exportCSV
                search
            >
                {(props) => (
                    <>
                        <Row
                            noGutters
                            className="mb-2 d-flex justify-content-end"
                        >
                            <ExportCSVButton
                                {...props.csvProps}
                                className="btn btn-primary btn-sm ml-1"
                            >
                                Export CSV
                            </ExportCSVButton>
                        </Row>
                        <Row noGutters>
                            <SearchBar {...props.searchProps} />
                        </Row>
                        <hr />
                        <BootstrapTable
                            {...props.baseProps}
                            pagination={paginationFactory()}
                            noDataIndication="No symbols available."
                            defaultSorted={[
                                {
                                    dataField: "security",
                                    order: "asc",
                                },
                            ]}
                            striped
                            wrapperClasses="size-sm"
                        />
                    </>
                )}
            </ToolkitProvider>
        );
    }
);
