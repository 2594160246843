import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { AddSymbolForm } from "../../../components/admin/market/symbol";
import { uploadImageForProfile } from "../../../helpers/asset-builder";
import { submitRegisterSymbol } from "../../../helpers/market";
import { removeUnusedKeys } from "../../../helpers/other";
import { useStore } from "../../../Store";
import { useSocket } from "../../../Websocket";

const AddSymbolPage = () => {
    const ws = useSocket();
    const { state, dispatch } = useStore();
    const navigate = useNavigate();

    const {
        market: { symbols: marketSymbols },
        trade: { symbols: tradeSymbols },
    } = state;

    const createSymbol = (symbolData) => {
        const existingSymbol = marketSymbols.find(
            (sym) => sym.security === symbolData.security
        );

        if (existingSymbol) return toast.error("Symbol already exists!");

        const registerSymbol = (image = undefined) =>
            submitRegisterSymbol(
                ws,
                dispatch,
                removeUnusedKeys({
                    ...symbolData,
                    attr: {
                        ...symbolData.attr,
                        image,
                    },
                }),
                tradeSymbols
            ).then(() => {
                navigate("/admin/symbols");
            });

        return symbolData?.attr?.image
            ? uploadImageForProfile(symbolData.attr.image).then((image) =>
                  registerSymbol(image)
              )
            : registerSymbol();
    };

    return (
        <div className="m-4">
            <Row noGutters className="forms-header">
                <Col>
                    <h4>Add Symbol</h4>
                </Col>
            </Row>
            <AddSymbolForm
                securities={marketSymbols}
                submitFunc={createSymbol}
            />
        </div>
    );
};

export default AddSymbolPage;
