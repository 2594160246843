import { useState } from "react";
import { Modal, Row } from "react-bootstrap";

import { FormRenderer } from "./FormRenderer";

export const FormPreviewModal = ({ show, handleClose, form }) => {
    const [currentGroup, setCurrentGroup] = useState(0);

    return (
        <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Form Preview</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row noGutters></Row>
                <FormRenderer
                    form={form.groups[currentGroup]}
                    preview
                    currentGroup={currentGroup}
                    groupCount={form.groups.length}
                    submitForm={() => {
                        if (currentGroup < form.groups.length - 1) {
                            setCurrentGroup(currentGroup + 1);
                        }
                    }}
                />
            </Modal.Body>
        </Modal>
    );
};
