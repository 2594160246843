import { clientCurrency } from "../../../../config";
import { addCommas } from "../../../../helpers/other";
import { useStore } from "../../../../Store";
import { ConfigureDisplayWrapper } from "../../../ConfigureDisplayTableWrapper";
import { TableRenderer } from "../../TableRenderer";

// eslint-disable-next-line no-unused-vars
export const PositionsTab = ({ layoutKey }) => {
    const { state } = useStore();

    const {
        positions,
        layoutConfigurationOptions: { configureEnabled },
        symbols,
    } = state.trade;

    const formatMarketValue = (row) => {
        const rowSymbol = symbols.find((symbol) =>
            symbol.pair && symbol.pair_second === clientCurrency
                ? symbol.pair_first === row.security
                : symbol.security === row.security
        );

        const price =
            (rowSymbol?.last ?? rowSymbol?.bid ?? rowSymbol?.prevcloseprice) ||
            "N/A";

        return price === "N/A"
            ? "N/A"
            : addCommas(parseFloat(row.curpos) * price);
    };

    return (
        <>
            <ConfigureDisplayWrapper
                keyType="trade_preferences"
                optionKey={`trade_screen.${layoutKey}.positions`}
                defaultOptions={["security", "curpos"]}
                data={positions}
                blacklistedAttr={["type"]}
                returnButtons
                render={(preferredColumns, ConfigureButtons) => (
                    <>
                        {(configureEnabled?.[layoutKey] ?? true) && (
                            <div className="my-2 d-flex justify-content-between orders-config-buttons-row">
                                <div>{ConfigureButtons}</div>
                            </div>
                        )}
                        <TableRenderer
                            keyField="security"
                            data={positions}
                            columns={preferredColumns.concat([
                                {
                                    dataField: "market-value",
                                    text: "Market Value",
                                    isDummyField: true,
                                    formatExtraData: symbols,
                                    formatter: (rowContent, row) =>
                                        formatMarketValue(row) || "N/A",
                                },
                            ])}
                            noDataIndication="No Positions Available"
                            bordered={false}
                            hover={false}
                            wrapperClasses="orders-table "
                        />
                    </>
                )}
            />
        </>
    );
};
