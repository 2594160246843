import { toast } from "react-toastify";

import request from "../apiRequest";
import * as ACTIONS from "../contexts/actions/account";
import { loginUser } from "./login";
import { sendMessageQuery } from "./websocket";

export const registerUser = async (dispatch, user) => {
    try {
        const response = await request("/account/register", "POST", user);

        dispatch(ACTIONS.authSuccess({ ...response.data, ip: response?.ip }));

        // if there is roles, parse the roles. Otherwise, give the dispatch a empty string.
        dispatch(ACTIONS.parseRoles(response.data.roles || ""));
    } catch (err) {
        toast.error(err.reason);

        dispatch(ACTIONS.clearState());
        dispatch(ACTIONS.authError(err));
    }
};

export const resendConfirmEmail = async () => {
    try {
        await request("/account/resend", "POST");

        toast.info(
            "A new confirmation email has been sent. Please check your inbox."
        );
    } catch (err) {
        toast.error(err.reason);
    }
};

export const verifyEmail = async (token, navigate, dispatch) => {
    try {
        const response = await request(
            `/account/verify?token=${encodeURIComponent(token)}`
        );

        toast.info("Email has successfully been verified.");

        await loginUser(dispatch, undefined, response);
    } catch (err) {
        toast.error(err.reason);
    } finally {
        navigate("/");
    }
};

export const getRoleList = async (dispatch) => {
    try {
        const response = await request("/account/roleList", "GET");

        dispatch(ACTIONS.getRoleList(response.data));
    } catch (err) {
        toast.error(err.reason);
    }
};

export const getRoleListAdmin = (ws, dispatch) =>
    sendMessageQuery(ws, "querybankinfo", { bank_type: "roles" }).then(
        (roles) => {
            dispatch(ACTIONS.getRoleList(roles));
        }
    );
