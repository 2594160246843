/* eslint-disable react/display-name */
import { memo, useState } from "react";
import { Button, Collapse, FormControl } from "react-bootstrap";

import { ActivityItem } from "../ActivityItem";

export const CaseActivityList = memo(({ activities, addComment }) => {
    const [showCommentBox, setShowCommentBox] = useState(false);
    const [commentData, setCommentData] = useState("");

    return (
        <>
            <Button
                size="sm"
                className="risk-tab-button"
                onClick={() => setShowCommentBox(!showCommentBox)}
            >
                Add Comment
            </Button>
            <Collapse in={showCommentBox}>
                <div className="">
                    <FormControl
                        size="sm"
                        as="textarea"
                        onChange={({ target: { value } }) =>
                            setCommentData(value)
                        }
                        value={commentData}
                    />
                    <div className="d-flex justify-content-end">
                        <Button
                            onClick={() => {
                                addComment(commentData);
                                setCommentData("");
                            }}
                            size="sm"
                            className="my-2 px-4"
                        >
                            Post
                        </Button>
                    </div>
                </div>
            </Collapse>
            {activities
                .sort((a, b) => b.date_created - a.date_created)
                .map((action, i) => (
                    <ActivityItem key={i} action={action} />
                ))}
        </>
    );
});
