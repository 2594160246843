import { toast } from "react-toastify";

import request from "../apiRequest";
import * as ACTIONS from "../contexts/actions/onboarding";
import { correctFieldType } from "./other";
import { validateOnlyString, validatePhoneNumber, validateSSN } from "./validate";
import { sendMessageQuery } from "./websocket";

export const getRegistrationSubmissions = (ws, dispatch, userid) =>
    sendMessageQuery(ws, "queryonboardinfo", { userid }).then((res) => {
        const mappedSubmissions = res.map((submission) => ({
            ...submission,
            data: JSON.parse(submission.data),
        }));

        // We only need to sort when there is a userid specified
        // because that means a user is running this query for
        // the registration process.

        dispatch(
            ACTIONS.queryOnboardingSubmissions(
                userid
                    ? mappedSubmissions.sort(
                        (a, b) => a.data.order > b.data.order
                    )
                    : mappedSubmissions
            )
        );

        return mappedSubmissions;
    });

export const updateRegistrationSubmission = (
    ws,
    dispatch,
    submission,
    updateContext = false,
    admin = false
) =>
    sendMessageQuery(ws, "updateonboard", {
        ...submission,
        data: JSON.stringify(submission.data),
    }).then(async (subm) => {
        if (updateContext) {
            const newSubmissions = await getRegistrationSubmissions(
                ws,
                dispatch,
                admin ? submission.userid : undefined
            );

            return Array.from(newSubmissions.map((submission) => (submission.submission_id === JSON.parse(subm[0]).submission_id ? JSON.parse(subm[0]) : submission)))
        }

        return subm;
    });

export const getOnboardingForms = (ws, dispatch) =>
    sendMessageQuery(
        ws,
        "querybankinfo",
        {
            userid: "OnboardingData",
            bank_type: "forms",
        },
        true
    )
        .then((resp) => {
            const { forms } = JSON.parse(resp.bank_detail);

            dispatch(ACTIONS.queryOnboardingForms(forms || []));

            return { forms };
        })
        .catch(() => { });

export const updateOnboardingForms = (ws, dispatch, { forms }) =>
    sendMessageQuery(ws, "updatebankinfo", {
        userid: "OnboardingData",
        bank_type: "forms",
        bank_detail: {
            forms,
        },
    }).then(() => getOnboardingForms(ws, dispatch));

export const getUserRoles = (ws, dispatch) =>
    sendMessageQuery(
        ws,
        "querybankinfo",
        {
            userid: "RolesData",
            bank_type: "roles",
        },
        true
    )
        .then((resp) => {
            const { roles } = JSON.parse(resp.bank_detail);

            dispatch(ACTIONS.queryUserRoles(roles || []));

            return { roles };
        })
        .catch(() => { });

export const updateUserRoles = (ws, dispatch, { roles }) =>
    sendMessageQuery(ws, "updatebankinfo", {
        userid: "RolesData",
        bank_type: "roles",
        bank_detail: {
            roles,
        },
    }).then(() => getUserRoles(ws, dispatch));

export const approveUser = async (userid) => {
    try {
        await request("/onboardingAdmin/users/approve", "POST", {
            email: userid,
        });

        toast.success(`User: ${userid} successfully approved`);
    } catch (err) {
        toast.error(err.reason);
    }
};

export const rejectUser = async (userid) => {
    try {
        await request("/onboardingAdmin/users/reject", "POST", {
            email: userid,
        });

        toast.success(`User: ${userid} successfully rejected`);
    } catch (err) {
        toast.error(err.reason);
    }
};

export const contactUser = async ({
    submission_id,
    name,
    subject,
    content,
}) => {
    try {
        await request("/onboardingAdmin/users/contact", "POST", {
            submission_id,
            name,
            subject,
            content,
        });

        toast.success(`Email sent successfully.`);
    } catch (err) {
        toast.error(err.reason);
    }
};

export const createUser = async (user) => {
    try {
        await request("/onboardingAdmin/users/create", "POST", user);
        toast.success(`User created successfully.`);
    } catch (err) {
        toast.error(err.reason);
    }
};

export const addAccount = (ws, account) =>
    sendMessageQuery(ws, "addaccount", account);

export const addAccountAccess = (ws, user) =>
    sendMessageQuery(ws, "addaccountaccess", user);

export const queryExistingAccounts = (ws, dispatch) =>
    sendMessageQuery(ws, "queryexistingaccounts").then((res) => {
        dispatch(ACTIONS.queryExistingAccounts(res));
    });

// This checks the users most recent submited form
// This is for auto reviewing a submission
export const reviewSubmission = async (submission_id) => {
    try {
        await request("/account/reviewSubmission", "POST", {
            submission_id: submission_id,
        });

    } catch (err) {
        console.log(err)
    }
};

// This is for auto reviewing a user
// This will check all submissions for a user
export const reviewSubmissions = async (navigate) => {
    try {
        await request("/account/reviewSubmissions", "POST", {});
        navigate(0);
    } catch (err) {
        console.log(err)
    }
};

export const notifyOnboardingAdmin = async () => {
    try {
        await request("/account/notifyAdmin", "POST", {});
    } catch (err) {
        console.log(err)
    }
};

export const validateFormGroup = (group, groupIndex, isSidebar, isBrassica) =>
    new Promise((resolve, reject) => {
        // Used for when we check the entire form, so that we can navigate to corresponding group.
        const firstIncorrectField = {
            group: groupIndex,
            field: undefined,
        };
        const toastMessage = (fieldName) => !isSidebar &&
            toast.error(`Field "${fieldName}" is required`);

        const isIncorrectFieldUndefined =
            !firstIncorrectField.field &&
            typeof firstIncorrectField?.field !== "number";

        const doesFieldHaveValue = (field) => {
            if ("subfields" in field) {
                return correctFieldType(field.fieldType) === "Table" ?
                    Object.keys(field.value)?.length > 0
                    : (
                        correctFieldType(field.fieldType) === "Branch" &&
                            field.value
                            ? field.subfields[parseInt(field.value)].data
                            : field.subfields
                    )?.every((subfield) => {
                        // if parent field is a branch and a subfield within it is required.
                        if (
                            (isIncorrectFieldUndefined && !subfield.value) ||
                            (field.fieldType === "Branch" &&
                                subfield.required &&
                                !subfield.value)
                        ) {
                            firstIncorrectField.field = subfield.fieldName;
                        }

                        return subfield.value;
                    });
            } else {
                if (!field?.required) return true;

                if (isIncorrectFieldUndefined && !field.value) {
                    firstIncorrectField.field = field.fieldName;
                }

                return field.value;
            }
        };

        const isBrassicaFieldValid = (field) => {
            if (correctFieldType(field.fieldType) === "String" && field?.required && ["SSN", "Primary Phone", "State", "City"].includes(field?.fieldName)) {
                // Check brassica fields
                switch (field.fieldName) {
                    case "Primary Phone":
                        if (
                            validatePhoneNumber(field.value) ||
                            field.value.length !== 10
                        )
                            return reject("Invalid phone number.");
                        break;
                    case "State":
                        if (
                            field.value?.length !== 2 ||
                            validateOnlyString(field.value)
                        )
                            return reject("Invalid state abbreviation.");
                        break;
                    case "SSN":
                        if (validateSSN(field.value))
                            return reject("Invalid SSN.");
                        break;
                    case "City":
                        if (validateOnlyString(field.value))
                            return reject("Invalid city name.");

                        break;
                    default:
                        break
                }
            }
        }
        if (
            group.fields.every((field, i) => isBrassica ? isBrassicaFieldValid(field) && doesFieldHaveValue(field, i) : doesFieldHaveValue(field))
        ) {
            // All fields are valid!
            return resolve([true]);
        } else {

            toastMessage(firstIncorrectField.field);

            return resolve([false, firstIncorrectField]);
        }
    });