import JSEncrypt from "jsencrypt";
import { toast } from "react-toastify";

import * as ACTIONS from "../contexts/actions/firm";
import { sendMessageQuery } from "./websocket";

/*
    queryexistingusers
    This function will get all users in a firm, or sub-accounts 
    that exist under the master account the user is currently 
    logged in with.
*/
export const getUsersFromWebsocket = (ws, dispatch) =>
    sendMessageQuery(ws, "queryexistingusers").then((users) => {
        getFirms(ws, dispatch);
        return dispatch(ACTIONS.queryExistingUsers(users));
    });
/*
    adduser
    This function will create or edit a user.
*/
export const addUser = (ws, dispatch, publicKey, user, type = "add") => {
    const jsEncrypt = new JSEncrypt();

    jsEncrypt.setPublicKey(publicKey);

    const formattedUserData = {
        ...user,
        userid: user?.userid || user?.attr?.email,
        pass: type === "add" ? jsEncrypt.encrypt(user.pass) : undefined,
        newpass:
            type === "edit" && user?.pass
                ? jsEncrypt.encrypt(user.pass)
                : undefined,
        resetpass: type === "edit" && user?.pass ? true : undefined,
        updateprof: type === "edit" ? true : undefined,
        attr: {
            ...user.attr,
            email_confirmed:
                user?.emailConfirmed || user?.attr?.email_confirmed,
            role: user?.attr?.role
                ? (type === "edit" ? user.attr.role : JSON.stringify(user?.attr?.role))
                : undefined,
        },
        config_setting: undefined,
        isEdit: undefined,
        rec_no: undefined,
    };

    return sendMessageQuery(ws, "adduser", formattedUserData).then((usr) => {
        toast.success(
            `User successfully ${type === "add" ? "created" : "edited"}`
        );

        dispatch(ACTIONS.updateExistingUser(formattedUserData));
        return usr;
    });
};
/*
    adduser - delete
    This function will delete a user.
*/
export const deleteUser = (ws, dispatch, { userid, firm }) =>
    sendMessageQuery(ws, "adduser", {
        delete: true,
        userid,
        firm,
    }).then((res) => {
        if (res.result === "OK") getUsersFromWebsocket(ws, dispatch);

        return res.result === "OK";
    });
/*
    transfer
    This function will transfer funds from one user to another.
*/
export const transferFunds = (
    ws,
    { fromaccount, toaccount, security, amount }
) => {
    let updatedPosition = false;

    return sendMessageQuery(ws, "transfer", {
        fromaccount: fromaccount.userid,
        toaccount: toaccount.userid,
        security,
        amount,
    }).then(() => {
        if (!updatedPosition) {
            updatedPosition = true;

            const users = [fromaccount, toaccount].map((user) => {
                delete user.positions;
                return user;
            });

            toast.success("Successfully transferred funds.");

            return users;
        }
    });
};
/*
    queryopenwithdraw
    This function will retrieve pending approvals for the user's firm/sub-accounts.
*/
export const getApprovals = (ws, dispatch, filter = {}) =>
    sendMessageQuery(ws, "queryopenwithdraw", filter).then((approvals) => {
        if (Object.keys(filter).length !== 0) return approvals;
        dispatch(ACTIONS.queryApprovals(approvals));
    });
/* 
    queryfirm
    This function will fetch firms from the backend.
*/
export const getFirms = (ws, dispatch) =>
    sendMessageQuery(ws, "queryfirm").then((firms) =>
        dispatch(ACTIONS.getFirms(firms))
    );
/*
    addfirm
    This function will create a firm or edit a firm on the backend.
*/
export const createFirm = (ws, dispatch, firm, isEdit) =>
    sendMessageQuery(ws, "addfirm", { ...firm }).then(() => {
        toast.success(
            `Firm: ${firm.firm} succesfully ${isEdit ? "edited" : "created"}.`
        );

        getFirms(ws, dispatch);
    });
/*
    addfirm - delete
    This function will delete a firm on the backend.
*/
export const deleteFirm = (ws, dispatch, firm) =>
    sendMessageQuery(ws, "addfirm", { firm, delete: true }).then(() => {
        toast.success(`Firm: ${firm} successfully deleted.`);

        getFirms(ws, dispatch);
    });
