import { useEffect, useState } from "react";
import {
    Button,
    Col,
    FormControl,
    FormLabel,
    Row,
    Tab,
    Tabs,
} from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { AiOutlineEdit } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";

import { alertTypes } from "../../../components/admin/risk";
import { FullScreenLoading } from "../../../components/Loading";
import { TableRenderer } from "../../../components/portal/TableRenderer";
import { getUsersFromWebsocket } from "../../../helpers/firm";
import { formatTableColumn } from "../../../helpers/other";
import { getRiskCases, uploadRiskCase } from "../../../helpers/risk";
import { useStore } from "../../../Store";
import { useSocket } from "../../../Websocket";

const CaseManagement = () => {
    const ws = useSocket();
    const { state, dispatch } = useStore();
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();

    const {
        risk: { cases },
        account: {
            account: { data: loggedInUser },
        },
        firm: { existing_users: users },
    } = state;

    const defaultCaseObj = {
        status: "open",
        assigned_to: loggedInUser?.userid || loggedInUser?.userId,
        severity: "low",
        details: {
            name: "",
            description: "",
            date_created: new Date().getTime(),
            alert_ids: [],
            alerts: [],
            activity: [
                {
                    date_created: new Date().getTime(),
                    action_by: loggedInUser?.userid || loggedInUser?.userId,
                    action_type: "new_case",
                    action_data: "",
                },
            ],
            attachments: [],
        },
    };

    const [searchValue, setSearchValue] = useState("");
    const [filterStatus, setFilterStatus] = useState("");
    const [filterSeverity, setFilterSeverity] = useState("");
    const [filterAlertType, setFilterAlertType] = useState("");
    const [filterAssignedTo, setFilterAssignedTo] = useState("");

    const [filteredCases, setFilteredCases] = useState(cases);

    const onSearch = () => {
        const showCases = cases.filter(
            (cse) =>
                cse.status.includes(filterStatus) &&
                cse.severity.includes(filterSeverity) &&
                (cse?.details?.name
                    ? cse.details.name
                          .toLowerCase()
                          .includes(searchValue.toLowerCase())
                    : cse.name
                          .toLowerCase()
                          .includes(searchValue.toLowerCase()))
        );

        const filterByAssignedTo =
            filterAssignedTo && filterAssignedTo.length > 0
                ? showCases.filter(
                      (cse) =>
                          cse?.assigned_to &&
                          cse.assigned_to.includes(filterAssignedTo)
                  )
                : showCases;

        const filterByAlertType =
            filterAlertType.length > 0
                ? filterByAssignedTo.filter(
                      (cse) =>
                          cse.details.alerts.length > 0 &&
                          cse.details.alerts.find((alrt) =>
                              alrt.subtype.includes(filterAlertType)
                          )
                  )
                : filterByAssignedTo;

        setFilteredCases(filterByAlertType);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(onSearch, [
        filterStatus,
        filterSeverity,
        filterAlertType,
        filterAssignedTo,
        searchValue,
    ]);

    useEffect(() => setFilteredCases(cases), [cases]);

    const createCase = () => {
        const identifier = uuid();
        const newCase = { ...defaultCaseObj, id: identifier };

        uploadRiskCase(ws, dispatch, newCase).then((riskCase) =>
            navigate(`/admin/case-management/${riskCase.id}/edit`)
        );
    };

    const CasesTable = ({ riskCases }) => (
        <>
            <Button size="sm" className="risk-tab-button" onClick={createCase}>
                Create a case
            </Button>
            <TableRenderer
                keyField="id"
                wrapperClasses="size-sm mt-1"
                data={riskCases || []}
                sortBy={"details.date_created"}
                sortOrder="desc"
                columns={[
                    ...[
                        "details.name",
                        "assigned_to",
                        "severity",
                        "status",
                        "details.date_created",
                    ].map((col) => formatTableColumn(col)),
                    {
                        dataField: "action",
                        text: "",
                        isDummyField: true,
                        formatter: (rowContent, row) => (
                            <AiOutlineEdit
                                className="cursor-pointer"
                                onClick={() =>
                                    navigate(
                                        `/admin/case-management/${row.id}/edit`
                                    )
                                }
                            />
                        ),
                    },
                ]}
                noDataIndication="No cases available."
                striped
                pagination
            />
        </>
    );

    useEffect(() => {
        if (users.length === 0) getUsersFromWebsocket(ws, dispatch);

        if (cases.length === 0) {
            getRiskCases(ws, dispatch).finally(() => setLoading(false));
        } else {
            setLoading(false);
        }

        return () => setLoading(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="m-4">
            <Row noGutters className="forms-header">
                <h4>Cases</h4>
            </Row>

            <Row noGutters>
                <Col xs={12} md={3} className="pr-2 pb-2">
                    <FormLabel className="size-sm">Alert Type:</FormLabel>
                    <FormControl
                        as="select"
                        size="sm"
                        onChange={({ target: { value } }) =>
                            setFilterAlertType(value)
                        }
                    >
                        <option value="">Select an option...</option>
                        {alertTypes.map(({ value, label }, i) => (
                            <option key={i} value={value}>
                                {label}
                            </option>
                        ))}
                    </FormControl>
                </Col>
                <Col xs={12} md={3} className="pr-2 pb-2">
                    <FormLabel className="size-sm">Status:</FormLabel>
                    <FormControl
                        as="select"
                        size="sm"
                        onChange={({ target: { value } }) =>
                            setFilterStatus(value)
                        }
                    >
                        <option value="">Select an option...</option>
                        <option value="open">Open</option>
                        <option value="investigating">Investigating</option>
                        <option value="closed">Closed</option>
                        <option value="escalated">Escalated</option>
                    </FormControl>
                </Col>
                <Col xs={12} md={3} className="pr-2 pb-2">
                    <FormLabel className="size-sm">Severity:</FormLabel>
                    <FormControl
                        as="select"
                        size="sm"
                        onChange={({ target: { value } }) =>
                            setFilterSeverity(value)
                        }
                    >
                        <option value="">Select an option...</option>
                        <option value="low">Low</option>
                        <option value="medium">Medium</option>
                        <option value="high">High</option>
                    </FormControl>
                </Col>
                <Col xs={12} md={3} className="pr-2 pb-2">
                    <FormLabel className="size-sm">Assigned to:</FormLabel>
                    <Typeahead
                        id="user"
                        labelKey="userid"
                        onChange={(e) =>
                            setFilterAssignedTo(e[0]?.userid || e[0]?.userId)
                        }
                        options={users}
                        size="sm"
                        placeholder="Select an option..."
                    />
                </Col>
            </Row>

            <Row noGutters>
                <Col xs={12} md={6} className="pr-2 pb-2">
                    <FormLabel className="size-sm">Search:</FormLabel>
                    <FormControl
                        size="sm"
                        placeholder="Search by case name..."
                        onChange={({ target: { value } }) =>
                            setSearchValue(value)
                        }
                    />
                </Col>
            </Row>

            {loading ? (
                <FullScreenLoading />
            ) : (
                <div className="position-relative size-sm">
                    <Tabs defaultActiveKey="all">
                        <Tab eventKey="all" title="All cases">
                            <CasesTable riskCases={filteredCases} />
                        </Tab>
                        <Tab eventKey="assigned" title="Assigned to me">
                            <CasesTable
                                riskCases={filteredCases.filter(
                                    (riskCase) =>
                                        riskCase.assigned_to ===
                                            loggedInUser?.userid ||
                                        riskCase.assigned_to ===
                                            loggedInUser?.userId
                                )}
                            />
                        </Tab>
                    </Tabs>
                </div>
            )}
        </div>
    );
};

export default CaseManagement;
