import { useEffect } from "react";
import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";

import {
    deleteUserFile,
    getUserFile,
    uploadUserFile,
} from "../../helpers/other";
import { useStore } from "../../Store";
import { useSocket } from "../../Websocket";

export const FileUploadInput = (props) => {
    const { field, onChange, classes, disabled } = props;
    const { required, onboarding, preview, restrictToImages } = field;

    const imageFileTypes = [
        "image/jpeg",
        "image/png",
        "image/jpg",
        "image/gif",
        "image/x-citrix-jpeg",
        "image/svg+xml",
        "image/x-png",
        "image/x-citrix-png",
    ];
    const nonImageFileTypes = [
        "application/pdf",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/msword",
    ];
    const allowedFileTypes = (
        restrictToImages
            ? imageFileTypes
            : imageFileTypes.concat(nonImageFileTypes)
    ).join(", ");

    const ws = useSocket();
    const { state, dispatch } = useStore();
    const {
        account: {
            account: { data: loggedInUser },
        },
    } = state;

    const [fileValue, setFileValue] = useState(null);

    useEffect(() => {
        if (onboarding && !preview) {
            field.value &&
                getUserFile(ws, field.value, loggedInUser.userid, true).then(
                    (res) => setFileValue(res)
                );
        } else if (!preview) {
            try {
                setFileValue(
                    typeof field.value === "string"
                        ? field.value
                        : URL.createObjectURL(field.value)
                );
            } catch (err) {
                console.log(err);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [field.value]);

    return (
        <Row className={classes} noGutters>
            <Col style={{ position: "relative" }}>
                {!field?.value || preview ? (
                    <>
                        <Form.File
                            custom
                            label={`Upload an ${
                                restrictToImages ? "image" : "file"
                            }...`}
                            className="size-sm"
                            accept={allowedFileTypes}
                            defaultValue={field?.value}
                            onChange={({ target: { files } }) =>
                                onboarding
                                    ? uploadUserFile(
                                          ws,
                                          dispatch,
                                          files[0],
                                          loggedInUser.userid
                                      ).then((res) => onChange(res))
                                    : onChange(files[0])
                            }
                            disabled={disabled}
                            required
                        />

                        {required && (
                            <span
                                className="required-icon"
                                style={{
                                    zIndex: 10,
                                    right: "5rem",
                                    bottom: 0,
                                }}
                            >
                                *
                            </span>
                        )}
                    </>
                ) : (
                    <embed
                        type={field.value.type}
                        src={`${fileValue}#toolbar=0&navpanes=0&scrollbar=0`}
                        key={`${fileValue}#toolbar=0&navpanes=0&scrollbar=0`}
                        height="250px"
                        width="100%"
                        style={{
                            objectFit: "contain",
                            minWidth: "300px",
                        }}
                    />
                )}
            </Col>
            {field?.value && !preview && (
                <Col className="text-right mr-1">
                    <Button
                        variant="danger"
                        size="sm"
                        className="px-2"
                        onClick={() =>
                            onboarding
                                ? deleteUserFile(
                                      ws,
                                      field.value,
                                      loggedInUser.userid
                                  ).then(() => onChange(null))
                                : onChange(null)
                        }
                        disabled={disabled}
                    >
                        X
                    </Button>
                </Col>
            )}
        </Row>
    );
};
