import { memo } from "react";
import { Button } from "react-bootstrap";
import { styled } from "styled-components";

import {
    isQMSClient,
    modifyOrderEnabled,
    presetTradeLayout1,
    presetTradeLayout2,
    presetTradeLayout3,
} from "../../../../config";
import { convertStylingVar } from "../../../../helpers/other";
import { useStore } from "../../../../Store";
import { ConfigureDisplayWrapper } from "../../../ConfigureDisplayTableWrapper";
import { TableRenderer } from "../../TableRenderer";
import { OrdersFilterSelect } from "./OrdersFilterSelect";

const CancelModifyButton = styled(
    ({ className, onClick, children, variant = "outline-danger" }) => (
        <Button
            variant={variant}
            className={`ml-1 px-3 py-1 ${className}`}
            size="sm"
            onClick={onClick}
        >
            {children}
        </Button>
    )
)`
    color: ${(props) => props.buttonColor};
    border-color: ${(props) => props.buttonColor};
    &:hover {
        background: ${(props) => props.buttonColor} !important;
        border-color: ${(props) => props.buttonColor} !important;
        color: ${(props) => props.fontColor};
    }
    &:focus {
        box-shadow: none !important;
    }
`;

// eslint-disable-next-line react/display-name
export const PendingOrdersTab = memo(
    ({ handleOpenOrderDetailModal, handleOpenConfirmModal, layoutKey }) => {
        const { state } = useStore();
        const {
            trade: {
                orders: { pending: data },
                layoutConfigurationOptions: editableLayout,
            },
        } = state;
        const layoutConfigurationOptions =
            editableLayout?.pageSelected === "preset1"
                ? presetTradeLayout1
                : editableLayout?.pageSelected === "preset2"
                ? presetTradeLayout2
                : editableLayout?.pageSelected === "preset3"
                ? presetTradeLayout3
                : editableLayout;

        const { configureEnabled } = layoutConfigurationOptions;

        const cancelButtonColor = convertStylingVar(
            editableLayout.styling.button.cancelBGColor
        );
        const cancelFontColor = convertStylingVar(
            editableLayout.styling.button.cancelFontColor
        );

        const secondaryButtonColor = convertStylingVar(
            editableLayout.styling.button.secondaryBGColor
        );
        const secondaryFontColor = convertStylingVar(
            editableLayout.styling.button.secondaryFontColor
        );

        const refnoColumn = {
            dataField: "refno",
            text: "ID",
            formatter: (rowContent, row) => {
                return (
                    <span
                        aria-hidden="true"
                        style={{
                            paddingBottom: "2px",
                            borderBottom: "1px solid",
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            handleOpenOrderDetailModal(row);
                        }}
                    >
                        {row.refno}
                    </span>
                );
            },
        };

        const filter =
            editableLayout?.activeOrderTableFiltering?.[layoutKey] ?? {};
        const filteredData =
            Object.keys(filter).length !== 0
                ? data.filter(
                      (order) =>
                          (filter.symbol !== ""
                              ? order.security === filter.symbol
                              : true) &&
                          (filter.side !== ""
                              ? order.side === filter.side
                              : true) &&
                          (filter.destination !== ""
                              ? order.category === filter.destination
                              : true)
                  )
                : data;

        return (
            <>
                <ConfigureDisplayWrapper
                    keyType="trade_preferences"
                    optionKey={`trade_screen.${layoutKey}.pending`}
                    defaultOptions={
                        isQMSClient
                            ? [
                                  "security",
                                  "side",
                                  "qty",
                                  "price",
                                  "updtime",
                                  "ordertype",
                                  "userid",
                              ]
                            : [
                                  "security",
                                  "side",
                                  "liveqty",
                                  "price",
                                  "tif",
                                  "ordertype",
                                  "clientorderid",
                              ]
                    }
                    data={data}
                    blacklistedAttr={["refno"]}
                    manualAdditionalKeys={["displayqty"]}
                    returnButtons
                    customStyling={refnoColumn.headerStyle}
                    customFormatter={{
                        dataField: "ordertype",
                        formatter: (cell, row) =>
                            row.negotiate
                                ? "Negotiate"
                                : row.ioi
                                ? "IOI"
                                : cell,
                    }}
                    render={(preferredColumns, ConfigureButtons) => (
                        <>
                            <div className="my-2 d-flex justify-content-between orders-config-buttons-row ">
                                <div
                                    className="d-flex flex-wrap"
                                    style={{ rowGap: "4px" }}
                                >
                                    <div className="mr-1">
                                        {(configureEnabled?.[layoutKey] ??
                                            true) &&
                                            ConfigureButtons}
                                    </div>
                                    <OrdersFilterSelect layoutKey={layoutKey} />
                                </div>
                                <CancelModifyButton
                                    className="cancel-all-orders-button"
                                    buttonColor={cancelButtonColor}
                                    fontColor={cancelFontColor}
                                    onClick={() => handleOpenConfirmModal()}
                                >
                                    Cancel all orders
                                </CancelModifyButton>
                            </div>
                            <TableRenderer
                                keyField="refno"
                                data={filteredData}
                                columns={[
                                    ...preferredColumns,
                                    !isQMSClient
                                        ? {
                                              ...refnoColumn,
                                              headerStyle: {
                                                  ...refnoColumn.headerStyle,
                                              },
                                          }
                                        : {},
                                    {
                                        dataField: "action",
                                        isDummyField: true,
                                        text: "Action",
                                        formatter: (rowContent, row) => {
                                            return (
                                                <div className="d-flex">
                                                    <CancelModifyButton
                                                        buttonColor={
                                                            cancelButtonColor
                                                        }
                                                        fontColor={
                                                            cancelFontColor
                                                        }
                                                        className="cancel-order-button"
                                                        onClick={() => {
                                                            handleOpenConfirmModal(
                                                                row
                                                            );
                                                        }}
                                                    >
                                                        Cancel
                                                    </CancelModifyButton>
                                                    {modifyOrderEnabled &&
                                                        !row.negotiate &&
                                                        !row.ioi && (
                                                            <CancelModifyButton
                                                                variant="outline-warning"
                                                                className="modify-order-button"
                                                                onClick={() => {
                                                                    handleOpenOrderDetailModal(
                                                                        row,
                                                                        true
                                                                    );
                                                                }}
                                                                buttonColor={
                                                                    secondaryButtonColor
                                                                }
                                                                fontColor={
                                                                    secondaryFontColor
                                                                }
                                                            >
                                                                Modify
                                                            </CancelModifyButton>
                                                        )}
                                                </div>
                                            );
                                        },
                                        headerStyle: refnoColumn.headerStyle,
                                    },
                                ]}
                                noDataIndication="No Pending Orders Available"
                                bordered={false}
                                hover={false}
                                sortBy="updtime"
                                sortOrder="desc"
                                wrapperClasses="orders-table "
                            />
                        </>
                    )}
                />
            </>
        );
    }
);
