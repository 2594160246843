import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

import { EditSymbolForm } from "../../../components/admin/market/symbol";
import {
    deleteImageForProfile,
    uploadImageForProfile,
} from "../../../helpers/asset-builder";
import { submitEditSymbol } from "../../../helpers/market";
import { removeUnusedKeys } from "../../../helpers/other";
import { useStore } from "../../../Store";
import { useSocket } from "../../../Websocket";

const EditSymbolPage = () => {
    const { state, dispatch } = useStore();
    const ws = useSocket();
    const navigate = useNavigate();

    const { security } = useParams();
    const {
        market: { symbols: marketSymbols },
        trade: { symbols: tradeSymbols },
    } = state;

    const [symbol, setSymbol] = useState({});

    const findMarketSymbol = (symb) =>
        marketSymbols.find((elem) => elem.security === symb);
    const findTradeSymbol = (symb) =>
        tradeSymbols.find((elem) => elem.security === symb);
    const getSymbolPageInfo = () => {
        let sym = findMarketSymbol(security);
        if (!sym) sym = findTradeSymbol(security);
        setSymbol(sym);
    };

    const editSymbol = (symbolData) => {
        const registerSymbol = (image = undefined) =>
            submitEditSymbol(
                ws,
                dispatch,
                removeUnusedKeys({
                    ...symbolData,
                    fractionbase: `${symbolData.fractionbase}`,
                    attr: {
                        ...symbolData.attr,
                        image,
                    },
                }),
                tradeSymbols
            ).then(() => navigate("/admin/symbols"));

        // Delete old image
        if (
            symbol?.attr?.image &&
            symbol?.attr?.image !== symbolData?.attr?.image
        ) {
            deleteImageForProfile(symbol.attr.image);
        }

        return typeof symbolData?.attr?.image === "object"
            ? uploadImageForProfile(symbolData.attr.image).then((image) =>
                  registerSymbol(image)
              )
            : registerSymbol(symbolData?.attr?.image);
    };

    useEffect(() => {
        if (marketSymbols.length > 0) {
            getSymbolPageInfo(security);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [marketSymbols]);

    return (
        <div className="m-4">
            <Row noGutters className="forms-header">
                <Col>
                    <h4>{security}</h4>
                </Col>
            </Row>
            {Object.keys(symbol).length !== 0 && (
                <EditSymbolForm symbol={symbol} submitFunc={editSymbol} />
            )}
        </div>
    );
};
export default EditSymbolPage;
