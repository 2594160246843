import moment from "moment";
import { useEffect, useRef, useState } from "react";
import {
    Badge,
    Button,
    Col,
    Image,
    Overlay,
    OverlayTrigger,
    Row,
    Tooltip,
} from "react-bootstrap";
import { AiFillMinusCircle, AiFillPlusCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { createGlobalStyle } from "styled-components";

import {
    clientCurrency,
    clientDateFormat,
    defaultSymbolIcon,
    emailIssuer,
    enableMarketWatchPriceDecimals,
    enableNewMarketWatch,
    enableSimpleMarketWatch,
    hideTradeScreenWhenInactive,
    showAssetBuilder,
    usePrevClosePriceAsLastPriceWhenLPIsNA,
} from "../../../config";
import { storeOptions } from "../../../helpers/display-options";
import {
    convertStylingVar,
    formatNumberWithDecimal,
    formatTableColumn,
    getPercentageChange,
} from "../../../helpers/other";
import { useResizeObserver } from "../../../hooks/useResizeObserver";
import { useStore } from "../../../Store";
import { useSocket } from "../../../Websocket";
import { ConfigureDisplayWrapper } from "../../ConfigureDisplayTableWrapper";
import { TableRenderer } from "../TableRenderer";

const TradeScreenStyling = createGlobalStyle`
     .trade-market-watch-table, .market-watch-table, .mini-market-watch-parent {
        background-color: ${(props) => props.backgroundColor} !important;
        color: ${(props) => props.fontColor} !important;
    }

     .trade-market-watch-table tbody tr:hover, .market-watch-table tbody tr:hover, .mini-market-watch-parent tbody tr:hover {
        background-color: ${(props) => props.primaryBackgroundColor} !important;
        color: ${(props) => props.fontColor} !important;
    }

    .trade-market-watch-table table, .market-watch-table table, .mini-market-watch-parent table {
        color: ${(props) => props.fontColor} !important;
    }

    .table-bordered {   
        border: none;
    }

    .security-name {
        color: ${(props) => props.secondaryFontColor} !important;
    }

    .security-parent {
        display: flex;
    }

    .security-parent .badge {
        margin-top: 0.15rem !important;
        margin-bottom: 0.3rem !important;
        margin-left: 0.5rem !important;

    }
`;

// eslint-disable-next-line react/display-name
export const MarketWatchTable = ({
    updateSymbolInWatchList,
    symbols,
    profiles,
    watchlist = [],
    loading,
    isTradeScreen = false,
}) => {
    const ws = useSocket();
    const navigate = useNavigate();
    const { state, dispatch } = useStore();
    const { portalAuctionInformation } = state.trade;
    const loggedInUser = state.account.account;
    const isUserActive = loggedInUser.data.active === "Y";

    const parentContainer = useRef(null);
    const [showTooltip, setShowTooltip] = useState(false);
    const [hoveredWatchlistRow, setHoveredWatchlistRow] = useState(null);
    const [hoveredUnwatchedRow, setHoveredUnwatchedRow] = useState(null);
    const target = useRef(null);
    const mousePosition = useRef({ x: 0, y: 0 });

    const handleMouseMove = (e) => {
        mousePosition.current = { x: e.screenX, y: e.screenY };
    };

    useEffect(() => {
        window.addEventListener("mousemove", handleMouseMove);
        return () => {
            window.removeEventListener("mousemove", handleMouseMove);
        };
    }, []);

    useResizeObserver(parentContainer, () => {
        setShowTooltip(parentContainer?.current?.clientWidth < 600);
    });

    const { showConfigureOnMarketWatch, styling } =
        state.trade.layoutConfigurationOptions;
    const doesProfileExist = (symbol) =>
        profiles?.find((profile) =>
            symbol?.pair_second !== clientCurrency
                ? profile.symbol === symbol.security
                : profile.symbol === symbol.pair_first
        );
    const isSymbolHalted = (symbol) => symbol?.status?.startsWith("H");
    const isSymbolPreOpen = (symbol) => symbol?.status?.startsWith("P");
    const ifMarketPlacePriceDecimalsEnabled = (price) =>
        price && price !== "N/A"
            ? `$${
                  enableMarketWatchPriceDecimals
                      ? formatNumberWithDecimal(price, 4)
                      : price
              }`
            : "N/A";

    const watchListedSymbols = symbols.filter((symb) =>
        watchlist.includes(symb.security)
    );
    const unwatchedSymbols = symbols.filter(
        (symb) => !watchlist.includes(symb.security)
    );

    const formatHeader = (header = "") => {
        const regex = /\.|_|^attr\./g;
        const capitalizeRegex = /(^\w{1})|(\s{1}\w{1})/g;

        switch (header) {
            case "attr.issuerFirm":
                return "Issuer Firm";
            case "prevcloseprice":
                return "Prior Day Close";
            case "bid":
                return "Best Bid";
            case "offer":
                return "Best Offer";
            case "lastPricePair":
                return "Last Price";
            case "percentChangePair":
                return "Percentage Change";
            default:
                return header
                    ?.replace(regex, " ")
                    ?.replace(capitalizeRegex, (match) => match.toUpperCase());
        }
    };

    const rowEvents = (setHoveredRow) => ({
        onMouseEnter: (e, row, rowIndex) => {
            setHoveredRow(rowIndex);
            // setTarget(e.target.closest("tr"));
            target.current = e.target;
        },
        onMouseLeave: () => {
            setHoveredRow(null);
            target.current = null;
        },
    });

    const renderTooltip = (row, props, showTooltip) => {
        return (
            <Tooltip
                {...props}
                className="market-watch-tooltip"
                style={{
                    ...props.style,
                }}
            >
                <>
                    {row?.description?.length != 0 && (
                        <div>
                            <b>{row?.description}</b>
                            <br />
                        </div>
                    )}
                    {showTooltip && isTradeScreen && (
                        <div className="mini-market-watch-tooltip">
                            <Row>
                                <Col>
                                    <b>Best Bid</b>
                                </Col>
                                <Col>
                                    <b>Best Offer</b>
                                </Col>
                            </Row>
                            <Row>
                                <Col>{row?.bid || "N/A"}</Col>
                                <Col>{row?.offer || "N/A"}</Col>
                            </Row>
                            <br />
                            <Row>
                                <Col>
                                    <b>High</b>
                                </Col>
                                <Col>
                                    <b>Low</b>
                                </Col>
                            </Row>
                            <Row>
                                <Col>{row?.high || "N/A"}</Col>
                                <Col>{row?.low || "N/A"}</Col>
                            </Row>
                            <br />
                            <Row>
                                {/* <Col>
                <b>Volume</b>
            </Col> */}
                                <Col>
                                    <b>Prior Day Close</b>
                                </Col>
                            </Row>
                            <Row>
                                {/* <Col>{row?.volume || "N/A"}</Col> */}
                                <Col>{row?.prevcloseprice || "N/A"}</Col>
                            </Row>
                        </div>
                    )}
                </>
            </Tooltip>
        );
    };

    const mapColumnFunc = (col) => ({
        ...col,
        text: formatHeader(col.dataField),
        headerClasses: `${col.dataField}`,
        classes: `${col.dataField}`,
        headerStyle: isTradeScreen
            ? {
                  backgroundColor: styling?.headerBackgroundColor,
                  //   color: styling?.headerFontColor,
              }
            : {},
        formatExtraData: col.dataField,
        formatter: (rowContent, row, index, dataField) => (
            <span
                className={
                    dataField === "bid"
                        ? "text-success"
                        : dataField === "offer"
                        ? "text-danger"
                        : ""
                }
                style={{
                    color:
                        dataField === "percentChangePair"
                            ? row?.last >= row?.prevcloseprice
                                ? "green"
                                : "red"
                            : "",
                }}
            >
                {[
                    "prevcloseprice",
                    "bid",
                    "offer",
                    "high",
                    "low",
                    "lastPricePair",
                ].includes(col.dataField)
                    ? ifMarketPlacePriceDecimalsEnabled(
                          dataField === "lastPricePair"
                              ? row?.last &&
                                !usePrevClosePriceAsLastPriceWhenLPIsNA
                                  ? row?.last || "N/A"
                                  : row.prevcloseprice
                                  ? row.prevcloseprice || "N/A"
                                  : "N/A"
                              : rowContent
                      )
                    : dataField === "percentChangePair"
                    ? `
                      ${
                          row?.last >= row?.prevcloseprice ? "+" : "-"
                      }${getPercentageChange(row?.prevcloseprice, row?.last)}`
                    : rowContent}
            </span>
        ),
    });

    const actionColumn = {
        dataField: "add_action",
        text: "",
        isDummyField: true,
        formatExtraData: watchlist,
        // eslint-disable-next-line no-unused-vars
        formatter: (content, row, i, symbol_watchlist) => (
            <div className="text-center">
                {symbol_watchlist.includes(row.security) ? (
                    <AiFillMinusCircle
                        style={{
                            fontSize: "1.5rem",
                            color: "var(--UniversalGrey)",
                        }}
                        className="cursor-pointer"
                        onClick={() =>
                            updateSymbolInWatchList(row.security, "remove")
                        }
                    />
                ) : (
                    <AiFillPlusCircle
                        style={{
                            fontSize: "1.5rem",
                            color: "var(--UniversalGrey)",
                        }}
                        className="cursor-pointer"
                        onClick={() =>
                            updateSymbolInWatchList(row.security, "add")
                        }
                    />
                )}
            </div>
        ),
    };

    const securityColumn = {
        dataField: "security",
        text: "Name",
        formatExtraData: {
            showTooltip,
            target,

            isTradeScreen,
        },
        // eslint-disable-next-line no-unused-vars
        formatter: (content, row, i, extraData) => {
            const profileExists = doesProfileExist(row);
            const symbolHalted = isSymbolHalted(row);
            // If permanent halted
            const haltedTime = portalAuctionInformation?.[row.security]?.find(
                (time) => time.status === "R"
            );
            const isSymbolPaused =
                haltedTime?.scheduletime > 0 && haltedTime?.status;
            const symbolPreOpen = isSymbolPreOpen(row);

            const scheduleTimes = portalAuctionInformation?.[row.security]
                ?.filter((time) => time.status !== "R")
                .sort((a, b) => a.scheduletime - b.scheduletime)
                .map((time) => {
                    const isToday =
                        new Date(time.scheduletime).setHours(0, 0, 0, 0) ===
                        new Date().setHours(0, 0, 0, 0);

                    return {
                        ...time,
                        scheduletime: time?.repeat_pattern
                            ? moment(
                                  isToday
                                      ? time.scheduletime
                                      : time.nextaction_date,
                                  isToday ? undefined : "YYYYMMDD"
                              )
                                  .toDate()
                                  .setHours(
                                      new Date(time.scheduletime).getHours(),
                                      new Date(time.scheduletime).getMinutes()
                                  )
                            : time.scheduletime,
                    };
                });

            // Check for next schedule time
            // We need to do + here because we want the next one that is higher
            // When we get a status change it re-renders but it isn't on the dot.
            // 30000 for 30 seconds
            const nextScheduleTime = scheduleTimes?.find(
                (time) => time?.scheduletime > Date.now() + 10000
            );

            const repeatingDays = nextScheduleTime?.repeat_pattern
                ? nextScheduleTime?.repeat_pattern.split("|D")[1].split(",")
                : [];

            const width =
                window.innerWidth ||
                document.documentElement.clientWidth ||
                document.body.clientWidth;

            let placementPosition = "right";
            if (mousePosition.current.x / width > 0.7)
                placementPosition = "left";

            return (
                <div className="d-inline-flex">
                    {extraData.hoveredRow !== null &&
                        extraData.hoveredRow === i &&
                        (row?.description ||
                            (extraData.isTradeScreen &&
                                extraData.showTooltip)) && (
                            <Overlay
                                target={target.current}
                                show
                                placement={placementPosition}
                            >
                                {(props) => {
                                    return renderTooltip(
                                        extraData.symbols[extraData.hoveredRow],
                                        props,
                                        extraData.showTooltip
                                    );
                                }}
                            </Overlay>
                        )}
                    <div
                        // xs={2}
                        style={{ minWidth: !isTradeScreen ? "40px" : "22px" }}
                        className=""
                    >
                        <Image
                            src={
                                profileExists?.image ||
                                row?.attr?.image ||
                                defaultSymbolIcon
                            }
                            // height="40px"
                            className="mr-4"
                            style={{
                                objectFit: "scale-down",
                                maxWidth: !isTradeScreen ? "40px" : "22px",
                            }}
                            alt=""
                        />
                    </div>

                    <Col className="px-0 security-parent">
                        <Row noGutters>
                            <b
                                className="security-name"
                                style={{
                                    color: "var(--UniversalGrey)",
                                }}
                            >
                                {row.attr?.name || content}
                            </b>
                            &nbsp;
                            {content}
                        </Row>
                        <Row noGutters>
                            {(symbolHalted || symbolPreOpen) && (
                                <Badge
                                    variant={
                                        symbolHalted ? "danger" : "warning"
                                    }
                                    as="div"
                                    className="mt-2 mr-1"
                                    style={{
                                        backgroundColor:
                                            isSymbolPaused && symbolHalted
                                                ? "#FF5F15"
                                                : "",
                                    }}
                                >
                                    {symbolHalted
                                        ? isSymbolPaused
                                            ? "PAUSED"
                                            : "HALTED"
                                        : "PRE-OPEN"}
                                </Badge>
                            )}
                            {nextScheduleTime && (
                                <OverlayTrigger
                                    placement="bottom"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={
                                        <Tooltip
                                            className="market-watch-time-tooltip"
                                            style={{
                                                display: !scheduleTimes
                                                    ? "none"
                                                    : "initial",
                                            }}
                                        >
                                            {scheduleTimes.map((time) => (
                                                <div
                                                    className="size-sm"
                                                    key={time.scheduletime}
                                                >
                                                    {time.status === "O"
                                                        ? "Opening at"
                                                        : time.status === "H"
                                                        ? "Halting at"
                                                        : "Pre-Opening at"}{" "}
                                                    {moment(
                                                        time?.scheduletime
                                                    ).format(
                                                        "MMMM Do YYYY, h:mmA"
                                                    )}
                                                </div>
                                            ))}
                                            {repeatingDays.length > 0 && (
                                                <div className="size-sm">
                                                    Repeating on:{" "}
                                                    {repeatingDays
                                                        .map((day) =>
                                                            moment()
                                                                .day(day)
                                                                .format("dddd")
                                                        )
                                                        .join(", ")}
                                                </div>
                                            )}
                                        </Tooltip>
                                    }
                                >
                                    <Badge
                                        variant="info"
                                        as="div"
                                        className="mt-2"
                                    >
                                        {repeatingDays.length === 0
                                            ? "Auction"
                                            : "Periodic Trading"}
                                        :{" "}
                                        {nextScheduleTime.status === "O"
                                            ? "Opening"
                                            : nextScheduleTime.status === "H"
                                            ? "Halting"
                                            : "Pre-Opening"}{" "}
                                        {/* Check if today */}
                                        {moment(
                                            nextScheduleTime?.scheduletime
                                        ).format("YYYY-MM-DD") ===
                                        moment().format("YYYY-MM-DD")
                                            ? `at ${moment(
                                                  nextScheduleTime?.scheduletime
                                              ).format("h:mm A")}`
                                            : `on ${moment(
                                                  nextScheduleTime?.scheduletime
                                              ).format(
                                                  clientDateFormat.toUpperCase()
                                              )}`}
                                    </Badge>
                                </OverlayTrigger>
                            )}
                        </Row>
                    </Col>
                </div>
            );
        },
    };

    const configuredColumns = [
        "prevcloseprice",
        "bid",
        "offer",
        "high",
        "low",
        "lastPricePair",
        "percentChangePair",
    ];

    const columns = (emailIssuer ? ["attr.issuerFirm"] : []).concat(
        enableSimpleMarketWatch ? [] : configuredColumns
    );

    return (
        <>
            {isTradeScreen && (
                <TradeScreenStyling
                    primaryBackgroundColor={convertStylingVar(
                        styling?.primaryBackgroundColor
                    )}
                    backgroundColor={convertStylingVar(
                        styling?.backgroundColor
                    )}
                    fontColor={convertStylingVar(styling?.fontColor)}
                    secondaryFontColor={convertStylingVar(
                        styling?.headerFontColor
                    )}
                />
            )}

            <ConfigureDisplayWrapper
                keyType="trade_preferences"
                optionKey="market_watch"
                defaultOptions={columns}
                whiteListedAttr={columns}
                manualAdditionalKeys={columns}
                data={symbols}
                returnButtons
                enableNewTextFormatting
                minimumOptions={2}
                render={(preferredColumns, configureButtons) => {
                    const columnsToRender = [
                        ...(!enableNewMarketWatch && !isTradeScreen
                            ? [actionColumn]
                            : []),
                        securityColumn,
                        ...(isTradeScreen
                            ? preferredColumns
                            : columns.map((col) => formatTableColumn(col))
                        ).map(mapColumnFunc),
                        ...(!isTradeScreen
                            ? [
                                  {
                                      dataField: "action",
                                      text: "Trade",
                                      isDummyField: true,
                                      formatter: (content, row) => {
                                          const profileExists =
                                              doesProfileExist(row);

                                          return (
                                              <>
                                                  {showAssetBuilder && (
                                                      <>
                                                          <Button
                                                              size="sm"
                                                              className="w-100 mb-1"
                                                              disabled={
                                                                  !profileExists
                                                              }
                                                              onClick={() =>
                                                                  navigate(
                                                                      `/assets/${profileExists.symbol}`
                                                                  )
                                                              }
                                                          >
                                                              Details
                                                          </Button>
                                                          <br />
                                                      </>
                                                  )}
                                                  {(hideTradeScreenWhenInactive
                                                      ? isUserActive
                                                      : true) && (
                                                      <Button
                                                          size="sm"
                                                          className="w-100"
                                                          onClick={() => {
                                                              navigate(
                                                                  `/portal/trade/${row.security}`
                                                              );
                                                          }}
                                                          disabled={
                                                              hideTradeScreenWhenInactive &&
                                                              !isUserActive
                                                          }
                                                      >
                                                          Trade
                                                      </Button>
                                                  )}
                                              </>
                                          );
                                      },
                                  },
                              ]
                            : [
                                  {
                                      dataField: "action",
                                      text: "",
                                      isDummyField: true,
                                      headerClasses: "tradingButton",
                                      classes: "tradingButton",
                                      formatter: (content, row) => {
                                          return (
                                              <>
                                                  <Button
                                                      size="sm"
                                                      className="w-100"
                                                      onClick={() => {
                                                          // Change tab to trading
                                                          storeOptions(
                                                              ws,
                                                              dispatch,
                                                              loggedInUser?.data,
                                                              "trade_preferences",
                                                              "trade_screen_configuration",
                                                              {
                                                                  ...state.trade
                                                                      .layoutConfigurationOptions,
                                                                  pageSelected:
                                                                      "trade",
                                                              },
                                                              undefined,
                                                              true
                                                          ).then(() =>
                                                              navigate(
                                                                  `/portal/trade/${row.security}`
                                                              )
                                                          );
                                                      }}
                                                  >
                                                      Trade
                                                  </Button>
                                              </>
                                          );
                                      },
                                  },
                              ]),
                    ];

                    return (
                        <div ref={parentContainer}>
                            {showConfigureOnMarketWatch && (
                                <Row
                                    noGutters
                                    className="mb-2 d-flex justify-content-end"
                                >
                                    {configureButtons}
                                </Row>
                            )}
                            {watchListedSymbols.length !== 0 && (
                                <>
                                    <TableRenderer
                                        keyField="security"
                                        data={JSON.parse(
                                            JSON.stringify(watchListedSymbols)
                                        )}
                                        condensed
                                        bordered={false}
                                        columns={columnsToRender.map((col) => ({
                                            ...col,
                                            formatExtraData:
                                                col.dataField === "security"
                                                    ? {
                                                          ...col.formatExtraData,
                                                          hoveredRow:
                                                              hoveredWatchlistRow,
                                                          symbols:
                                                              watchListedSymbols,
                                                      }
                                                    : col?.formatExtraData ||
                                                      {},
                                        }))}
                                        rowClasses={`market-watch-row ${
                                            isTradeScreen
                                                ? "cursor-pointer"
                                                : ""
                                        }`}
                                        wrapperClasses={`size-sm ${
                                            isTradeScreen
                                                ? "trade-market-watch-table  mini-market-watch-parent"
                                                : `market-watch-table`
                                        }`}
                                        rowEvents={
                                            isTradeScreen
                                                ? {
                                                      ...rowEvents(
                                                          setHoveredWatchlistRow
                                                      ),
                                                      onClick: (e, row) => {
                                                          // Change tab to trading
                                                          storeOptions(
                                                              ws,
                                                              dispatch,
                                                              loggedInUser?.data,
                                                              "trade_preferences",
                                                              "trade_screen_configuration",
                                                              {
                                                                  ...state.trade
                                                                      .layoutConfigurationOptions,
                                                                  pageSelected:
                                                                      "trade",
                                                              },
                                                              undefined,
                                                              true
                                                          ).then(() =>
                                                              navigate(
                                                                  `/portal/trade/${row.security}`
                                                              )
                                                          );
                                                      },
                                                  }
                                                : {
                                                      ...rowEvents(
                                                          setHoveredWatchlistRow
                                                      ),
                                                  }
                                        }
                                        loading={loading}
                                    />
                                </>
                            )}

                            {!enableNewMarketWatch && !isTradeScreen && (
                                <>
                                    <hr />
                                    <TableRenderer
                                        keyField="security"
                                        data={JSON.parse(
                                            JSON.stringify(unwatchedSymbols)
                                        )}
                                        columns={columnsToRender.map((col) => ({
                                            ...col,
                                            formatExtraData:
                                                col.dataField === "security"
                                                    ? {
                                                          ...col.formatExtraData,
                                                          hoveredRow:
                                                              hoveredUnwatchedRow,
                                                          symbols:
                                                              unwatchedSymbols,
                                                      }
                                                    : col?.formatExtraData ||
                                                      {},
                                        }))}
                                        noDataIndication="No Data Available"
                                        rowClasses="market-watch-row"
                                        wrapperClasses={`size-sm ${
                                            isTradeScreen
                                                ? "trade-market-watch-table  mini-market-watch-parent"
                                                : "market-watch-table"
                                        }`}
                                        loading={loading}
                                        rowEvents={rowEvents(
                                            setHoveredUnwatchedRow
                                        )}
                                    />
                                </>
                            )}
                        </div>
                    );
                }}
            />
        </>
    );
};
