/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { debounce } from "lodash";
import { useState } from "react";
import { Form } from "react-bootstrap";
import ReactGridLayout from "react-grid-layout";
import { SizeMe } from "react-sizeme";

import { tradeDestinations } from "../../../config";
import * as ACTIONS from "../../../contexts/actions/trade";
import { storeOptions } from "../../../helpers/display-options";
import { formatResultString } from "../../../helpers/other";
import { DefaultOrderTicketConfig } from "../../../preferences";
import { useStore } from "../../../Store";
import { useSocket } from "../../../Websocket";

const TradeScreenConfigurePage = () => {
    const ws = useSocket();
    const { state, dispatch } = useStore();
    const { layoutConfigurationOptions: editableLayout } = state.trade;
    // Doesnt merge with default layout, custom symbols will be missing
    // const layoutConfigurationOptions =
    //     editableLayout?.layoutSelected === "preset1"
    //         ? presetTradeLayout1
    //         : editableLayout?.layoutSelected === "preset2"
    //         ? presetTradeLayout2
    //         : editableLayout;
    const layoutConfigurationOptions = editableLayout;
    const { layout } = layoutConfigurationOptions;
    const orderTicketConfig =
        state.account.account.data.config_setting?.attr?.order_ticket ||
        DefaultOrderTicketConfig;

    const keyType = "trade_preferences";
    const optionKey = "trade_screen_configuration";

    const debounceSetLayout = debounce((currentLayout) => {
        dispatch(ACTIONS.updateLayout(currentLayout));

        storeOptions(
            ws,
            dispatch,
            state.account.account?.data,
            keyType,
            optionKey,
            currentLayout,
            "layout"
        );
    }, 500);

    const removeComponentFromLayout = (componentName) => {
        storeOptions(
            ws,
            dispatch,
            state.account.account?.data,
            keyType,
            optionKey,
            layoutConfigurationOptions.layout.filter(
                (elem) => elem.i !== componentName
            ),
            "layout"
        );
    };

    const [config, setConfig] = useState(layoutConfigurationOptions);

    const getTabValue = (parentKey, tabKey, tableKey = "ordersTableTabs") =>
        config[tableKey]?.[parentKey] !== undefined
            ? !!config[tableKey]?.[parentKey]?.includes(tabKey)
            : true;

    const storeNewTabs = (cfg, tableKey) =>
        storeOptions(
            ws,
            dispatch,
            state.account.account?.data,
            keyType,
            optionKey,
            cfg[tableKey],
            tableKey
        );

    const onChangeTabSelection = (
        { target: { name, checked } },
        parentKey,
        type = "orders"
    ) =>
        // eslint-disable-next-line no-undef
        new Promise(() => {
            const tableKey =
                type === "orders" ? "ordersTableTabs" : "booksTableTabs";
            const newConfig = {
                ...layoutConfigurationOptions,
                [tableKey]: {
                    ...layoutConfigurationOptions[tableKey],
                    [parentKey]: checked
                        ? (
                              layoutConfigurationOptions[tableKey][parentKey] ||
                              []
                          ).concat([name])
                        : (
                              layoutConfigurationOptions[tableKey][parentKey] ||
                              []
                          ).filter((tab) => tab !== name),
                },
            };
            setConfig(newConfig);

            storeNewTabs(newConfig, tableKey);
        });

    const toggleConfigureOnTables = ({ target: { name, checked } }) =>
        storeOptions(
            ws,
            dispatch,
            state.account.account?.data,
            keyType,
            optionKey,
            {
                ...layoutConfigurationOptions.configureEnabled,
                [name]: checked,
            },
            "configureEnabled"
        );

    const toggleExpandedOrderTicket = ({ target: { name, checked } }) =>
        storeOptions(
            ws,
            dispatch,
            state.account.account?.data,
            keyType,
            optionKey,
            {
                ...layoutConfigurationOptions.expandedOrderTicket,
                [name]: checked,
            },
            "expandedOrderTicket"
        );

    const toggleDestinationFieldOnMain = ({ target: { name, checked } }) =>
        storeOptions(
            ws,
            dispatch,
            state.account.account?.data,
            keyType,
            optionKey,
            {
                ...layoutConfigurationOptions.destinationFieldOnMain,
                [name]: checked,
            },
            "destinationFieldOnMain"
        );

    const isOneTabSelected = (
        parentKey,
        tableKey = "ordersTableTabs",
        tabKey
    ) =>
        config?.[tableKey]?.[parentKey] &&
        config?.[tableKey]?.[parentKey].includes(tabKey) &&
        config?.[tableKey]?.[parentKey].length === 1;

    return (
        <>
            <SizeMe>
                {({ size }) => (
                    <ReactGridLayout
                        layout={layout}
                        className="layout"
                        onLayoutChange={(lyt) =>
                            !layout && debounceSetLayout(lyt)
                        }
                        onDragStop={debounceSetLayout}
                        onResizeStop={debounceSetLayout}
                        width={size?.width || 1200}
                        cols={26}
                        rowHeight={48}
                        style={{
                            height: "100%",
                            width: "100%",
                        }}
                        margin={config.spacing}
                        containerPadding={[1, 1]}
                        autoSize
                        draggableCancel=".form-check-input,.hide-button"
                        // resizeHandle={
                        //     editableLayout?.layoutSelected?.startsWith(
                        //         "preset"
                        //     ) ? (
                        //         <></>
                        //     ) : null
                        // }
                    >
                        {layout.map((elem) => (
                            <div
                                key={elem.i}
                                data-grid={{
                                    ...elem,
                                    isResizable: elem.i.startsWith(
                                        "market-statistic"
                                    )
                                        ? true
                                        : elem.isResizable,
                                    // isDraggable:
                                    //     !editableLayout?.layoutSelected?.startsWith(
                                    //         "preset"
                                    //     ),
                                }}
                                className="trade-configure-item"
                                style={{
                                    background: "silver",
                                    color: "black",
                                    outline: "1px dotted yellow",
                                    fontSize: `${
                                        (config?.fontSize ?? 2) * 8
                                    }px`,
                                    border:
                                        !elem.i.startsWith("symbol-select") &&
                                        !elem.i.startsWith("market-statistic")
                                            ? `${
                                                  layoutConfigurationOptions?.padding ??
                                                  2
                                              }px solid red`
                                            : "0",
                                }}
                            >
                                <div
                                    className="hide-button"
                                    onClick={() =>
                                        removeComponentFromLayout(elem.i)
                                    }
                                >
                                    &times;
                                </div>

                                <div className="d-flex flex-wrap">
                                    <div
                                        className="text-center"
                                        style={{ flex: "100%" }}
                                    >
                                        {formatResultString(
                                            elem.i.replaceAll("-", " ")
                                        )}
                                    </div>

                                    <>
                                        <br />
                                        {elem.i.startsWith("order-ticket") &&
                                            // Check if additionalFlags are set to display
                                            (orderTicketConfig["LIMIT"]
                                                ?.additionalFlags?.display ||
                                                tradeDestinations?.length >
                                                    0) && (
                                                <>
                                                    <div
                                                        className="text-center my-1 d-flex"
                                                        style={{
                                                            flex: "100%",
                                                            justifyContent:
                                                                "center",
                                                        }}
                                                    >
                                                        <div>
                                                            <b>
                                                                Show Expanded
                                                                Order Ticket
                                                            </b>
                                                        </div>
                                                        <div className="ml-2">
                                                            <Form.Check
                                                                type="checkbox"
                                                                name={elem.i}
                                                                key={elem.i}
                                                                size="sm"
                                                                onChange={
                                                                    toggleExpandedOrderTicket
                                                                }
                                                                defaultChecked={
                                                                    layoutConfigurationOptions
                                                                        .expandedOrderTicket?.[
                                                                        elem.i
                                                                    ] ?? false
                                                                }
                                                                inline
                                                            />
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="text-center my-1 d-flex"
                                                        style={{
                                                            flex: "100%",
                                                            justifyContent:
                                                                "center",
                                                        }}
                                                    >
                                                        <div>
                                                            <b>
                                                                Add Destination
                                                                to Main Order
                                                                Ticket
                                                            </b>
                                                        </div>
                                                        <div className="ml-2">
                                                            <Form.Check
                                                                type="checkbox"
                                                                name={elem.i}
                                                                key={elem.i}
                                                                size="sm"
                                                                onChange={
                                                                    toggleDestinationFieldOnMain
                                                                }
                                                                defaultChecked={
                                                                    layoutConfigurationOptions
                                                                        .destinationFieldOnMain?.[
                                                                        elem.i
                                                                    ] ?? false
                                                                }
                                                                inline
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        {elem.i.startsWith("orders-table") && (
                                            <>
                                                <div
                                                    className="text-center my-1"
                                                    style={{ flex: "100%" }}
                                                >
                                                    <b>Tabs</b>
                                                </div>
                                                <div
                                                    style={{ flex: "100%" }}
                                                    className="text-center"
                                                >
                                                    <Form.Check
                                                        type="checkbox"
                                                        key={`${elem.i}-orders`}
                                                        name={"orders"}
                                                        label="Orders"
                                                        size="sm"
                                                        onChange={(e) =>
                                                            onChangeTabSelection(
                                                                e,
                                                                elem.i
                                                            )
                                                        }
                                                        checked={getTabValue(
                                                            elem.i,
                                                            "orders"
                                                        )}
                                                        disabled={isOneTabSelected(
                                                            elem.i,
                                                            "ordersTableTabs",
                                                            "orders"
                                                        )}
                                                        inline
                                                    />
                                                    <Form.Check
                                                        type="checkbox"
                                                        key={`${elem.i}-cancelled`}
                                                        name={"cancelled"}
                                                        label="Closed"
                                                        size="sm"
                                                        onChange={(e) =>
                                                            onChangeTabSelection(
                                                                e,
                                                                elem.i
                                                            )
                                                        }
                                                        checked={getTabValue(
                                                            elem.i,
                                                            "cancelled"
                                                        )}
                                                        disabled={isOneTabSelected(
                                                            elem.i,
                                                            "ordersTableTabs",
                                                            "cancelled"
                                                        )}
                                                        inline
                                                    />
                                                    <Form.Check
                                                        type="checkbox"
                                                        key={`${elem.i}-fills`}
                                                        name={"fills"}
                                                        label="Fills"
                                                        size="sm"
                                                        onChange={(e) =>
                                                            onChangeTabSelection(
                                                                e,
                                                                elem.i
                                                            )
                                                        }
                                                        checked={getTabValue(
                                                            elem.i,
                                                            "fills"
                                                        )}
                                                        disabled={isOneTabSelected(
                                                            elem.i,
                                                            "ordersTableTabs",
                                                            "fills"
                                                        )}
                                                        inline
                                                    />
                                                    <Form.Check
                                                        type="checkbox"
                                                        key={`${elem.i}-negotiate`}
                                                        name={"negotiate"}
                                                        label="Negotiate"
                                                        size="sm"
                                                        onChange={(e) =>
                                                            onChangeTabSelection(
                                                                e,
                                                                elem.i
                                                            )
                                                        }
                                                        checked={getTabValue(
                                                            elem.i,
                                                            "negotiate"
                                                        )}
                                                        disabled={isOneTabSelected(
                                                            elem.i,
                                                            "ordersTableTabs",
                                                            "negotiate"
                                                        )}
                                                        inline
                                                    />
                                                    <Form.Check
                                                        type="checkbox"
                                                        key={`${elem.i}-positions`}
                                                        name={"positions"}
                                                        label="Positions"
                                                        size="sm"
                                                        onChange={(e) =>
                                                            onChangeTabSelection(
                                                                e,
                                                                elem.i
                                                            )
                                                        }
                                                        checked={getTabValue(
                                                            elem.i,
                                                            "positions"
                                                        )}
                                                        disabled={isOneTabSelected(
                                                            elem.i,
                                                            "ordersTableTabs",
                                                            "positions"
                                                        )}
                                                        inline
                                                    />
                                                </div>
                                                <div
                                                    className="text-center my-1 d-flex"
                                                    style={{
                                                        flex: "100%",
                                                        justifyContent:
                                                            "center",
                                                    }}
                                                >
                                                    <div>
                                                        <b>
                                                            Show Configure
                                                            Buttons
                                                        </b>
                                                    </div>
                                                    <div className="ml-2">
                                                        <Form.Check
                                                            type="checkbox"
                                                            name={elem.i}
                                                            key={elem.i}
                                                            size="sm"
                                                            onChange={
                                                                toggleConfigureOnTables
                                                            }
                                                            defaultChecked={
                                                                layoutConfigurationOptions
                                                                    .configureEnabled?.[
                                                                    elem.i
                                                                ] ?? true
                                                            }
                                                            inline
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        {elem.i.startsWith("books-table") && (
                                            <>
                                                <div
                                                    className="text-center my-1"
                                                    style={{ flex: "100%" }}
                                                >
                                                    <b>Tabs</b>
                                                </div>
                                                <div
                                                    style={{ flex: "100%" }}
                                                    className="text-center"
                                                >
                                                    <Form.Check
                                                        type="checkbox"
                                                        key={`${elem.i}-books`}
                                                        name={"books"}
                                                        label="Books"
                                                        size="sm"
                                                        onChange={(e) =>
                                                            onChangeTabSelection(
                                                                e,
                                                                elem.i,
                                                                "books"
                                                            )
                                                        }
                                                        checked={getTabValue(
                                                            elem.i,
                                                            "books",
                                                            "booksTableTabs"
                                                        )}
                                                        disabled={isOneTabSelected(
                                                            elem.i,
                                                            "booksTableTabs",
                                                            "books"
                                                        )}
                                                        inline
                                                    />
                                                    <Form.Check
                                                        type="checkbox"
                                                        key={`${elem.i}-history`}
                                                        name={"history"}
                                                        label="History"
                                                        size="sm"
                                                        onChange={(e) =>
                                                            onChangeTabSelection(
                                                                e,
                                                                elem.i,
                                                                "books"
                                                            )
                                                        }
                                                        checked={getTabValue(
                                                            elem.i,
                                                            "history",
                                                            "booksTableTabs"
                                                        )}
                                                        disabled={isOneTabSelected(
                                                            elem.i,
                                                            "booksTableTabs",
                                                            "history"
                                                        )}
                                                        inline
                                                    />
                                                    <Form.Check
                                                        type="checkbox"
                                                        key={`${elem.i}-qms`}
                                                        name={"qms"}
                                                        label="QMS"
                                                        size="sm"
                                                        onChange={(e) =>
                                                            onChangeTabSelection(
                                                                e,
                                                                elem.i,
                                                                "books"
                                                            )
                                                        }
                                                        checked={getTabValue(
                                                            elem.i,
                                                            "qms",
                                                            "booksTableTabs"
                                                        )}
                                                        disabled={isOneTabSelected(
                                                            elem.i,
                                                            "booksTableTabs",
                                                            "qms"
                                                        )}
                                                        inline
                                                    />
                                                </div>
                                                <div
                                                    className="text-center my-1 d-flex"
                                                    style={{
                                                        flex: "100%",
                                                        justifyContent:
                                                            "center",
                                                    }}
                                                >
                                                    <div>
                                                        <b>
                                                            Show Configure
                                                            Buttons
                                                        </b>
                                                    </div>
                                                    <div className="ml-2">
                                                        <Form.Check
                                                            type="checkbox"
                                                            name={elem.i}
                                                            key={elem.i}
                                                            size="sm"
                                                            onChange={
                                                                toggleConfigureOnTables
                                                            }
                                                            defaultChecked={
                                                                layoutConfigurationOptions
                                                                    .configureEnabled?.[
                                                                    elem.i
                                                                ] ?? true
                                                            }
                                                            inline
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </>
                                </div>
                            </div>
                        ))}
                    </ReactGridLayout>
                )}
            </SizeMe>
        </>
    );
};

export default TradeScreenConfigurePage;
