import { toast } from "react-toastify";

import * as ACTIONS from "../contexts/actions/risk";
import { sendMessageQuery } from "./websocket";

/*
    account_update
    Edit Risk
 */

export const editRisk = (ws, editSetting, value) => {
    const wsValues = {
        firm: editSetting.firm,
        trader: editSetting.trader,
        [editSetting.field]: value,
    }
    // washcheck is the old field value but a lot of clients are still using it
    // wash_check is what it will be once ALL clients have migrated
    // Currently risk_setting only shows washcheck to avoid similar keys
    if (editSetting.field === "washcheck") wsValues["wash_check"] = value;
    return sendMessageQuery(ws, "account_update", wsValues);
}

/*
    queryalerts
    This function will get filtered data for Pre-Trade Alerts for Admin 
 */

export const getQueryAlerts = (ws, filter, fromtime = "") =>
    sendMessageQuery(ws, "queryalerts", {
        filter,
        fromtime,
    });

/*
    querymultiorders

*/

export const getOpenOrders = (ws, userid, security, firm) =>
    sendMessageQuery(ws, "querymultiorders", {
        userid,
        security,
        lastfirst: true,
        maxreturn: 1000,
        firm,
    }).then((res) => {
        const open = res.filter(
            ({ orderstatus }) =>
                !["canceled", "executed"].includes(orderstatus.toLowerCase())
        );

        return open;
    });
/*
    getriskcase

*/
export const getRiskCases = (ws, dispatch) =>
    sendMessageQuery(ws, "getriskcase")
        .then((res) => dispatch(ACTIONS.getRiskCases(res || [])))
        .catch(() => { });
/*
    uploadriskcase

*/
export const uploadRiskCase = (ws, dispatch, riskCase, type = "add") =>
    sendMessageQuery(ws, "uploadriskcase", {
        ...riskCase,
        details: JSON.stringify(riskCase.details),
    }).then((res) => {
        type === "add" && toast.success("Case successfully created.");

        return (
            type === "add" &&
            getRiskCases(ws, dispatch).then(() => ({
                ...res,
                details: JSON.parse(res.details),
            }))
        );
    });

export const queryRiskSettingHistory = (ws, firm, trader) =>
    sendMessageQuery(ws, "history_query", { firm, trader });
