/* eslint-disable no-unused-vars */
import { useEffect } from "react";
import { useState } from "react";
import {
    Col,
    FormControl,
    OverlayTrigger,
    Popover,
    Row,
    Table,
} from "react-bootstrap";

import { correctFieldType, getUserFile } from "../../../helpers/other";
import { useSocket } from "../../../Websocket";
import { Loading } from "../../Loading";

export const SubmissionRenderer = ({ submission, currentGroup = null }) => {
    const ws = useSocket();
    const [loading, setLoading] = useState(true);

    const [submissionFieldsWithImages, setSubmissionFieldsWithImages] =
        useState();

    const renderField = (field) => {
        switch (correctFieldType(field.fieldType)) {
            case "Image Upload":
            case "File Upload":
            case "e-Signature": {
                return field.value ? (
                    <div className="submission-content">
                        <embed
                            type={field.value.type}
                            src={`${field?.value}#toolbar=0&navpanes=0&scrollbar=0`}
                            alt="Submission Content"
                            height="250px"
                            width="100%"
                            style={{
                                objectFit: "contain",
                                maxHeight: "300px",
                            }}
                        />
                    </div>
                ) : (
                    <FormControl
                        disabled
                        defaultValue={"** This field was left blank **"}
                        size="sm"
                    />
                );
            }
            case "Checkbox": {
                return (
                    <div className="submission-subfield-content">
                        {field.subfields.map((subfield, i) => (
                            <div key={i} className="mb-1">
                                <div className="mb-1">{subfield.fieldName}</div>
                                <FormControl
                                    disabled
                                    defaultValue={
                                        subfield?.value ||
                                        "** This field was left blank **"
                                    }
                                    size="sm"
                                />
                            </div>
                        ))}
                    </div>
                );
            }
            case "Branch": {
                return (
                    <div className="submission-subfield-content">
                        Branch:{" "}
                        {field?.value
                            ? field.subfields[parseInt(field.value) || 0]
                                  ?.name || "Unnamed Branch"
                            : "No Branch Selected"}
                        <div className="mt-1">
                            {field.subfields[
                                parseInt(field.value) || 0
                            ].data.map((subfield, i) => (
                                <div key={i} className="mb-1">
                                    <div className="mb-1">
                                        {field.fieldName || "Unnamed Field"}{" "}
                                    </div>
                                    {renderField(subfield)}
                                </div>
                            ))}
                        </div>
                    </div>
                );
            }
            case "Table": {
                return (
                    <div className="submission-subfield-content">
                        {Object.keys(field?.value).length > 0 ? (
                            <Table striped bordered hover size="sm">
                                <thead>
                                    <tr>
                                        {field?.subfields?.map(
                                            (subfield, i) => (
                                                <th key={i}>
                                                    {subfield?.fieldName}
                                                </th>
                                            )
                                        )}
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.keys(field?.value).map((key, i) => (
                                        <tr key={i}>
                                            {field?.subfields?.map(
                                                (subfield, j) => (
                                                    <td key={j}>
                                                        {correctFieldType(
                                                            subfield.fieldType
                                                        ) === "File Upload" &&
                                                        field?.value[key]?.[j]
                                                            ?.value ? (
                                                            <OverlayTrigger
                                                                overlay={
                                                                    <Popover>
                                                                        <Popover.Title as="h3">
                                                                            {
                                                                                subfield.fieldName
                                                                            }
                                                                        </Popover.Title>
                                                                        <Popover.Content>
                                                                            <embed
                                                                                type={
                                                                                    field
                                                                                        .value
                                                                                        .type
                                                                                }
                                                                                src={`${field?.value[key]?.[j]?.value}#toolbar=0&navpanes=0&scrollbar=0`}
                                                                                key={`${field?.value[key]?.[j]?.value}#toolbar=0&navpanes=0&scrollbar=0`}
                                                                                height="250px"
                                                                                width="100%"
                                                                                style={{
                                                                                    objectFit:
                                                                                        "contain",
                                                                                    minWidth:
                                                                                        "200px",
                                                                                }}
                                                                            />
                                                                        </Popover.Content>
                                                                    </Popover>
                                                                }
                                                            >
                                                                <span>
                                                                    <u>
                                                                        {
                                                                            field
                                                                                ?.value[
                                                                                key
                                                                            ]?.[
                                                                                j
                                                                            ]
                                                                                ?.label
                                                                        }
                                                                    </u>
                                                                </span>
                                                            </OverlayTrigger>
                                                        ) : (
                                                            field?.value[key]?.[
                                                                j
                                                            ] ||
                                                            "** This field was left blank **"
                                                        )}
                                                    </td>
                                                )
                                            )}
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        ) : (
                            "No data available."
                        )}
                    </div>
                );
            }
            default:
                return (
                    <FormControl
                        disabled
                        defaultValue={
                            field?.value || "** This field was left blank **"
                        }
                        size="sm"
                    />
                );
        }
    };

    const replaceValueWithFile = (fld) =>
        getUserFile(ws, fld.value, submission.userid, true).then((data) => ({
            ...fld,
            value: data,
        }));

    const detectFieldType = (field) => {
        if (field?.value) {
            switch (correctFieldType(field.fieldType)) {
                case "File Upload":
                case "Image Upload":
                    return replaceValueWithFile(field);
                case "Branch": {
                    const branchPromiseArr = [];
                    let selectedBranch = field.subfields[field.value]?.data;

                    for (let j = 0; j < selectedBranch.length; j++) {
                        const subfield = selectedBranch[j];

                        branchPromiseArr.push(detectFieldType(subfield));
                    }

                    // eslint-disable-next-line no-undef
                    return Promise.all(branchPromiseArr).then((brnch) => {
                        const newBranchField = field;
                        newBranchField.subfields[field.value].data = brnch;

                        return newBranchField;
                    });
                }
                case "Table": {
                    const newValues = {};
                    const promises = [];

                    try {
                        field.value?.forEach((row, i) => {
                            field.subfields?.forEach((subfield, j) => {
                                if (
                                    correctFieldType(subfield.fieldType) ===
                                        "File Upload" &&
                                    row[j] &&
                                    !row[j]?.label
                                ) {
                                    promises.push(
                                        getUserFile(
                                            ws,
                                            row[j],
                                            submission.userid,
                                            true
                                        ).then((data) => {
                                            newValues[i] = newValues[i] || [];
                                            newValues[i][j] = {
                                                value: data,
                                                label: row[j],
                                            };
                                        })
                                    );
                                } else {
                                    newValues[i] = newValues[i] || [];
                                    newValues[i][j] = row[j];
                                }
                            });
                        });

                        return Promise.all(promises).then(() => ({
                            ...field,
                            value: newValues,
                        }));
                    } catch (e) {
                        return field;
                    }
                }
                default:
                    return field;
            }
        } else return field;
    };

    const mapFileValues = () => {
        const submissionFields =
            submission?.data &&
            "groups" in submission.data.form &&
            currentGroup !== null
                ? submission.data?.form?.groups[currentGroup]?.fields
                : submission.data?.form?.fields;

        const promiseArr = [];

        for (let i = 0; i < submissionFields.length; i++) {
            const field = submissionFields[i];

            promiseArr.push(detectFieldType(field));
        }
        // eslint-disable-next-line no-undef
        Promise.all(promiseArr).then((data) => {
            setSubmissionFieldsWithImages(data);
            setLoading(false);
        });
    };

    useEffect(() => {
        if (
            submission?.data?.form?.fields ||
            (submission?.data?.form &&
                "groups" in submission.data.form &&
                currentGroup !== null)
        ) {
            mapFileValues();
        } else {
            setLoading(false);
        }

        return () => setLoading(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submission, currentGroup]);

    return !loading ? (
        <>
            {submissionFieldsWithImages ? (
                submissionFieldsWithImages.map((field, i) => (
                    <Row noGutters key={i} className="mb-2">
                        <Col>
                            <div className="mb-1">
                                {field.fieldName || "Unnamed Field"}{" "}
                            </div>
                            {renderField(field)}
                        </Col>
                    </Row>
                ))
            ) : (
                <div className="text-center pb-4">No fields available.</div>
            )}
        </>
    ) : (
        <Loading />
    );
};
