/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from "react";
import { useEffect } from "react";
import {
    Button,
    Card,
    Col,
    Container,
    FormControl,
    Image,
    Row,
} from "react-bootstrap";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { Link, NavLink } from "react-router-dom";

import {
    alt_full_logo,
    clientLoginPageText,
    disableRegister,
    enforceEmailForUserID,
    full_logo,
} from "../config";
import { loginUser, send2fa } from "../helpers/login";
import { useStore } from "../Store";

const Login = () => {
    const { state, dispatch } = useStore();

    const [form, setForm] = useState({
        userid: undefined,
        email: undefined,
        password: "",
        token: "",
        keepMeLoggedIn: true,
    });

    const [use2FA, setUse2FA] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        const passwordBox = document.getElementsByName("password")[0];

        passwordBox.type = passwordBox.type === "text" ? "password" : "text";

        setShowPassword(!showPassword);
    };
    const disabledResync =
        (enforceEmailForUserID ? form.email : form.userid) && form.password
            ? false
            : true;

    const disabled =
        ((enforceEmailForUserID ? form.email : form.userid) && form.password
            ? false
            : true) ||
        (use2FA && form["token"].length === 0);

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
    };

    const handleLogin = async () => {
        await loginUser(dispatch, form);
    };

    const handleResync = async () => {
        await send2fa(dispatch, form);
    };

    const enterKeyPress = (e) => {
        if (e.key === "Enter") {
            handleLogin();
        }
    };

    useEffect(() => {
        if (state.account.auth.error.reason === "Invalid 2fa token") {
            setUse2FA(true);
            setForm({
                ...state.account.auth.account_info,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.account.auth.error]);

    return (
        <Container fluid className="login-container">
            <Row className="login-row g-0">
                <Col sm={8} md={6} lg={4} xl={4} className="login-col">
                    <Card className="login-box">
                        <Row className="g-0">
                            <Col className="login-content-col">
                                <Row className="login-header g-0">
                                    <Link to={"/"}>
                                        <Image
                                            fluid
                                            src={alt_full_logo}
                                            onError={(img) => {
                                                img.target.onerror = null;
                                                img.target.src = full_logo;
                                            }}
                                            className="login-logo"
                                        />
                                    </Link>
                                </Row>

                                {clientLoginPageText && (
                                    <Row className="login-box-padding login-formcontrol-row g-0">
                                        <Row className="login-welcome m-auto g-0">
                                            <h3>{clientLoginPageText}</h3>
                                        </Row>
                                    </Row>
                                )}

                                <Row className="login-box-padding login-formcontrol-row g-0">
                                    <Col className="login-box-col">
                                        <Row className="login-welcome  g-0">
                                            <Col>
                                                <h1>Welcome</h1>
                                                <p>Log in to continue</p>
                                            </Col>
                                        </Row>

                                        <Row className="login-formcontrol-container g-0">
                                            <div className="login-formcontrol-box">
                                                <div className="login-formcontrol-text">
                                                    {enforceEmailForUserID
                                                        ? "Email"
                                                        : "User ID"}
                                                </div>
                                                <FormControl
                                                    size="sm"
                                                    name={
                                                        enforceEmailForUserID
                                                            ? "email"
                                                            : "userid"
                                                    }
                                                    value={
                                                        (enforceEmailForUserID
                                                            ? form.email
                                                            : form.userid) || ""
                                                    }
                                                    onChange={handleOnChange}
                                                    className="login-formcontrol"
                                                    onKeyUp={enterKeyPress}
                                                />
                                            </div>
                                        </Row>

                                        <Row className="login-formcontrol-container g-0">
                                            <div className="login-formcontrol-box">
                                                <div className="login-formcontrol-text">
                                                    Password
                                                </div>
                                                <FormControl
                                                    size="sm"
                                                    name="password"
                                                    type="password"
                                                    value={form.password}
                                                    onChange={handleOnChange}
                                                    className="login-formcontrol"
                                                    onKeyUp={enterKeyPress}
                                                />
                                                <div
                                                    className="password-icon"
                                                    onClick={
                                                        togglePasswordVisibility
                                                    }
                                                    role="button"
                                                >
                                                    {showPassword ? (
                                                        <AiFillEyeInvisible />
                                                    ) : (
                                                        <AiFillEye />
                                                    )}
                                                </div>
                                            </div>
                                        </Row>

                                        {use2FA && (
                                            <Row className="login-formcontrol-container g-0">
                                                <div className="login-formcontrol-box">
                                                    <div className="login-formcontrol-text">
                                                        2FA Token
                                                    </div>
                                                    <FormControl
                                                        size="sm"
                                                        name="token"
                                                        type="token"
                                                        value={
                                                            form["token"] || ""
                                                        }
                                                        onChange={
                                                            handleOnChange
                                                        }
                                                        className="login-formcontrol"
                                                        onKeyUp={enterKeyPress}
                                                    />
                                                </div>
                                            </Row>
                                        )}

                                        <Row
                                            className={
                                                use2FA
                                                    ? "login-button-row-second "
                                                    : "login-button-row " +
                                                      "g-0"
                                            }
                                        >
                                            {use2FA && (
                                                <Button
                                                    size="sm"
                                                    disabled={disabledResync}
                                                    onClick={handleResync}
                                                    className="teal-button"
                                                >
                                                    RESYNC 2FA
                                                </Button>
                                            )}

                                            <Button
                                                size="sm"
                                                disabled={disabled}
                                                onClick={handleLogin}
                                                className="teal-button"
                                            >
                                                LOGIN
                                            </Button>
                                        </Row>

                                        <div className="login-navlinks">
                                            {!disableRegister && (
                                                <NavLink to="/register">
                                                    Create an Account
                                                </NavLink>
                                            )}
                                            <NavLink to="/forgot-password">
                                                Forgot your password?
                                            </NavLink>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default Login;
