/* eslint-disable react/display-name */
import lodash from "lodash";
import { memo, useState } from "react";

import { DefaultOrderTicketConfig } from "../../../../preferences";
import { PreferenceComponent } from "../PreferenceComponent";

const mergeNestedArrays = (config) => {
    // This func makes sure all nested arrays are saved properly
    const orderTypes = Object.keys(config);
    for (let index = 0; index < orderTypes.length; index++) {
        const orderType = orderTypes[index];
        const orderTypeConfig = config[orderType];
        mergeNestedByKey(config, orderType, "tif");
        mergeNestedByKey(config, orderType, "type");

        mergeNestedByKey(config, orderType, "capacity", "options", true);

        if (orderTypeConfig.additionalFlags) {
            mergeNestedByKey(
                config,
                orderType,
                "additionalFlags",
                "modalFields"
            );
        }
    }
    return config;
};

const mergeNestedByKey = (obj, orderType, key, subkey, useDefault = false) => {
    // Checks default and current config by key and or subkey
    // If there are any matches use settable values from the current config
    // Everything else use the default config
    // Whenever we want to add a new field it would be best to add to the end of the array
    // This way it'll align everything but even if it doesn't it will work and use the default

    // local copy
    const DefaultOrderTicketConfigCopy = JSON.parse(
        JSON.stringify(DefaultOrderTicketConfig)
    );

    const array = subkey
        ? obj[orderType]?.[key]?.[subkey]
        : obj[orderType]?.[key];

    const defaultArray = subkey
        ? DefaultOrderTicketConfigCopy[orderType]?.[key]?.[subkey]
        : DefaultOrderTicketConfigCopy[orderType]?.[key];

    if (array && Array.isArray(array)) {
        const uniqueArrayDisplayName = defaultArray.map((defaultElement, i) => {
            // use current to fill out default if they match else just keep default
            if (
                defaultElement.displayName === array[i]?.displayName || // Checks if displayName matches
                defaultElement.fieldName === array[i]?.fieldName // If not check fieldName (not all fields have displayName)
            ) {
                defaultElement.isHidden = useDefault
                    ? defaultElement.isHidden
                    : array[i]?.isHidden;
                defaultElement.default = useDefault
                    ? defaultElement.default
                    : array[i]?.default;
            }
            return defaultElement;
        });
        if (subkey) obj[orderType][key][subkey] = uniqueArrayDisplayName;
        else obj[orderType][key] = uniqueArrayDisplayName;
    }
};

export const OrderTicket = memo(({ comp, submitUpdate }) => {
    // Merge actual settings into default config to override default options.
    const [config, setConfig] = useState(
        mergeNestedArrays(
            lodash.merge(
                JSON.parse(JSON.stringify(DefaultOrderTicketConfig)),
                comp
            )
        )
        // Need to use below when updating the comp with a new field
        // lodash.merge(DefaultOrderTicketConfig, DefaultOrderTicketConfig)
    );

    const toggleSetting = (section, key, value) =>
        setConfig({
            ...config,
            [section]: {
                ...config[section],
                [key]: value,
            },
        });

    return (
        <PreferenceComponent
            comp={config}
            compKey={"order_ticket"}
            toggleSetting={toggleSetting}
            submitUpdate={() => submitUpdate(config, "order_ticket")}
        />
    );
});
