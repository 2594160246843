import { useEffect, useState } from "react";
import { Button, Col, Row, Tab, Tabs } from "react-bootstrap";
import { AiOutlineDelete } from "react-icons/ai";
import { useParams } from "react-router-dom";

import { EditAccountAccessModal } from "../../../components/admin/onboarding/EditAccountAccessModal";
import { TableRenderer } from "../../../components/portal/TableRenderer";
import { getUsersFromWebsocket } from "../../../helpers/firm";
import {
    addAccountAccess,
    queryExistingAccounts,
} from "../../../helpers/onboarding";
import { formatTableColumn } from "../../../helpers/other";
import { getAccountPositions } from "../../../helpers/resources";
import { useStore } from "../../../Store";
import { useSocket } from "../../../Websocket";

const AccountDetail = () => {
    const { state, dispatch } = useStore();
    const ws = useSocket();
    const { id: accountId } = useParams();
    const [loading, setLoading] = useState(true);

    const {
        firm: { existing_users: users },
        onboarding: { accounts },
    } = state;
    const accountDetails = accounts.find(
        (account) => account.account === accountId
    );

    const [tabKey, setTabKey] = useState("linked-users");
    const [positions, setPositions] = useState([]);

    const [showEditAccountAccessModal, setShowEditAccountAccessModal] =
        useState(false);
    const handleShowEditUserModal = () => {
        setShowEditAccountAccessModal(true);
    };
    const handleHideEditAccountAccessModal = () => {
        setShowEditAccountAccessModal(false);
    };

    const handleAddAccountAccess = (user, isDelete) => {
        addAccountAccess(
            ws,
            isDelete ? { ...user, isdelete: isDelete } : user
        ).then(() => {
            queryExistingAccounts(ws, dispatch);
            handleHideEditAccountAccessModal();
        });
    };

    useEffect(() => {
        if (accounts.length === 0) {
            queryExistingAccounts(ws, dispatch);
        }
        if (users.length === 0) {
            getUsersFromWebsocket(ws, dispatch).finally(() =>
                setLoading(false)
            );
        } else {
            setLoading(false);
        }

        return () => setLoading(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (accountDetails)
            getAccountPositions(ws, accountId, accountDetails.firm).then(
                (res) => {
                    setPositions(res);
                }
            );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accounts]);

    return (
        <div className="m-4">
            <Row noGutters className="forms-header justify-content-between">
                <Col>
                    <h4>Account Details</h4>
                </Col>
                <Col>
                    <Button
                        variant="outline-dark"
                        className="add-user-button"
                        size="sm"
                        onClick={handleShowEditUserModal}
                    >
                        Add User
                    </Button>
                </Col>
            </Row>
            <EditAccountAccessModal
                show={showEditAccountAccessModal}
                handleClose={handleHideEditAccountAccessModal}
                handleSubmit={handleAddAccountAccess}
                users={users}
                loading={loading}
                accounts={[accountId]}
            />
            <Tabs activeKey={tabKey} onSelect={(k) => setTabKey(k)}>
                <Tab eventKey="linked-users" title="Linked Users">
                    <TableRenderer
                        data={
                            accountDetails?.restricted_attr?.access_list ?? []
                        }
                        wrapperClasses="size-sm"
                        columns={[
                            ...["account", "firm"].map((col) =>
                                formatTableColumn(col)
                            ),
                            {
                                dataField: "action",
                                isDummyField: true,
                                text: "",
                                formatter: (cell, { account, firm }) => (
                                    <AiOutlineDelete
                                        className="cursor-pointer text-danger"
                                        onClick={() =>
                                            handleAddAccountAccess(
                                                {
                                                    user: account,
                                                    firm,
                                                    accounts: [accountId],
                                                },
                                                true
                                            )
                                        }
                                    />
                                ),
                            },
                        ]}
                        keyField="account"
                        noDataIndication="No linked users"
                        pagination
                        striped
                    />
                </Tab>
                <Tab eventKey="positions" title="Positions">
                    <TableRenderer
                        data={positions}
                        wrapperClasses="size-sm"
                        columns={[
                            ...["security", "curpos"].map((col) =>
                                formatTableColumn(col)
                            ),
                        ]}
                        keyField="security"
                        noDataIndication="No positions"
                        pagination
                        striped
                    />
                </Tab>
            </Tabs>
        </div>
    );
};

export default AccountDetail;
