import * as CONSTANTS from "../constants/other";

export const initialOtherState = {
    connected: false,
    links: [],
    loading: false,
};
export const otherReducer = (state, action) => {
    switch (action.type) {
        case CONSTANTS.QUERY_LINKS:
            return {
                ...state,
                links: state.links.concat(action.links),
            };
        case CONSTANTS.CONNECT_SOCKET:
            return { ...state, connected: true };
        case CONSTANTS.DISCONNECT_SOCKET:
            return { ...state, connected: false };
        case CONSTANTS.TOGGLE_DIM_LOADING:
            return {
                ...state,
                loading: !state.loading,
            };
        case CONSTANTS.CLEAR_STATE:
            return initialOtherState;
        default:
            return state;
    }
};
