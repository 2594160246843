/* eslint-disable react/display-name */
import Markdown from "markdown-to-jsx";
import { Fragment, memo, useEffect, useState } from "react";
import { Button, Col, FormControl, Row } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";

import { getCustodyAccountApplicationDisclosures } from "../../../helpers/brassica";
import { correctFieldType } from "../../../helpers/other";
import { useSocket } from "../../../Websocket";
import {
    BranchInput,
    CheckboxInput,
    DateInput,
    DropdownInput,
    ESignatureInput,
    FileUploadInput,
    LocationInput,
    NumberInput,
    ParagraphInput,
    StringInput,
} from "../../inputs/";
import { OnboardingTableInput } from "../../inputs/OnboardingTableInput";

export const renderField = (field, onChange, preview = false) => {
    const props = {
        field: {
            ...field,
            onboarding: true,
            preview,
        },
        onChange,
    };

    switch (correctFieldType(field.fieldType)) {
        case "String":
            return <StringInput {...props} />;
        case "Paragraph":
            return <ParagraphInput {...props} />;
        case "Location":
            return <LocationInput {...props} />;
        case "Date":
            return <DateInput {...props} />;
        case "Number":
            return <NumberInput {...props} />;
        case "File Upload":
        case "Image Upload":
            return <FileUploadInput {...props} />;
        case "e-Signature":
            return <ESignatureInput {...props} />;
        case "Display":
            return;
        case "Dropdown":
            return <DropdownInput {...props} />;
        case "Branch":
            return <BranchInput {...props} />;
        case "IDM-Plugin":
            return;
        case "Checkbox":
            return <CheckboxInput {...props} />;
        case "Table":
            return <OnboardingTableInput {...props} />;
        default:
            return <FormControl size="sm" />;
    }
};
export const FormRenderer = memo(
    ({
        form,
        setForm = () => {},
        submitForm = () => {},
        preview = false,
        custodyAccountApplicationId,
        isBrassica = false,
        stepOneCompleted = false,
        // eslint-disable-next-line no-unused-vars
        stepTwoThreeCompleted = false,
        stepFourCompleted = false,
        // eslint-disable-next-line no-unused-vars
        stepFivePartOneCompleted = false,
        kycLinks = [],
        custodyAgreement,
        nomineeAgreement,
        eSignConsentStatement = "",
        usersName,
        currentGroup,
        groupCount,
    }) => {
        const ws = useSocket();

        const [disclosureAgreement, setDisclosureAgreement] =
            useState("<div></div>");

        useEffect(() => {
            if (isBrassica && !stepOneCompleted) {
                getCustodyAccountApplicationDisclosures(
                    ws,
                    custodyAccountApplicationId,
                    true
                ).then((disclosure) => {
                    setDisclosureAgreement(disclosure);
                });
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        useEffect(() => {
            if (custodyAgreement || nomineeAgreement) {
                const signatureBox = document.getElementsByClassName(
                    "electronic-signature"
                )[0];

                signatureBox.innerHTML = `<div>
                        <u>_______${usersName}_______</u>
                        <br/>
                        (First Last)
                        <br />
                        Date: ${new Date().toLocaleDateString()}
                    </div>`;
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [custodyAgreement, nomineeAgreement]);

        return (
            <div className="size-sm onboarding-form">
                {!stepOneCompleted && !stepTwoThreeCompleted ? (
                    <>
                        <div className="text-center">
                            <h5>{form.name}</h5>
                        </div>
                        <hr />
                        {form.fields.map((field, i) => {
                            const nextField = form.fields?.[i + 1];
                            const splitFields =
                                nextField &&
                                (nextField?.subgroup || "B") !==
                                    (field?.subgroup || "B");

                            return (
                                <Fragment key={i}>
                                    <div
                                        className={`onboarding-group text-left ${
                                            [
                                                "Display",
                                                "e-Signature",
                                                "Checkbox",
                                                "Table",
                                            ].includes(
                                                correctFieldType(
                                                    field.fieldType
                                                )
                                            )
                                                ? "display"
                                                : ""
                                        } `}
                                    >
                                        <Row noGutters>
                                            <b>{field.fieldName}</b>
                                        </Row>
                                        <Col className="mb-1 px-1">
                                            {field?.fieldDescription &&
                                                field.fieldDescription.length >
                                                    0 &&
                                                field.fieldDescription !==
                                                    "<p><br></p>" &&
                                                field.fieldType !== "Table" &&
                                                ReactHtmlParser(
                                                    field.fieldDescription
                                                )}
                                        </Col>
                                        {renderField(
                                            field,
                                            (value) => {
                                                const newFields = Array.from(
                                                    form.fields
                                                );

                                                // Need to only update the fields within our current group

                                                if (
                                                    [
                                                        "Branch",
                                                        "Checkbox",
                                                    ].includes(
                                                        correctFieldType(
                                                            field.fieldType
                                                        )
                                                    )
                                                ) {
                                                    newFields[i] = value;
                                                } else {
                                                    newFields[i].value = value;
                                                }
                                                setForm({
                                                    ...form,
                                                    fields: newFields,
                                                });
                                            },
                                            preview
                                        )}
                                    </div>
                                    {splitFields && <hr />}
                                </Fragment>
                            );
                        })}
                        <br />
                        <style>
                            {`
                    h1 {
                        font-size: 1.75rem !important;
                    }`}
                        </style>
                        <div
                            id="disclosure-agreement"
                            dangerouslySetInnerHTML={{
                                __html: disclosureAgreement,
                            }}
                        ></div>
                    </>
                ) : (
                    <></>
                )}
                {stepTwoThreeCompleted && !stepFourCompleted && (
                    <>
                        {kycLinks.map((link, i) => (
                            <Fragment key={i}>
                                <div
                                    key={i}
                                    className="w-100 "
                                    style={{ height: "500px" }}
                                >
                                    <iframe
                                        src={link}
                                        title={i}
                                        className="w-100 h-100"
                                    ></iframe>
                                </div>
                                <div className="py-2">
                                    <a
                                        href={link}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        If the above page could not be loaded,
                                        please click here to complete.
                                    </a>
                                </div>
                            </Fragment>
                        ))}
                    </>
                )}
                {stepFourCompleted && (
                    <>
                        <div
                            id="disclosure-agreement"
                            dangerouslySetInnerHTML={{
                                __html:
                                    stepFourCompleted &&
                                    !stepFivePartOneCompleted
                                        ? custodyAgreement
                                        : nomineeAgreement,
                            }}
                        ></div>
                        <br />
                    </>
                )}

                <div className="text-center" style={{ flex: "1 0 100%" }}>
                    {stepFourCompleted && (
                        <>
                            <Markdown>{eSignConsentStatement}</Markdown>
                            {/* <br /> */}
                            <br />
                            {/* <Button className="mr-1">Previous</Button> */}
                        </>
                    )}
                    <Button onClick={submitForm}>
                        {(stepOneCompleted || stepTwoThreeCompleted) &&
                        !stepFourCompleted &&
                        !stepFivePartOneCompleted
                            ? "Continue"
                            : stepFourCompleted
                            ? "Agree and Sign"
                            : groupCount - 1 === currentGroup
                            ? "Submit"
                            : "Next"}
                    </Button>
                </div>
            </div>
        );
    }
);
