/* eslint-disable react/display-name */
import { memo } from "react";
import { useState } from "react";
import { Button, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
import Select from "react-select";

import {
    getRandomStringCharAndNum,
    SELECT_SMALL_SIZE_STYLE,
} from "../../../helpers/other";
import { validateOnlyNumbersLetters } from "../../../helpers/validate";

const DEFAULT_ACCOUNT = {
    firm: null,
    account: "",
};

export const EditAccountModal = memo(
    ({ show = false, handleClose, handleSubmit, firms }) => {
        const [account, setAccount] = useState(DEFAULT_ACCOUNT);

        const handleOnChangeAccount = ({ target: { name, value } }) =>
            setAccount({
                ...account,
                [name]: value.toUpperCase(),
            });

        return (
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Create Account</Modal.Title>
                </Modal.Header>
                <Modal.Body className="size-sm">
                    <Form.Group>
                        <Form.Label className="size-sm">Firm</Form.Label>
                        <Select
                            onChange={(e) =>
                                handleOnChangeAccount({
                                    target: {
                                        name: "firm",
                                        value: e.value,
                                    },
                                })
                            }
                            options={firms.map((firm) => ({
                                value: firm.firm,
                                label: firm.firm,
                            }))}
                            styles={SELECT_SMALL_SIZE_STYLE}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label className="size-sm">Account</Form.Label>
                        <InputGroup>
                            <Form.Control
                                type="text"
                                value={account.account}
                                name="account"
                                onChange={handleOnChangeAccount}
                                onKeyDown={validateOnlyNumbersLetters}
                                area-describedby="amount"
                                size="sm"
                            />
                            <InputGroup.Text
                                id="amount"
                                style={{
                                    borderTopLeftRadius: 0,
                                    borderBottomLeftRadius: 0,
                                    fontSize: "12px",
                                    cursor: "pointer",
                                }}
                                onClick={() =>
                                    handleOnChangeAccount({
                                        target: {
                                            name: "account",
                                            value: getRandomStringCharAndNum(),
                                        },
                                    })
                                }
                            >
                                Generate
                            </InputGroup.Text>
                        </InputGroup>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Row noGutters className="w-100 size-sm">
                        <Col className="text-right">
                            <Button
                                size="sm"
                                onClick={() => handleSubmit(account)}
                                disabled={!account.firm || !account.account}
                            >
                                Save
                            </Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        );
    }
);
