import { Row } from "react-bootstrap";

import { UserAccountDropdown } from "../UserAccountDropdown";

export const AdminTopbar = () => {
    // const {
    //     dispatch,
    //     state: {
    //         account: { account },
    //         other,
    //     },
    // } = useStore();

    // const renderNavBarLinks = () => {
    //     return (
    //         <NavDropdown className="topbar-navdropdown" title="Trading Portal">
    //             {other.links.map((link, key) => (
    //                 <NavDropdown.Item
    //                     href={link.url}
    //                     className="topbar-navdropitem"
    //                     key={key}
    //                 >
    //                     {link.name}
    //                 </NavDropdown.Item>
    //             ))}
    //         </NavDropdown>
    //     );
    // };

    return (
        <div className="topbar">
            <Row noGutters className="topbar-row">
                <div className="search-container" title="FIRM">
                    {/* {renderNavBarLinks()} */}
                </div>
                <UserAccountDropdown />
            </Row>
        </div>
    );
};
