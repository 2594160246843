import { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";

import { ChangePassword } from "../../components/portal/ChangePassword";
import { TwoFA } from "../../components/portal/TowFA";
import { custodianLink } from "../../config";
import { useStore } from "../../Store";

const Profile = () => {
    const { state } = useStore();

    const {
        data: loggedInUser,
        data: { use2fa: use2FA },
    } = state.account.account;

    const [status, setStatus] = useState(null);

    return (
        <div className="m-4">
            <Row noGutters className="forms-header">
                <Col>
                    <h4>Profile</h4>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={6}>
                    <Col>
                        <Row>
                            <Col xs={3}>Name:</Col>
                            <Col>
                                {loggedInUser.first_name}{" "}
                                {loggedInUser.last_name}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={3}>User ID:</Col>
                            <Col>
                                {loggedInUser?.userid || loggedInUser?.userId}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={3}>Email:</Col>
                            <Col>{loggedInUser.email}</Col>
                        </Row>
                        <Row>
                            <Col xs={3}>Firm:</Col>
                            <Col>{loggedInUser.firm}</Col>
                        </Row>
                    </Col>
                </Col>
                <Col xs={12} md={6}>
                    <Button
                        className="mr-2"
                        onClick={() => setStatus("password")}
                    >
                        Change Password
                    </Button>
                    {!use2FA || use2FA === "N" ? (
                        <Button onClick={() => setStatus("set2fa")}>
                            Set 2FA
                        </Button>
                    ) : (
                        <Button onClick={() => setStatus("disable2fa")}>
                            Disable 2FA
                        </Button>
                    )}
                    {custodianLink && (
                        <Button
                            className="ml-2"
                            href={custodianLink}
                            target="_blank"
                        >
                            Link Custodian
                        </Button>
                    )}
                </Col>
            </Row>
            {status === "password" ? (
                <ChangePassword />
            ) : status === "set2fa" ? (
                <TwoFA
                    userid={loggedInUser?.userId || loggedInUser?.userid}
                    use2fa="Y"
                />
            ) : status === "disable2fa" ? (
                <TwoFA
                    userid={loggedInUser?.userId || loggedInUser?.userid}
                    use2fa="N"
                />
            ) : null}
        </div>
    );
};

export default Profile;
