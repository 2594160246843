import { Button, Modal } from "react-bootstrap";

export const DeleteModal = ({
    show = false,
    handleCloseModal,
    handleDelete,
    user,
}) => {
    return (
        <Modal show={show} onHide={handleCloseModal}>
            <Modal.Header closeButton>
                <Modal.Title>Delete User</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Are you sure you want to delete this user?
                <br />
                {JSON.stringify(user.userid)}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={handleDelete} size="sm">
                    Delete
                </Button>
                <Button variant="light" onClick={handleCloseModal} size="sm">
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
