import { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { createGlobalStyle } from "styled-components";

import { isQMSClient } from "../../../config";
import { storeOptions } from "../../../helpers/display-options";
import { convertStylingVar } from "../../../helpers/other";
import { useStore } from "../../../Store";
import { useSocket } from "../../../Websocket";
import { OrderDetailModal } from "../OrderDetailModal";
import { CancelOrdersTab } from "./order-tables/CancelOrdersTab";
import { FillOrdersTab } from "./order-tables/FillOrdersTab";
import { NegotiateOrdersTab } from "./order-tables/NegotiateOrdersTab";
import { PendingOrdersTab } from "./order-tables/PendingOrdersTab";
import { PositionsTab } from "./order-tables/PositionsTab";

const DynamicHeightStyling = createGlobalStyle`
    #${(props) => props.layoutKey} .tab-content {
        height: calc(100% - ${(props) => props.height}px) !important;
    }
`;

// Get height of config-buttons-row and trade-tab

export const OrdersTable = ({
    layoutKey,
    handleOpenConfirmModal,
    handleOpenNegotiateModal,
    handleOpenOrderDetailModal,
}) => {
    const ws = useSocket();
    const { state, dispatch } = useStore();

    const { layoutConfigurationOptions } = state.trade;
    const configuredTabs =
        layoutConfigurationOptions?.ordersTableTabs?.[layoutKey] || [];
    const previouslySelectedTab =
        layoutConfigurationOptions?.lastSelectedTabs?.[layoutKey] || "orders";

    const [height, setHeight] = useState(100);

    const updateSize = () => {
        let currentHeight = 100;

        try {
            const tabsHeight =
                document.getElementsByClassName("orders-table-tabs")[0]
                    .offsetHeight;

            const idContainerConfigButtonRows = document.querySelectorAll(
                `#${layoutKey} .orders-config-buttons-row`
            );
            const configButtonsRowHeight = Array.from(
                idContainerConfigButtonRows
            ).reduce((maxHeight, row) => {
                return row.offsetHeight > maxHeight
                    ? row.offsetHeight
                    : maxHeight;
            }, 0);
            currentHeight =
                tabsHeight +
                configButtonsRowHeight +
                (configButtonsRowHeight === 0 ? -10 : 5);
        } catch (err) {
            //
        }
        setHeight(currentHeight);
    };

    useEffect(() => {
        window.addEventListener(`resize${layoutKey}`, updateSize);
        updateSize();
        return () =>
            window.removeEventListener(`resize${layoutKey}`, updateSize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fontColor = convertStylingVar(
        layoutConfigurationOptions.styling.fontColor
    );

    const [tabKey, setTabKey] = useState(
        configuredTabs && configuredTabs?.includes(previouslySelectedTab)
            ? previouslySelectedTab
            : configuredTabs?.[0] || "pending"
    );

    useEffect(() => {
        setTimeout(() => {
            updateSize();
        }, 200);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tabKey]);

    return (
        <div
            style={{
                padding: layoutConfigurationOptions?.padding,
                color: `${convertStylingVar(
                    layoutConfigurationOptions.styling.fontColor
                )} !important `,
                border: layoutConfigurationOptions?.componentBorders
                    ? `1px solid ${fontColor}`
                    : 0,
                height: "100%",
            }}
            className="orders-table-tab-container"
            id={layoutKey}
        >
            <OrderDetailModal />
            <Tabs
                className="trade-tab orders-table-tabs size-sm"
                activeKey={tabKey}
                onSelect={(tab) => {
                    setTabKey(tab);
                    storeOptions(
                        ws,
                        dispatch,
                        state.account.account?.data,
                        "trade_preferences",
                        "trade_screen_configuration",
                        {
                            ...layoutConfigurationOptions.lastSelectedTabs,
                            [layoutKey]: tab,
                        },
                        "lastSelectedTabs"
                    );
                }}
                defaultActiveKey="orders"
            >
                {configuredTabs.includes("orders") && (
                    <Tab
                        eventKey="orders"
                        title={
                            isQMSClient
                                ? "My Indications of Interest"
                                : "ORDERS"
                        }
                    >
                        <PendingOrdersTab
                            layoutKey={layoutKey}
                            handleOpenOrderDetailModal={
                                handleOpenOrderDetailModal
                            }
                            handleOpenConfirmModal={handleOpenConfirmModal}
                        />
                    </Tab>
                )}
                {configuredTabs.includes("cancelled") && (
                    <Tab eventKey="cancelled" title="CLOSED">
                        <CancelOrdersTab layoutKey={layoutKey} />
                    </Tab>
                )}
                {configuredTabs.includes("fills") && (
                    <Tab eventKey="fills" title="FILLS">
                        <FillOrdersTab layoutKey={layoutKey} />
                    </Tab>
                )}
                {configuredTabs.includes("negotiate") && (
                    <Tab eventKey="negotiate" title="NEGOTIATE">
                        <NegotiateOrdersTab
                            layoutKey={layoutKey}
                            handleOpenOrderDetailModal={
                                handleOpenOrderDetailModal
                            }
                            handleOpenNegotiateModal={handleOpenNegotiateModal}
                        />
                    </Tab>
                )}
                {configuredTabs.includes("positions") && (
                    <Tab eventKey="positions" title="POSITIONS">
                        <PositionsTab layoutKey={layoutKey} />
                    </Tab>
                )}
            </Tabs>
            <DynamicHeightStyling height={height} layoutKey={layoutKey} />
        </div>
    );
};
