export const DefaultOrderTicketConfig = {
    LIMIT: {
        display: true,
        type: [
            {
                displayName: "Limit",
                ordertype: "LMT",
                default: true,
            },
            {
                displayName: "Post Only",
                ordertype: "POST",
                default: false,
                additionalFields: [
                    {
                        fieldName: "alo",
                        fieldType: "Boolean",
                        fieldValue: true,
                    },
                ],
            },
            {
                displayName: "Iceberg",
                ordertype: "ICEBERG",
                default: false,
                additionalFields: [
                    {
                        fieldName: "displayqty",
                        fieldType: "Number",
                        fieldSync: "Amount",
                    },
                ],
            },
            {
                displayName: "Stop Order",
                ordertype: "STOP",
                default: false,
                additionalFields: [
                    {
                        fieldName: "stopprice",
                        fieldType: "Number",
                        fieldSync: "Price",
                    },
                    {
                        fieldName: "stoptype",
                        fieldType: "String",
                        fieldValue: "STOP",
                    },
                ],
            },
        ],
        tif: [
            { displayName: "GTC", value: "GTC", default: true },
            { displayName: "IOC", value: "IOC", default: false },
            {
                displayName: "GTT",
                value: "GTT",
                default: false,
                additionalFields: [
                    {
                        fieldName: "exptime",
                        fieldType: "Date",
                    },
                ],
            },
            { displayName: "FOK", value: "FOK", default: false },
            { displayName: "DAY", value: "DAY", default: false },
        ],
        account: {
            display: true,
            fieldType: "Dropdown",
        },
        capacity: {
            display: false,
            displayName: "Capacity",
            fieldName: "capacity",
            fieldType: "Dropdown",
            options: [
                {
                    displayName: "Principal",
                    value: "P",
                },
                {
                    displayName: "Agency",
                    value: "A",
                    default: true,
                },
                {
                    displayName: "Riskless",
                    value: "R",
                },
            ],
        },
        clientOrderId: {
            display: true,
            fieldType: "String",
            optional: true,
        },
        additionalFlags: {
            display: true,
            fieldType: "Modal",
            modalFields: [
                {
                    displayName: "Attributable",
                    fieldName: "TEMP1",
                    fieldType: "Checkbox",
                    selectedValue: true,
                    ATS: true,
                    externalATS: true,
                },
                {
                    displayName: "Prevent Cross At Venue",
                    fieldName: "TEMP2",
                    fieldType: "Checkbox",
                    selectedValue: true,
                    alwaysOn: true,
                },
                {
                    displayName: "InterMarket Sweep",
                    fieldName: "TEMP3",
                    fieldType: "Checkbox",
                    selectedValue: true,
                    alwaysOn: true,
                },
                {
                    displayName: "Anonymous",
                    fieldName: "TEMP4",
                    fieldType: "Checkbox",
                    selectedValue: true,
                    alwaysOn: true,
                },
                {
                    displayName: "Locate Stock",
                    fieldName: "TEMP5",
                    fieldType: "Number",
                    DMA: true,
                },
                {
                    displayName: "On Close",
                    fieldName: "TEMP6",
                    fieldType: "Checkbox",
                    selectedValue: true,
                    ATS: true,
                    externalATS: true,
                },
                {
                    displayName: "Internal Peg",
                    fieldName: "TEMP7",
                    fieldType: "Checkbox",
                    selectedValue: true,
                    ATS: true,
                },
                {
                    displayName: "Is Marker",
                    fieldName: "ismarker",
                    fieldType: "Checkbox",
                    selectedValue: true,
                    alwaysOn: true,
                },
                {
                    displayName: "Keep Minimum Quote",
                    fieldName: "keepmin",
                    fieldType: "Checkbox",
                    selectedValue: true,
                    alwaysOn: true,
                    additionalFields: [
                        {
                            displayName: "Minimum Quote Quantity",
                            fieldName: "minquoteqty",
                            fieldType: "Number",
                        },
                    ],
                },
                {
                    displayName: "Short Sale",
                    fieldName: "shortsaleflag",
                    fieldType: "Checkbox",
                    selectedValue: true,
                    alwaysOn: true,
                },
                {
                    fieldName: "qaprate",
                    fieldType: "Number",
                    OTCLINK: true,
                },
                {
                    displayName: "Effective Time",
                    fieldName: "effective_time",
                    fieldType: "Date",
                    bindedField: "effective_day",
                    alwaysOn: true,
                },
                {
                    displayName: "Strategy",
                    fieldName: "strategy",
                    fieldType: "Dropdown",
                    strategy: true,
                    options: [
                        {
                            fieldValue: "TWAP",
                            additionalFields: [
                                {
                                    displayName: "Max Volume Percentage",
                                    fieldName: "maxvolumepercentage",
                                    fieldType: "String",
                                },
                                {
                                    displayName: "Slice Interval",
                                    fieldName: "interval",
                                    fieldType: "Number",
                                },
                            ],
                        },
                        // {
                        //     fieldValue: "VWAP",
                        //     additionalFields: [
                        //         {
                        //             displayName: "Participation Rate",
                        //             fieldName: "participation_rate",
                        //             fieldType: "String",
                        //         },
                        //     ],
                        // },
                        // {
                        //     fieldValue: "POV",
                        //     additionalFields: [],
                        // },
                    ],
                    additionalFields: [
                        {
                            displayName: "Start Time",
                            fieldName: "effective_time",
                            fieldType: "Date",
                            bindedField: "effective_day",
                        },
                        {
                            displayName: "End Time",
                            fieldName: "exptime",
                            fieldType: "Date",
                            bindedField: "exp_day",
                        },
                    ],
                },
            ],
        },
    },
    MKT: {
        display: true,
        type: [
            {
                displayName: "MKT",
                ordertype: "MKT",
                default: true,
            },
            {
                displayName: "Stop Order",
                ordertype: "STOP",
                default: false,
                additionalFields: [
                    {
                        fieldName: "stopprice",
                        fieldType: "Number",
                        fieldSync: "Price",
                    },
                    {
                        fieldName: "stoptype",
                        fieldType: "String",
                        fieldValue: "STOP",
                    },
                ],
            },
        ],
        tif: [{ displayName: "GTC", value: "GTC", default: true }],
        account: {
            display: true,
            fieldType: "Dropdown",
        },
        clientOrderId: {
            display: true,
            fieldType: "String",
            optional: true,
        },
        additionalFlags: {
            display: true,
            fieldType: "Modal",
            modalFields: [
                {
                    displayName: "Attributable",
                    fieldName: "TEMP1",
                    fieldType: "Checkbox",
                    selectedValue: true,
                },
                {
                    displayName: "Prevent Cross At Venue",
                    fieldName: "TEMP2",
                    fieldType: "Checkbox",
                    selectedValue: true,
                },
                {
                    displayName: "InterMarket Sweep",
                    fieldName: "TEMP3",
                    fieldType: "Checkbox",
                    selectedValue: true,
                },
                {
                    displayName: "Anonymous",
                    fieldName: "TEMP4",
                    fieldType: "Checkbox",
                    selectedValue: true,
                },
                {
                    displayName: "Locate Stock",
                    fieldName: "TEMP5",
                    fieldType: "Checkbox",
                    selectedValue: true,
                },
                {
                    displayName: "On Close",
                    fieldName: "TEMP6",
                    fieldType: "Checkbox",
                    selectedValue: true,
                },
                {
                    displayName: "Internal Peg",
                    fieldName: "TEMP7",
                    fieldType: "Checkbox",
                    selectedValue: true,
                },
                {
                    displayName: "Keep Minimum Quote",
                    fieldName: "keepmin",
                    fieldType: "Checkbox",
                    selectedValue: true,
                },
            ],
        },
    },
    IOI: {
        display: true,
        tif: [
            { displayName: "GTC", value: "GTC", default: true },
            { displayName: "IOC", value: "IOC", default: false },
            {
                displayName: "GTT",
                value: "GTT",
                default: false,
                additionalFields: [
                    {
                        fieldName: "exptime",
                        fieldType: "Date",
                    },
                ],
            },
            { displayName: "FOK", value: "FOK", default: false },
            { displayName: "DAY", value: "DAY", default: false },
        ],
        account: {
            display: true,
            fieldType: "Dropdown",
        },
        clientOrderId: {
            display: true,
            fieldType: "String",
            optional: true,
        },
        setting: {
            fieldName: "ioi",
            fieldType: "Boolean",
            fieldValue: true,
        },
    },
    NEGOTIATE: {
        display: true,
        brokers: {
            display: true,
            fieldType: "String",
            displayName: "Firm",
        },
        account: {
            display: true,
            fieldType: "Dropdown",
        },
        clientOrderId: {
            display: true,
            fieldType: "String",
            optional: true,
        },
        setting: {
            fieldName: "negotiate",
            fieldType: "Boolean",
            fieldValue: true,
        },
    },
};

