/* eslint-disable react/display-name */
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";

import { useState } from "react";
import { memo } from "react";
import { useEffect } from "react";
import { Button, Col, FormControl, Modal, Row } from "react-bootstrap";
import { InputGroup } from "react-bootstrap";
import RangeSlider from "react-bootstrap-range-slider";

import { formatDisplayTextForConfigure } from "../helpers/display-options";

export const ConfigureStylingModal = memo(
    ({
        show = false,
        handleCloseModal,
        keys,
        saveStyling,
        oldStyles = [],
        enableNewTextFormatting = false,
    }) => {
        const [selectedColumn, setSelectedColumn] = useState("select_option");
        const [newWidth, setNewWidth] = useState(40);
        const [widthType, setWidthType] = useState("px");

        const findExistingWidth = () => {
            const existing = oldStyles.filter(
                (col) => col.key === selectedColumn
            )[0];

            if (existing?.style?.width) {
                // eslint-disable-next-line no-unused-vars
                const [e, width, type] = existing.style.width.split(/(\d+)/g);
                type && setWidthType(type);
                setNewWidth(width);
            }
        };

        useEffect(() => {
            findExistingWidth();
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [selectedColumn]);

        return (
            <Modal show={show} onHide={handleCloseModal}>
                <Modal.Header closeButton>Configure Styling</Modal.Header>
                <Modal.Body>
                    <Row noGutters>Column</Row>
                    <Row noGutters className="m-1">
                        <select
                            className="custom-select"
                            defaultValue={"select_option"}
                            onChange={({ target: { value } }) =>
                                setSelectedColumn(value)
                            }
                        >
                            <option value="select_option" disabled>
                                Select Option...
                            </option>
                            {keys.map((key, i) => (
                                <option key={i} value={key}>
                                    {enableNewTextFormatting
                                        ? formatDisplayTextForConfigure(key)
                                        : key}
                                </option>
                            ))}
                        </select>
                    </Row>
                    {selectedColumn !== "select_option" && (
                        <>
                            <Row noGutters>Width</Row>
                            <Row
                                noGutters
                                className="m-1 justify-content-between"
                            >
                                <Col sm={9}>
                                    <RangeSlider
                                        size="sm"
                                        value={newWidth}
                                        onChange={({ target: { value } }) =>
                                            setNewWidth(value)
                                        }
                                        tooltipLabel={(currentValue) =>
                                            `${currentValue}${widthType} `
                                        }
                                        variant="dark"
                                        className="pr-2"
                                        min={widthType === "px" ? 40 : 1}
                                        max={widthType === "px" ? 500 : 100}
                                    />
                                </Col>
                                <Col sm={3}>
                                    <div className="d-flex d-inline-flex">
                                        <InputGroup>
                                            <FormControl
                                                value={newWidth}
                                                onChange={({
                                                    target: { value },
                                                }) => setNewWidth(value)}
                                            />
                                            <InputGroup.Append
                                                className="no-select cursor-pointer"
                                                onClick={() => {
                                                    setNewWidth(40);
                                                    setWidthType(
                                                        widthType === "px"
                                                            ? "%"
                                                            : "px"
                                                    );
                                                }}
                                            >
                                                <InputGroup.Text>
                                                    {widthType}
                                                </InputGroup.Text>
                                            </InputGroup.Append>
                                        </InputGroup>
                                    </div>
                                </Col>
                            </Row>
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="dark"
                        onClick={() =>
                            saveStyling({
                                style: {
                                    width: `${newWidth}${widthType}`,
                                },
                                key: selectedColumn,
                            })
                        }
                        size="sm"
                    >
                        Save
                    </Button>
                    <Button
                        variant="light"
                        onClick={handleCloseModal}
                        size="sm"
                    >
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
);
