/* eslint-disable react/display-name */
import "react-datepicker/dist/react-datepicker.css";

import { useEffect } from "react";
import { memo, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";

import { clientDateFormat } from "../../../config";

export const UpdateSystemStatusModal = memo(
    ({
        show = false,
        handleCloseModal,
        status = "Open",
        exchange,
        updateStatus,
    }) => {
        const [newStatus, setNewStatus] = useState("O");
        const [scheduleTime, setScheduleTime] = useState(new Date());
        const [disabledTime, setDisabledTime] = useState(false);

        useEffect(() => {
            try {
                if (status) {
                    const statusChar =
                        status === "Normal" ? "O" : status.charAt(0);
                    const option = document.querySelector(
                        `[value="${statusChar}"]`
                    );

                    option.selected = true;
                }
            } catch (err) {
                //
            }
        }, [status]);

        return (
            <Modal show={show} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Update Exchange Status</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row noGutters className="mx-3 my-1">
                        <Col>Status</Col>
                        <Col>
                            <select
                                className="custom-select"
                                onChange={(e) => setNewStatus(e.target.value)}
                            >
                                <option value="O">Open</option>
                                <option value="P">Pre-Open</option>
                                <option value="H">Halt</option>
                            </select>
                        </Col>
                    </Row>
                    <Row noGutters className="mx-3 my-1">
                        <Col>Schedule Time</Col>
                        <Col className="position-relative">
                            <Form.Check
                                type="checkbox"
                                className="scheduletime-checkbox"
                                onChange={({ target: { checked } }) =>
                                    setDisabledTime(checked)
                                }
                            />
                            <DatePicker
                                className="scheduletime-picker "
                                selected={scheduleTime}
                                onChange={(datetime) =>
                                    setScheduleTime(datetime)
                                }
                                timeInputLabel="Time:"
                                dateFormat={`${clientDateFormat} h:mm aa`}
                                showTimeInput
                                disabled={!disabledTime}
                                minDate={new Date()}
                            />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={() =>
                            updateStatus(
                                exchange,
                                newStatus,
                                disabledTime
                                    ? scheduleTime.getTime()
                                    : undefined
                            )
                        }
                    >
                        Update
                    </Button>
                    <Button variant="light" onClick={handleCloseModal}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
);
