import { Col, FormControl, Row } from "react-bootstrap";

export const ClientOrderId = ({
    orderTicket,
    tabKey,
    clientOrderId,
    setClientOrderId,
}) => {
    return (
        <Row className="d-flex order-ticket-flex-item pb-2 clientorderid-field">
            <Col xs={12}>
                <FormControl
                    value={clientOrderId}
                    size="sm"
                    placeholder="Custom Client Order ID"
                    onChange={(e) => setClientOrderId(e.target.value)}
                    type={
                        orderTicket[tabKey]?.clientOrderId?.fieldType ===
                        "Number"
                            ? "number"
                            : "text"
                    }
                />
            </Col>
        </Row>
    );
};
