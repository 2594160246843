import { useState } from "react";
import {
    Button,
    Col,
    Form,
    FormControl,
    Modal,
    OverlayTrigger,
    Row,
    Tooltip,
} from "react-bootstrap";
import { v4 as uuid } from "uuid";

import { tradeDestinations } from "../../../config";
import {
    getCurrency,
    getStylingHex,
    removeCommas,
} from "../../../helpers/other";
import { addOrder } from "../../../helpers/trade";
import { DefaultOrderTicketConfig } from "../../../preferences";
import { useStore } from "../../../Store";
import { useSocket } from "../../../Websocket";
import { DestinationField } from "../trade/order-ticket/DestinationField";
import { AdditionalFlagsForm } from "./order-ticket/AdditionalFlagsForm";
import { OrderTicketBasic } from "./order-ticket/OrderTicketBasic";
import { SymbolSelect } from "./SymbolSelect";

const DEFAULT_ORDER = {
    qty: "",
    price: "",
    side: undefined,
    ordertype: undefined,
    tif: undefined,
};

const correctOldHotKeyOrder = (
    order,
    capacityValue = undefined,
    layoutConfigurationOptions = {},
    books = [],
    currentPageSymbol = {}
) => {
    let matchingPrice = undefined;

    if (
        order?.priceContext &&
        order?.side &&
        (currentPageSymbol?.security === order?.security || !order?.security)
    ) {
        matchingPrice =
            order?.side === "B"
                ? books.filter((x) => x.side === "S").slice(-1)[0]?.price
                : books.filter((x) => x.side === "B")[0]?.price;
    }

    const mapFieldArr = (arr) =>
        arr &&
        Object.assign(
            {},
            ...arr.map((fld) => ({
                [fld?.name || fld?.fieldName]:
                    fld?.value || fld?.fieldValue || fld?.isChecked,
            }))
        );

    return {
        ...order,
        // Remove old saved hotkey order keys that are unneeded and shouldn't be
        // in our websocket message.
        additionalTifFields: undefined,
        additionalTypeFields: undefined,
        modalFields: undefined,
        additionalFlagsAdditionalFieldsKeyValueMap: undefined,
        // Remove old unnecessary keys
        firm: undefined,
        key: undefined,
        // Remove saved commas from old hotkey orders
        price: order?.priceContext
            ? matchingPrice
            : order?.price
            ? removeCommas(order?.price)
            : undefined,
        qty: order?.qty ? removeCommas(order?.qty) : undefined,
        // Remap the old field keys
        ...mapFieldArr(order?.additionalTifFields),
        ...mapFieldArr(order?.additionalTypeFields),
        ...mapFieldArr(order?.modalFields),
        ...order?.additionalFlagsAdditionalFieldsKeyValueMap,
        // Correct old hotkey orders to default to Today
        effective_day:
            !order?.effective_day && order?.effective_time
                ? "today"
                : order?.effective_day,
        exptime: order?.exptime ? new Date(order.exptime).getTime() : undefined,
        capacity: capacityValue,
        backgroundColor:
            order.backgroundColor ||
            getStylingHex(
                order.side === "S"
                    ? layoutConfigurationOptions?.styling?.button
                          ?.secondaryBGColor
                    : layoutConfigurationOptions?.styling?.button
                          ?.primaryBGColor
            ),
        textColor:
            order.textColor ||
            getStylingHex(
                order.side === "S"
                    ? layoutConfigurationOptions?.styling?.button
                          ?.secondaryFontColor
                    : layoutConfigurationOptions?.styling?.button
                          ?.primaryFontColor
            ),
        priceContext: order.priceContext || false,
    };
};

export const HotKeyModal = ({
    show,
    handleClose,
    savedHotKeyOrder,
    isCreate,
    saveNewHotKeyOrder,
    updateExistingHotKeyOrder,
    deleteExistingHotKeyOrder,
}) => {
    const ws = useSocket();
    const { state } = useStore();
    const {
        symbols,
        positions,
        books: currentBooks,
        layoutConfigurationOptions,
        currentSymbol: currentPageSymbol,
    } = state.trade;

    const orderTicketConfig =
        state.account.account.data.config_setting?.attr?.order_ticket ||
        DefaultOrderTicketConfig;

    const findCapacityValue = (key) =>
        orderTicketConfig?.[key]?.capacity?.display
            ? orderTicketConfig?.[key]?.capacity?.options.find(
                  (capacity) => capacity?.default
              )?.value
            : undefined;

    const [tabKey, setTabKey] = useState(
        savedHotKeyOrder?.tab || Object.entries(orderTicketConfig)[0][0]
    );

    const capacityValue = findCapacityValue(tabKey);
    const books = currentBooks[savedHotKeyOrder?.security] || [];

    // || DEFAULT_ORDER never triggers
    // We ddin't have correctOldHotKeyOrder before to settle all the issues
    const [currentOrder, setCurrentOrder] = useState(
        correctOldHotKeyOrder(
            savedHotKeyOrder,
            capacityValue,
            layoutConfigurationOptions,
            books,
            currentPageSymbol
        ) || DEFAULT_ORDER
    );

    const currentSymbol = symbols.find(
        (symb) => symb.security === currentOrder?.security
    );
    const [security, currency] = getCurrency(
        currentSymbol,
        currentOrder?.security,
        positions
    );

    const onChangeCurrentOrder = (key, value, key2, value2, additionalFields) =>
        setCurrentOrder({
            ...currentOrder,
            [key]: value,
            [key2]: value2,
            ...additionalFields?.reduce(
                (obj, fld) =>
                    Object.assign(obj, {
                        [fld.fieldName]: undefined,
                        [fld?.bindedField]: undefined,
                    }),
                {}
            ),
        });

    const submitHotKeyOrder = (currentOrder) => {
        const getSettingFieldKeyValue = (key) =>
            orderTicketConfig[key]?.setting
                ? {
                      [orderTicketConfig[key].setting.fieldName]:
                          orderTicketConfig[key]?.setting?.fieldValue,
                  }
                : {};

        const getEffectiveTime = (date, time) => {
            const timeOfDay = new Date(time);

            return new Date(
                new Date().setDate(
                    new Date().getDate() + (date === "tomorrow" ? 1 : 0)
                )
            ).setHours(
                timeOfDay.getHours(),
                timeOfDay.getMinutes(),
                timeOfDay.getSeconds(),
                timeOfDay.getMilliseconds()
            );
        };

        const formattedOrder = {
            ...currentOrder,
            ...getSettingFieldKeyValue(tabKey),
            effective_time: getEffectiveTime(
                currentOrder?.effective_day,
                currentOrder?.effective_time
            ),
            effective_day: undefined,
            exptime: getEffectiveTime(
                currentOrder?.exp_day,
                currentOrder?.exptime
            ),
            clientOrderId: undefined,
            clientorderid: currentOrder?.clientOrderId,
            exp_day: undefined,
            firm: state?.account?.account?.data?.firm,
            tab: undefined,
            id: undefined,
        };

        // Send order to backend
        addOrder(ws, formattedOrder);

        handleClose();
    };

    const createHotKeyOrder = (currentOrder) => {
        const identifier = uuid();

        // Save new hotkey order
        saveNewHotKeyOrder({ ...currentOrder, id: identifier });
        handleClose();
    };

    const updateHotKeyOrder = (currentOrder) => {
        // Update existing saved hotkey order
        updateExistingHotKeyOrder({
            ...JSON.parse(JSON.stringify(currentOrder)),
            id: savedHotKeyOrder.id,
        });
        handleClose();
    };

    const removeHotKeyOrder = (currentOrder) => {
        // Remove saved hotkey order
        deleteExistingHotKeyOrder(currentOrder);
        handleClose();
    };

    const renderTooltip = (props) => (
        <Tooltip {...props}>
            Price context will auto match the price for your buy and sell order.
            Price will only be set if you are on the saved symbol or have an
            unset symbol.
        </Tooltip>
    );

    const isSubmitDisabled =
        !currentOrder.side ||
        !currentOrder.qty ||
        !currentOrder.price ||
        (!orderTicketConfig[tabKey]?.clientOrderId?.optional &&
            !currentOrder.clientOrderId) ||
        (orderTicketConfig[tabKey]?.brokers?.display && !currentOrder.brokers);

    return (
        <Modal
            show={show}
            onHide={handleClose}
            size="lg"
            className="trade-confirm-modal hotkey-modal"
        >
            <Modal.Header>
                <Modal.Title>Create Hot Key</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row noGutters className="mb-2">
                    <Col xs={3}>Title: </Col>
                    <Col xs={9}>
                        <FormControl
                            value={currentOrder?.title || ""}
                            onChange={(e) =>
                                onChangeCurrentOrder("title", e.target.value)
                            }
                            type="text"
                            size="sm"
                        />
                    </Col>
                </Row>
                <Row className="mb-2 py-1" noGutters>
                    <Col>Button Background</Col>
                    <Col>
                        <Form.Control
                            type="color"
                            size="sm"
                            name="backgroundColor"
                            onChange={({ target: { name, value } }) =>
                                onChangeCurrentOrder(name, value)
                            }
                            value={getStylingHex(currentOrder?.backgroundColor)}
                        />
                    </Col>
                </Row>
                <Row className="mb-2 py-1" noGutters>
                    <Col>Button Text</Col>
                    <Col>
                        <Form.Control
                            type="color"
                            size="sm"
                            name="textColor"
                            onChange={({ target: { name, value } }) =>
                                onChangeCurrentOrder(name, value)
                            }
                            value={getStylingHex(
                                getStylingHex(currentOrder?.textColor)
                            )}
                        />
                    </Col>
                </Row>
                <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip}
                >
                    <Row className="mb-2 py-1" noGutters>
                        <Col>Price Context </Col>
                        <Col>
                            <Form.Check
                                type="checkbox"
                                size="md"
                                name="priceContext"
                                onChange={({ target: { name, checked } }) =>
                                    onChangeCurrentOrder(name, checked)
                                }
                                checked={currentOrder.priceContext}
                            />
                        </Col>
                    </Row>
                </OverlayTrigger>
                <Row className="mb-2 order-ticket-info-row" noGutters>
                    <Col className="d-flex flex-column">
                        <span>{security?.type}</span>
                        <span>{security?.value}</span>
                    </Col>
                    <Col className="d-flex flex-column">
                        <span>{currency?.type}</span>
                        <span>{currency?.value}</span>
                    </Col>
                    <Col>
                        <SymbolSelect
                            isHotKey
                            selectedSymbol={currentOrder}
                            onChange={(val) =>
                                onChangeCurrentOrder("security", val.value)
                            }
                        />
                    </Col>
                </Row>
                <OrderTicketBasic
                    tabKey={tabKey}
                    setTabKey={setTabKey}
                    currentOrder={currentOrder}
                    onChangeCurrentOrder={onChangeCurrentOrder}
                    currentSymbol={currentSymbol}
                    resetOrder={(newKey) =>
                        setCurrentOrder({
                            ...DEFAULT_ORDER,
                            title: currentOrder?.title,
                            security: currentOrder?.security,
                            tab: newKey,
                        })
                    }
                    currency={currency}
                    security={security}
                >
                    {orderTicketConfig[tabKey]?.additionalFlags?.fieldType ===
                        "Modal" &&
                        orderTicketConfig[tabKey]?.additionalFlags?.display && (
                            <AdditionalFlagsForm
                                additionalFlags={
                                    orderTicketConfig[tabKey]?.additionalFlags
                                        ?.modalFields
                                }
                                currentOrder={currentOrder}
                                onChange={(
                                    key,
                                    val,
                                    key2,
                                    val2,
                                    additionalFields
                                ) =>
                                    onChangeCurrentOrder(
                                        key,
                                        val || undefined,
                                        key2,
                                        val2 || undefined,
                                        additionalFields
                                    )
                                }
                                isHotKey
                            />
                        )}
                    {!(
                        // IF do not show additional flags AND we have dest
                        // This is because additional flags already have dest
                        (
                            orderTicketConfig[tabKey]?.additionalFlags
                                ?.fieldType === "Modal" &&
                            orderTicketConfig[tabKey]?.additionalFlags?.display
                        )
                    ) &&
                        tradeDestinations?.length > 0 && (
                            <DestinationField
                                destination={currentOrder?.["dest"]}
                                setDestination={(val) =>
                                    onChangeCurrentOrder("dest", val)
                                }
                            />
                        )}
                </OrderTicketBasic>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="primary"
                    size="sm"
                    onClick={() =>
                        isCreate
                            ? createHotKeyOrder(currentOrder)
                            : updateHotKeyOrder(currentOrder)
                    }
                    disabled={!currentOrder?.title}
                >
                    {isCreate ? "Save" : "Update"}
                </Button>
                <Button
                    variant="primary"
                    size="sm"
                    onClick={() => submitHotKeyOrder(currentOrder)}
                    disabled={isSubmitDisabled}
                >
                    Submit
                </Button>
                {!isCreate && (
                    <Button
                        variant="danger"
                        size="sm"
                        onClick={() => removeHotKeyOrder(currentOrder)}
                    >
                        Remove
                    </Button>
                )}
                <Button variant="light" onClick={handleClose} size="sm">
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
