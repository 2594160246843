import { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

import { EditAccountModal } from "../../../components/admin/onboarding/EditAccountModal";
import { TableRenderer } from "../../../components/portal/TableRenderer";
import { getFirms } from "../../../helpers/firm";
import { addAccount, queryExistingAccounts } from "../../../helpers/onboarding";
import { formatTableColumn } from "../../../helpers/other";
import { useStore } from "../../../Store";
import { useSocket } from "../../../Websocket";

const ManageAccountsPage = () => {
    const { state, dispatch } = useStore();
    const ws = useSocket();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    const {
        firm: { firms },
        onboarding: { accounts },
    } = state;

    const [showEditAccountModal, setShowEditAccountModal] = useState(false);
    const handleShowEditAccountModal = () => {
        setShowEditAccountModal(true);
    };
    const handleHideEditAccountModal = () => {
        setShowEditAccountModal(false);
    };

    const handleAddAccount = (account, isDelete) => {
        addAccount(
            ws,
            isDelete ? { ...account, isdelete: isDelete } : account
        ).then(() => {
            queryExistingAccounts(ws, dispatch);
            handleHideEditAccountModal();
        });
    };

    useEffect(() => {
        if (accounts.length === 0) {
            queryExistingAccounts(ws, dispatch);
        }

        if (firms.length === 0) {
            getFirms(ws, dispatch).finally(() => setLoading(false));
        } else {
            setLoading(false);
        }

        return () => setLoading(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="m-4">
            <Row noGutters className="forms-header justify-content-between">
                <Col>
                    <h4>Manage Accounts</h4>
                </Col>
                <Col>
                    <Button
                        variant="outline-dark"
                        className="add-user-button"
                        onClick={() => {
                            handleShowEditAccountModal();
                        }}
                        size="sm"
                    >
                        Add Account
                    </Button>
                </Col>
            </Row>
            {showEditAccountModal && (
                <EditAccountModal
                    show={showEditAccountModal}
                    handleClose={handleHideEditAccountModal}
                    handleSubmit={handleAddAccount}
                    firms={firms}
                />
            )}
            <TableRenderer
                data={accounts}
                wrapperClasses="size-sm"
                columns={[
                    ...["account", "firm"].map((col) => formatTableColumn(col)),
                    {
                        dataField: "users",
                        isDummyField: true,
                        text: "Linked Users",
                        formatter: (cell, { account, restricted_attr }) => (
                            <div className="d-flex justify-content-between align-items-center">
                                <span>
                                    {restricted_attr?.access_list?.length || 0}
                                </span>
                                <AiOutlineEdit
                                    onClick={() =>
                                        navigate(
                                            `/admin/manage-accounts/${account}`
                                        )
                                    }
                                    className="mt-1 cursor-pointer text-success"
                                />
                            </div>
                        ),
                    },
                    {
                        dataField: "action",
                        isDummyField: true,
                        text: "",
                        formatter: (cell, { account, firm }) => (
                            <AiOutlineDelete
                                className="cursor-pointer text-danger"
                                onClick={() =>
                                    handleAddAccount({ account, firm }, true)
                                }
                            />
                        ),
                    },
                ]}
                loading={loading}
                keyField="account"
                pagination
                striped
            />
        </div>
    );
};

export default ManageAccountsPage;
