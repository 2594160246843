import { getNames } from "country-list";
import { cloneDeep } from "lodash";
import { Row } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";

export const LocationInput = (props) => {
    const { field, onChange, classes, disabled } = props;
    const { required, value } = field;

    const countryList = getNames();

    const otherProps = cloneDeep(props);

    delete otherProps.onChange;
    delete otherProps.field;
    delete otherProps.countryList;

    return (
        <Row className={classes} noGutters style={{ position: "relative" }}>
            <Typeahead
                id="country"
                labelKey="country_list"
                onChange={(value) => onChange(value[0])}
                options={countryList}
                size="sm"
                style={{ width: "100%" }}
                disabled={disabled}
                defaultSelected={[value || ""]}
                {...otherProps}
                // isInvalid={required && (!value || value?.length === 0)}
            />
            {required && <span className="required-icon">*</span>}
        </Row>
    );
};
