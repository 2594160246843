import { Fragment, memo } from "react";
import { Col, Form, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";

import {
    activeMarketTypes,
    clientDateFormat,
    clientTimeFormat,
    isMarkerAsMarketMaker,
} from "../../../../config";
import { addCommas, removeCommas } from "../../../../helpers/other";
import { validateNoFloatsAllSigns } from "../../../../helpers/validate";
import { useStore } from "../../../../Store";
import { DestinationField } from "./DestinationField";

const FieldFlagFlexElement = ({ fieldFlag, index, renderFlag }) => (
    <Row
        key={index}
        className={`more-options order-ticket-flex-item d-flex pb-2 ${fieldFlag.fieldName.toLowerCase()}-field`}
    >
        <Col
            xs={
                fieldFlag?.displayName?.length > 20 &&
                fieldFlag?.fieldType !== "Checkbox"
                    ? null
                    : 4
            }
            style={{ whiteSpace: "nowrap" }}
        >
            {/* If isMarkerAsMarketMaker and fieldFlag.fieldName === "ismarker" Rename checkbox
            ELSE use the default displayName or fieldName */}
            {isMarkerAsMarketMaker && fieldFlag.fieldName === "ismarker"
                ? "Is Market Maker"
                : fieldFlag?.displayName || fieldFlag.fieldName}{" "}
            :
        </Col>
        <Col
            xs={
                fieldFlag?.displayName?.length > 20 &&
                fieldFlag?.fieldType !== "Checkbox"
                    ? null
                    : 8
            }
        >
            {renderFlag(fieldFlag)}
        </Col>
    </Row>
);

const DropdownFlagFlexElement = ({
    field,
    index,
    selectedField,
    renderFlag,
}) => (
    <>
        <FieldFlagFlexElement
            fieldFlag={field}
            index={index}
            renderFlag={renderFlag}
        />
        {selectedField?.additionalFields &&
            selectedField?.additionalFields.map((fld, j) => (
                <Fragment key={j}>
                    <FieldFlagFlexElement
                        index={j}
                        fieldFlag={fld}
                        renderFlag={renderFlag}
                    />
                </Fragment>
            ))}
    </>
);

const DatePickerField = ({
    flag,
    firstValue,
    secondValue,
    onChange,
    isHotKey,
}) => {
    const hotKeyProps = {
        showTimeInput: false,
        showTimeSelect: true,
        showTimeSelectOnly: true,
        timeIntervals: 1,
        timeCaption: "Time",
    };

    return (
        <div className="d-inline-flex w-100 ">
            {isHotKey && (
                <>
                    <Form.Control
                        as="select"
                        className="w-50 mr-1"
                        size="sm"
                        isInvalid={firstValue && !secondValue}
                        onChange={({ target: { value } }) =>
                            onChange(
                                flag.bindedField,
                                value,
                                flag.fieldName,
                                !value
                                    ? undefined
                                    : firstValue ?? new Date().getTime()
                            )
                        }
                        value={secondValue}
                    >
                        <option value="">None</option>
                        <option value="today">Today</option>
                        <option value="tomorrow">Tomorrow</option>
                    </Form.Control>
                    <div className="size-sm mt-1">at</div>
                </>
            )}
            <DatePicker
                wrapperClassName={` ${isHotKey ? "w-50 ml-1" : ""}`}
                className="custom-time-input "
                showTimeInput
                selected={firstValue ? new Date(firstValue) : null}
                onChange={(date) =>
                    onChange(
                        flag.fieldName,
                        new Date(date).getTime(),
                        flag.bindedField,
                        isHotKey && !secondValue ? "today" : secondValue
                    )
                }
                dateFormat={
                    isHotKey
                        ? "hh:mm aa"
                        : `${clientDateFormat} ${clientTimeFormat}`
                }
                {...(isHotKey ? hotKeyProps : {})}
            />
        </div>
    );
};

// eslint-disable-next-line react/display-name
export const AdditionalFlagsForm = memo(
    ({ currentOrder, additionalFlags, isHotKey, onChange, layoutKey }) => {
        const { state } = useStore();
        const { layoutConfigurationOptions } = state.trade;

        const isShowAll =
            Array.isArray(activeMarketTypes) &&
            activeMarketTypes[0] === "ShowAll";

        const showCurrentFlag = (flag) => {
            if (isShowAll || flag["alwaysOn"]) return true;

            return activeMarketTypes.some((item) => {
                return flag[item];
            });
        };

        const renderFlag = (flag) => {
            switch (flag.fieldType) {
                case "Checkbox":
                    return (
                        <Form.Check
                            className="text-right pr-1 pb-1"
                            type="checkbox"
                            name={flag.fieldName}
                            onChange={({ target: { checked } }) =>
                                onChange(
                                    flag.fieldName,
                                    checked,
                                    undefined,
                                    undefined,
                                    flag?.additionalFields
                                )
                            }
                            checked={!!currentOrder?.[flag.fieldName]}
                        />
                    );
                case "Date": {
                    return (
                        <DatePickerField
                            isHotKey={isHotKey}
                            flag={flag}
                            firstValue={currentOrder[flag.fieldName]}
                            secondValue={currentOrder[flag.bindedField]}
                            onChange={onChange}
                        />
                    );
                }
                case "Number":
                    return (
                        <div className="d-inline-flex w-100">
                            {flag.fieldName === "interval" && (
                                <div className="mr-2 size-sm">Every</div>
                            )}
                            <Form.Control
                                onKeyDown={validateNoFloatsAllSigns}
                                value={
                                    currentOrder?.[flag.fieldName]
                                        ? addCommas(
                                              currentOrder[flag.fieldName] /
                                                  (flag.fieldName === "interval"
                                                      ? 60
                                                      : 1)
                                          )
                                        : ""
                                }
                                onChange={(e) =>
                                    onChange(
                                        flag.fieldName,
                                        parseInt(removeCommas(e.target.value)) *
                                            (flag.fieldName === "interval"
                                                ? 60
                                                : 1),
                                        undefined,
                                        undefined,
                                        flag?.additionalFields
                                    )
                                }
                                size="sm"
                            />
                            {flag.fieldName === "interval" && (
                                <div className="ml-2 size-sm">Minute(s)</div>
                            )}
                        </div>
                    );
                case "Dropdown":
                    return (
                        <>
                            <Form.Control
                                as="select"
                                size="sm"
                                onChange={(e) => {
                                    onChange(
                                        flag.fieldName,
                                        e.target.value,
                                        !!e.target.value &&
                                            flag.fieldName === "strategy"
                                            ? "tif"
                                            : undefined,
                                        !!e.target.value &&
                                            flag.fieldName === "strategy"
                                            ? "GTT"
                                            : undefined,
                                        (flag?.additionalFields || []).concat(
                                            flag.options.find(
                                                (opt) =>
                                                    opt.fieldValue ===
                                                    currentOrder?.[
                                                        flag.fieldName
                                                    ]
                                            )?.additionalFields || []
                                        )
                                    );
                                }}
                                value={currentOrder?.[flag.fieldName]}
                            >
                                <option value="">None</option>
                                {flag.options.map((opt, i) => {
                                    return (
                                        <option key={i} value={opt?.fieldValue}>
                                            {opt?.displayName ||
                                                opt?.fieldValue}
                                        </option>
                                    );
                                })}
                            </Form.Control>
                        </>
                    );
                default:
                    return (
                        <Form.Control
                            value={currentOrder?.[flag.fieldName] || ""}
                            onChange={(e) =>
                                onChange(flag.fieldName, e.target.value)
                            }
                            size="sm"
                        />
                    );
            }
        };

        return (
            <>
                {additionalFlags
                    .filter((flag) => showCurrentFlag(flag))
                    .map((flag, i) => (
                        <Fragment key={i}>
                            {flag.fieldType === "Dropdown" ? (
                                <DropdownFlagFlexElement
                                    field={flag}
                                    index={i}
                                    renderFlag={renderFlag}
                                    selectedField={flag?.options.find(
                                        (opt) =>
                                            opt.fieldValue ===
                                            currentOrder[flag.fieldName]
                                    )}
                                />
                            ) : currentOrder?.strategy && // Hide effective time if straegy is
                              flag.fieldName === "effective_time" ? (
                                <></>
                            ) : (
                                <FieldFlagFlexElement
                                    fieldFlag={flag}
                                    index={i}
                                    renderFlag={renderFlag}
                                />
                            )}
                            {currentOrder[flag.fieldName] &&
                                flag?.additionalFields &&
                                flag?.additionalFields.map((field, j) => (
                                    <Fragment key={j}>
                                        <FieldFlagFlexElement
                                            fieldFlag={field}
                                            index={j}
                                            renderFlag={renderFlag}
                                        />
                                    </Fragment>
                                ))}

                            {/* {flag.fieldType === "Dropdown" &&
                                flag.options
                                    .find(
                                        (opt) =>
                                            opt.fieldValue ===
                                            currentOrder[flag.fieldName]
                                    )
                                    ?.additionalFields.map((field, k) => (
                                        <Fragment key={k}>
                                            <FieldFlagFlexElement
                                                fieldFlag={field}
                                                index={k}
                                                renderFlag={renderFlag}
                                            />
                                        </Fragment>
                                    ))} */}
                        </Fragment>
                    ))}
                {!layoutConfigurationOptions?.destinationFieldOnMain[
                    layoutKey
                ] && (
                    <DestinationField
                        destination={currentOrder?.dest}
                        setDestination={(destination) =>
                            onChange("dest", destination)
                        }
                    />
                )}
            </>
        );
    }
);
