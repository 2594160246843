import { Button, Modal } from "react-bootstrap";

export const DeleteProfileModal = ({
    show = false,
    handleDelete,
    profile,
    handleClose,
}) => (
    <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
            <Modal.Title>Delete Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            Are you sure you want to delete this profile? <br />
            <div className="text-center">&quot;{profile?.name}&quot;</div>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="danger" onClick={handleDelete}>
                Delete
            </Button>
            <Button variant="light" onClick={handleClose}>
                Close
            </Button>
        </Modal.Footer>
    </Modal>
);
