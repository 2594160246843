import { useState } from "react";
import {
    Button,
    Card,
    Col,
    Container,
    FormControl,
    Image,
    Row,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

import { alt_full_logo, full_logo } from "../config";
import { forgotPassword } from "../helpers/login";

const ForgotPassword = () => {
    const navigate = useNavigate();

    const [email, setEmail] = useState("");

    const handleSendResetMail = () => {
        forgotPassword(email).then(() => navigate("/login"));
    };

    return (
        <Container fluid className="login-container">
            <Row className="login-row g-0">
                <Col sm={8} md={6} lg={4} xl={4} className="login-col">
                    <Card className="login-box">
                        <Row className="g-0">
                            <Col className="login-content-col">
                                <Row className="login-header g-0">
                                    <Link to={"/"}>
                                        <Image
                                            fluid
                                            src={alt_full_logo}
                                            onError={(img) => {
                                                img.target.onerror = null;
                                                img.target.src = full_logo;
                                            }}
                                            className="login-logo"
                                        />
                                    </Link>
                                </Row>

                                <Row className="login-box-padding login-formcontrol-row g-0">
                                    <Col className="login-box-col">
                                        <h3>Forgot your password?</h3>
                                        <br />
                                        <Row className="login-formcontrol-container g-0">
                                            <div className="login-formcontrol-box">
                                                <div className="login-formcontrol-text">
                                                    Email
                                                </div>
                                                <FormControl
                                                    size="sm"
                                                    name={"email"}
                                                    value={email || ""}
                                                    onChange={({
                                                        target: { value },
                                                    }) => setEmail(value)}
                                                    className="login-formcontrol"
                                                />
                                            </div>
                                        </Row>

                                        <Row className="login-button-row g-0">
                                            <Button
                                                size="sm"
                                                onClick={handleSendResetMail}
                                                className="teal-button"
                                                disabled={!email}
                                            >
                                                Send Reset Email
                                            </Button>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default ForgotPassword;
