import { debounce } from "lodash";
import { useState } from "react";

import { customTradeEnabled } from "../../../config";
import { initialTradeState } from "../../../contexts/reducers/trade";
import { storeOptions } from "../../../helpers/display-options";
import { useStore } from "../../../Store";
import { useSocket } from "../../../Websocket";
import { SidebarNoPathItem, SidebarWrappper } from "../../SidebarWrapper";
import { StylingModal } from "./StylingModal";

export const TradeScreenConfigureSidebar = () => {
    const ws = useSocket();
    const { state, dispatch } = useStore();

    const keyType = "trade_preferences";
    const optionKey = "trade_screen_configuration";

    const { layoutConfigurationOptions } = state.trade;
    // const isPresetLayout =
    //     layoutConfigurationOptions?.layoutSelected?.startsWith("preset");
    const addComponentToLayout = debounce(
        (name) => {
            const componentToAdd =
                layoutConfigurationOptions.layout.findLast((elem) =>
                    elem.i.startsWith(name)
                ) ??
                initialTradeState.layoutConfigurationOptions.layout.find(
                    (elem) => elem.i.startsWith(name)
                );

            const previousComponentIndex = parseInt(
                Array.from(...componentToAdd.i.matchAll(/-([0-9+])$/gm))[1]
            );

            storeOptions(
                ws,
                dispatch,
                state.account.account?.data,
                keyType,
                optionKey,
                layoutConfigurationOptions.layout.concat([
                    {
                        ...componentToAdd,
                        i: `${name}-${previousComponentIndex + 1}`,
                    },
                ]),
                "layout"
            );
        },
        [500]
    );

    const [showStylngModal, setShowStylingModal] = useState(false);

    // const isComponentLimitReached = (key) => {
    //     let occurrences = 0;
    //     layout.forEach((item) =>
    //         item.i.startsWith(key) ? occurrences++ : item
    //     );

    //     return occurrences >= layoutConfigurationOptions.itemLimits[key];
    // };

    return (
        <>
            {showStylngModal && (
                <StylingModal
                    show={showStylngModal}
                    onClose={() => setShowStylingModal(false)}
                    updateLayoutConfig={(newConfig) =>
                        storeOptions(
                            ws,
                            dispatch,
                            state.account.account?.data,
                            keyType,
                            optionKey,
                            newConfig,
                            undefined,
                            true
                        )
                    }
                />
            )}
            <SidebarWrappper
                homePath="/portal"
                headerName="ADD"
                headerClass="text-center w-100"
                sidebarName="portal-sidebar"
                style={{
                    color: "var(--PortalSidebar-color)",
                    background: "var(--DarkModePortalComponent-background)",
                }}
                hideCollapse
                settingsIconEnabled
                settingsOnClick={() => setShowStylingModal(true)}
                settingsBottomLinkEnabled
                settingsBottomLink="/portal/trade"
                settingsBottomLinkLabel="Return"
            >
                <SidebarNoPathItem
                    name="Market Statistic"
                    onClick={() => addComponentToLayout("market-statistic")}
                    // disabled={isPresetLayout}
                    // disabled={isComponentLimitReached("market-statistic")}
                />
                <SidebarNoPathItem
                    name="HotKey Select"
                    onClick={() => addComponentToLayout("hotkey-select")}
                    // disabled={isPresetLayout}

                    // disabled={isComponentLimitReached("hotkey-select")}
                />
                <SidebarNoPathItem
                    name="Symbol Select"
                    onClick={() => addComponentToLayout("symbol-select")}
                    // disabled={isPresetLayout}

                    // disabled={isComponentLimitReached("symbol-select")}
                />
                <SidebarNoPathItem
                    name="Order Ticket"
                    onClick={() => addComponentToLayout("order-ticket")}
                    // disabled={isPresetLayout}

                    // disabled={isComponentLimitReached("order-ticket")}
                />
                <SidebarNoPathItem
                    name="Chart"
                    onClick={() => addComponentToLayout("trade-chart")}
                    // disabled={isPresetLayout}

                    // disabled={isComponentLimitReached("trade-chart")}
                />
                <SidebarNoPathItem
                    name="Books Table"
                    onClick={() => addComponentToLayout("books-table")}
                    // disabled={isPresetLayout}

                    // disabled={isComponentLimitReached("books-table")}
                />
                <SidebarNoPathItem
                    name="Orders Table"
                    onClick={() => addComponentToLayout("orders-table")}
                    // disabled={isPresetLayout}

                    // disabled={isComponentLimitReached("orders-table")}
                />
                {!customTradeEnabled && (
                    <SidebarNoPathItem
                        name="Market Watch"
                        onClick={() => addComponentToLayout("market-watch")}
                        // disabled={isPresetLayout}

                        // disabled={isComponentLimitReached("market-watch")}
                    />
                )}
            </SidebarWrappper>
        </>
    );
};
