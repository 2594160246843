import { Col, FormControl, Row } from "react-bootstrap";

import { custodians } from "../../../../config";

export const CustodianSelect = ({ custodian = "", setCustodian }) => {
    return (
        <Row className="d-flex pb-2 order-ticket-flex-item  ">
            <Col xs={4}>Custodian:</Col>
            <Col xs={8}>
                <FormControl
                    value={custodian || ""}
                    size="sm"
                    placeholder="Custodian"
                    onChange={(e) => setCustodian(e.target.value)}
                    as="select"
                >
                    <option value="">Select Custodian</option>
                    {custodians.map((opt, i) => (
                        <option key={i} value={opt}>
                            {opt}
                        </option>
                    ))}
                </FormControl>
            </Col>
        </Row>
    );
};
