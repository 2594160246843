import * as CONSTANTS from "../constants/resources";

export const initialResourcesState = {
    positions: [],
    posUser: "",
};

export const resourcesReducer = (state, action) => {
    switch (action.type) {
        case CONSTANTS.GET_POSITIONS:
            return {
                ...state,
                positions: action.positions,
                posUser: action.user,
            };
        default:
            return state;
    }
};
