import { AiOutlineLoading } from "react-icons/ai";

export const Loading = () => {
    return (
        <div className="loading-component">
            <AiOutlineLoading className="loading-icon" size={"2em"} />
        </div>
    );
};

export const FullScreenLoading = (loading) => {
    return (
        loading && (
            <div className="loading-screen">
                <AiOutlineLoading className="loading-icon" size={"2em"} />
            </div>
        )
    );
};

export const DimFullScreenLoading = () => (
    <div className="dim-loading-screen">
        <AiOutlineLoading className="loading-icon" size={"2em"} />
    </div>
);
