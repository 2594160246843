/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useState } from "react";
import {
    Button,
    Col,
    Collapse,
    Form,
    Modal,
    OverlayTrigger,
    Row,
    Tooltip,
} from "react-bootstrap";
import RangeSlider from "react-bootstrap-range-slider";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { toast } from "react-toastify";

import { enableExternalTrade } from "../../../config";
import * as ACCOUNT_ACTIONS from "../../../contexts/actions/account";
import {
    deleteUserFile,
    getFontFile,
    getStylingHex,
    isAdmin,
    uploadUserFile,
} from "../../../helpers/other";
import { storeNewPreferences } from "../../../helpers/settings";
import { useStore } from "../../../Store";
import { useSocket } from "../../../Websocket";

export const StylingModal = ({ show, onClose, updateLayoutConfig }) => {
    const ws = useSocket();
    const { dispatch, state } = useStore();
    const {
        account: user,
        trade: {
            layoutConfigurationOptions,
            defaultLayoutConfigurationOptions,
        },
    } = state;

    const fontURL = state.account.fontURL;

    const [currentConfig, setCurrentConfig] = useState({
        ...layoutConfigurationOptions,
    });
    const [expandButtonCollapse, setExpandButtonCollapse] = useState(false);
    const [expandBookGradientCollapse, setExpandBookGradientCollapse] =
        useState(false);
    const [expandTradeHistoryCollapse, setExpandTradeHistoryCollapse] =
        useState(false);
    const [expandNotificationsCollapse, setExpandNotificationsCollapse] =
        useState(false);
    // const [expandTabCollapse, setExpandTabCollapse] = useState(false);

    useEffect(() => {
        if (fontURL === null) {
            getFontFile(ws, dispatch, user?.account?.data?.userid);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (fontURL) {
            const fontFace = new FontFace("CustomFont", `url(${fontURL})`);
            fontFace
                .load()
                .then((loadedFace) => {
                    document.fonts.add(loadedFace);
                })
                .catch((error) => {
                    console.error("Font loading failed:", error);
                });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fontURL]);

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        // Fallback for file type detection
        const fileType = file.type || "font/ttf";

        if (file && fileType.includes("font")) {
            uploadUserFile(
                ws,
                dispatch,
                file,
                user?.account?.data?.userid,
                "CustomFontFile"
            )
                .then(() => {
                    getFontFile(ws, dispatch, user?.account?.data?.userid);
                })
                .catch(() => {
                    dispatch(ACCOUNT_ACTIONS.setFontURL(""));
                });
        } else {
            alert("Please upload a valid font file.");
        }
    };

    const handleDeleteFont = () => {
        deleteUserFile(ws, "CustomFontFile", user?.account?.data?.userid).then(
            () => {
                dispatch(ACCOUNT_ACTIONS.setFontURL(""));
            }
        );
    };

    const isUserAdmin = isAdmin(user?.account?.data?.user_type, user?.roles);

    const onChange = (name, value) =>
        setCurrentConfig({
            ...currentConfig,
            [name]: value,
        });

    const onChangeStyling = (name, value) =>
        setCurrentConfig({
            ...currentConfig,
            styling: {
                ...currentConfig.styling,
                [name]: value,
            },
        });

    const onChangeButtonStyling = (name, value) =>
        setCurrentConfig({
            ...currentConfig,
            styling: {
                ...currentConfig.styling,
                button: {
                    ...currentConfig.styling.button,
                    [name]: value,
                },
            },
        });

    const onChangeBookGradientStyling = (name, value) =>
        setCurrentConfig({
            ...currentConfig,
            styling: {
                ...currentConfig.styling,
                bookGradient: {
                    ...currentConfig.styling.bookGradient,
                    [name]: value,
                },
            },
        });

    const preferenceComponents = state.account.account.data.config_setting.attr;

    const saveAsFirmDefault = (layoutConfig) =>
        storeNewPreferences(
            ws,
            dispatch,
            layoutConfig,
            "trade_screen_configuration",
            preferenceComponents
        );

    const resetLayoutConfig = () => {
        updateLayoutConfig(defaultLayoutConfigurationOptions);
    };

    useEffect(
        () => setCurrentConfig(layoutConfigurationOptions),
        [layoutConfigurationOptions]
    );

    const renderTooltip = (props) => (
        <Tooltip {...props}>
            Uploaded font is account-specific and not linked to your firm
        </Tooltip>
    );

    return (
        <Modal
            show={show}
            onHide={onClose}
            className="trade-confirm-modal trade-configure-modal"
            size="md"
        >
            <Modal.Header closeButton>
                <Modal.Title>Styling</Modal.Title>
            </Modal.Header>
            <Modal.Body className="configure-style-modal size-sm">
                {/* {layoutConfigurationOptions?.pageSelected?.startsWith(
                    "preset"
                ) && (
                    <div className="order-ticket-overlay">
                        <div>
                            A preset layout is currently selected, styling can
                            not be changed.
                        </div>
                    </div>
                )} */}
                <div className="section-divider">
                    <hr />
                    <span>Sizing</span>
                </div>
                <div className="ml-3">
                    <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip}
                    >
                        <Row className="pb-1" noGutters>
                            <Col>Font :</Col>
                            <Col className="d-inline-flex">
                                {fontURL ? (
                                    <Button
                                        variant="danger"
                                        size="sm"
                                        className="w-100 mr-4"
                                        onClick={handleDeleteFont}
                                    >
                                        Delete Uploaded Font
                                    </Button>
                                ) : (
                                    <Form.File
                                        className="mr-4"
                                        custom
                                        label={"Upload Font"}
                                        type="file"
                                        accept=".ttf,.otf,.woff,.woff2"
                                        onChange={handleFileUpload}
                                    />
                                )}
                            </Col>
                        </Row>
                    </OverlayTrigger>

                    {fontURL && (
                        <Row>
                            <Col className="text-right mr-4">
                                {" "}
                                <p style={{ fontFamily: "CustomFont" }}>
                                    This is a preview of the current font
                                </p>
                            </Col>
                        </Row>
                    )}

                    <Row className="pb-1" noGutters>
                        <Col>Font Size :</Col>
                        <Col className="text-right d-inline-flex">
                            <Form.Control
                                as="select"
                                size="sm"
                                name="fontSize"
                                value={currentConfig?.fontSize || 2}
                                onChange={({ target: { name, value } }) =>
                                    onChange(name, parseFloat(value))
                                }
                                className="mr-4"
                            >
                                <option value={1.85}>Small</option>
                                <option value={2}>Medium</option>
                                <option value={2.25}>Large</option>
                            </Form.Control>
                        </Col>
                    </Row>
                    <Row className="pb-1" noGutters>
                        <Col>Row Sizing :</Col>
                        <Col className="text-right d-inline-flex">
                            <Form.Control
                                as="select"
                                size="sm"
                                name="rowSizing"
                                value={currentConfig?.rowSizing || "normal"}
                                onChange={({ target: { name, value } }) =>
                                    onChange(name, value)
                                }
                                className="mr-4"
                            >
                                <option value="normal">Normal</option>
                                <option value="compact">Compact</option>
                            </Form.Control>
                        </Col>
                    </Row>
                    <Row className="pb-1" noGutters>
                        <Col>Spacing :</Col>
                        <Col className="text-right d-inline-flex">
                            <RangeSlider
                                name="spacing"
                                size="sm"
                                value={
                                    currentConfig?.spacing
                                        ? currentConfig?.spacing[0]
                                        : 0
                                }
                                onChange={({ target: { name, value } }) =>
                                    onChange(name, [
                                        parseInt(value),
                                        parseInt(value),
                                    ])
                                }
                                tooltipLabel={(currentValue) =>
                                    `${currentValue}px`
                                }
                                variant="dark"
                                className="pr-1"
                                tooltipPlacement="top"
                                min={0}
                                max={16}
                            />
                            <div className="ml-2">px</div>
                        </Col>
                    </Row>
                    <Row className="py-1" noGutters>
                        <Col>Item Padding :</Col>
                        <Col className="text-right d-inline-flex">
                            <RangeSlider
                                name="padding"
                                size="sm"
                                value={currentConfig?.padding || 6}
                                onChange={({ target: { name, value } }) =>
                                    onChange(name, parseInt(value))
                                }
                                tooltipLabel={(currentValue) =>
                                    `${currentValue}px`
                                }
                                variant="dark"
                                className="pr-1"
                                min={6}
                                max={32}
                            />
                            <div className="ml-2">px</div>
                        </Col>
                    </Row>
                    {/* <Row className="py-1" noGutters>
                        <Col>Font Size :</Col>
                        <Col className="text-right d-inline-flex">
                            <RangeSlider
                                name="fontSize"
                                size="sm"
                                value={currentConfig?.fontSize || 6}
                                onChange={({ target: { name, value } }) =>
                                    onChange(name, parseInt(value))
                                }
                                tooltipLabel={(currentValue) =>
                                    `${currentValue}px`
                                }
                                variant="dark"
                                className="pr-1"
                                min={6}
                                max={32}
                            />
                            <div className="ml-2">px</div>
                        </Col>
                    </Row> */}
                </div>
                <div className="section-divider">
                    <hr />
                    <span>Coloring</span>
                </div>
                <div className="ml-3 mr-4">
                    <Row className="py-1" noGutters>
                        <Col>Primary Background :</Col>
                        <Col>
                            <Form.Control
                                type="color"
                                size="sm"
                                name="primaryBackgroundColor"
                                onChange={({ target: { name, value } }) =>
                                    onChangeStyling(name, value)
                                }
                                value={getStylingHex(
                                    currentConfig?.styling
                                        ?.primaryBackgroundColor
                                )}
                            />
                        </Col>
                    </Row>
                    <Row className="py-1" noGutters>
                        <Col>Item Header Font :</Col>
                        <Col>
                            <Form.Control
                                type="color"
                                size="sm"
                                name="headerFontColor"
                                onChange={({ target: { name, value } }) =>
                                    onChangeStyling(name, value)
                                }
                                value={getStylingHex(
                                    currentConfig?.styling?.headerFontColor
                                )}
                            />
                        </Col>
                    </Row>
                    <Row className="py-1" noGutters>
                        <Col>Item Background :</Col>
                        <Col>
                            <Form.Control
                                type="color"
                                size="sm"
                                name="backgroundColor"
                                onChange={({ target: { name, value } }) =>
                                    onChangeStyling(name, value)
                                }
                                value={getStylingHex(
                                    currentConfig?.styling?.backgroundColor
                                )}
                            />
                        </Col>
                    </Row>
                    <Row className="py-1" noGutters>
                        <Col>Item Font :</Col>
                        <Col>
                            <Form.Control
                                type="color"
                                size="sm"
                                name="fontColor"
                                onChange={({ target: { name, value } }) =>
                                    onChangeStyling(name, value)
                                }
                                value={getStylingHex(
                                    currentConfig?.styling?.fontColor
                                )}
                            />
                        </Col>
                    </Row>
                    <Row
                        className="d-flex justify-content-between my-1 mr-1"
                        noGutters
                    >
                        <div>Toggle Borders :</div>
                        <div>
                            <Form.Check
                                name="componentBorders"
                                checked={currentConfig?.componentBorders}
                                onChange={({ target: { name, checked } }) =>
                                    onChange(name, checked)
                                }
                            />
                        </div>
                    </Row>
                    <div
                        onClick={() =>
                            setExpandButtonCollapse(!expandButtonCollapse)
                        }
                        className="pb-1 mb-1 -ml-1 cursor-pointer d-flex justify-content-between"
                        style={{
                            borderBottom: "1px solid white",
                            margin: "0 -0.75rem 0 -0.75rem",
                        }}
                    >
                        <div>
                            <b>Buttons</b>
                        </div>
                        <div>
                            {!expandButtonCollapse ? (
                                <IoIosArrowDown />
                            ) : (
                                <IoIosArrowUp />
                            )}
                        </div>
                    </div>

                    <Collapse in={expandButtonCollapse} className="size-sm">
                        <div>
                            <Row className="py-1" noGutters>
                                <Col>Primary Background :</Col>
                                <Col>
                                    <Form.Control
                                        type="color"
                                        size="sm"
                                        name="primaryBGColor"
                                        onChange={({
                                            target: { name, value },
                                        }) =>
                                            onChangeButtonStyling(name, value)
                                        }
                                        value={getStylingHex(
                                            currentConfig?.styling?.button
                                                ?.primaryBGColor
                                        )}
                                    />
                                </Col>
                            </Row>
                            <Row className="py-1" noGutters>
                                <Col>Primary Font :</Col>
                                <Col>
                                    <Form.Control
                                        type="color"
                                        size="sm"
                                        name="primaryFontColor"
                                        onChange={({
                                            target: { name, value },
                                        }) =>
                                            onChangeButtonStyling(name, value)
                                        }
                                        value={getStylingHex(
                                            currentConfig?.styling?.button
                                                ?.primaryFontColor
                                        )}
                                    />
                                </Col>
                            </Row>
                            <Row className="py-1" noGutters>
                                <Col>Secondary Background :</Col>
                                <Col>
                                    <Form.Control
                                        type="color"
                                        size="sm"
                                        name="secondaryBGColor"
                                        onChange={({
                                            target: { name, value },
                                        }) =>
                                            onChangeButtonStyling(name, value)
                                        }
                                        value={getStylingHex(
                                            currentConfig?.styling?.button
                                                ?.secondaryBGColor
                                        )}
                                    />
                                </Col>
                            </Row>
                            <Row className="py-1" noGutters>
                                <Col>Secondary Font :</Col>
                                <Col>
                                    <Form.Control
                                        type="color"
                                        size="sm"
                                        name="secondaryFontColor"
                                        onChange={({
                                            target: { name, value },
                                        }) =>
                                            onChangeButtonStyling(name, value)
                                        }
                                        value={getStylingHex(
                                            currentConfig?.styling?.button
                                                ?.secondaryFontColor
                                        )}
                                    />
                                </Col>
                            </Row>
                            <Row className="py-1" noGutters>
                                <Col>Cancel Background :</Col>
                                <Col>
                                    <Form.Control
                                        type="color"
                                        size="sm"
                                        name="cancelBGColor"
                                        onChange={({
                                            target: { name, value },
                                        }) =>
                                            onChangeButtonStyling(name, value)
                                        }
                                        value={getStylingHex(
                                            currentConfig?.styling?.button
                                                ?.cancelBGColor
                                        )}
                                    />
                                </Col>
                            </Row>
                            <Row className="py-1" noGutters>
                                <Col>Cancel Font :</Col>
                                <Col>
                                    <Form.Control
                                        type="color"
                                        size="sm"
                                        name="cancelFontColor"
                                        onChange={({
                                            target: { name, value },
                                        }) =>
                                            onChangeButtonStyling(name, value)
                                        }
                                        value={getStylingHex(
                                            currentConfig?.styling?.button
                                                ?.cancelFontColor
                                        )}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Collapse>
                    {/* <div
                        onClick={() => setExpandTabCollapse(!expandTabCollapse)}
                        className="pb-1 mb-1 -ml-1 cursor-pointer d-flex justify-content-between"
                        style={{
                            borderBottom: "1px solid white",
                            margin: "0 -0.75rem 0 -0.75rem",
                        }}
                    >
                        <div>
                            <b>Tabs</b>
                        </div>
                        <div>
                            {!expandTabCollapse ? (
                                <IoIosArrowDown />
                            ) : (
                                <IoIosArrowUp />
                            )}
                        </div>
                    </div> */}
                    <div
                        onClick={() =>
                            setExpandBookGradientCollapse(
                                !expandBookGradientCollapse
                            )
                        }
                        className="pb-1 mb-1 -ml-1 cursor-pointer d-flex justify-content-between"
                        style={{
                            borderBottom: "1px solid white",
                            margin: "0 -0.75rem 0 -0.75rem",
                        }}
                    >
                        <div>
                            <b>Order Book Gradient</b>
                        </div>
                        <div>
                            {!expandBookGradientCollapse ? (
                                <IoIosArrowDown />
                            ) : (
                                <IoIosArrowUp />
                            )}
                        </div>
                    </div>

                    <Collapse
                        in={expandBookGradientCollapse}
                        className="size-sm"
                    >
                        <div>
                            <div className="mr-0 py-2">
                                <Row className="d-flex justify-content-between ml-0 mr-0">
                                    <div>Enable Order Book Gradient :</div>
                                    <div>
                                        <Form.Check
                                            name="bookGradientActive"
                                            checked={
                                                currentConfig?.styling
                                                    ?.bookGradient
                                                    ?.bookGradientActive ||
                                                false
                                            }
                                            onChange={({
                                                target: { name, checked },
                                            }) =>
                                                onChangeBookGradientStyling(
                                                    name,
                                                    checked
                                                )
                                            }
                                        />
                                    </div>
                                </Row>
                            </div>
                            <Row className="py-1" noGutters>
                                <Col>Buy / Bid Background :</Col>
                                <Col>
                                    <Form.Control
                                        type="color"
                                        size="sm"
                                        name="buyBidColor"
                                        onChange={({
                                            target: { name, value },
                                        }) =>
                                            onChangeBookGradientStyling(
                                                name,
                                                value
                                            )
                                        }
                                        value={getStylingHex(
                                            currentConfig?.styling?.bookGradient
                                                ?.buyBidColor || "#00008B"
                                        )}
                                    />
                                </Col>
                            </Row>
                            <Row className="py-1" noGutters>
                                <Col>Buy / Bid Text Color :</Col>
                                <Col>
                                    <Form.Control
                                        type="color"
                                        size="sm"
                                        name="buyBidTextColor"
                                        onChange={({
                                            target: { name, value },
                                        }) =>
                                            onChangeBookGradientStyling(
                                                name,
                                                value
                                            )
                                        }
                                        value={getStylingHex(
                                            currentConfig?.styling?.bookGradient
                                                ?.buyBidTextColor || "--White"
                                        )}
                                    />
                                </Col>
                            </Row>
                            <Row className="py-1" noGutters>
                                <Col>Sell / Ask Background :</Col>
                                <Col>
                                    <Form.Control
                                        type="color"
                                        size="sm"
                                        name="sellAskColor"
                                        onChange={({
                                            target: { name, value },
                                        }) =>
                                            onChangeBookGradientStyling(
                                                name,
                                                value
                                            )
                                        }
                                        value={getStylingHex(
                                            currentConfig?.styling?.bookGradient
                                                ?.sellAskColor || "#8B0000"
                                        )}
                                    />
                                </Col>
                            </Row>
                            <Row className="py-1" noGutters>
                                <Col>Sell / Ask Text Color :</Col>
                                <Col>
                                    <Form.Control
                                        type="color"
                                        size="sm"
                                        name="sellAskTextColor"
                                        onChange={({
                                            target: { name, value },
                                        }) =>
                                            onChangeBookGradientStyling(
                                                name,
                                                value
                                            )
                                        }
                                        value={getStylingHex(
                                            currentConfig?.styling?.bookGradient
                                                ?.sellAskTextColor || "--White"
                                        )}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Collapse>

                    <div
                        onClick={() =>
                            setExpandTradeHistoryCollapse(
                                !expandTradeHistoryCollapse
                            )
                        }
                        className="pb-1 mb-1 -ml-1 cursor-pointer d-flex justify-content-between"
                        style={{
                            borderBottom: "1px solid white",
                            margin: "0 -0.75rem 0 -0.75rem",
                        }}
                    >
                        <div>
                            <b>Trade History Tab</b>
                        </div>
                        <div>
                            {!expandTradeHistoryCollapse ? (
                                <IoIosArrowDown />
                            ) : (
                                <IoIosArrowUp />
                            )}
                        </div>
                    </div>
                    <Collapse
                        in={expandTradeHistoryCollapse}
                        className="size-sm"
                    >
                        <div>
                            <Row className="py-1" noGutters>
                                <Col>Positive Change Color :</Col>
                                <Col>
                                    <Form.Control
                                        type="color"
                                        size="sm"
                                        name="positiveChangeColor"
                                        onChange={({
                                            target: { name, value },
                                        }) => onChangeStyling(name, value)}
                                        value={getStylingHex(
                                            currentConfig?.styling?.tradeHistory
                                                ?.positiveChangeColor ||
                                                defaultLayoutConfigurationOptions
                                                    .styling.tradeHistory
                                                    .positiveChangeColor
                                        )}
                                    />
                                </Col>
                            </Row>
                            <Row className="py-1" noGutters>
                                <Col>Negative Change Color :</Col>
                                <Col>
                                    <Form.Control
                                        type="color"
                                        size="sm"
                                        name="negativeChangeColor"
                                        onChange={({
                                            target: { name, value },
                                        }) => onChangeStyling(name, value)}
                                        value={getStylingHex(
                                            currentConfig?.styling?.tradeHistory
                                                ?.negativeChangeColor ||
                                                defaultLayoutConfigurationOptions
                                                    .styling.tradeHistory
                                                    .negativeChangeColor
                                        )}
                                    />
                                </Col>
                            </Row>
                            <Row className="py-1" noGutters>
                                <Col>Neutral Change Color :</Col>
                                <Col>
                                    <Form.Control
                                        type="color"
                                        size="sm"
                                        name="neutralChangeColor"
                                        onChange={({
                                            target: { name, value },
                                        }) => onChangeStyling(name, value)}
                                        value={getStylingHex(
                                            currentConfig?.styling?.tradeHistory
                                                ?.neutralChangeColor ||
                                                defaultLayoutConfigurationOptions
                                                    .styling.tradeHistory
                                                    .neutralChangeColor
                                        )}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Collapse>
                </div>
                <div className="mr-2">
                    <div
                        onClick={() =>
                            setExpandNotificationsCollapse(
                                !expandNotificationsCollapse
                            )
                        }
                        className="pb-1 mb-1 cursor-pointer d-flex justify-content-between"
                        style={{
                            borderBottom: "1px solid white",
                        }}
                    >
                        <div>
                            <b>Notifications</b>
                        </div>
                        <div>
                            {!expandNotificationsCollapse ? (
                                <IoIosArrowDown />
                            ) : (
                                <IoIosArrowUp />
                            )}
                        </div>
                    </div>
                    <Collapse
                        in={expandNotificationsCollapse}
                        className="size-sm"
                    >
                        <div>
                            <div className="mr-0 py-2">
                                <Row className="d-flex justify-content-between ml-0 mr-0">
                                    <div>Play sound on Trade Execution :</div>
                                    <div>
                                        <Form.Check
                                            name="playSoundOnTrade"
                                            checked={
                                                currentConfig?.playSoundOnTrade
                                            }
                                            onChange={({
                                                target: { name, checked },
                                            }) => onChange(name, checked)}
                                        />
                                    </div>
                                </Row>
                            </div>
                            <div className="mr-0 py-2">
                                <Row className="d-flex justify-content-between ml-0 mr-0">
                                    <div>
                                        Surpress Cancelled Order Notifications :
                                    </div>
                                    <div>
                                        <Form.Check
                                            name="surpressCancelledNotifications"
                                            checked={
                                                currentConfig?.surpressCancelledNotifications
                                            }
                                            onChange={({
                                                target: { name, checked },
                                            }) => onChange(name, checked)}
                                        />
                                    </div>
                                </Row>
                            </div>
                            <div className="mr-0 py-2">
                                <Row className="d-flex justify-content-between ml-0 mr-0">
                                    <div>
                                        Surpress Executed Order Notifications :
                                    </div>
                                    <div>
                                        <Form.Check
                                            name="surpressExecutedNotifications"
                                            checked={
                                                currentConfig?.surpressExecutedNotifications
                                            }
                                            onChange={({
                                                target: { name, checked },
                                            }) => onChange(name, checked)}
                                        />
                                    </div>
                                </Row>
                            </div>
                        </div>
                    </Collapse>
                </div>
                <div className="section-divider ">
                    <hr />
                    <span>Other</span>
                </div>
                <div className="text-center text-gray pb-2">
                    Other and Notification changes require a page refresh
                </div>
                {enableExternalTrade && (
                    <>
                        <div className="ml-3 py-2">
                            <Row className="d-flex justify-content-between ml-1 mr-4">
                                <div>Add external book feed :</div>
                                <div>
                                    <Form.Check
                                        name="useExternalBookFeed"
                                        checked={
                                            currentConfig?.useExternalBookFeed
                                        }
                                        onChange={({
                                            target: { name, checked },
                                        }) => onChange(name, checked)}
                                    />
                                </div>
                            </Row>
                        </div>
                        <div className="ml-3 py-2">
                            <Row className="d-flex justify-content-between ml-1 mr-4">
                                <div>Use national best bid/offer :</div>
                                <div>
                                    <Form.Check
                                        name="useExternalTobFeed"
                                        checked={
                                            currentConfig?.useExternalTobFeed
                                        }
                                        onChange={({
                                            target: { name, checked },
                                        }) => onChange(name, checked)}
                                    />
                                </div>
                            </Row>
                        </div>
                    </>
                )}
                <div className="ml-3 py-2">
                    <Row className="d-flex justify-content-between ml-1 mr-4">
                        <div>Show configure buttons on market watch tab :</div>
                        <div>
                            <Form.Check
                                name="showConfigureOnMarketWatch"
                                checked={
                                    currentConfig?.showConfigureOnMarketWatch
                                }
                                onChange={({ target: { name, checked } }) =>
                                    onChange(name, checked)
                                }
                            />
                        </div>
                    </Row>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="primary"
                    size="sm"
                    className="px-3"
                    onClick={() =>
                        updateLayoutConfig(currentConfig).then(() =>
                            toast.success("Styling Updated")
                        )
                    }
                    // disabled={layoutConfigurationOptions?.pageSelected?.startsWith(
                    //     "preset"
                    // )}
                >
                    Save
                </Button>
                {isUserAdmin && (
                    <Button
                        variant="primary"
                        size="sm"
                        className="px-2"
                        onClick={() => saveAsFirmDefault(currentConfig)}
                    >
                        Save As Firm Default
                    </Button>
                )}
                <Button
                    variant="danger"
                    size="sm"
                    className="px-2"
                    onClick={resetLayoutConfig}
                    // disabled={layoutConfigurationOptions?.pageSelected?.startsWith(
                    //     "preset"
                    // )}
                >
                    Reset Layout
                </Button>
                <Button
                    variant="light"
                    size="sm"
                    className="px-3"
                    onClick={onClose}
                >
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
