/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState } from "react";
import { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { clientName, emailOnboardingAdmin } from "../../config";
import {
    notifyOnboardingAdmin,
    reviewSubmissions,
} from "../../helpers/onboarding";
import { resendConfirmEmail } from "../../helpers/register";
import { useStore } from "../../Store";

const ConfirmationPage = () => {
    const { state } = useStore();
    const navigate = useNavigate();

    const {
        onboarding: { submissions },
        account: {
            account: { data: loggedInUser },
        },
    } = state;

    const [isAllFormsCompleted, setIsAllFormsCompleted] = useState(false);

    useEffect(() => {
        if (submissions.length > 0) {
            setIsAllFormsCompleted(
                submissions.every(
                    (submission) => submission.status !== "PENDING"
                )
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submissions]);

    useEffect(() => {
        if (
            loggedInUser?.attr?.email_confirmed === "Y" &&
            isAllFormsCompleted === true &&
            loggedInUser?.attr?.status === "open"
        ) {
            // If every single submission created was an automatic form we will ask the system to auto review the account
            const autoReviewSubmissions = submissions.every(
                (submission) => submission?.data?.form?.onSubmit === "automatic"
            );
            if (autoReviewSubmissions) reviewSubmissions(navigate);

            // If email onboarding admin is on
            // If the user who finshed submitting their forms within the last minute
            if (!autoReviewSubmissions && emailOnboardingAdmin)
                notifyOnboardingAdmin();
        }

        if (
            loggedInUser?.attr?.email_confirmed === "Y" &&
            isAllFormsCompleted === false &&
            loggedInUser?.attr?.status === "open"
        ) {
            const nextSubmissionIndex = submissions.findIndex(
                (submission) => submission.status === "PENDING"
            );
            if (nextSubmissionIndex >= 0) {
                navigate(
                    `/forms/${submissions[nextSubmissionIndex]?.submission_id}`
                );
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loggedInUser, submissions, isAllFormsCompleted]);

    useEffect(() => {
        if (loggedInUser?.attr?.status === "approved") navigate("/portal");
    }, [loggedInUser, navigate]);

    return (
        <Container fluid className="confirmation-container">
            {loggedInUser?.attr?.status === "rejected" ? (
                <Row
                    noGutters
                    className="d-flex justify-content-center"
                    style={{ flexDirection: "column" }}
                >
                    <h5 className="text-center w-100">
                        Your application has been rejected.
                    </h5>
                </Row>
            ) : loggedInUser?.attr?.email_confirmed === "N" ? (
                <>
                    <Row noGutters className="confirmation-header">
                        <h4 className="text-center w-100">Verify Email</h4>
                    </Row>
                    <Row noGutters className="confirmation-body size-sm">
                        <Col>
                            You must verify your email address using the link we
                            emailed you when you created your account.
                        </Col>
                    </Row>

                    <Row className="confirmation-footer size-sm">
                        <Col>
                            Didn&apos;t get anything? Please check your spam
                            folder or{" "}
                            <span
                                className="resend-confirmation"
                                onClick={resendConfirmEmail}
                            >
                                resend your confirmation email
                            </span>
                        </Col>
                    </Row>
                </>
            ) : loggedInUser?.attr?.email_confirmed === "Y" &&
              isAllFormsCompleted ? (
                <Row
                    noGutters
                    className="d-flex justify-content-center"
                    style={{ flexDirection: "column" }}
                >
                    <h5 className="text-center w-100">
                        You have completed the registration process. <br />
                        <br /> Your registration is under review by {clientName}
                        . <br />
                        <br /> You will receive an email about your registration
                        to the site. <br />
                        <br /> Approval will be provided as quickly as possible
                        but approval may take up to one business day.
                    </h5>
                </Row>
            ) : (
                <Row
                    noGutters
                    className="d-flex justify-content-center"
                    style={{ flexDirection: "column" }}
                >
                    <h5 className="text-center w-100">
                        Your email has been successfully confirmed.
                    </h5>
                </Row>
            )}
        </Container>
    );
};

export default ConfirmationPage;
