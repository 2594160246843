import "react-datepicker/dist/react-datepicker.css";

import moment from "moment";
import Papa from "papaparse";
import { useEffect, useState } from "react";
import { Button, Col, Row, Tab, Tabs } from "react-bootstrap";
import DatePicker from "react-datepicker";

import { Loading } from "../../../components/Loading";
import { TableRenderer } from "../../../components/portal/TableRenderer";
import { clientDateFormat } from "../../../config";
import { getTradeFile } from "../../../helpers/market";
import { formatTableColumn } from "../../../helpers/other";
import { useStore } from "../../../Store";

const TradeReport = () => {
    const { state, dispatch } = useStore();

    const { tradefile } = state.market;

    const [loading, setLoading] = useState(false);
    const [selectedDate, setSelectedDate] = useState(
        tradefile.date.length > 0
            ? moment(tradefile.date, "YYYYMMDD").toDate()
            : new Date()
    );
    const [disableDate, setDisableDate] = useState(false);

    const updateSelectedDate = (date) => setSelectedDate(date);

    const getCSVURL = (fileName, fileData) => {
        const formattedFileName = fileName.replaceAll(".", "\\.");
        const removeNameRe = new RegExp(`${formattedFileName}`, "g");
        const newFileData = fileData.replace(removeNameRe, "");

        const csvFile = new Blob([newFileData], {
            type: "text/csv",
        });
        const objectURL = URL.createObjectURL(csvFile);

        return objectURL;
    };

    const formatTradeFile = (elem) => {
        // let [fileName, fileData] = elem.split(/#/g);
        const fileArr = elem.split(/\n/g);
        let fileName = fileArr[0];
        let fileData = fileArr.slice(1, fileArr.length).join("\n");

        const isJsonFile = fileName.endsWith(".json");

        fileData = `#${fileData}`;
        fileName = fileName.replace(/^\$/g, "");

        const formattedData = Papa.parse(fileData, {
            header: true,
            transformHeader: (hdr) => {
                if (hdr.length !== 0)
                    return hdr.replace(/^#(.+)/g, (a, b) => b);
            },
            preview: 100,
        });

        const filteredData = formattedData.data.filter((elem) => {
            Object.keys(elem).forEach((key) =>
                elem[key] === undefined ||
                key === "undefined" ||
                elem[key].length === 0
                    ? delete elem[key]
                    : {}
            );

            return Object.keys(elem).length !== 0;
        });

        const filteredColumns = isJsonFile
            ? fileArr.slice(1, fileArr.length)
            : formattedData.meta.fields.filter((elem) => elem !== undefined);

        return [fileName, filteredData, filteredColumns, isJsonFile];
    };

    useEffect(() => {
        const formattedDate = moment(selectedDate).format("YYYYMMDD");

        if (formattedDate !== tradefile.date) {
            setDisableDate(true);
            setLoading(true);

            getTradeFile(dispatch, formattedDate).then(() => {
                setLoading(false);
                setDisableDate(false);
            });
        }

        return () => setLoading(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDate]);

    return (
        <div className="m-4">
            <Row noGutters className="forms-header">
                <Col>
                    <h4>Trade Report</h4>
                </Col>
            </Row>
            <Row className="my-2" md={6}>
                <Col className="mt-1">Date:</Col>
                <Col>
                    <DatePicker
                        selected={selectedDate}
                        onChange={updateSelectedDate}
                        disabled={disableDate}
                        dateFormat={clientDateFormat}
                    />
                </Col>
            </Row>

            {!loading ? (
                <Tabs>
                    {tradefile.data &&
                        tradefile.data.map((elem, i) => {
                            const [
                                fileName,
                                fileData,
                                filteredColumns,
                                isJsonFile,
                            ] = formatTradeFile(elem);

                            const objectURL = getCSVURL(fileName, elem);

                            return (
                                <Tab eventKey={i} key={i} title={fileName}>
                                    <Row noGutters className="my-2">
                                        <a
                                            href={objectURL}
                                            download={`${fileName.trim()}${
                                                isJsonFile ? "" : ".csv"
                                            }`}
                                        >
                                            <Button size="sm">
                                                {fileName.endsWith(".json")
                                                    ? "Download as .json"
                                                    : "Download as .CSV"}
                                            </Button>
                                        </a>
                                    </Row>
                                    {isJsonFile ? (
                                        <Row noGutters>
                                            Please download file, cannot display
                                            JSON as table
                                        </Row>
                                    ) : (
                                        <TableRenderer
                                            keyField="refno"
                                            data={fileData}
                                            columns={filteredColumns.map(
                                                (col) => formatTableColumn(col)
                                            )}
                                            noDataIndication="No trade data."
                                            striped
                                            pagination
                                            wrapperClasses="size-sm"
                                        />
                                    )}
                                    {!isJsonFile && (
                                        <Row noGutters>
                                            The max preview is limited to 100
                                            results, If you&apos;d like to see
                                            more, please download the .CSV file.
                                        </Row>
                                    )}
                                </Tab>
                            );
                        })}
                </Tabs>
            ) : (
                <Loading />
            )}
        </div>
    );
};

export default TradeReport;
