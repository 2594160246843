import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";

import { ConfigureModalTemplate } from "./ConfigureModalTemplate";

export const DropdownConfigureModal = ({
    show = false,
    handleSave,
    options = [],
    handleSaveRejected,
    rejected = [],
    onboarding,
}) => {
    const [dropdownOptions, setDropdownOptions] = useState(options);

    const updateRejectedArray = (checked, i) => {
        const newRejected = rejected;

        if (checked && dropdownOptions[i].length > 0) {
            newRejected.splice(i, 0, dropdownOptions[i]);
        } else {
            const index = newRejected.indexOf(dropdownOptions[i]);

            if (index > -1) {
                newRejected.splice(i, 1);
            }
        }

        handleSaveRejected(newRejected);
    };

    return (
        <ConfigureModalTemplate
            show={show}
            handleConfirm={() => setDropdownOptions([...dropdownOptions, ""])}
            handleClose={() => handleSave("options", dropdownOptions)}
            confirmText="Add Dropdown Option"
            body={
                <>
                    {dropdownOptions.map((opt, i) => {
                        const rejectExists = rejected.find(
                            (rej) => rej === opt
                        );

                        return (
                            <Row noGutters key={i} className="my-2">
                                <Col md={onboarding ? 6 : 10} className="mr-1">
                                    <Form.Control
                                        size="sm"
                                        disabled={rejectExists}
                                        defaultValue={dropdownOptions[i]}
                                        onChange={({ target: { value } }) => {
                                            const newOptions =
                                                Array.from(dropdownOptions);
                                            newOptions[i] = value;

                                            setDropdownOptions(newOptions);
                                        }}
                                    />
                                </Col>

                                {onboarding && (
                                    <Col md={4} className="size-sm mt-1 ml-2">
                                        <div className="d-inline-flex d-flex">
                                            <div className="mr-3">
                                                Reject this value?
                                            </div>
                                            <Form.Check
                                                type={"checkbox"}
                                                defaultChecked={rejectExists}
                                                disabled={opt.length === 0}
                                                onChange={({
                                                    target: { checked },
                                                }) =>
                                                    updateRejectedArray(
                                                        checked,
                                                        i
                                                    )
                                                }
                                            />
                                        </div>
                                    </Col>
                                )}
                                <Col>
                                    <Button
                                        size="sm"
                                        variant="danger"
                                        onClick={() => {
                                            handleSaveRejected(
                                                rejected.filter(
                                                    (item) => item !== opt
                                                )
                                            );

                                            setDropdownOptions(
                                                dropdownOptions.filter(
                                                    (item, y) => y !== i
                                                )
                                            );
                                        }}
                                    >
                                        Delete
                                    </Button>
                                </Col>
                            </Row>
                        );
                    })}
                </>
            }
        />
    );
};
