import moment from "moment";
import { memo } from "react";
import {
    Badge,
    Button,
    Card,
    Col,
    OverlayTrigger,
    Row,
    Tooltip,
} from "react-bootstrap";
import { AiFillMinusCircle, AiFillPlusCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

import {
    clientDateFormat,
    defaultSymbolIcon,
    emailIssuer,
    enableNewMarketWatch,
    enableSimpleMarketWatch,
    hideTradeScreenWhenInactive,
    isQMSClient,
    showAssetBuilder,
    usePrevClosePriceAsLastPriceWhenLPIsNA,
} from "../../../config";
import { formatNumber, getPercentageChange } from "../../../helpers/other";
import { useStore } from "../../../Store";

// eslint-disable-next-line react/display-name
export const MarketWatchCard = memo(
    ({ asset, profile, updateSymbolInWatchList, watchlist }) => {
        const navigate = useNavigate();
        const { state } = useStore();
        const { portalAuctionInformation } = state.trade;

        const isUserActive = state.account.account.data.active === "Y";

        const ifMarketPlacePriceDecimalsEnabled = (price) =>
            price
                ? enableSimpleMarketWatch
                    ? formatNumber(price, 4)
                    : price
                : "N/A";

        // If permanent halted
        const haltedTime = portalAuctionInformation?.[asset.security]?.find(
            (time) => time.status === "R"
        );
        const isSymbolPaused =
            haltedTime?.scheduletime > 0 && haltedTime?.status;

        const scheduleTimes = portalAuctionInformation?.[asset.security]
            ?.filter((time) => time.status !== "R")
            .sort((a, b) => a.scheduletime - b.scheduletime)
            .map((time) => {
                const isToday =
                    new Date(time.scheduletime).setHours(0, 0, 0, 0) ===
                    new Date().setHours(0, 0, 0, 0);

                return {
                    ...time,
                    scheduletime: time?.repeat_pattern
                        ? moment(
                              isToday
                                  ? time.scheduletime
                                  : time.nextaction_date,
                              isToday ? undefined : "YYYYMMDD"
                          )
                              .toDate()
                              .setHours(
                                  new Date(time.scheduletime).getHours(),
                                  new Date(time.scheduletime).getMinutes()
                              )
                        : time.scheduletime,
                };
            });

        // Check for next schedule time
        const nextScheduleTime = scheduleTimes?.find(
            (time) => time?.scheduletime > Date.now()
        );

        const repeatingDays = nextScheduleTime?.repeat_pattern
            ? nextScheduleTime?.repeat_pattern.split("|D")[1].split(",")
            : [];

        return (
            <Card
                style={{
                    width: "16rem",
                }}
                className="mr-2 mt-2 no-select"
            >
                <Card.Img
                    variant="top"
                    src={
                        profile?.image ||
                        asset?.attr?.image ||
                        defaultSymbolIcon
                    }
                    alt=""
                    width="130px"
                    height="130px"
                    className="mt-4"
                    style={{ objectFit: "contain" }}
                    draggable={false}
                />
                <Card.Body className="d-flex flex-column">
                    {!enableNewMarketWatch &&
                        (watchlist ? (
                            <AiFillMinusCircle
                                style={{
                                    fontSize: "1.5rem",
                                    left: "0.5rem",
                                    top: "0.5rem",
                                }}
                                className="cursor-pointer position-absolute"
                                onClick={() =>
                                    updateSymbolInWatchList(
                                        asset.security,
                                        "remove"
                                    )
                                }
                            />
                        ) : (
                            <AiFillPlusCircle
                                style={{
                                    fontSize: "1.5rem",
                                    left: "0.5rem",
                                    top: "0.5rem",
                                }}
                                className="cursor-pointer position-absolute"
                                onClick={() =>
                                    updateSymbolInWatchList(
                                        asset.security,
                                        "add"
                                    )
                                }
                            />
                        ))}
                    <Card.Title className="text-center mb-0">
                        {asset.security}
                    </Card.Title>

                    <Card.Body className="size-sm px-1 pt-0 d-flex justify-content-between flex-column">
                        <Row className="d-flex justify-content-center w-100 mx-1">
                            {(asset?.status?.startsWith("H") ||
                                asset?.status?.startsWith("P")) && (
                                <Badge
                                    variant={
                                        asset?.status.startsWith("H")
                                            ? "danger"
                                            : "warning"
                                    }
                                    as="div"
                                    className="px-1 py-1 mt-2 mr-1"
                                    style={{
                                        backgroundColor:
                                            isSymbolPaused &&
                                            asset?.status.startsWith("H")
                                                ? "#FF5F15"
                                                : "",
                                    }}
                                >
                                    {asset?.status.startsWith("H")
                                        ? isSymbolPaused
                                            ? "Paused"
                                            : "Halted"
                                        : "Pre-Open"}
                                </Badge>
                            )}
                            {nextScheduleTime && (
                                <OverlayTrigger
                                    placement="bottom"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={
                                        <Tooltip
                                            className="market-watch-time-tooltip"
                                            style={{
                                                display: !scheduleTimes
                                                    ? "none"
                                                    : "initial",
                                            }}
                                        >
                                            {scheduleTimes.map((time) => (
                                                <div
                                                    className="size-sm"
                                                    key={time.scheduletime}
                                                >
                                                    {time.status === "O"
                                                        ? "Opening at"
                                                        : time.status === "H"
                                                        ? "Halting at"
                                                        : "Pre-Opening at"}{" "}
                                                    {moment(
                                                        time?.scheduletime
                                                    ).format(
                                                        "MMMM Do YYYY, h:mmA"
                                                    )}
                                                </div>
                                            ))}
                                            {repeatingDays.length > 0 && (
                                                <div className="size-sm">
                                                    Repeating on:{" "}
                                                    {repeatingDays
                                                        .map((day) =>
                                                            moment()
                                                                .day(day)
                                                                .format("dddd")
                                                        )
                                                        .join(", ")}
                                                </div>
                                            )}
                                        </Tooltip>
                                    }
                                >
                                    <Badge
                                        variant="info"
                                        as="div"
                                        className="mt-2"
                                    >
                                        {repeatingDays.length === 0
                                            ? "Auction"
                                            : "Periodic Trading"}
                                        :{" "}
                                        {nextScheduleTime.status === "O"
                                            ? "Opening"
                                            : nextScheduleTime.status === "H"
                                            ? "Halting"
                                            : "Pre-Opening"}{" "}
                                        {/* Check if today */}
                                        {moment(
                                            nextScheduleTime?.scheduletime
                                        ).format("YYYY-MM-DD") ===
                                        moment().format("YYYY-MM-DD")
                                            ? `at ${moment(
                                                  nextScheduleTime?.scheduletime
                                              ).format("h:mm A")}`
                                            : `on ${moment(
                                                  nextScheduleTime?.scheduletime
                                              ).format(
                                                  clientDateFormat.toUpperCase()
                                              )}`}
                                    </Badge>
                                </OverlayTrigger>
                            )}
                        </Row>

                        <div>
                            <Row noGutters className="py-2">
                                {asset?.attr?.name}
                            </Row>
                            {emailIssuer && (
                                <Row noGutters>
                                    <Col xs={9}>
                                        <b>Issuer Firm</b>
                                    </Col>
                                    <Col>
                                        {asset?.attr?.issuerFirm || "N/A"}
                                    </Col>
                                </Row>
                            )}
                            {!enableSimpleMarketWatch && (
                                <>
                                    <Row noGutters>
                                        <Col xs={9}>
                                            <b>Prior Day Close</b>
                                        </Col>
                                        <Col>
                                            {ifMarketPlacePriceDecimalsEnabled(
                                                asset?.prevcloseprice
                                            )}
                                        </Col>
                                    </Row>
                                    <Row noGutters>
                                        <Col xs={9}>
                                            <b>Best Bid</b>
                                        </Col>
                                        <Col className="text-success">
                                            {ifMarketPlacePriceDecimalsEnabled(
                                                asset?.bid
                                            )}
                                        </Col>
                                    </Row>
                                    <Row noGutters>
                                        <Col xs={9}>
                                            <b>Best Offer</b>
                                        </Col>
                                        <Col className="text-danger">
                                            {ifMarketPlacePriceDecimalsEnabled(
                                                asset?.offer
                                            )}
                                        </Col>
                                    </Row>
                                    <Row noGutters>
                                        <Col xs={9}>
                                            <b>High</b>
                                        </Col>
                                        <Col>
                                            {ifMarketPlacePriceDecimalsEnabled(
                                                asset?.high
                                            )}
                                        </Col>
                                    </Row>
                                    <Row noGutters>
                                        <Col xs={9}>
                                            <b>Low</b>
                                        </Col>
                                        <Col>
                                            {ifMarketPlacePriceDecimalsEnabled(
                                                asset?.low
                                            )}
                                        </Col>
                                    </Row>
                                    <Row noGutters>
                                        <Col xs={9}>
                                            <b>Last Price</b>
                                        </Col>
                                        <Col>
                                            {ifMarketPlacePriceDecimalsEnabled(
                                                asset?.last
                                                    ? asset?.last
                                                    : usePrevClosePriceAsLastPriceWhenLPIsNA
                                                    ? asset?.prevcloseprice
                                                    : asset?.last
                                            )}
                                        </Col>
                                    </Row>
                                    <Row noGutters>
                                        <Col xs={9}>
                                            <b>Percentage Change</b>
                                        </Col>
                                        <Col>
                                            {getPercentageChange(
                                                asset?.prevcloseprice,
                                                asset?.last
                                            ) || "N/A"}
                                        </Col>
                                    </Row>
                                </>
                            )}
                        </div>
                    </Card.Body>
                    <Row noGutters className="justify-content-between">
                        {showAssetBuilder && (
                            <Button
                                variant="primary"
                                className="px-4"
                                onClick={() =>
                                    navigate(`/assets/${profile.symbol}`)
                                }
                                disabled={!profile}
                            >
                                Details
                            </Button>
                        )}
                        {(hideTradeScreenWhenInactive
                            ? isUserActive
                            : true) && (
                            <Button
                                variant="primary"
                                className="px-4"
                                onClick={() =>
                                    navigate(`/portal/trade/${asset?.security}`)
                                }
                                disabled={
                                    hideTradeScreenWhenInactive && !isUserActive
                                }
                            >
                                {isQMSClient ? "Enter / Review IOI" : "Trade"}
                            </Button>
                        )}
                    </Row>
                </Card.Body>
            </Card>
        );
    }
);
