/* eslint-disable react/display-name */
import { memo } from "react";
import { Button, Modal } from "react-bootstrap";

export const ConfirmSymbolStatusModal = memo(
    ({
        show = false,
        handleCloseModal,
        handleSubmit,
        security,
        statusString,
    }) => {
        return (
            <Modal show={show} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Symbol Status Change Request</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to change the status of symbol: &quot;
                    {security}&quot; to {statusString}?
                    <br />
                    <br />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={() => handleSubmit(statusString.charAt(0))}
                    >
                        Update
                    </Button>
                    <Button variant="light" onClick={handleCloseModal}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
);
