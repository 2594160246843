/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";

export const EditCompanyDocModal = ({
    show = false,
    onClose,
    doc,
    type = "add",
    handleSave,
}) => {
    const [document, setDocument] = useState({
        title: "",
        description: "",
        isPublished: false,
        fileName: "",
        ...doc,
    });
    const [file, setFile] = useState();
    const [filePreview, setFilePreview] = useState();

    const disabled = !document.title || !document.description || !filePreview;

    useEffect(() => {
        try {
            setFilePreview(doc?.file ?? file);
            file &&
                setDocument({
                    ...document,
                    fileName: file.name,
                });
        } catch (err) {
            console.log(err);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [file]);

    return (
        <Modal show={show} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {type === "add" ? "Create" : "Edit"} Company Document
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Form.Label className="size-sm">Title</Form.Label>
                        <Form.Control
                            type="text"
                            value={document?.title}
                            onChange={(e) =>
                                setDocument({
                                    ...document,
                                    title: e.target.value,
                                })
                            }
                            size="sm"
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label className="size-sm">Description</Form.Label>
                        <Form.Control
                            type="text"
                            as="textarea"
                            rows={5}
                            value={document?.description}
                            onChange={(e) =>
                                setDocument({
                                    ...document,
                                    description: e.target.value,
                                })
                            }
                            size="sm"
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label className="size-sm">File</Form.Label>
                        {filePreview ? (
                            <div style={{ position: "relative" }}>
                                {/* <embed
                                    src={filePreview}
                                    type={filePreview.type}
                                    style={{
                                        width: "100%",
                                        height: "250px",
                                        objectFit: "contain",
                                    }}
                                /> */}
                                <div className="size-sm">
                                    <b>
                                        {document?.fileName || "No file name"}
                                    </b>
                                </div>

                                <div
                                    className="text-danger p-1"
                                    style={{
                                        position: "absolute",
                                        top: "-1.75rem",
                                        right: "0rem",
                                        cursor: "pointer",
                                        fontSize: "0.9rem",
                                    }}
                                    onClick={() => setFilePreview(null)}
                                >
                                    Remove
                                </div>
                            </div>
                        ) : (
                            <Form.File
                                label={
                                    file?.name ?? "Upload Company Document.."
                                }
                                custom
                                className="size-sm"
                                onChange={({ target: { files } }) =>
                                    setFile(files[0])
                                }
                            />
                        )}
                    </Form.Group>
                    <Form.Group
                        className="mt-2 mb-0"
                        style={{
                            display: "inline-flex",
                            justifyContent: "space-between",
                            width: "100%",
                        }}
                    >
                        <Form.Label className="size-sm">Published</Form.Label>
                        <Form.Check
                            type="switch"
                            id="published-switch"
                            label=""
                            checked={document?.isPublished || false}
                            onChange={() =>
                                setDocument({
                                    ...document,
                                    isPublished: !document.isPublished,
                                })
                            }
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    className="px-4"
                    size="sm"
                    onClick={() => handleSave(document, file, type === "edit")}
                    disabled={disabled}
                >
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
