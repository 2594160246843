import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { Col, FormControl, Row } from "react-bootstrap";
import Select from "react-select";

import { TableRenderer } from "../../../components/portal/TableRenderer";
import { sevenDaysAgo, thirtyDaysAgo } from "../../../helpers/other";
import { getQueryAlerts } from "../../../helpers/risk";
import { useSocket } from "../../../Websocket";

const AlertsWrapper = ({
    title,
    defaultSubtype,
    columns,
    subtypeOptions,
    periodOptions,
}) => {
    const ws = useSocket();

    const [alerts, setAlerts] = useState([]);
    const [subtype, setSubtype] = useState(defaultSubtype);
    const [firmFilter, setFirmFilter] = useState("");
    const [accountFilter, setAccountFilter] = useState("");
    const [timeFilter, setTimeFilter] = useState({
        value: "seven",
        label: "7 days ago",
    });
    const [isLoading, setIsLoading] = useState(false);

    const updateAlerts = async (filter) => {
        const alertsRes = await getQueryAlerts(
            ws,
            filter,
            getFromTime(timeFilter.value)
        );
        setAlerts(alertsRes.reverse());
    };

    useEffect(() => {
        setIsLoading(true);
        updateAlerts({
            subtype: subtype.value,
            firm: firmFilter,
            account: accountFilter,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeFilter]);

    useEffect(() => {
        setIsLoading(false);
    }, [alerts]);

    const onChangeFirm = (e) => {
        updateAlerts({
            subtype: subtype.value,
            account: accountFilter,
            firm: e.target.value,
        });
    };

    const onChangeAccount = (e) => {
        updateAlerts({
            subtype: subtype.value,
            firm: firmFilter,
            account: e.target.value,
        });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedOnChangeFirm = useCallback(debounce(onChangeFirm, 500), [
        subtype,
        accountFilter,
        timeFilter,
    ]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedOnChangeAccount = useCallback(
        debounce(onChangeAccount, 500),
        [subtype, firmFilter, timeFilter]
    );

    const getFromTime = (time) => {
        return time === "all"
            ? ""
            : time === "seven"
            ? new Date(sevenDaysAgo()).getTime().toString()
            : new Date(thirtyDaysAgo()).getTime().toString();
    };

    return (
        <div className="m-4">
            <Row noGutters className="forms-header">
                <h4>{title}</h4>
            </Row>

            <Row noGutters className="pb-4">
                <Col xs={12} md={3} className="pr-2 pb-2">
                    <Select
                        classNamePrefix="react-select-prefix"
                        value={subtype}
                        onChange={(e) => {
                            setSubtype({ value: e.value, label: e.label });
                            setIsLoading(true);
                            updateAlerts({
                                subtype: e.value,
                                firm: firmFilter,
                                account: accountFilter,
                            });
                        }}
                        options={subtypeOptions}
                    />
                </Col>
            </Row>

            <Row noGutters className="pb-4">
                <Col xs={12} md={4} className="pr-2 pb-2">
                    <FormControl
                        placeholder="Search Firm..."
                        value={firmFilter}
                        onChange={(e) => {
                            setFirmFilter(e.target.value);
                            setIsLoading(true);
                            debouncedOnChangeFirm(e);
                        }}
                    />
                </Col>
                <Col xs={12} md={4} className="pr-2 pb-2">
                    <FormControl
                        placeholder="Search Account..."
                        value={accountFilter}
                        onChange={(e) => {
                            setAccountFilter(e.target.value);
                            setIsLoading(true);
                            debouncedOnChangeAccount(e);
                        }}
                    />
                </Col>
                <Col xs={12} md={4}>
                    <Select
                        classNamePrefix="react-select-prefix"
                        value={timeFilter}
                        onChange={(e) => {
                            setIsLoading(true);
                            setTimeFilter({ value: e.value, label: e.label });
                        }}
                        options={periodOptions}
                    />
                </Col>
            </Row>

            <TableRenderer
                keyField="rec_no"
                data={alerts}
                columns={columns}
                noDataIndication="No Alerts Available."
                loading={isLoading}
                pagination
                striped
            />
        </div>
    );
};

export default AlertsWrapper;
