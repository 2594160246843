/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Col, Image, OverlayTrigger, Row, Tooltip } from "react-bootstrap";

import { clientCurrency, defaultSymbolIcon } from "../../../config";
import * as ACTIONS from "../../../contexts/actions/trade";
import { convertStylingVar, getPercentageChange } from "../../../helpers/other";
import { useStore } from "../../../Store";
import { MarketWatchTable } from "../market-watch";

const SymbolItem = ({ symbol, image, onClick, height, width = 0 }) => (
    <OverlayTrigger
        placement={"auto-start"}
        overlay={
            width < 10 ? (
                <Tooltip className="mini-market-watch-tooltip">
                    <Row>
                        <Col>
                            <b>Best Bid</b>
                        </Col>
                        <Col>
                            <b>Best Offer</b>
                        </Col>
                    </Row>
                    <Row>
                        <Col>{symbol?.bid || "N/A"}</Col>
                        <Col>{symbol?.offer || "N/A"}</Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            <b>High</b>
                        </Col>
                        <Col>
                            <b>Low</b>
                        </Col>
                    </Row>
                    <Row>
                        <Col>{symbol?.high || "N/A"}</Col>
                        <Col>{symbol?.low || "N/A"}</Col>
                    </Row>
                    <br />
                    <Row>
                        {/* <Col>
                <b>Volume</b>
            </Col> */}
                        <Col>
                            <b>Prior Day Close</b>
                        </Col>
                    </Row>
                    <Row>
                        {/* <Col>{symbol?.volume || "N/A"}</Col> */}
                        <Col>{symbol?.prevcloseprice || "N/A"}</Col>
                    </Row>
                </Tooltip>
            ) : (
                <div />
            )
        }
    >
        <div
            // className=" mini-market-watch-item "
            // style={{
            //     display: "flex",
            //     flexWrap: height === 1 ? "nowrap" : "wrap",
            //     columnGap: "8px",
            //     flexFlow: "row wrap",

            //     justifyContent: "space-between",
            //     flex: `1 1 ${height === 1 ? "150px" : "100px"}`,
            //     minWidth: height === 1 ? "150px" : "100px",
            //     width: "100%",
            // }}
            onClick={onClick}
            style={{
                flex: `${height === 1 ? 1 : 0} 1 49%`,
                minWidth: "49%",
                display: "flex",
                justifyContent: "space-between",
            }}
        >
            <div className="w-50">
                <Image
                    src={image}
                    height="22px"
                    className="mr-2 mb-1"
                    style={{
                        objectFit: "scale-down",
                        maxWidth: "22px",
                    }}
                    alt=""
                />
                <b>{symbol?.security}</b>
            </div>

            <div
                className="w-50 pb-1"
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <b>{symbol?.last ? `$${symbol?.last}` : "$0.00"}</b>
                <div
                    className="text-right"
                    style={{
                        color:
                            symbol?.last >= symbol?.prevcloseprice
                                ? "green"
                                : "red",
                    }}
                >
                    <b>
                        {symbol?.last >= symbol?.prevcloseprice ? "+" : "-"}
                        {getPercentageChange(
                            symbol?.prevcloseprice,
                            symbol?.last
                        ) || "N/A"}
                    </b>
                </div>
            </div>
        </div>
    </OverlayTrigger>
);

// eslint-disable-next-line no-unused-vars
export const MiniMarketWatch = ({
    layoutKey,
    height = 0,
    width = 0,
    setSecuritySlug,
}) => {
    const { state, dispatch } = useStore();

    const { account: loggedInUser } = state.account;
    const {
        asset_builder: { publicProfiles: profiles },
    } = state;
    const { layoutConfigurationOptions } = state.trade;
    const fontColor = convertStylingVar(
        layoutConfigurationOptions.styling.fontColor
    );

    const symbolWatchList =
        loggedInUser?.data?.attr?.trade_preferences?.market_watch?.watch_list;
    const symbols = state.trade.symbols.sort((a, b) => {
        if (a.security > b.security) return 1;
        if (a.security < b.security) return -1;
        return 0;
    });
    const mappedSymbolWatchList = symbolWatchList
        ?.map((symbol) => {
            return symbols.find((s) => s.security === symbol);
        })
        .sort((a, b) => {
            if (a.security > b.security) return 1;
            if (a.security < b.security) return -1;
            return 0;
        });

    const doesProfileExist = (symbol) =>
        profiles.find((profile) =>
            symbol?.pair_second !== clientCurrency
                ? profile.symbol === symbol.security
                : profile.symbol === symbol.pair_first
        );

    const setNewCurrentSymbol = (symb) => {
        dispatch(ACTIONS.setCurrentSymbol(symb));

        setSecuritySlug(symb.security);

        window.history.replaceState(null, "", `/portal/trade/${symb.security}`);
    };

    return (
        <div
            style={{
                padding:
                    height === 1 ? "0" : layoutConfigurationOptions?.padding,
                paddingLeft: layoutConfigurationOptions?.padding,
                paddingRight: layoutConfigurationOptions?.padding,
                border: layoutConfigurationOptions?.componentBorders
                    ? `1px solid ${fontColor}`
                    : 0,
                height: "100%",
                overflowX: "auto",
                overflowY: height === 1 ? "hidden" : "auto",
            }}
        >
            <div
                className="mini-market-watch-parent"
                style={
                    {
                        // flexWrap: height === 1 ? "nowrap" : "wrap",
                    }
                }
            >
                {/* {!enableTempMiniMarketWatch ? (
                    mappedSymbolWatchList?.map((symbol, i) => (
                        <SymbolItem
                            key={i}
                            symbol={symbol}
                            onClick={() => setNewCurrentSymbol(symbol)}
                            height={height}
                            width={width}
                            image={
                                doesProfileExist(symbol)?.image ||
                                symbol?.attr?.image ||
                                defaultSymbolIcon
                            }
                        />
                    ))
                ) : ( */}
                <MarketWatchTable
                    symbols={symbols}
                    watchlist={symbolWatchList}
                    setSecuritySlug={setSecuritySlug}
                    isTradeScreen
                />
                {/* )} */}
            </div>
        </div>
    );
};
