import { useState } from "react";
import { Button, Col, FormControl, FormLabel, Row } from "react-bootstrap";
import { toast } from "react-toastify";

import { resetPassword } from "../../helpers/profile";
import { useStore } from "../../Store";
import { useSocket } from "../../Websocket";

export const ChangePassword = () => {

    const { state } = useStore();
    const ws = useSocket();

    const [passwordData, setPasswordData] = useState({
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: "",
    });

    const handleOnChange = ({ target: { name, value } }) =>
        setPasswordData({
            ...passwordData,
            [name]: value,
        });

    const {
        account: { publicKey },
    } = state;

    const {
        data: loggedInUser,
    } = state.account.account;

    const handleChangePassword = () => {

        if (passwordData.newPassword === passwordData.confirmNewPassword) {
            resetPassword(ws, publicKey, loggedInUser.userid, passwordData.oldPassword, passwordData.newPassword)
                .then(() => {
                    toast.success("Password has been changed.");
                    setPasswordData({
                        oldPassword: "",
                        newPassword: "",
                        confirmNewPassword: "",
                    })
                })
        } else {
            toast.error("New passwords entered do not match.")
        }
    }

    return (
        <Row className="mt-5">
            <Col xs={12}>
                <h5>Change Password</h5>
            </Col>
            <Col xs={6} className="mt-4">
                <Col className="mt-2">
                    <FormLabel>Old Password</FormLabel>
                    <FormControl
                        type="password"
                        name="oldPassword"
                        onChange={handleOnChange}
                        required
                        value={passwordData.oldPassword}
                    />
                </Col>
                <Col className="mt-2">
                    <FormLabel>New Password</FormLabel>
                    <FormControl type="password"
                        name="newPassword"
                        onChange={handleOnChange}
                        required
                        value={passwordData.newPassword}
                    />
                </Col>
                <Col className="mt-2">
                    <FormLabel>Confirm New Password</FormLabel>
                    <FormControl type="password"
                        name="confirmNewPassword"
                        onChange={handleOnChange}
                        required
                        value={passwordData.confirmNewPassword}
                    />
                </Col>
                <Col className="mt-4 d-flex justify-content-end">
                    <Button
                        onClick={() => {
                            handleChangePassword();
                        }}
                    >
                        Submit
                    </Button>
                </Col>
            </Col>
        </Row>
    );
};
