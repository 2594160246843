import { useEffect, useState } from "react";
import { Col, FormControl, FormLabel, Row } from "react-bootstrap";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import { MdList, MdViewModule, MdViewStream } from "react-icons/md";

import { AssetCard } from "../../components/assets/AssetCard";
import { AssetTable } from "../../components/assets/AssetTable";
import { Loading } from "../../components/Loading";
import { hideHaltedSymbols } from "../../config";
import { getPublishedAssetProfiles } from "../../helpers/asset-builder";
import useDrag from "../../hooks/useDrag.js";
import { useStore } from "../../Store";

const AssetsLandingPage = () => {
    const { state, dispatch } = useStore();
    const [loading, setLoading] = useState(true);

    const [view, setView] = useState("grid");
    const {
        asset_builder: { publicProfiles: assets },
        account: {
            auth: { status: isLoggedIn },
            account: { data: loggedInUser },
        },
        trade: { symbols },
    } = state;
    const [filteredAssets, setFilteredAssets] = useState([]);
    const mappedAssets = filteredAssets
        .sort((a, b) => a.symbol.localeCompare(b.symbol))
        .map((asset, i) => (
            <AssetCard
                asset={asset}
                key={i}
                hidden={!isLoggedIn || loggedInUser?.active === "N"}
            />
        ));

    const { dragStart, dragStop, dragMove } = useDrag();
    const handleDrag =
        ({ scrollContainer }) =>
        (ev) =>
            dragMove(ev, (posDiff) => {
                if (scrollContainer.current) {
                    scrollContainer.current.scrollLeft += posDiff;
                }
            });
    const onWheel = (apiObj, ev) => {
        const isTouchPad =
            Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

        if (isTouchPad) {
            ev.stopPropagation();
            return;
        }

        if (ev.deltaY > 0) {
            apiObj.scrollNext();
        } else if (ev.deltaY < 0) {
            apiObj.scrollPrev();
        }
    };
    const onSearch = ({ target: { value: searchValue } }) => {
        const showAssets = assets
            .filter((asset) =>
                asset.symbol.toLowerCase().includes(searchValue.toLowerCase())
            )
            .filter((asset) =>
                hideHaltedSymbols
                    ? !symbols.find((symb) => symb.security === asset.symbol)
                          ?.attr?.isHidden
                    : asset
            );

        setFilteredAssets(showAssets);
    };

    useEffect(
        () =>
            setFilteredAssets(
                assets.filter((asset) =>
                    hideHaltedSymbols
                        ? !symbols.find(
                              (symb) => symb.security === asset.symbol
                          )?.attr?.isHidden
                        : asset
                )
            ),
        [assets, symbols]
    );

    useEffect(() => {
        getPublishedAssetProfiles(dispatch).finally(() => setLoading(false));

        return () => setLoading(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="m-4">
            <div className="bg-white p-2">
                <Row noGutters className="pt-3">
                    <Col xs={3}>
                        <FormLabel>Search Assets:</FormLabel>
                    </Col>
                    <Col xs={3} className="pr-4">
                        <FormControl
                            onChange={onSearch}
                            placeholder={"Search..."}
                        />
                    </Col>
                    {/* <Col xs={3}>Select an Asset Type:</Col>
                    <Col xs={3}>
                        <FormControl placeholder={"Select an Asset Type..."} />
                    </Col> */}
                </Row>

                <Row noGutters>
                    <MdList
                        fontSize={"1.25rem"}
                        className="mr-2 cursor-pointer my-2"
                        onClick={() => setView("list")}
                    />
                    <MdViewModule
                        fontSize={"1.25rem"}
                        className="mr-2 cursor-pointer my-2"
                        onClick={() => setView("grid")}
                    />
                    <MdViewStream
                        fontSize={"1.25rem"}
                        className="mr-2 cursor-pointer my-2"
                        onClick={() => setView("cards")}
                    />
                </Row>
            </div>
            <Row noGutters className="mt-4">
                <Col>
                    <h4>Assets</h4>
                </Col>
            </Row>
            <div>
                {!loading ? (
                    view !== "list" ? (
                        view === "cards" ? (
                            <ScrollMenu
                                onMouseDown={() => dragStart}
                                onMouseUp={() => dragStop}
                                onMouseMove={handleDrag}
                                onWheel={onWheel}
                            >
                                {mappedAssets}
                            </ScrollMenu>
                        ) : (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "start",
                                    flexWrap: "wrap",
                                }}
                            >
                                {mappedAssets}
                            </div>
                        )
                    ) : (
                        <AssetTable profiles={filteredAssets} />
                    )
                ) : (
                    <Loading />
                )}
            </div>
        </div>
    );
};

export default AssetsLandingPage;
