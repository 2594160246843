import { useEffect } from "react";
import { Col, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { createGlobalStyle } from "styled-components";

import { DimFullScreenLoading } from "../../components/Loading";
import { PortalSidebar } from "../../components/portal/PortalSidebar";
import { PortalTopbar } from "../../components/portal/PortalTopbar";
import { TradeScreenConfigureSidebar } from "../../components/portal/trade/TradeScreenConfigureSidebar";
import { showOnboarding } from "../../config";
import { convertStylingVar } from "../../helpers/other";
import { useStore } from "../../Store";

const ModalStyling = createGlobalStyle`
        .trade-confirm-modal .modal-content {
            background: ${(props) => props.backgroundColor};
            color: ${(props) => props.fontColor}
        }
        .section-divider span {
             background-color: ${(props) => props.backgroundColor};
        }
        .topbar .dropdown-toggle.nav-link, .sidebar-collapse-toggle-aura,
        .sidebar-bottom-link, .portal-sidebar .dropdown-toggle, .portal-sidebar 
        .list-item-nodropdown, .topbar-row span, .topbar-row .text-white, .sidebar-bottom-quicklink {
            color: ${(props) => props.fontColor} !important;
        }
        .topbar .stage-status {
            color: ${(props) => props.fontColor} !important;
            margin-bottom: .5px;
        }
        .trade-tab {
            position: sticky;
            top: 0rem;
            background: ${(props) => props.backgroundColor};
            z-index: 99;
        }
        .order-ticket-container .trade-tab {
            position: relative !important;
        }
        .orders-table table thead {
            background: ${(props) => props.backgroundColor};
        }
        .books-table table thead {
            background: ${(props) => props.backgroundColor};
        }
        `;

const PortalLayout = ({ children }) => {
    const { state } = useStore();
    const navigate = useNavigate();

    const {
        other: { loading },
        trade: {
            layoutConfigurationOptions: { styling },
        },
        account: {
            account: {
                data: { status: userStatus },
            },
        },
    } = state;

    const isTradeScreenConfigure =
        window.location.pathname.includes("/portal/configure");
    const isTradeScreen = window.location.pathname.includes("/portal/trade");

    const fontColor = convertStylingVar(styling.fontColor);
    const backgroundColor = convertStylingVar(styling.backgroundColor);

    useEffect(() => {
        if (showOnboarding && ["open", "rejected"].includes(userStatus)) {
            toast.error("You have not completed your onboarding forms.", {
                toastId: "incomplete-onboarding",
                draggable: false,
                autoClose: false,
                position: "top-center",
                theme: "colored",
                onClick: () => navigate("/onboarding"),
                closeButton: false,
                closeOnClick: false,
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {(isTradeScreen || isTradeScreenConfigure) && (
                <ModalStyling
                    fontColor={fontColor}
                    backgroundColor={backgroundColor}
                    padding={state.trade.layoutConfigurationOptions?.padding}
                />
            )}
            {loading && <DimFullScreenLoading />}
            <Col className="flex-grow-0">
                {isTradeScreenConfigure ? (
                    <TradeScreenConfigureSidebar />
                ) : (
                    <PortalSidebar />
                )}
            </Col>
            <Col
                className={`d-flex flex-column overflow-hidden ${
                    isTradeScreen
                        ? "trade-layout"
                        : isTradeScreenConfigure
                        ? "trade-configure-layout"
                        : ""
                } `}
                style={{
                    background:
                        isTradeScreen || isTradeScreenConfigure
                            ? convertStylingVar(styling.primaryBackgroundColor)
                            : "",
                }}
            >
                <PortalTopbar />
                <div className="content-pane">
                    <Container
                        fluid
                        className={`${isTradeScreenConfigure && "h-100"} ${
                            isTradeScreen && "p-0"
                        }`}
                    >
                        {children}
                    </Container>
                </div>
            </Col>
        </>
    );
};

export default PortalLayout;
