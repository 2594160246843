import { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { v4 as uuid } from "uuid";

import { GoBackModal } from "../../../components/admin/asset-builder/GoBackModal";
import { CreateFormForm } from "../../../components/admin/onboarding/CreateFormForm";
import { FullScreenLoading } from "../../../components/Loading";
import {
    getOnboardingForms,
    updateOnboardingForms,
} from "../../../helpers/onboarding";
import { useStore } from "../../../Store";
import { useSocket } from "../../../Websocket";

const FormBuilder = () => {
    const ws = useSocket();
    const { state, dispatch } = useStore();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const {
        onboarding: { forms },
    } = state;

    const [searchParams] = useSearchParams();
    const [copiedForm, setCopiedForm] = useState({});

    const createForm = (newForm) => {
        const identifier = uuid();

        const formattedForm = {
            ...newForm,
            id: identifier,
            updatedAt: new Date().getTime(),
            groups: newForm.groups.map((group) => ({
                ...group,
                fields: group?.fields?.map((fld) => {
                    fld.showFieldDescription = undefined;
                    return fld;
                }),
            })),
        };

        return updateOnboardingForms(ws, dispatch, {
            forms: forms.concat([formattedForm]),
        }).then(() => {
            toast.success("Form successfully created");

            navigate("/admin/forms");
        });
    };

    useEffect(() => {
        if (forms.length > 0) {
            for (const entry of searchParams.entries()) {
                const [param, value] = entry;

                if (param === "copy") {
                    const fm = JSON.parse(
                        JSON.stringify(forms.find((frm) => frm.id === value))
                    );

                    delete fm.id;
                    delete fm.isPublished;

                    toast.success("Form succesfully copied");

                    setCopiedForm(fm);
                }
            }
        }
    }, [searchParams, forms]);

    useEffect(() => {
        if (forms.length === 0) {
            getOnboardingForms(ws, dispatch).finally(() => setLoading(false));
        } else {
            setLoading(false);
        }

        return () => setLoading(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [showGoBackModal, setShowGoBackModal] = useState(false);
    const handleHideGoBackModal = () => setShowGoBackModal(false);
    const handleShowGoBackModal = () => setShowGoBackModal(true);

    return (
        <div className="m-4">
            <Row noGutters className="forms-header">
                <AiOutlineArrowLeft
                    className="asset-back-button"
                    onClick={handleShowGoBackModal}
                />
                <h4>Create Form</h4>
            </Row>
            <GoBackModal
                show={showGoBackModal}
                handleClose={handleHideGoBackModal}
                handleConfirm={() => {
                    handleHideGoBackModal();
                    navigate("/admin/forms");
                }}
            />
            {loading ? (
                <FullScreenLoading />
            ) : (
                <CreateFormForm
                    existingForm={copiedForm}
                    submitData={createForm}
                />
            )}
        </div>
    );
};

export default FormBuilder;
