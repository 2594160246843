import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";

import {
    ApprovalsList,
    ApproveModal,
    DenyModal,
    SearchBars,
} from "../../../components/admin/firm/approvals";
import { ConfigureDisplayWrapper } from "../../../components/ConfigureDisplayTableWrapper";
import { getApprovals } from "../../../helpers/firm";
import { submitTransaction } from "../../../helpers/market";
import { formatResultString } from "../../../helpers/other";
import { useStore } from "../../../Store";
import { useSocket } from "../../../Websocket";

const Approvals = () => {
    const ws = useSocket();
    const { dispatch, state } = useStore();
    const [loading, setLoading] = useState(true);

    const { approvals } = state.firm;
    const [searchResults, setSearchResults] = useState([]);

    // Handle modals for approving/denying requests.
    const [showApproveModal, setShowApproveModal] = useState(false);
    const [showDenyModal, setShowDenyModal] = useState(false);
    const [managedRequest, setManagedRequest] = useState({});

    const handleCloseApproveModal = () => {
        setShowApproveModal(false);
        setManagedRequest({});
    };
    const handleOpenApproveModal = (req) => {
        setShowApproveModal(true);
        setManagedRequest(req);
    };

    const handleCloseDenyModal = () => {
        setShowDenyModal(false);
        setManagedRequest({});
    };
    const handleOpenDenyModal = (req) => {
        setShowDenyModal(true);
        setManagedRequest(req);
    };

    const handleApproveDeny = ({
        type,
        security,
        traderefno,
        approve,
        comment,
    }) =>
        submitTransaction(ws, type, {
            refno: traderefno,
            security,
            approve,
            comment,
        }).then(() => {
            const apprIndex = approvals.findIndex(
                (appr) => appr.traderefno === traderefno
            );

            approvals.splice(apprIndex, 1);

            toast.success(
                formatResultString(type) +
                    ` successfully ${approve ? "approved" : "denied"}.`
            );

            approve ? handleCloseApproveModal() : handleCloseDenyModal();
        });

    const handleFilterApprovals = (firm, userid) =>
        getApprovals(ws, dispatch, { firm, userid }).then((appr) =>
            setSearchResults(appr)
        );

    useEffect(() => {
        if (approvals.length === 0) {
            getApprovals(ws, dispatch).finally(() => setLoading(false));
        } else {
            setLoading(false);
        }

        return () => setLoading(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="m-4">
            <Row noGutters className="forms-header">
                <Col>
                    <h4>Approvals</h4>
                </Col>
            </Row>

            <ApproveModal
                show={showApproveModal}
                handleCloseModal={handleCloseApproveModal}
                handleApprove={() => handleApproveDeny(managedRequest)}
                request={managedRequest}
            />

            <DenyModal
                show={showDenyModal}
                handleCloseModal={handleCloseDenyModal}
                handleDeny={() => handleApproveDeny(managedRequest)}
                request={managedRequest}
            />

            <SearchBars searchApprovals={handleFilterApprovals} />

            <br />

            <ConfigureDisplayWrapper
                keyType={"firm_preferences"}
                optionKey={"approvals"}
                defaultOptions={[
                    "type",
                    "account",
                    "security",
                    "amount",
                    "time",
                ]}
                data={approvals}
                render={(preferredColumns) => {
                    return (
                        <ApprovalsList
                            preferredColumns={preferredColumns}
                            approvals={
                                searchResults.length > 0
                                    ? searchResults
                                    : approvals
                            }
                            openApproveModal={handleOpenApproveModal}
                            openDenyModal={handleOpenDenyModal}
                            loading={loading}
                        />
                    );
                }}
            />
        </div>
    );
};

export default Approvals;
