/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { v4 as uuid } from "uuid";

import { CompanyDocsTable } from "../../../components/admin/settings/companydocs/CompanyDocsTable";
import { DeleteCompanyDocModal } from "../../../components/admin/settings/companydocs/DeleteCompanyDocModal";
import { EditCompanyDocModal } from "../../../components/admin/settings/companydocs/EditCompanyDocModal";
import {
    deleteImageForProfile,
    uploadImageForProfile,
} from "../../../helpers/asset-builder";
import { getCompanyDocs, updateCompanyDocs } from "../../../helpers/settings";
import { useStore } from "../../../Store";
import { useSocket } from "../../../Websocket";

const CompanyDocumentsPage = () => {
    const ws = useSocket();
    const { state, dispatch } = useStore();

    const { docs } = state.settings;

    const [document, setDocument] = useState({});
    const [isEdit, setIsEdit] = useState(false);

    const [showAddEditModal, setShowAddEditModal] = useState(false);
    const handleShowAddEditModal = (doc = {}, isEdit = false) => {
        setShowAddEditModal(true);
        setDocument(doc);
        setIsEdit(isEdit);
    };
    const handleCloseAddEditModal = () => {
        setShowAddEditModal(false);
        setDocument({});
    };

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const handleShowDeleteModal = (doc) => {
        setDocument(doc);
        setShowDeleteModal(true);
    };
    const handleCloseDeleteModal = () => setShowDeleteModal(false);

    const handleCreateDocument = (doc, file, isEdit) => {
        const updateDocuments = (url) => {
            const identifier = uuid();

            const formattedDoc = {
                ...doc,
                id: isEdit ? doc?.id : identifier,
                file: isEdit && !file ? doc.file : url,
                updatedAt: new Date().getTime(),
                fileName: file?.name ?? doc.fileName,
            };

            return updateCompanyDocs(ws, dispatch, {
                docs: isEdit
                    ? docs.map((d) =>
                          d.id === formattedDoc.id ? formattedDoc : d
                      )
                    : docs.concat([formattedDoc]),
            }).then(() => {
                handleCloseAddEditModal();

                toast.success(
                    `Document successfully ${isEdit ? "updated" : "created"}.`
                );
            });
        };

        if (isEdit && file) {
            // Delete old file
            deleteImageForProfile(doc.file);
        }

        return file
            ? uploadImageForProfile(file).then(updateDocuments)
            : updateDocuments();
    };
    const handleDeleteDocument = (doc) => {
        deleteImageForProfile(doc.file).then(() => {
            const updatedDocs = docs.filter((d) => d.id !== doc.id);

            updateCompanyDocs(ws, dispatch, { docs: updatedDocs }).then(() => {
                handleCloseDeleteModal();

                toast.success("Document successfully deleted.");
            });
        });
    };

    useEffect(() => {
        if (docs.length === 0) {
            getCompanyDocs(ws, dispatch);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="m-4">
            {showAddEditModal && (
                <EditCompanyDocModal
                    show={showAddEditModal}
                    onClose={handleCloseAddEditModal}
                    doc={document}
                    type={isEdit ? "edit" : "add"}
                    handleSave={handleCreateDocument}
                />
            )}
            {showDeleteModal && (
                <DeleteCompanyDocModal
                    show={showDeleteModal}
                    onClose={handleCloseDeleteModal}
                    doc={document}
                    handleDelete={handleDeleteDocument}
                />
            )}
            <Row noGutters className="forms-header ">
                <h4>Company Documents</h4>

                <Button size="sm" onClick={() => handleShowAddEditModal()}>
                    Create
                </Button>
            </Row>
            <Row noGutters>
                <Col>
                    <CompanyDocsTable
                        docs={docs}
                        handleShowEditModal={handleShowAddEditModal}
                        handleShowDeleteModal={handleShowDeleteModal}
                    />
                </Col>
            </Row>
        </div>
    );
};

export default CompanyDocumentsPage;
