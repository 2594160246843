import { Button, ButtonGroup } from "react-bootstrap";
import { styled } from "styled-components";

import { convertStylingVar } from "../../../../helpers/other";
import { useStore } from "../../../../Store";

const BuyButton = styled(({ className }) => (
    <Button className={`${className} sell-side-button`} value="B" size="sm">
        BUY
    </Button>
))`
    &:not(.dropdown-toggle) {
        background: ${(props) => props.backgroundColor} !important;
        border-color: ${(props) => props.backgroundColor} !important;
        color: ${(props) => props.fontColor} !important;
        filter: ${(props) =>
            props.side !== "B" ? "brightness(0.65)" : "none"} !important;
        &:hover {
            background: ${(props) => props.backgroundColor} !important;
            color: ${(props) => props.fontColor} !important;
        }
    }
`;

const SellButton = styled(({ className }) => (
    <Button value="S" className={`${className} buy-side-button`} size="sm">
        SELL
    </Button>
))`
    &:not(.dropdown-toggle) {
        background: ${(props) => props.backgroundColor} !important;
        border-color: ${(props) => props.backgroundColor} !important;
        color: ${(props) => props.fontColor} !important;
        filter: ${(props) =>
            props.side !== "S" ? "brightness(0.65)" : "none"} !important;
        &:hover {
            background: ${(props) => props.backgroundColor} !important;
            color: ${(props) => props.fontColor} !important;
        }
    }
`;

export const SideButtonGroup = ({ side, setSide }) => {
    const { state } = useStore();
    const { layoutConfigurationOptions } = state.trade;

    const primaryButtonColor = convertStylingVar(
        layoutConfigurationOptions.styling.button.primaryBGColor
    );
    const secondaryButtonColor = convertStylingVar(
        layoutConfigurationOptions.styling.button.secondaryBGColor
    );
    const primaryFontColor = convertStylingVar(
        layoutConfigurationOptions.styling.button.primaryFontColor
    );
    const secondaryFontColor = convertStylingVar(
        layoutConfigurationOptions.styling.button.secondaryFontColor
    );

    return (
        <ButtonGroup
            className="btn-block pb-2 order-ticket-flex-item"
            value={side}
            onClick={(e) => setSide(e.target.value)}
        >
            <BuyButton
                backgroundColor={primaryButtonColor}
                fontColor={primaryFontColor}
                side={side}
            />
            <SellButton
                backgroundColor={secondaryButtonColor}
                fontColor={secondaryFontColor}
                side={side}
            />
        </ButtonGroup>
    );
};
