import { Button, Form, InputGroup, Modal } from "react-bootstrap";

export const DenyModal = ({
    show = false,
    handleCloseModal,
    handleDeny,
    request,
}) => {
    return (
        <Modal show={show} onHide={handleCloseModal}>
            <Modal.Header closeButton>
                <Modal.Title>Deny Request</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Are you sure you want to deny this request?
                <br />
                <br />
                <InputGroup>
                    <InputGroup.Text>Comment</InputGroup.Text>
                    <Form.Control
                        type="text"
                        as="textarea"
                        placeholder="Comment"
                        onChange={({ target }) =>
                            (request.comment = target.value)
                        }
                    />
                </InputGroup>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={() => handleDeny(request)}>
                    Deny
                </Button>
                <Button variant="light" onClick={handleCloseModal}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
