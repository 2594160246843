import { enableFirmAdditionalInfo } from "../../config";
import { isStringifiedJson } from "../../helpers/other";
import * as CONSTANTS from "../constants/firm";

export const initialFirmState = {
    existing_users: [],
    positions: [],
    transfers: [],
    approvals: [],
    firms: [],
};

export const firmReducer = (state, action) => {
    switch (action.type) {
        case CONSTANTS.QUERY_EXISTING_USERS:
            return {
                ...state,
                existing_users: action.data,
            };
        case CONSTANTS.GET_FIRMS:
            return {
                ...state,
                firms: enableFirmAdditionalInfo ? action.firms.map((firmObj) => ({ ...firmObj, ...isStringifiedJson(firmObj.description) })) : action.firms,
            };
        case CONSTANTS.QUERY_APPROVALS: {
            return {
                ...state,
                approvals: state.approvals.concat(action.approvals),
            };
        }
        case CONSTANTS.UPDATE_EXISTING_USER: {
            return {
                ...state,
                existing_users: state.existing_users.map((user) =>
                    user.userid === action.user.userid ? action.user : user
                ),
            };
        }
        case CONSTANTS.CLEAR_STATE:
            return initialFirmState;
        default:
            return state;
    }
};
