import * as CONSTANTS from "../constants/market";

export const queryMultipleOrders = (orders) => ({
    type: CONSTANTS.QUERY_MULTIPLE_ORDERS,
    orders,
});

export const queryCancels = (cancels) => ({
    type: CONSTANTS.QUERY_CANCELS,
    cancels,
});

export const queryActivityTrade = (fills) => ({
    type: CONSTANTS.QUERY_ACTIVITY_TRADE,
    fills,
});

export const queryDeposit = (balances) => ({
    type: CONSTANTS.QUERY_DEPOSIT,
    balances,
});

export const getDepositSymbols = (symbols) => ({
    type: CONSTANTS.GET_DEPOSIT_SYMBOLS,
    symbols,
});

export const updateSymbolStatus = (symbols) => ({
    type: CONSTANTS.UPDATE_SYMBOL_STATUS,
    symbols,
});

export const updatePairWithNewSymbol = (symbol, tradeSymbols) => ({
    type: CONSTANTS.UPDATE_PAIRS_WITH_NEW_SYMBOL,
    symbol,
    tradeSymbols,
});

export const removePairsSymbol = (symbol, pair) => ({
    type: CONSTANTS.REMOVE_PAIRS_SYMBOL,
    symbol,
    pair,
});

export const appendSymbol = (symbol) => ({
    type: CONSTANTS.APPEND_SYMBOL,
    symbol,
});

export const editSymbol = (symbol) => ({
    type: CONSTANTS.EDIT_SYMBOL,
    symbol,
});

export const deleteSymbol = (security) => ({
    type: CONSTANTS.DELETE_SYMBOL,
    security,
});

export const getTradeFileData = (data, date) => ({
    type: CONSTANTS.GET_TRADE_FILE_DATA,
    data,
    date,
});

export const clearState = () => ({
    type: CONSTANTS.CLEAR_STATE,
});
