/* eslint-disable react/display-name */
import { memo, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";

import { FieldGroup } from "./FieldGroup";
import { FormPreviewModal } from "./FormPreviewModal";

// const defaultFieldConfig = {
//     fieldName: "",
//     fieldType: "String",
//     fieldDescription: "<p></p>",
//     required: false,
//     showFieldDescription: false,
// };

const brassicaFields = [
    {
        fieldName: "Date Of Birth",
        reject: [],
        fieldDescription: "<p></p>",
        fieldType: "Date",
        required: true,
    },
    {
        fieldName: "Country",
        reject: [],
        options: ["United States"],
        fieldDescription: "<p></p>",
        fieldType: "Dropdown",
        value: "United States",
        required: true,
    },
    {
        fieldName: "Street Address",
        fieldDescription: "<p></p>",
        fieldType: "String",
        required: true,
    },
    {
        fieldName: "City",
        fieldDescription: "<p></p>",
        fieldType: "String",
        required: true,
    },
    {
        fieldName: "State",
        fieldDescription:
            "<p>Please enter the two code abbreviation for your state, example: <strong>NY</strong></p>",
        fieldType: "String",
        required: true,
    },
    {
        fieldName: "Zip Code",
        reject: [],
        fieldDescription: "<p></p>",
        fieldType: "Number",
        required: true,
    },
    {
        fieldName: "Primary Phone",
        fieldDescription:
            "<p>Please enter your <u>Primary Phone</u> in the following format: <strong>XXXXXXXXXX</strong></p>",
        fieldType: "String",
        required: true,
    },
    {
        fieldName: "SSN",
        reject: [],
        fieldDescription:
            "<p>Please enter your <u>Social Security Number</u> in the following format: <strong>XXX-XX-XXXX</strong></p>",
        fieldType: "String",
        value: "",
        required: true,
    },
];

const defaultGroup = {
    name: "",
    fields: [],
};

export const CreateFormForm = memo(
    ({
        existingForm = undefined,
        submitData,
        type = "add",
        disableEditing,
    }) => {
        const [form, setForm] = useState({
            name: "",
            isPublished: false,
            onSubmit: "manual",
            groups: existingForm?.groups || [
                {
                    ...defaultGroup,
                    fields: existingForm?.fields || [],
                },
            ],
            ...existingForm,
            fields: undefined,
        });

        const isFormDisabled = form.isPublished || form.onSubmit === "brassica";

        const [showFormPreviewModal, setShowFormPreviewModal] = useState(false);
        const handleHideFormPreviewModal = () => setShowFormPreviewModal(false);
        const handleShowFormPreviewModal = () => setShowFormPreviewModal(true);

        const setIndex = (from, to) => {
            const newGroups = form.groups;
            const group = newGroups.splice(from, 1)[0];

            newGroups.splice(to, 0, group);

            setForm({ ...form, groups: newGroups });
        };

        return (
            <>
                {showFormPreviewModal && (
                    <FormPreviewModal
                        show={showFormPreviewModal}
                        handleClose={handleHideFormPreviewModal}
                        form={form}
                    />
                )}
                <Row noGutters>
                    <Form.Label className="size-sm">Form Name</Form.Label>
                </Row>
                <Row noGutters>
                    <Col>
                        <Form.Control
                            name="name"
                            onChange={({ target: { value } }) =>
                                setForm({ ...form, name: value })
                            }
                            value={form.name}
                            size="sm"
                            disabled={form.isPublished}
                        />
                    </Col>
                    <Col>
                        <Form.Label
                            className="size-sm"
                            style={{
                                position: "absolute",
                                top: "-1.75rem",
                                left: "0.5rem",
                            }}
                        >
                            Set manual or automatic:
                        </Form.Label>
                        <select
                            className="custom-select custom-select-sm w-50 ml-2"
                            onChange={({ target: { value } }) => {
                                setForm({
                                    ...form,
                                    onSubmit: value,
                                    groups:
                                        value === "brassica"
                                            ? [
                                                  {
                                                      ...defaultGroup,
                                                      fields: brassicaFields,
                                                  },
                                              ]
                                            : form.groups,
                                });
                            }}
                            defaultValue={form.onSubmit}
                            disabled={form.isPublished}
                        >
                            <option value="manual">Manual</option>
                            <option value="automatic">Automatic</option>
                            <option value="brassica">Brassica</option>
                        </select>
                    </Col>
                    <Col className="text-right" xs={4}>
                        <Button
                            size="sm"
                            onClick={() =>
                                setForm({
                                    ...form,
                                    groups: [...form.groups, defaultGroup],
                                })
                            }
                            disabled={isFormDisabled}
                        >
                            Add Group
                        </Button>
                        <Button
                            size="sm"
                            className="mx-1"
                            onClick={handleShowFormPreviewModal}
                        >
                            Preview
                        </Button>
                        <Button
                            size="sm"
                            onClick={() => {
                                // setForm({
                                //     ...form,
                                //     isPublished: !form.isPublished,
                                // });
                                submitData({
                                    ...form,
                                    isPublished: !form.isPublished,
                                });
                            }}
                            disabled={
                                form.groups.length === 0 || disableEditing
                            }
                        >
                            {!form.isPublished ? "Publish" : "Unpublish"} & Save
                        </Button>
                        <Button
                            size="sm"
                            className="ml-1"
                            onClick={() => submitData(form)}
                            disabled={
                                form.groups.length === 0 || disableEditing
                            }
                        >
                            {type === "add" ? "Create" : "Save"} Form
                        </Button>
                    </Col>
                </Row>
                <hr />
                {form.groups.map((group, key) => (
                    <FieldGroup
                        key={key}
                        index={key}
                        length={form.groups.length}
                        setIndex={setIndex}
                        group={group}
                        setGroup={(newGroup) => {
                            setForm({
                                ...form,
                                groups: form.groups.map((g, i) =>
                                    i === key ? newGroup : g
                                ),
                            });
                        }}
                        deleteGroup={() =>
                            setForm({
                                ...form,
                                groups: form.groups.filter((g, i) => i !== key),
                            })
                        }
                        disabled={isFormDisabled}
                    />
                ))}
                {/* {form.fields.length > 0 && (
                    <Fields
                        section={form}
                        disabled={isFormDisabled}
                        setSection={setForm}
                        onboarding
                    />
                )} */}
            </>
        );
    }
);
