/* eslint-disable react/display-name */
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import { memo } from "react";
import BootstrapTable from "react-bootstrap-table-next";

export const Responses = memo(({ responses }) => {
    const columns = [
        {
            dataField: "tif",
            text: "Tif",
            sort: true,
        },
        {
            dataField: "firm",
            text: "Firm",
            sort: true,
        },
        {
            dataField: "refno",
            text: "Ref No.",
            sort: true,
        },
        {
            dataField: "security",
            text: "Symbol",
            sort: true,
        },
        {
            dataField: "side",
            text: "Side",
            sort: true,
        },
        {
            dataField: "price",
            text: "Price",
            sort: true,
        },
        {
            dataField: "qty",
            text: "Quantity",
            sort: true,
        },
        {
            dataField: "ordertype",
            text: "Order Type",
            sort: true,
        },
    ];

    return responses.length === 0 ? (
        <></>
    ) : (
        <BootstrapTable
            keyField="refno"
            data={responses}
            columns={columns}
            striped
            wrapperClasses="size-sm"
        />
    );
});
