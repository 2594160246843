/* eslint-disable no-unused-vars */
import { QRCodeSVG } from "qrcode.react";
import { useEffect, useState } from "react";
import { Button, Col, FormControl, FormLabel, Row } from "react-bootstrap";

import { clientName } from "../../config";
import { create2faseed, set2fa, verify2faToken } from "../../helpers/profile";
import { useStore } from "../../Store";
import { useSocket } from "../../Websocket";
import { Loading } from "../Loading";

export const TwoFA = ({ userid, use2fa }) => {
    const ws = useSocket();
    const { dispatch, state } = useStore();

    const { publicKey } = state.account;

    const [setupProcess, setSetupProcess] = useState({
        secret: null,
        password: "",
        code: "",
        step: 0,
        loading: false,
    });
    // step 0: Waiting for user to input password
    // step 0.5: Submit and wait for seed
    // step 1: display qr code and wait for user to enter verification code
    // step 1.5: enable 2fa
    // step 2: DONE

    useEffect(() => {
        // use2fa === "Y" start process
        // use2fa === "N" remove 2fa from account
        use2fa === "N" ? set2fa(ws, dispatch, userid, "N") : null;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [use2fa]);

    const handleOnChange = ({ target: { name, value } }) =>
        setSetupProcess({
            ...setupProcess,
            [name]: value,
        });

    const start2FAProcess = async () => {
        setSetupProcess({ ...setupProcess, loading: true });
        try {
            const resp = await create2faseed(
                ws,
                publicKey,
                userid,
                setupProcess.password
            );
            setSetupProcess({
                ...setupProcess,
                secret: resp["2faseed"],
                loading: false,
                step: 1,
            });
        } catch (err) {
            setSetupProcess({
                ...setupProcess,
                loading: false,
                step: 0,
            });
        }
    };

    const complete2FAProcess = async () => {
        setSetupProcess({ ...setupProcess, loading: true });
        verify2faToken(ws, userid, setupProcess.code)
            .then((resp) => {
                set2fa(ws, dispatch, userid, "Y").then((resp2) => {
                    setSetupProcess({
                        ...setupProcess,
                        loading: false,
                        step: 2,
                    });
                });
            })
            .catch(() => {
                setSetupProcess({
                    ...setupProcess,
                    loading: false,
                    step: 1,
                });
            });
    };

    const renderStepZero = () => {
        return (
            <Row>
                <Col xs={12} className="mt-4">
                    <Col className="mt-2">
                        <FormLabel>Current Password</FormLabel>
                        <FormControl
                            type="password"
                            name="password"
                            onChange={handleOnChange}
                            required
                            value={setSetupProcess.password}
                        />
                    </Col>
                    <Col className="mt-4 d-flex justify-content-end">
                        <Button
                            onClick={() => {
                                start2FAProcess();
                            }}
                        >
                            Submit
                        </Button>
                    </Col>
                </Col>
            </Row>
        );
    };

    const renderStepOne = () => {
        return (
            <Row>
                <Col>
                    <QRCodeSVG
                        value={
                            "otpauth://totp/" +
                            userid +
                            "?secret=" +
                            setupProcess.secret +
                            `&issuer=${clientName}`
                        }
                    />
                </Col>
                <Col xs={12} className="mt-4">
                    <Col className="mt-2">
                        <FormLabel>Auth code</FormLabel>
                        <FormControl
                            type="text"
                            name="code"
                            onChange={handleOnChange}
                            required
                            value={setSetupProcess.code}
                        />
                    </Col>
                    <Col className="mt-4 d-flex justify-content-end">
                        <Button
                            onClick={() => {
                                complete2FAProcess();
                            }}
                        >
                            Submit
                        </Button>
                    </Col>
                </Col>
            </Row>
        );
    };

    return (
        <Row className="mt-4">
            <Col xs={12}>
                <h5>Set 2FA</h5>
            </Col>
            {use2fa === "Y" ? (
                setupProcess.loading ? (
                    <Loading />
                ) : setupProcess.step === 0 ? (
                    renderStepZero()
                ) : setupProcess.step === 1 ? (
                    renderStepOne()
                ) : setupProcess.step === 2 ? (
                    <Col>Completed</Col>
                ) : null
            ) : (
                <Col>Disabled</Col>
            )}
        </Row>
    );
};
