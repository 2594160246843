import { Tab, Tabs } from "react-bootstrap";

import {
    enableCustodianSelection,
    enableUserAccounts,
} from "../../../../config";
import { DefaultOrderTicketConfig } from "../../../../preferences";
import { useStore } from "../../../../Store";
import { AccountSelect } from "./AccountSelect";
import { Broker } from "./Broker";
import { ClientOrderId } from "./ClientOrderId";
import { CustodianSelect } from "./CustodianSelect";
import { OrderFormControl } from "./OrderFormControl";
import { OrderTifTypeDropdownRenderer } from "./OrderTifTypeDropdownRenderer";
import { RenderDropdown } from "./RenderDropdown";
import { SideButtonGroup } from "./SideButtonGroup";

export const OrderTicketBasic = ({
    children,
    tabKey,
    setTabKey,
    currentOrder,
    onChangeCurrentOrder,
    currentSymbol,
    resetOrder,
    currency,
    security,
    custodian,
    setCustodian,
}) => {
    const { state } = useStore();

    const orderTicketConfig =
        state.account.account.data.config_setting?.attr?.order_ticket ||
        DefaultOrderTicketConfig;

    const { firms } = state.firm;

    const accounts =
        state.account?.account?.data?.restricted_attr?.access_list || [];

    return (
        <Tabs
            className="trade-tab mb-2 size-sm order-ticket-basic"
            activeKey={tabKey}
            onSelect={(tab) => {
                resetOrder(tab);
                setTabKey(tab);
            }}
        >
            {Object.entries(orderTicketConfig).map(
                ([tab, keys]) =>
                    keys.display && (
                        <Tab
                            key={tab}
                            eventKey={tab}
                            title={tab}
                            className="order-ticket-flex-parent"
                        >
                            <>
                                {/* Button Group To Decide Buy/Sell */}
                                <SideButtonGroup
                                    side={currentOrder?.side}
                                    setSide={(val) =>
                                        onChangeCurrentOrder("side", val)
                                    }
                                />
                                {/* Amount Form Control */}
                                <OrderFormControl
                                    label="Quantity"
                                    value={currentOrder?.qty}
                                    setValue={(val) =>
                                        onChangeCurrentOrder("qty", val)
                                    }
                                    symbol={currentSymbol}
                                    currencyLabel={security?.type}
                                />
                                {/* Price Form Control */}
                                <OrderFormControl
                                    label="Price"
                                    value={currentOrder?.price}
                                    setValue={(val) =>
                                        onChangeCurrentOrder("price", val)
                                    }
                                    symbol={currentSymbol}
                                    currencyLabel={currency?.type}
                                />
                                {/* Order Type Input */}
                                {orderTicketConfig[tabKey]?.type && (
                                    <OrderTifTypeDropdownRenderer
                                        currentOrder={currentOrder}
                                        onChange={onChangeCurrentOrder}
                                        tabConfig={
                                            orderTicketConfig[tabKey].type
                                        }
                                        symbol={currentSymbol}
                                        currency={currency}
                                        security={security}
                                        renderType="ordertype"
                                        typeLabel="Type"
                                    />
                                )}
                                {/* Order Tif Input */}
                                {orderTicketConfig[tabKey]?.tif && (
                                    <OrderTifTypeDropdownRenderer
                                        currentOrder={currentOrder}
                                        onChange={onChangeCurrentOrder}
                                        tabConfig={
                                            orderTicketConfig[tabKey].tif
                                        }
                                        symbol={currentSymbol}
                                        currency={currency}
                                        security={security}
                                        renderType="tif"
                                        typeLabel="TIF"
                                    />
                                )}
                                {/* Custodian Select */}
                                {enableCustodianSelection && (
                                    <CustodianSelect
                                        custodian={custodian}
                                        setCustodian={setCustodian}
                                    />
                                )}

                                {/* Broker Input */}
                                {orderTicketConfig[tabKey]?.brokers
                                    ?.display && (
                                    <Broker
                                        firms={firms}
                                        orderTicket={orderTicketConfig}
                                        tabKey={tabKey}
                                        broker={currentOrder?.broker}
                                        setBroker={(val) =>
                                            onChangeCurrentOrder("brokers", val)
                                        }
                                    />
                                )}
                                {/* Account Input */}
                                {orderTicketConfig[tabKey]?.account?.display &&
                                    enableUserAccounts && (
                                        <AccountSelect
                                            options={accounts}
                                            account={currentOrder?.account}
                                            setAccount={(val) =>
                                                onChangeCurrentOrder(
                                                    "account",
                                                    val
                                                )
                                            }
                                        />
                                    )}
                                {/* Capacity */}
                                {orderTicketConfig[tabKey]?.capacity
                                    ?.display && (
                                    <RenderDropdown
                                        options={
                                            orderTicketConfig[tabKey]?.capacity
                                                ?.options
                                        }
                                        displayName={
                                            orderTicketConfig[tabKey]?.capacity
                                                .displayName
                                        }
                                        val={currentOrder.capacity || ""}
                                        setDropdown={(val) =>
                                            onChangeCurrentOrder(
                                                orderTicketConfig[tabKey]
                                                    ?.capacity.fieldName,
                                                val
                                            )
                                        }
                                    />
                                )}
                                {/* Client Order Id Input */}
                                {orderTicketConfig[tabKey]?.clientOrderId
                                    ?.display && (
                                    <ClientOrderId
                                        tabKey={tabKey}
                                        orderTicket={orderTicketConfig}
                                        setClientOrderId={(val) =>
                                            onChangeCurrentOrder(
                                                "clientOrderId",
                                                val
                                            )
                                        }
                                    />
                                )}
                            </>
                            {children}
                        </Tab>
                    )
            )}
        </Tabs>
    );
};
