/* eslint-disable react/display-name */
import { memo, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Button, Modal, Row } from "react-bootstrap";

import { formatDisplayTextForConfigure } from "../helpers/display-options";

export const ConfigureSequence = memo(
    ({
        show = false,
        handleCloseModal,
        options,
        saveColumns,
        enableNewTextFormatting = false,
    }) => {
        const [columns, setColumns] = useState([]);

        useEffect(() => {
            setColumns(Object.keys(options).filter((opt) => options[opt]));
        }, [options]);

        const handleOnDragEnd = (result) => {
            if (!result.destination) return;
            const items = Array.from(columns);
            const [reorderedItem] = items.splice(result.source.index, 1);
            items.splice(result.destination.index, 0, reorderedItem);
            setColumns(items);
        };

        return (
            <Modal show={show} onHide={handleCloseModal}>
                <Modal.Header closeButton>Configure Sequence</Modal.Header>
                <Modal.Body>
                    <Row className="mx-2 mb-2 text-gray">
                        Drag and drop to rearrange columns.
                    </Row>
                    <DragDropContext onDragEnd={handleOnDragEnd}>
                        <Droppable droppableId="symbol-sequence">
                            {(provided) => (
                                <ul
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    style={{
                                        columns: "200px 1",
                                        listStyle: "none",
                                    }}
                                >
                                    {columns.map((column, index) => (
                                        <Draggable
                                            key={column}
                                            draggableId={column}
                                            index={index}
                                        >
                                            {(provided) => (
                                                <li
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <Row
                                                        style={{
                                                            border: "1px solid",
                                                            padding: "5px 10px",
                                                            borderRadius: "4px",
                                                            marginRight: "15px",
                                                            marginBottom: "5px",
                                                        }}
                                                    >
                                                        {enableNewTextFormatting
                                                            ? formatDisplayTextForConfigure(
                                                                  column
                                                              )
                                                            : column}
                                                    </Row>
                                                </li>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </ul>
                            )}
                        </Droppable>
                    </DragDropContext>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="dark"
                        onClick={() => saveColumns(columns)}
                        size="sm"
                    >
                        Save
                    </Button>
                    <Button
                        variant="light"
                        onClick={handleCloseModal}
                        size="sm"
                    >
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
);
