import { InputTemplate } from "./InputTemplate";

export const ParagraphInput = ({ field, onChange, classes, disabled }) => (
    <InputTemplate
        field={field}
        onChange={onChange}
        as="textarea"
        rows={4}
        classes={classes}
        disabled={disabled}
    />
);
