import { memo, useEffect, useState } from "react";
import { Col, FormControl, Row } from "react-bootstrap";

import { formatTableColumn } from "../../../helpers/other";
import { getOpenOrders } from "../../../helpers/risk";
import { useStore } from "../../../Store";
import { useSocket } from "../../../Websocket";
import { OrderDetailModal } from "../../portal/OrderDetailModal";
import { TableRenderer } from "../../portal/TableRenderer";

// eslint-disable-next-line react/display-name
export const InvestigateOpenOrdersTable = memo(
    ({ trader, symbol, setSymbol, firm }) => {
        const ws = useSocket();
        const { state } = useStore();

        const {
            trade: { symbols },
        } = state;

        const [openOrders, setOpenOrders] = useState([]);

        const [selectedOrder, setSelectedOrder] = useState(null);
        const [showOrderDetailModal, setShowOrderDetailModal] = useState(false);
        const handleOpenOrderDetailModal = () => setShowOrderDetailModal(true);
        const handleCloseOrderDetailModal = () =>
            setShowOrderDetailModal(false);

        useEffect(
            () => {
                if (trader) {
                    getOpenOrders(ws, trader, symbol, firm).then((orders) =>
                        setOpenOrders(orders)
                    );
                }
            },
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [symbol, trader]
        );

        return (
            <>
                <Row noGutters>
                    <Col xs={3} className="mt-1">
                        Symbol:
                    </Col>
                    <Col xs={3}>
                        {symbols.length > 0 && (
                            <FormControl
                                as="select"
                                size="sm"
                                className=""
                                onChange={({ target: { value } }) =>
                                    setSymbol(value)
                                }
                                value={symbol}
                                disabled={!trader}
                            >
                                <option value="">Select a symbol...</option>
                                {symbols.map(({ security }, i) => (
                                    <option key={i} value={security}>
                                        {security}
                                    </option>
                                ))}
                            </FormControl>
                        )}
                    </Col>
                </Row>
                <br />
                <Row noGutters className="mb-3">
                    <h6>
                        <b>Open Orders</b>
                    </h6>
                </Row>
                <OrderDetailModal
                    show={showOrderDetailModal}
                    selectedOrder={selectedOrder}
                    handleClose={handleCloseOrderDetailModal}
                />
                <TableRenderer
                    keyField="refno"
                    wrapperClasses="risk-investigate-table"
                    data={openOrders}
                    columns={[
                        {
                            dataField: "refno",
                            text: "Ref no.",
                            sort: true,
                            formatter: (rowContent, row) => {
                                return (
                                    <span
                                        aria-hidden="true"
                                        style={{
                                            paddingBottom: "2px",
                                            borderBottom: "1px solid",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => {
                                            handleOpenOrderDetailModal();
                                            setSelectedOrder(row);
                                        }}
                                    >
                                        {row.refno}
                                    </span>
                                );
                            },
                        },
                        ...[
                            "security",
                            "side",
                            "price",
                            "qty",
                            "updtime",
                        ].map((col) => formatTableColumn(col)),
                    ]}
                    noDataIndication="No orders found."
                />
            </>
        );
    }
);
