import { toast } from "react-toastify";

import request from "../apiRequest";
import * as ACTIONS from "../contexts/actions/asset-builder";
import { sendMessageQuery } from "./websocket";

export const getPublishedAssetProfiles = (dispatch) =>
    request("/public/profiles", "GET").then(({ data }) =>
        dispatch(ACTIONS.getPublicProfiles(data))
    );

export const getAssetTemplatesProfiles = (ws, dispatch) =>
    sendMessageQuery(
        ws,
        "querybankinfo",
        {
            userid: "AssetData",
            bank_type: "assets",
        },
        true
    )
        .then((resp) => {
            const { templates, profiles } = JSON.parse(resp.bank_detail);

            dispatch(ACTIONS.queryAssetTemplates(templates || []));
            dispatch(ACTIONS.queryAssetProfiles(profiles || []));

            return { templates, profiles };
        })
        .catch(() => {});

export const updateAssetTemplateProfile = (
    ws,
    dispatch,
    { templates, profiles }
) =>
    sendMessageQuery(ws, "updatebankinfo", {
        bank_type: "assets",
        userid: "AssetData",
        bank_detail: {
            templates: templates,
            profiles: profiles,
        },
    }).then(() => getAssetTemplatesProfiles(ws, dispatch));

export const uploadImageForProfile = async (image) => {
    if (!image || typeof image === "string")
        return typeof image === "string" ? image : undefined;

    const imgFormData = new FormData();
    imgFormData.append("file", image);

    const imageUrl = await request(
        "/file/uploadFile",
        "POST",
        imgFormData,
        false,
        true
    ).catch((err) => toast.error(err.message));

    return imageUrl.url.toString();
};

export const deleteImageForProfile = (image) => {
    // eslint-disable-next-line no-undef
    if (!image) return new Promise((resolve) => resolve(undefined));

    return request("/file/deleteFile", "POST", {
        url: image,
    });
};

export const setSymbolProfileExists = (
    symbols,
    newProfile,
    profileBeforeChanges
) => {
    const originalSymbol = symbols.find(
        (sym) => sym.security === profileBeforeChanges.symbol
    );
    const toEditSymbol = symbols.find(
        (sym) => sym.security === newProfile.symbol
    );

    const profileCanExist = newProfile.symbol && newProfile.isPublished;

    return [
        {
            ...toEditSymbol,
            fractionbase:
                toEditSymbol?.fractionbase && `${toEditSymbol?.fractionbase}`,
            priceprecision:
                toEditSymbol?.priceprecision &&
                `${toEditSymbol?.priceprecision}`,
            attr: {
                ...toEditSymbol?.attr,
                profile_exists:
                    profileCanExist && !toEditSymbol?.attr?.profile_exists
                        ? profileCanExist
                        : toEditSymbol?.attr?.profile_exists,
                numberOfShares: newProfile?.numberOfShares,
            },
        },
        profileBeforeChanges &&
            newProfile.symbol !== profileBeforeChanges.symbol && {
                ...originalSymbol,
                fractionbase:
                    originalSymbol?.fractionbase &&
                    `${originalSymbol?.fractionbase}`,
                priceprecision:
                    originalSymbol?.priceprecision &&
                    `${originalSymbol?.priceprecision}`,
                attr: {
                    ...originalSymbol?.attr,
                    profile_exists: false,
                    numberOfShares: undefined,
                },
            },
    ];
};

export const remapImageFields = (sections) =>
    // eslint-disable-next-line no-undef
    Promise.all(
        sections.map(async (sect) => {
            const { fields } = sect;

            const mappedFields = [];

            for (let fld of fields) {
                if (["Image Upload", "File Upload"].includes(fld.fieldType)) {
                    const imageUrl = await uploadImageForProfile(fld.value);

                    fld.value = imageUrl;
                } else if (fld.fieldType === "Subsection") {
                    for (let subfield of fld.subfields) {
                        if (
                            ["Image Upload", "File Upload"].includes(
                                subfield.fieldType
                            )
                        ) {
                            const subfieldImage = await uploadImageForProfile(
                                subfield.value
                            );

                            subfield.value = subfieldImage;
                        }
                    }
                }

                mappedFields.push(fld);
            }

            return {
                ...sect,
                fields: mappedFields,
            };
        })
    );

export const deleteAllFieldImages = (sections) =>
    // eslint-disable-next-line no-undef
    Promise.all(
        sections.map(async (sect) => {
            const { fields } = sect;

            for (let fld of fields) {
                if (["Image Upload", "File Upload"].includes(fld.fieldType)) {
                    deleteImageForProfile(fld.value);
                } else if (fld.fieldType === "Subsection") {
                    for (let subfld of fld.subfields) {
                        ["Image Upload", "File Upload"].includes(
                            subfld.fieldType
                        ) && deleteImageForProfile(subfld.value);
                    }
                }
            }
        })
    );

export const compareFieldObjectImages = (originalSection, updatedSection) => {
    updatedSection.fields.forEach((field, i) => {
        if (["Image Upload", "File Upload"].includes(field.fieldType)) {
            const originalFieldToCompare = originalSection.fields[i];

            if (originalFieldToCompare.value !== field.value) {
                deleteImageForProfile(originalFieldToCompare.value);

                uploadImageForProfile(field.value);
            }
        } else if (field.fieldType === "Subsection") {
            field.subfields.forEach((subfield, j) => {
                if (
                    ["Image Upload", "File Upload"].includes(subfield.fieldType)
                ) {
                    const originalSubfieldToCompare =
                        originalSection.fields[i].subfields[j];

                    if (originalSubfieldToCompare.value !== subfield.value) {
                        deleteImageForProfile(originalSubfieldToCompare.value);

                        uploadImageForProfile(subfield.value);
                    }
                }
            });
        }
    });
};

export const getSymbolLastPrice = (symbol) =>
    request(`/public/prices?symbol=${symbol}`, "GET").then(({ data }) => data);
