import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";

import { ConfigureModalTemplate } from "./ConfigureModalTemplate";

export const SubsectionConfigureModal = ({
    show = false,
    handleSave,
    fieldTypes,
    existingSubfields = [],
}) => {
    const [subfields, setSubfields] = useState(existingSubfields);

    return (
        <ConfigureModalTemplate
            show={show}
            handleConfirm={() =>
                setSubfields([
                    ...subfields,
                    {
                        fieldName: "",
                        fieldType: "String",
                    },
                ])
            }
            handleClose={() => handleSave("subfields", subfields)}
            confirmText="Add Subfield (max of 4)"
            disabled={subfields.length === 4}
            body={
                <>
                    {subfields.map((opt, i) => (
                        <Row noGutters key={i} className="my-2">
                            <Col md={5}>
                                <Form.Control
                                    defaultValue={opt?.fieldName}
                                    onChange={({ target: { value } }) => {
                                        const newOptions = subfields;

                                        newOptions[i].fieldName = value;

                                        setSubfields(newOptions);
                                    }}
                                    size="sm"
                                />
                            </Col>
                            <Col md={5}>
                                <select
                                    className="custom-select custom-select-sm mx-1"
                                    onChange={({ target: { value } }) => {
                                        const newOptions = subfields;
                                        newOptions[i].fieldType = value;

                                        setSubfields(newOptions);
                                    }}
                                    defaultValue={opt?.fieldType}
                                >
                                    {fieldTypes.map((opt, i) => (
                                        <option key={i} value={opt}>
                                            {opt}
                                        </option>
                                    ))}
                                </select>
                            </Col>
                            <Col className="ml-2">
                                <Button
                                    variant="danger"
                                    size="sm"
                                    onClick={() =>
                                        setSubfields(
                                            subfields.filter(
                                                (item, y) => y !== i
                                            )
                                        )
                                    }
                                >
                                    Delete
                                </Button>
                            </Col>
                        </Row>
                    ))}
                </>
            }
        />
    );
};
