import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";

import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import AppRoutes from "./Routes";
import { StoreProvider } from "./Store";

const App = () => {
    return (
        <StoreProvider>
            <div className="app">
                <ToastContainer hideProgressBar={true} autoClose={3000} />
                <Router>
                    <AppRoutes />
                </Router>
            </div>
        </StoreProvider>
    );
};

export default App;
