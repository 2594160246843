import { createContext, useContext, useEffect, useRef } from "react";

import { useUnsecureWebsocket } from "./config";
import establishWebsocketConnection from "./helpers/websocket";
import { useStore } from "./Store";
import { useSubStore } from "./SubscriptionData"; // Import your DataContext

const WebSocketContext = createContext(null);

export const useSocket = () => useContext(WebSocketContext);

export const WebsocketProvider = ({ isLoggedIn, roles, children }) => {
    const { state, dispatch } = useStore();
    const subStore = useSubStore();
    const ws = useRef();

    const roleExists = Object.keys(roles).some((role) => roles[role]);

    useEffect(() => {
        if (
            isLoggedIn &&
            (roleExists || useUnsecureWebsocket) &&
            !ws.current?.socket
        ) {
            ws.current = {
                socket: establishWebsocketConnection(state, dispatch, subStore),
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn, roles]);

    return !isLoggedIn ? (
        <>{children}</>
    ) : (
        <WebSocketContext.Provider value={ws.current}>
            {children}
        </WebSocketContext.Provider>
    );
};
