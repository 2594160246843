export const StatisticItem = ({ name, value }) => {
    return (
        <>
            <div className="d-flex flex-column text-nowrap">
                <b>{name}</b>
                <span>{value || "N/A"}</span>
            </div>
            <div
                style={{
                    width: "2px",
                    backgroundColor: "rgba(0, 0, 0, 0.25)",
                    margin: "0px 10px",
                }}
            />
        </>
    );
};
