import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";

import { tradeDestinations } from "../../../../config";
import { formatTableColumn } from "../../../../helpers/other";
import { useStore } from "../../../../Store";
import { TableRenderer } from "../../../portal/TableRenderer";

export const TransactionDetailModal = ({
    show = false,
    handleClose,
    transactions = [],
    refno,
    isAdmin,
}) => {
    const { state } = useStore();
    const {
        account: { account: loggedInUser },
    } = state;
    const [showTransactions, setShowTransactions] = useState([]);
    const [initialOrder, setInitialOrder] = useState({});

    const mappedColumns = [
        "refno",
        "rootref",
        "security",
        "price",
        "qty",
        "execqty",
        "side",
        "tif",
        "enttime",
    ].map((col) => formatTableColumn(col));

    useEffect(() => {
        if (transactions.length > 0) {
            const initial = transactions
                .filter((transaction) =>
                    tradeDestinations.lenght > 0
                        ? transaction.category === "STAGE"
                        : true
                )
                .sort((a, b) => b.enttime < a.enttime);

            setInitialOrder(initial);

            setShowTransactions(
                transactions.filter((transaction) =>
                    loggedInUser.data.userid !== initial.userid
                        ? transaction.refno === refno
                        : JSON.stringify(transaction) !==
                              JSON.stringify(initial) &&
                          transaction.userid === loggedInUser.data.userid
                )
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transactions]);

    return (
        <Modal size="xl" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Transaction Detail{" : "}
                    {isAdmin ? initialOrder?.userid || "" : ""}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h5 className="pb-3">Initial Order: </h5>
                <TableRenderer
                    keyField="refno"
                    data={initialOrder}
                    columns={mappedColumns}
                    noDataIndication="No Transactions Available"
                    bordered={false}
                    hover={false}
                    wrapperClasses="orders-table size-sm"
                />
                <h5 className="pb-3">Transaction(s): </h5>
                <TableRenderer
                    keyField="refno"
                    data={showTransactions}
                    columns={mappedColumns}
                    noDataIndication="No Transactions Available"
                    bordered={false}
                    hover={false}
                    wrapperClasses="orders-table size-sm"
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="light" onClick={handleClose} size="sm">
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
