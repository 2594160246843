import { useEffect, useRef } from "react";
import { Modal, Row } from "react-bootstrap";

// import { AiOutlineFile, AiOutlineFilePdf } from "react-icons/ai";
import { formatTimestamp } from "../../helpers/other";

export const AnnouncementDetailModal = ({ template, show, handleClose }) => {
    const templateBody = useRef();


    useEffect(() => {
        if (templateBody.current) {
            templateBody.current.innerHTML = template?.body || "";
        }
    }, [template]);

    return (
        <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>{template?.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row noGutters ref={templateBody}></Row>
                <div className="size-sm mb-2">
                    <b>Attachments:</b>
                </div>
                <Row noGutters>
                    {template?.attachments?.map((attachment, index) => {
                        // const urlFileExtension = attachment.url
                        //     .split(".")
                        //     .pop();
                        // const isPdf = urlFileExtension === "pdf";
                        // const isImage = ["jpg", "jpeg", "png"].includes(
                        //     urlFileExtension
                        // );

                        return (
                            <div key={index} className="w-100">
                                <a
                                    href={attachment.url}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="d-flex justify-content-between"
                                    style={{ height: "50px" }}
                                >
                                    <div>{attachment.fieldName}</div>
                                    {/* <div
                                        style={{
                                            flex: "0 1 50px",
                                        }}
                                    >
                                        {isPdf ? (
                                            <AiOutlineFilePdf
                                                style={{
                                                    width: "100%",
                                                    height: "100%",
                                                }}
                                            />
                                        ) : isImage ? (
                                            <img
                                                src={attachment.url}
                                                alt=""
                                                height="50px"
                                            />
                                        ) : (
                                            <AiOutlineFile
                                                style={{
                                                    width: "100%",
                                                    height: "100%",
                                                }}
                                            />
                                        )}
                                    </div> */}
                                </a>
                            </div>
                        );
                    })}
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <div className="d-flex justify-content-between w-100 size-sm">
                    <div>
                        <b className="size-sm">Securities:</b>{" "}
                        {template?.securities?.join(", ")}
                    </div>
                    <div>{formatTimestamp(template?.datePublished)}</div>
                </div>
            </Modal.Footer>
        </Modal>
    );
};
