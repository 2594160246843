import { Col, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import { clientLoginPageText, clientName } from "../../config";

const PortalHome = () => {
    return (
        <>
            <Row noGutters style={{ color: "black" }} className="forms-header">
                <Col className="m-4">
                    <h4>{clientName}</h4>
                </Col>
            </Row>
            <Row noGutters style={{ color: "black" }} className="forms-header mb-0">
                <Col className="m-4">
                    <h4>{clientLoginPageText || ""}</h4>
                </Col>
            </Row>
            <Row noGutters style={{ color: "black" }} className="forms-header mb-0">
                <Col className="m-4">
                    <div>
                        Welcome to the {clientName}{" "}
                        {clientLoginPageText || "Portal"}
                    </div>
                </Col>
            </Row>

            <Row noGutters style={{ color: "black" }} className="forms-header mb-0">
                <Col className="m-4">
                    <div>
                        To enter a new indication of interest to sell or purchase
                        membership units of {clientName} LLC, {clientName} Financial Services,
                        or both entities, or to review  previously posted Indications of Interests  click on the{" "}
                        <NavLink to={"/portal/market-watch"}>
                            <b>&quot;Indications of Interest&quot;.</b>
                        </NavLink> link on the left side of the screen.
                    </div>
                </Col>
            </Row>

            <Row noGutters style={{ color: "black" }} className="forms-header mb-0">
                <Col className="m-4">
                    <div>
                        To review instructions on how to use the Qualified
                        Matching Service and how to proceed with selling or
                        purchasing membership units that are posted on the
                        Qualified Matching Service, click on the{" "}
                        <NavLink to={"/portal/instructions"}>
                            <b>&quot;Instruction Page&quot;</b>
                        </NavLink>{" "}
                        link on the left side of the screen.
                    </div>
                </Col>
            </Row>

            <Row noGutters style={{ color: "black" }} className="forms-header mb-0">
                <Col className="m-4">
                    <div>
                        To access documents that should be submitted to the
                        Company in connection with the purchase or sale of
                        membership units that are arranged through this
                        Qualified Matching Service, click on the{" "}
                        <NavLink to={"/portal/docs"}>
                            <b>&quot;Company Documents&quot;</b>
                        </NavLink>{" "}
                        link on the left side of the screen.
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default PortalHome;
