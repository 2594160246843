import { useEffect, useState } from "react";
import { Button, ButtonGroup, Col, FormControl, Row } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { MdList, MdViewModule } from "react-icons/md";
import { createGlobalStyle } from "styled-components";

import {
    AddSymbolToWatchListModal,
    MarketWatchCardList,
    MarketWatchTable,
} from "../../../components/portal/market-watch/";
import {
    auctionEnabled,
    emptySymbolList,
    enableNewMarketWatch,
    hideHaltedSymbols,
    hideMarketWatchList,
} from "../../../config";
import * as OTHER_ACTIONS from "../../../contexts/actions/other";
import { getPublishedAssetProfiles } from "../../../helpers/asset-builder";
import { storeOptions } from "../../../helpers/display-options";
// import { getSymbols } from "../../../helpers/market";
import { getPortalAuctions, subscribeSymbols } from "../../../helpers/trade";
import { useStore } from "../../../Store";
import { useSubStore } from "../../../SubscriptionData";
import { useSocket } from "../../../Websocket";

const ContentPaneStyling = createGlobalStyle`
    .content-pane { 
        overflow-x: scroll;
    }

`;

const MarketWatch = () => {
    const ws = useSocket();
    const { dispatch, state } = useStore();
    const [loading, setLoading] = useState(true);
    const [showAddSymbolModal, setShowAddSymbolModal] = useState(false);

    const {
        trade,
        asset_builder: { publicProfiles: profiles },
        account: { account: loggedInUser },
    } = state;

    const { symbolsSubscribed, layoutConfigurationOptions } = trade;
    const subscriptionContext = useSubStore();
    const { refChangeEmitter } = subscriptionContext;

    // const symbols = trade.symbols.sort((a, b) => {
    //     if (a.security > b.security) return 1;
    //     if (a.security < b.security) return -1;
    //     return 0;
    // });

    const [symbols, setSymbols] = useState(
        subscriptionContext.symbolsDataRef.current.sort((a, b) => {
            if (a.security > b.security) return 1;
            if (a.security < b.security) return -1;
            return 0;
        })
    );

    useEffect(() => {
        const onSymbolChange = (updatedData) => {
            setSymbols(
                updatedData.sort((a, b) => {
                    if (a.security > b.security) return 1;
                    if (a.security < b.security) return -1;
                    return 0;
                })
            );
        };

        refChangeEmitter.on("symbolChange", onSymbolChange);

        return () => {
            refChangeEmitter.removeListener("symbolChange", onSymbolChange);
        };
    }, [refChangeEmitter]);

    const [searchValue, setSearchValue] = useState("");
    const [filteredSymbols, setFilteredSymbols] = useState([]);

    const keyType = "trade_preferences";
    const optionKey = "market_watch";
    const symbolWatchList =
        loggedInUser?.data?.attr?.[keyType]?.[optionKey]?.watch_list || [];
    const preferredView =
        loggedInUser?.data?.attr?.[keyType]?.[optionKey]?.view || "list";

    const onSearch = (e) => {
        setSearchValue(e.target.value);
        const showSymbols = symbols.filter(
            (symb) =>
                symb.security
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase()) &&
                (hideHaltedSymbols ? !symb?.attr?.isHidden : symb) &&
                (enableNewMarketWatch
                    ? symbolWatchList?.includes(symb.security)
                    : true)
        );
        setFilteredSymbols(showSymbols);
    };

    const updateSymbolInWatchList = (symbol, type) => {
        const oldSymbols =
            loggedInUser?.data?.attr?.[keyType]?.[optionKey]?.watch_list || [];

        return storeOptions(
            ws,
            dispatch,
            loggedInUser?.data,
            keyType,
            optionKey,
            type === "add"
                ? [...oldSymbols, symbol]
                : oldSymbols.filter((symb) => symb !== symbol),
            "watch_list"
        );
    };

    const setMarketWatchPreferredView = (preferView) => {
        storeOptions(
            ws,
            dispatch,
            loggedInUser?.data,
            keyType,
            optionKey,
            preferView,
            "view"
        );
    };

    useEffect(() => {
        setFilteredSymbols(
            symbols.filter((symb) =>
                hideHaltedSymbols ? !symb?.attr?.isHidden : symb
            )
        );
        if (symbols.length > 0 && !symbolsSubscribed) {
            subscribeSymbols(
                ws,
                dispatch,
                // If new market watch is enabled, subscribe to only watchlisted symbols; otherwise only non hidden symbols
                symbols.filter(
                    (symb) =>
                        (hideHaltedSymbols ? !symb?.attr?.isHidden : symb) &&
                        (enableNewMarketWatch
                            ? symbolWatchList?.includes(symb.security)
                            : true)
                ),
                [], // unsub symbols
                layoutConfigurationOptions?.useExternalBookFeed,
                layoutConfigurationOptions?.useExternalTobFeed
            ).finally(() => dispatch(OTHER_ACTIONS.toggleDimLoading()));
        }

        return () => setFilteredSymbols([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [symbols]);

    useEffect(() => {
        !symbolsSubscribed &&
            !emptySymbolList &&
            dispatch(OTHER_ACTIONS.toggleDimLoading());

        auctionEnabled && getPortalAuctions(dispatch);

        if (profiles.length === 0) {
            getPublishedAssetProfiles(dispatch).finally(() =>
                setLoading(false)
            );
        } else {
            setLoading(false);
        }

        return () => {
            setLoading(true);
            setFilteredSymbols([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Row noGutters className="forms-header">
            <ContentPaneStyling />
            {enableNewMarketWatch && (
                <AddSymbolToWatchListModal
                    show={showAddSymbolModal}
                    handleClose={() => setShowAddSymbolModal(false)}
                />
            )}
            <Col className="m-4">
                <div className="pb-2 d-inline-flex w-100 justify-content-between ">
                    <div className="w-75">
                        <FormControl
                            placeholder="Search..."
                            value={searchValue}
                            onChange={onSearch}
                        />
                    </div>
                    {!hideMarketWatchList && !isMobile && (
                        <div className="text-right">
                            {enableNewMarketWatch && (
                                <Button
                                    onClick={() => setShowAddSymbolModal(true)}
                                >
                                    Add Symbol
                                </Button>
                            )}
                            <ButtonGroup className="ml-1">
                                <Button
                                    variant={
                                        preferredView === "list"
                                            ? "primary"
                                            : "outline-primary"
                                    }
                                    onClick={() => {
                                        setMarketWatchPreferredView("list");
                                    }}
                                    className="px-3"
                                >
                                    <MdList fontSize={"1.25rem"} />
                                </Button>
                                <Button
                                    variant={
                                        preferredView === "grid"
                                            ? "primary"
                                            : "outline-primary"
                                    }
                                    onClick={() => {
                                        setMarketWatchPreferredView("grid");
                                    }}
                                    className="px-3"
                                >
                                    <MdViewModule fontSize={"1.25rem"} />
                                </Button>
                            </ButtonGroup>
                        </div>
                    )}
                </div>

                {!hideMarketWatchList &&
                preferredView !== "grid" &&
                !isMobile ? (
                    <MarketWatchTable
                        updateSymbolInWatchList={updateSymbolInWatchList}
                        symbols={filteredSymbols}
                        watchlist={symbolWatchList}
                        profiles={profiles}
                        loading={loading}
                    />
                ) : (
                    <MarketWatchCardList
                        updateSymbolInWatchList={updateSymbolInWatchList}
                        symbols={filteredSymbols}
                        watchlist={symbolWatchList}
                        profiles={profiles}
                        loading={loading}
                    />
                )}
            </Col>
        </Row>
    );
};

export default MarketWatch;
