import { memo } from "react";
import { Button } from "react-bootstrap";

import {
    presetTradeLayout1,
    presetTradeLayout2,
    presetTradeLayout3,
} from "../../../../config";
import { useStore } from "../../../../Store";
import { ConfigureDisplayWrapper } from "../../../ConfigureDisplayTableWrapper";
import { TableRenderer } from "../../TableRenderer";
import { OrdersFilterSelect } from "./OrdersFilterSelect";

// eslint-disable-next-line react/display-name
export const NegotiateOrdersTab = memo(
    ({ layoutKey, handleOpenNegotiateModal, handleOpenOrderDetailModal }) => {
        const { state } = useStore();
        const {
            trade: {
                orders: { negotiate: data },
                layoutConfigurationOptions: editableLayout,
            },
            account,
        } = state;

        const layoutConfigurationOptions =
            editableLayout?.pageSelected === "preset1"
                ? presetTradeLayout1
                : editableLayout?.pageSelected === "preset2"
                ? presetTradeLayout2
                : editableLayout?.pageSelected === "preset3"
                ? presetTradeLayout3
                : editableLayout;

        const { configureEnabled } = layoutConfigurationOptions;
        const { activeOrderTableFiltering } = editableLayout;

        const styles =
            account?.data?.attr?.["trade_preferences"]?.["trade_screen"]?.styles
                ?.ordersTable;

        const filter = activeOrderTableFiltering[layoutKey] ?? {};
        const filteredData =
            Object.keys(filter).length !== 0
                ? data.filter(
                      (order) =>
                          (filter.symbol !== ""
                              ? order.security === filter.symbol
                              : true) &&
                          (filter.side !== ""
                              ? order.side === filter.side
                              : true) &&
                          (filter.destination !== ""
                              ? order.category === filter.destination
                              : true)
                  )
                : data;

        const refnoColumn = {
            dataField: "refno",
            text: "ID",
            formatter: (rowContent, row) => {
                return (
                    <span
                        aria-hidden="true"
                        style={{
                            paddingBottom: "2px",
                            borderBottom: "1px solid",
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            handleOpenOrderDetailModal(row);
                        }}
                    >
                        {row.refno}
                    </span>
                );
            },
            headerStyle: {
                backgroundColor: styles?.headerBackgroundColor,
                color: styles?.headerFontColor,
                width: "180px",
            },
        };

        return (
            <>
                <ConfigureDisplayWrapper
                    keyType="trade_preferences"
                    optionKey={`trade_screen.${layoutKey}.negotiate`}
                    defaultOptions={[
                        "security",
                        "side",
                        "ismaker",
                        "execprice",
                        "execqty",
                        "qty",
                    ]}
                    data={data}
                    blacklistedAttr={["refno"]}
                    returnButtons
                    customFormatter={{
                        dataField: "ismaker",
                        formatter: (cell, row) =>
                            row.ismaker ? "Maker" : "Taker",
                    }}
                    render={(preferredColumns, ConfigureButtons) => (
                        <>
                            <div className="my-2 d-flex justify-content-between orders-config-buttons-row">
                                <div
                                    className="d-flex flex-wrap"
                                    style={{ rowGap: "4px" }}
                                >
                                    <div className="mr-1">
                                        {(configureEnabled?.[layoutKey] ??
                                            true) &&
                                            ConfigureButtons}
                                    </div>
                                    <OrdersFilterSelect layoutKey={layoutKey} />
                                </div>
                            </div>
                            <TableRenderer
                                keyField="refno"
                                data={filteredData}
                                columns={[
                                    refnoColumn,
                                    ...preferredColumns,
                                    {
                                        dataField: "action",
                                        isDummyField: true,
                                        text: "Action",
                                        formatter: (rowContent, row) => {
                                            return (
                                                <Button
                                                    variant="outline-warning"
                                                    size="sm"
                                                    onClick={() => {
                                                        handleOpenNegotiateModal(
                                                            row
                                                        );
                                                    }}
                                                >
                                                    Action
                                                </Button>
                                            );
                                        },
                                    },
                                ]}
                                noDataIndication="No Negotiate Orders Available"
                                bordered={false}
                                hover={false}
                                wrapperClasses="orders-table "
                                sortBy="updtime"
                                sortOrder="desc"
                            />
                        </>
                    )}
                />
            </>
        );
    }
);
