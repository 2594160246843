import * as CONSTANTS from "../constants/firm";

export const queryExistingUsers = (data) => ({
    type: CONSTANTS.QUERY_EXISTING_USERS,
    data,
});

export const getFirms = (firms) => ({
    type: CONSTANTS.GET_FIRMS,
    firms,
});

export const updateExistingUser = (user) => ({
    type: CONSTANTS.UPDATE_EXISTING_USER,
    user,
})

export const queryApprovals = (approvals) => ({
    type: CONSTANTS.QUERY_APPROVALS,
    approvals,
});

export const removeApproval = (approval) => ({
    type: CONSTANTS.REMOVE_APPROVAL,
    approval,
});

export const clearState = () => ({
    type: CONSTANTS.CLEAR_STATE,
});
