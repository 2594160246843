import { useEffect, useState } from "react";
import { Row } from "react-bootstrap";

import { CompanyDocumentsTable } from "../../components/portal/CompanyDocumentsTable";
import { getCompanyDocs } from "../../helpers/protected";

const CompanyDocs = () => {
    const [docs, setDocs] = useState([]);

    useEffect(() => {
        const getDocs = async () => {
            const { data } = await getCompanyDocs();
            setDocs(data);
        };
        getDocs();
    }, []);

    return (
        <div className="m-4">
            <Row noGutters className="forms-header ">
                <h4>Company Documents</h4>
            </Row>
            <CompanyDocumentsTable docs={docs} />
        </div>
    );
};

export default CompanyDocs;
