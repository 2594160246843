import * as CONSTANTS from "../constants/settings";

export const updateSystemStatus = (statuses) => ({
    type: CONSTANTS.UPDATE_SYSTEM_STATUS,
    statuses,
});

export const getHolidays = (holidays) => ({
    type: CONSTANTS.GET_HOLIDAYS,
    holidays,
});

export const queryCompanyDocs = (companyDocs) => ({
    type: CONSTANTS.QUERY_COMPANY_DOCS,
    companyDocs,
});