import { Col, FormControl, Row } from "react-bootstrap";

import { tradeDestinations } from "../../../../config";

export const DestinationField = ({ destination, setDestination }) => {
    return (
        <Row className="more-options order-ticket-flex-item d-flex pb-2 destination-field ">
            <Col xs={4}>Destination</Col>
            <Col xs={8}>
                <FormControl
                    value={destination || ""}
                    size="sm"
                    placeholder={
                        tradeDestinations.length > 0
                            ? "Select Destination"
                            : "Destination"
                    }
                    onChange={(e) => setDestination(e.target.value)}
                    as={tradeDestinations.length > 0 ? "select" : "input"}
                >
                    {tradeDestinations.length > 0 ? (
                        <>
                            <option value="">Select Destination</option>
                            {tradeDestinations.map((opt, i) => (
                                <option key={i} value={opt}>
                                    {opt}
                                </option>
                            ))}
                        </>
                    ) : undefined}
                </FormControl>
            </Col>
        </Row>
    );
};
