import { Button, Form, InputGroup, Modal } from "react-bootstrap";

export const ApproveModal = ({
    show = false,
    handleCloseModal,
    handleApprove,
    request,
}) => (
    <Modal show={show} onHide={handleCloseModal}>
        <Modal.Header closeButton>
            <Modal.Title>Approve Request</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            Are you sure you want to approve this request?
            <br />
            <br />
            <InputGroup>
                <InputGroup.Text>Comment</InputGroup.Text>
                <Form.Control
                    type="text"
                    as="textarea"
                    placeholder="Comment"
                    onChange={({ target }) => (request.comment = target.value)}
                />
            </InputGroup>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="dark" onClick={() => handleApprove(request)}>
                Approve
            </Button>
            <Button variant="light" onClick={handleCloseModal}>
                Cancel
            </Button>
        </Modal.Footer>
    </Modal>
);
