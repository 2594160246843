import { memo } from "react";
import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";

// eslint-disable-next-line react/display-name
export const AddAttachmentToCaseModal = memo(
    ({ show, handleClose, handleSubmit, disableUpload }) => {
        const [file, setFile] = useState();
        const [description, setDescription] = useState();

        return (
            <Modal show={show} onHide={!disableUpload ? handleClose : () => {}}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Attachment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h6>File</h6>
                    <Form.File
                        custom
                        className="size-sm mb-2"
                        label={file?.name ?? "Upload a file..."}
                        onChange={({ target: { files } }) => setFile(files[0])}
                        disabled={disableUpload}
                    />
                    <h6>Description</h6>
                    <Form.Control
                        as="textarea"
                        size="sm"
                        rows={6}
                        onChange={({ target: { value } }) =>
                            setDescription(value)
                        }
                        disabled={disableUpload}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        size="sm"
                        onClick={() => handleSubmit(file, description)}
                        disabled={!file || disableUpload}
                    >
                        Add Attachment
                    </Button>
                    <Button
                        size="sm"
                        onClick={handleClose}
                        disabled={disableUpload}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
);
