/* eslint-disable react/display-name */
import { useEffect } from "react";
import { useState } from "react";
import { memo } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";

import { Loading } from "../../../Loading";

export const UserFirmSelector = memo(
    ({ users, firms, submitChange, loading = false, existingUser }) => {
        const [user, setUser] = useState([]);
        const [firm, setFirm] = useState([]);

        useEffect(() => {
            if (existingUser && existingUser.length > 0) {
                setUser([users.find((usr) => usr.userid === existingUser)]);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        return loading ? (
            <Loading />
        ) : (
            <>
                <Row noGutters>User</Row>
                <Row noGutters>
                    <Col className="ml-1">
                        <Typeahead
                            id="user"
                            labelKey="userid"
                            onChange={setUser}
                            options={users}
                            defaultSelected={user}
                            placeholder="Select a user..."
                            required
                        />
                    </Col>
                    <Col className="ml-1">
                        <Typeahead
                            id="firm"
                            labelKey="firm"
                            onChange={setFirm}
                            options={firms}
                            defaultSelected={firm}
                            placeholder="Select a firm..."
                            required
                        />
                    </Col>
                    <Col className="ml-1">
                        <Button
                            type="submit"
                            onClick={(ev) => {
                                ev.preventDefault();
                                submitChange(
                                    user?.[0]?.userid,
                                    firm?.[0]?.firm || user?.[0]?.firm
                                );
                            }}
                            disabled={!user && !firm}
                        >
                            Submit
                        </Button>
                    </Col>
                </Row>
            </>
        );
    }
);
