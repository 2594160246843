import { useEffect, useState } from "react";
import ReactGridLayout from "react-grid-layout";
import { useParams } from "react-router-dom";
import { SizeMe } from "react-sizeme";
import { createGlobalStyle } from "styled-components";

import { ConfirmModal } from "../../../components/portal/ConfirmModal";
import { MarketWatchTable } from "../../../components/portal/market-watch";
import { OrderDetailModal } from "../../../components/portal/OrderDetailModal";
import {
    BooksTable,
    HotKeySavedOrders,
    MarketStatistic,
    MiniMarketWatch,
    OrdersTable,
    OrderTicket,
    SymbolSelect,
    TradeChart,
} from "../../../components/portal/trade/";
import { NegotiateModal } from "../../../components/portal/trade/order-tables/NegotiateModal";
import {
    customTradeEnabled,
    enableNewMarketWatch,
    fastCancelEnabled,
    hideHaltedSymbols,
    presetTradeLayout1,
    presetTradeLayout2,
    presetTradeLayout3,
} from "../../../config";
import * as OTHER_ACTIONS from "../../../contexts/actions/other";
import * as ACTIONS from "../../../contexts/actions/trade";
import { getFirms } from "../../../helpers/firm";
import { convertStylingVar, getFontFile } from "../../../helpers/other";
import {
    cancelOrder,
    modifyOrder,
    subscribeCurrentSymbol,
    subscribeSymbols,
} from "../../../helpers/trade";
import { useStore } from "../../../Store";
import { useSubStore } from "../../../SubscriptionData";
import { useSocket } from "../../../Websocket";

const LayoutStyling = createGlobalStyle`
    .table td, .trade-market-watch-table td {
        vertical-align: initial !important;
        padding: ${(props) =>
            props.rowType === "compact"
                ? "0.25rem 0.5rem"
                : "0.75rem"} !important;
    }
            
    .form-control, .input-group-text {
        font-size: ${(props) => parseFloat(props.fontSize)}px !important;
    }

    .nav-link {
        padding: ${(props) => parseFloat(props.fontSize) / 3}px ${(props) =>
    parseFloat(props.fontSize) / 1.5}px !important;
        font-size: ${(props) => parseFloat(props.fontSize) - 1}px !important;
    }

    .trade-market-watch-table { 
        font-size: ${(props) => parseFloat(props.fontSize)}px !important;
    }

    .connection-bar {
        margin-top: 0;
    }
    `;
const TradeStyle = createGlobalStyle`
    .react-grid-layout { 
        font-family: "CustomFont"
    }
`;

const TradeScreen = () => {
    const { security: securitySlug } = useParams();

    const [security, setSecurity] = useState(securitySlug);

    const ws = useSocket();
    const { state, dispatch } = useStore();
    const {
        layoutConfigurationOptions: editableLayout,
        orders,
        // symbols,
        currentSymbol,
        subscribedSecurity,
        symbolsSubscribed,
    } = state.trade;

    const subscriptionContext = useSubStore();
    const { refChangeEmitter } = subscriptionContext;

    const loggedInUser = state.account.account;

    const symbolWatchList =
        loggedInUser?.data?.attr?.["trade_preferences"]?.["market_watch"]
            ?.watch_list || [];

    const layoutConfigurationOptions =
        editableLayout?.pageSelected === "preset1"
            ? presetTradeLayout1
            : editableLayout?.pageSelected === "preset2"
            ? presetTradeLayout2
            : editableLayout?.pageSelected === "preset3"
            ? presetTradeLayout3
            : editableLayout;
    const selectedPage =
        state.trade.layoutConfigurationOptions?.pageSelected || "trade";

    const userid = state?.account?.data?.userid;
    const fontURL = state.account.fontURL;

    const { layout } = layoutConfigurationOptions;
    const fontSize = `${(editableLayout?.fontSize ?? 2) * 7.25}px`;

    const { firms } = state.firm;

    useEffect(() => {
        !symbolsSubscribed &&
            !customTradeEnabled &&
            dispatch(OTHER_ACTIONS.toggleDimLoading());

        if (firms.length === 0) {
            getFirms(ws, dispatch);
        }

        if (fontURL === null) {
            getFontFile(ws, dispatch, userid);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [symbols, setSymbols] = useState(
        subscriptionContext.symbolsDataRef.current
    );

    useEffect(() => {
        const onSymbolChange = (updatedData) => {
            setSymbols(updatedData);
        };

        refChangeEmitter.on("symbolChange", onSymbolChange);

        return () => {
            refChangeEmitter.removeListener("symbolChange", onSymbolChange);
        };
    }, [refChangeEmitter]);

    useEffect(() => {
        if (fontURL) {
            const fontFace = new FontFace("CustomFont", `url(${fontURL})`);
            fontFace
                .load()
                .then((loadedFace) => {
                    document.fonts.add(loadedFace);
                })
                .catch((error) => {
                    console.error("Font loading failed:", error);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fontURL]);

    useEffect(() => {
        if (symbols.length > 0) {
            dispatch(
                ACTIONS.setCurrentSymbol(
                    symbols.find((symb) => symb.security === security)
                )
            );
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [symbols]);

    useEffect(() => {
        if (
            currentSymbol?.security &&
            currentSymbol?.security !== subscribedSecurity &&
            symbols.length > 0 &&
            !customTradeEnabled
        ) {
            // Start subscriptions and get history for our currently selected symbol.
            // Loading screen is disabled after all messages have been sent
            // Regardless of responses
            subscribeCurrentSymbol(
                ws,
                dispatch,
                currentSymbol?.security,
                subscribedSecurity,
                editableLayout?.useExternalBookFeed,
                () => {}
                // subscriptionContext.resetContextBook
            ).then(async () => {
                !symbolsSubscribed
                    ? subscribeSymbols(
                          ws,
                          dispatch,
                          symbols.filter(
                              (symb) =>
                                  (hideHaltedSymbols
                                      ? !symb?.attr?.isHidden
                                      : symb) &&
                                  (enableNewMarketWatch
                                      ? symbolWatchList.includes(symb.security)
                                      : true)
                          ),
                          [], // unsub symbols
                          editableLayout?.useExternalBookFeed,
                          editableLayout?.useExternalTobFeed
                      ).finally(() => {
                          dispatch(OTHER_ACTIONS.toggleDimLoading());
                      })
                    : () => {};
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSymbol?.security]);

    const [selectedOrder, setSelectedOrder] = useState();
    const [isEditOrder, setIsEditOrder] = useState();

    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showOrderDetailModal, setShowOrderDetailModal] = useState(false);
    const [showNegotiateModal, setShowNegotiateModal] = useState(false);

    const handleOpenOrderDetailModal = (order, isEdit = false) => {
        setIsEditOrder(isEdit);
        setSelectedOrder(order);
        setShowOrderDetailModal(true);
    };

    const handleOpenConfirmModal = (order) => {
        setSelectedOrder(order);

        // Allows users to cancel orders without going through the modal
        return fastCancelEnabled
            ? handleCancelOrder(order)
            : setShowConfirmModal(true);
    };

    const handleOpenNegotiateModal = (order) => {
        setSelectedOrder(order);
        setShowNegotiateModal(true);
    };

    const handleCloseOrderDetailModal = () => setShowOrderDetailModal(false);
    const handleCloseNegotiateModal = () => setShowNegotiateModal(false);
    const handleCloseConfirmModal = () => setShowConfirmModal(false);

    const handleEditOrder = (opt) => {
        modifyOrder(ws, opt);
        return handleCloseOrderDetailModal();
    };

    const handleCancelOrder = (order) => {
        order && order?.security && order?.refno
            ? cancelOrder(ws, order)
            : !order &&
              orders.pending.forEach((order) => cancelOrder(ws, order));

        return handleCloseConfirmModal();
    };

    const openModalFunctions = {
        handleOpenConfirmModal,
        handleOpenNegotiateModal,
        handleOpenOrderDetailModal,
    };

    return (
        <>
            <LayoutStyling
                rowType={editableLayout?.rowSizing}
                fontSize={fontSize}
            />
            <ConfirmModal
                show={showConfirmModal}
                handleConfirm={() => handleCancelOrder(selectedOrder)}
                handleClose={handleCloseConfirmModal}
                title="Cancel Order"
                body={`Send cancel request for ${
                    selectedOrder?.refno
                        ? `order ${selectedOrder.refno}`
                        : "all pending orders"
                }`}
            />
            {selectedOrder && (
                <>
                    <OrderDetailModal
                        show={showOrderDetailModal}
                        handleEditOrder={handleEditOrder}
                        handleClose={handleCloseOrderDetailModal}
                        selectedOrder={selectedOrder}
                        isEdit={isEditOrder}
                        symbol={currentSymbol}
                    />
                    <NegotiateModal
                        show={showNegotiateModal}
                        handleClose={handleCloseNegotiateModal}
                        selectedOrder={selectedOrder}
                    />
                </>
            )}

            {fontURL && <TradeStyle />}
            <>
                <ConfirmModal
                    show={showConfirmModal}
                    handleConfirm={() => handleCancelOrder(selectedOrder)}
                    handleClose={handleCloseConfirmModal}
                    title="Cancel Order"
                    body={`Send cancel request for ${
                        selectedOrder?.refno
                            ? `order ${selectedOrder.refno}`
                            : "all pending orders"
                    }`}
                />
                {selectedOrder && (
                    <>
                        <OrderDetailModal
                            show={showOrderDetailModal}
                            handleEditOrder={handleEditOrder}
                            handleClose={handleCloseOrderDetailModal}
                            selectedOrder={selectedOrder}
                            isEdit={isEditOrder}
                            symbol={currentSymbol}
                        />
                        <NegotiateModal
                            show={showNegotiateModal}
                            handleClose={handleCloseNegotiateModal}
                            selectedOrder={selectedOrder}
                        />
                    </>
                )}
            </>
            {["trade", "preset1", "preset2", "preset3"].includes(
                selectedPage
            ) ? (
                <SizeMe>
                    {({ size }) => (
                        <ReactGridLayout
                            width={size?.width || 1200}
                            cols={26}
                            rowHeight={48}
                            margin={editableLayout.spacing}
                            autoSize
                            layout={layout.map((elem) => ({
                                ...elem,
                                static: true,
                                isResizable: false,
                            }))}
                            style={{
                                fontSize: fontSize,
                            }}
                            resizeHandle={<></>}
                        >
                            {layout.map((elem) => (
                                <div
                                    key={elem.i}
                                    data-grid={{
                                        ...elem,
                                        static: true,
                                        isResizable: false,
                                        isDraggable: false,
                                    }}
                                    className={`trade-section-container`}
                                    style={{
                                        background: convertStylingVar(
                                            editableLayout.styling
                                                .backgroundColor
                                        ),
                                        color: convertStylingVar(
                                            editableLayout.styling.fontColor
                                        ),
                                        overflow: "initial !important",
                                    }}
                                    // style={{
                                    //     padding: `${layoutConfigurationOptions?.padding}px `,
                                    // }}
                                >
                                    {elem.i.startsWith("market-statistic") && (
                                        <MarketStatistic layoutKey={elem.i} />
                                    )}

                                    {elem.i.startsWith("order-ticket") && (
                                        <OrderTicket
                                            layoutKey={elem.i}
                                            securitySlug={security}
                                        />
                                    )}
                                    {elem.i.startsWith("hotkey-select") && (
                                        <HotKeySavedOrders layoutKey={elem.i} />
                                    )}
                                    {elem.i.startsWith("symbol-select") && (
                                        <SymbolSelect
                                            layoutKey={elem.i}
                                            setSecuritySlug={setSecurity}
                                        />
                                    )}
                                    {elem.i.startsWith("trade-chart") && (
                                        <TradeChart layoutKey={elem.i} />
                                    )}
                                    {elem.i.startsWith("books-table") && (
                                        <BooksTable layoutKey={elem.i} />
                                    )}
                                    {elem.i.startsWith("orders-table") && (
                                        <OrdersTable
                                            layoutKey={elem.i}
                                            {...openModalFunctions}
                                        />
                                    )}
                                    {elem.i.startsWith("market-watch") && (
                                        <MiniMarketWatch
                                            layoutKey={elem.i}
                                            height={elem?.h}
                                            width={elem?.w}
                                            setSecuritySlug={setSecurity}
                                        />
                                    )}
                                </div>
                            ))}
                        </ReactGridLayout>
                    )}
                </SizeMe>
            ) : (
                <MarketWatchTable
                    symbols={symbols}
                    watchlist={symbolWatchList}
                    isTradeScreen
                />
            )}
        </>
    );
};

export default TradeScreen;
