import { useEffect, useState } from "react";
import { Button, Col, Dropdown, DropdownButton, Row } from "react-bootstrap";
import { AiOutlineSetting } from "react-icons/ai";

import {
    AddUserModal,
    DeleteModal,
} from "../../../components/admin/firm/users";
import { TableRenderer } from "../../../components/TableRenderer";
import {
    addUser,
    deleteUser,
    getUsersFromWebsocket,
} from "../../../helpers/firm";
import { isStringifiedJson } from "../../../helpers/other";
import { useStore } from "../../../Store";
import { useSocket } from "../../../Websocket";

const Users = () => {
    const { dispatch, state } = useStore();
    const ws = useSocket();
    const [loading, setLoading] = useState(true);

    const users = state.firm.existing_users;
    const loggedInUser = state.account.account;
    const { firms } = state.firm;

    // Handle modals for user management.
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showAddModal, setShowAddModal] = useState(false);
    const [managedUser, setManagedUser] = useState({
        isEdit: false,
    });

    const handleCloseDeleteModal = () => setShowDeleteModal(false);
    const handleCloseAddModal = () => {
        setShowAddModal(false);
        setManagedUser({ isEdit: false });
    };
    const handleOpenAddModal = () => setShowAddModal(true);

    const handleAddUser = (userData) =>
        addUser(
            ws,
            dispatch,
            state.account.publicKey,
            userData,
            managedUser?.isEdit ? "edit" : "add"
        ).then(() => {
            setManagedUser({ isEdit: false });
            handleCloseAddModal();
        });

    const showEditPopup = (user) => {
        setManagedUser({ ...user, isEdit: true });
        handleOpenAddModal();
    };

    const showDeletePopup = (userid, firm) => {
        setManagedUser({
            userid,
            firm,
        });
        setShowDeleteModal(true);
    };

    useEffect(() => {
        // If not existing data in store context, fetch it.
        // (Prevents unnecessary data fetches)
        if (users.length === 0) {
            getUsersFromWebsocket(ws, dispatch).then(() => {
                setLoading(false);
            });
        } else {
            setLoading(false);
        }

        return () => setLoading(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="m-4">
            <Row noGutters className="forms-header justify-content-between">
                <Col>
                    <h4>Users</h4>
                </Col>
                <Col className="d-flex justify-content-end">
                    <Button
                        onClick={() => {
                            handleOpenAddModal();
                        }}
                        size="sm"
                    >
                        Add User
                    </Button>
                </Col>
            </Row>

            <TableRenderer
                keyField="rec_no"
                keyType={"firm_preferences"}
                optionKey={"existing_users"}
                defaultOptions={[
                    "userid",
                    "attr.first_name",
                    "attr.last_name",
                    "attr.email",
                    "user_type",
                    "active",
                ]}
                blacklistedAttr={["restricted_attr", "config_setting"]}
                data={users}
                loading={loading}
                settingsColumn={{
                    dataField: "settings-column",
                    text: "",
                    isDummyField: true,
                    formatter: (rec, user) => {
                        return (
                            <DropdownButton
                                variant="light"
                                title={<AiOutlineSetting /> || "Settings"}
                            >
                                <Dropdown.Item
                                    onClick={() => showEditPopup(user)}
                                >
                                    Edit User
                                </Dropdown.Item>
                                <Dropdown.Item
                                    style={{ color: "red" }}
                                    onClick={() =>
                                        showDeletePopup(user.userid, user.firm)
                                    }
                                >
                                    Delete User
                                </Dropdown.Item>
                            </DropdownButton>
                        );
                    },
                }}
                striped
                pagination
                wrapperClasses=" size-sm"
                searchBar
                exportCSVName="users"
                exportCSVIgnoredColumns={[
                    "rec_no",
                    "restricted_attr",
                    "config_setting",
                    "trade_preferences",
                    "firm_preferences",
                    "market_preferences",
                    "risk_preferences",
                    "existing_users",
                ]}
                exportCSVMapFunc={(data) => {
                    return data.map((user) => {
                        let role = user?.attr?.role || {};

                        if (isStringifiedJson(role)) {
                            while (
                                typeof role === "string" &&
                                isStringifiedJson(role)
                            ) {
                                role = JSON.parse(role);
                            }
                        }

                        return {
                            ...user,
                            watch_list:
                                user?.attr?.trade_preferences?.market_watch?.watch_list?.join(
                                    "|"
                                ),
                            attr: {
                                ...user.attr,
                                role: role.name,
                            },
                        };
                    });
                }}
            />

            <DeleteModal
                show={showDeleteModal}
                handleCloseModal={handleCloseDeleteModal}
                handleDelete={() => {
                    deleteUser(ws, dispatch, managedUser).then(
                        (success) => success && handleCloseDeleteModal()
                    );
                }}
                user={managedUser}
            />

            {showAddModal && (
                <AddUserModal
                    show={showAddModal}
                    loggedInUser={loggedInUser.data}
                    user={managedUser}
                    handleSubmit={handleAddUser}
                    handleCloseModal={handleCloseAddModal}
                    type={managedUser?.isEdit ? "edit" : "add"}
                    firms={firms}
                />
            )}
        </div>
    );
};

export default Users;
