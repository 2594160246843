import { useEffect, useState } from "react";
import { FormControl, Row } from "react-bootstrap";

import { ConfigureDisplayWrapper } from "../../../components/ConfigureDisplayTableWrapper";
import { TableRenderer } from "../../../components/portal/TableRenderer";
import { useStore } from "../../../Store";

const RiskMonitor = () => {
    const { state } = useStore();

    const {
        risk: { currentRisks },
    } = state;

    const [searchValue, setSearchValue] = useState("");
    const [filteredRisks, setFilteredRisks] = useState([]);

    useEffect(() => {
        setFilteredRisks(currentRisks);
    }, [currentRisks]);

    const onSearch = (e) => {
        setSearchValue(e.target.value);
        const showRisks = currentRisks.filter((risk) =>
            risk.trader.toLowerCase().includes(e.target.value.toLowerCase())
        );
        setFilteredRisks(showRisks);
    };

    return (
        <div className="m-4">
            <Row noGutters className="forms-header">
                <h4>Risk Monitor</h4>

                <Row noGutters className="pb-2">
                    <FormControl
                        placeholder="Look up a trader..."
                        value={searchValue}
                        onChange={onSearch}
                    />
                </Row>
            </Row>

            <ConfigureDisplayWrapper
                keyType={"risk_preferences"}
                optionKey={"monitor_list"}
                defaultOptions={[
                    "trader",
                    "totalorders",
                    "totalpendingorders",
                    "currentamount",
                    "maxdailyqty",
                    "currentcumopenvalue",
                    "maxdailyamount",
                    "maxmargin",
                    "totaltrades",
                    "currentmargin",
                ]}
                data={filteredRisks}
                render={(preferredColumns) => {
                    return (
                        <TableRenderer
                            keyField="trader"
                            data={filteredRisks}
                            columns={preferredColumns}
                            noDataIndication="No Current Risks Available"
                            pagination={true}
                            striped={true}
                        />
                    );
                }}
            />
        </div>
    );
};

export default RiskMonitor;
