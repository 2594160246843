import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { useStore } from "../../Store";

const AdminHome = () => {
    const { state } = useStore();
    const navigate = useNavigate();
    const { user_type } = state.account.account.data;

    useEffect(() => {
        navigate(user_type === "S" ? "/admin/status" : "/admin/symbol-status");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user_type]);

    return (
        <Row noGutters className="forms-header">
            <Col className="m-4">
                <h4>Home</h4>
            </Col>
        </Row>
    );
};

export default AdminHome;
