/* eslint-disable no-undef */

import * as ACTIONS from "../contexts/actions/account";
import * as TRADE_ACTIONS from "../contexts/actions/trade";
import { sendMessageQuery } from "./websocket";

export const storeOptions = (
    ws,
    dispatch,
    user,
    type,
    key,
    options,
    storeType,
    root = false
) =>
    sendMessageQuery(ws, "adduser", {
        updateprof: true,
        ...user,
        attr: {
            ...user?.attr,
            [type]: {
                ...user?.config_setting?.attr,
                ...user?.attr?.[type],
                [key]: {
                    ...user?.config_setting?.attr?.[key],
                    ...user?.attr?.[type]?.[key],
                    ...(root ? options : { [storeType]: options }),
                },
            },
        },
    }).then((res) => {
        dispatch(TRADE_ACTIONS.updateLayoutConfiguration(res));
        dispatch(ACTIONS.authSuccess(res));
    });

export const getStatePreferences = (loggedInUser, keyType, key) =>
    loggedInUser.data.attr?.[keyType]?.[key]?.columns;

export const formatKeyArray = (blacklist, whitelist, data, additionalKeys) => {
    // Initialize a set to prevent redundancy.
    let columns = new Set();

    // Recursively grab all possible columns.
    data.forEach((obj) => {
        return Object.keys(obj).forEach((key) => {
            // handle attributes
            if (!blacklist.includes(key) && (whitelist.length === 0 || whitelist.includes(key))) {
                if (key === "attr") {
                    Object.keys(obj["attr"]).forEach(
                        (attrKey) =>
                            // Don't add objects as options to prevent errors.
                            typeof obj["attr"][attrKey] !== "object" &&
                            columns.add(`${key}.${attrKey}`)
                    );
                } else {
                    return columns.add(key);
                }
            }
            ;
        });
    });

    additionalKeys &&
        additionalKeys.length > 0 &&
        additionalKeys.forEach((key) => columns.add(key));

    return [...columns];
};

export const formatDisplayTextForConfigure = (text) => {
    switch (text) {
        case "prevcloseprice":
            return "Prior Day Close";
        case "percentChangePair":
            return "Percentage Change";
        case "lastPricePair":
            return "Last Price";
        case "bid":
            return "Best Bid";
        case "offer":
            return "Best Offer";
        default: {
            const result = text
                .replace(/([A-Z])/g, " $1")
                .replace("attr.", "");

            return result.charAt(0).toUpperCase() + result.slice(1);
        }
    }
};
