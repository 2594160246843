import JSEncrypt from "jsencrypt";

import * as ACTIONS from "../contexts/actions/account";
import { sendMessageQuery } from "./websocket";

/*
    adduser
    get secret or disable 2fa
 */

export const set2fa = (ws, dispatch, userid, use2fa) => {
    return sendMessageQuery(ws, "adduser", {
        userid,
        updateprof: true,
        use2fa,
    }).then((resp) => {
        dispatch(ACTIONS.updateWebsocket2FaData(resp.use2fa));
        return resp;
    });
};

export const resetPassword = (
    ws,
    publicKey,
    userid,
    oldPassword,
    newPassword
) => {
    const jsEncrypt = new JSEncrypt();

    jsEncrypt.setPublicKey(publicKey);

    return sendMessageQuery(ws, "adduser", {
        userid,
        updateprof: true,
        pass: jsEncrypt.encrypt(oldPassword),
        newpass: jsEncrypt.encrypt(newPassword),
    }).then((resp) => {
        return resp;
    });
};

export const create2faseed = (ws, publicKey, userid, password) => {
    const jsEncrypt = new JSEncrypt();
    jsEncrypt.setPublicKey(publicKey);

    return sendMessageQuery(ws, "adduser", {
        userid,
        updateprof: true,
        create2faseed: true,
        pass: jsEncrypt.encrypt(password),
        override2faseed: true,
    }).then((resp) => {
        return resp;
    });
};

export const verify2faToken = (ws, userid, token) => {
    return sendMessageQuery(ws, "verify2facode", {
        userid,
        updateprof: true,
        "2fatoken": token,
    }).then((resp) => {
        return resp;
    });
};
