import moment from "moment";
import { toast } from "react-toastify";

import request from "../apiRequest";
import * as ACTIONS from "../contexts/actions/market";
import * as TRADE_ACTIONS from "../contexts/actions/trade";
import { formatFromTime } from "./other";
import { sendMessageQuery } from "./websocket";

export const formatPairSymbols = (marketSymbols, tradeSymbols) => {
    tradeSymbols.forEach((sym) => {
        const { security, pair_first } = sym;
        const marketSymbol = marketSymbols.find((elem) => {
            return elem.security === pair_first;
        });

        if (marketSymbol) {
            marketSymbol.pairs = marketSymbol?.pairs
                ? [...marketSymbol.pairs, security]
                : [security];
        }
    });

    return marketSymbols;
};

/*
    getsymbols
    This function will get existing symbols from the backend.
*/
export const getSymbols = (ws, dispatch, previousSymbols, dataContext) =>
    sendMessageQuery(ws, "getsymbols").then((symbols) => {
        const mappedSymbols = previousSymbols
            ? symbols.map((symb) => {
                  const previousSymb = previousSymbols.find(
                      (symbB) => symb.security === symbB.security
                  );

                  return Object.assign({}, symb, previousSymb);
              })
            : symbols;

        const tradeSymbols =
            mappedSymbols.length > 0
                ? mappedSymbols.filter((symb) => symb.tradingsymbol === true)
                : [];
        const depositSymbols =
            mappedSymbols.length > 0
                ? mappedSymbols.filter((symb) => symb.deposit === true)
                : [];

        mappedSymbols.length > 0 &&
            formatPairSymbols(depositSymbols, tradeSymbols);

        dispatch(TRADE_ACTIONS.addTradeSymbols(tradeSymbols));
        dataContext.addTradeSymbols(tradeSymbols);
        dispatch(ACTIONS.getDepositSymbols(depositSymbols));

        return mappedSymbols;
    });

/*
    setsymbolstatus
    This function will update the status of a symbol on the websocket.
*/
export const submitSymbolStatus = (
    ws,
    security,
    status,
    scheduletime = undefined,
    repeat_pattern = undefined,
    noToast = false
) =>
    sendMessageQuery(ws, "setsymbolstatus", {
        security,
        status,
        scheduletime,
        repeat_pattern,
    }).then((resp) => {
        !noToast &&
            toast.success(
                scheduletime
                    ? `Symbol: ${security} will update to ${
                          status === "O"
                              ? "Open"
                              : status === "P"
                              ? "Pre-Open"
                              : "Halt"
                      } at ${moment(scheduletime).format(
                          "h:mma on MMMM Do YYYY"
                      )}.`
                    : `Symbol: ${security} successfully updated to ${
                          status === "O"
                              ? "Open"
                              : status === "P"
                              ? "Pre-Open"
                              : "Halt"
                      }.`
            );
        return resp;
    });
/*
    registersymbol - create
    This function will register a brand new symbol.
*/
export const submitRegisterSymbol = (ws, dispatch, symbolData, tradeSymbols) =>
    sendMessageQuery(ws, "registersymbol", symbolData).then((resp) => {
        toast.success(`Symbol: ${resp.security} successfully created.`);

        setTimeout(
            () =>
                sendMessageQuery(ws, "getsymbols", {
                    security: resp.security,
                }).then((newResp) => {
                    const newSymbol = newResp[0];

                    if (newSymbol.tradingsymbol) {
                        dispatch(
                            TRADE_ACTIONS.addTradeSymbols(
                                tradeSymbols.concat([newSymbol])
                            )
                        );
                    }
                    if (newSymbol?.pair_first) {
                        dispatch(
                            ACTIONS.updatePairWithNewSymbol(newSymbol, [
                                newSymbol,
                                ...tradeSymbols,
                            ])
                        );
                    }
                    if (newSymbol.deposit) {
                        dispatch(ACTIONS.appendSymbol(newSymbol));
                    }
                }),
            1000
        );
    });
/*
    registersymbol - edit
    This function will edit a market symbol.
*/
export const submitEditSymbol = (ws, dispatch, symbolData, oldSymbols) => {
    if (
        !symbolData?.istradingsymbol &&
        typeof symbolData?.istradingsymbol === "boolean" &&
        symbolData?.clearinginstrument
    ) {
        delete symbolData?.clearinginstrument;
    }

    return sendMessageQuery(ws, "registersymbol", {
        ...symbolData,
        minpriceincr: symbolData?.minpriceincr
            ? symbolData?.minpriceincr?.toString()
            : undefined,
        priceprecision: symbolData?.priceprecision
            ? symbolData?.priceprecision.toString()
            : undefined,
    }).then((editedSymbol) => {
        // Update market symbol in context.
        const reformedSymbol = {
            ...editedSymbol,
            clear_inst: editedSymbol?.clearinginstrument,
            tradingsymbol: editedSymbol?.istradingsymbol,
        };

        delete reformedSymbol.clearinginstrument;
        delete reformedSymbol.istradingsymbol;

        // Update symbols in context to reflect latest data.
        getSymbols(ws, dispatch, oldSymbols);

        toast.success(`Symbol: ${symbolData.security} successfully edited.`);

        dispatch(ACTIONS.editSymbol(reformedSymbol));

        return editedSymbol;
    });
};
/*
    registersymbol - delete

*/
export const submitDeleteSymbol = (
    ws,
    dispatch,
    security,
    type,
    pairSymbol = {}
) =>
    sendMessageQuery(ws, "registersymbol", { security, isdelete: true }).then(
        (resp) => {
            if (type === "pair") {
                dispatch(ACTIONS.removePairsSymbol(pairSymbol, security));
            } else {
                dispatch(ACTIONS.deleteSymbol(security));
            }

            toast.success(`Symbol: ${security} succesfully deleted.`);
            return resp;
        }
    );
/*
    querypos
    This function will get a particular user's position.
*/
export const getUserPos = (ws, userid, firm) =>
    sendMessageQuery(ws, "querypos", { userid, firm });
/*
    withdraw/deposit
    This function will submit a withdraw/deposit request.
*/
export const submitTransaction = (ws, type, opt) =>
    sendMessageQuery(ws, type, opt);
/*
    addorder
    This function will submit an order.
*/
export const submitOrder = (ws, order) =>
    sendMessageQuery(ws, "addorder", order);
/*
    querymultiorders
    This function will submit a query to receive either cancelled, or open orders.
*/
export const getAdminMultipleOrders = (ws, dispatch, type, opt) =>
    sendMessageQuery(ws, "querymultiorders", {
        ...opt,
        fromtime: opt?.fromtime && formatFromTime(opt.fromtime),
        lastfirst: true,
        maxreturn: 1000,
    }).then((res) => {
        if (type === "rootref") return res;
        const typeCancel = type === "cancels";
        const results = res.filter(({ orderstatus, side }) => {
            const isCancel = orderstatus.toLowerCase() === "canceled";
            const isExecuted = orderstatus.toLowerCase() === "executed";
            const matchingSide = opt?.side ? side === opt?.side : true;

            return (
                matchingSide &&
                (typeCancel ? isCancel : !isCancel && !isExecuted)
            );
        });
        dispatch(
            typeCancel
                ? ACTIONS.queryCancels(results)
                : ACTIONS.queryMultipleOrders(results)
        );
        return results;
    });
/*
    querytrade
    This function will retrieve trades for the activity page.
*/

export const resursiveTradesCall = (
    ws,
    opt,
    previousCallResults = [],
    callNumber = 0
) => {
    return new Promise((resolve) => {
        if (callNumber === 0) {
            // start of call
            opt.fromtime = opt?.fromtime && formatFromTime(opt.fromtime);
        } else {
            // has been called before
            if (previousCallResults.length > 0) {
                const lastRecord =
                    previousCallResults[previousCallResults.length - 1];
                const time = lastRecord?.trdtime;
                opt.fromtime = parseInt(time) + 1 + "";
            } else return resolve(previousCallResults);
        }
        callNumber++;

        sendMessageQuery(ws, "querytrade", {
            ...opt,
            maxreturn: 1000,
        }).then((res) => {
            const lastRec = res[res.length - 1];
            const newData = res.sort(
                (a, b) => parseInt(a.trdtime) - parseInt(b.trdtime)
            );

            if (lastRec?.rec_no / 1000 > 0.97) {
                return resolve(
                    resursiveTradesCall(
                        ws,
                        opt,
                        previousCallResults.concat(newData),
                        callNumber
                    )
                );
            } else return resolve(previousCallResults.concat(newData));
        });
    });
};

export const getAdminTrades = async (ws, dispatch, opt) => {
    await resursiveTradesCall(ws, opt, []).then((res) =>
        dispatch(
            ACTIONS.queryActivityTrade(
                res.filter(({ side }) =>
                    opt?.side && opt?.side.length !== 0
                        ? side === opt?.side
                        : true
                )
            )
        )
    );
};

// sendMessageQuery(ws, "querytrade", {
//     ...opt,
//     fromtime: opt?.fromtime && formatFromTime(opt.fromtime),
//     lastfirst: true,
//     maxreturn: 1000,
// }).then((res) => {
//     if (!opt.exchangeref) {

//         dispatch(
//             ACTIONS.queryActivityTrade(
//                 res.filter(({ side }) =>
//                     opt?.side && opt?.side.length !== 0
//                         ? side === opt?.side
//                         : true
//                 )
//             )
//         );
//     }
//     return res;
// });

/*
    breaktrade
    This function will call the breaktrade ws call within the activity fills tab
*/
export const breakTrade = (ws, dispatch, exchangeref) =>
    sendMessageQuery(ws, "breaktrade", {
        exchangeref: exchangeref,
    });

/*
    querydeposit
    This function will retrieve credit/debits for the activity page.
*/
export const getAdminDeposits = (ws, dispatch, opt) =>
    sendMessageQuery(ws, "querydeposit", {
        ...opt,
        fromtime: opt?.fromtime && formatFromTime(opt.fromtime),
        lastfirst: true,
        maxreturn: 1000,
    }).then((res) => {
        dispatch(
            ACTIONS.queryDeposit(
                res.filter(({ side }) =>
                    opt?.side && opt?.side.length !== 0
                        ? side === opt?.side
                        : true
                )
            )
        );
        return res;
    });
/*
    This function fetches trade report csv file(s) based on the date given to it.
*/
export const getTradeFile = async (dispatch, date) => {
    // https://firisktest.fundamentalinteractions.com/api/tradeAdmin/tradeFile?date=YYYYMMDD
    try {
        const response = await request(
            `/tradeAdmin/tradeFile?date=${date}`,
            "GET"
        );

        const responseArrays = response.split(/\$/g).filter((n) => n);

        dispatch(ACTIONS.getTradeFileData(responseArrays, date));
    } catch (err) {
        //
        console.log(err);
    }
};

// This function will activate a symbol.
export const activateSymbol = async (ws, security, away_pct) =>
    sendMessageQuery(ws, "otcquotemanage", { security, away_pct }).then(() =>
        toast.success(
            `Symbol: ${security} successfully activated with away percentage of ${away_pct}.`
        )
    );
