/* eslint-disable react/display-name */

import { memo } from "react";
import { Col, Row } from "react-bootstrap";

export const SymbolInformation = memo(({ symbol }) => {
    return (
        <Col>
            <Row>
                <Col>Symbol:</Col> <Col>{symbol?.security}</Col>
            </Row>
            <Row>
                <Col>Security Name:</Col>
                <Col>{symbol?.attr?.name || symbol?.security}</Col>
            </Row>
            <Row>
                <Col>Type:</Col>
                <Col>
                    {symbol?.tradingsymbol
                        ? "Trading"
                        : symbol?.deposit
                        ? "Deposit"
                        : "Crypto-Pair"}
                </Col>
            </Row>
            <Row>
                <Col>Fraction Base:</Col> <Col>{symbol?.fractionbase}</Col>
            </Row>
            {symbol?.tradingsymbol && symbol?.clear_inst && (
                <Row>
                    <Col>Clearing Instrument:</Col>
                    <Col>{symbol?.clear_inst}</Col>
                </Row>
            )}
            {symbol?.description && (
                <Row>
                    <Col>Description:</Col> <Col>{symbol?.description}</Col>
                </Row>
            )}

            {/* <Row>
                    <Col>Default Exchange Rate</Col>
                    <Col></Col>
                </Row> */}
            {/* <Row>
                    <Col>Ethereum Token</Col>
                    <Col></Col>
                </Row>
                <Row>
                    <Col>Minimum Order Bid Size</Col>
                    <Col></Col>
                </Row>
                <Row>
                    <Col>Maximum Order Bid Size</Col>
                    <Col></Col>
                </Row>
                <Row>
                    <Col>Minimum Order Bid Price</Col>
                    <Col></Col>
                </Row>
                <Row>
                    <Col>Maximum Order Bid Price</Col>
                    <Col></Col>
                </Row> */}
            {/* <Row>
                    <Col>High Limit Price</Col>
                    <Col></Col>
                </Row>
                <Row>
                    <Col>High Price Warning</Col>
                    <Col></Col>
                </Row> */}
        </Col>
    );
});
