import { Button, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { useStore } from "../../Store";
import { UserAccountDropdown } from "../UserAccountDropdown";

export const LandingTopbar = () => {
    const { state } = useStore();
    const { status: isLoggedIn } = state.account.auth;

    const navigate = useNavigate();

    return (
        <div className="topbar">
            <Row noGutters className="topbar-row">
                <div />
                {isLoggedIn ? (
                    <UserAccountDropdown />
                ) : (
                    <Button
                        className="mr-4 px-4"
                        onClick={() => navigate("/login")}
                    >
                        Login
                    </Button>
                )}
            </Row>
        </div>
    );
};
