import { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";

import { ConfigureModalTemplate } from "./ConfigureModalTemplate";

export const IDMPluginConfigureModal = ({
    show = false,
    handleSave,
    existingURL,
}) => {
    const [url, setUrl] = useState(existingURL);

    return (
        <ConfigureModalTemplate
            show={show}
            confirmText="Save"
            closeText=""
            handleConfirm={() => handleSave("url", url)}
            handleClose={() => handleSave("url", url)}
            body={
                <Row noGutters>
                    <Col>URL</Col>
                    <Col>
                        <Form.Control
                            size="sm"
                            defaultValue={url}
                            onChange={({ target: { value } }) => setUrl(value)}
                        />
                    </Col>
                </Row>
            }
        />
    );
};
