import { useContext, useState } from "react";
import { Button, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { DeleteSymbolModal } from "../../../components/admin/market/symbol";
import { SymbolsTable } from "../../../components/admin/market/symbol/SymbolsTable";
import { deleteImageForProfile } from "../../../helpers/asset-builder";
import { submitDeleteSymbol } from "../../../helpers/market";
import { useStore } from "../../../Store";
import { DataContext } from "../../../SubscriptionData";
import { useSocket } from "../../../Websocket";

const Symbols = () => {
    const { state, dispatch } = useStore();
    const ws = useSocket();
    const navigate = useNavigate();

    const {
        market: { symbols: marketSymbols },
    } = state;

    const { deleteTradeSymbol } = useContext(DataContext);

    const [toDeleteSymbol, setToDeleteSymbol] = useState({});
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const handleCloseDeleteModal = () => setShowDeleteModal(false);
    const handleOpenDeleteModal = (symbol) => {
        setToDeleteSymbol(symbol);
        setShowDeleteModal(true);
    };

    const handleDelete = () => {
        // Delete image if it exists
        if (toDeleteSymbol?.attr?.image) {
            deleteImageForProfile(toDeleteSymbol.attr.image);
        }

        submitDeleteSymbol(ws, dispatch, toDeleteSymbol.security).then(() => {
            deleteTradeSymbol(toDeleteSymbol.security);
            handleCloseDeleteModal();
        });
    };

    return (
        <div className="m-4">
            <Row noGutters className="forms-header">
                <h4>Symbols</h4>

                <Button
                    className="px-3"
                    size="sm"
                    onClick={() => navigate("/admin/symbols/create")}
                >
                    Add Symbol
                </Button>
            </Row>

            <DeleteSymbolModal
                show={showDeleteModal}
                handleCloseModal={handleCloseDeleteModal}
                handleDelete={handleDelete}
                security={toDeleteSymbol?.security}
            />

            <SymbolsTable
                symbols={marketSymbols}
                showDeletePopup={handleOpenDeleteModal}
            />
        </div>
    );
};

export default Symbols;
