/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/display-name */
import { memo } from "react";
import { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";

import { clientCurrency, emailIssuer } from "../../../../config";
import { getUsersFromWebsocket } from "../../../../helpers/firm";
import { useStore } from "../../../../Store";
import { useSocket } from "../../../../Websocket";

const tradeOnlyProps = {
    clearinginstrument: undefined,
    commissioninstrument: undefined,
    comm: undefined,
    istradingsymbol: false,
};

const cryptoPairOnlyProps = {
    pairseppos: undefined,
};

const notDepositOnlyProps = {
    priceprecision: undefined,
    minpriceincr: undefined,
    // 1- When we have priceprecision 1 and  minpriceincr 10. This means prive can only go up by 10 whole numbers every time
    // 2- When we have priceprecision 1000 or 0.001 and  minpriceincr 1000. This means price can only go up by 1 whole number every time
    // priceprecision / minpriceincr is how much is can go up by
    // 1000 / 10 === 100. It'll enforce as if we had priceprecision 100 on the matching engine when we enter an order
    // We do not need to enforce on the GUI
};

const fractionPrecisionOptions = {
    1: "1",
    10: "0.1",
    100: "0.01",
    1000: "0.001",
    10000: "0.0001",
    100000: "0.00001",
    1000000: "0.000001",
    100000000: "0.0000001",
    1000000000: "0.00000001",
};

export const EditSymbolForm = memo(({ symbol, type = "edit", submitFunc }) => {
    const { dispatch, state } = useStore();
    const ws = useSocket();
    const users = state.firm.existing_users;

    const initialAttributes = {
        ...symbol?.attr,
    };

    const [attributes, setAttributes] = useState(initialAttributes);
    const [imagePreview, setImagePreview] = useState();

    const toSetEditSymbol = (symbol) => ({
        security: symbol?.security,
        clearinginstrument: symbol?.clear_inst,
        istradingsymbol: symbol?.tradingsymbol,
        description: symbol?.description,
        comm: undefined,
        fractionbase: `${symbol?.fractionbase}`,
        priceprecision:
            typeof symbol?.priceprecision === "number"
                ? `${symbol?.priceprecision}`
                : symbol?.priceprecision,
        commissioninstrument: undefined,
        pairseppos: undefined,
        minpriceincr: `${symbol?.minpriceincr?.toString() || "1"}`,
    });
    const [symbolData, setSymbolData] = useState({});
    const [symbolType, setSymbolType] = useState("deposit");

    const handleOnChange = ({ target: { name, checked, type, value } }) =>
        setSymbolData({
            ...symbolData,
            [name]: type === "checkbox" ? checked : value,
        });

    useEffect(() => {
        setSymbolData(
            type === "edit"
                ? toSetEditSymbol(symbol)
                : {
                      security: symbol?.security,
                      clearinginstrument: clientCurrency || "USD",
                      istradingsymbol: false,
                      description: undefined,
                      comm: undefined,
                      fractionbase: `${symbol?.fractionbase || "1"}`,
                      priceprecision: `${symbol?.priceprecision || ""}`,
                      commissioninstrument: undefined,
                      pairseppos: undefined,
                      minpriceincr: `${
                          symbol?.minpriceincr?.toString() || "1"
                      }`,
                  }
        );
        setSymbolType(
            symbol?.pair
                ? "crypto-pair"
                : symbol?.tradingsymbol
                ? "trading"
                : "deposit"
        );

        return setAttributes({ ...symbol?.attr });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [symbol]);

    useEffect(() => {
        if (users.length === 0) {
            getUsersFromWebsocket(ws, dispatch);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        try {
            attributes.image &&
                setImagePreview(
                    typeof attributes.image === "string"
                        ? attributes.image
                        : URL.createObjectURL(attributes.image)
                );
        } catch (err) {
            console.log(err);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attributes]);

    const submitSymbolData = () => {
        const submissionData = {
            ...symbolData,
            ...(symbolType === "deposit"
                ? {
                      ...tradeOnlyProps,
                      ...cryptoPairOnlyProps,
                      ...notDepositOnlyProps,
                  }
                : symbolType === "trading"
                ? {
                      ...cryptoPairOnlyProps,
                      istradingsymbol: true,
                  }
                : { ...tradeOnlyProps, istradingsymbol: true }),
        };

        submitFunc({
            ...submissionData,
            attr: attributes,
        });
    };

    const renderFractionPrecisionOptions = (emptyDefault = false) => {
        let result = emptyDefault
            ? [
                  <option value={""} key={"Empty option"}>
                      {"Default"}
                  </option>,
              ]
            : [];

        result = result.concat(
            Object.keys(fractionPrecisionOptions).map((key, i) => (
                <option value={key} key={i}>
                    {key} or {fractionPrecisionOptions[key]}
                </option>
            ))
        );

        return result;
    };

    const disabled =
        !symbolData?.security &&
        !symbolData?.fractionbase &&
        symbolType === "trading"
            ? !symbolData?.clearinginstrument
            : symbolType === "crypto-pair"
            ? !symbolData?.pairseppos
            : false;

    const allowedFileTypes = [
        "image/jpeg",
        "image/png",
        "image/jpg",
        "image/gif",
        "image/x-citrix-jpeg",
        "image/svg+xml",
        "image/x-png",
        "image/x-citrix-png",
    ].join(", ");

    return (
        <div>
            <Col>
                {type === "edit" && (
                    <Row noGutters>
                        <Col className="pr-0">Symbol</Col>
                        <Col className="pl-0">{symbolData?.security}</Col>
                    </Row>
                )}
                <Row className="mt-2" noGutters>
                    <Col>Symbol Image</Col>
                    <Col>
                        {imagePreview ? (
                            <div style={{ position: "relative" }}>
                                <img
                                    src={imagePreview}
                                    alt="preview"
                                    style={{
                                        width: "100%",
                                        objectFit: "contain",
                                        height: "200px",
                                    }}
                                />
                                <div
                                    className="text-danger p-1"
                                    style={{
                                        position: "absolute",
                                        top: "0rem",
                                        right: "0rem",
                                        cursor: "pointer",
                                        fontSize: "0.9rem",
                                    }}
                                    onClick={() => {
                                        setAttributes({
                                            ...attributes,
                                            image: null,
                                        });
                                        setImagePreview(null);
                                    }}
                                >
                                    Remove
                                </div>
                            </div>
                        ) : (
                            <Form.File
                                label={"Upload Symbol Image (Optional)"}
                                custom
                                accept={allowedFileTypes}
                                onChange={({ target: { files } }) =>
                                    setAttributes({
                                        ...attributes,
                                        image: files[0],
                                    })
                                }
                            />
                        )}
                    </Col>
                </Row>
                <Row className="mt-2" noGutters>
                    <Col>Security Name</Col>
                    <Col>
                        <Form.Control
                            value={attributes?.name || ""}
                            onChange={({ target: { value } }) =>
                                setAttributes({
                                    ...attributes,
                                    name: value,
                                })
                            }
                            required
                        />
                    </Col>
                </Row>
                <Row className="mt-2" noGutters>
                    <Col>Symbol Type</Col>
                    <Col>
                        <Form.Control
                            as="select"
                            onChange={(e) => setSymbolType(e.target.value)}
                            value={symbolType}
                        >
                            <option value="deposit">Deposit</option>
                            <option value="trading">Trading</option>
                            <option value="crypto-pair">Crypto-Pair</option>
                        </Form.Control>
                    </Col>
                </Row>

                <Row className="mt-2">
                    <Col className="pr-0">Description</Col>
                    <Col className="pl-0">
                        <Form.Control
                            as="textarea"
                            rows={3}
                            name="description"
                            onChange={handleOnChange}
                            value={symbolData?.description || ""}
                            required
                        />
                    </Col>
                </Row>
                <Row noGutters className="mt-2">
                    <Col>Fraction Base</Col>
                    <Col>
                        <select
                            className="custom-select"
                            name="fractionbase"
                            onChange={handleOnChange}
                            value={symbolData?.fractionbase}
                            disabled={type === "edit"}
                        >
                            {renderFractionPrecisionOptions()}
                        </select>
                    </Col>
                </Row>
                {symbolType !== "deposit" && (
                    <>
                        <Row className="mt-2">
                            <Col className="pr-0">Price Precision</Col>
                            <Col className="pl-0">
                                <select
                                    className="custom-select"
                                    name="priceprecision"
                                    onChange={handleOnChange}
                                    value={symbolData?.priceprecision}
                                    // disabled={type === "edit"}
                                >
                                    {renderFractionPrecisionOptions(true)}
                                </select>
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col className="pr-0">Minimum Price Increment</Col>
                            <Col className="pl-0">
                                <Form.Control
                                    name="minpriceincr"
                                    onChange={handleOnChange}
                                    value={symbolData?.minpriceincr}
                                    // disabled={type === "edit"}
                                />
                            </Col>
                        </Row>
                    </>
                )}
                {symbolType === "trading" && (
                    <>
                        <Row className="mt-2">
                            <Col className="pr-0">Clearing Instrument</Col>
                            <Col className="pl-0">
                                <Form.Control
                                    name="clearinginstrument"
                                    value={symbolData?.clearinginstrument}
                                    onChange={handleOnChange}
                                    isInvalid={!symbolData?.clearinginstrument}
                                    required
                                />
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col className="pr-0">Commission Instrument</Col>
                            <Col className="pl-0">
                                <Form.Control
                                    name="commissioninstrument"
                                    onChange={handleOnChange}
                                    value={
                                        symbolData?.commissioninstrument || ""
                                    }
                                    required
                                />
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col className="pr-0">Commission Value</Col>
                            <Col className="pl-0">
                                <Form.Control
                                    name="comm"
                                    onChange={handleOnChange}
                                    value={symbolData?.comm || ""}
                                    required
                                />
                            </Col>
                        </Row>
                    </>
                )}
                {symbolType === "crypto-pair" && (
                    <Row className="mt-2">
                        <Col className="pr-0">
                            Crypto pair&#x2019;s separation position
                        </Col>
                        <Col className="pl-0">
                            <Form.Control
                                name="pairseppos"
                                onChange={handleOnChange}
                                value={symbolData?.pairseppos || ""}
                                isInvalid={!symbolData?.pairseppos}
                                required
                            />
                        </Col>
                    </Row>
                )}
                {symbolType !== "deposit" && emailIssuer && (
                    <>
                        <Row className="mt-2">
                            <Col className="pr-0">Issuer Firm</Col>
                            <Col className="pl-0">
                                <Select
                                    defaultValue={{
                                        value: attributes.issuerFirm,
                                        label: attributes.issuerFirm,
                                    }}
                                    onChange={(e) => {
                                        setAttributes({
                                            ...attributes,
                                            issuerFirm: e.value,
                                        });
                                    }}
                                    options={[
                                        // eslint-disable-next-line no-undef
                                        ...new Set(
                                            users
                                                .map((user) => ({
                                                    label: user.firm,
                                                    value: user.firm,
                                                }))
                                                .map(JSON.stringify)
                                        ),
                                    ].map(JSON.parse)}
                                />
                            </Col>
                        </Row>
                    </>
                )}
                <Row className="mt-4 text-right">
                    <Col>
                        <Button onClick={submitSymbolData} disabled={disabled}>
                            Submit
                        </Button>
                    </Col>
                </Row>
            </Col>
        </div>
    );
});
