import { Col, Form, Row } from "react-bootstrap";

import { InputTemplate } from "./InputTemplate";

// displayText
// value

export const LinkInput = (props) => {
    const { field, onChange, setDisplayText, classes, disabled } = props;
    const { required } = field;

    return (
        <Row className={classes} noGutters>
            <Col style={{ position: "relative" }}>
                <Form.Control
                    size="sm"
                    name="displayText"
                    defaultValue={field?.displayText}
                    placeholder="Display Text..."
                    disabled={props.disabled}
                    onChange={({ target: { value } }) => setDisplayText(value)}
                    // isInvalid={
                    //     required && (!value || value?.length === 0)
                    // }
                    required
                />
                {required && <span className="required-icon">*</span>}
            </Col>
            <Col>
                <InputTemplate
                    field={field}
                    placeholder="Link URL..."
                    disabled={disabled}
                    onChange={onChange}
                />
            </Col>
        </Row>
    );
};
