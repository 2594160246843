import { useCallback, useEffect, useMemo, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { BsDashLg } from "react-icons/bs";
import { FaMinus, FaPlus } from "react-icons/fa6";
import { createGlobalStyle } from "styled-components";

import {
    enableQMSUserData,
    enableSplitBooksTable,
    isMarkerAsMarketMaker,
    isQMSClient,
    presetTradeLayout1,
    presetTradeLayout2,
    presetTradeLayout3,
    showMPIDWithSameFirm,
} from "../../../config";
import { storeOptions } from "../../../helpers/display-options";
import {
    convertStylingVar,
    formatNumberWithDecimal,
    getZeroes,
    hexToRgb,
} from "../../../helpers/other";
import { getUserData } from "../../../helpers/protected";
import { mergelsHistoryAnditradeLsHistory } from "../../../helpers/trade";
import { useStore } from "../../../Store";
import { useSubStore } from "../../../SubscriptionData";
import { useSocket } from "../../../Websocket";
import { ConfigureDisplayWrapper } from "../../ConfigureDisplayTableWrapper";
import { BookQMSModal } from "../BookQMSModal";
import { TableRenderer } from "../TableRenderer";
import { BooksFilterSelect } from "./BooksFilterSelect";

const DynamicHeightStyling = createGlobalStyle`
    #${(props) => props.layoutKey} .tab-content {
        height: calc(100% - ${(props) => props.height}px) !important;
    }
`;

const TradeHistoryRowStyling = createGlobalStyle`
    .equal-row {
        color: ${(props) => props.equalRow};
    }
    .neg-row {
        color: ${(props) => props.negRow};
    }
    .pos-row {
        color: ${(props) => props.posRow};
    }
`;

export const BooksTable = ({ layoutKey }) => {
    const ws = useSocket();
    const { state, dispatch } = useStore();

    const {
        lsHistory,
        // itradeLsHistory,
        currentSymbol,
        symbols,
        layoutConfigurationOptions: editableLayout,
    } = state.trade;
    const layoutConfigurationOptions =
        editableLayout?.pageSelected === "preset1"
            ? presetTradeLayout1
            : editableLayout?.pageSelected === "preset2"
            ? presetTradeLayout2
            : editableLayout?.pageSelected === "preset3"
            ? presetTradeLayout3
            : editableLayout;
    const { configureEnabled } = layoutConfigurationOptions;
    const { activeBooksTableFiltering } = editableLayout;
    const bookSymbol = activeBooksTableFiltering?.[layoutKey]?.symbol
        ? symbols.find(
              (symb) =>
                  symb.security ===
                  activeBooksTableFiltering?.[layoutKey]?.symbol
          )
        : null;
    const symbolToUse = bookSymbol || currentSymbol;

    const subscriptionContext = useSubStore();
    const { refChangeEmitter } = subscriptionContext;

    const configuredTabs =
        layoutConfigurationOptions?.booksTableTabs?.[layoutKey];
    const previouslySelectedTab = editableLayout.lastSelectedTabs?.[layoutKey];
    const fontColor = convertStylingVar(editableLayout.styling.fontColor);

    const isBookGradientActive =
        editableLayout?.styling?.bookGradient?.bookGradientActive || false;

    const [tabKey, setTabKey] = useState(
        configuredTabs && configuredTabs?.includes(previouslySelectedTab)
            ? previouslySelectedTab
            : configuredTabs?.[0] || "books"
    );
    const [timedData, setTimedData] = useState([]);

    const [height, setHeight] = useState(100);

    const { zeroCount: qtyZeroes } = useMemo(
        () => getZeroes("Quantity", symbolToUse),
        [symbolToUse]
    );
    const { zeroCount: priceZeroes } = useMemo(
        () => getZeroes("Price", symbolToUse),
        [symbolToUse]
    );
    const formatZeroColumn = useCallback(
        (cell, col) =>
            formatNumberWithDecimal(
                cell,
                col.dataField === "qty" ? qtyZeroes : priceZeroes
            ),
        [qtyZeroes, priceZeroes]
    );

    const [books, setBooks] = useState(
        subscriptionContext.bookDataRef.current?.[
            bookSymbol?.security || currentSymbol?.security
        ] || []
    );
    const [itradeLsHistory, setitradeLsHistory] = useState(
        subscriptionContext.tradeDataRef.current
    );

    useEffect(() => {
        // layoutKeyToChange is the layoutKey that the data is coming from when a symbol is swapped.
        const onBookChange = (updatedData, newSymbol, layoutKeyToChange) => {
            const isSymbolSelectChange =
                layoutKeyToChange?.startsWith("symbol-select") &&
                !bookSymbol?.security;

            // This is needed for when the book changes, so all the tables arent updated;
            // Only the ones with data being changed.
            if (
                layoutKeyToChange &&
                layoutKeyToChange !== layoutKey &&
                !isSymbolSelectChange
            )
                return;

            // If there is no bookSymbol and it is a select symbol change and the current symbol is the same as the new symbol: currentSymbol
            // If there is a newSymbol and the newSymbol is not an empty string: newSymbol
            // Otherwise, fallback to symbolToUse

            setBooks(
                updatedData?.[
                    (!bookSymbol?.security &&
                        isSymbolSelectChange &&
                        currentSymbol.security === newSymbol) ||
                    newSymbol === ""
                        ? currentSymbol.security
                        : newSymbol && newSymbol !== ""
                        ? newSymbol
                        : symbolToUse.security
                ] || []
            );
        };
        const onTradeChange = (updatedData) => {
            setitradeLsHistory(updatedData);
        };

        refChangeEmitter.on("bookChange", onBookChange);
        refChangeEmitter.on("tradeChange", onTradeChange);

        return () => {
            refChangeEmitter.removeListener("bookChange", onBookChange);
            refChangeEmitter.removeListener("tradeChange", onTradeChange);
        };
    }, [
        currentSymbol.security,
        bookSymbol?.security,
        refChangeEmitter,
        symbolToUse.security,
        layoutKey,
    ]);

    // useEffect(() => {
    //     if (currentSymbol.security !== symbolToUse.security) {
    //         console.log(
    //             "Current Symbol Change",
    //             currentSymbol.security,
    //             symbolToUse.security,
    //             subscriptionContext.bookDataRef.current
    //         );
    //         setBooks(
    //             subscriptionContext.bookDataRef.current?.[
    //                 currentSymbol.security
    //             ] || []
    //         );
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [symbolToUse.security, currentSymbol.security]);

    const [showQMSUserModal, setShowQMSUserModal] = useState(false);
    const [qMSUserDetails, setQMSUserDetails] = useState({
        name: "",
        email: "",
        phone_number: "",
    });
    const handleCloseQMSUserModal = () => setShowQMSUserModal(false);

    const getChangeSymbol = (val1, val2) => {
        return val1 === val2 ? "=" : val1 > val2 ? "-" : "+";
    };

    const updateSize = () => {
        let currentHeight = 100;

        try {
            const tabsHeight =
                document.getElementsByClassName("books-table-tabs")[0]
                    .offsetHeight;

            const idContainerConfigButtonRows = document.querySelectorAll(
                `#${layoutKey} .books-config-buttons-row`
            );

            const configButtonsRowHeight = Array.from(
                idContainerConfigButtonRows
            ).reduce((maxHeight, row) => {
                return row.offsetHeight > maxHeight
                    ? row.offsetHeight
                    : maxHeight;
            }, 0);

            currentHeight =
                tabsHeight +
                configButtonsRowHeight +
                (configButtonsRowHeight === 0 ? 0 : 5);
        } catch (err) {
            //
        }
        setHeight(currentHeight);
    };

    useEffect(() => {
        window.addEventListener(`resize${layoutKey}`, updateSize);
        updateSize();
        return () =>
            window.removeEventListener(`resize${layoutKey}`, updateSize);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const beginingOfToday = new Date().setHours(0, 0, 0, 0);
        const mergedTrades = mergelsHistoryAnditradeLsHistory(
            lsHistory.filter((hist) => hist.security === symbolToUse?.security),
            itradeLsHistory?.[symbolToUse?.security]
                ? itradeLsHistory?.[symbolToUse?.security]
                : []
        );

        setTimedData(
            mergedTrades
                .filter((ele) => parseInt(ele.time) > beginingOfToday)
                .map((ele, i, arr) => ({
                    ...ele,
                    change: getChangeSymbol(
                        // Use prevcloseprice if first in array, otherwise use execprice of previous symbol
                        parseFloat(
                            i === 0
                                ? symbolToUse.prevcloseprice
                                : arr[i - 1].execprice
                        ),
                        parseFloat(ele.execprice)
                    ),
                }))
        );
    }, [
        lsHistory,
        itradeLsHistory,
        currentSymbol,
        bookSymbol,
        symbolToUse?.security,
        symbolToUse.prevcloseprice,
    ]);

    const bookRowClasses = (row) =>
        `${row.side === "B" ? "buy-row" : "sell-row"} ${
            isBookGradientActive ? "book-gradient-row" : ""
        }`;

    const ioiRowClasses = (row) =>
        `${row.side === "B" ? "buy-row" : "sell-row"}`;

    // blacklist mpid if we are forcing the col
    const blacklistedAttr =
        enableQMSUserData || isMarkerAsMarketMaker || showMPIDWithSameFirm
            ? ["src", "act", "key", "id", "mpid", "ioi"]
            : ["src", "act", "key", "id", "ioi"];

    const tradeRowClasses = (row) =>
        `${
            row.change === "="
                ? "equal-row"
                : row.change === "-"
                ? "neg-row"
                : "pos-row"
        }`;

    const booksRowStyle = (row) => {
        const rowData = getBookRow(row);
        const percentage = rowData.index / rowData.total;

        // This is to match both the buy and sell gradient
        // Either - to the Buy side ;  Both sides will have no gradient at the end
        // Or + to the Sell side ; Both sides will have
        const adjustment = 1 / rowData.total;

        const bookGradientLayout = editableLayout?.styling?.bookGradient;
        const rgbValues = hexToRgb(
            bookGradientLayout[rowData.bg] || rowData.defaultBG
        );
        const textColor = convertStylingVar(
            bookGradientLayout[rowData.text] || rowData.defaultTextColor
        );
        if (rowData.side === "B") {
            return {
                background: `rgba(${rgbValues.r},${rgbValues.g},${
                    rgbValues.b
                }, ${1 - percentage})`,
                color: textColor,
            };
        } else {
            return {
                background: `rgba(${rgbValues.r},${rgbValues.g},${
                    rgbValues.b
                }, ${percentage + adjustment})`,
                color: textColor,
            };
        }
    };

    const buyBooks = useMemo(
        () => books?.filter((book) => book?.side === "B" && !book?.ioi) || [],
        [books]
    );
    const sellBooks = useMemo(
        () =>
            books
                ?.filter((book) => book?.side === "S" && !book?.ioi)
                .sort((a, b) => a.price - b.price) || [],
        [books]
    );
    const qmsBooks = useMemo(
        () => books?.filter((book) => book?.ioi) || [],
        [books]
    );

    const getBookRow = (row) => {
        if (row.side === "S")
            return {
                total: sellBooks.length,
                index: sellBooks.findIndex((x) => x?.id === row?.id),
                side: "S",
                bg: "sellAskColor",
                text: "sellAskTextColor",
                defaultBG: "#8B0000",
                defaultTextColor: "--White",
            };
        else
            return {
                total: buyBooks.length,
                index: buyBooks.findIndex((x) => x?.id === row?.id),
                side: "B",
                bg: "buyBidColor",
                text: "buyBidTextColor",
                defaultBG: "#00008B",
                defaultTextColor: "--White",
            };
    };

    // eslint-disable-next-line no-unused-vars
    const IOIMpid =
        enableQMSUserData || isMarkerAsMarketMaker || showMPIDWithSameFirm
            ? [
                  {
                      dataField: "mpid",
                      text: "MPID" + " (Clickable for contact information)",
                      formatter: (mpid, row) => {
                          return (
                              <span
                                  className="mpid-cell"
                                  aria-hidden="true"
                                  style={{
                                      paddingBottom: "2px",
                                      borderBottom: "1px solid",
                                      cursor: "pointer",
                                  }}
                                  onClick={() => {
                                      const queryData = mpid.split(":");
                                      getUserData(queryData[0], queryData[1])
                                          .then((data) => {
                                              setQMSUserDetails({
                                                  ...data.data,
                                              });
                                          })
                                          .catch(() => {
                                              setQMSUserDetails({
                                                  name: "N/A",
                                                  email: "N/A",
                                                  phone_number: "N/A",
                                              });
                                          })
                                          .finally(() => {
                                              setShowQMSUserModal(true);
                                          });
                                  }}
                              >
                                  {isMarkerAsMarketMaker &&
                                  row?.ismarker === true
                                      ? "MM"
                                      : showMPIDWithSameFirm
                                      ? state.account?.account?.data?.firm ===
                                        mpid
                                          ? mpid
                                          : ""
                                      : mpid}
                              </span>
                          );
                      },
                  },
              ]
            : [];
    // eslint-disable-next-line no-unused-vars
    const forceMpid =
        isMarkerAsMarketMaker || showMPIDWithSameFirm
            ? [
                  {
                      dataField: "mpid",
                      text: "MPID",
                      formatter: (mpid, row) =>
                          isMarkerAsMarketMaker && row?.ismarker === true
                              ? "MM"
                              : showMPIDWithSameFirm
                              ? state.account?.account?.data?.firm === mpid
                                  ? mpid
                                  : ""
                              : mpid,
                  },
              ]
            : [];

    const bookIOIDefaultOptions =
        enableQMSUserData || isMarkerAsMarketMaker || showMPIDWithSameFirm
            ? ["qty", "price"]
            : ["qty", "price", "mpid"];

    return (
        <div
            style={{
                padding: editableLayout?.padding,
                paddingBottom: 0,
                overflowY: "auto",
                border: editableLayout?.componentBorders
                    ? `1px solid ${fontColor}`
                    : 0,
                height: "100%",
            }}
            className="books-table-tab-container"
            id={layoutKey}
        >
            <TradeHistoryRowStyling
                equalRow={
                    editableLayout?.styling?.tradeHistory?.neutralChangeColor ||
                    "#00FF00"
                }
                negRow={
                    editableLayout?.styling?.tradeHistory
                        ?.negativeChangeColor || "#FF0000"
                }
                posRow={
                    editableLayout?.styling?.tradeHistory
                        ?.positiveChangeColor || "#FFFFFF"
                }
            />
            <BookQMSModal
                show={showQMSUserModal}
                handleClose={handleCloseQMSUserModal}
                qMSUserDetails={qMSUserDetails}
            />
            <div>
                <BooksFilterSelect layoutKey={layoutKey} />
            </div>

            <Tabs
                className="trade-tab books-table-tabs size-sm"
                activeKey={tabKey}
                onSelect={(tab) => {
                    setTabKey(tab);
                    storeOptions(
                        ws,
                        dispatch,
                        state.account.account?.data,
                        "trade_preferences",
                        "trade_screen_configuration",
                        {
                            ...editableLayout.lastSelectedTabs,
                            [layoutKey]: tab,
                        },
                        "lastSelectedTabs"
                    );
                }}
                defaultActiveKey={"books"}
            >
                {configuredTabs?.includes("books") && (
                    <Tab eventKey="books" title="BOOK">
                        <ConfigureDisplayWrapper
                            keyType="trade_preferences"
                            optionKey={`trade_screen.${layoutKey}.books`}
                            defaultOptions={bookIOIDefaultOptions}
                            data={books}
                            returnButtons
                            blacklistedAttr={blacklistedAttr}
                            toggleLimitOff
                            sortSetting={false}
                            tableOrigin="trade-book"
                            intervalBased // Needed so the modal doesnt constantly rerender on data change
                            render={(preferredColumns, ConfigureButtons) => (
                                <div className="books-tab h-100">
                                    {(configureEnabled?.[layoutKey] ??
                                        true) && (
                                        <div className="py-1 books-config-buttons-row">
                                            {ConfigureButtons}
                                        </div>
                                    )}

                                    <div className="d-flex w-100 h-100">
                                        <TableRenderer
                                            keyField="id"
                                            data={
                                                enableSplitBooksTable
                                                    ? buyBooks
                                                    : buyBooks.concat(sellBooks)
                                            }
                                            noDataIndication={
                                                enableSplitBooksTable
                                                    ? "No Buy Books Available"
                                                    : "No Books Available"
                                            }
                                            columns={[
                                                ...preferredColumns.map((col) =>
                                                    ["qty", "price"].includes(
                                                        col.dataField
                                                    )
                                                        ? {
                                                              ...col,
                                                              formatExtraData:
                                                                  col.dataField ===
                                                                  "qty"
                                                                      ? qtyZeroes
                                                                      : priceZeroes,
                                                              formatter: (
                                                                  cell
                                                              ) =>
                                                                  formatZeroColumn(
                                                                      cell,
                                                                      col
                                                                  ),
                                                          }
                                                        : col
                                                ),
                                            ].concat(forceMpid)}
                                            bordered={false}
                                            hover={false}
                                            rowClasses={bookRowClasses}
                                            rowStyle={
                                                isBookGradientActive
                                                    ? booksRowStyle
                                                    : undefined
                                            }
                                            wrapperClasses="books-table "
                                        />

                                        {enableSplitBooksTable && (
                                            <TableRenderer
                                                keyField="id"
                                                data={sellBooks}
                                                noDataIndication="No Sell Books Available"
                                                columns={[
                                                    ...preferredColumns.map(
                                                        (col) =>
                                                            [
                                                                "qty",
                                                                "price",
                                                            ].includes(
                                                                col.dataField
                                                            )
                                                                ? {
                                                                      ...col,
                                                                      formatExtraData:
                                                                          col.dataField ===
                                                                          "qty"
                                                                              ? qtyZeroes
                                                                              : priceZeroes,
                                                                      formatter:
                                                                          (
                                                                              cell
                                                                          ) =>
                                                                              formatZeroColumn(
                                                                                  cell,
                                                                                  col
                                                                              ),
                                                                  }
                                                                : col
                                                    ),
                                                ].concat(forceMpid)}
                                                bordered={false}
                                                hover={false}
                                                rowClasses={bookRowClasses}
                                                rowStyle={
                                                    isBookGradientActive
                                                        ? booksRowStyle
                                                        : undefined
                                                }
                                                wrapperClasses="books-table "
                                            />
                                        )}
                                    </div>
                                </div>
                            )}
                        />
                    </Tab>
                )}
                {configuredTabs?.includes("history") && (
                    <Tab eventKey="history" title="TRADE HISTORY">
                        <ConfigureDisplayWrapper
                            keyType="trade_preferences"
                            optionKey={`trade_screen.${layoutKey}.history`}
                            defaultOptions={[
                                "security",
                                "execqty",
                                "execprice",
                                "time",
                            ]}
                            data={timedData}
                            returnButtons
                            blacklistedAttr={blacklistedAttr}
                            toggleLimitOff
                            tableOrigin="trade-history"
                            render={(preferredColumns, ConfigureButtons) => (
                                <>
                                    {(configureEnabled?.[layoutKey] ??
                                        true) && (
                                        <div className="py-1 books-config-buttons-row">
                                            {ConfigureButtons}
                                        </div>
                                    )}
                                    <TableRenderer
                                        keyField="rec_no"
                                        data={timedData.map((d, i) => ({
                                            ...d,
                                            rec_no: i,
                                        }))}
                                        noDataIndication="No Trades Available"
                                        columns={[
                                            ...preferredColumns,
                                            {
                                                dataField: "change",
                                                text: "Tick Indicator",
                                                formatter: (change) =>
                                                    change === "=" ? (
                                                        <BsDashLg />
                                                    ) : change === "-" ? (
                                                        <FaMinus />
                                                    ) : (
                                                        <FaPlus />
                                                    ),
                                            },
                                        ]}
                                        bordered={false}
                                        hover={false}
                                        rowClasses={tradeRowClasses}
                                        wrapperClasses="books-table "
                                    />
                                </>
                            )}
                        />
                    </Tab>
                )}
                {configuredTabs?.includes("qms") && (
                    <Tab
                        eventKey="qms"
                        title={
                            isQMSClient
                                ? "All Posted Indications of Interest"
                                : "QMS"
                        }
                    >
                        <ConfigureDisplayWrapper
                            keyType="trade_preferences"
                            optionKey={`trade_screen.${layoutKey}.qms`}
                            defaultOptions={bookIOIDefaultOptions.concat(
                                isQMSClient ? ["time", "side"] : []
                            )}
                            data={qmsBooks}
                            returnButtons
                            blacklistedAttr={blacklistedAttr}
                            toggleLimitOff
                            sortSetting={false}
                            render={(preferredColumns, ConfigureButtons) => (
                                <>
                                    {(configureEnabled?.[layoutKey] ??
                                        true) && (
                                        <div className="py-1 books-config-buttons-row">
                                            {ConfigureButtons}
                                        </div>
                                    )}
                                    <TableRenderer
                                        keyField="key"
                                        data={qmsBooks}
                                        noDataIndication="No Books Available"
                                        columns={[...preferredColumns].concat(
                                            IOIMpid
                                        )}
                                        bordered={false}
                                        hover={false}
                                        rowClasses={ioiRowClasses}
                                        wrapperClasses="books-table "
                                    />
                                </>
                            )}
                        />
                    </Tab>
                )}
            </Tabs>
            <DynamicHeightStyling height={height} layoutKey={layoutKey} />
        </div>
    );
};
