/* eslint-disable react/display-name */
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

import { memo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

import { Loading } from "../../Loading";

export const BalanceTable = memo(({ positions, loading = false }) => {
    const columns = [
        {
            dataField: "rec_no",
            text: "No.",
            sort: true,
        },
        {
            dataField: "account",
            text: "User ID",
            sort: true,
        },
        {
            dataField: "security",
            text: "Security",
            sort: true,
        },
        {
            dataField: "curpos",
            text: "Curpos",
            sort: true,
        },
    ];

    return loading ? (
        <Loading />
    ) : (
        <BootstrapTable
            keyField="rec_no"
            data={positions}
            columns={columns}
            pagination={paginationFactory()}
            noDataIndication="No positions available."
            striped
            wrapperClasses="size-sm"
        />
    );
});
