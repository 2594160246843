import moment from "moment";
import { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";

import {
    AddEditHolidayModal,
    DeleteHolidayModal,
} from "../../../components/admin/settings/holidays";
import { TableRenderer } from "../../../components/portal/TableRenderer";
import { formatTableColumn, momentDateFormat } from "../../../helpers/other";
import { createHoliday, deleteHoliday, getHolidays } from "../../../helpers/settings"
import { useStore } from "../../../Store";
import { useSocket } from "../../../Websocket";

const HolidaysPage = () => {
    const ws = useSocket();
    const { dispatch, state } = useStore();

    const { holidays } = state.settings;

    const [loading, setLoading] = useState(true);
    const [selectedHoliday, setSelectedHoliday] = useState({
        isEdit: false,
    });
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showAddEditModal, setShowAddEditModal] = useState(false);

    const handleOpenDeleteModal = () => setShowDeleteModal(true);
    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
    }
    const handleOpenAddEditModal = () => setShowAddEditModal(true);
    const handleCloseAddEditModal = () => {
        setShowAddEditModal(false);
        setSelectedHoliday({ isEdit: false });
    };

    const handleDelete = (holiday) =>
        deleteHoliday(ws, dispatch, holiday).then(handleCloseDeleteModal);

    const handleAddEditSubmit = (holiday, type) =>
        createHoliday(ws, dispatch, holiday, type).then(handleCloseAddEditModal);


    useEffect(() => {
        if (holidays.length === 0) {
            getHolidays(ws, dispatch).then(() => setLoading(false));
        } else {
            setLoading(false);
        }

        return () => setLoading(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="m-4">
            <Row noGutters className="forms-header justify-content-between">
                <Col>
                    <h4>Holidays</h4>
                </Col>
                <Col>
                    <Button
                        variant="outline-dark"
                        onClick={handleOpenAddEditModal}
                        className="add-firm-button"
                        size="sm"
                    >
                        Add Holiday
                    </Button>
                </Col>
            </Row>

            {showAddEditModal && <AddEditHolidayModal
                show={showAddEditModal}
                handleCloseModal={handleCloseAddEditModal}
                holiday={selectedHoliday}
                handleSubmit={handleAddEditSubmit}
                type={selectedHoliday?.isEdit ? "edit" : "add"}
            />}

            <DeleteHolidayModal
                show={showDeleteModal}
                handleCloseModal={handleCloseDeleteModal}
                holiday={selectedHoliday}
                handleDelete={handleDelete}
            />

            <Row noGutters>
                <Col>
                    <TableRenderer
                        keyField="holidaydate"
                        data={holidays}
                        columns={[
                            {
                                dataField: "holidaydate",
                                text: "Holidaydate",
                                sort: true,
                                type: "date",
                                formatter: (cell) => moment(new Date(cell)).format(`${momentDateFormat}`)
                            },
                            ...["holidaytype", "reason", "opentime", "closetime"].map((col) =>
                                formatTableColumn(col)
                            ),
                            {
                                dataField: "action",
                                text: "Action",
                                formatter: (action, holiday) => (
                                    <Row noGutters>
                                        <Button
                                            size="sm"
                                            className="mx-1"
                                            onClick={() => {
                                                setSelectedHoliday({ ...holiday, isEdit: true });
                                                handleOpenAddEditModal();
                                            }}
                                        >
                                            Update
                                        </Button>
                                        <Button
                                            size="sm"
                                            variant="danger"
                                            onClick={() => {
                                                setSelectedHoliday(holiday);
                                                handleOpenDeleteModal();
                                            }}
                                        >
                                            Delete
                                        </Button>
                                    </Row>

                                ),
                            },
                        ]}
                        noDataIndication="No Holidays available."
                        striped
                        loading={loading}
                    />
                </Col>
            </Row>
        </div>
    );
};

export default HolidaysPage;
