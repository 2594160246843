/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
import { useRef } from "react";
import { Row } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import ReactSignatureCanvas from "react-signature-canvas";

export const ESignatureInput = (props) => {
    const { field, onChange, classes, disabled } = props;
    const { required, value } = field;

    const sigCanvas = useRef(null);

    const clearCanvas = () => {
        sigCanvas.current ? sigCanvas.current.clear() : onChange(undefined);
    };

    const handleSignCanvas = () => {
        const signatureValue = sigCanvas.current.getCanvas().toDataURL();

        onChange(signatureValue);
    };

    return (
        <Row
            className={classes ?? " registration-sigCanvas-container mx-2"}
            noGutters
        >
            {!value ? (
                <ReactSignatureCanvas
                    onEnd={handleSignCanvas}
                    penColor="#858796"
                    canvasProps={{
                        className: "registration-sigCanvas",
                        height: 200,
                        width: 710,
                    }}
                    ref={sigCanvas}
                />
            ) : (
                <img src={value} height={200} width={710} alt="" />
            )}

            <div
                style={{ top: "1rem", right: "2rem", position: "absolute" }}
                className="cursor-pointer p-2"
                onClick={() => clearCanvas()}
            >
                <AiOutlineClose />
            </div>
            {required && (
                <span className="required-icon" style={{ top: "-2.75rem" }}>
                    *
                </span>
            )}
        </Row>
    );
};
