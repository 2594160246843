/* eslint-disable react/display-name */

import { memo, useState } from "react";
import {
    Button,
    Col,
    Form,
    OverlayTrigger,
    Row,
    Tooltip,
} from "react-bootstrap";
import { AiOutlineCopy, AiOutlineDelete } from "react-icons/ai";

import {
    BranchConfigureModal,
    CheckboxConfigureModal,
    DropdownConfigureModal,
    IDMPluginConfigureModal,
    SubsectionConfigureModal,
    TableConfigureModal,
} from "./confirm-modals";
import { OnboardingTableConfigureModal } from "./confirm-modals/OnboardingTableConfigureModal";
import { FieldDescriptionComponent } from "./FieldDescriptionComponent";

export const Field = memo(
    ({
        setSection,
        fieldValue,
        copyField,
        deleteField,
        disabled,
        onboarding,
        typeBranch,
        typeTable,
        setParentModal = () => {},
        isGroupField = false,
    }) => {
        const defaultSubfieldTypeOptions = ["Dropdown"];
        const assetbuilderSubfieldTypeOptions = ["Subsection", "Table"];
        const onboardingSubfieldTypeOptions = [
            "Branch",
            "IDM-Plugin",
            "Checkbox",
            "Table",
        ];

        const defaultFieldTypeOptions = [
            "String",
            "Paragraph",
            "Location",
            "Number",
            "Date",
            "File Upload",
        ];

        const assetbuilderFieldTypeOptions = ["Link", "Social"];
        const onboardingFieldTypeOptions = ["e-Signature", "Display"];

        const subFieldTypeOptions = !onboarding
            ? defaultSubfieldTypeOptions.concat(assetbuilderSubfieldTypeOptions)
            : onboarding && !typeBranch
            ? defaultSubfieldTypeOptions.concat(onboardingSubfieldTypeOptions)
            : defaultSubfieldTypeOptions;

        const fieldTypeOptions = onboarding
            ? defaultFieldTypeOptions.concat(onboardingFieldTypeOptions)
            : defaultFieldTypeOptions.concat(assetbuilderFieldTypeOptions);

        const subFieldObject = subFieldTypeOptions.includes(
            fieldValue.fieldType
        );

        const [showBranchModal, setShowBranchModal] = useState(false);
        const handleOpenBranchModal = () => setShowBranchModal(true);
        const handleCloseBranchModal = () => setShowBranchModal(false);

        const [showConfirmModal, setShowConfirmModal] = useState(false);
        const handleOpenConfirmModal = () => {
            setShowConfirmModal(true);
            setParentModal(true);
        };
        const handleCloseConfirmModal = () => {
            setShowConfirmModal(false);
            setParentModal(false);
        };

        const handleConfirmSave = (
            key,
            value,
            key2 = undefined,
            value2 = undefined,
            key3 = undefined,
            value3 = undefined
        ) => {
            setSection({
                ...fieldValue,
                [key]: value,
                [key2]: value2,
                [key3]: value3,
            });

            fieldValue.fieldType === "Branch"
                ? handleCloseBranchModal()
                : handleCloseConfirmModal();
        };

        return (
            <div className="asset-field">
                {fieldValue.fieldType === "Dropdown" && showConfirmModal && (
                    <DropdownConfigureModal
                        show={showConfirmModal}
                        handleSave={handleConfirmSave}
                        options={fieldValue?.options}
                        handleSaveRejected={(reject) => {
                            setSection({ ...fieldValue, reject });
                        }}
                        rejected={fieldValue?.reject}
                        onboarding={onboarding}
                    />
                )}
                {/* {fieldValue.fieldType === "Multi-field" && showConfirmModal && (
                    <MultiFieldConfigureModal
                        show={showConfirmModal}
                        handleSave={handleConfirmSave}
                        fieldTypes={fieldTypeOptions}
                        selectedSubfield={fieldValue?.subfield}
                    />
                )} */}
                {fieldValue.fieldType === "Subsection" && showConfirmModal && (
                    <SubsectionConfigureModal
                        show={showConfirmModal}
                        handleSave={handleConfirmSave}
                        fieldTypes={fieldTypeOptions}
                        existingSubfields={
                            fieldValue?.subfields || [
                                {
                                    fieldName: "",
                                    fieldType: "String",
                                },
                            ]
                        }
                    />
                )}
                {fieldValue.fieldType === "Table" &&
                    showConfirmModal &&
                    (onboarding ? (
                        <OnboardingTableConfigureModal
                            show={showConfirmModal}
                            handleSave={handleConfirmSave}
                            existingTable={{
                                label: fieldValue?.label,
                                subfields: fieldValue?.subfields,
                                max: fieldValue?.max,
                            }}
                        />
                    ) : (
                        <TableConfigureModal
                            show={showConfirmModal}
                            handleSave={handleConfirmSave}
                            existingHeaders={fieldValue?.headers}
                        />
                    ))}
                {fieldValue.fieldType === "Checkbox" && showConfirmModal && (
                    <CheckboxConfigureModal
                        show={showConfirmModal}
                        handleSave={handleConfirmSave}
                        existingOptions={fieldValue?.options}
                        existingSubfields={fieldValue?.subfields}
                    />
                )}
                {fieldValue.fieldType === "IDM-Plugin" && showConfirmModal && (
                    <IDMPluginConfigureModal
                        show={showConfirmModal}
                        handleSave={handleConfirmSave}
                        existingURL={fieldValue.url}
                    />
                )}
                {fieldValue.fieldType === "Branch" && showBranchModal && (
                    <BranchConfigureModal
                        show={showBranchModal}
                        handleSave={handleConfirmSave}
                        existingBranches={fieldValue?.subfields || []}
                        rejected={fieldValue?.reject}
                        handleSaveRejected={(reject) => {
                            setSection({ ...fieldValue, reject });
                        }}
                    />
                )}
                <Row noGutters>
                    <Col md={4}>
                        <Form.Label className="size-sm">Field Name</Form.Label>
                    </Col>
                    <Col md={4}>
                        <Form.Label className="size-sm">Field Type</Form.Label>
                    </Col>
                </Row>
                <Row noGutters>
                    <Col md={4}>
                        <Form.Control
                            placeholder="Field Name"
                            size="sm"
                            onChange={({ target: { value } }) =>
                                setSection({ ...fieldValue, fieldName: value })
                            }
                            value={fieldValue.fieldName}
                            required
                            disabled={disabled}
                        />
                    </Col>
                    <Col md={2}>
                        <select
                            className="custom-select custom-select-sm mx-1"
                            onChange={({ target: { value } }) => {
                                setSection({
                                    ...fieldValue,
                                    fieldType: value,
                                    options: !["Dropdown", "Checkbox"].includes(
                                        value
                                    )
                                        ? undefined
                                        : [],
                                    subfield:
                                        value !== "Multi-field"
                                            ? undefined
                                            : "String",
                                    label: !["Table"].includes(value)
                                        ? undefined
                                        : "",
                                    subfields: ![
                                        "Subsection",
                                        "Checkbox",
                                        "Table",
                                    ].includes(value)
                                        ? undefined
                                        : [],
                                    max: value !== "Table" ? undefined : 1,
                                    headers: value !== "Table" ? undefined : [],
                                    url:
                                        value !== "IDM-Plugin" ? undefined : "",
                                    reject: [],
                                    restrictToImages:
                                        value !== "File Upload"
                                            ? undefined
                                            : false,
                                    required:
                                        value === "Display"
                                            ? undefined
                                            : fieldValue.required,
                                });
                            }}
                            value={fieldValue.fieldType}
                            disabled={disabled}
                        >
                            {[...fieldTypeOptions, ...subFieldTypeOptions]
                                .filter((fld) =>
                                    typeTable
                                        ? // Blocklist
                                          ![
                                              "IDM-Plugin",
                                              "e-Signature",
                                              "Branch",
                                              "Checkbox",
                                              "Table",
                                              "Display",
                                          ].includes(fld)
                                        : true
                                )
                                .map((opt, i) => (
                                    <option key={i} value={opt}>
                                        {opt}
                                    </option>
                                ))}
                        </select>
                    </Col>
                    <Col className="text-right">
                        <Row noGutters className="float-right d-inline-flex">
                            {subFieldObject && (
                                <div style={{ marginTop: "-0.25rem" }}>
                                    <Button
                                        size="sm"
                                        onClick={() => {
                                            fieldValue.fieldType === "Branch"
                                                ? handleOpenBranchModal()
                                                : handleOpenConfirmModal();
                                        }}
                                        disabled={disabled}
                                    >
                                        Configure
                                    </Button>
                                </div>
                            )}
                            {["Image Upload", "File Upload"].includes(
                                fieldValue.fieldType
                            ) && (
                                <div className="d-inline-flex mr-2  mb-1">
                                    <Form.Check
                                        type="checkbox"
                                        className="mr-2"
                                        onChange={({ target: { checked } }) =>
                                            setSection({
                                                ...fieldValue,
                                                restrictToImages: checked,
                                            })
                                        }
                                        checked={
                                            fieldValue?.restrictToImages ||
                                            false
                                        }
                                        disabled={disabled}
                                        style={{
                                            marginTop: "-0.10rem",
                                        }}
                                    />
                                    <Form.Label className="size-sm">
                                        Restrict to Images?
                                    </Form.Label>
                                </div>
                            )}
                            {isGroupField && (
                                <OverlayTrigger
                                    overlay={
                                        <Tooltip>
                                            Click to toggle between subgroups, A
                                            and B.
                                            <br />
                                            <br />
                                            Dividers will be created between
                                            subgroups
                                        </Tooltip>
                                    }
                                >
                                    <div className="d-inline-flex mr-2 ml-2">
                                        <Button
                                            style={{
                                                height: "1.25rem",
                                                // marginTop: "0.1rem",
                                                fontSize: "0.75rem",
                                                lineHeight: "0.25rem",
                                                // paddingBottom: "1.25rem",
                                            }}
                                            variant={
                                                fieldValue.subgroup === "A"
                                                    ? "info"
                                                    : "secondary"
                                            }
                                            className="px-3 py-2 "
                                            disabled={disabled}
                                            onClick={() => {
                                                // Toggle between subgroup A and subgroup B

                                                setSection({
                                                    ...fieldValue,
                                                    subgroup:
                                                        fieldValue.subgroup ===
                                                        "A"
                                                            ? "B"
                                                            : "A",
                                                });
                                            }}
                                        >
                                            Subgroup
                                        </Button>
                                    </div>
                                </OverlayTrigger>
                            )}
                            <div className="d-inline-flex mr-2  mb-1 ">
                                <Form.Check
                                    type="checkbox"
                                    className="mr-2 "
                                    onChange={({ target: { checked } }) =>
                                        setSection({
                                            ...fieldValue,
                                            required: checked,
                                        })
                                    }
                                    checked={fieldValue?.required || false}
                                    disabled={
                                        disabled ||
                                        fieldValue.fieldType === "Display"
                                    }
                                    style={{
                                        marginTop: "-0.1rem",
                                    }}
                                />
                                <Form.Label className="size-sm ">
                                    Required?
                                </Form.Label>
                            </div>
                            <div className=" mr-2">
                                <Row noGutters>
                                    <Col>
                                        {onboarding && (
                                            <AiOutlineCopy
                                                onClick={
                                                    !disabled
                                                        ? copyField
                                                        : () => {}
                                                }
                                                className={`${
                                                    !disabled
                                                        ? "cursor-pointer"
                                                        : "text-gray"
                                                } mb-2 mx-1`}
                                            />
                                        )}
                                        <AiOutlineDelete
                                            onClick={
                                                !disabled
                                                    ? deleteField
                                                    : () => {}
                                            }
                                            className={`${
                                                !disabled
                                                    ? "cursor-pointer"
                                                    : "text-gray"
                                            } mb-2`}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Row>
                    </Col>
                </Row>
                {onboarding && !typeBranch && (
                    <FieldDescriptionComponent
                        setData={setSection}
                        data={fieldValue}
                        disabled={disabled}
                    />
                )}
            </div>
        );
    }
);
