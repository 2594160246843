/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Col, Row } from "react-bootstrap";
import Select from "react-select";
import Creatable from "react-select/creatable";

import { customTradeEnabled, hideHaltedSymbols } from "../../../config";
import * as ACTIONS from "../../../contexts/actions/trade";
import { storeOptions } from "../../../helpers/display-options";
import {
    convertStylingVar,
    mapToSelect,
    stringToUpperCase,
} from "../../../helpers/other";
import { useStore } from "../../../Store";
import { useSubStore } from "../../../SubscriptionData";
import { useSocket } from "../../../Websocket";

export const SymbolSelect = ({
    // eslint-disable-next-line no-unused-vars
    layoutKey,
    selectedSymbol,
    onChange = undefined,
    isHotKey = false,
    setSecuritySlug,
}) => {
    const ws = useSocket();
    const { state, dispatch } = useStore();
    const subscriptionContext = useSubStore();
    const { refChangeEmitter } = subscriptionContext;
    const { symbols, currentSymbol, layoutConfigurationOptions } = state.trade;

    const fontColor = convertStylingVar(
        layoutConfigurationOptions.styling.fontColor
    );
    const backgroundColor = convertStylingVar(
        layoutConfigurationOptions.styling.backgroundColor
    );

    const savedCustomSymbols =
        state.account?.account?.data?.attr?.["trade_preferences"]?.[
            "trade_screen_configuration"
        ]?.customSymbols || [];

    const symbolOptions = customTradeEnabled
        ? savedCustomSymbols.map((symb) => ({ value: symb, label: symb }))
        : mapToSelect(
              symbols
                  .sort((a, b) =>
                      a.security
                          .toLowerCase()
                          .localeCompare(b.security.toLowerCase())
                  )
                  .filter((symb) =>
                      hideHaltedSymbols ? !symb?.attr?.isHidden : symb
                  ),
              "security"
          );

    const setNewCurrentSymbol = (val) => {
        dispatch(
            ACTIONS.setCurrentSymbol(
                symbols.find((symb) => symb.security === val.value)
            )
        );

        if (!isHotKey) {
            setSecuritySlug(val.value);

            // We need to emit a book change so that the books table will
            // properly rerender with book data for the new symbol.
            refChangeEmitter.emit(
                "bookChange",
                subscriptionContext.bookDataRef.current,
                val.value,
                layoutKey
            );
        }

        window.history.replaceState(null, "", `/portal/trade/${val.value}`);
    };

    const setNewCurrentCustomSymbol = (val) => {
        !isHotKey && setSecuritySlug(val.value);

        dispatch(
            ACTIONS.setCurrentSymbol({
                security: val.value,
            })
        );
    };
    const saveNewCustomSymbol = (val) => {
        storeOptions(
            ws,
            dispatch,
            state.account?.account?.data,
            "trade_preferences",
            "trade_screen_configuration",
            [val, ...(savedCustomSymbols ?? []).slice(0, 9)],
            "customSymbols"
        );
    };

    const stylingProps = {
        control: (styles) => ({
            ...styles,
            color: fontColor,
            backgroundColor: backgroundColor,
            height: "100%",
            border: layoutConfigurationOptions?.componentBorders
                ? `1px solid ${fontColor}`
                : 0,
        }),
        menu: (styles) => ({
            ...styles,
            scrollBehavior: "smooth",
            backgroundColor: backgroundColor,
            color: fontColor,
        }),
        menuPortal: (styles) => ({
            ...styles,
            zIndex: 9999,
        }),
        option: (styles, { isFocused, isSelected }) => ({
            ...styles,
            color: isFocused || isSelected ? "rgba(0, 0, 0, 0.75)" : fontColor,
            ":hover": {
                ...styles[":hover"],
                color: "rgba(0, 0, 0, 0.75)",
            },
        }),
        input: (styles) => ({
            ...styles,
            color: fontColor,
        }),
        singleValue: (styles) => ({
            ...styles,
            color: fontColor,
        }),
    };

    const symbolSelectProps = {
        value: {
            value: isHotKey
                ? selectedSymbol?.security
                : currentSymbol?.security,
            label: isHotKey
                ? selectedSymbol?.security
                : currentSymbol?.security,
        },
        onChange: (symb, { action }) => {
            if (action === "create-option")
                saveNewCustomSymbol(stringToUpperCase(symb.value));
            onChange
                ? onChange(symb)
                : customTradeEnabled
                ? setNewCurrentCustomSymbol(symb)
                : setNewCurrentSymbol(symb);
        },
        options: symbolOptions,
        styles: stylingProps,
        menuPortalTarget: document.body,
        menuPosition: "fixed",
        isValidNewOption: (value) =>
            !symbolOptions.find((symb) => symb.value === value.toUpperCase()) &&
            value.length > 0,
    };

    return (
        <Row noGutters className="h-100 symbol-select">
            <Col xs={12} className="d-flex justify-content-center flex-column">
                {customTradeEnabled ? (
                    <div
                        onKeyUp={(e) =>
                            e.key.match(/[a-zA-Z0-9]/g) &&
                            e.key !== "Backspace" &&
                            e.key !== "Tab" &&
                            (onChange
                                ? onChange({
                                      value: e.target.value.toUpperCase(),
                                  })
                                : setNewCurrentCustomSymbol({
                                      value: e.target.value.toUpperCase(),
                                  }))
                        }
                    >
                        <Creatable
                            {...symbolSelectProps}
                            formatCreateLabel={(symb) => `Save ${symb}`}
                        />
                    </div>
                ) : (
                    <Select {...symbolSelectProps} className="h-100" />
                )}
            </Col>
        </Row>
    );
};
