import { memo, useEffect, useState } from "react";

import {
    presetTradeLayout1,
    presetTradeLayout2,
    presetTradeLayout3,
} from "../../../../config";
import { useStore } from "../../../../Store";
import { ConfigureDisplayWrapper } from "../../../ConfigureDisplayTableWrapper";
import { TableRenderer } from "../../TableRenderer";
import { OrdersFilterSelect } from "./OrdersFilterSelect";

// eslint-disable-next-line react/display-name
export const FillOrdersTab = memo(({ layoutKey }) => {
    const { state } = useStore();
    const {
        trade: {
            orders: { fill: data },
            layoutConfigurationOptions: editableLayout,
        },
    } = state;
    const layoutConfigurationOptions =
        editableLayout?.pageSelected === "preset1"
            ? presetTradeLayout1
            : editableLayout?.pageSelected === "preset2"
            ? presetTradeLayout2
            : editableLayout?.pageSelected === "preset3"
            ? presetTradeLayout3
            : editableLayout;

    const { configureEnabled } = layoutConfigurationOptions;
    const { activeOrderTableFiltering } = editableLayout;

    const [filteredData, setFilteredData] = useState([]);
    const filter = activeOrderTableFiltering[layoutKey] ?? {};

    useEffect(() => {
        setFilteredData(
            Array.from(
                data.filter(
                    (datapoint) =>
                        datapoint.isbroken !== true &&
                        (Object.keys(filter).length !== 0
                            ? (filter.symbol !== ""
                                  ? datapoint.security === filter.symbol
                                  : true) &&
                              (filter.side !== ""
                                  ? datapoint.side === filter.side
                                  : true) &&
                              (filter.destination !== ""
                                  ? datapoint.category === filter.destination
                                  : true)
                            : true)
                )
            )
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
        <>
            <ConfigureDisplayWrapper
                keyType="trade_preferences"
                optionKey={`trade_screen.${layoutKey}.fills`}
                defaultOptions={[
                    "security",
                    "side",
                    "clientorderid",
                    "ismaker",
                    "execprice",
                    "execqty",
                ]}
                data={filteredData}
                blacklistedAttr={["refno", "isbroken"]}
                returnButtons
                customFormatter={{
                    dataField: "ismaker",
                    formatter: (cell, row) => (row.ismaker ? "Maker" : "Taker"),
                }}
                render={(preferredColumns, ConfigureButtons) => (
                    <>
                        <div className="my-2 d-flex justify-content-between orders-config-buttons-row">
                            <div
                                className="d-flex flex-wrap"
                                style={{ rowGap: "4px" }}
                            >
                                <div className="mr-1">
                                    {(configureEnabled?.[layoutKey] ?? true) &&
                                        ConfigureButtons}
                                </div>
                                <OrdersFilterSelect layoutKey={layoutKey} />
                            </div>
                        </div>
                        <TableRenderer
                            keyField="traderefno"
                            data={filteredData}
                            columns={preferredColumns}
                            noDataIndication="No Fill Orders Available"
                            bordered={false}
                            hover={false}
                            wrapperClasses="orders-table "
                            sortBy="updtime"
                            sortOrder="desc"
                        />
                    </>
                )}
            />
        </>
    );
});
