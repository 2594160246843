import { NavDropdown } from "react-bootstrap";
import {
    AiOutlineLineChart,
    AiOutlineLogout,
    AiOutlineSetting,
    AiOutlineUnorderedList,
    AiOutlineUser,
    AiOutlineWifi,
} from "react-icons/ai";
import { Link } from "react-router-dom";

import {
    allowIncompleteOnboardingForms,
    dispalyStageSystemStatus,
    hideTradeScreenWhenInactive,
    showOnboarding,
    showTradingPortal,
} from "../config";
import { logoutUser } from "../helpers/login";
import { isAdmin } from "../helpers/other";
import { useStore } from "../Store";
import { useSocket } from "../Websocket";

export const UserAccountDropdown = () => {
    const { dispatch, state } = useStore();
    const {
        account: user,
        other,
        trade: { symbols: tradeSymbols },
        settings: { statuses },
    } = state;

    const isUserActive = user?.account?.data?.active === "Y";
    const canOnboardingUserAccess =
        (showOnboarding && allowIncompleteOnboardingForms) ||
        user.account.data.status === "approved" ||
        isUserActive;

    const symbols = tradeSymbols.sort((a, b) => {
        if (a.security > b.security) return 1;
        if (a.security < b.security) return -1;
        return 0;
    });

    const ws = useSocket();
    const isUserAdmin = isAdmin(user.account.data.user_type, user.roles);
    // const showPortal = showOnboarding
    //     ? showTradingPortal &&
    //       (user.account.data.status === "approved" || isUserAdmin)
    //     : showTradingPortal;

    const handleLogout = async () => {
        await logoutUser(dispatch, ws.socket);

        window.location.reload();
    };

    const renderConnectionBar = () => (
        <AiOutlineWifi
            size={"1.4em"}
            style={{ color: other.connected ? "green" : "red" }}
        />
    );

    const renderStageStatus = () => {
        const stageItem = statuses.find((a) => a.exchange === "STAGE");
        let status = "";
        let backgroundColor = "red";
        let color = "white";
        if (stageItem) {
            status = stageItem.status;
            switch (stageItem.status) {
                case "InitialClose":
                    status = "Closed";
                    break;
                case "PreOpening":
                    backgroundColor = "yellow";
                    color = "";
                    break;
                case "Open":
                    backgroundColor = "green";
                    break;
                default:
                    status = stageItem.status;
                    backgroundColor = "red";
                    break;
            }
        }

        return (
            <div className="stage-status">
                Market Status:{" "}
                <span
                    className="badge"
                    style={{
                        marginLeft: "4px",
                        backgroundColor: backgroundColor,
                        color: color ? color : "inherit",
                    }}
                >
                    {status}
                </span>
            </div>
        );
    };

    const AdminOnly = ({ children }) => (isUserAdmin ? children : null);

    return (
        <div className="topbar-icon-dropdown">
            {dispalyStageSystemStatus && renderStageStatus()}
            <div className="connection-bar">{renderConnectionBar()}</div>
            <NavDropdown
                className="topbar-nav mr-2"
                title={
                    <>
                        {user.account.data.first_name
                            ? user.account.data.first_name +
                              " " +
                              user.account.data.last_name
                            : "My Account"}

                        <AiOutlineUser />
                    </>
                }
            >
                <AdminOnly>
                    <Link to="/">
                        <NavDropdown.Item className="topbar-option" as="li">
                            <AiOutlineSetting />
                            Administration
                        </NavDropdown.Item>
                    </Link>
                </AdminOnly>

                {canOnboardingUserAccess && showTradingPortal && (
                    <>
                        <Link to="/portal">
                            <NavDropdown.Item className="topbar-option" as="li">
                                <AiOutlineUnorderedList />
                                Portal
                            </NavDropdown.Item>
                        </Link>
                        {symbols.length > 0 &&
                            (hideTradeScreenWhenInactive
                                ? isUserActive
                                : true) && (
                                <Link
                                    to={"/portal/trade/" + symbols[0].security}
                                >
                                    <NavDropdown.Item
                                        className="topbar-option"
                                        as="li"
                                    >
                                        <AiOutlineLineChart />
                                        Trade
                                    </NavDropdown.Item>
                                </Link>
                            )}
                        <Link to="/profile">
                            <NavDropdown.Item className="topbar-option" as="li">
                                <AiOutlineUser />
                                Profile
                            </NavDropdown.Item>
                        </Link>
                    </>
                )}
                <NavDropdown.Item
                    onClick={handleLogout}
                    className="topbar-option"
                >
                    <AiOutlineLogout />
                    Log out
                </NavDropdown.Item>
            </NavDropdown>
        </div>
    );
};
