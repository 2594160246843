import { isArray } from "lodash";

import request from "../apiRequest";
import * as ACTIONS from "../contexts/actions/trade";
import { resursiveTradesCall } from "./market";
import { thirtyDaysAgo } from "./other";
import { sendMessageQuery } from "./websocket";

const formatFromTime = (time) => {
    if (!time) return `${thirtyDaysAgo().getTime()}`;

    // Set to beginning of day.
    time.setHours(0, 0, 0, 0);
    return `${time.getTime()}`;
};

/*
    subscribe
    This function is to subscribe a security to get real time book data.
 */
export const subscribeHelper = (ws, dispatch) => {
    // eslint-disable-next-line no-undef
    return new Promise((resolve) => {
        const handleEventListener = (e) => {
            const message = JSON.parse(e.data) || "";
            if (
                message.type === "subscribe" &&
                message.request[0].msg === "ibook"
            ) {
                dispatch(
                    ACTIONS.subscribedSecurity(message.request[0].security)
                );

                resolve(true);

                ws.socket.removeEventListener("message", handleEventListener);
            }
        };

        ws.socket.addEventListener("message", handleEventListener);
    });
};

/*
    querytrade
    This function will retrieve filled orders for order details/orders table
 */

export const queryTrade = (
    ws,
    dispatch,
    firm = undefined,
    fromtime,
    userid = "*",
    dispatchAction = true
) =>
    sendMessageQuery(ws, "querytrade", {
        firm,
        fromtime,
        lastfirst: true,
        maxreturn: 1000,
        userid,
    }).then((resp) => {
        dispatchAction && dispatch(ACTIONS.queryTrade(resp || []));
        return resp;
    });

/*
    tradehistory
    This function will retrieve chart data for order details/chart section
 */

export const getTradeHistory = (
    ws,
    dispatch,
    security,
    endtime,
    lastfirst,
    maxreturn
) =>
    sendMessageQuery(
        ws,
        "tradehistory",
        {
            security,
            starttime: thirtyDaysAgo().getTime(),
            endtime,
            lastfirst,
            maxreturn,
        },
        true,
        true
    );
/*
    subscribeonopen

*/
export const subscribeOnOpen = (ws, security, unsubscribe = undefined) =>
    sendMessageQuery(
        ws,
        "subscribeonopen",
        { security, remove: unsubscribe },
        true
    );

/*

    subscribehighlow
    This query is to subscribe high, low values for portal/market watch page
*/
export const subscribeHighLow = (ws, security, unsubscribe = undefined) =>
    sendMessageQuery(
        ws,
        "subscribehighlow",
        { security, remove: unsubscribe },
        true
    );

/*
    subscribe - tob
    This query is to subscribe bid, offer values for portal/market watch page
*/
export const subscribeTob = (
    ws,
    dispatch,
    security,
    useExternalTobFeed = false
) =>
    sendMessageQuery(
        ws,
        "subscribe",
        {
            request: [
                {
                    security,
                    msg: useExternalTobFeed ? "nbbo" : "tob",
                },
            ],
        },
        true
    );

export const unsubscribeTob = (ws, security, useExternalTobFeed) =>
    sendMessageQuery(ws, "unsubscribe", {
        request: [
            {
                security,
                msg: useExternalTobFeed ? "nbbo" : "tob",
            },
        ],
    });

/*
    querymultiorders
    This function will submit a query to receive either cancelled, or open orders.
*/
export const submitMultipleOrders = (ws, dispatch, type, opt) =>
    sendMessageQuery(ws, "querymultiorders", {
        ...opt,
        fromtime: opt?.fromtime && formatFromTime(opt.fromtime),
        lastfirst: true,
        maxreturn: 1000,
    }).then((res) => {
        const typeCancel = type === "cancels";
        const results = res.filter(({ orderstatus, side }) => {
            const isCancel = orderstatus.toLowerCase() === "canceled";
            const matchingSide = opt?.side ? side === opt?.side : true;

            return matchingSide && (typeCancel ? isCancel : !isCancel);
        });
        dispatch(
            typeCancel
                ? ACTIONS.queryCancels(results)
                : ACTIONS.queryMultipleOrders(results)
        );
        return results;
    });
/*
    querytrade
    This function will retrieve trades for the activity page.
*/
export const submitActivityTrade = async (ws, dispatch, opt) => {
    await resursiveTradesCall(ws, opt, []).then((res) =>
        dispatch(
            ACTIONS.queryActivityTrade(
                res.filter(({ side }) =>
                    opt?.side && opt?.side.length !== 0
                        ? side === opt?.side
                        : true
                )
            )
        )
    );
};

/*
    querydeposit
    This function will retrieve deposits for the activity page.
*/
export const getTradeDeposits = (ws, dispatch, opt) =>
    sendMessageQuery(ws, "querydeposit", {
        ...opt,
        fromtime: opt?.fromtime && formatFromTime(opt.fromtime),
        lastfirst: true,
        maxreturn: 1000,
    }).then((res) => {
        dispatch(
            ACTIONS.queryDeposit(
                res.filter(({ side }) =>
                    opt?.side && opt?.side.length !== 0
                        ? side === opt?.side
                        : true
                )
            )
        );
        return res;
    });

/*
    querypos
    This function will retrieve credit/debits for the activity page.
*/
export const submitActivityBalance = (ws, dispatch, opt) =>
    sendMessageQuery(ws, "querypos", {
        ...opt,
        fromtime: opt?.fromtime && formatFromTime(opt.fromtime),
        lastfirst: true,
        maxreturn: 1000,
    }).then((res) => {
        dispatch(ACTIONS.queryPosition(res));
        return res;
    });

/*
    addorder
    This function is to make an order
*/

export const addOrder = (ws, order) => sendMessageQuery(ws, "addorder", order);

/*
    cancelorder

*/
export const cancelOrder = (ws, order) =>
    sendMessageQuery(ws, "cancelorder", {
        security: order.security,
        refno: order.refno,
    });
/*
    executeorder
    This function will execute negotiate order.
*/
export const executeOrder = (ws, opt) =>
    sendMessageQuery(ws, "executeorder", {
        ...opt,
    });

/*
    counterorder
    This function will counter negotiate order.
*/
export const counterOrder = (ws, opt) =>
    sendMessageQuery(ws, "counterorder", {
        ...opt,
    });

/*
    declineorder
    This function will decline negotiate order.
*/
export const declineOrder = (ws, opt) =>
    sendMessageQuery(ws, "declineorder", {
        ...opt,
    });

/*
    modifyorder
    This function is to modify an order
*/

export const modifyOrder = (ws, opt) => {
    sendMessageQuery(ws, "modifyorder", { ...opt });
};

export const subscribeCurrentSymbol = async (
    ws,
    dispatch,
    newSecurity,
    oldSecurity,
    useExternalBookFeed,
    resetContextBook,
    doGetLsHistory = true
) =>
    // eslint-disable-next-line no-undef
    Promise.allSettled(
        (newSecurity
            ? [
                  await getTradeHistory(
                      ws,
                      dispatch,
                      newSecurity,
                      Date.now(),
                      true,
                      100
                  ),
                  doGetLsHistory &&
                      (await getLsHistory(ws, dispatch, {
                          security: newSecurity,
                          starttime: new Date().setHours(0, 0, 0, 0),
                      })),
                  await subscribeToCurrentSecurity(
                      ws,
                      dispatch,
                      newSecurity,
                      useExternalBookFeed
                  ),
                  // await subscribeItrade(ws, newSecurity, useExternalTobFeed),
              ]
            : []
        ).concat(
            oldSecurity && [
                await unsubscribeFromPreviousSecurity(
                    ws,
                    dispatch,
                    oldSecurity,
                    useExternalBookFeed,
                    resetContextBook
                ),
                // await unSubscribeItrade(ws, oldSecurity, useExternalTobFeed),
            ]
        )
    );

export const subscribeSymbols = (
    ws,
    dispatch,
    symbols,
    symbolsToUnsubscribe,
    useExternalBookFeed,
    useExternalTobFeed
) => {
    let promiseArr = [];

    for (let i = 0; i < symbols.length; i++) {
        const symbol = symbols[i];

        promiseArr = [
            ...promiseArr,
            subscribeOnOpen(ws, symbol.security),
            subscribeHighLow(ws, symbol.security),
            subscribeTob(ws, dispatch, symbol.security, useExternalTobFeed),
            subscribeItrade(ws, symbol.security, useExternalTobFeed),
        ];
    }

    for (let i = 0; i < symbolsToUnsubscribe.length; i++) {
        const symbol = symbolsToUnsubscribe[i];

        promiseArr = [
            ...promiseArr,
            subscribeOnOpen(ws, symbol.security, true),
            subscribeHighLow(ws, symbol.security, true),
            unsubscribeTob(ws, symbol.security, useExternalTobFeed),
            unSubscribeItrade(ws, symbol.security, useExternalTobFeed),
        ];
    }

    dispatch(ACTIONS.subscribeToSymbols());

    // eslint-disable-next-line no-undef
    return Promise.allSettled(promiseArr);
};

/*
    lshistory
    It will return all trades for Trade History tab of books table
*/

const removeSameMatchIds = (mergedArr) => {
    return mergedArr.filter(
        (trade, index) =>
            index ===
            mergedArr.findIndex((other) => trade.matchid === other.matchid)
    );
};

const removeSamePriceQtyTimeAndMerge = (lshistory, itradeLsHistory) => {
    // If an item inside itradeLsHistory has the same price + qty + time as 1 inside lshistory
    // remove that item because we know its a duplciate
    // items inside the itradeLsHistory is UNIQUE after removing broken trades
    return lshistory.concat(
        itradeLsHistory.filter((trade) => {
            return !lshistory.find((lsHistoryItem) => {
                return (
                    lsHistoryItem.execprice === trade.execprice &&
                    lsHistoryItem.execqty === trade.execqty &&
                    lsHistoryItem.time === trade.time
                );
            });
        })
    );
};

export const mergelsHistoryAnditradeLsHistory = (
    lshistory,
    itradeLsHistory
) => {
    // We need to remove broken trades first because they have the same match ids
    // If we remove duplicates first we won't know which one is broken

    // Sometimes the lshistoy WILL NOT have matchids
    // itradeLsHistory will always have matchids
    // 2 ways to sort uniqueness
    // IF lshistory has matchids concat and remove matchids

    const doesLsHistoryHaveMatchId = lshistory.some((item) => item?.matchid);

    if (doesLsHistoryHaveMatchId)
        return removeSameMatchIds(
            removeBrokenTrades(lshistory.concat(itradeLsHistory))
        );
    // ELSE lshistory is empty OR it doesnt have matchids
    // IF lshistory  does not have matchids it means brake trade is not enabled
    // We can remove trades from itradeLsHistory where time + price + qty exists in lshistory
    // Other than that we can just concat it all
    return removeSamePriceQtyTimeAndMerge(
        lshistory,
        removeBrokenTrades(itradeLsHistory)
    );
};

export const getLsHistory = (ws, dispatch, opt) => {
    return sendMessageQuery(ws, "lshistory", { ...opt }, true).then(
        (result) => {
            dispatch(ACTIONS.queryLsHistory(isArray(result) ? result : []));
        }
    );
};

export const getLsHistoryWithoutDispatch = (ws, opt) =>
    sendMessageQuery(ws, "lshistory", { ...opt }, true);

export const subscribeToCurrentSecurity = async (
    ws,
    dispatch,
    currentSecurity,
    useExternalBookFeed
) => {
    await sendMessageQuery(
        ws,
        "subscribe",
        {
            request: [
                {
                    security: currentSecurity,
                    msg: useExternalBookFeed ? "book" : "ibook",
                },
            ],
        },
        false,
        true
    );
    dispatch(ACTIONS.subscribedSecurity(currentSecurity));
};

// Same functionality
// export const removeBrokenTrades = (lsHistoryArr) =>
//  lsHistoryArr.filter((trade) => trade.status !== "Break"
//  // Check if this is a trade that was broken but doesn't have the broken status.
//  && !lsHistoryArr.find((t) => t.matchid === trade.matchid && t.status === "Break"))

export const removeBrokenTrades = (lsHistoryArr = []) => {
    const map = new Map();
    lsHistoryArr.forEach((v) => {
        if (v.status === "Break")
            map.set(v.matchid, map.has(v.matchid) ? map.get(v.matchid) + 1 : 1);
    });
    return lsHistoryArr.filter((v) => map.get(v.matchid) === undefined);
};

export const unsubscribeFromPreviousSecurity = async (
    ws,
    dispatch,
    previousSecurity,
    useExternalBookFeed,
    resetContextBook
) => {
    await sendMessageQuery(
        ws,
        "unsubscribe",
        {
            request: [
                {
                    security: previousSecurity,
                    msg: useExternalBookFeed ? "book" : "ibook",
                },
            ],
        },
        false,
        true
    );
    // dispatch(ACTIONS.resetBooks());
    resetContextBook();
};

/*
    subscribe - itrade
    Subscribe for trade history data of books table
*/

export const subscribeItrade = (ws, security, useExternalTobFeed) =>
    sendMessageQuery(
        ws,
        "subscribe",
        {
            request: [
                {
                    security,
                    msg: useExternalTobFeed ? "etrade" : "itrade",
                },
            ],
        },
        false,
        true
    );

/*
    unsubscribe - itrade
    Unsubscribe for trade history data of books table
*/

export const unSubscribeItrade = (ws, security, useExternalTobFeed) =>
    sendMessageQuery(
        ws,
        "unsubscribe",
        {
            request: [
                {
                    security,
                    msg: useExternalTobFeed ? "etrade" : "itrade",
                },
            ],
        },
        false,
        true
    );
/*
    Trade Order Table Seperation Functions

*/

export const addTabToParent = (
    ParentRefs,
    ContentRefs,
    setEventKey,
    setOldEventKey,
    hiddenTabs,
    setHiddenTabs,
    keySubfix = ""
) => {
    const draggingRef = Object.values(ContentRefs).find((ref) =>
        ref.current.className.includes("dragging")
    );

    const shownNewTab = draggingRef.current.id.replace(keySubfix, "");

    const newTabToShow = draggingRef.current.id.replace(
        keySubfix ? "" : "-screen",
        ""
    );

    const newHiddenTabs = Array.from(hiddenTabs).map((tab) =>
        tab === draggingRef.current.id.replace(!keySubfix ? "" : "-screen", "")
            ? newTabToShow
            : tab
    );

    if (newHiddenTabs.every((tab) => !tab.endsWith("-screen"))) return;

    for (let i = 0; i < newHiddenTabs.length; i++) {
        const tab = newHiddenTabs[i];

        const validOldKey =
            keySubfix && !tab.endsWith(keySubfix)
                ? `${tab}-screen`
                : !keySubfix && tab.endsWith("-screen")
                ? tab.replace("-screen", "")
                : undefined;

        if (validOldKey) {
            setOldEventKey(validOldKey);
            break;
        }
    }

    ParentRefs[shownNewTab].current.appendChild(draggingRef.current);
    setHiddenTabs(newHiddenTabs);
    setEventKey(shownNewTab);
};

export const queryScheduleSymbolStatus = (ws, security) =>
    sendMessageQuery(ws, "queryschedulesymbolstatus", { security }, true);

export const getPortalAuctions = (dispatch) =>
    request("/public/auctions?symbol=*", "GET").then(({ data }) =>
        dispatch(ACTIONS.addPortalAuctionInformation(data))
    );
