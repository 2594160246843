import { memo } from "react";
import { Col, FormControl, InputGroup, Row } from "react-bootstrap";

import {
    addCommas,
    countDecimals,
    getZeroes,
    isMultipleDot,
    removeCommas,
    removeNonNumeric,
} from "../../../../helpers/other";
import {
    validateNoFloats,
    validatePosNumber,
} from "../../../../helpers/validate";

// eslint-disable-next-line react/display-name
export const OrderFormControl = memo(
    ({ label, value, setValue, symbol, currencyLabel }) => {
        const { zeroCount, zeroes } = getZeroes(label, symbol);

        return (
            <Row
                className={`d-flex align-items-center pb-2 order-ticket-flex-item ${label.toLowerCase()}-field`}
            >
                <Col xs={4}>{label}</Col>
                <Col xs={8}>
                    <InputGroup size="sm">
                        <FormControl
                            className={"input-form-" + label}
                            value={addCommas(value) || ""}
                            onChange={(e) => {
                                if (
                                    !isMultipleDot(e.target.value) &&
                                    countDecimals(e.target.value) <=
                                        zeroCount &&
                                    !e.target.value.startsWith(".")
                                )
                                    setValue(
                                        removeCommas(
                                            removeNonNumeric(e.target.value)
                                        )
                                    );
                            }}
                            onKeyDown={
                                zeroCount ? validatePosNumber : validateNoFloats
                            }
                            type="text"
                            placeholder={`0${zeroes}`}
                            min={0}
                            tabIndex={0}
                        />
                        <InputGroup.Append>
                            <InputGroup.Text>{currencyLabel}</InputGroup.Text>
                        </InputGroup.Append>
                    </InputGroup>
                </Col>
            </Row>
        );
    }
);
