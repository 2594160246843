import AlertsWrapper from "../../../components/admin/risk/AlertsWrapper";

const RiskAlert = () => {
    const columns = [
        {
            dataField: "time",
            text: "Time",
            sort: true,
            formatter: (rowContent, row) => {
                return (
                    <span>{new Date(parseInt(row.time)).toLocaleString()}</span>
                );
            },
        },
        {
            dataField: "account",
            text: "Account",
            sort: true,
        },
        {
            dataField: "refno",
            text: "Refno",
            sort: true,
            formatter: (rowContent, row) => {
                return (
                    <span>
                        {row?.related_refs &&
                            row.related_refs.split(",").join(", ")}
                    </span>
                );
            },
        },
        {
            dataField: "errordetails",
            text: "Error Details",
            sort: true,
        },
    ];

    const subtypeOptions = [
        { value: "LAYER", label: "Layering" },
        { value: "SPOOF", label: "Spoofing" },
        { value: "momentum", label: "Momentum Ignition" },
        { value: "Churning", label: "Churning" },
        { value: "Pre-Arranged Trading", label: "Pre-Arranged Trading" },
        { value: "High Volume", label: "High Volume" },
        { value: "Order to Trade Ratio", label: "Order to Trade Ratio" },
    ];

    const periodOptions = [
        { value: "all", label: "All" },
        { value: "seven", label: "7 days ago" },
        { value: "thirty", label: "30 days ago" },
    ];

    return (
        <AlertsWrapper
            title="Pattern Detection Alerts"
            defaultSubtype={{ value: "SPOOF", label: "Spoofing" }}
            columns={columns}
            subtypeOptions={subtypeOptions}
            periodOptions={periodOptions}
        />
    );
};

export default RiskAlert;
