import {
    customTradeEnabled,
    emailIssuer,
    hideMyFundsPage,
    isQMSClient,
    portalLogoURL,
    showAssetBuilder,
    showCompanyDocumentsPage,
    showFundsPage,
    showPortfolio,
} from "../../config";
import { convertStylingVar, isAdmin } from "../../helpers/other";
import { useStore } from "../../Store";
import {
    DropdownMenuWrapper,
    ParentSidebarItem,
    SidebarItem,
    SidebarWrappper,
} from "../SidebarWrapper";

export const PortalSidebar = () => {
    const { state } = useStore();
    const { layoutConfigurationOptions } = state.trade;
    const { account: user } = state;

    const isUserAdmin = isAdmin(user.account.data.user_type, user.roles);

    const stylingConfig = {
        background: convertStylingVar(
            layoutConfigurationOptions.styling.backgroundColor
        ),
        color: `${convertStylingVar(
            layoutConfigurationOptions.styling.fontColor
        )} !important`,
    };

    const isTradeScreen = window.location.pathname.includes("/portal/trade");

    return (
        <SidebarWrappper
            homePath={portalLogoURL || "/portal"}
            isURL={!!portalLogoURL}
            headerName="PLATFORM"
            sidebarName="portal-sidebar"
            style={
                isTradeScreen
                    ? stylingConfig
                    : {
                          color: "var(--PortalSidebar-color)",
                          background: "var(--PortalSidebar-background)",
                      }
            }
            settingsBottomLinkEnabled={window.location.pathname.includes(
                "/portal/trade"
            )}
            bottomQuickLinkName={isUserAdmin ? "Administration" : null}
            bottomQuickLinkPath="/admin"
        >
            {!isQMSClient && (
                <DropdownMenuWrapper name={"Market"} className="portal">
                    <SidebarItem
                        name={customTradeEnabled ? "Trading" : "Market Watch"}
                        path={
                            customTradeEnabled
                                ? "/portal/trade/custom"
                                : "/portal"
                        }
                        className="portal"
                    />
                    <SidebarItem
                        name={"Transactions"}
                        path="/portal/transactions"
                        className="portal"
                    />
                    {!hideMyFundsPage && (
                        <SidebarItem
                            name="My Funds"
                            path="/portal/my-funds"
                            className="portal"
                        />
                    )}
                </DropdownMenuWrapper>
            )}
            {isQMSClient && (
                <>
                    <ParentSidebarItem
                        name="Indications of Interest"
                        path="/portal/market-watch"
                    />
                    <ParentSidebarItem
                        name="Instructions"
                        path="/portal/instructions"
                    />
                </>
            )}
            {showCompanyDocumentsPage && (
                <ParentSidebarItem
                    name="Company Documents"
                    path="/portal/docs"
                />
            )}
            {showPortfolio && (
                <ParentSidebarItem name="Portfolio" path="/portal/portfolio" />
            )}
            {showFundsPage && (
                <ParentSidebarItem name="Funds" path="/portal/funds" />
            )}
            {showAssetBuilder && (
                <ParentSidebarItem name="Assets" path="/assets" />
            )}
            {emailIssuer && (
                <ParentSidebarItem
                    name="Notifications"
                    path="/portal/notifications"
                />
            )}
        </SidebarWrappper>
    );
};
