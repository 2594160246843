import { memo, useState } from "react";
import { Button, Card, Row } from "react-bootstrap";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { toast } from "react-toastify";

import {
    deleteUserFile,
    getUserFile,
    uploadUserFile,
} from "../../../helpers/other";
import { useStore } from "../../../Store";
import { useSocket } from "../../../Websocket";
import { AddAttachmentToCaseModal } from "./AddAttachmentToCaseModal";
import { AttachmentDetailsModal } from "./AttachmentDetailsModal";

// eslint-disable-next-line react/display-name
export const AttachmentsTab = memo(
    ({ attachments, addAttachment, removeAttachment }) => {
        const ws = useSocket();
        const { dispatch } = useStore();

        const [selectedAttachment, setSelectedAttachment] = useState({});
        const [disableUpload, setDisableUpload] = useState(false);

        const [showAddAttachmentModal, setShowAddAttachmentModal] =
            useState(false);
        const handleShowAddAttachmentModal = () =>
            setShowAddAttachmentModal(true);
        const handleHideAddAttachmentModal = () =>
            setShowAddAttachmentModal(false);

        const [showAttachmentDetailsModal, setShowAttachmentDetailsModal] =
            useState(false);
        const handleShowAttachmentDetailsModal = (attchmt, key) => {
            setSelectedAttachment({ ...attchmt, key });
            setShowAttachmentDetailsModal(true);
        };
        const handleHideAttachmentDetailsModal = () =>
            setShowAttachmentDetailsModal(false);

        const handleUploadFile = (file, description) => {
            setDisableUpload(true);

            uploadUserFile(ws, dispatch, file).then(() => {
                addAttachment({ filename: file.name, description });

                handleHideAddAttachmentModal();

                setDisableUpload(false);

                toast.success("Attachment successfully added.");
            });
        };

        const handleDeleteFile = (file) => {
            deleteUserFile(ws, file.filename).then(() => {
                removeAttachment(file.key);

                handleHideAttachmentDetailsModal();

                toast.success("Attachment succesfully deleted.");
            });
        };

        return (
            <>
                <Button
                    size="sm"
                    className="risk-tab-button"
                    onClick={handleShowAddAttachmentModal}
                >
                    Add Attachment
                </Button>
                <AddAttachmentToCaseModal
                    show={showAddAttachmentModal}
                    handleClose={handleHideAddAttachmentModal}
                    handleSubmit={handleUploadFile}
                    disableUpload={disableUpload}
                />
                <AttachmentDetailsModal
                    show={showAttachmentDetailsModal}
                    handleClose={handleHideAttachmentDetailsModal}
                    attachment={selectedAttachment}
                    deleteAttachment={handleDeleteFile}
                />
                <Row noGutters style={{ gap: "0.5rem" }}>
                    {attachments.map((attchmt, i) => (
                        <Card style={{ width: "18rem" }} key={i}>
                            <Card.Body>
                                <Card.Subtitle className="mb-2 text-dark">
                                    {attchmt.filename}{" "}
                                    <AiOutlineInfoCircle
                                        className="float-right cursor-pointer"
                                        onClick={() =>
                                            handleShowAttachmentDetailsModal(
                                                attchmt,
                                                i
                                            )
                                        }
                                    />
                                </Card.Subtitle>
                                <Card.Text style={{ height: "7rem" }}>
                                    {attchmt?.description &&
                                        attchmt?.description.substring(0, 205)}
                                    {attchmt?.description &&
                                        attchmt?.description.length > 205 &&
                                        "..."}
                                </Card.Text>
                                <Button
                                    onClick={() =>
                                        getUserFile(ws, attchmt.filename)
                                    }
                                >
                                    Download
                                </Button>
                            </Card.Body>
                        </Card>
                    ))}
                </Row>
            </>
        );
    }
);
