import { useState } from "react";
import { Button, Col, Form, FormControl, Row } from "react-bootstrap";

import { activateSymbol } from "../../../helpers/market";
import { validatePosNumber } from "../../../helpers/validate";
import { useSocket } from "../../../Websocket";

const SymbolActivation = () => {
    const ws = useSocket();
    const [symbol, setSymbol] = useState();
    const [value, setValue] = useState(0);

    const handleActivate = () => activateSymbol(ws, symbol, value);

    return (
        <div className="m-4">
            <Row noGutters className="forms-header">
                <Col>
                    <h4>Symbol Activation</h4>
                </Col>
            </Row>
            <Row noGutters>
                <Col xs={6} sm={3}>
                    {/* Symbol Input */}
                    <Form.Label>Symbol:</Form.Label>
                    <FormControl
                        value={symbol || ""}
                        size="sm"
                        placeholder="Symbol"
                        onChange={(e) => setSymbol(e.target.value)}
                    />
                </Col>
                <Col xs={6} sm={3} className="mx-2">
                    {/* Value Input */}
                    <Form.Label>Away %:</Form.Label>
                    <FormControl
                        value={value || 0}
                        size="sm"
                        type="number"
                        max={0.99}
                        min={0.01}
                        step="0.01"
                        placeholder="Value"
                        onKeyDown={(e) =>
                            e.target.value <= 0.99 &&
                            e.target.value >= 0.01 &&
                            validatePosNumber(e)
                        }
                        onChange={(e) => setValue(e.target.value)}
                    />
                </Col>
                <Col className="d-flex justify-content-end flex-column">
                    <div>
                        <Button
                            size="sm"
                            onClick={handleActivate}
                            className="px-4"
                        >
                            Activate
                        </Button>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default SymbolActivation;
