import { useState } from "react";
import { Button, Col, Collapse, FormControl, Row } from "react-bootstrap";
import { toast } from "react-toastify";

import { isBrassicaSandbox } from "../../../config";
import { approveKYCApplication } from "../../../helpers/brassica";
import {
    contactUser,
    updateRegistrationSubmission,
} from "../../../helpers/onboarding";
import { useStore } from "../../../Store";
import { useSocket } from "../../../Websocket";

export const CommentEmailBox = ({ submission, setSubmission }) => {
    const ws = useSocket();
    const { state, dispatch } = useStore();

    const {
        account: {
            account: { data: loggedInUser },
        },
    } = state;

    const [view, setView] = useState(false);
    const [type, setType] = useState("");

    const [comment, setComment] = useState("");
    const [email, setEmail] = useState({
        subject: "",
        content: "",
    });

    const toggleView = (typ) => {
        setType(typ);

        return setView(!(view === true && typ === type));
    };

    const addComment = (cmt) => {
        const newSubmission = {
            ...submission,
            data: {
                ...submission?.data,
                activity: [
                    ...(submission?.data?.activity || []),
                    {
                        date_created: new Date().getTime(),
                        action_by: loggedInUser?.userid || loggedInUser?.userId,
                        action_type: "add_comment",
                        action_data: cmt,
                    },
                ],
            },
        };

        updateRegistrationSubmission(ws, dispatch, newSubmission, true).then(
            () => {
                setSubmission({ ...submission, data: newSubmission });
                setComment("");
            }
        );
    };

    const emailApplicant = () =>
        contactUser({
            ...email,
            submission_id: submission.submission_id,
            name: "test name",
        });

    // eslint-disable-next-line no-unused-vars
    const manuallyApproveKYCApplication = () => {
        const kycApplicationId =
            submission?.data?.form?.brassica?.kycApplications[0];
        // 4.4
        approveKYCApplication(ws, kycApplicationId).finally(() => {
            toast.success("KYC Application Approved");

            updateRegistrationSubmission(
                ws,
                dispatch,
                {
                    ...submission,
                    data: {
                        ...submission.data,
                        activity: [
                            ...(submission?.data?.activity || []),
                            {
                                date_created: new Date().getTime(),
                                action_by:
                                    loggedInUser?.userid ||
                                    loggedInUser?.userId,
                                action_type:
                                    "kyc_application_manually_approved",
                                action_data: undefined,
                            },
                        ],
                    },
                },
                false
            ).then((updatedSubmission) => {
                setSubmission({ ...submission, data: updatedSubmission[1] });
            });
        });
    };

    return (
        <>
            <br />
            <br />
            <Collapse in={view}>
                {type === "comment" ? (
                    <div className="">
                        <div className="mb-2">Comment:</div>
                        <FormControl
                            size="sm"
                            as="textarea"
                            onChange={({ target: { value } }) =>
                                setComment(value)
                            }
                            value={comment}
                        />
                        <div className="d-flex justify-content-end">
                            <Button
                                onClick={() => addComment(comment)}
                                size="sm"
                                className="my-2 px-4"
                                disabled={comment.length === 0}
                            >
                                Post
                            </Button>
                        </div>
                    </div>
                ) : (
                    <div className="">
                        <Row className="mb-2">
                            <Col xs={2}>Subject:</Col>
                            <Col>
                                <FormControl
                                    size="sm"
                                    placeholder="Subject.."
                                    onChange={({ target: { value } }) =>
                                        setEmail({ ...email, subject: value })
                                    }
                                />
                            </Col>
                        </Row>
                        <div className="mb-2">Email:</div>
                        <FormControl
                            size="sm"
                            as="textarea"
                            rows={4}
                            onChange={({ target: { value } }) =>
                                setEmail({ ...email, content: value })
                            }
                        />
                        <div className="d-flex justify-content-end">
                            <Button
                                onClick={emailApplicant}
                                size="sm"
                                className="my-2 px-4"
                                disabled={
                                    email.subject.length === 0 ||
                                    email.content.length === 0
                                }
                            >
                                Send Email
                            </Button>
                        </div>
                    </div>
                )}
            </Collapse>
            <Row noGutters className="d-flex justify-content-end">
                <Button
                    size="sm"
                    className="mr-1"
                    onClick={() => toggleView("comment")}
                >
                    Add Comment
                </Button>
                <Button
                    size="sm"
                    onClick={() => toggleView("email")}
                    className="mr-1"
                >
                    Email Applicant
                </Button>

                {isBrassicaSandbox && (
                    <Button
                        size="sm"
                        disabled={
                            !(
                                submission?.data?.activity?.some(
                                    (a) =>
                                        a.action_type ===
                                        "custody_account_application_submitted"
                                ) &&
                                !submission?.data?.activity?.some(
                                    (b) =>
                                        b.action_type ===
                                        "custody_agreement_signed"
                                )
                            )
                        }
                        onClick={() => manuallyApproveKYCApplication()}
                    >
                        Approve KYC Application
                    </Button>
                )}
            </Row>
        </>
    );
};
