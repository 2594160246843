import { memo, useEffect, useState } from "react";
import { FormControl } from "react-bootstrap";

import { tradeDestinations } from "../../../../config";
import { storeOptions } from "../../../../helpers/display-options";
import { useStore } from "../../../../Store";
import { useSocket } from "../../../../Websocket";

// eslint-disable-next-line react/display-name
export const OrdersFilterSelect = memo(({ layoutKey }) => {
    // Filter by symbol, buy/sell, and destination
    const { state, dispatch } = useStore();
    const ws = useSocket();

    const symbols = state.trade.symbols.map((order) => order.security);
    const layoutConfigurationOptions = state.trade.layoutConfigurationOptions;
    // const allOrders = [
    //     ...state.trade.orders.fill,
    //     ...state.trade.orders.negotiate,
    //     ...state.trade.orders.cancelled,
    //     ...state.trade.orders.pending,
    // ];
    // const destinationsUsed = new Set(allOrders.map((order) => order.category));
    const destinationsUsed = []
    
    const [filter, setFilter] = useState({
        symbol: "",
        side: "",
        destination: "",
    });

    const handleChange = (e) => {
        const newFilter = {
            [layoutKey]: { ...filter, [e.target.name]: e.target.value },
        };

        setFilter({ ...filter, [e.target.name]: e.target.value });

        storeOptions(
            ws,
            dispatch,
            state.account.account?.data,
            "trade_preferences",
            "trade_screen_configuration",
            {
                ...layoutConfigurationOptions.activeOrderTableFiltering,
                ...newFilter,
            },
            "activeOrderTableFiltering"
        );
    };

    useEffect(() => {
        setFilter({
            ...filter,
            ...layoutConfigurationOptions.activeOrderTableFiltering[layoutKey],
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [layoutConfigurationOptions.activeOrderTableFiltering[layoutKey]]);

    return (
        <div className="d-flex ">
            <FormControl
                as="select"
                size="sm"
                className="w-50"
                onChange={handleChange}
                name="symbol"
                value={filter?.symbol || ""}
            >
                <option value="">Filter by Symbol</option>
                {symbols.map((opt, i) => (
                    <option key={i} value={opt}>
                        {opt}
                    </option>
                ))}
            </FormControl>
            <FormControl
                as="select"
                className="mx-1 w-25"
                size="sm"
                onChange={handleChange}
                name="side"
                value={filter?.side || ""}
            >
                <option value="">Side</option>
                <option value="B">Buy</option>
                <option value="S">Sell</option>
            </FormControl>

            <FormControl
                as="select"
                size="sm"
                className="w-25"
                onChange={handleChange}
                name="destination"
                value={filter?.destination || ""}
            >
                <option value="">Destination</option>
                {Array.from(destinationsUsed)
                    .concat(tradeDestinations)
                    .map((opt, i) => (
                        <option key={i} value={opt}>
                            {opt}
                        </option>
                    ))}
            </FormControl>
        </div>
    );
});
