import { memo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import {
    AiOutlineCopy,
    AiOutlineDelete,
    AiOutlineEdit,
    AiOutlineEye,
} from "react-icons/ai";
import { useNavigate } from "react-router-dom";

import { TableRenderer } from "../../../components/portal/TableRenderer";
import { ConfirmCopyProfileModal } from "./ConfirmCopyProfileModal";
import { DeleteProfileModal } from "./DeleteProfileModal";

// eslint-disable-next-line react/display-name
export const AssetProfileTable = memo(
    ({ deleteAssetProfile, profiles, loading }) => {
        const navigate = useNavigate();
        const [selectedProfile, setSelectedProfile] = useState();

        const [showDeleteModal, setShowDeleteModal] = useState(false);
        const handleShowDeleteModal = (profile) => {
            setSelectedProfile(profile);
            setShowDeleteModal(true);
        };
        const handleHideDeleteModal = () => setShowDeleteModal(false);

        const [showCopyModal, setShowCopyModal] = useState(false);
        const handleHideCopyProfileModal = () => setShowCopyModal(false);
        const handleShowCopyProfileModal = (profile) => {
            setSelectedProfile(profile);
            setShowCopyModal(true);
        };

        const columns = [
            {
                dataField: "image",
                text: "Image",
                formatter: (content) => (
                    <img src={content} alt="" height={"50px"} />
                ),
            },
            {
                dataField: "name",
                text: "Name",
                sort: true,
                formatter: (content) => `${content || ""}`,
            },
            {
                dataField: "templateId.name",
                text: "Asset Type",
                sort: true,
            },
            {
                dataField: "isPublished",
                text: "Published Status",
                formatter: (rowContent, row) => {
                    return (
                        <Row noGutters>
                            <Col
                                className={
                                    row.isPublished
                                        ? "text-success"
                                        : "text-danger"
                                }
                            >
                                {row.isPublished ? "Published" : "Unverified"}
                            </Col>
                            <Col className="text-right">
                                {row.isPublished && (
                                    <AiOutlineEye
                                        onClick={() =>
                                            navigate(`/assets/${row.symbol}`)
                                        }
                                        className="mr-2 cursor-pointer"
                                    />
                                )}
                                <AiOutlineEdit
                                    onClick={() =>
                                        navigate(
                                            `/admin/asset-profiles/${row?.id}/edit`
                                        )
                                    }
                                    className="mr-2 cursor-pointer"
                                />
                                <AiOutlineCopy
                                    onClick={() =>
                                        handleShowCopyProfileModal(row)
                                    }
                                    className="mr-2 cursor-pointer"
                                />
                                <AiOutlineDelete
                                    onClick={() =>
                                        !row.isPublished &&
                                        handleShowDeleteModal(row)
                                    }
                                    className={
                                        !row.isPublished
                                            ? "cursor-pointer"
                                            : "text-gray"
                                    }
                                />
                            </Col>
                        </Row>
                    );
                },
            },
        ];

        return (
            <>
                {showDeleteModal && (
                    <DeleteProfileModal
                        show={showDeleteModal}
                        handleDelete={() =>
                            deleteAssetProfile(selectedProfile).then(() =>
                                handleHideDeleteModal()
                            )
                        }
                        handleClose={handleHideDeleteModal}
                        profile={selectedProfile}
                    />
                )}
                {showCopyModal && (
                    <ConfirmCopyProfileModal
                        show={showCopyModal}
                        handleCopy={() =>
                            navigate(
                                `/admin/asset-profiles/create?copy=${selectedProfile?.id}`
                            )
                        }
                        handleClose={handleHideCopyProfileModal}
                    />
                )}
                <TableRenderer
                    keyField="id"
                    sortBy="isPublished"
                    sortOrder="desc"
                    data={profiles || []}
                    columns={columns}
                    noDataIndication="No Asset Profiles Available."
                    loading={loading}
                    pagination
                    wrapperClasses="size-sm"
                />
            </>
        );
    }
);
