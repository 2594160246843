/* eslint-disable react/display-name */
import { memo, useState } from "react";
import {
    Button,
    Col,
    Form,
    ToggleButton,
    ToggleButtonGroup,
} from "react-bootstrap";
import Select from "react-select";
import { toast } from "react-toastify";

import { enableUserAccounts } from "../../../../config";
import { mapToSelect } from "../../../../helpers/other";
import { validatePosNumber } from "../../../../helpers/validate";

export const DepositWithdrawForm = memo(
    ({ accounts, users, firms, securities, submitTransaction }) => {
        const userOptions = mapToSelect(users, "userid");
        const accountOptions = mapToSelect(accounts, "userid");
        const firmOptions = firms.map((elem) => ({
            value: elem.firm,
            label: elem.firm,
        }));
        const securityOptions = mapToSelect(securities, "security");

        const [isUser, setIsUser] = useState(true);
        const [user, setUser] = useState("");
        const [account, setAccount] = useState("");
        const [firm, setFirm] = useState("");
        const [security, setSecurity] = useState("");
        const [transType, setTransType] = useState("deposit");
        const [amount, setAmount] = useState(0);

        const disabled =
            user.length === 0 &&
            firm.length === 0 &&
            security.length === 0 &&
            amount === 0;

        const reset = () => {
            setUser("");
            setAccount("");
            setFirm("");
            setSecurity("");
            setAmount(0);
        };

        const onSwitch = () => {
            setIsUser(!isUser);
            reset();
        };

        return (
            <Form>
                <Col sm={6} xl={3}>
                    {enableUserAccounts && (
                        <Form.Group>
                            <Form.Label className="size-sm">
                                User/Account Switch
                            </Form.Label>
                            <Form.Check
                                type="switch"
                                id="toggle-switch"
                                checked={isUser}
                                onChange={onSwitch}
                            />
                        </Form.Group>
                    )}
                    {isUser ? (
                        <Form.Group>
                            <Form.Label className="size-sm">User</Form.Label>
                            <Select
                                className="deposit-user-select"
                                value={{
                                    value: user,
                                    label: user || "Select...",
                                }}
                                options={userOptions}
                                onChange={({ value }) => {
                                    setUser(value);

                                    const selectedUser = users.filter(
                                        (usr) => usr.userid === value
                                    )[0];

                                    setFirm(selectedUser.firm);
                                }}
                            />
                        </Form.Group>
                    ) : (
                        <Form.Group>
                            <Form.Label className="size-sm">Account</Form.Label>
                            <Select
                                className="deposit-account-select"
                                value={{
                                    value: account,
                                    label: account || "Select...",
                                }}
                                options={accountOptions}
                                onChange={({ value }) => {
                                    setAccount(value);

                                    const selectedAccount = accounts.filter(
                                        (acc) => acc.userid === value
                                    )[0];

                                    setFirm(selectedAccount.firm);
                                }}
                            />
                        </Form.Group>
                    )}
                    <Form.Group>
                        <Form.Label className="size-sm">Firm</Form.Label>
                        <Select
                            className="deposit-firm-select"
                            value={{
                                value: firm,
                                label: firm || "Select...",
                            }}
                            options={firmOptions}
                            onChange={({ value }) => setFirm(value)}
                        />
                    </Form.Group>
                    <Form.Group>
                        <ToggleButtonGroup
                            className="w-100
                            deposit-withdraw-buttons"
                            name="deposit-withdraw-buttons"
                            onChange={(val) => setTransType(val)}
                            size="sm"
                        >
                            <ToggleButton
                                value={"deposit"}
                                id="tgl-deposit"
                                checked
                            >
                                Deposit
                            </ToggleButton>
                            <ToggleButton value={"withdraw"} id="tgl-withdraw">
                                Withdraw
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label className="size-sm">Security</Form.Label>
                        <Select
                            className="deposit-security-select"
                            value={{
                                value: security,
                                label: security || "Select...",
                            }}
                            options={securityOptions}
                            onChange={({ value }) => setSecurity(value)}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label className="size-sm">Amount</Form.Label>
                        <Form.Control
                            className="deposit-amount-input"
                            value={amount}
                            onChange={({ target }) => setAmount(target.value)}
                            onKeyDown={validatePosNumber}
                            type="number"
                            min={0}
                            size="sm"
                        />
                    </Form.Group>
                    <Button
                        type="submit"
                        disabled={disabled}
                        onClick={(ev) => {
                            ev.preventDefault();
                            if (isNaN(parseFloat(amount)))
                                return toast.error("Invalid number.");
                            submitTransaction({
                                ...(isUser
                                    ? {
                                          userid:
                                              users.find(
                                                  (usr) =>
                                                      (usr.userid ||
                                                          usr.userId) === user
                                              )?.complianceid ?? user,
                                      }
                                    : { account: account }),
                                firm,
                                type: transType,
                                security,
                                amount,
                            });
                        }}
                        size="sm"
                    >
                        Submit
                    </Button>
                </Col>
            </Form>
        );
    }
);
