import { Button, Card, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export const AssetCard = ({ asset, hidden }) => {
    const navigate = useNavigate();

    return (
        <Card
            style={{ width: "18rem", display: "inline-block" }}
            className="mr-2 mt-2 no-select"
        >
            <Card.Img
                variant="top"
                src={asset?.image}
                alt=""
                width="130px"
                height="130px"
                style={{ objectFit: "contain" }}
                draggable={false}
            />
            <Card.Body>
                <Card.Title>{asset?.symbol}</Card.Title>
                <Card.Text
                    style={{
                        height: "100px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                >
                    {asset?.description || ""}
                </Card.Text>
                <Row noGutters className="justify-content-between">
                    <Button
                        variant="primary"
                        onClick={() => navigate(`/assets/${asset?.symbol}`)}
                    >
                        View Profile
                    </Button>
                    {!hidden && (
                        <Button
                            variant="primary"
                            className="px-4"
                            onClick={() =>
                                navigate(`/portal/trade/${asset?.symbol}`)
                            }
                        >
                            Trade
                        </Button>
                    )}
                </Row>
            </Card.Body>
        </Card>
    );
};
