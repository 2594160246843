import { memo, useState } from "react";
import { Button, Row } from "react-bootstrap";

import { formatTableColumn, handleGetRefno } from "../../../helpers/other";
import { TableRenderer } from "../../portal/TableRenderer";
import { AddAlertToCaseModal } from "./AddAlertToCaseModal";

// eslint-disable-next-line react/display-name
export const AlertsInThisCaseTab = memo(
    ({ traders, caseAlerts, addAlert, removeAlert }) => {
        const [showAddAlertModal, setShowAddAlertModal] = useState(false);
        const handleOpenShowAddAlertModal = () => setShowAddAlertModal(true);
        const handleCloseShowAddAlertModal = () => setShowAddAlertModal(false);

        return (
            <>
                <Button
                    size="sm"
                    className="risk-tab-button"
                    onClick={handleOpenShowAddAlertModal}
                >
                    Add Alert
                </Button>
                <AddAlertToCaseModal
                    show={showAddAlertModal}
                    handleClose={handleCloseShowAddAlertModal}
                    traders={traders}
                    caseAlerts={caseAlerts}
                    addAlert={addAlert}
                    removeAlert={removeAlert}
                />
                <TableRenderer
                    keyField="eventid"
                    data={caseAlerts}
                    columns={[
                        {
                            dataField: "refno",
                            text: "Ref no.",
                            sort: true,
                            formatter: (rowContent, row) => (
                                <span>{handleGetRefno(row.errordetails)}</span>
                            ),
                        },
                        ...["time", "account"].map((col) =>
                            formatTableColumn(col)
                        ),
                        {
                            dataField: "errordetails",
                            text: "Error Details",
                            formatter: (rowContent) => (
                                <Row noGutters>{rowContent}</Row>
                            ),
                        },
                    ]}
                    noDataIndication="No alerts in this case."
                />
            </>
        );
    }
);
