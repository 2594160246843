/* eslint-disable react/display-name */

import { memo, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";

import { FromTimeSelector } from "../../../FromTimeSelector";

export const LoadForm = memo(({ users, symbols, firms, getData }) => {
    const [enableTime, setEnableTime] = useState(false);
    const [fromTime, setFromTime] = useState(new Date());
    const [userId, setUserId] = useState([]);
    const [complianceId, setComplianceId] = useState([]);
    const [symbol, setSymbol] = useState([]);
    const [firm, setFirm] = useState([]);
    const [side, setSide] = useState("");

    return (
        <Container fluid>
            <Form>
                <Row>
                    <Col sm={6} lg={3} xl={2}>
                        <Form.Group>
                            <Form.Label className="size-sm">User ID</Form.Label>
                            <Typeahead
                                id="user"
                                labelKey="userid"
                                onChange={(val) => {
                                    setUserId(val);
                                    setComplianceId([]);
                                }}
                                options={users}
                                placeholder="All"
                                selected={userId}
                                size="sm"
                                disabled={complianceId.length > 0}
                            />
                        </Form.Group>
                    </Col>
                    <Col sm={6} lg={3} xl={2}>
                        <Form.Group>
                            <Form.Label className="size-sm">
                                Compliance ID
                            </Form.Label>
                            <Typeahead
                                id="user"
                                labelKey="complianceid"
                                onChange={(val) => {
                                    setUserId([]);
                                    setComplianceId(val);
                                }}
                                options={users}
                                placeholder="All"
                                selected={complianceId}
                                size="sm"
                                disabled={userId.length > 0}
                            />
                        </Form.Group>
                    </Col>
                    <Col sm={6} lg={3} xl={2}>
                        <Form.Group>
                            <Form.Label className="size-sm">Firm</Form.Label>
                            <Typeahead
                                type="text"
                                size="sm"
                                id="firm"
                                labelKey="firm"
                                onChange={setFirm}
                                options={firms}
                                placeholder="All"
                                selected={firm}
                                disabled={
                                    userId.length > 0 || complianceId.length > 0
                                }
                            />
                        </Form.Group>
                    </Col>
                    <Col sm={6} lg={3} xl={2}>
                        <Form.Group>
                            <Form.Label className="size-sm">Symbol</Form.Label>
                            <Typeahead
                                id="symbol"
                                labelKey="security"
                                onChange={setSymbol}
                                options={symbols}
                                placeholder="All"
                                selected={symbol}
                                size="sm"
                            />
                        </Form.Group>
                    </Col>
                    <Col sm={6} lg={3} xl={2}>
                        <Form.Group>
                            <Form.Label className="size-sm">Side</Form.Label>
                            <Form.Control
                                as="select"
                                size="sm"
                                onChange={({ target: { value } }) =>
                                    setSide(value)
                                }
                                value={side}
                            >
                                <option value="">All</option>
                                <option value="B">Buy</option>
                                <option value="S">Sell</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col sm={6} lg={3} xl={2}>
                        <FromTimeSelector
                            fromTime={fromTime}
                            setFromTime={setFromTime}
                            enableTime={enableTime}
                            setEnableTime={setEnableTime}
                        />
                    </Col>
                    <Col sm={6} lg={3} xl={2}>
                        <Button
                            className="mt-4"
                            type="submit"
                            onClick={(ev) => {
                                ev.preventDefault();

                                getData(
                                    symbol[0],
                                    userId[0] || complianceId[0] || undefined,
                                    enableTime ? fromTime : undefined,
                                    firm[0] ||
                                        userId[0] ||
                                        complianceId[0] ||
                                        undefined,
                                    !!complianceId,
                                    side
                                );
                            }}
                            size="sm"
                        >
                            Request
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Container>
    );
});
