import { useEffect, useState } from "react";
import { Row } from "react-bootstrap";

import { UserFirmSelector } from "../../../components/admin/resources/positions";
import { TableRenderer } from "../../../components/portal/TableRenderer";
import { getUsersFromWebsocket } from "../../../helpers/firm";
import { formatTableColumn } from "../../../helpers/other";
import { getPositions } from "../../../helpers/resources";
import { useStore } from "../../../Store";
import { useSocket } from "../../../Websocket";

const Positions = () => {
    const ws = useSocket();
    const { state, dispatch } = useStore();
    const [loading, setLoading] = useState(true);

    const {
        resources: { positions, posUser },
        firm: { existing_users: users, firms },
        market: { symbols: marketSymbols },
        trade: { symbols: tradeSymbols },
    } = state;

    const getUserPos = (userid, firm) =>
        getPositions(ws, dispatch, userid, firm);

    useEffect(() => {
        if (users.length === 0) {
            getUsersFromWebsocket(ws, dispatch).finally(() =>
                setLoading(false)
            );
        } else {
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="m-4">
            <Row noGutters className="forms-header">
                <h4>Positions</h4>
            </Row>
            <UserFirmSelector
                users={users}
                firms={firms}
                submitChange={getUserPos}
                loading={loading}
                existingUser={posUser}
            />
            <br />
            <TableRenderer
                keyField="security"
                data={positions.map((position) => {
                    return {
                        ...position,
                        fractionbase: tradeSymbols
                            .concat(marketSymbols)
                            .find(
                                (symbol) =>
                                    symbol.security === position.security
                            )?.fractionbase,
                    };
                })}
                noDataIndication="No positions available."
                columns={["security", "curpos", "openpos", "managedpos"].map(
                    (col) => formatTableColumn(col)
                )}
                striped
                wrapperClasses="size-sm"
            />
        </div>
    );
};

export default Positions;
