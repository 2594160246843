import { memo, useState } from "react";
import { Button, Form, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { AiOutlineInfoCircle } from "react-icons/ai";

import { Fields } from "../Fields";

const defaultFieldConfig = {
    fieldName: "",
    fieldType: "String",
    fieldDescription: "<p></p>",
    required: false,
    showFieldDescription: false,
};

// eslint-disable-next-line react/display-name
export const OnboardingTableConfigureModal = memo(
    ({ show = false, handleSave, existingTable = {} }) => {
        const [hideConfigureModal, setHideConfigureModal] = useState(false);
        const [table, setTable] = useState({
            label: "",
            subfields: [],
            max: 1,
            ...existingTable,
        });

        return (
            <Modal
                show={show}
                onHide={() =>
                    handleSave(
                        "label",
                        table?.label,
                        "subfields",
                        table?.subfields
                    )
                }
                size="xl"
                className={hideConfigureModal && "d-none"}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Configure Table</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-inline-flex w-100">
                        <Form.Group className="w-75 mr-2">
                            <Form.Label className="size-sm">Label</Form.Label>
                            <Form.Control
                                type="text"
                                size="sm"
                                value={table?.label || ""}
                                onChange={({ target: { value } }) =>
                                    setTable({ ...table, label: value })
                                }
                            />
                        </Form.Group>
                        <Form.Group className="w-25">
                            <Form.Label className="size-sm">
                                Max submissions
                                <span className="pb-2 ml-2">
                                    <OverlayTrigger
                                        overlay={
                                            <Tooltip>
                                                Control how many submissions
                                                from the user you want to allow
                                                for this table input.
                                                <br />
                                                <br />
                                                If unspecified or invalid, the
                                                limit will default to <b>
                                                    1
                                                </b>{" "}
                                                submission.
                                            </Tooltip>
                                        }
                                        placement="right"
                                    >
                                        <AiOutlineInfoCircle />
                                    </OverlayTrigger>
                                </span>
                            </Form.Label>
                            <Form.Control
                                type="number"
                                size="sm"
                                value={table?.max || 1}
                                onChange={({ target: { value } }) =>
                                    setTable({ ...table, max: value })
                                }
                            />
                        </Form.Group>
                    </div>
                    <Form.Group className="asset-section">
                        <div className="d-flex justify-content-between">
                            <Form.Label>Subfields</Form.Label>
                            <div className="d-flex ">
                                <div className="pr-2 mt-1">
                                    <OverlayTrigger
                                        overlay={
                                            <Tooltip>
                                                Add up to 8 subfields.
                                            </Tooltip>
                                        }
                                    >
                                        <AiOutlineInfoCircle />
                                    </OverlayTrigger>
                                </div>
                                <Button
                                    size="sm"
                                    onClick={() =>
                                        setTable({
                                            ...table,
                                            subfields: [
                                                ...table.subfields,
                                                { ...defaultFieldConfig },
                                            ],
                                        })
                                    }
                                    disabled={table?.subfields.length >= 8}
                                >
                                    + Add Subfield
                                </Button>
                            </div>
                        </div>
                        {table?.subfields?.length > 0 && (
                            <Fields
                                section={table}
                                setSection={setTable}
                                storeKey="subfields"
                                setParentModal={setHideConfigureModal}
                                typeTable
                                onboarding
                                isGroupField
                            />
                        )}
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="dark"
                        onClick={() =>
                            handleSave(
                                "label",
                                table?.label,
                                "subfields",
                                table?.subfields,
                                "max",
                                table?.max
                            )
                        }
                    >
                        Save
                    </Button>
                    <Button
                        variant="light"
                        onClick={() =>
                            handleSave(
                                "label",
                                table?.label,
                                "subfields",
                                table?.subfields,
                                "max",
                                table?.max
                            )
                        }
                    >
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
);
