/* eslint-disable react/display-name */
import { memo } from "react";
import { Row } from "react-bootstrap";

import {
    DateInput,
    DropdownInput,
    FileUploadInput,
    LinkInput,
    LocationInput,
    NumberInput,
    ParagraphInput,
    SocialInput,
    StringInput,
    TableConfigureInput,
} from "../../inputs";

export const FieldRenderer = memo(({ field, setField, disabled }) => {
    const { fieldName } = field;

    const renderField = (
        fld,
        onChange,
        setDisplayText = undefined,
        setPlatform = undefined
    ) => {
        const defaultProps = {
            field: fld,
            onChange,
            classes: "mx-2 my-1 size-sm",
            disabled,
        };

        switch (fld.fieldType) {
            case "String":
                return <StringInput {...defaultProps} />;
            case "Paragraph":
                return <ParagraphInput {...defaultProps} />;
            case "Location":
                return <LocationInput {...defaultProps} />;
            case "Number":
                return <NumberInput {...defaultProps} />;
            case "Date":
                return <DateInput {...defaultProps} />;
            case "Link":
                return (
                    <LinkInput
                        {...defaultProps}
                        classes="ml-2 mr-1 size-sm"
                        setDisplayText={
                            setDisplayText ??
                            ((displayText) => setField({ ...fld, displayText }))
                        }
                    />
                );
            case "Social":
                return (
                    <SocialInput
                        {...defaultProps}
                        classes="ml-2 mr-1 size-sm"
                        setPlatform={
                            setPlatform ??
                            ((platform) => setField({ ...fld, platform }))
                        }
                    />
                );
            case "File Upload":
            case "Image Upload":
                return (
                    <FileUploadInput
                        {...defaultProps}
                        classes="ml-2 mr-1 size-sm"
                    />
                );
            case "Dropdown":
                return <DropdownInput {...defaultProps} />;
            case "Subsection": {
                const newSubfields = field.subfields;

                const updateSubfields = (subf) =>
                    setField({
                        ...fld,
                        subfields: subf,
                    });

                return field.subfields.map((subfield, i) => (
                    <div key={i}>
                        <Row className="ml-3 mr-2 my-2 size-sm" noGutters>
                            <b>{subfield.fieldName}</b>
                        </Row>
                        {renderField(
                            { ...subfield, required: field.required },
                            (val) => {
                                newSubfields[i].value = val;
                                updateSubfields(newSubfields);
                            },
                            subfield.fieldType === "Link"
                                ? (displayText) => {
                                      newSubfields[i].displayText = displayText;
                                      updateSubfields(newSubfields);
                                  }
                                : undefined,
                            subfield.fieldType === "Social"
                                ? (platform) => {
                                      newSubfields[i].platform = platform;
                                      updateSubfields(newSubfields);
                                  }
                                : undefined
                        )}
                    </div>
                ));
            }
            case "Table":
                return <TableConfigureInput {...defaultProps} />;
            default:
                break;
        }
    };

    return (
        <>
            <Row className="mx-2 my-2 size-sm" noGutters>
                <b>{fieldName || "Unnamed Field"}</b>
            </Row>
            {renderField(field, (value) => setField({ ...field, value }))}
        </>
    );
});
