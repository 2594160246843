/* eslint-disable react/display-name */

import { useEffect } from "react";
import { memo } from "react";
import { useState } from "react";
import { FormControl, Row } from "react-bootstrap";

import { renderField } from "../admin/onboarding/FormRenderer";

export const BranchInput = memo((props) => {
    const { field, onChange, disabled } = props;
    const { required, value } = field;

    const [selectedBranchKey, setSelectedBranchKey] = useState(value);
    const [edited, setEdited] = useState(false);

    useEffect(() => {
        if (selectedBranchKey && edited) {
            const newBranches = Array.from(field.subfields);

            newBranches.forEach((brnch, i) => {
                if (i !== selectedBranchKey) {
                    brnch.data.forEach((subfield) => {
                        subfield.value = undefined;
                    });
                }
            });

            onChange({
                ...field,
                subfields: newBranches,
                value: selectedBranchKey,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedBranchKey]);

    return (
        <>
            <Row noGutters className="position-relative">
                <FormControl
                    as="select"
                    size="sm"
                    className="mb-2"
                    onChange={({ target: { value } }) => {
                        setEdited(true);
                        setSelectedBranchKey(value);
                    }}
                    value={selectedBranchKey}
                    disabled={disabled}
                >
                    <option value="">Select an option...</option>
                    {field.subfields.map((branch, i) => (
                        <option key={i} value={i}>
                            {branch.name}
                        </option>
                    ))}
                </FormControl>
                {required && (
                    <span className="required-icon" style={{ top: "0.25rem" }}>
                        *
                    </span>
                )}
            </Row>

            {selectedBranchKey &&
                field.subfields[selectedBranchKey].data.map((subfield, j) => (
                    <div className="mx-2" key={j}>
                        <Row noGutters className="mb-1">
                            {subfield.fieldName}
                        </Row>
                        {renderField(subfield, (value) => {
                            const newBranches = Array.from(field.subfields);

                            newBranches[selectedBranchKey].data[j].value =
                                value;

                            onChange({
                                ...field,
                                subfields: newBranches,
                            });
                        })}
                    </div>
                ))}
        </>
    );
});
