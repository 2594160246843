/* eslint-disable no-unused-vars */
import EventEmitter from "events";
import React, { createContext, useContext, useEffect, useRef } from "react";

import { removeBrokenTrades } from "./helpers/trade";

// Create the context
export const DataContext = createContext(null);

export const useSubStore = () => useContext(DataContext);

const refChangeEmitter = new EventEmitter();

// Data Provider
export const DataProvider = ({ children }) => {
    // {
    //    [symb]: [
    //        // Book data
    //        ],
    // }
    const bookDataRef = useRef({});
    const tobDataRef = useRef({});
    const tradeDataRef = useRef({});
    const symbolsDataRef = useRef([]);

    const symbolChange = "symbolChange";
    const tobChange = "tobChange";
    const tradeChange = "tradeChange";
    const bookChange = "bookChange";

    const addBook = (newBook) => {
        bookDataRef.current[newBook.security] = [
            ...(bookDataRef.current?.[newBook.security] || []),
        ];

        const isBookExist = bookDataRef.current?.[newBook.security].some(
            (book) => newBook?.id === book?.id
        );

        const books = isBookExist
            ? bookDataRef.current[newBook.security].map((book) =>
                  book?.id === newBook?.id ? newBook : book
              )
            : bookDataRef.current[newBook.security].concat(newBook);

        const replaceWithNumberIfNegotiable = (price, side) =>
            price === "Negotiable" ? (side === "S" ? 999999999999 : -1) : price;

        const buyBooks = books
            .filter((book) => book.side === "B")
            .sort(
                (a, b) =>
                    replaceWithNumberIfNegotiable(b.price, "B") -
                        replaceWithNumberIfNegotiable(a.price, "B") ||
                    (a.price !== "Negotiable" &&
                        b.price !== "Negotiable" &&
                        (a.priority && b.priority
                            ? a.priority - b.priority
                            : a.time - b.time))
            );
        const sellBooks = books
            .filter((book) => book.side === "S")
            .sort(
                (a, b) =>
                    replaceWithNumberIfNegotiable(b.price, "S") -
                        replaceWithNumberIfNegotiable(a.price, "S") ||
                    (a.price !== "Negotiable" &&
                        b.price !== "Negotiable" &&
                        (a.priority && b.priority
                            ? b.priority - a.priority
                            : b.time - a.time))
            );

        bookDataRef.current[newBook.security] = sellBooks.concat([...buyBooks]);
        refChangeEmitter.emit(bookChange, bookDataRef.current);
    };

    const removeBook = (removeBook) => {
        bookDataRef.current[removeBook.security] = bookDataRef.current[
            removeBook.security
        ].filter((book) => book.key !== removeBook.key);
        refChangeEmitter.emit(bookChange, bookDataRef.current);
    };

    const getLsHistoryToUpdate = (histories, newTrade) => {
        return histories.some(
            (history) =>
                ((newTrade.matchid && history.matchid === newTrade.matchid) ||
                    (history.time === newTrade.time &&
                        history.qty === newTrade.qty &&
                        history.price === newTrade.price)) &&
                history.status === newTrade.status
        )
            ? histories
            : [
                  ...histories,
                  {
                      ...newTrade,
                      execqty: newTrade.qty,
                      execprice: newTrade.price,
                      rec_no: histories.length + 1,
                  },
              ];
    };

    const addTradeDataForTob = (newTrade) => {
        if (newTrade.status === "Open") {
            tobDataRef.current = {
                ...(tobDataRef.current[newTrade.security] = {
                    ...tobDataRef.current?.[newTrade.security],
                    last: newTrade.price,
                    lastPricePair: {
                        ...tobDataRef.current?.[newTrade.security]?.[
                            "lastPricePair"
                        ],
                        last: newTrade.price,
                    },
                    percentChangePair: {
                        ...tobDataRef.current[newTrade.security]?.[
                            "percentChangePair"
                        ],
                        last: newTrade.price,
                    },
                }),
            };

            symbolsDataRef.current = Array.from(
                symbolsDataRef.current.map((symbol) => {
                    return symbol.security === newTrade.security &&
                        newTrade.status === "Open"
                        ? JSON.parse(
                              JSON.stringify(
                                  Object.assign(symbol, {
                                      last: newTrade.price,
                                      lastPricePair: {
                                          ...symbol["lastPricePair"],
                                          last: newTrade.price,
                                      },
                                      percentChangePair: {
                                          ...symbol["percentChangePair"],
                                          last: newTrade.price,
                                      },
                                  })
                              )
                          )
                        : symbol;
                })
            );
            refChangeEmitter.emit(symbolChange, symbolsDataRef.current);
            refChangeEmitter.emit(tobChange, tobDataRef.current);
        }
    };

    const addTradeData = (newTrade) => {
        // We will add the broken trade into history here
        // AND filter both trades out whenever we want to display
        // we might need to revisit in the future depends on type+"trade" incoming messages

        // We will move the above to the respective components
        // WE CANNOT PUT removeBrokenTrades here
        // we can inside QUERY_LS_HISTORY because that is a static array, and if its broken
        // The original matching trade will be there already
        // We cannot do that here because entire array could just be broken trades
        // If that was the case and we called removeBrokenTrades we would get an empty array

        if (tradeDataRef.current[newTrade.security]) {
            tradeDataRef.current = {
                ...tradeDataRef.current,
                [newTrade.security]: getLsHistoryToUpdate(
                    tradeDataRef.current[newTrade.security],
                    newTrade
                ).sort((x, y) => parseInt(x.time) > parseInt(y.time)),
            };
        } else {
            tradeDataRef.current = {
                ...tradeDataRef.current,
                [newTrade.security]: [newTrade],
            };
        }
        refChangeEmitter.emit(tradeChange, tradeDataRef.current);
    };

    const addBidOffer = (data) => {
        tobDataRef.current = {
            ...(tobDataRef.current[data.security] = {
                ...tobDataRef.current[data.security],
                bid: data.bid,
                offer: data.ask,
            }),
        };

        symbolsDataRef.current = symbolsDataRef.current.map((symbol) => {
            if (symbol.security === data.security) {
                symbol["bid"] = data.bid;
                symbol["offer"] = data.ask;
            }
            return symbol;
        });
        refChangeEmitter.emit(symbolChange, symbolsDataRef.current);
        refChangeEmitter.emit(tobChange, tobDataRef.current);
    };

    const resetContextBook = () => {
        bookDataRef.current = [];
        tradeDataRef.current = [];
        refChangeEmitter.emit(bookChange, bookDataRef.current);
        refChangeEmitter.emit(tradeChange, tradeDataRef.current);
    };

    const addTradeSymbols = (data) => {
        symbolsDataRef.current = data;
        refChangeEmitter.emit(symbolChange, symbolsDataRef.current);
    };

    const updateTradeSymbolStatus = (data) => {
        symbolsDataRef.current = Array.from(
            symbolsDataRef.current.map((symb) => {
                const foundSymb = data.find(
                    (found) => found.security === symb.security
                );

                if (foundSymb) {
                    symb.status = foundSymb.status;
                }

                return symb;
            })
        );
        refChangeEmitter.emit(symbolChange, symbolsDataRef.current);
    };

    const deleteTradeSymbol = (data) => {
        symbolsDataRef.current = symbolsDataRef.current.filter(
            (existing) => existing.security !== data.security
        );
        refChangeEmitter.emit(symbolChange, symbolsDataRef.current);
    };

    const addSymbolPrevClosePrice = (data) => {
        tobDataRef.current = {
            ...(tobDataRef.current[data.security] = {
                ...tobDataRef.current?.[data.security],
                prevcloseprice: data.prevcloseprice,
                high: data.high,
                low: data.low,
                last: data.last,
                lastPricePair: {
                    ...tobDataRef.current?.[data.security]?.["lastPricePair"],
                    prevcloseprice: data.prevcloseprice,
                    last: data.last,
                },
                percentChangePair: {
                    ...tobDataRef.current?.[data.security]?.[
                        "percentChangePair"
                    ],
                    prevcloseprice: data.prevcloseprice,
                    last: data.last,
                },
            }),
        };

        symbolsDataRef.current = Array.from(
            symbolsDataRef.current.map((symbol) => {
                if (symbol.security === data.security) {
                    symbol["prevcloseprice"] = data.prevcloseprice;
                    symbol["high"] = data.high;
                    symbol["low"] = data.low;
                    symbol["last"] = data.last;
                    symbol["lastPricePair"] = {
                        ...symbol["lastPricePair"],
                        prevcloseprice: data.prevcloseprice,
                        last: data.last,
                    };
                    symbol["percentChangePair"] = {
                        ...symbol["percentChangePair"],
                        prevcloseprice: data.prevcloseprice,
                        last: data.last,
                    };
                }
                return JSON.parse(JSON.stringify(symbol));
            })
        );
        refChangeEmitter.emit(symbolChange, symbolsDataRef.current);
        refChangeEmitter.emit(tobChange, tobDataRef.current);
    };

    return (
        <DataContext.Provider
            value={{
                bookDataRef,
                tobDataRef,
                tradeDataRef,
                symbolsDataRef,
                addBook,
                removeBook,
                addTradeData,
                addBidOffer,
                resetContextBook,
                addTradeSymbols,
                deleteTradeSymbol,
                updateTradeSymbolStatus,
                addSymbolPrevClosePrice,
                addTradeDataForTob,
                refChangeEmitter,
            }}
        >
            {children}
        </DataContext.Provider>
    );
};

// Hook to use the Data Context
export const useData = () => {
    return useContext(DataContext);
};
