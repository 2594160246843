import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Row } from "react-bootstrap";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import { FormRenderer } from "../../components/admin/onboarding/FormRenderer";
import { isBrassicaSandbox } from "../../config";
import {
    completeDisclosureAcceptance,
    createKYCProfile,
    createPerson,
    designatePersonForAccountApplication,
    displayCustodyAgreementForSignature,
    generateLivelinessCheckURL,
    getCustodyAccountApplicationDisclosures,
    getElectronicSignaturesForCustodyAgreement,
    getElectronicSignaturesForNomineeAgreement,
    getKYCApplicationsForAccountApplication,
    getKYCChecksForKYCApplication,
    signCustodyAgreement,
    signNomineeAgreement,
    submitCustodyAccountApplicationForReview,
} from "../../helpers/brassica";
import {
    reviewSubmission,
    updateRegistrationSubmission,
    validateFormGroup,
} from "../../helpers/onboarding";
import { useDebouncedEffect } from "../../hooks/useDebouncedEffect";
import { useStore } from "../../Store";
import { useSocket } from "../../Websocket";

const OnboardingHome = () => {
    const ws = useSocket();
    const { state, dispatch } = useStore();
    const navigate = useNavigate();

    const {
        onboarding: { submissions },
        account: {
            account: { data: loggedInUser },
        },
    } = state;

    const { formId } = useParams();

    const [form, setForm] = useState();
    const [edited, setEdited] = useState(false);
    const [searchParams] = useSearchParams();
    const [currentGroup, setCurrentGroup] = useState(0);

    // Brassica variables
    const [kycLinks, setKycLinks] = useState([]);
    const [electronicSignatureId, setElectronicSignatureId] = useState("");
    const [custodyAgreement, setCustodyAgreement] = useState();
    const [nomineeSignatureId, setNomineeSignatureId] = useState("");
    const [nomineeAgreement, setNomineeAgreement] = useState();
    const [eSignConsentStatement, setESignConsentStatement] = useState();
    const accountOwnerId = loggedInUser?.complianceid || loggedInUser?.userid;
    const signMark = loggedInUser?.first_name + " " + loggedInUser?.last_name;
    const ipAddress = loggedInUser?.ip;
    const custodyAccountApplicationId = loggedInUser?.secondary_account;
    const brassicaSteps = form?.data?.form?.brassica || {};

    const validateFormBeforeSubmit = () =>
        // eslint-disable-next-line no-undef
        new Promise((resolve, reject) => {
            const groups =
                "groups" in form.data.form
                    ? form.data.form.groups
                    : [
                          {
                              name: form.data.form.name,
                              fields: form.data.form.fields,
                          },
                      ];

            const validateAllGroups = () =>
                Promise.all(
                    groups.map((group, i) =>
                        validateFormGroup(group, i).then((res) => {
                            const [valid] = res;

                            if (!valid) {
                                navigate(`?grp=${i}`);
                            }

                            return valid;
                        })
                    )
                ).then((results) => results.every((valid) => valid));

            validateAllGroups().then((valid) => (valid ? resolve() : reject()));
        });

    const submitForm = () =>
        validateFormBeforeSubmit()
            .then(() => {
                const isBrassica = form.data.form.onSubmit === "brassica";
                if (isBrassica) {
                    if (!brassicaSteps.step_one && !brassicaSteps.step_two) {
                        completeBrassicaStepOne();
                    } else if (
                        !brassicaSteps.step_four &&
                        brassicaSteps.step_three
                    ) {
                        completeBrassicaStepFour();
                    } else if (
                        !brassicaSteps.step_five_part_one &&
                        !brassicaSteps.step_five_part_two &&
                        brassicaSteps.step_four
                    ) {
                        completeBrassicaStepFivePartOne();
                    } else if (
                        !brassicaSteps.step_five_part_two &&
                        brassicaSteps.step_five_part_one
                    ) {
                        completeBrassicaStepFivePartTwo();
                    }
                } else {
                    updateRegistrationSubmission(
                        ws,
                        dispatch,
                        {
                            ...form,
                            status: "SUBMITTED",
                            data: {
                                ...form.data,
                                activity: [
                                    ...form.data.activity,
                                    {
                                        date_created: new Date().getTime(),
                                        action_by:
                                            loggedInUser?.userid ||
                                            loggedInUser?.userId,
                                        action_type: "submit_form",
                                    },
                                ],
                                form: {
                                    ...form.data.form,
                                    updatedAt: new Date().getTime(),
                                },
                            },
                        },
                        true
                    ).then(async (updatedSubmission) => {
                        const nextForm = updatedSubmission.find(
                            (submission) =>
                                submission.data.order === form.data.order + 1
                        );
                        if (form.data.form.onSubmit === "automatic")
                            await reviewSubmission(form.submission_id);
                        toast.success("Form submitted.");
                        navigate(
                            nextForm ? `/forms/${nextForm.submission_id}` : `/`
                        );
                    });
                }
            })
            .catch((msg) => toast.error(msg));

    const completeBrassicaStepOne = () => {
        // 1.2 - Step 1
        getCustodyAccountApplicationDisclosures(
            ws,
            custodyAccountApplicationId
        ).then((disclosure) => {
            // 1.3
            completeDisclosureAcceptance(
                ws,
                disclosure.data.id,
                accountOwnerId,
                ipAddress
            ).then(() => {
                completeBrassicaStepTwoAndThree();
            });

            updateRegistrationSubmission(
                ws,
                dispatch,
                {
                    ...form,
                    data: {
                        ...form.data,
                        activity: [
                            ...form.data.activity,
                            {
                                date_created: new Date().getTime(),
                                action_by:
                                    loggedInUser?.userid ||
                                    loggedInUser?.userId,
                                action_type: "disclosure_acceptance",
                            },
                        ],
                        form: {
                            ...form.data.form,
                            brassica: {
                                ...form.data.form.brassica,
                                step_one: true,
                            },
                            updatedAt: new Date().getTime(),
                        },
                    },
                },
                true
            );
        });
    };

    const completeBrassicaStepTwoAndThree = () => {
        let taxIdNumber = "";

        const person = {
            name: signMark,
            email: loggedInUser?.email,
            personType: "natural-person",
            primaryAddress: {
                // No option for anything but the US
                countryCode: "US",
            },
            primaryPhone: {},
        };

        form.data.form.fields.forEach((field) => {
            switch (field.fieldName) {
                case "Date Of Birth":
                    person["dateOfBirth"] = moment(field.value).format(
                        "YYYY-MM-DD"
                    );
                    break;
                case "Street Address":
                    person["primaryAddress"]["street"] = field.value;
                    break;
                case "City":
                    person["primaryAddress"]["city"] = field.value;
                    break;
                case "State":
                    person["primaryAddress"]["region"] = field.value;
                    break;
                case "Zip Code":
                    person["primaryAddress"]["postalCode"] = field.value;
                    break;
                case "Primary Phone":
                    person["primaryPhone"]["number"] = field.value;
                    break;
                case "SSN":
                    taxIdNumber = field.value.replaceAll(/-/g, "");
                    break;
                default:
                    break;
            }
            return;
        });

        // 2.1 - Step 2
        createPerson(ws, dispatch, loggedInUser, person).then((prsn) =>
            // 2.2
            createKYCProfile(ws, prsn.data.id, {
                countryCode: "US",
                taxIdNumber,
                taxIdNumberType: "us-ssn",
            }).then(() => {
                // 2.3
                designatePersonForAccountApplication(
                    ws,
                    prsn.data.id,
                    custodyAccountApplicationId
                ).then(() => {
                    updateRegistrationSubmission(
                        ws,
                        dispatch,
                        {
                            ...form,
                            data: {
                                ...form.data,
                                activity: [
                                    ...form.data.activity,
                                    {
                                        date_created: new Date().getTime(),
                                        action_by:
                                            loggedInUser?.userid ||
                                            loggedInUser?.userId,
                                        action_type:
                                            "custody_account_application_submitted",
                                    },
                                    {
                                        date_created: new Date().getTime(),
                                        action_by:
                                            loggedInUser?.userid ||
                                            loggedInUser?.userId,
                                        action_type: "brassica_person_created",
                                    },
                                ],
                                form: {
                                    ...form.data.form,
                                    brassica: {
                                        ...form.data.form.brassica,
                                        step_two: true,
                                        step_three: true,
                                    },
                                    updatedAt: new Date().getTime(),
                                },
                            },
                        },
                        true
                    );
                    // 3.1
                    return submitCustodyAccountApplicationForReview(
                        ws,
                        custodyAccountApplicationId
                    );
                });
            })
        );
    };

    const completeBrassicaStepFour = () => {
        // Check and see if the applications were completed
        // 4.1 status == approved
        getKYCApplicationsForAccountApplication(
            ws,
            custodyAccountApplicationId
        ).then((res) => {
            const isStepFourComplete = res.data.every(
                (kycApplication) =>
                    kycApplication.attributes.status === "approved"
            );

            if (isStepFourComplete || isBrassicaSandbox) {
                // Update form activity and step completion
                updateRegistrationSubmission(
                    ws,
                    dispatch,
                    {
                        ...form,
                        data: {
                            ...form.data,
                            activity: [
                                ...form.data.activity,
                                {
                                    date_created: new Date().getTime(),
                                    action_by:
                                        loggedInUser?.userid ||
                                        loggedInUser?.userId,
                                    action_type: "kyc_application_submitted",
                                },
                            ],
                            form: {
                                ...form.data.form,
                                brassica: {
                                    ...form.data.form.brassica,
                                    step_four: true,
                                },
                                updatedAt: new Date().getTime(),
                            },
                        },
                    },
                    true
                );
            } else {
                toast.error("KYC applications are not complete yet!");
            }
        });
    };

    const getKYCApplicationLinks = () => {
        // 4.1
        getKYCApplicationsForAccountApplication(
            ws,
            loggedInUser?.secondary_account
        ).then((res) => {
            const kycApplications = res.data.map(
                (kycApplication) => kycApplication.id
            );

            // 4.2
            const applicationIds = kycApplications.map((kycApplication) =>
                getKYCChecksForKYCApplication(ws, kycApplication)
            );

            Promise.all(applicationIds)
                .then((results) => results.flat())
                .then((checks) => {
                    // 4.3
                    for (let i = 0; i < checks.length; i++) {
                        generateLivelinessCheckURL(
                            ws,
                            checks[i],
                            signMark,
                            loggedInUser?.email
                        ).then((url) => {
                            setKycLinks((prev) => [...prev, url]);
                        });
                    }
                })
                .then(() => {
                    updateRegistrationSubmission(
                        ws,
                        dispatch,
                        {
                            ...form,
                            data: {
                                ...form.data,
                                form: {
                                    ...form.data.form,
                                    brassica: {
                                        ...form.data.form.brassica,
                                        kycApplications,
                                    },
                                    updatedAt: new Date().getTime(),
                                },
                            },
                        },
                        false
                    );
                });
        });
    };

    const completeBrassicaStepFivePartOne = () => {
        // 5.3
        signCustodyAgreement(
            ws,
            electronicSignatureId,
            accountOwnerId,
            ipAddress,
            signMark
        ).then(() =>
            updateRegistrationSubmission(
                ws,
                dispatch,
                {
                    ...form,
                    data: {
                        ...form.data,
                        activity: [
                            ...form.data.activity,
                            {
                                date_created: new Date().getTime(),
                                action_by:
                                    loggedInUser?.userid ||
                                    loggedInUser?.userId,
                                action_type: "custody_agreement_signed",
                            },
                        ],
                        form: {
                            ...form.data.form,
                            brassica: {
                                ...form.data.form.brassica,
                                step_five_part_one: true,
                            },
                            updatedAt: new Date().getTime(),
                        },
                    },
                },
                true
            )
        );
    };

    const completeBrassicaStepFivePartTwo = () => {
        // 5.6
        signNomineeAgreement(
            ws,
            nomineeSignatureId,
            accountOwnerId,
            ipAddress,
            signMark
        ).then(() => {
            // Submit form.
            updateRegistrationSubmission(
                ws,
                dispatch,
                {
                    ...form,
                    status: "SUBMITTED",
                    data: {
                        ...form.data,
                        activity: [
                            ...form.data.activity,
                            {
                                date_created: new Date().getTime(),
                                action_by:
                                    loggedInUser?.userid ||
                                    loggedInUser?.userId,
                                action_type: "submit_form",
                            },
                            {
                                date_created: new Date().getTime(),
                                action_by:
                                    loggedInUser?.userid ||
                                    loggedInUser?.userId,
                                action_type: "nominee_agreement_signed",
                            },
                        ],
                        form: {
                            ...form.data.form,
                            brassica: {
                                ...form.data.form.brassica,
                                step_five_part_two: true,
                            },
                            updatedAt: new Date().getTime(),
                        },
                    },
                },
                true
            ).then(async (updatedSubmission) => {
                const nextForm = updatedSubmission.find(
                    (submission) =>
                        submission.data.order === form.data.order + 1
                );

                if (form.data.form.onSubmit === "automatic")
                    await reviewSubmission(form.submission_id);

                toast.success("Form submitted.");

                navigate(nextForm ? `/forms/${nextForm.submission_id}` : `/`);
            });
        });
    };

    useEffect(
        () =>
            submissions.length > 0 &&
            setForm(
                Object.assign(
                    {},
                    submissions.find(
                        (submission) => submission.submission_id === formId
                    )
                )
            ),
        [formId, submissions]
    );

    useDebouncedEffect(
        () =>
            edited &&
            !form?.data?.brassica?.step_two &&
            updateRegistrationSubmission(
                ws,
                dispatch,
                {
                    ...form,
                    data: {
                        ...form.data,
                        form: {
                            ...form.data.form,
                            updatedAt: new Date().getTime(),
                        },
                    },
                },
                true
            ).then(() => setEdited(false)),
        [form],
        2000
    );

    useEffect(() => {
        if (form?.data?.form?.onSubmit === "brassica") {
            if (
                brassicaSteps.step_four &&
                !brassicaSteps.step_five_part_one &&
                !custodyAgreement
            ) {
                // 5.1
                getElectronicSignaturesForCustodyAgreement(
                    ws,
                    loggedInUser?.secondary_account
                ).then((res) => {
                    setESignConsentStatement(
                        res.meta?.legalLanguage?.eSignConsentStatement
                    );
                    // 5.2
                    displayCustodyAgreementForSignature(ws, res.id).then(
                        (agreement) => {
                            setElectronicSignatureId(res.id);
                            setCustodyAgreement(agreement);
                        }
                    );
                });
            } else if (
                brassicaSteps.step_five_part_one &&
                !brassicaSteps.step_five_part_two
            ) {
                // 5.4
                getElectronicSignaturesForNomineeAgreement(
                    ws,
                    loggedInUser?.secondary_account
                ).then((signatureId) => {
                    // 5.5
                    displayCustodyAgreementForSignature(ws, signatureId).then(
                        (agreement) => {
                            setNomineeSignatureId(signatureId);
                            setNomineeAgreement(agreement);
                        }
                    );
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form]);

    useEffect(() => {
        if (
            form &&
            kycLinks.length === 0 &&
            form?.data?.form?.onSubmit === "brassica"
        ) {
            if (
                brassicaSteps.step_three &&
                !brassicaSteps.step_four &&
                kycLinks.length === 0
            ) {
                // 4
                getKYCApplicationLinks();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [kycLinks, form]);

    useEffect(() => {
        // Check if there is a group number in the search params, and set the current shown group to that number.
        if (searchParams.has("grp")) {
            const group = parseInt(searchParams.get("grp"));
            if (!isNaN(group)) setCurrentGroup(group);
        }
    }, [searchParams]);

    const formRef = useRef(form);

    useEffect(() => {
        formRef.current = form;
    }, [form]);

    useEffect(() => {
        const currentForm = formRef.current;
        if (
            !!currentForm &&
            "groups" in currentForm.data.form &&
            !submissions.find(
                (submission) => submission.submission_id === formId
            )?.data?.form?.groups[currentGroup]?.visited
        ) {
            setEdited(true);
            setForm({
                ...currentForm,
                data: {
                    ...currentForm.data,
                    form: {
                        ...currentForm.data.form,
                        groups: currentForm.data.form.groups.map((group, i) =>
                            i === currentGroup
                                ? { ...group, visited: true }
                                : group
                        ),
                    },
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentGroup]);

    return (
        <div className="m-4 d-flex justify-content-center">
            {form && (
                <div
                    className="confirmation-container px-4 pt-3 position-relative"
                    style={{ maxWidth: "85%" }}
                >
                    {form.status === "PENDING" ? (
                        <FormRenderer
                            form={
                                "groups" in form.data.form
                                    ? form.data.form.groups[currentGroup]
                                    : form.data.form
                            }
                            setForm={(frm) => {
                                setEdited(true);
                                setForm({
                                    ...form,
                                    data: {
                                        ...form.data,
                                        // Only change the current group if the form has groups
                                        form:
                                            "groups" in form.data.form
                                                ? {
                                                      ...form.data.form,
                                                      groups: form.data.form.groups.map(
                                                          (group, i) =>
                                                              i === currentGroup
                                                                  ? frm
                                                                  : group
                                                      ),
                                                  }
                                                : frm,
                                    },
                                });
                            }}
                            submitForm={
                                ("groups" in form.data.form &&
                                    form.data.form.groups.length ===
                                        currentGroup + 1) ||
                                !("groups" in form.data.form)
                                    ? submitForm
                                    : () => {
                                          validateFormGroup(
                                              form.data.form.groups[
                                                  currentGroup
                                              ],
                                              currentGroup
                                          ).then((res) => {
                                              const [valid] = res;
                                              if (valid) {
                                                  searchParams.set(
                                                      "grp",
                                                      currentGroup + 1
                                                  );
                                                  navigate(
                                                      `?${searchParams.toString()}`
                                                  );
                                              }
                                          });
                                      }
                            }
                            custodyAccountApplicationId={
                                custodyAccountApplicationId
                            }
                            stepOneCompleted={brassicaSteps.step_one}
                            stepTwoThreeCompleted={
                                brassicaSteps.step_two &&
                                brassicaSteps.step_three
                            }
                            stepFourCompleted={brassicaSteps.step_four}
                            stepFivePartOneCompleted={
                                brassicaSteps.step_five_part_one
                            }
                            kycLinks={kycLinks}
                            custodyAgreement={custodyAgreement}
                            nomineeAgreement={nomineeAgreement}
                            eSignConsentStatement={eSignConsentStatement}
                            ipAddress={ipAddress}
                            usersName={signMark}
                            isBrassica={form.data.form.onSubmit === "brassica"}
                            currentGroup={currentGroup}
                            groupCount={
                                "groups" in form.data.form
                                    ? form.data.form.groups.length
                                    : 1
                            }
                        />
                    ) : (
                        <Row
                            noGutters
                            className="d-flex justify-content-center"
                            style={{ flexDirection: "column" }}
                        >
                            <h5 className="text-center w-100">
                                Thank you for submitting this form.
                            </h5>
                        </Row>
                    )}
                </div>
            )}
        </div>
    );
};

export default OnboardingHome;
