/* eslint-disable react/display-name */
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

import { memo } from "react";
import { Button, Col, Row } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

import { Loading } from "../../../Loading";

export const ApprovalsList = memo(
    ({
        preferredColumns,
        approvals,
        loading = false,
        openApproveModal,
        openDenyModal,
    }) => {
        const optionsColumn = {
            dataField: "options",
            text: "",
            isDummyField: true,
            formatter: (rec_no, { security, traderefno, type }) => (
                <Row className="justify-content-end">
                    <Col>
                        <Button
                            variant="success"
                            onClick={() =>
                                openApproveModal({
                                    security,
                                    traderefno,
                                    type,
                                    approve: true,
                                })
                            }
                            size="sm"
                        >
                            Approve
                        </Button>

                        <Button
                            variant="danger"
                            onClick={() =>
                                openDenyModal({
                                    security,
                                    traderefno,
                                    type,
                                    approve: false,
                                })
                            }
                            size="sm"
                        >
                            Deny
                        </Button>
                    </Col>
                </Row>
            ),
        };

        const columns = preferredColumns.concat(optionsColumn);

        return loading ? (
            <Loading />
        ) : (
            <BootstrapTable
                keyField="traderefno"
                data={approvals}
                columns={columns}
                classes=""
                noDataIndication="No approvals available."
                sort={{ dataField: "time", order: "desc" }}
                pagination={paginationFactory()}
                striped
                wrapperClasses="size-sm"
            />
        );
    }
);
