import { Fragment, memo } from "react";

import { clientCurrency, enableNewMarketWatch } from "../../../config";
import { FullScreenLoading } from "../../Loading";
import { MarketWatchCard } from "./MarketWatchCard";

// eslint-disable-next-line react/display-name
export const MarketWatchCardList = memo(
    ({
        updateSymbolInWatchList,
        symbols,
        profiles,
        watchlist = [],
        loading,
    }) => {
        const doesProfileExist = (symbol) =>
            profiles.find((profile) =>
                symbol?.pair_second !== clientCurrency
                    ? profile.symbol === symbol.security
                    : profile.symbol === symbol.pair_first
            );

        const watchListedSymbols = symbols.filter((symb) =>
            watchlist.includes(symb.security)
        );
        const unwatchedSymbols = symbols.filter(
            (symb) => !watchlist.includes(symb.security)
        );

        return (
            <>
                {!loading ? (
                    watchListedSymbols.length !== 0 && (
                        <>
                            <div
                                className="d-flex"
                                style={{
                                    flexWrap: "wrap",
                                    justifyContent: "space-evenly",
                                }}
                            >
                                {watchListedSymbols.map((symb, i) => {
                                    const profileExists =
                                        doesProfileExist(symb);

                                    return (
                                        <Fragment key={i}>
                                            <MarketWatchCard
                                                asset={symb}
                                                profile={profileExists}
                                                updateSymbolInWatchList={
                                                    updateSymbolInWatchList
                                                }
                                                watchlist
                                            />
                                        </Fragment>
                                    );
                                })}
                            </div>
                            {!enableNewMarketWatch && <hr />}
                        </>
                    )
                ) : (
                    <FullScreenLoading />
                )}

                {!enableNewMarketWatch && (
                    <div
                        className="d-flex"
                        style={{
                            flexWrap: "wrap",
                            justifyContent: "space-evenly",
                        }}
                    >
                        {!loading &&
                            unwatchedSymbols.map((symb, i) => {
                                const profileExists = doesProfileExist(symb);

                                return (
                                    <Fragment key={i}>
                                        <MarketWatchCard
                                            asset={symb}
                                            profile={profileExists}
                                            updateSymbolInWatchList={
                                                updateSymbolInWatchList
                                            }
                                        />
                                    </Fragment>
                                );
                            })}
                    </div>
                )}
            </>
        );
    }
);
