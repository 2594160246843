import { useEffect, useMemo, useState } from "react";
import {
    Button,
    Col,
    FormControl,
    InputGroup,
    Modal,
    Row,
    Tab,
    Tabs,
} from "react-bootstrap";

import {
    addCommas,
    countDecimals,
    getCurrency,
    getZeroes,
    isMultipleDot,
    removeCommas,
    removeNonNumeric,
} from "../../../../helpers/other";
import {
    counterOrder,
    declineOrder,
    executeOrder,
} from "../../../../helpers/trade";
import { validatePosNumber } from "../../../../helpers/validate";
import { useStore } from "../../../../Store";
import { useSocket } from "../../../../Websocket";

export const NegotiateModal = ({
    show = false,
    handleClose,
    selectedOrder,
}) => {
    const ws = useSocket();
    const { state } = useStore();
    const { symbols } = state.trade;
    const [key, setKey] = useState("execute");

    const symbol = symbols.find(
        (item) => item.security === selectedOrder?.security
    );
    const currency = getCurrency(symbol, selectedOrder?.security);

    const [qty, setQty] = useState("");
    const [price, setPrice] = useState("");

    const onNegotiate = (type, price, qty) => {
        switch (type) {
            case "execute":
                return executeOrder(ws, {
                    refno: selectedOrder.refno,
                    security: selectedOrder.security,
                    price,
                    qty,
                    side: selectedOrder.side === "B" ? "S" : "B",
                });
            case "counter offer":
                return counterOrder(ws, {
                    refno: selectedOrder.refno,
                    security: selectedOrder.security,
                    price,
                    qty,
                    side: selectedOrder.side === "B" ? "S" : "B",
                });
            case "decline":
                return declineOrder(ws, {
                    refno: selectedOrder.refno,
                    security: selectedOrder.security,
                });

            default:
                break;
        }
        handleClose();
    };

    useEffect(() => {
        if (selectedOrder) {
            setQty(selectedOrder?.qty);
            setPrice(selectedOrder?.price);
        }
    }, [selectedOrder]);

    const amountRenderer = useMemo(() => {
        const { zeroCount, zeroes } = getZeroes("Amount", symbol);
        return (
            <>
                <Row>
                    <Button
                        style={{ margin: "8px 16px 8px auto" }}
                        variant={
                            selectedOrder?.side === "S" ? "success" : "warning"
                        }
                    >
                        {selectedOrder?.side === "B" ? "SELL" : "BUY"}
                    </Button>
                </Row>
                <Row className="d-flex align-items-center pb-3">
                    <Col xs={3}>Quantity:</Col>
                    <Col xs={9}>
                        <InputGroup>
                            <FormControl
                                value={
                                    key === "counter offer"
                                        ? qty
                                        : selectedOrder?.qty
                                }
                                onChange={(e) => {
                                    if (
                                        !isMultipleDot(e.target.value) &&
                                        countDecimals(e.target.value) <=
                                            zeroCount
                                    )
                                        setQty(
                                            addCommas(
                                                removeNonNumeric(e.target.value)
                                            )
                                        );
                                }}
                                onKeyDown={validatePosNumber}
                                type="text"
                                placeholder={`0${zeroes}`}
                                min={0}
                                tabIndex={0}
                                disabled={key !== "counter offer"}
                            />
                            <InputGroup.Append>
                                <InputGroup.Text>
                                    {currency[0]?.type}
                                </InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup>
                    </Col>
                </Row>
            </>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [qty, key, selectedOrder, symbols]);

    const priceRenderer = useMemo(() => {
        const { zeroCount, zeroes } = getZeroes("Price", symbol);
        return (
            <Row className="d-flex align-items-center pb-3">
                <Col xs={3}>Price:</Col>
                <Col xs={9}>
                    <InputGroup>
                        <FormControl
                            value={
                                key === "counter offer"
                                    ? price
                                    : selectedOrder?.price
                            }
                            onChange={(e) => {
                                if (
                                    !isMultipleDot(e.target.value) &&
                                    countDecimals(e.target.value) <= zeroCount
                                )
                                    setPrice(
                                        addCommas(
                                            removeNonNumeric(e.target.value)
                                        )
                                    );
                            }}
                            onKeyDown={validatePosNumber}
                            type="text"
                            placeholder={`0${zeroes}`}
                            min={0}
                            tabIndex={0}
                            disabled={key !== "counter offer"}
                        />
                        <InputGroup.Append>
                            <InputGroup.Text>
                                {currency[1]?.type}
                            </InputGroup.Text>
                        </InputGroup.Append>
                    </InputGroup>
                </Col>
            </Row>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [price, key, selectedOrder, symbols]);

    const totalRenderer = (qty, price) => {
        return (
            <Row className="d-flex align-items-center">
                <Col xs={3}>Total:</Col>
                <Col xs={3}>
                    {qty * price} {currency[1]?.type}
                </Col>
                <Col xs={6} style={{ textAlign: "right" }}>
                    <Button
                        className="negotiate-button"
                        variant={
                            selectedOrder?.side === "S" ? "success" : "warning"
                        }
                        onClick={() => onNegotiate(key, price, qty)}
                    >
                        {key.toUpperCase()} -{" "}
                        {selectedOrder?.side === "B" ? "SELL" : "BUY"}
                    </Button>
                </Col>
            </Row>
        );
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Negotiate Order</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div
                    style={{
                        paddingBottom: "4px",
                        borderBottom: "1px solid gray",
                    }}
                >
                    <b>{selectedOrder?.security}</b>
                </div>
                <div
                    style={{
                        paddingBottom: "4px",
                        borderBottom: "1px solid gray",
                        marginBottom: "8px",
                        paddingTop: "4px",
                    }}
                >
                    {selectedOrder?.side === "S" ? "BUY" : "SELL"} SIZE{" "}
                    {selectedOrder?.qty} @ {selectedOrder?.price} PRICE
                </div>
                <Tabs activeKey={key} onSelect={(k) => setKey(k)}>
                    <Tab eventKey="execute" title="EXECUTE">
                        {amountRenderer}
                        {priceRenderer}
                        {totalRenderer(
                            selectedOrder?.qty,
                            selectedOrder?.price
                        )}
                    </Tab>
                    <Tab eventKey="counter offer" title="COUNTER OFFER">
                        {amountRenderer}
                        {priceRenderer}
                        {totalRenderer(removeCommas(qty), removeCommas(price))}
                    </Tab>
                    <Tab eventKey="decline" title="DECLINE">
                        {amountRenderer}
                        {priceRenderer}
                        {totalRenderer(
                            selectedOrder?.qty,
                            selectedOrder?.price
                        )}
                    </Tab>
                </Tabs>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="light"
                    onClick={handleClose}
                    className="negotiate-cancel-button"
                >
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
