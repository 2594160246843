import { memo, useEffect, useState } from "react";
import { Col, FormControl, Row } from "react-bootstrap";

import { formatTableColumn, handleGetRefno } from "../../../helpers/other";
import { getQueryAlerts } from "../../../helpers/risk";
import { useSocket } from "../../../Websocket";
import { FullScreenLoading } from "../../Loading";
import { TableRenderer } from "../../portal/TableRenderer";
import { alertTypes } from "./alertTypes";

// eslint-disable-next-line react/display-name
export const InvestigatePreviousRejectsTable = memo(({ trader }) => {
    const ws = useSocket();
    const [loading, setLoading] = useState(false);

    const [alertType, setAlertType] = useState(undefined);
    const [previousRejects, setPreviousRejects] = useState([]);

    useEffect(() => {
        if (trader) {
            setLoading(true);
            getQueryAlerts(ws, {
                subtype: alertType,
                account: trader,
            }).then((res) => {
                setPreviousRejects(
                    res
                        .filter((alt) => alt?.errordetails && alt)
                        .sort((a, b) => a.time < b.time)
                );
                setLoading(false);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [alertType, trader]);

    return (
        <>
            <Row noGutters className="mt-2 mb-4">
                <Col xs={3} className="mt-1">
                    Alert Type:
                </Col>
                <Col xs={3}>
                    <FormControl
                        as="select"
                        size="sm"
                        className=""
                        onChange={({ target: { value } }) =>
                            setAlertType(value)
                        }
                        value={alertType}
                        disabled={!trader}
                    >
                        <option value={undefined}>Select a option...</option>
                        {alertTypes.map(({ value, label }, i) => (
                            <option key={i} value={value}>
                                {label}
                            </option>
                        ))}
                    </FormControl>
                </Col>
            </Row>

            <Row noGutters className="mb-3">
                <h6>
                    <b>Previous Risk Rejects</b>
                </h6>
            </Row>
            {loading ? (
                <FullScreenLoading />
            ) : (
                <TableRenderer
                    keyField="rec_no"
                    wrapperClasses="risk-investigate-table"
                    data={previousRejects}
                    columns={[
                        {
                            dataField: "refno",
                            text: "Ref no.",
                            sort: true,
                            formatter: (rowContent, row) => (
                                <span>{handleGetRefno(row.errordetails)}</span>
                            ),
                        },
                        ...["time", "errordetails"].map((col) =>
                            formatTableColumn(col)
                        ),
                    ]}
                    noDataIndication="No previous rejects found."
                />
            )}
        </>
    );
});
