import { useContext, useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

import {
    DeleteSymbolModal,
    SecondaryMarket,
    SymbolAuction,
    SymbolInformation,
    SymbolStatus,
} from "../../../components/admin/market/symbol/";
import { auctionEnabled } from "../../../config";
import { deleteImageForProfile } from "../../../helpers/asset-builder";
import {
    submitDeleteSymbol,
    submitEditSymbol,
    submitSymbolStatus,
} from "../../../helpers/market";
import { removeUnusedKeys } from "../../../helpers/other";
import { queryScheduleSymbolStatus } from "../../../helpers/trade";
import { useStore } from "../../../Store";
import { DataContext } from "../../../SubscriptionData";
import { useSocket } from "../../../Websocket";

const SymbolPage = () => {
    const { state, dispatch } = useStore();
    const ws = useSocket();
    const navigate = useNavigate();

    const { security } = useParams();
    const {
        market: { symbols: marketSymbols },
        trade: { symbols: tradeSymbols },
    } = state;

    const dataContext = useContext(DataContext);

    const [symbol, setSymbol] = useState();
    const [toDeleteSymbol, setToDeleteSymbol] = useState({});
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [symbolAuctionTimes, setSymbolAuctionTimes] = useState([]);
    const permanentAuctionTime = symbolAuctionTimes?.find(
        (time) => time.status === "R"
    );
    const isStatusPermanentHalt =
        permanentAuctionTime?.scheduletime > 0 && permanentAuctionTime?.status;

    const handleCloseDeleteModal = () => setShowDeleteModal(false);
    const handleOpenDeleteModal = (symbol) => {
        setToDeleteSymbol(symbol);
        setShowDeleteModal(true);
    };

    const removePairSymbol = () =>
        setSymbol({
            ...symbol,
            pairs: symbol.pairs.filter(
                (elem) => elem !== toDeleteSymbol?.security
            ),
        });

    const handleDelete = () => {
        // Delete image if it exists
        if (toDeleteSymbol?.attr?.image) {
            deleteImageForProfile(toDeleteSymbol.attr.image);
        }

        submitDeleteSymbol(
            ws,
            dispatch,
            toDeleteSymbol.security,
            "pair",
            symbol
        ).then(() => {
            dataContext.deleteTradeSymbol(toDeleteSymbol.security);
            handleCloseDeleteModal();
            removePairSymbol();
        });
    };

    const findMarketSymbol = (symb) =>
        marketSymbols.find((elem) => elem.security === symb);
    const findTradeSymbol = (symb) =>
        tradeSymbols.find((elem) => elem.security === symb);
    const getSymbolPageInfo = () => {
        let sym = findMarketSymbol(security);
        if (!sym) sym = findTradeSymbol(security);
        setSymbol(sym);
    };

    const updateStatus = (status) => {
        // Remove permanent halt if previous status was R
        if (isStatusPermanentHalt)
            submitSymbolStatus(ws, security, "R", 0, undefined, true);

        if (status === "R") {
            submitSymbolStatus(
                ws,
                security,
                status,
                new Date().setMinutes(new Date().getMinutes() + 5),
                "W1|D1,2,3,4,5,6,7",
                true
            );
        }

        submitSymbolStatus(
            ws,
            security,
            status === "R" ? "H" : status?.charAt(0)
        ).then(() => {
            submitEditSymbol(
                ws,
                dispatch,
                {
                    ...symbol,
                    fractionbase: symbol?.fractionbase?.toString(),
                    attr: {
                        ...symbol?.attr,
                        isHidden: status === "HH",
                    },
                },
                tradeSymbols.map((symb) => ({
                    ...symb,
                    status:
                        security === symb?.security
                            ? status === "R"
                                ? "H"
                                : status
                            : symb?.status,
                    attr: {
                        ...symb?.attr,
                        isHidden:
                            security === symb?.security
                                ? status === "HH"
                                : symb?.attr?.isHidden,
                    },
                })),
                tradeSymbols
            );
        });
    };

    const submitAuctionDate = (symbolData) =>
        submitEditSymbol(
            ws,
            dispatch,
            removeUnusedKeys(symbolData),
            tradeSymbols
        );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => getSymbolPageInfo(), [marketSymbols, security]);
    useEffect(() => {
        if (auctionEnabled)
            queryScheduleSymbolStatus(ws, security).then((times) => {
                setSymbolAuctionTimes(times);
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [security]);

    return (
        <div className="m-4">
            <Row noGutters className="forms-header">
                <Col>
                    <h4>{security}</h4>
                </Col>
                <Col className="text-right">
                    <Button
                        onClick={() =>
                            navigate(`/admin/symbols/${security}/edit`)
                        }
                        size="sm"
                    >
                        Edit Symbol
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h5>Information</h5>
                </Col>
                <Col>
                    <h5>Symbol Status</h5>
                </Col>
            </Row>
            <Row>
                <Col>
                    <SymbolInformation symbol={symbol} />
                </Col>
                <Col>
                    <SymbolStatus
                        updateStatus={updateStatus}
                        status={isStatusPermanentHalt || symbol?.status}
                        isHidden={symbol?.attr?.isHidden}
                    />
                    {auctionEnabled && symbol?.tradingsymbol && (
                        <SymbolAuction
                            symbol={symbol}
                            submitAuctionDate={submitAuctionDate}
                            auctionTimes={symbolAuctionTimes}
                        />
                    )}
                </Col>
            </Row>
            <br />
            {symbol?.pairs && (
                <>
                    <Row>
                        <Col>
                            <h5>Secondary Market</h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <DeleteSymbolModal
                                show={showDeleteModal}
                                handleCloseModal={handleCloseDeleteModal}
                                handleDelete={handleDelete}
                                security={toDeleteSymbol?.security}
                            />

                            <SecondaryMarket
                                handleOpenDeleteModal={handleOpenDeleteModal}
                                tradeSymbols={tradeSymbols}
                                pairs={symbol.pairs}
                            />
                        </Col>
                    </Row>
                </>
            )}
        </div>
    );
};

export default SymbolPage;
