/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import { useEffect, useState } from "react";
import {
    Button,
    Card,
    Col,
    Container,
    FormControl,
    Image,
    OverlayTrigger,
    Row,
    Tooltip,
} from "react-bootstrap";
import {
    AiFillEye,
    AiFillEyeInvisible,
    AiOutlineQuestionCircle,
} from "react-icons/ai";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import { alt_full_logo, full_logo } from "../config";
import { resetPassword } from "../helpers/login";
import { validatePassword } from "../helpers/validate";

const ResetPasswordPage = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");

    const [token, setToken] = useState("");
    const [email, setEmail] = useState("");

    const [showPassword, setShowPassword] = useState(false);
    const [passwordError, setPasswordError] = useState("");
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [confirmPasswordError, setConfirmPasswordError] = useState("");

    const togglePasswordVisibility = (type) => {
        const passwordBox = document.getElementsByName(type)[0];

        passwordBox.type = passwordBox.type === "text" ? "password" : "text";

        type === "password"
            ? setShowPassword(!showPassword)
            : setShowConfirmPassword(!showConfirmPassword);
    };

    const checkPasswordFormat = ({ target: { value } }) =>
        setPasswordError(value.length > 0 && validatePassword(value));

    const checkConfirmPassword = ({ target: { value } }) =>
        setConfirmPasswordError(value.length > 0 && newPassword !== value);

    const sendResetPasswordRequest = () => {
        resetPassword(email, token, newPassword).then(() => navigate("/login"));
    };

    useEffect(() => {
        if (
            searchParams.entries().length === 0 ||
            !searchParams.toString().includes("token") ||
            !searchParams.toString().includes("email")
        ) {
            // return redirect
            navigate("/");
        }
        for (let param of searchParams.entries()) {
            if (param[0] === "email") setEmail(param[1]);
            else if (param[0] === "token") setToken(param[1]);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const disabled =
        confirmPasswordError ||
        passwordError ||
        !newPassword ||
        !confirmNewPassword;

    return (
        <Container fluid className="login-container">
            <Row className="login-row g-0">
                <Col sm={8} md={6} lg={4} xl={4} className="login-col">
                    <Card className="login-box">
                        <Row className="g-0">
                            <Col className="login-content-col">
                                <Row className="login-header g-0">
                                    <Link to={"/"}>
                                        <Image
                                            fluid
                                            src={alt_full_logo}
                                            onError={(img) => {
                                                img.target.onerror = null;
                                                img.target.src = full_logo;
                                            }}
                                            className="login-logo"
                                        />
                                    </Link>
                                </Row>

                                <Row className="login-box-padding login-formcontrol-row g-0">
                                    <Col className="login-box-col">
                                        <h3>Reset Password</h3>
                                        <br />

                                        <Row className="login-formcontrol-container g-0">
                                            <div
                                                className={`login-formcontrol-box ${
                                                    passwordError &&
                                                    "formcontrol-error"
                                                }`}
                                            >
                                                <div className="login-formcontrol-text">
                                                    New Password
                                                </div>
                                                <FormControl
                                                    size="sm"
                                                    name="password"
                                                    type="password"
                                                    value={newPassword || ""}
                                                    onChange={({
                                                        target: { value },
                                                    }) => setNewPassword(value)}
                                                    onBlur={checkPasswordFormat}
                                                    className="login-formcontrol"
                                                />
                                                {passwordError && (
                                                    <div className="system-form-control-error-text">
                                                        Invalid password format
                                                    </div>
                                                )}
                                                <div
                                                    className="password-icon"
                                                    onClick={() =>
                                                        togglePasswordVisibility(
                                                            "password"
                                                        )
                                                    }
                                                    role="button"
                                                >
                                                    {showPassword ? (
                                                        <AiFillEyeInvisible />
                                                    ) : (
                                                        <AiFillEye />
                                                    )}
                                                </div>
                                                <OverlayTrigger
                                                    key="role-description"
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip>
                                                            Your password must
                                                            be a minimum of
                                                            eight characters
                                                            long and contain at
                                                            least one letter and
                                                            one number.
                                                        </Tooltip>
                                                    }
                                                >
                                                    <AiOutlineQuestionCircle className="individual-form-icon password-format-icon" />
                                                </OverlayTrigger>
                                            </div>
                                        </Row>
                                        <Row className="login-formcontrol-container g-0">
                                            <div
                                                className={`login-formcontrol-box ${
                                                    confirmPasswordError &&
                                                    "formcontrol-error"
                                                }`}
                                            >
                                                <div className="login-formcontrol-text">
                                                    Confirm New Password
                                                </div>
                                                <FormControl
                                                    size="sm"
                                                    name="confirm_password"
                                                    type="password"
                                                    value={confirmNewPassword}
                                                    onChange={({
                                                        target: { value },
                                                    }) =>
                                                        setConfirmNewPassword(
                                                            value
                                                        )
                                                    }
                                                    onBlur={
                                                        checkConfirmPassword
                                                    }
                                                    className="login-formcontrol"
                                                />
                                                {confirmPasswordError && (
                                                    <div className="system-form-control-error-text">
                                                        Passwords must match!
                                                    </div>
                                                )}
                                                <div
                                                    className="password-icon"
                                                    onClick={() =>
                                                        togglePasswordVisibility(
                                                            "confirm_password"
                                                        )
                                                    }
                                                    role="button"
                                                >
                                                    {showConfirmPassword ? (
                                                        <AiFillEyeInvisible />
                                                    ) : (
                                                        <AiFillEye />
                                                    )}
                                                </div>
                                            </div>
                                        </Row>
                                        <Row className="login-button-row g-0">
                                            <Button
                                                size="sm"
                                                className="teal-button"
                                                onClick={
                                                    sendResetPasswordRequest
                                                }
                                                disabled={disabled}
                                            >
                                                Change Password
                                            </Button>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default ResetPasswordPage;
