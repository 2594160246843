import { formatPairSymbols } from "../../helpers/market";
import * as CONSTANTS from "../constants/market";

export const initialMarketState = {
    orders: [],
    cancels: [],
    fills: [],
    balances: [],
    symbols: [],
    symbolStatuses: [],
    tradefile: {
        data: [],
        date: "",
    },
};

const updateSymbolStatuses = (oldSymbolStatuses, newSymbolStatuses) => {
    if (oldSymbolStatuses.length === 0) return newSymbolStatuses;

    const newSymbolStatusMap = {};

    newSymbolStatuses.forEach((newSymb) => {
        newSymbolStatusMap[newSymb.security] = newSymb;
    });

    // Removes matching symbs if status is now Normal from the OLD array
    // Also deletes matching halted symbols from the new Array
    const filteredArray = oldSymbolStatuses.filter((oldSymb) => {
        if (newSymbolStatusMap[oldSymb.security]) {
            if (newSymbolStatusMap[oldSymb.security].status === "Halt") {
                delete newSymbolStatusMap[oldSymb.security];
                // Keep halted symbol inside filter
                // and remove duplicate so it isn't concated at the end
                return true;
            } else if (
                newSymbolStatusMap[oldSymb.security].status === "Normal"
            ) {
                delete newSymbolStatusMap[oldSymb.security];
                // Remove halted symbol inside filter since it is now normal
                // and remove so it isn't concated at the end
                return false;
            }
        }
        return true;
    });

    // Removes all Normal symbols from the incoming message
    // This case can happen when we get duplicate messages after we change symbol status
    // Since previously it was Halted and is now removed
    // This causes the duplicate message to fly past the previous filters
    Object.values(newSymbolStatusMap).forEach((newSymb) => {
        if (newSymb.status === "Normal")
            delete newSymbolStatusMap[newSymb.security];
    });

    return filteredArray.concat(Object.values(newSymbolStatusMap));
};

export const marketReducer = (state, action) => {
    switch (action.type) {
        case CONSTANTS.QUERY_MULTIPLE_ORDERS:
            return {
                ...state,
                orders: state.orders.concat(action.orders),
            };
        case CONSTANTS.QUERY_CANCELS:
            return {
                ...state,
                cancels: state.cancels.concat(action.cancels),
            };
        case CONSTANTS.QUERY_ACTIVITY_TRADE:
            return {
                ...state,
                fills: state.fills.concat(action.fills),
            };
        case CONSTANTS.QUERY_DEPOSIT:
            return {
                ...state,
                balances: state.balances.concat(action.balances),
            };
        case CONSTANTS.GET_DEPOSIT_SYMBOLS:
            return {
                ...state,
                symbols: action.symbols,
            };
        case CONSTANTS.UPDATE_SYMBOL_STATUS:
            return {
                ...state,
                symbols: state.symbols.map((symb) => {
                    const foundSymb = action.symbols.find(
                        (found) => found.security === symb.security
                    );

                    if (foundSymb) {
                        symb.status = foundSymb.status;
                    }

                    return symb;
                }),
                symbolStatuses: updateSymbolStatuses(
                    state.symbolStatuses,
                    action.symbols
                ),
            };
        case CONSTANTS.APPEND_SYMBOL: {
            return {
                ...state,
                symbols: [...state.symbols, action.symbol],
            };
        }
        case CONSTANTS.UPDATE_PAIRS_WITH_NEW_SYMBOL: {
            state.symbols.forEach((item) => {
                delete item.pairs;
            });

            const filteredSymbols = state.symbols.filter(
                (sym) => sym.security !== action.symbol.security
            );

            return {
                ...state,
                symbols: formatPairSymbols(
                    filteredSymbols,
                    action.tradeSymbols
                ),
            };
        }
        case CONSTANTS.REMOVE_PAIRS_SYMBOL: {
            const newSymbols = state.symbols.filter(
                (elem) => elem.security !== action.symbol.security
            );

            action.symbol.pairs = action.symbol.pairs.filter(
                (elem) => elem !== action.pair
            );

            return {
                ...state,
                symbols: newSymbols.concat([action.symbol]),
            };
        }
        case CONSTANTS.EDIT_SYMBOL: {
            const newSymbols = state.symbols.map((symb) =>
                symb.security === action.symbol.security
                    ? { ...symb, ...action.symbol }
                    : symb
            );

            return {
                ...state,
                symbols: newSymbols,
            };
        }
        case CONSTANTS.DELETE_SYMBOL:
            return {
                ...state,
                symbols: state.symbols.filter(
                    (existing) => existing.security !== action.security
                ),
            };
        case CONSTANTS.GET_TRADE_FILE_DATA: {
            return {
                ...state,
                tradefile: {
                    data: action.data,
                    date: action.date,
                },
            };
        }
        case CONSTANTS.CLEAR_STATE:
            return initialMarketState;
        default:
            return state;
    }
};
