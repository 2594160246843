import { Button, Container, Form, Row } from "react-bootstrap";

export const ConfirmOrderForm = ({ confirmOrder, cancelOrder }) => {
    return (
        <Container fluid>
            <br />
            <Form>
                <Form.Group>
                    <Row>ORDER CONFIRMATION</Row>
                    <br />
                    <Row>Are you sure you want to submit this order?</Row>
                    <br />
                    <Row className="justify-content-between mx-2">
                        <Button
                            variant="light"
                            type="submit"
                            onClick={(ev) => {
                                ev.preventDefault();
                                cancelOrder();
                            }}
                            size="sm"
                        >
                            CANCEL
                        </Button>
                        <Button
                            variant="dark"
                            type="submit"
                            onClick={(ev) => {
                                ev.preventDefault();
                                confirmOrder();
                            }}
                            size="sm"
                        >
                            SUBMIT
                        </Button>
                    </Row>
                </Form.Group>
            </Form>
        </Container>
    );
};
