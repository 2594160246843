import * as CONSTANTS from "../constants/announcement-builder";

export const initialAnnouncementBuilderState = {
    templates: null,
};

export const announcementBuilderReducer = (state, action) => {
    switch (action.type) {
        case CONSTANTS.GET_ANNOUNCEMENT_TEMPLATES:
            return {
                ...state,
                templates: action.templates,
            };
        case CONSTANTS.CREATE_ANNOUNCEMENT_TEMPLATE:
            return {
                ...state,
                templates: [...state.templates, action.template],
            };
        case CONSTANTS.UPDATE_ANNOUNCEMENT_TEMPLATE:
            return {
                ...state,
                templates: state.templates.map((template) =>
                    template._id === action.attrs._id
                        ? {
                              ...template,
                              ...action.attrs,
                          }
                        : template
                ),
            };
        case CONSTANTS.DELETE_ANNOUNCEMENT_TEMPLATE:
            return {
                ...state,
                templates: state.templates.filter(
                    (template) => template._id !== action.templateId
                ),
            };
        case CONSTANTS.CLEAR_STATE:
            return initialAnnouncementBuilderState;
        default:
            return state;
    }
};
