/* eslint-disable react/display-name */

import { memo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Creatable from "react-select/creatable";

import { validateOnlyNumbersLetters } from "../../../../helpers/validate";
import { EditSymbolForm } from "./EditSymbolForm";

export const AddSymbolForm = memo(({ securities, submitFunc }) => {
    const [symbol, setSymbol] = useState({});

    const options = securities.map((sec) => ({
        value: sec,
        label: sec.security,
    }));

    return (
        <>
            <Row
                noGutters
                className="mx-3"
                style={{
                    zIndex: 10,
                    position: "relative",
                }}
            >
                <Col>Symbol</Col>
                <Col>
                    <Creatable
                        options={options}
                        onChange={(e) => {
                            setSymbol({
                                security: e.value?.security || e.value,
                            });
                        }}
                        onKeyDown={validateOnlyNumbersLetters}
                    />
                </Col>
            </Row>

            {Object.keys(symbol).length !== 0 && (
                <EditSymbolForm
                    symbol={symbol}
                    type="add"
                    submitFunc={submitFunc}
                />
            )}
        </>
    );
});
