/* eslint-disable react/display-name */
import { memo } from "react";
import { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import Select from "react-select";

import { SELECT_SMALL_SIZE_STYLE } from "../../../helpers/other";
import { Loading } from "../../Loading";

const DEFAULT_USER = {
    firm: null,
    user: "",
};

export const EditAccountAccessModal = memo(
    ({ show = false, handleClose, handleSubmit, users, loading, accounts }) => {
        const [user, setUser] = useState(DEFAULT_USER);

        useEffect(() => {
            setUser({ ...DEFAULT_USER, accounts });
        }, [accounts, show]);

        return (
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Create Account Access</Modal.Title>
                </Modal.Header>
                <Modal.Body className="size-sm">
                    {loading ? (
                        <Loading />
                    ) : (
                        <>
                            <Form.Group>
                                <Form.Label className="size-sm">
                                    User
                                </Form.Label>
                                <Select
                                    onChange={(e) =>
                                        setUser({
                                            ...user,
                                            user: e.value,
                                            firm: users.find(
                                                (user) =>
                                                    user.userid === e.value
                                            ).firm,
                                        })
                                    }
                                    options={users.map((user) => ({
                                        value: user.userid,
                                        label: user.userid,
                                    }))}
                                    styles={SELECT_SMALL_SIZE_STYLE}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label className="size-sm">
                                    Firm
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    value={user.firm}
                                    name="firm"
                                    size="sm"
                                    disabled
                                />
                            </Form.Group>
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Row noGutters className="w-100 size-sm">
                        <Col className="text-right">
                            <Button
                                size="sm"
                                onClick={() => handleSubmit(user)}
                            >
                                Save
                            </Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        );
    }
);
