import { Button, Modal } from "react-bootstrap";

export const GoBackModal = ({ show = false, handleConfirm, handleClose }) => (
    <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
            <Modal.Title>Return</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            Are you sure you want to go back?
            <br />
            <br />
            <div className="text-danger">
                Any changes that you&apos;ve made will be lost.
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="dark" onClick={handleConfirm}>
                Confirm
            </Button>
            <Button variant="light" onClick={handleClose}>
                Cancel
            </Button>
        </Modal.Footer>
    </Modal>
);
