import { memo, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { styled } from "styled-components";

import {
    allowIOIOrderWithoutPrice,
    autoSelectBuyOrder,
    customTradeEnabled,
    enableCustodianSelection,
    enableUserAccounts,
    isQMSClient,
    orderAlertEnabled,
    orderAlertMessage,
    orderMessage,
    orderMessageTitle,
    orderPrefixMessage,
    orderPreMessage,
    tradeDestinations,
} from "../../../../config";
import { storeOptions } from "../../../../helpers/display-options";
import {
    convertStylingVar,
    getCurrency,
    isPriceAlert,
    multiplyWithPrecision,
    removeCommas,
} from "../../../../helpers/other";
import { addOrder } from "../../../../helpers/trade";
import { DefaultOrderTicketConfig } from "../../../../preferences";
import { useStore } from "../../../../Store";
import { useSocket } from "../../../../Websocket";
import { AdditionalFlagsModal } from "../../AdditionalFlagsModal";
import { ConfirmModal } from "../../ConfirmModal";
import { AdditionalFlagsForm } from "./AdditionalFlagsForm";
import { DestinationField } from "./DestinationField";
import { OrderTicketBasic } from "./OrderTicketBasic";

const SubmitOrderButton = styled(({ className, disabled, onClick }) => (
    <Button
        className={`mb-1 ${className}`}
        disabled={disabled}
        onClick={onClick}
    >
        SUBMIT
    </Button>
))`
    &:not(.dropdown-toggle) {
        background: ${(props) => props.buttonBackground} !important;
        border-color: ${(props) => props.buttonBackground} !important;
        color: ${(props) => props.fontColor} !important;
        &:hover {
            filter: brightness(0.75) !important;
            background: ${(props) => props.buttonBackground} !important;
            color: ${(props) => props.fontColor} !important;
        }
    }
`;

const MoreOptionsButton = styled(({ className, onClick }) => (
    <Button
        onClick={onClick}
        variant="warning"
        className={`w-100 ${className}`}
        size="sm"
    >
        More Options
    </Button>
))`
    background: ${(props) => props.buttonColor} !important;
    color: ${(props) => props.fontColor};
    border-color: ${(props) => props.buttonColor};
    &:hover {
        background: ${(props) => props.buttonColor} !important;
        filter: brightness(0.75) !important;
    }
    &:focus,
    &:hover {
        box-shadow: none !important;
        border-color: ${(props) => props.buttonColor} !important;
        color: ${(props) => props.fontColor};
    }
`;

// eslint-disable-next-line react/display-name
export const OrderTicket = memo(({ layoutKey, securitySlug }) => {
    const ws = useSocket();
    const { dispatch, state } = useStore();

    const { positions, currentSymbol, layoutConfigurationOptions } =
        state.trade;

    const { account } = state.account;

    const orderTicketConfig =
        state.account.account.data.config_setting?.attr?.order_ticket ||
        DefaultOrderTicketConfig;
    const previouslySelectedTab =
        layoutConfigurationOptions.lastSelectedTabs?.[layoutKey];
    const [custodian, setCustodian] = useState("");

    const configuredTabs = Object.entries(orderTicketConfig)
        .filter((tab) => tab[1].display)
        .map((tab) => tab[0]);
    const [tabKey, setTabKey] = useState(
        configuredTabs && configuredTabs.includes(previouslySelectedTab)
            ? previouslySelectedTab
            : configuredTabs[0] || "LIMIT"
    );

    const findOrderTypeValue = (key) =>
        orderTicketConfig?.[key]?.type
            ? orderTicketConfig?.[key]?.type.find((type) => type?.default)
                  ?.ordertype
            : undefined;

    const findTifValue = (key) =>
        orderTicketConfig?.[key]?.tif
            ? orderTicketConfig?.[key]?.tif.find((type) => type?.default)?.value
            : undefined;

    const findCapacityValue = (key) =>
        orderTicketConfig?.[key]?.capacity?.display
            ? orderTicketConfig?.[key]?.capacity?.options.find(
                  (capacity) => capacity?.default
              )?.value
            : undefined;

    const DEFAULT_ORDER = {
        qty: "",
        price: "",
        side: autoSelectBuyOrder ? "B" : undefined,
        // Find default order type
        ordertype: findOrderTypeValue(tabKey),
        // Find default tif
        tif: findTifValue(tabKey),
        // capacity
        capacity: findCapacityValue(tabKey),
    };

    const [currentOrder, setCurrentOrder] = useState(DEFAULT_ORDER);
    const [security, currency] = getCurrency(
        currentSymbol,
        securitySlug,
        positions,
        currentOrder?.account
    );

    const onChangeCurrentOrder = (key, value, key2, value2, additionalFields) =>
        setCurrentOrder({
            ...currentOrder,
            [key]: value,
            [key2]: value2,
            ...additionalFields?.reduce(
                (obj, fld) =>
                    Object.assign(obj, {
                        [fld.fieldName]: undefined,
                        [fld?.bindedField]: undefined,
                    }),
                {}
            ),
        });

    const [showAdditionalFlagsModal, setShowAdditionalFlagsModal] =
        useState(false);
    const handleOpenAdditionalFlagsModal = () =>
        setShowAdditionalFlagsModal(true);
    const handleCloseAdditionalFlagsModal = () =>
        setShowAdditionalFlagsModal(false);

    const [showConfirmOrderModal, setShowConfirmOrderModal] = useState(false);
    const handleOpenConfirmOrderModal = () => setShowConfirmOrderModal(true);
    const handleCloseConfirmOrderModal = () => setShowConfirmOrderModal(false);

    const submitCurrentOrder = () => {
        const getSettingFieldKeyValue = (key) =>
            orderTicketConfig[key]?.setting
                ? {
                      [orderTicketConfig[key].setting.fieldName]:
                          orderTicketConfig[key]?.setting?.fieldValue,
                  }
                : {};

        addOrder(ws, {
            ...currentOrder,
            ...getSettingFieldKeyValue(tabKey),
            security: currentSymbol?.security,
            firm: account?.data?.firm,
            clientOrderId: undefined,
            clientorderid: currentOrder?.clientOrderId,
        });

        setCurrentOrder(DEFAULT_ORDER);

        handleCloseConfirmOrderModal();
    };

    const isOrderAlert =
        orderAlertEnabled &&
        !isPriceAlert(
            parseFloat(currentSymbol?.last),
            parseFloat(currentOrder.price)
        );

    const isOrderTicketDisabled = account?.data?.active !== "Y";

    const requireAccountSelection = enableUserAccounts
        ? orderTicketConfig[tabKey]?.account?.display && !currentOrder?.account
        : false;

    const isAccountRequired =
        orderTicketConfig[tabKey]?.account?.display &&
        enableUserAccounts &&
        !currentOrder?.account;
    const isClientOrderIdRequired =
        orderTicketConfig[tabKey]?.clientOrderId?.display &&
        !orderTicketConfig[tabKey]?.clientOrderId?.optional &&
        !currentOrder?.account;
    const isBrokersRequired =
        orderTicketConfig[tabKey]?.brokers?.display && !currentOrder?.brokers;

    const isSubmitDisabled =
        requireAccountSelection ||
        !currentOrder.side ||
        !currentOrder.qty ||
        (allowIOIOrderWithoutPrice && !currentOrder.price) ||
        isClientOrderIdRequired ||
        isBrokersRequired ||
        isAccountRequired ||
        (customTradeEnabled && !security) ||
        isOrderTicketDisabled ||
        (enableCustodianSelection && !custodian) ||
        !currentSymbol?.security ||
        currentSymbol?.security === "custom";

    const primaryButtonColor = convertStylingVar(
        layoutConfigurationOptions.styling.button.primaryBGColor
    );
    const secondaryButtonColor = convertStylingVar(
        layoutConfigurationOptions.styling.button.secondaryBGColor
    );
    const fontColor = convertStylingVar(
        layoutConfigurationOptions.styling.fontColor
    );

    const submitButtonBG =
        currentOrder.side !== "S" ? primaryButtonColor : secondaryButtonColor;

    const submitButtonFontColor = convertStylingVar(
        layoutConfigurationOptions.styling.button[
            currentOrder.side !== "S"
                ? "primaryFontColor"
                : "secondaryFontColor"
        ]
    );

    const formatOrderMessage = (msg) => {
        return (
            <div style={{ whiteSpace: "pre-wrap" }}>
                {msg
                    .replaceAll("&#x2022;", "    •")
                    .split("\n")
                    .map((row) => {
                        if (row.startsWith("<b>") && row.endsWith("</b>")) {
                            return (
                                <div key={row} style={{ fontWeight: "bold" }}>
                                    {row.replace("<b>", "").replace("</b>", "")}
                                </div>
                            );
                        } else return <div key={row}>{row}</div>;
                    })}
            </div>
        );
    };

    return (
        <div
            className="order-ticket-container"
            style={{
                padding: layoutConfigurationOptions?.padding,
                overflow: "scroll",
                border: layoutConfigurationOptions?.componentBorders
                    ? `1px solid ${fontColor}`
                    : 0,
            }}
        >
            {isOrderTicketDisabled && (
                <div className="order-ticket-overlay">
                    <div>Your account is not active.</div>
                </div>
            )}
            <div>
                <div>
                    <h6
                        style={{
                            color: convertStylingVar(
                                layoutConfigurationOptions.styling
                                    .headerFontColor
                            ),
                        }}
                    >
                        <b>
                            {isQMSClient
                                ? "Enter Indications of Interest"
                                : "ORDER TICKET"}
                        </b>
                    </h6>
                </div>
                <Row className="mb-2 order-ticket-info-row" noGutters>
                    <Col className="d-flex flex-column">
                        <span>{security?.type}</span>
                        <span>{security?.value}</span>
                    </Col>
                    <Col className="d-flex flex-column">
                        <span>{currency?.type}</span>
                        <span>{currency?.value}</span>
                    </Col>
                </Row>
                <OrderTicketBasic
                    tabKey={tabKey}
                    setTabKey={(key) => {
                        setTabKey(key);
                        storeOptions(
                            ws,
                            dispatch,
                            account?.data,
                            "trade_preferences",
                            "trade_screen_configuration",
                            {
                                ...layoutConfigurationOptions.lastSelectedTabs,
                                [layoutKey]: key,
                            },
                            "lastSelectedTabs"
                        );
                    }}
                    custodian={custodian}
                    setCustodian={setCustodian}
                    currentOrder={currentOrder}
                    onChangeCurrentOrder={onChangeCurrentOrder}
                    currentSymbol={currentSymbol}
                    resetOrder={(newKey) =>
                        setCurrentOrder({
                            ...DEFAULT_ORDER,
                            ordertype: findOrderTypeValue(newKey),
                            tif: findTifValue(newKey),
                        })
                    }
                    currency={currency}
                    security={security}
                >
                    {/* Additional Flags Modal Button */}
                    {orderTicketConfig[tabKey]?.additionalFlags?.display &&
                    layoutConfigurationOptions?.expandedOrderTicket[
                        layoutKey
                    ] ? (
                        <>
                            <AdditionalFlagsForm
                                currentOrder={currentOrder}
                                additionalFlags={
                                    orderTicketConfig[tabKey]?.additionalFlags
                                        ?.modalFields
                                }
                                onChange={(
                                    key,
                                    val,
                                    key1,
                                    val2,
                                    additionalFields
                                ) =>
                                    onChangeCurrentOrder(
                                        key,
                                        val || undefined,
                                        key1,
                                        val2 || undefined,
                                        additionalFields
                                    )
                                }
                            />
                        </>
                    ) : (
                        (orderTicketConfig[tabKey]?.additionalFlags?.display ||
                            tradeDestinations?.length > 0) && (
                            <Row className="d-flex order-ticket-flex-item pb-2">
                                {layoutConfigurationOptions
                                    ?.destinationFieldOnMain?.[layoutKey] && (
                                    <Col xs={12}>
                                        <DestinationField
                                            destination={currentOrder?.["dest"]}
                                            setDestination={(val) =>
                                                onChangeCurrentOrder(
                                                    "dest",
                                                    val
                                                )
                                            }
                                        />
                                    </Col>
                                )}
                                {orderTicketConfig[tabKey]?.additionalFlags
                                    ?.display && (
                                    <Col xs={12}>
                                        <MoreOptionsButton
                                            onClick={
                                                handleOpenAdditionalFlagsModal
                                            }
                                            buttonColor={
                                                currentOrder.side !== "S"
                                                    ? primaryButtonColor
                                                    : secondaryButtonColor
                                            }
                                            fontColor={submitButtonFontColor}
                                        />
                                    </Col>
                                )}
                            </Row>
                        )
                    )}
                    {orderTicketConfig[tabKey]?.additionalFlags?.fieldType ===
                        "Modal" && (
                        <AdditionalFlagsModal
                            show={showAdditionalFlagsModal}
                            handleClose={handleCloseAdditionalFlagsModal}
                            currentOrder={currentOrder}
                            onChange={onChangeCurrentOrder}
                            additionalFlags={
                                orderTicketConfig[tabKey]?.additionalFlags
                                    ?.modalFields
                            }
                            layoutKey={layoutKey}
                        />
                    )}
                </OrderTicketBasic>
            </div>
            <div className="d-flex justify-content-between mt-2">
                <div
                    className="d-flex justify-content-between mt-1"
                    style={{ width: "40%" }}
                >
                    <div>Total:</div>
                    <div>
                        {multiplyWithPrecision(
                            removeCommas(currentOrder.qty),
                            removeCommas(currentOrder.price)
                        )}{" "}
                        {currency?.type}
                    </div>
                </div>
                <ConfirmModal
                    size={"lg"}
                    show={showConfirmOrderModal}
                    handleConfirm={submitCurrentOrder}
                    handleClose={handleCloseConfirmOrderModal}
                    title={
                        <span className={isOrderAlert ? "text-danger" : ""}>
                            {isQMSClient
                                ? "ACKNOWLEDGEMENT / AGREEMENT TO QMS TERMS OF USE"
                                : orderMessageTitle}
                        </span>
                    }
                    body={
                        <>
                            {orderPreMessage && (
                                <>
                                    {formatOrderMessage(orderPreMessage)}
                                    <br />
                                </>
                            )}
                            <div>
                                {orderPrefixMessage}
                                {isQMSClient
                                    ? "IOI"
                                    : orderPrefixMessage.length > 0
                                    ? "order"
                                    : "Order"}{" "}
                                to {currentOrder.side === "B" ? "Buy" : "Sell"}
                                &nbsp;{currentOrder.qty} {security?.type} at{" "}
                                {currentOrder.price || "Negotiable"}
                                &nbsp;{currency?.type}
                            </div>
                            <br />

                            {isOrderAlert &&
                            (orderMessage || orderAlertMessage) ? (
                                <div
                                    className={
                                        orderAlertMessage ? "text-danger" : ""
                                    }
                                >
                                    {orderAlertMessage ??
                                        orderMessage.replaceAll(
                                            "{{CUSTODIAN}}",
                                            custodian
                                        )}
                                </div>
                            ) : (
                                orderMessage &&
                                formatOrderMessage(
                                    orderMessage.replaceAll(
                                        "{{CUSTODIAN}}",
                                        custodian
                                    )
                                )
                            )}
                        </>
                    }
                />
                <SubmitOrderButton
                    onClick={handleOpenConfirmOrderModal}
                    disabled={isSubmitDisabled}
                    buttonBackground={submitButtonBG}
                    fontColor={submitButtonFontColor}
                />
            </div>
        </div>
    );
});
