import { useEffect } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { TableRenderer } from "../../../components/portal/TableRenderer";
import {
    clientCurrency,
    enableNewMarketWatch,
    hideHaltedSymbols,
} from "../../../config";
import * as OTHER_ACTIONS from "../../../contexts/actions/other";
import { addCommas, formatTableColumn } from "../../../helpers/other";
import { subscribeSymbols } from "../../../helpers/trade";
import { useStore } from "../../../Store";
import { useSocket } from "../../../Websocket";

const Firms = () => {
    const ws = useSocket();
    const { dispatch, state } = useStore();
    const navigate = useNavigate();

    const {
        trade: {
            symbols,
            positions,
            symbolsSubscribed,
            layoutConfigurationOptions,
        },
        other: { loading },
        account: { account: loggedInUser },
    } = state;

    const symbolWatchList =
        loggedInUser?.data?.attr?.["trade_preferences"]?.["market_watch"]
            ?.watch_list;

    const formatMarketValue = (row) => {
        const rowSymbol = symbols.find((symbol) =>
            symbol.pair && symbol.pair_second === clientCurrency
                ? symbol.pair_first === row.security
                : symbol.security === row.security
        );

        const price = (rowSymbol?.last ?? rowSymbol?.prevcloseprice) || "N/A";
        let marketValue =
            price === "N/A" ? "N/A" : addCommas(parseFloat(row.curpos) * price);

        return (
            <Row
                noGutters
                className="d-flex justify-content-between align-items-center"
            >
                <span>{marketValue}</span>
            </Row>
        );
    };

    useEffect(() => {
        if (symbols.length > 0 && !symbolsSubscribed) {
            subscribeSymbols(
                ws,
                dispatch,
                symbols.filter(
                    (symb) =>
                        (hideHaltedSymbols ? !symb?.attr?.isHidden : symb) &&
                        (enableNewMarketWatch
                            ? symbolWatchList.includes(symb.security)
                            : true)
                ),
                [], // unsub symbols
                layoutConfigurationOptions?.useExternalBookFeed,
                layoutConfigurationOptions?.useExternalTobFeed
            ).finally(() => dispatch(OTHER_ACTIONS.toggleDimLoading()));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [symbols]);

    useEffect(() => {
        !symbolsSubscribed && dispatch(OTHER_ACTIONS.toggleDimLoading());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="m-4">
            <Row noGutters className="forms-header justify-content-between">
                <Col>
                    <h4>My Funds</h4>
                </Col>
            </Row>

            <TableRenderer
                keyField="security"
                data={positions}
                columns={[
                    ...["security", "curpos"].map((col) =>
                        formatTableColumn(col)
                    ),
                    {
                        dataField: "text",
                        text: "Market Value",
                        formatter: (rowContent, row) => formatMarketValue(row),
                    },
                    {
                        dataField: "tradeButton",
                        text: "",
                        isDummyField: true,
                        formatter: (rowContent, row) => (
                            <Button
                                onClick={() =>
                                    navigate(`/portal/trade/${row.security}`)
                                }
                            >
                                Trade
                            </Button>
                        ),
                    },
                ]}
                pagination
                noDataIndication="No funds available."
                striped
                wrapperClasses="size-sm"
                loading={loading}
            />
        </div>
    );
};

export default Firms;
