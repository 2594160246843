import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";

import * as ACTIONS from "../../contexts/actions/account";
import { addUser } from "../../helpers/firm";
import { checkLoginStatus } from "../../helpers/login";
import { useStore } from "../../Store";
import { useSocket } from "../../Websocket";

const Notifications = () => {
    const { dispatch, state } = useStore();
    const ws = useSocket();
    const user = state.account.account.data;
    const [userData, setUserData] = useState({
        ...user,
        pass: undefined,
    });

    const handleSubmit = () => {
        addUser(ws, dispatch, state.account.publicKey, userData, "edit").then(
            () => {
                dispatch(ACTIONS.authRequest());
                checkLoginStatus(dispatch);
            }
        );
    };

    const onChangeAttr = (name, value) =>
        setUserData({
            ...userData,
            attr: {
                ...userData?.attr,
                [name]: value,
            },
        });

    return (
        <div className="m-4">
            <Row noGutters className="forms-header">
                <h4>Notifications</h4>
            </Row>
            <Row noGutters className="forms-header">
                <h5>Please wait for updates after 24 hours</h5>
            </Row>
            <Form>
                <Row noGutters>
                    <Col>
                        <Form.Label className="size-sm">
                            Firm symbol was sold
                        </Form.Label>
                    </Col>
                    <Col>
                        <Form.Check
                            type="checkbox"
                            name="emailFirmSymbolTraded"
                            onChange={({ target: { name, checked } }) =>
                                onChangeAttr(name, checked)
                            }
                            defaultChecked={userData?.emailFirmSymbolTraded}
                            required
                        />
                    </Col>
                </Row>
                <Row noGutters>
                    <Col>
                        <Form.Label className="size-sm">
                            An order was made for my symbol
                        </Form.Label>
                    </Col>
                    <Col>
                        <Form.Check
                            type="checkbox"
                            name="emailSymbolOrder"
                            onChange={({ target: { name, checked } }) =>
                                onChangeAttr(name, checked)
                            }
                            defaultChecked={userData?.emailSymbolOrder}
                            required
                        />
                    </Col>
                </Row>
                <Row noGutters>
                    <Col>
                        <Form.Label className="size-sm">
                            Receive End Of Day Prices
                        </Form.Label>
                    </Col>
                    <Col>
                        <Form.Check
                            type="checkbox"
                            name="emailEndOfDayPrices"
                            onChange={({ target: { name, checked } }) =>
                                onChangeAttr(name, checked)
                            }
                            defaultChecked={userData?.emailEndOfDayPrices}
                            required
                        />
                    </Col>
                </Row>
                <Row noGutters>
                    <Col>
                        <Form.Label className="size-sm">
                            Receive Announcements For Symbols In My Watchlist
                        </Form.Label>
                    </Col>
                    <Col>
                        <Form.Check
                            type="checkbox"
                            name="emailAnnouncements"
                            onChange={({ target: { name, checked } }) =>
                                onChangeAttr(name, checked)
                            }
                            defaultChecked={userData?.emailAnnouncements}
                            required
                        />
                    </Col>
                </Row>{" "}
                <Row noGutters>
                    <Col>
                        <Form.Label className="size-sm">
                            Receive Announcements For All Symbols
                        </Form.Label>
                    </Col>
                    <Col>
                        <Form.Check
                            type="checkbox"
                            name="emailAllAnnouncements"
                            onChange={({ target: { name, checked } }) =>
                                onChangeAttr(name, checked)
                            }
                            defaultChecked={userData?.emailAllAnnouncements}
                            required
                        />
                    </Col>
                </Row>
                <Button onClick={handleSubmit} size="sm">
                    Save
                </Button>
            </Form>
        </div>
    );
};

export default Notifications;
