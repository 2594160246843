import "react-bootstrap-typeahead/css/Typeahead.css";

import { useEffect, useState } from "react";
import { Button, FormControl, Modal } from "react-bootstrap";

export const RiskEditModal = ({
    show = false,
    handleConfirm,
    handleClose,
    editSetting,
}) => {
    const [val, setVal] = useState();
    useEffect(() => {
        setVal(editSetting.value || "");
    }, [editSetting]);

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Edit Risk
                    <br />
                    Trader: {editSetting.trader}
                    <br />
                    Field: {editSetting.field}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FormControl
                    value={val}
                    onChange={(e) => setVal(e.target.value)}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="dark" onClick={() => handleConfirm(val)}>
                    Confirm
                </Button>
                <Button variant="light" onClick={handleClose}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
