/* eslint-disable react/display-name */

import { memo } from "react";
import { Button, Modal } from "react-bootstrap";

export const DeleteFirmModal = memo(
    ({ show = false, handleCloseModal, handleDelete, firm }) => {
        return (
            <Modal show={show} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Firm</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this firm? <br />
                    <div className="text-center">{firm.firm}</div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="danger"
                        onClick={() => handleDelete(firm)}
                        size="sm"
                    >
                        Delete
                    </Button>
                    <Button
                        variant="light"
                        onClick={handleCloseModal}
                        size="sm"
                    >
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
);
