/* eslint-disable react/display-name */

import { memo, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";

import { FromTimeSelector } from "../FromTimeSelector";

export const LoadFormSingle = memo(({ symbols, getData }) => {
    const [enableTime, setEnableTime] = useState(false);
    const [fromTime, setFromTime] = useState(new Date());
    const [symbol, setSymbol] = useState([]);
    const [side, setSide] = useState("");

    return (
        <Container fluid>
            <Form>
                <Row>
                    <Col sm={6} lg={3} xl={2}>
                        <Form.Group>
                            <Form.Label className="size-sm">Symbol</Form.Label>
                            <Typeahead
                                id="symbol"
                                labelKey="security"
                                onChange={setSymbol}
                                options={symbols}
                                placeholder="All"
                                selected={symbol}
                                size="sm"
                            />
                        </Form.Group>
                    </Col>
                    <Col sm={6} lg={3} xl={2}>
                        <Form.Group>
                            <Form.Label className="size-sm">Side</Form.Label>
                            <Form.Control
                                as="select"
                                size="sm"
                                onChange={({ target: { value } }) =>
                                    setSide(value)
                                }
                                value={side}
                            >
                                <option value="">All</option>
                                <option value="B">Buy</option>
                                <option value="S">Sell</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col sm={6} lg={3} xl={2}>
                        <FromTimeSelector
                            fromTime={fromTime}
                            setFromTime={setFromTime}
                            enableTime={enableTime}
                            setEnableTime={setEnableTime}
                        />
                    </Col>
                    <Col sm={6} lg={3} xl={2}>
                        <Button
                            className="mt-4"
                            type="submit"
                            onClick={(ev) => {
                                ev.preventDefault();

                                getData(
                                    symbol[0],
                                    enableTime ? fromTime : undefined,
                                    side
                                );
                            }}
                            size="sm"
                        >
                            Request
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Container>
    );
});
