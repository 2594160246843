export const SUBSCRIBED_TO_SYMBOLS = "SUBSCRIBED_TO_SYMBOLS";

export const ADD_ORDER = "ADD_ORDER";
export const CANCEL_ORDER = "CANCEL_ORDER";
export const EXECUTE_ORDER = "EXECUTE_ORDER";
export const QUERY_TRADE = "QUERY_TRADE";
export const REJECT_ORDER = "REJECT_ORDER";

export const ADD_BOOK = "ADD_BOOK";
export const REMOVE_BOOK = "REMOVE_BOOK";
export const RESET_BOOKS = "RESET_BOOKS";

export const ADD_POSITION = "ADD_POSITION";

export const ADD_TRADE_SYMBOLS = "ADD_TRADE_SYMBOLS";
export const ADD_SYMBOL_PREV_CLOSE_PRICE = "ADD_SYMBOL_PREV_CLOSE_PRICE";
export const ADD_SYMBOL_BID_OFFER = "ADD_SYMBOL_BID_OFFER";

export const ACTIVE_ORDER = "ACTIVE_ORDER";
export const CLEAR_ACTIVE_ORDER = "CLEAR_ACTIVE_ORDER";

export const SUBSCRIBED_SECURITY = "SUBSCRIBED_SECURITY";

export const ADD_TRADE_HISTORY = "ADD_TRADE_HISTORY";

export const TRADE_QUERY_MULTIPLE_ORDERS = "TRADE_QUERY_MULTIPLE_ORDERS";
export const TRADE_QUERY_CANCELS = "TRADE_QUERY_CANCELS";
export const TRADE_QUERY_ACTIVITY_TRADE = "TRADE_QUERY_ACTIVITY_TRADE";
export const TRADE_QUERY_DEPOSIT = "TRADE_QUERY_DEPOSIT";
export const TRADE_QUERY_POSITION = "TRADE_QUERY_POSITION";

export const QUERY_LS_HISTORY = "QUERY_LS_HISTORY";
export const ADD_LS_HISTORY = "ADD_LS_HISTORY";

export const SET_CURRENT_SYMBOL = "SET_CURRENT_SYMBOL";
export const UPDATE_SYMBOL_STATUS = "UPDATE_SYMBOL_STATUS";

export const UPDATE_LAYOUT = "UPDATE_LAYOUT";
export const REMOVE_COMPONENT_FROM_LAYOUT = "REMOVE_COMPONENT_FROM_LAYOUT";
export const RESET_LAYOUT_CONFIGURATION = "RESET_LAYOUT_CONFIGURATION";
export const UPDATE_LAYOUT_CONFIGURATION = "UPDATE_LAYOUT_CONFIGURATION";

export const ADD_AUCTION_INFORMATION = "ADD_AUCTION_INFORMATION";
export const ADD_PORTAL_AUCTION_INFORMATION = "ADD_PORTAL_AUCTION_INFORMATION";

export const CHANGE_SUBSCRIBED_BOOK_SYMBOL = "CHANGE_SUBSCRIBED_BOOK_SYMBOL";

export const RESET = "RESET";
