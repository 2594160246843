import { memo, useEffect, useState } from "react";
import { FormControl } from "react-bootstrap";

import * as TRADE_ACTIONS from "../../../contexts/actions/trade";
import { storeOptions } from "../../../helpers/display-options";
import { subscribeCurrentSymbol } from "../../../helpers/trade";
import { useStore } from "../../../Store";
import { useSubStore } from "../../../SubscriptionData";
import { useSocket } from "../../../Websocket";

// eslint-disable-next-line react/display-name
export const BooksFilterSelect = memo(({ layoutKey }) => {
    const { state, dispatch } = useStore();
    const ws = useSocket();

    const subscriptionContext = useSubStore();
    const { refChangeEmitter } = subscriptionContext;

    const {
        subscribedBookSymbols,
        layoutConfigurationOptions: { activeBooksTableFiltering },
        currentSymbol,
    } = state.trade;

    const layoutConfigurationOptions = state.trade.layoutConfigurationOptions;
    const bookSymbol = activeBooksTableFiltering[layoutKey]?.symbol;

    const symbols = state.trade.symbols.map((order) => order.security);

    const [filter, setFilter] = useState({
        symbol: "",
    });

    const startSubscription = (symbol, prevSymbol) =>
        subscribeCurrentSymbol(
            ws,
            dispatch,
            symbol,
            prevSymbol,
            layoutConfigurationOptions?.useExternalBookFeed,
            () => {}
        );

    const handleChange = (e) => {
        const newFilter = {
            [layoutKey]: { ...filter, [e.target.name]: e.target.value },
        };

        setFilter({ ...filter, [e.target.name]: e.target.value });

        storeOptions(
            ws,
            dispatch,
            state.account.account?.data,
            "trade_preferences",
            "trade_screen_configuration",
            {
                ...layoutConfigurationOptions.activeBooksTableFiltering,
                ...newFilter,
            },
            "activeBooksTableFiltering"
        );

        refChangeEmitter.emit(
            "bookChange",
            subscriptionContext.bookDataRef.current,
            e.target.value,
            layoutKey
        );

        dispatch(
            TRADE_ACTIONS.changeSubscribedBookSymbol(bookSymbol, e.target.value)
        );

        return startSubscription(e.target.value, bookSymbol || undefined);
    };

    useEffect(() => {
        setFilter({
            ...filter,
            ...layoutConfigurationOptions.activeBooksTableFiltering[layoutKey],
        });

        return () => {
            setFilter({
                symbol: "",
            });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [layoutConfigurationOptions.activeBooksTableFiltering[layoutKey]]);

    useEffect(() => {
        if (
            bookSymbol?.length > 0 &&
            !subscribedBookSymbols.includes(bookSymbol) &&
            currentSymbol !== bookSymbol
        ) {
            startSubscription(bookSymbol);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div
            className="d-flex"
            style={{
                paddingBottom: layoutConfigurationOptions?.padding,
            }}
        >
            <FormControl
                as="select"
                size="sm"
                onChange={handleChange}
                name="symbol"
                value={filter?.symbol || ""}
            >
                <option value="">Filter by Symbol</option>
                {symbols.map((opt, i) => (
                    <option key={i} value={opt}>
                        {opt}
                    </option>
                ))}
            </FormControl>
        </div>
    );
});
