import * as CONSTANTS from "../constants/onboarding";

export const initialOnboardingState = {
    forms: [],
    submissions: [],
    roles: [],
    accounts: [],
};

export const onboardingReducer = (state, action) => {
    switch (action.type) {
        case CONSTANTS.QUERY_ONBOARDING_FORMS:
            return {
                ...state,
                forms: action.forms,
            };
        case CONSTANTS.QUERY_ONBOARDING_SUBMISSIONS:
            return {
                ...state,
                submissions: Array.from(action.submissions)
            };
        case CONSTANTS.QUERY_USER_ROLES:
            return {
                ...state,
                roles: action.roles,
            };
        case CONSTANTS.QUERY_EXISTING_ACCOUNTS:
            return {
                ...state,
                accounts: action.accounts,
            };
        case CONSTANTS.CLEAR_STATE:
            return initialOnboardingState;
        default:
            return state;
    }
};
