/* eslint-disable react/display-name */

import { cloneDeep } from "lodash";
import { memo } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import { correctFieldType } from "../../helpers/other";
import { Field } from "./Field";

export const Fields = memo(
    ({
        section,
        setSection,
        disabled,
        storeKey = "fields",
        onboarding = false,
        typeBranch = false,
        typeTable = false,
        setParentModal,
        isGroupField = false,
    }) => {
        const handleOnDragEnd = (result) => {
            if (!result.destination) return;

            const items = cloneDeep(section[storeKey]);
            const [reorderedItem] = items.splice(result.source.index, 1);

            items.splice(result.destination.index, 0, reorderedItem);

            setSection({ ...section, [storeKey]: items });
        };

        const deleteField = (i) =>
            setSection({
                ...section,
                [storeKey]: section[storeKey].filter((item, y) => y !== i),
            });

        const copyField = (i) => {
            const newFields = section[storeKey];
            const copiedField = section[storeKey].find((item, j) => j === i);

            newFields.splice(newFields.indexOf(copiedField), 0, copiedField);

            setSection({
                ...section,
                [storeKey]: newFields,
            });
        };

        return (
            <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="field-sequence">
                    {(provided) => (
                        <ul
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={{
                                listStyle: "none",
                                padding: "0",
                                overflowY: "none",
                            }}
                        >
                            {section[storeKey].map((field, i) => (
                                <Draggable
                                    key={i}
                                    index={i}
                                    draggableId={`field-${i}`}
                                    isDragDisabled={disabled}
                                >
                                    {(provided) => (
                                        <li
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            <Field
                                                key={i}
                                                setSection={(data) =>
                                                    setSection({
                                                        ...section,
                                                        [storeKey]: section[
                                                            storeKey
                                                        ].map((item, j) =>
                                                            i === j
                                                                ? {
                                                                      ...item,
                                                                      ...data,
                                                                  }
                                                                : item
                                                        ),
                                                    })
                                                }
                                                copyField={() => copyField(i)}
                                                deleteField={() =>
                                                    deleteField(i)
                                                }
                                                fieldValue={{
                                                    ...field,
                                                    fieldType: correctFieldType(
                                                        field.fieldType
                                                    ),
                                                }}
                                                onboarding={onboarding}
                                                disabled={disabled}
                                                typeBranch={typeBranch}
                                                typeTable={typeTable}
                                                setParentModal={setParentModal}
                                                isGroupField={isGroupField}
                                            />
                                        </li>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </ul>
                    )}
                </Droppable>
            </DragDropContext>
        );
    }
);
