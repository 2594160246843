import { useEffect } from "react";
import { useState } from "react";
import { Button, Col, Form, Modal, Row, Tab, Tabs } from "react-bootstrap";
import { toast } from "react-toastify";

import { getUsersFromWebsocket } from "../../../helpers/firm";
import {
    getRegistrationSubmissions,
    updateRegistrationSubmission,
} from "../../../helpers/onboarding";
import { approveUser, rejectUser } from "../../../helpers/onboarding";
import { formatTimestamp } from "../../../helpers/other";
import { useStore } from "../../../Store";
import { useSocket } from "../../../Websocket";
import { ActivityItem } from "../ActivityItem";
import { CommentEmailBox } from "./CommentEmailBox";
import { SubmissionRenderer } from "./SubmissionRenderer";

export const ReviewSubmissionModal = ({
    submission,
    setSubmission,
    show = false,
    handleClose,
}) => {
    const ws = useSocket();
    const { state, dispatch } = useStore();

    const {
        account: {
            account: { data: loggedInUser },
        },
    } = state;

    const [submissionStatus, setSubmissionStatus] = useState(
        submission?.status
    );

    const [viewingGroup, setViewingGroup] = useState();

    useEffect(() => {
        if (submission) {
            setSubmissionStatus(submission?.status);
            submission?.data?.form &&
                "groups" in submission.data.form &&
                setViewingGroup(0);
        }
    }, [submission]);

    const updateUserStatus = async (subms) => {
        // We need to check the user's other submissons to see
        // if we can approve/reject and update their user status.
        const otherSubmissions = subms.filter(
            (subm) => subm.userid === submission.userid
        );
        const userRejected = otherSubmissions.some(
            (subm) => subm.status === "REJECTED"
        );
        const userApproved = otherSubmissions.every(
            (subm) => subm.status === "APPROVED"
        );

        // If the user status hasn't changed, don't update.
        if (userRejected && !userApproved) {
            await rejectUser(submission.userid);
        } else if (userApproved && !userRejected) {
            await approveUser(submission.userid);
        }

        // Refresh context users to reflect the user status change.
        (userRejected || userApproved) && getUsersFromWebsocket(ws, dispatch);
    };

    const updateSubmissionStatus = (sts) =>
        updateRegistrationSubmission(
            ws,
            dispatch,
            {
                ...submission,
                data: {
                    ...submission?.data,
                    activity: [
                        ...(submission?.data?.activity || []),
                        {
                            date_created: new Date().getTime(),
                            action_by:
                                loggedInUser?.userid || loggedInUser?.userId,
                            action_type: "status_change",
                            action_data: sts.toLowerCase(),
                        },
                    ],
                    date_approved: sts === "APPROVED" ? Date.now() : null,
                },
                status: sts,
            },
            false
        ).then((newSubmission) => {
            getRegistrationSubmissions(ws, dispatch).then((subms) =>
                updateUserStatus(subms)
            );

            setSubmission({
                ...submission,
                data: newSubmission[1],
                status: sts,
            });

            toast.success("Submission status successfully updated.");
            handleClose();
        });

    return (
        <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Row noGutters>
                    <Col>
                        <Row noGutters>
                            <Modal.Title>
                                {submission?.data?.form?.name || "Unnamed Form"}
                            </Modal.Title>
                        </Row>
                        <Row noGutters className="size-sm">
                            Submission by&nbsp;<b>{submission?.userid}</b>
                        </Row>
                        <Row noGutters className="size-sm">
                            Created on:&nbsp;
                            <b>
                                {formatTimestamp(
                                    submission?.data?.date_created
                                )}
                            </b>
                        </Row>

                        <Row noGutters className="size-sm">
                            Current Status:&nbsp;
                            {submission?.status === "APPROVED" ? (
                                <>
                                    <b>approved</b>&nbsp;on{" "}
                                    {formatTimestamp(
                                        submission?.data?.date_approved
                                    )}
                                </>
                            ) : (
                                <b>{submission?.status}</b>
                            )}
                        </Row>
                    </Col>
                </Row>
            </Modal.Header>
            <Modal.Body className="size-sm">
                <Tabs defaultActiveKey="all-fields">
                    <Tab eventKey="all-fields" title="All Fields">
                        <div className="mt-2">
                            {submission?.data?.form?.groups && (
                                <Row noGutters className="mt-3  mx-1">
                                    <Form.Group className="d-inline-flex w-100">
                                        <Form.Label
                                            className="mr-2 mt-1"
                                            style={{ whiteSpace: "nowrap" }}
                                        >
                                            View Group:
                                        </Form.Label>
                                        <Form.Control
                                            as="select"
                                            size="sm"
                                            className="w-100"
                                            value={viewingGroup || 0}
                                            onChange={(e) =>
                                                setViewingGroup(e.target.value)
                                            }
                                        >
                                            {submission?.data.form.groups.map(
                                                (group, index) => (
                                                    <option
                                                        key={index}
                                                        value={index}
                                                    >
                                                        {group.name}
                                                    </option>
                                                )
                                            )}
                                        </Form.Control>
                                    </Form.Group>
                                </Row>
                            )}
                            {submission && (
                                <SubmissionRenderer
                                    submission={submission}
                                    currentGroup={viewingGroup}
                                />
                            )}
                        </div>
                    </Tab>
                    <Tab eventKey="activity" title="Activity">
                        <div className="my-2">
                            {submission &&
                            submission?.data?.activity &&
                            submission?.data?.activity.length > 0
                                ? submission.data?.activity
                                      .sort(
                                          (a, b) =>
                                              b.date_created - a.date_created
                                      )
                                      .map((act, i) => (
                                          <ActivityItem key={i} action={act} />
                                      ))
                                : "No Activity Available."}
                        </div>
                        <CommentEmailBox
                            submission={submission}
                            setSubmission={setSubmission}
                        />
                    </Tab>
                </Tabs>
            </Modal.Body>
            <Modal.Footer>
                <Row noGutters className="size-sm w-100">
                    <Form.Label className="size-sm mt-1">
                        Set Status:
                    </Form.Label>
                    <Col className="ml-2" xs={4}>
                        <Form.Control
                            as={"select"}
                            size="sm"
                            name="status"
                            onChange={({ target: { value } }) =>
                                setSubmissionStatus(value)
                            }
                            value={submissionStatus}
                        >
                            <option value="" disabled>
                                Choose a status...
                            </option>
                            <option value="APPROVED">Approved</option>
                            <option value="REJECTED">Rejected</option>
                            <option value="SUBMITTED">Submitted</option>
                            <option value="PENDING">Pending</option>
                        </Form.Control>
                    </Col>
                    <Col className="text-right">
                        <Button
                            size="sm"
                            className="px-4"
                            onClick={() => {
                                if (submissionStatus !== submission.status) {
                                    updateSubmissionStatus(submissionStatus);
                                } else {
                                    handleClose();
                                }
                            }}
                        >
                            Save
                        </Button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    );
};
