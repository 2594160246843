import { Row } from "react-bootstrap";

import { UserAccountDropdown } from "../UserAccountDropdown";

export const OnboardingTopbar = () => {
    return (
        <div className="topbar">
            <Row noGutters className="topbar-row">
                <div />
                <UserAccountDropdown />
            </Row>
        </div>
    );
};
