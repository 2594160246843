/* eslint-disable react/display-name */
import { memo, useState } from "react";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";

import { storeOptions } from "../helpers/display-options";
import { ConfigureDisplay } from "./ConfigureDisplayModal";

export const ConfigureDisplayButton = memo(
    ({
        ws,
        dispatch,
        user,
        keys, // Keys that can be displayed
        preexisting, // Keys that are currently displayed
        type,
        optionKey,
        toggleLimitOff = false,
        enableNewTextFormatting = false,
        minimumOptions = 3,
    }) => {
        const [showModal, setShowModal] = useState(false);

        const handleOpenModal = () => setShowModal(true);
        const handleCloseModal = () => setShowModal(false);

        const saveOptions = (newKeys) => {
            // Minimum 3 options permitted.
            if (!toggleLimitOff && newKeys.length < minimumOptions) {
                return toast.error(
                    `You must have at least ${minimumOptions} options selected!`
                );
            } else {
                return storeOptions(
                    ws,
                    dispatch,
                    user,
                    type,
                    optionKey,
                    [...new Set(newKeys)],
                    "columns"
                );
            }
        };

        return (
            <>
                <ConfigureDisplay
                    show={showModal}
                    handleCloseModal={handleCloseModal}
                    keys={keys}
                    selectedKeys={preexisting}
                    saveOptions={saveOptions}
                    enableNewTextFormatting={enableNewTextFormatting}
                />
                <Button onClick={handleOpenModal} size="sm">
                    Configure Display
                </Button>
            </>
        );
    }
);
