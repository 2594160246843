import { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { CreateProfileForm } from "../../../components/admin/asset-builder/CreateProfileForm";
import { GoBackModal } from "../../../components/admin/asset-builder/GoBackModal";
import { FullScreenLoading } from "../../../components/Loading";
import {
    compareFieldObjectImages,
    deleteImageForProfile,
    getAssetTemplatesProfiles,
    remapImageFields,
    setSymbolProfileExists,
    updateAssetTemplateProfile,
    uploadImageForProfile,
} from "../../../helpers/asset-builder";
import { submitEditSymbol } from "../../../helpers/market";
import { useStore } from "../../../Store";
import { useSocket } from "../../../Websocket";

const CreateProfilePage = () => {
    const ws = useSocket();
    const { state, dispatch } = useStore();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const { profile: profileId } = useParams();

    const {
        asset_builder: { templates, profiles },
        market: { symbols },
        trade: { symbols: tradeSymbols },
    } = state;
    const [existingProfile, setExistingProfile] = useState();
    const [profileBeforeChanges, setProfileBeforeChanges] = useState();
    const [template, setTemplate] = useState();

    const editProfile = (newProfile, image) =>
        uploadImageForProfile(image).then((image_url) => {
            const newProfiles = profiles.filter(
                (prof) => prof.id !== profileId
            );

            if (
                (profileBeforeChanges.image && !image_url) ||
                (profileBeforeChanges?.image &&
                    profileBeforeChanges.image !== image_url)
            ) {
                // If image is changed or removed, we need to delete the original image.
                deleteImageForProfile(profileBeforeChanges.image);
            }

            return remapImageFields(newProfile.sections).then((sections) => {
                newProfile.sections = sections;

                for (let i = 0; i < newProfile.sections.length; i++) {
                    const newSection = newProfile.sections[i];
                    const oldSectionToCompare =
                        profileBeforeChanges.sections[i];

                    compareFieldObjectImages(oldSectionToCompare, newSection);
                }

                return updateAssetTemplateProfile(ws, dispatch, {
                    templates: templates,
                    profiles: newProfiles.concat([
                        {
                            ...newProfile,
                            image: image_url,
                            updatedAt: new Date().getTime(),
                        },
                    ]),
                })
                    .then(() => {
                        const [newSymbol, oldSymbol] = setSymbolProfileExists(
                            symbols,
                            newProfile,
                            profileBeforeChanges
                        );

                        oldSymbol?.security &&
                            submitEditSymbol(
                                ws,
                                dispatch,
                                oldSymbol,
                                tradeSymbols
                            );

                        return (
                            newSymbol?.security &&
                            submitEditSymbol(
                                ws,
                                dispatch,
                                newSymbol,
                                tradeSymbols
                            )
                        );
                    })
                    .then(() => {
                        toast.success("Profile successfully edited.");
                        navigate("/admin/asset-profiles");
                    });
            });
        });
    useEffect(() => {
        if (existingProfile && templates.length > 0) {
            setTemplate(
                templates.find(
                    (temp) => temp.id === existingProfile.templateId.id
                )
            );
        }
    }, [existingProfile, templates]);

    useEffect(() => {
        if (profiles.length > 0) {
            const profile = profiles.find((prof) => prof.id === profileId);

            setProfileBeforeChanges(JSON.parse(JSON.stringify(profile)));

            setExistingProfile(profile);
        }
    }, [profiles, profileId]);

    useEffect(() => {
        if (profiles.length === 0) {
            getAssetTemplatesProfiles(ws, dispatch).then(() =>
                setLoading(false)
            );
        } else {
            setLoading(false);
        }

        return () => setLoading(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [showGoBackModal, setShowGoBackModal] = useState(false);
    const handleHideGoBackModal = () => setShowGoBackModal(false);
    const handleShowGoBackModal = () => setShowGoBackModal(true);

    return (
        <div className="m-4">
            <Row noGutters className="forms-header">
                <AiOutlineArrowLeft
                    className="asset-back-button"
                    onClick={handleShowGoBackModal}
                />
                <h4>Create Profile</h4>
            </Row>
            <GoBackModal
                show={showGoBackModal}
                handleClose={handleHideGoBackModal}
                handleConfirm={() => {
                    handleHideGoBackModal();
                    navigate(`/admin/asset-profiles/`);
                }}
            />
            {loading ? (
                <FullScreenLoading />
            ) : (
                <CreateProfileForm
                    symbols={symbols}
                    submitData={editProfile}
                    template={template}
                    existingProfile={existingProfile}
                    type={"edit"}
                />
            )}
        </div>
    );
};

export default CreateProfilePage;
