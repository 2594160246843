/* eslint-disable react/display-name */
import "react-datepicker/dist/react-datepicker.css";

import { memo } from "react";
import { Form, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";

import { clientDateFormat } from "../config";

export const FromTimeSelector = memo(
    ({ fromTime, setFromTime, enableTime, setEnableTime }) => {
        const toggleFromTime = ({ target: { checked } }) =>
            setEnableTime(checked);

        return (
            <Form.Group>
                <Row>
                    <Form.Check type="checkbox" onChange={toggleFromTime} />
                    <Form.Label className="size-sm">From Time</Form.Label>
                </Row>
                <DatePicker
                    selected={fromTime}
                    onChange={(date) => setFromTime(date)}
                    disabled={!enableTime}
                    dateFormat={clientDateFormat}
                />
            </Form.Group>
        );
    }
);
