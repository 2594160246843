/* eslint-disable react/display-name */
import { memo } from "react";

// import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Section } from "./Section";

export const Sections = memo(({ template, setTemplate }) => {
    // const handleOnDragEnd = (result) => {
    //     if (!result.destination) return;

    //     const items = Array.from(template.sections);
    //     const [reorderedItem] = items.splice(result.source.index, 1);

    //     items.splice(result.destination.index, 0, reorderedItem);

    //     setTemplate({ ...template, sections: items });
    // };

    const setIndex = (from, to) => {
        const newSections = template.sections;
        const section = newSections.splice(from, 1)[0];

        newSections.splice(to, 0, section);

        setTemplate({ ...template, sections: newSections });
    };

    const deleteSection = (i) =>
        setTemplate({
            ...template,
            sections: template.sections.filter((item, y) => y !== i),
        });

    return (
        // <DragDropContext onDragEnd={handleOnDragEnd}>
        //     <Droppable droppableId="section-sequence">
        //         {(provided) => (
        //             <ul
        //                 {...provided.droppableProps}
        //                 ref={provided.innerRef}
        //                 style={{
        //                     listStyle: "none",
        //                     padding: "0",
        //                     overflowY: "none",
        //                 }}
        //             >
        template.sections.map((section, i) => (
            // <Draggable
            //     key={i}
            //     index={i}
            //     draggableId={`section-${i}`}
            //     isDragDisabled={template.isPublished}
            // >
            //     {(provided) => (
            //         <li
            //             ref={provided.innerRef}
            //             {...provided.draggableProps}
            //             {...provided.dragHandleProps}
            //         >
            <Section
                key={i}
                index={i}
                length={template.sections.length}
                setIndex={setIndex}
                section={section}
                setSection={(data) =>
                    setTemplate({
                        ...template,
                        sections: template.sections.map((item, j) =>
                            i === j
                                ? {
                                      ...item,
                                      ...data,
                                  }
                                : item
                        ),
                    })
                }
                deleteSection={() => deleteSection(i)}
                disabled={template.isPublished}
            />
            //         </li>
            //     )}
            // </Draggable>
        ))
        //                 {provided.placeholder}
        //             </ul>
        //         )}
        //     </Droppable>
        // </DragDropContext>
    );
});
