import * as ACTIONS from "../contexts/actions/account";
import { sendMessageQuery } from "./websocket";


const platformSlug = "fundamental-interactions"

// Send Brassica Message
export const sendBrassicaMessage = (ws, rest_type, main_action, identifier, sub_type, data = undefined, return_html = undefined,) => sendMessageQuery(ws, "restaction", {
    dest: "BRASSICA",
    rest_type: rest_type,
    main_action,
    sub_type,
    identifier,
    return_html,
    json_data: data ? JSON.stringify({
        data: {
            attributes: {
                ...data
            }
        }
    }) : undefined
}).then((response) => return_html ? response.return_data : JSON.parse(response.return_data.replaceAll('\u001c', '&quot; ').replaceAll('\u001d', ' &apos;')));


// 1.1 Create a custody account application
// No longer needed, automatically run on register on backend
export const createCustodyAccountApplication = (ws, accountHolderType, applicantEmail, applicantName) =>
    sendBrassicaMessage(ws,
        `platforms/${platformSlug}/custody-account-applications`,
        "P",
        { accountHolderType, applicantEmail, applicantName })


// 1.2 Retrieve disclosures for a custody account application
export const getCustodyAccountApplicationDisclosures = (ws, custodyAccountApplicationId, getHTML = false) =>
    sendBrassicaMessage(ws,
        "Q",
        `custody-account-applications`,
        custodyAccountApplicationId,
        "disclosures",
        undefined,
        getHTML
    )

// 1.3 Complete the disclosure acceptance
export const completeDisclosureAcceptance = (ws, agreementId, accountOwnerId, ipAddress) =>
    sendBrassicaMessage(ws,
        "P",
        `agreements`,
        agreementId,
        'disclosure-acceptance',
        { clientMeta: { accountOwnerId, ipAddress } })

// 2.1 Create a person
export const createPerson = (ws, dispatch, user, person) =>
    sendBrassicaMessage(ws,
        "P",
        `platforms/${platformSlug}/persons`,
        undefined,
        undefined,
        person).then((person) => {
            // person.data.id
            // Save person id to user 
            updateUser(ws, dispatch, {
                ...user,
                extern_account: person.data.id
            });

            return person;
        })


// 2.2 Create a KYC profile
export const createKYCProfile = (ws, applicationPersonId, kycProfile) =>
    sendBrassicaMessage(ws,
        "P",
        `persons`,
        applicationPersonId,
        "kyc-profile",
        kycProfile)

// 2.3 Designate person for account application
export const designatePersonForAccountApplication = (ws, applicationPersonId, custodyAccountApplicationId) =>
    sendMessageQuery(ws, "restaction", {
        dest: "BRASSICA",
        rest_type: "U",
        main_action: "custody-account-applications",
        identifier: custodyAccountApplicationId,
        json_data: JSON.stringify({
            data: {
                relationships: {
                    signerPersons: [
                        {
                            id: applicationPersonId
                        }
                    ],
                    holderPersons: [
                        {
                            id: applicationPersonId
                        }
                    ],
                    taxReportingPerson: {
                        id: applicationPersonId
                    }
                }
            }
        })
    });
// 3.1 Submit Custody Account Application For Review
export const submitCustodyAccountApplicationForReview = (ws, custodyAccountApplicationId) =>
    sendBrassicaMessage(ws,
        "U",
        `custody-account-applications`,
        custodyAccountApplicationId,
        undefined,
        { status: "submitted" })

// 4.1 Get KYC Applications for Account Application
export const getKYCApplicationsForAccountApplication = (ws, custodyAccountApplicationId) =>
    sendBrassicaMessage(ws,
        "Q",
        `custody-account-applications`,
        custodyAccountApplicationId,
        "kyc-applications")
// 4.2 Get KYC Checks for KYC Application
export const getKYCChecksForKYCApplication = (ws, kycApplicationId) =>
    sendBrassicaMessage(ws,
        "Q",
        `kyc-applications`,
        kycApplicationId,
        "kyc-checks").then((res) => res.data.map((kycCheck) => kycCheck.id))

// 4.3 Generate Liveliness Check URL
export const generateLivelinessCheckURL = (ws, kycCheckId, name, email) =>
    sendBrassicaMessage(ws,
        "P",
        `kyc-checks`,
        kycCheckId,
        "kyc-session",
        {
            name,
            email
        }).then((res) => res?.data?.attributes?.url)

// 4.3 Enable Liveliness Check (SANDBOX ONLY)
export const enableLivelinessCheck = (ws, kycCheckId) =>
    sendBrassicaMessage(ws,
        "U",
        `sandbox/kyc-checks`,
        kycCheckId,
        undefined,
        {
            status: "liveliness-check-required"
        })

// 4.4 Approve KYC Application (SANDBOX ONLY)
export const approveKYCApplication = (ws, kycApplicationId) =>
    sendBrassicaMessage(ws,
        "U",
        `sandbox/kyc-applications`,
        kycApplicationId,
        undefined,
        {
            status: "approved"
        })

// 5.1 Get Electronic Signatures For The Custody Agreement
export const getElectronicSignaturesForCustodyAgreement = (ws, custodyAccountApplicationId) =>
    sendBrassicaMessage(ws,
        "Q",
        `custody-account-applications`,
        custodyAccountApplicationId,
        "signer-electronic-signatures",
        undefined,
        undefined,
        true).then((res) => {
            return res.data.map((electronicSignature) => electronicSignature)[0]
        })

// 5.2 Display Custody Agreement For Signature
export const displayCustodyAgreementForSignature = (ws, electronicSignatureId) =>
    sendBrassicaMessage(ws,
        "Q",
        `electronic-signatures`,
        electronicSignatureId,
        "agreement",
        undefined,
        true)

// 5.3 Sign Custody Agreement
export const signCustodyAgreement = (ws, electronicSignatureId, accountOwnerId, ipAddress, signerMark) =>
    sendBrassicaMessage(ws,
        "U",
        `electronic-signatures`,
        electronicSignatureId,
        undefined,
        {
            clientMeta: { accountOwnerId, ipAddress },
            signerMark,
        }
    )

// 5.4 Get Electronic Signatures For The Nominee Agreement
export const getElectronicSignaturesForNomineeAgreement = (ws, custodyAccountApplicationId) =>
    sendBrassicaMessage(ws,
        "Q",
        `custody-account-applications`,
        custodyAccountApplicationId,
        "nominee-trust-agreement-electronic-signature").then((res) => res.data.id)

// 5.5 Display Nominee Agreement For Signature
export const displayNomineeAgreementForSignature = (ws, nomineeElectronicSignatureId) =>
    sendBrassicaMessage(ws,
        "Q",
        `electronic-signatures`,
        nomineeElectronicSignatureId,
        "agreement",
        undefined,
        true)

// 5.6 Sign Nominee Agreement
export const signNomineeAgreement = (ws, nomineeElectronicSignatureId, accountOwnerId, ipAddress, signerMark) =>
    sendBrassicaMessage(ws,
        "U",
        `electronic-signatures`,
        nomineeElectronicSignatureId,
        undefined,
        {
            clientMeta: { accountOwnerId, ipAddress },
            signerMark,
        }
    )

export const updateUser = (
    ws,
    dispatch,
    user,
) => {
    delete user.type
    delete user.result
    sendMessageQuery(ws, "adduser", {
        ...user,
        updateprof: true,
    }).then((res) => {
        dispatch(ACTIONS.authSuccess(res));
    })
};