/* eslint-disable react/display-name */

import { memo, useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { AiOutlineSetting } from "react-icons/ai";

import { enableBreakTrade } from "../../../../config";
import {
    getAdminMultipleOrders,
    getAdminTrades,
} from "../../../../helpers/market";
import { useStore } from "../../../../Store";
import { useSocket } from "../../../../Websocket";
import { Loading } from "../../../Loading";
import { OrderDetailModal } from "../../../portal/OrderDetailModal";
import { TableRenderer } from "../../../TableRenderer";
import { TransactionDetailModal } from "./TransactionDetailModal";

export const Fills = memo(
    ({ fills, loading = false, isAdmin, showBreakTradePopup }) => {
        const ws = useSocket();
        const { dispatch } = useStore();

        const [selectedOrder, setSelectedOrder] = useState(null);
        const [showOrderDetailModal, setShowOrderDetailModal] = useState(false);
        const handleOpenOrderDetailModal = () => setShowOrderDetailModal(true);
        const handleCloseOrderDetailModal = () =>
            setShowOrderDetailModal(false);

        const [transactions, setTransactions] = useState([]);
        const [showTransactionDetailModal, setShowTransactionDetailModal] =
            useState(false);
        const handleOpenTransactionDetailModal = () =>
            setShowTransactionDetailModal(true);
        const handleCloseTransactionDetailModal = () =>
            setShowTransactionDetailModal(false);

        const onOpenTransactionDetailModal = async (row) => {
            const trades = await getAdminTrades(ws, dispatch, {
                exchangeref: row.exchangeref,
            });

            let newTransactions = [];
            for (let trade of trades) {
                const multiOrdersRes = await getAdminMultipleOrders(
                    ws,
                    dispatch,
                    "rootref",
                    { rootref: trade.rootref }
                );
                newTransactions = [...newTransactions, ...multiOrdersRes];
            }

            setSelectedOrder(row);
            setTransactions(newTransactions);
            handleOpenTransactionDetailModal(true);
        };

        const refnoColumn = [
            {
                dataField: "refno",
                text: "Ref no.",
                sort: true,
                formatter: (rowContent, row) => {
                    return (
                        <span
                            aria-hidden="true"
                            style={{
                                paddingBottom: "2px",
                                borderBottom: "1px solid",
                                cursor: "pointer",
                            }}
                            onClick={() => {
                                handleOpenOrderDetailModal();
                                setSelectedOrder(row);
                            }}
                        >
                            {row.refno}
                        </span>
                    );
                },
            },
            {
                dataField: "rootref",
                text: "Rootref",
                sort: true,
                formatter: (rowContent, row) => {
                    return (
                        <span
                            aria-hidden="true"
                            style={{
                                paddingBottom: "2px",
                                borderBottom: "1px solid",
                                cursor: "pointer",
                            }}
                            onClick={() => onOpenTransactionDetailModal(row)}
                        >
                            {row.refno}
                        </span>
                    );
                },
            },
        ];

        const isBrokenColumn = {
            dataField: "isbroken",
            text: "Is Broken",
            sort: true,
            formatter: (rowContent, row) => (
                <span>{row?.isbroken ? "true" : "false"}</span>
            ),
        };
        const breakTradeColumn = {
            dataField: "settings-column",
            text: "Action",
            isDummyField: true,
            formatter: (rec, row) => (
                <DropdownButton
                    variant="light"
                    title={<AiOutlineSetting /> || "Settings"}
                >
                    <Dropdown.Item
                        style={{ color: "red" }}
                        onClick={() => {
                            showBreakTradePopup(row.refno, row.exchangeref);
                        }}
                    >
                        Break Trade
                    </Dropdown.Item>
                </DropdownButton>
            ),
        };

        const additionalColumns = enableBreakTrade
            ? isAdmin
                ? [isBrokenColumn, breakTradeColumn]
                : [isBrokenColumn]
            : [];

        return loading ? (
            <Loading />
        ) : (
            <>
                <TableRenderer
                    keyField="traderefno"
                    keyType={"market_preferences"}
                    optionKey={"fills"}
                    defaultOptions={[
                        "userid",
                        "security",
                        "execqty",
                        "execprice",
                        "type",
                        "side",
                        "account",
                    ]}
                    interactColumn={refnoColumn}
                    settingsColumn={additionalColumns}
                    blacklistedAttr={["refno", "rootref", "isbroken"]}
                    data={fills}
                    noDataIndication="No fills available."
                    pagination
                    striped
                    wrapperClasses="size-sm"
                    exportCSVName="fills"
                />
                <OrderDetailModal
                    show={showOrderDetailModal}
                    handleClose={handleCloseOrderDetailModal}
                    selectedOrder={selectedOrder}
                />
                <TransactionDetailModal
                    show={showTransactionDetailModal}
                    handleClose={handleCloseTransactionDetailModal}
                    transactions={transactions}
                    refno={selectedOrder?.refno}
                    isAdmin={isAdmin}
                />
            </>
        );
    }
);
