/* eslint-disable react/display-name */

import { memo } from "react";
import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";

import { enableFirmAdditionalInfo } from "../../../../config";

export const AddEditFirmModal = memo(
    ({ show = false, handleCloseModal, handleSubmit, type = "add", firm }) => {
        const [firmData, setFirmData] = useState(type === "add" ? {
            firm: "",
            description: "",
        } : { ...firm, isEdit: undefined });


        const additionalFirmInfo = [
            { label: "Firm Description", key: "firmDescription" },
            { label: "Firm Name", key: "firmName" },
            { label: "Firm ID", key: "firmID" },
            { label: "Firm Type", key: "firmType", options: ["TRADING", "CLEARING"] },
            { label: "Firm State", key: "firmState", options: ["ACTIVE", "INACTIVE"] },
            { label: "Firm Organization Type", key: "firmOrganizationType", options: ["Sole proprietorship", "Partnerships", "Limited liability company (LLC)", "C corp", "S corp", "B corp"] },
            { label: "Firm Legal Entity Name", key: "firmLegalEntityName" },
            { label: "Firm Legal Entity ID", key: "firmLegalEntityID" },
            { label: "Firm Taxpayer ID Number", key: "firmTaxpayerIDNumber" },
            { isSeperator: true, label: "Primary Contact Information", key: "primaryContactInformation" },
            { label: "Full Name", key: "fullName" },
            { label: "Email Address", key: "emailAddress" },
            { label: "Phone Number", key: "phoneNumber" },
            { label: "Address Line 1", key: "addressLine1" },
            { label: "Address Line 2", key: "addressLine2" },
            { label: "City", key: "addressCity" },
            { label: "State", key: "addressState" },
            { label: "Postal Code", key: "addressPostalCode" },
            { label: "Country Code", key: "addressCountryCode" },
        ];

        const handleOnChange = ({ target: { name, value } }) =>
            setFirmData({ ...firmData, [name]: value });

        const disabled = !firmData.firm;

        const renderAdditionalFirmInfo = () =>
            additionalFirmInfo.map((additionalFirmInfoField) => (
                <Form.Group key={additionalFirmInfoField.key}>
                    <Form.Label className="size-sm">
                        {additionalFirmInfoField.label}
                    </Form.Label>
                    {!additionalFirmInfoField.isSeperator &&
                        (additionalFirmInfoField.options ? (
                            <Form.Control
                                as="select"
                                size="sm"
                                className="w-50 ml-2"
                                name={additionalFirmInfoField.key}
                                value={firmData[additionalFirmInfoField.key] || ""}
                                onChange={handleOnChange}
                            >
                                <option value="" disabled>
                                    Select your option
                                </option>
                                {additionalFirmInfoField.options.map((option, i) => (
                                    <option value={option} key={i}>
                                        {option}
                                    </option>
                                ))}
                            </Form.Control>
                        ) : (
                            <Form.Control
                                type={"text"}
                                name={additionalFirmInfoField.key}
                                placeholder={additionalFirmInfoField.label}
                                value={firmData[additionalFirmInfoField.key] || ""}
                                onChange={handleOnChange}
                                size="sm"
                            />
                        ))}
                </Form.Group>
            ));

        return (
            <Modal show={show} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {type === "add" ? "Create" : "Edit"} Firm
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label className="size-sm">Firm</Form.Label>
                            <Form.Control
                                type="text"
                                name="firm"
                                placeholder="Firm"
                                value={firmData?.firm || ""}
                                onChange={handleOnChange}
                                disabled={type !== "add"}
                                size="sm"
                            />
                        </Form.Group>
                        {enableFirmAdditionalInfo ? renderAdditionalFirmInfo() : <Form.Group>
                            <Form.Label className="size-sm">Firm Description</Form.Label>
                            <Form.Control
                                type="text"
                                name="description"
                                placeholder="Firm Description"
                                value={firmData?.description || ""}
                                onChange={handleOnChange}
                                size="sm"
                            />
                        </Form.Group>}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="dark"
                        onClick={() => handleSubmit(firmData)}
                        disabled={disabled}
                        size="sm"
                    >
                        {type === "add" ? "Create" : "Edit"}
                    </Button>
                    <Button
                        variant="light"
                        onClick={handleCloseModal}
                        size="sm"
                    >
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
);
