/* eslint-disable react/display-name */
import { memo } from "react";
import { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

import {
    addUserSetTrader,
    enableFirmAdditionalInfo,
    enableRegisterPhoneNumber,
} from "../../../config";
import { SELECT_SMALL_SIZE_STYLE } from "../../../helpers/other";

export const EditUserModal = memo(
    ({
        show = false,
        handleClose,
        user,
        handleSubmit,
        deleteUser,
        firms,
        roles,
    }) => {
        const [editingUser, setEditingUser] = useState(user || {});
        const navigate = useNavigate();

        const handleOnChangeAttr = ({ target: { name, value } }) =>
            setEditingUser({
                ...editingUser,
                attr: {
                    ...editingUser?.attr,
                    [name]: value,
                },
            });

        useEffect(() => {
            setEditingUser(user);
        }, [user, show]);

        return (
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        {user ? "User Data" : "Create User"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="size-sm">
                    {user && (
                        <Form.Group>
                            <Form.Label className="size-sm">ID</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="ID"
                                size="sm"
                                value={
                                    editingUser?.userid || editingUser?.userId
                                }
                                disabled
                            />
                        </Form.Group>
                    )}
                    <Form.Group>
                        <Form.Label className="size-sm">First Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="First Name"
                            size="sm"
                            value={editingUser?.attr?.first_name ?? ""}
                            name={user ? "first_name" : "first"}
                            onChange={handleOnChangeAttr}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label className="size-sm">Last Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Last Name"
                            size="sm"
                            value={editingUser?.attr?.last_name ?? ""}
                            name={user ? "last_name" : "last"}
                            onChange={handleOnChangeAttr}
                        />
                    </Form.Group>
                    {enableRegisterPhoneNumber && (
                        <Form.Group>
                            <Form.Label className="size-sm">
                                Phone Number
                            </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Phone Number"
                                size="sm"
                                value={editingUser?.attr?.phone_number ?? ""}
                                name={"phone_number"}
                                onChange={handleOnChangeAttr}
                            />
                        </Form.Group>
                    )}
                    <Form.Group>
                        <Form.Label className="size-sm">Email</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Email"
                            size="sm"
                            name="email"
                            value={editingUser?.attr?.email ?? ""}
                            disabled={user}
                            onChange={handleOnChangeAttr}
                        />
                    </Form.Group>
                    {user ? (
                        <Form.Group>
                            <Form.Label className="size-sm">
                                Confirmed Email
                            </Form.Label>
                            <Form.Control
                                type="text"
                                size="sm"
                                value={
                                    editingUser?.attr?.email_confirmed === "Y"
                                        ? "Yes"
                                        : "No"
                                }
                                disabled
                            />
                        </Form.Group>
                    ) : (
                        <Form.Group>
                            <Form.Label className="size-sm">
                                Password
                            </Form.Label>
                            <Form.Control
                                type="password"
                                size="sm"
                                name="password"
                                value={editingUser?.attr?.password ?? ""}
                                onChange={handleOnChangeAttr}
                            />
                        </Form.Group>
                    )}
                    {!user && (
                        <>
                            {enableFirmAdditionalInfo ? (
                                <>
                                    <Form.Group>
                                        <Form.Label className="size-sm">
                                            Clearing Firm
                                        </Form.Label>
                                        <Select
                                            onChange={(e) =>
                                                handleOnChangeAttr({
                                                    target: {
                                                        name: "clear_firm",
                                                        value: e.value,
                                                    },
                                                })
                                            }
                                            options={firms
                                                .filter(
                                                    (firm) =>
                                                        firm.firmState ===
                                                            "ACTIVE" &&
                                                        firm.firmType ===
                                                            "CLEARING"
                                                )
                                                .map((firm) => ({
                                                    value: firm.firm,
                                                    label: firm.firm,
                                                }))}
                                            styles={SELECT_SMALL_SIZE_STYLE}
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label className="size-sm">
                                            Trading Firm
                                        </Form.Label>
                                        <Select
                                            onChange={(e) =>
                                                handleOnChangeAttr({
                                                    target: {
                                                        name: "firm",
                                                        value: e.value,
                                                    },
                                                })
                                            }
                                            options={firms
                                                .filter(
                                                    (firm) =>
                                                        firm.firmState ===
                                                            "ACTIVE" &&
                                                        firm.firmType ===
                                                            "TRADING"
                                                )
                                                .map((firm) => ({
                                                    value: firm.firm,
                                                    label: firm.firm,
                                                }))}
                                            styles={SELECT_SMALL_SIZE_STYLE}
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label className="size-sm">
                                            Clearing House
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Clearing House"
                                            size="sm"
                                            name="clear_house"
                                            value={
                                                editingUser?.attr
                                                    ?.clear_house ?? ""
                                            }
                                            onChange={handleOnChangeAttr}
                                        />
                                    </Form.Group>
                                </>
                            ) : (
                                <Form.Group>
                                    <Form.Label className="size-sm">
                                        Firm
                                    </Form.Label>
                                    <Select
                                        onChange={(e) =>
                                            handleOnChangeAttr({
                                                target: {
                                                    name: "firm",
                                                    value: e.value,
                                                },
                                            })
                                        }
                                        options={firms.map((firm) => ({
                                            value: firm.firm,
                                            label: firm.firm,
                                        }))}
                                        styles={SELECT_SMALL_SIZE_STYLE}
                                    />
                                </Form.Group>
                            )}
                            {addUserSetTrader && (
                                <Form.Group>
                                    <Form.Label className="size-sm">
                                        Trader
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Trader"
                                        size="sm"
                                        name="complianceid"
                                        value={
                                            editingUser?.attr?.complianceid ??
                                            ""
                                        }
                                        onChange={handleOnChangeAttr}
                                    />
                                </Form.Group>
                            )}
                        </>
                    )}
                    <Form.Group>
                        <Form.Label className="size-sm">Role</Form.Label>
                        {user ? (
                            <Form.Control
                                type="text"
                                placeholder="Role"
                                size="sm"
                                value={editingUser?.attr?.role?.name ?? ""}
                                disabled
                            />
                        ) : (
                            <Select
                                onChange={(e) =>
                                    handleOnChangeAttr({
                                        target: {
                                            name: "role",
                                            value: JSON.stringify({
                                                name: e.label,
                                                id: e.value,
                                            }),
                                        },
                                    })
                                }
                                options={roles.map((role) => ({
                                    value: role.id,
                                    label: role.name,
                                }))}
                                styles={SELECT_SMALL_SIZE_STYLE}
                            />
                        )}
                    </Form.Group>
                    {user && (
                        <Form.Group>
                            <Form.Label className="size-sm">Status</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Status"
                                size="sm"
                                value={editingUser?.attr?.status ?? ""}
                                disabled
                            />
                        </Form.Group>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Row noGutters className="w-100 size-sm">
                        {user && (
                            <>
                                <Form.Label className="size-sm mt-1">
                                    Set Status:
                                </Form.Label>
                                <Col className="ml-2" xs={4}>
                                    <Form.Control
                                        as={"select"}
                                        size="sm"
                                        value={editingUser?.attr?.status ?? ""}
                                        name="status"
                                        onChange={handleOnChangeAttr}
                                    >
                                        <option value="" disabled>
                                            Choose a status...
                                        </option>
                                        <option value="approved">
                                            Approved
                                        </option>
                                        <option value="rejected">
                                            Rejected
                                        </option>
                                        <option value="open">Open</option>
                                    </Form.Control>
                                </Col>
                            </>
                        )}
                        <Col className="text-right">
                            <Button
                                size="sm"
                                onClick={() =>
                                    handleSubmit({
                                        ...editingUser,
                                        attr: {
                                            ...editingUser?.attr,
                                            role: JSON.stringify(
                                                editingUser?.attr?.role || {}
                                            ),
                                        },
                                    })
                                }
                            >
                                Save
                            </Button>
                            {user && (
                                <>
                                    <Button
                                        size="sm"
                                        className="ml-1"
                                        onClick={() =>
                                            navigate(
                                                `/admin/submissions?email=${encodeURIComponent(
                                                    editingUser?.attr?.email
                                                )}${
                                                    editingUser?.attr
                                                        ?.status === "open"
                                                        ? "&status=PENDING"
                                                        : ""
                                                }`
                                            )
                                        }
                                    >
                                        View Submissions
                                    </Button>
                                    <Button size="sm" className="ml-1" disabled>
                                        View PDF
                                    </Button>
                                    <Button
                                        size="sm"
                                        className="ml-1"
                                        variant="danger"
                                        onClick={deleteUser}
                                    >
                                        Delete User
                                    </Button>
                                </>
                            )}
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        );
    }
);
