import { Button, Modal } from "react-bootstrap";

export const ConfigureModalTemplate = ({
    show = false,
    size = "",
    handleConfirm,
    handleClose,
    body,
    confirmText,
    closeText = "Close",
    disabled = false,
    className = "",
}) => {
    return (
        <Modal
            show={show}
            onHide={handleClose}
            size={size}
            className={className}
        >
            <Modal.Header closeButton>
                <Modal.Title>Configure</Modal.Title>
            </Modal.Header>
            <Modal.Body>{body}</Modal.Body>
            <Modal.Footer>
                {confirmText?.length > 0 && (
                    <Button
                        variant="dark"
                        onClick={handleConfirm}
                        disabled={disabled}
                    >
                        {confirmText}
                    </Button>
                )}
                {closeText?.length > 0 && (
                    <Button variant="light" onClick={handleClose}>
                        {closeText}
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    );
};
