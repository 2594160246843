import { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";

import { UpdateSystemStatusModal } from "../../../components/admin/settings/UpdateSystemStatusModal";
import { TableRenderer } from "../../../components/portal/TableRenderer";
import { formatTableColumn } from "../../../helpers/other";
import { sendMessageQuery } from "../../../helpers/websocket";
import { useStore } from "../../../Store";
import { useSocket } from "../../../Websocket";

const StatusPage = () => {
    const ws = useSocket();
    const { state } = useStore();

    const { statuses } = state.settings;

    const [currentExchange, setCurrentExchange] = useState("");
    const [currentStatus, setCurrentStatus] = useState("");
    const possibleStatuses = ["Open", "Pre-Open", "Halt"];

    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const handleCloseUpdateModal = () => setShowUpdateModal(false);
    const handleOpenUpdateModal = (exchange, status) => {
        setCurrentExchange(exchange);
        setCurrentStatus(status);
        setShowUpdateModal(true);
    };

    const updateSystemStatus = (exchange, status, scheduletime) =>
        sendMessageQuery(ws, "setsystemstatus", {
            scheduletime,
            exchange,
            status,
        }).then(() => {
            const newStatus = possibleStatuses.find((sts) =>
                sts.startsWith(status)
            );
            toast.success(
                !scheduletime
                    ? `${exchange} successfully updated to ${newStatus}. `
                    : `${exchange} will update to ${newStatus} at ${new Date(
                          scheduletime
                      )}.`
            );
            handleCloseUpdateModal();
        });

    return (
        <div className="m-4">
            <Row noGutters className="forms-header">
                <h4>System Status</h4>
            </Row>
            <Row noGutters>
                <h5>Exchanges</h5>
            </Row>
            <Row noGutters>
                <UpdateSystemStatusModal
                    show={showUpdateModal}
                    handleCloseModal={handleCloseUpdateModal}
                    status={currentStatus}
                    exchange={currentExchange}
                    updateStatus={updateSystemStatus}
                />
                <Col>
                    <TableRenderer
                        keyField="exchange"
                        data={statuses}
                        columns={[
                            ...["exchange", "time", "status"].map((col) =>
                                formatTableColumn(col)
                            ),
                            {
                                dataField: "action",
                                text: "",
                                formatter: (action, { status, exchange }) => (
                                    <Button
                                        onClick={() =>
                                            handleOpenUpdateModal(
                                                exchange,
                                                status
                                            )
                                        }
                                    >
                                        Update Status
                                    </Button>
                                ),
                            },
                        ]}
                        noDataIndication="No exchanges available."
                        striped
                    />
                </Col>
            </Row>
        </div>
    );
};

export default StatusPage;
