/* eslint-disable react/display-name */
import { cloneDeep } from "lodash";
import moment from "moment/moment";
import { forwardRef } from "react";
import { Row } from "react-bootstrap";
import DatePicker from "react-datepicker";

import { clientDateFormat } from "../../config";

export const DateInput = (props) => {
    const { field, onChange, classes, disabled } = props;
    const { required, value } = field;

    const otherProps = cloneDeep(props);

    const CustomInput = forwardRef(({ value, onClick, disabled }, ref) => (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <input
            onChange={() => {}}
            onClick={onClick}
            ref={ref}
            value={value}
            className="form-control form-control-sm cursor-pointer"
            disabled={disabled}
            style={{ caretColor: "transparent" }}
        />
    ));

    return (
        <Row
            className={classes}
            noGutters
            style={{
                position: "relative",
            }}
        >
            <DatePicker
                {...otherProps}
                onChange={(date) => onChange(date.getTime())}
                dateFormat={clientDateFormat}
                className={"asset-date-input "}
                customInput={<CustomInput />}
                disabled={disabled}
                value={
                    value
                        ? moment(value).format(clientDateFormat.toUpperCase())
                        : undefined
                }
                dateFormatCalendar={"MMM yyyy"}
                showYearDropdown
                showMonthDropdown
                dropdownMode="select"
                wrapperClassName="w-100"
                minDate={moment().subtract(100, "years").toDate()}
                maxDate={moment().add(10, "years").toDate()}
                // className={`asset-date-input ${
                //     required &&
                //     (!value || value?.length === 0) &&
                //     "border border-danger"
                // }`}
            />
            {required && <span className="required-icon">*</span>}
        </Row>
    );
};
