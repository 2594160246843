/* eslint-disable react/display-name */
import { useState } from "react";
import { memo } from "react";
import { Button } from "react-bootstrap";

import { storeOptions } from "../helpers/display-options";
import { ConfigureStylingModal } from "./ConfigureStylingModal";

export const ConfigureStylingButton = memo(
    ({
        ws,
        dispatch,
        user,
        keys,
        type,
        optionKey,
        enableNewTextFormatting = false,
    }) => {
        const oldStyles = user?.attr?.[type]?.[optionKey]?.["styles"] || [];

        const [showModal, setShowModal] = useState(false);
        const handleOpenModal = () => setShowModal(true);
        const handleCloseModal = () => setShowModal(false);

        const saveStyling = (styles) => {
            const newStyles = oldStyles
                .filter((style) => style.key !== styles.key)
                .concat(styles);

            storeOptions(
                ws,
                dispatch,
                user,
                type,
                optionKey,
                newStyles,
                "styles"
            );

            return handleCloseModal();
        };

        return (
            <>
                {showModal && (
                    <ConfigureStylingModal
                        show={showModal}
                        handleCloseModal={handleCloseModal}
                        keys={keys}
                        saveStyling={saveStyling}
                        oldStyles={oldStyles}
                        enableNewTextFormatting={enableNewTextFormatting}
                    />
                )}
                <Button onClick={handleOpenModal} className="mx-1" size="sm">
                    Configure Styling
                </Button>
            </>
        );
    }
);
