export const QUERY_MULTIPLE_ORDERS = "QUERY_MULTIPLE_ORDERS";
export const QUERY_CANCELS = "QUERY_CANCELS";
export const QUERY_ACTIVITY_TRADE = "QUERY_ACTIVITY_TRADE";
export const QUERY_DEPOSIT = "QUERY_DEPOSIT";

export const GET_DEPOSIT_SYMBOLS = "GET_DEPOSIT_SYMBOLS";
export const UPDATE_SYMBOL_STATUS = "UPDATE_SYMBOL_STATUS";
export const UPDATE_PAIRS_WITH_NEW_SYMBOL = "UPDATE_PAIRS_WITH_NEW_SYMBOL";
export const REMOVE_PAIRS_SYMBOL = "REMOVE_PAIRS_SYMBOL";
export const APPEND_SYMBOL = "APPEND_SYMBOL";
export const EDIT_SYMBOL = "EDIT_SYMBOL";
export const DELETE_SYMBOL = "DELETE_SYMBOL";

export const GET_TRADE_FILE_DATA = "GET_TRADE_FILE_DATA";

export const CLEAR_STATE = "CLEAR_STATE";
