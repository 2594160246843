export const AUTH_REQUEST = "AUTH_REQUEST";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_ERROR = "AUTH_ERROR";

export const ADD_PUBLIC_KEY = "ADD_PUBLIC_KEY";
export const ADD_WS_DATA = "ADD_WS_DATA";
export const UPDATE_WS_2FA_DATA = "UPDATE_WS_2FA_DATA";

export const PARSE_ROLES = "PARSE_ROLES";

export const UPDATE_SYSTEM_PREFERENCES = "UPDATE_SYSTEM_PREFERENCES";

export const GET_ROLE_LIST = "GET_ROLE_LIST";

export const SET_FONT_URL = "SET_FONT_URL";

export const CLEAR_STATE = "CLEAR_STATE";
