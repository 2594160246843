/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/display-name */
import { memo, useState } from "react";
import { Button, Collapse, Row } from "react-bootstrap";

import { formatTimestamp, getUserFile } from "../../helpers/other";
import { useSocket } from "../../Websocket";

export const ActivityItem = memo(({ action }) => {
    const ws = useSocket();

    const [showData, setShowData] = useState(false);

    const renderActivityType = ({ action_type, action_data }) => {
        switch (action_type) {
            case "new_case":
                return `created this case.`;
            case "add_comment":
                return `added a new comment.`;
            case "add_alert":
                return `added a new alert.`;
            case "remove_alert":
                return `removed a alert.`;
            case "add_attachment":
                return `added a new attachment.`;
            case "remove_attachment":
                return `removed a attachment.`;
            case "status_change":
                return `changed the status to ${action_data}.`;
            case "severity_change":
                return `changed the severity to ${action_data}.`;
            case "email_applicant":
                return `sent an email to user.`;
            case "submit_form":
                return `submitted this form.`;
            case "disclosure_acceptance":
                return `accepted the Brassica disclosure agreement.`;
            case "brassica_person_created":
                return `created a new Brassica person and KYC profile.`;
            case "custody_account_application_submitted":
                return `submitted the custody account application for review.`;
            case "kyc_application_submitted":
                return `submitted the KYC application for review.`;
            case "kyc_application_manually_approved":
                return `manually approved the KYC application in the sandbox environment.`;
            case "custody_agreement_signed":
                return `signed the custody agreement.`;
            case "nominee_agreement_signed":
                return `signed the nominee agreement.`;
            default:
                return "";
        }
    };

    return (
        <div className="p-3 border mb-1">
            <Row noGutters className="mb-1">
                {formatTimestamp(action.date_created)}
            </Row>
            <Row noGutters>
                <u className="mr-1">{action.action_by}</u>
                {renderActivityType(action)}
            </Row>
            {action.action_data?.length > 0 &&
                !["severity_change", "status_change"].includes(
                    action.action_type
                ) && (
                    <>
                        <Button
                            size="sm"
                            className={`mt-2 w-100`}
                            onClick={() => setShowData(!showData)}
                        >
                            See{" "}
                            {action.action_type.includes("alert")
                                ? "Alert"
                                : action.action_type.includes("comment")
                                ? "Comment"
                                : action.action_type.includes("email")
                                ? "Email"
                                : "Attachment"}
                        </Button>
                        <Collapse in={showData}>
                            <div>
                                <div
                                    className={`pt-2 ${
                                        action.action_type === "add_attachment"
                                            ? "cursor-pointer text-primary"
                                            : ""
                                    }`}
                                    onClick={
                                        action.action_type === "add_attachment"
                                            ? () =>
                                                  getUserFile(
                                                      ws,
                                                      action.action_data
                                                  )
                                            : () => {}
                                    }
                                >
                                    {action.action_data}
                                </div>
                            </div>
                        </Collapse>
                    </>
                )}
        </div>
    );
});
