/* eslint-disable react/display-name */

import { memo, useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { formatTableColumn } from "../../../../helpers/other";
import { TableRenderer } from "../../../portal/TableRenderer";

export const SecondaryMarket = memo(
    ({ tradeSymbols, pairs, handleOpenDeleteModal }) => {
        const [pairSymbols, setPairSymbols] = useState([]);
        const navigate = useNavigate();

        const findPairSymbols = (pairSymbols) => {
            let pairSymbolData = [];

            pairSymbols.forEach((pair) =>
                pairSymbolData.push(
                    tradeSymbols.find((elem) => elem.security === pair)
                )
            );

            setPairSymbols(pairSymbolData);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
        useEffect(() => findPairSymbols(pairs), [pairs]);

        return (
            <Col>
                {pairSymbols && (
                    <TableRenderer
                        keyField="security"
                        data={pairSymbols}
                        columns={[
                            ...[
                                "security",
                                "attr.name",
                                "tradingsymbol",
                                "deposit",
                            ].map((col) => formatTableColumn(col)),
                            {
                                dataField: "action",
                                text: "Action",
                                isDummyField: true,
                                formatter: (rowContent, row) => (
                                    <Row noGutters>
                                        <Button
                                            size="sm"
                                            onClick={() =>
                                                navigate(
                                                    `/admin/symbols/${row.security}`
                                                )
                                            }
                                        >
                                            Info
                                        </Button>
                                        <Button
                                            size="sm"
                                            className="mx-1"
                                            onClick={() =>
                                                navigate(
                                                    `/admin/symbols/${row.security}/edit`
                                                )
                                            }
                                        >
                                            Edit
                                        </Button>
                                        <Button
                                            size="sm"
                                            variant="danger"
                                            onClick={() =>
                                                handleOpenDeleteModal(
                                                    row.security
                                                )
                                            }
                                        >
                                            Delete
                                        </Button>
                                    </Row>
                                ),
                            },
                        ]}
                        noDataIndication="No Symbols Available"
                        pagination={true}
                        striped={true}
                    />
                )}
            </Col>
        );
    }
);
