import { memo } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Button, Col, Form, Row } from "react-bootstrap";
import { AiOutlineQuestionCircle } from "react-icons/ai";

// eslint-disable-next-line react/display-name
export const CreateRoleForm = memo(({ role, setRole, submitData, forms }) => {
    const handleOnDragEnd = (result) => {
        if (!result.destination) return;

        const formsAssigned = Array.from(role.forms);

        const [reorderedItem] = (
            result.source.droppableId.includes("available-forms")
                ? forms
                : formsAssigned
        ).splice(result.source.index, 1);

        (result.destination.droppableId.includes("available-forms")
            ? forms
            : formsAssigned
        ).splice(result.destination.index, 0, reorderedItem);

        setRole({ ...role, forms: formsAssigned });
    };

    return (
        <>
            <Row noGutters className="mb-2 size-sm">
                <Col sm={3}>Role Name:</Col>
                <Col sm={5}>
                    <Form.Control
                        size="sm"
                        name="name"
                        onChange={({ target: { value } }) =>
                            setRole({ ...role, name: value })
                        }
                        value={role.name}
                    />
                </Col>
                <Col className="ml-2">
                    <AiOutlineQuestionCircle /> Your users will see this name
                    when choosing a role during registration
                </Col>
            </Row>
            <Row noGutters className="size-sm">
                <Col sm={3}>Role Description:</Col>
                <Col sm={5}>
                    <Form.Control
                        size="sm"
                        as="textarea"
                        name="description"
                        onChange={({ target: { value } }) =>
                            setRole({ ...role, description: value })
                        }
                        value={role.description}
                    />
                </Col>
                <Col className="ml-2">
                    <AiOutlineQuestionCircle /> Please create a detailed
                    description to help your users understand if this role
                    applies to them
                </Col>
            </Row>
            <br />
            <Row>
                <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Col xs={6}>
                        <div
                            style={{
                                padding: "15px",
                                border: "1px solid",
                                backgroundColor: "#ededed",
                                borderRadius: "8px",
                            }}
                        >
                            <h5 className="pb-2">
                                Forms assigned to this role:
                            </h5>
                            <Droppable droppableId="forms-assigned">
                                {(provided) => (
                                    <ul
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        style={{
                                            listStyle: "none",
                                            padding: "0",
                                            overflowY: "none",
                                        }}
                                    >
                                        {role.forms.map((form, i) => (
                                            <Draggable
                                                key={i}
                                                index={i}
                                                draggableId={`forms-assigned-${i}`}
                                            >
                                                {(provided) => (
                                                    <li
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <div className="roles-form-option">
                                                            {form.name}
                                                        </div>
                                                    </li>
                                                )}
                                            </Draggable>
                                        ))}
                                    </ul>
                                )}
                            </Droppable>
                        </div>
                    </Col>
                    <Col xs={6}>
                        <div
                            style={{
                                padding: "15px",
                                border: "1px solid",
                                backgroundColor: "#ededed",
                                borderRadius: "8px",
                            }}
                        >
                            <h5 className="pb-2">Available forms:</h5>
                            <Droppable droppableId="available-forms">
                                {(provided) => (
                                    <ul
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        style={{
                                            listStyle: "none",
                                            padding: "0",
                                            overflowY: "none",
                                        }}
                                    >
                                        {forms.map((form, i) => (
                                            <Draggable
                                                key={i}
                                                index={i}
                                                draggableId={`available-forms-${i}`}
                                            >
                                                {(provided) => (
                                                    <li
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <div className="roles-form-option">
                                                            {form.name}
                                                        </div>
                                                    </li>
                                                )}
                                            </Draggable>
                                        ))}
                                    </ul>
                                )}
                            </Droppable>
                        </div>
                    </Col>
                </DragDropContext>
            </Row>
            <Row noGutters className=" mt-4">
                <Col className="d-flex justify-content-center w-100">
                    <Button
                        className="px-4 mb-4 mr-1"
                        onClick={() => {
                            // setRole({
                            //     ...role,
                            //     isPublished: !role?.isPublished,
                            // });
                            submitData({
                                ...role,
                                isPublished: !role?.isPublished,
                            });
                        }}
                        disabled={role.forms.length === 0}
                    >
                        {role?.isPublished ? "Unpublish" : "Publish"} & Save
                    </Button>
                    <Button
                        className="px-4 mb-4"
                        onClick={() => submitData(role)}
                    >
                        Save
                    </Button>
                </Col>
            </Row>
        </>
    );
});
