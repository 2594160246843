import { debounce } from "lodash";
import { memo, useCallback, useEffect, useState } from "react";
import { Col, Form, FormControl, Modal, Row } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";

import { formatTableColumn } from "../../../helpers/other";
import { getQueryAlerts } from "../../../helpers/risk";
import { useStore } from "../../../Store";
import { useSocket } from "../../../Websocket";
import { Loading } from "../../Loading";
import { TableRenderer } from "../../portal/TableRenderer";
import { alertTypes } from "./alertTypes";

// eslint-disable-next-line react/display-name
export const AddAlertToCaseModal = memo(
    ({ show, handleClose, traders, caseAlerts, addAlert, removeAlert }) => {
        const ws = useSocket();
        const { state } = useStore();
        const [loading, setLoading] = useState(false);

        const [alertType, setAlertType] = useState(undefined);
        const [account, setAccount] = useState([]);

        const [alerts, setAlerts] = useState([]);
        const [filteredAlerts, setFilteredAlerts] = useState([]);
        const {
            risk: { cases },
        } = state;

        const [caseAlertsUsed, setCaseAlertsUsed] = useState([]);

        useEffect(() => {
            const allAlertIdsUsed = cases
                .map((riskCase) => riskCase.details.alert_ids)
                .flat()
                .filter((alt) => alt);

            const alertIdsUsedInThisCase = caseAlerts.map(
                (riskCase) => riskCase.eventid
            );

            setCaseAlertsUsed(
                allAlertIdsUsed.filter(
                    (alt) => !alertIdsUsedInThisCase.includes(alt) && alt
                )
            );
        }, [cases, caseAlerts]);

        // eslint-disable-next-line react-hooks/exhaustive-deps
        const debouncedOnChangeFirm = useCallback(
            debounce(
                ({ target: { value } }) =>
                    setFilteredAlerts(
                        alerts.filter((alt) =>
                            alt.firm.toLowerCase().includes(value.toLowerCase())
                        )
                    ),
                500
            ),
            [account, alertType, alerts]
        );

        useEffect(
            () =>
                setFilteredAlerts(
                    alerts.filter(
                        (alrt) => !caseAlertsUsed.includes(alrt.eventid)
                    )
                ),
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [alerts]
        );

        useEffect(() => {
            setLoading(true);

            getQueryAlerts(ws, {
                subtype: alertType,
                account: account.length > 0 ? account[0]?.userid : undefined,
            }).then((res) => {
                setAlerts(
                    res
                        .filter((alt) => alt?.errordetails && alt)
                        .sort((a, b) => a.time < b.time)
                );
                setLoading(false);
            });

            return () => setLoading(true);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [alertType, account]);

        return (
            <Modal size="lg" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Alert</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row noGutters className="mb-1">
                        <Col xs={3} className="mt-1">
                            Alert Type:
                        </Col>
                        <Col xs={5}>
                            <FormControl
                                as="select"
                                size="sm"
                                className=""
                                onChange={({ target: { value } }) =>
                                    setAlertType(value)
                                }
                                value={alertType}
                            >
                                <option value={""}>Select a option...</option>
                                {alertTypes.map(({ value, label }, i) => (
                                    <option key={i} value={value}>
                                        {label}
                                    </option>
                                ))}
                            </FormControl>
                        </Col>
                    </Row>
                    <Row noGutters className="mb-1">
                        <Col xs={3} className="mt-1">
                            Firm:
                        </Col>
                        <Col xs={5}>
                            <FormControl
                                size="sm"
                                onChange={debouncedOnChangeFirm}
                            />
                        </Col>
                    </Row>
                    <Row noGutters className="mb-1">
                        <Col xs={3} className="mt-1">
                            Account:
                        </Col>
                        <Col xs={5}>
                            <Typeahead
                                id="user"
                                labelKey="userid"
                                onChange={setAccount}
                                options={traders}
                                defaultSelected={account}
                                size="sm"
                            />
                        </Col>
                    </Row>
                    <br />
                    <Row noGutters>
                        {loading ? (
                            <Col xs={12}>
                                <Loading />
                            </Col>
                        ) : (
                            <TableRenderer
                                wrapperClasses="w-100"
                                keyField="eventid"
                                data={filteredAlerts}
                                columns={[
                                    ...["account", "time", "subtype"].map(
                                        (col) => formatTableColumn(col)
                                    ),
                                    {
                                        dataField: "add",
                                        text: "Add",
                                        isDummyField: true,
                                        formatter: (col, row) => {
                                            const isChecked = caseAlerts.find(
                                                (alrt) =>
                                                    alrt.eventid === row.eventid
                                            );

                                            return (
                                                <span className="text-center">
                                                    <Form.Check
                                                        type="checkbox"
                                                        defaultChecked={
                                                            isChecked
                                                        }
                                                        onChange={({
                                                            target: { checked },
                                                        }) => {
                                                            checked
                                                                ? addAlert(row)
                                                                : removeAlert(
                                                                      row
                                                                  );
                                                        }}
                                                    />
                                                </span>
                                            );
                                        },
                                    },
                                ]}
                                sortBy="time"
                                sortOrder="desc"
                                noDataIndication="No alerts found."
                                pagination
                            />
                        )}
                    </Row>
                </Modal.Body>
            </Modal>
        );
    }
);
