import { Row } from "react-bootstrap";

import { OrderTicket } from "../../../components/admin/settings/preferences/OrderTicket";
import { storeNewPreferences } from "../../../helpers/settings";
import { useStore } from "../../../Store";
import { useSocket } from "../../../Websocket";

const Preferences = () => {
    const { state, dispatch } = useStore();
    const ws = useSocket();

    const preferenceComponents = state.account.account.data.config_setting.attr;

    const submitUpdate = (config, key) =>
        storeNewPreferences(ws, dispatch, config, key, preferenceComponents);

    return (
        <div className="m-4">
            <Row noGutters className="forms-header">
                <h4>Preferences</h4>
            </Row>
            <OrderTicket
                submitUpdate={submitUpdate}
                comp={preferenceComponents["order_ticket"]}
            />
        </div>
    );
};

export default Preferences;
