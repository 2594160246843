/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/display-name */
import { useState } from "react";
import { memo } from "react";
import {
    Accordion,
    Button,
    Card,
    Col,
    Collapse,
    FormCheck,
    FormControl,
    Row,
} from "react-bootstrap";

import { formatResultString } from "../../../helpers/other";

// const renderAdditionalField = (field, i) => {
//     return (
//         <Fragment key={i}>
//             <Row noGutters className="mx-4 px-4">
//                 <Col>
//                     <u>{field?.fieldName}</u>
//                 </Col>
//             </Row>
//             {field?.fieldValue && (
//                 <Row className="mx-4 px-4">
//                     <Col>Field Value</Col>
//                     <Col>{`${field?.fieldValue || "N/A"}`}</Col>
//                 </Row>
//             )}
//             <Row className="mx-4 px-4">
//                 <Col>Field Type</Col>
//                 <Col>{field?.fieldType}</Col>
//             </Row>
//             {field?.fieldSync && (
//                 <Row className="mx-4 px-4">
//                     <Col>Field Sync</Col>
//                     <Col>{field?.fieldSync}</Col>
//                 </Row>
//             )}
//         </Fragment>
//     );
// };

const renderTypeField = (field, fieldValue, i, toggleSetting) => {
    const isFieldObject =
        Array.isArray(fieldValue) &&
        fieldValue.some((val) => typeof val === "object");

    return !Array.isArray(fieldValue) && typeof fieldValue === "object" ? (
        <div key={i} className="mx-2">
            <Row className="mx-3">
                <u>{field}</u>
            </Row>
            {Object.keys(fieldValue).map((objKey, i) => (
                <Row key={i} className="mx-4" noGutters>
                    <Col>{formatResultString(objKey)}</Col>
                    <Col>
                        {objKey === "display" ? (
                            <FormCheck
                                type="checkbox"
                                checked={fieldValue?.[objKey]}
                                onChange={({ target: { checked } }) =>
                                    toggleSetting(field, {
                                        ...fieldValue,
                                        display: checked,
                                    })
                                }
                            />
                        ) : objKey === "modalFields" ? (
                            fieldValue[objKey]
                                .map((fld) => fld?.displayName || fld.fieldName)
                                .join(", ")
                        ) : (
                            `${fieldValue[objKey]}`
                        )}
                    </Col>
                </Row>
            ))}
        </div>
    ) : !isFieldObject ? (
        <Row className="mx-2" key={i}>
            <Col>
                <u>{field}</u>
            </Col>
            <Col>
                {Array.isArray(fieldValue)
                    ? `${fieldValue.join()}`
                    : fieldValue}
            </Col>
        </Row>
    ) : (
        <div key={i}>
            <Row className="mx-4">
                <u>{field}</u>
            </Row>
            <div className="mx-4">
                <Row className="mx-2" noGutters>
                    <Col className="mt-1">Default</Col>
                    <Col>
                        <FormControl
                            size="sm"
                            as="select"
                            onChange={({ target: { value } }) =>
                                toggleSetting(
                                    field,
                                    fieldValue.map((opt, j) => ({
                                        ...opt,
                                        default:
                                            parseInt(value) === j &&
                                            !opt?.default,
                                    }))
                                )
                            }
                            defaultValue={fieldValue.findIndex(
                                (opt) => opt.default === true
                            )}
                        >
                            {fieldValue.map((fld, i) => (
                                <option
                                    key={i}
                                    value={i}
                                    disabled={fld?.isHidden}
                                >
                                    {fld?.displayName}
                                </option>
                            ))}
                        </FormControl>
                    </Col>
                </Row>
                <Row noGutters className="mx-2 mt-1">
                    <Col>Show:</Col>
                    <Col className="d-flex">
                        {fieldValue.map((fld, i) => (
                            <div key={i} className="mr-3 d-flex">
                                <FormCheck
                                    type="checkbox"
                                    onChange={({ target: { checked } }) =>
                                        toggleSetting(
                                            field,
                                            fieldValue.map((opt, j) => ({
                                                ...opt,
                                                isHidden:
                                                    i === j
                                                        ? !checked
                                                        : opt?.isHidden,
                                            }))
                                        )
                                    }
                                    checked={!fld?.isHidden}
                                />
                                {fld?.displayName}
                            </div>
                        ))}
                    </Col>
                </Row>
            </div>
            {/* {fieldValue.map((fld, i) => (
                <div key={i} className="mx-2">
                    <Row className="mx-4 font-weight-bold border-bottom">
                        Option #{i + 1} : {fld.displayName || fld.dispalyName}
                    </Row>
                    <Row className="mx-4">
                        <Col>Display Name</Col>
                        <Col>{fld.displayName || fld.dispalyName}</Col>
                    </Row>
                    <Row className="mx-4">
                        <Col>Default</Col>
                        <Col>
                            <FormCheck />
                        </Col>
                    </Row>
                    {fld?.additionalFields && (
                        <>
                            <Row className="mx-4 px-3">Additional Fields</Row>
                            {fld?.additionalFields.map((additFld, i) =>
                                renderAdditionalField(additFld, i)
                            )}
                        </>
                    )}
                </div>
            ))} */}
        </div>
    );
};

export const PreferenceComponent = memo(
    ({ comp, compKey, toggleSetting, submitUpdate }) => {
        const [displayProps, setDisplayProps] = useState({});

        return (
            <>
                <Row className="d-flex justify-content-between" noGutters>
                    <h5>{formatResultString(compKey)}</h5>
                    <Button onClick={submitUpdate} className="mb-2 px-4">
                        Save
                    </Button>
                </Row>
                <Accordion defaultActiveKey="0">
                    {Object.keys(comp).map((sect, i) => {
                        const fields = Object.keys(comp[sect]).filter(
                            (keys) => keys !== "display"
                        );

                        return (
                            <Card key={i}>
                                <Card.Header
                                    className="border-bottom"
                                    onClick={() =>
                                        setDisplayProps({
                                            ...displayProps,
                                            [sect]: {
                                                ...displayProps[sect],
                                                showSection:
                                                    !displayProps[sect]
                                                        ?.showSection,
                                            },
                                        })
                                    }
                                >
                                    {sect}
                                </Card.Header>
                                <Collapse
                                    in={
                                        displayProps?.[sect]?.showSection ||
                                        false
                                    }
                                >
                                    <Card.Body className="my-0 py-0">
                                        <br />

                                        <div className="mx-2 d-flex justify-content-between">
                                            {/* Whether to hide the section or not  */}
                                            <Col>Display</Col>
                                            <Col>
                                                <FormCheck
                                                    type="checkbox"
                                                    checked={comp[sect].display}
                                                    onChange={({
                                                        target: { checked },
                                                    }) =>
                                                        toggleSetting(
                                                            sect,
                                                            "display",
                                                            checked
                                                        )
                                                    }
                                                />
                                            </Col>
                                        </div>
                                        {fields.length > 0 && (
                                            <>
                                                {/* The rest of the keys in sect are our fields and their options. */}
                                                <div
                                                    className="cursor-pointer mx-4 font-weight-bold border-bottom "
                                                    onClick={() =>
                                                        setDisplayProps({
                                                            ...displayProps,
                                                            [sect]: {
                                                                ...displayProps[
                                                                    sect
                                                                ],
                                                                showFields:
                                                                    !displayProps?.[
                                                                        sect
                                                                    ]
                                                                        ?.showFields,
                                                            },
                                                        })
                                                    }
                                                >
                                                    Fields
                                                </div>
                                                {/* <Collapse
                                                    in={
                                                        displayProps?.[sect]
                                                            ?.showFields ||
                                                        false
                                                    }
                                                > */}
                                                <div className="mx-2">
                                                    {fields.map((field, i) => {
                                                        const fieldValue =
                                                            comp[sect][field];

                                                        return renderTypeField(
                                                            field,
                                                            fieldValue,
                                                            i,
                                                            (key, value) =>
                                                                toggleSetting(
                                                                    sect,
                                                                    key,
                                                                    value
                                                                )
                                                        );
                                                    })}
                                                </div>
                                                {/* </Collapse> */}
                                            </>
                                        )}
                                        <br />
                                    </Card.Body>
                                </Collapse>
                            </Card>
                        );
                    })}
                </Accordion>
            </>
        );
    }
);
