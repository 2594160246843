import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { verifyEmail } from "../helpers/register";
import { useStore } from "../Store";

const VerifyEmailPage = () => {
    const { dispatch } = useStore();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        for (const entry of searchParams.entries()) {
            const [param, value] = entry;

            if (param === "token") {
                verifyEmail(value, navigate, dispatch);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams]);

    return <></>;
};

export default VerifyEmailPage;
