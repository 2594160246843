/* eslint-disable react/display-name */

import { memo, useState } from "react";

import { Loading } from "../../../Loading";
import { OrderDetailModal } from "../../../portal/OrderDetailModal";
import { TableRenderer } from "../../../TableRenderer";

export const Orders = memo(({ orders, loading = false }) => {
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [showOrderDetailModal, setShowOrderDetailModal] = useState(false);
    const handleOpenOrderDetailModal = () => setShowOrderDetailModal(true);
    const handleCloseOrderDetailModal = () => setShowOrderDetailModal(false);

    const refnoColumn = [
        {
            dataField: "refno",
            text: "Ref no.",
            sort: true,
            formatter: (rowContent, row) => {
                return (
                    <span
                        aria-hidden="true"
                        style={{
                            paddingBottom: "2px",
                            borderBottom: "1px solid",
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            handleOpenOrderDetailModal();
                            setSelectedOrder(row);
                        }}
                    >
                        {row.refno}
                    </span>
                );
            },
        },
    ];

    return loading ? (
        <Loading />
    ) : (
        <>
            <TableRenderer
                keyField="refno"
                keyType="market_preferences"
                optionKey="orders"
                defaultOptions={[
                    "userid",
                    "security",
                    "qty",
                    "price",
                    "side",
                    "account",
                ]}
                interactColumn={refnoColumn}
                blacklistedAttr={["refno"]}
                data={orders}
                noDataIndication="No orders available."
                pagination
                striped
                wrapperClasses="size-sm"
                exportCSVName="orders"
            />
            <OrderDetailModal
                show={showOrderDetailModal}
                handleClose={handleCloseOrderDetailModal}
                selectedOrder={selectedOrder}
            />
        </>
    );
});
