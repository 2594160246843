import { memo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { AiOutlineCopy, AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

import { TableRenderer } from "../../../components/portal/TableRenderer";
import { DeleteTemplateModal } from "./DeleteTemplateModal";

// eslint-disable-next-line react/display-name
export const AssetTemplateTable = memo(
    ({ deleteAssetTemplate, templates, loading }) => {
        const navigate = useNavigate();
        const [selectedTemplate, setSelectedTemplate] = useState();

        const [showDeleteModal, setShowDeleteModal] = useState(false);
        const handleShowDeleteModal = (temp) => {
            setSelectedTemplate(temp);
            setShowDeleteModal(true);
        };
        const handleHideDeleteModal = () => setShowDeleteModal(false);

        const columns = [
            {
                dataField: "name",
                text: "Asset Type",
                sort: true,
            },
            { dataField: "id", text: "ID" },
            {
                dataField: "isPublished",
                text: "Published Status",
                formatter: (rowContent, row) => {
                    return (
                        <Row noGutters>
                            <Col
                                className={
                                    row.isPublished
                                        ? "text-success"
                                        : "text-danger"
                                }
                            >
                                {row.isPublished
                                    ? "Published"
                                    : "Not Published"}
                            </Col>
                            <Col className="text-right">
                                <AiOutlineEdit
                                    onClick={() =>
                                        navigate(
                                            `/admin/asset-templates/${row?.id}/edit`
                                        )
                                    }
                                    className="mr-2 cursor-pointer"
                                />
                                <AiOutlineCopy
                                    onClick={() =>
                                        navigate(
                                            `/admin/asset-templates/create?copy=${row?.id}`
                                        )
                                    }
                                    className="mr-2 cursor-pointer"
                                />
                                <AiOutlineDelete
                                    onClick={() =>
                                        !row.isPublished &&
                                        handleShowDeleteModal(row)
                                    }
                                    className={
                                        !row.isPublished
                                            ? "cursor-pointer"
                                            : "text-gray"
                                    }
                                />
                            </Col>
                        </Row>
                    );
                },
            },
        ];

        return (
            <>
                {showDeleteModal && (
                    <DeleteTemplateModal
                        show={showDeleteModal}
                        handleDelete={() =>
                            deleteAssetTemplate(selectedTemplate).then(() =>
                                handleHideDeleteModal()
                            )
                        }
                        handleClose={handleHideDeleteModal}
                        template={selectedTemplate}
                    />
                )}
                <TableRenderer
                    keyField="name"
                    data={templates || []}
                    columns={columns}
                    noDataIndication="No Asset Templates Available."
                    loading={loading}
                    pagination
                    striped
                    wrapperClasses="size-sm"
                />
            </>
        );
    }
);
