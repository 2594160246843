/* eslint-disable no-unused-vars */
import { useEffect, useMemo, useRef, useState } from "react";
import { Button, Col, FormControl, Modal, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";

import { clientDateFormat, clientTimeFormat } from "../../config";
import {
    formatTimestamp,
    getDateTimeHelper,
    getZeroes,
} from "../../helpers/other";
import { CustomTimeInput } from "./trade/order-ticket/CustomTimeInput";

export const OrderDetailModal = ({
    show = false,
    handleEditOrder,
    handleClose,
    selectedOrder,
    isEdit = false,
    symbol,
}) => {
    const [qty, setQty] = useState("");
    const [price, setPrice] = useState("");
    const [expTime, setExpTime] = useState(0);
    const [qaprate, setQaprate] = useState("");
    const [displayqty, setDisplayQty] = useState("");
    const [minquoteqty, setMinQuoteQty] = useState("");
    const [effectiveTime, setEffectiveTime] = useState(null);

    const qtyRef = useRef();

    const { zeroCount: qtyZeroes } = getZeroes("Amount", symbol);
    const { zeroCount: priceZeroes } = getZeroes("Price", symbol);

    const typeToSetStateMapper = useMemo(() => {
        const getValueToUpdate = (prev, zeroes, direction) => {
            const valueToIncrease =
                zeroes === 0
                    ? 1 * direction
                    : Number(1 / Math.pow(10, zeroes)) * direction;
            const final = parseFloat(prev) + valueToIncrease;
            return final.toFixed(zeroes);
        };
        return {
            qty: (direction) =>
                setQty((prev) => getValueToUpdate(prev, qtyZeroes, direction)),
            price: (direction) =>
                setPrice((prev) =>
                    getValueToUpdate(prev, priceZeroes, direction)
                ),
            displayqty: (direction) =>
                setDisplayQty((prev) =>
                    getValueToUpdate(prev, qtyZeroes, direction)
                ),
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [symbol]);

    useEffect(() => {
        if (selectedOrder) {
            if (selectedOrder.type === "sale") {
                setQty(selectedOrder?.execqty);
                setPrice(selectedOrder?.execprice);
            } else {
                setQty(selectedOrder?.qty);
                setPrice(selectedOrder?.price);
                setExpTime(
                    selectedOrder?.exptime
                        ? parseInt(selectedOrder?.exptime)
                        : 0
                );
                selectedOrder.type !== "order" &&
                    !selectedOrder.negotiate &&
                    qtyRef.current.focus();
            }
            setQaprate(selectedOrder?.qaprate);
            setDisplayQty(selectedOrder?.displayqty);
            setMinQuoteQty(selectedOrder?.minquoteqty);
            setEffectiveTime(selectedOrder?.effective_time);
        }
    }, [selectedOrder]);

    const onKeyDown = (ev, type) => {
        let direction = 0;
        if (ev.key === "ArrowUp") {
            ev.preventDefault();
            direction = 1;
        } else if (ev.key === "ArrowDown") {
            ev.preventDefault();
            direction = -1;
        }
        if (direction) typeToSetStateMapper[type](direction);
    };

    const renameCapacity = (capVal) => {
        switch (capVal) {
            case "P":
                return "Principal";
            case "A":
                return "Agency";
            case "R":
                return "Riskless";
            default:
                return "";
        }
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title className="pl-2 my-0" style={{ fontSize: "18px" }}>
                    Order Details
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="order-detail-modal size-sm">
                <Row noGutters>
                    <Col>Order Reference Number</Col>
                    <Col className="text-right">{selectedOrder?.refno}</Col>
                </Row>
                <Row noGutters>
                    <Col>Symbol</Col>
                    <Col className="text-right">{selectedOrder?.security}</Col>
                </Row>
                <Row noGutters>
                    <Col>Side</Col>
                    <Col
                        className={`font-weight-bold text-right ${
                            selectedOrder?.side === "S"
                                ? "text-danger"
                                : "text-success"
                        }`}
                    >
                        {selectedOrder?.side === "S" ? "Offer" : "Bid"}
                    </Col>
                </Row>
                <Row noGutters>
                    <Col className="mt-1">Quantity</Col>
                    <Col>
                        <FormControl
                            ref={qtyRef}
                            type="number"
                            value={qty || ""}
                            onKeyDown={(e) => onKeyDown(e, "qty")}
                            onChange={(e) => setQty(e.target.value)}
                            size="sm"
                            disabled={!isEdit}
                            className="text-right"
                        />
                    </Col>
                </Row>
                {selectedOrder?.type === "order" && (
                    <Row noGutters>
                        <Col>Live Quantity (Unmatched qty)</Col>
                        <Col className="text-right">
                            {selectedOrder?.liveqty}
                        </Col>
                    </Row>
                )}
                {selectedOrder?.displayqty && (
                    <Row noGutters>
                        <Col className="mt-1">Display Quantity</Col>
                        <Col>
                            <FormControl
                                type="number"
                                value={displayqty}
                                onKeyDown={(e) => onKeyDown(e, "displayqty")}
                                onChange={(e) => setDisplayQty(e.target.value)}
                                size="sm"
                                disabled={!isEdit}
                                className="text-right"
                            />
                        </Col>
                    </Row>
                )}
                {selectedOrder?.minquoteqty && (
                    <Row noGutters>
                        <Col className="mt-1">Minimum Quote Quantity</Col>
                        <Col>
                            <FormControl
                                type="number"
                                value={minquoteqty}
                                onChange={(e) => setMinQuoteQty(e.target.value)}
                                size="sm"
                                disabled={!isEdit}
                                className="text-right"
                            />
                        </Col>
                    </Row>
                )}
                <Row noGutters>
                    <Col className="mt-1">Price</Col>
                    <Col>
                        <FormControl
                            type="number"
                            value={price || ""}
                            onKeyDown={(e) => onKeyDown(e, "price")}
                            onChange={(e) => setPrice(e.target.value)}
                            size="sm"
                            disabled={!isEdit}
                            className="text-right"
                        />
                    </Col>
                </Row>
                {selectedOrder?.tif && (
                    <Row noGutters>
                        <Col>TIF</Col>
                        <Col className="text-right">{selectedOrder?.tif}</Col>
                    </Row>
                )}
                {selectedOrder?.tif && (
                    <Row noGutters>
                        <Col>exptime</Col>
                        <Col className="text-right">
                            <DatePicker
                                className="custom-time-input"
                                showTimeInput
                                selected={expTime}
                                onChange={(date) => setExpTime(date.getTime())}
                                dateFormat={`${clientDateFormat} ${clientTimeFormat}`}
                            />
                        </Col>
                    </Row>
                )}
                <Row noGutters>
                    <Col>Type</Col>
                    <Col className="text-right">
                        {selectedOrder?.negotiate
                            ? "Negotiate"
                            : selectedOrder?.ioi
                            ? "IOI"
                            : selectedOrder?.ordertype || selectedOrder?.type}
                    </Col>
                </Row>
                <Row noGutters>
                    <Col>Status</Col>
                    <Col className="text-right">
                        {selectedOrder?.orderstatus || "Executed"}
                    </Col>
                </Row>
                <Row noGutters>
                    <Col>Firm</Col>
                    <Col className="text-right">{selectedOrder?.firm}</Col>
                </Row>
                <Row noGutters>
                    <Col>Category</Col>
                    <Col className="text-right">{selectedOrder?.category}</Col>
                </Row>
                {selectedOrder?.capacity && (
                    <Row noGutters>
                        <Col>Capacity</Col>
                        <Col className="text-right">
                            {renameCapacity(selectedOrder?.capacity)}
                        </Col>
                    </Row>
                )}
                <Row noGutters>
                    <Col className="mt-1">qaprate</Col>
                    <Col>
                        <FormControl
                            type="number"
                            value={qaprate || ""}
                            onChange={(e) => setQaprate(e.target.value)}
                            size="sm"
                            disabled={!isEdit}
                            className="text-right"
                        />
                    </Col>
                </Row>
                <Row noGutters>
                    <Col>Account</Col>
                    <Col className="text-right">{selectedOrder?.account}</Col>
                </Row>
                {selectedOrder?.clientorderid && (
                    <Row noGutters>
                        <Col>Client Order ID</Col>
                        <Col className="text-right">
                            {selectedOrder?.clientorderid}
                        </Col>
                    </Row>
                )}
                {selectedOrder?.enttime && (
                    <Row noGutters>
                        <Col>Entry Date</Col>
                        <Col className="text-right">
                            {formatTimestamp(selectedOrder.enttime)}
                        </Col>
                    </Row>
                )}
                {selectedOrder?.trdtime && (
                    <Row noGutters>
                        <Col>Trade Date</Col>
                        <Col className="text-right">
                            {formatTimestamp(selectedOrder.trdtime)}
                        </Col>
                    </Row>
                )}
                {selectedOrder?.effective_time && (
                    <Row noGutters>
                        <Col>Effective Date</Col>
                        <Col>
                            <DatePicker
                                className="custom-time-input text-right"
                                showTimeInput
                                selected={
                                    effectiveTime
                                        ? new Date(effectiveTime)
                                        : null
                                }
                                disabled={
                                    selectedOrder?.orderstatus !== "Pending"
                                }
                                onChange={(date) =>
                                    setEffectiveTime(new Date(date).getTime())
                                }
                                dateFormat={`${clientDateFormat} ${clientTimeFormat}`}
                            />
                        </Col>
                    </Row>
                )}
            </Modal.Body>
            <Modal.Footer>
                {isEdit && (
                    <Button
                        size="sm"
                        onClick={() => {
                            // When it is spread like this orderToUpdate will be equal to selectedOrder
                            // Where keys shown before the spread variable (orderToUpdate) is removed
                            const {
                                account,
                                category,
                                enttime,
                                execamount,
                                execqty,
                                liveqty,
                                orderstatus,
                                rootref,
                                updtime,
                                type,
                                userid,
                                ...orderToUpdate
                            } = selectedOrder;
                            handleEditOrder({
                                ...orderToUpdate,
                                qty: parseFloat(qty),
                                price: parseFloat(price),
                                qaprate: parseInt(qaprate),
                                displayqty: parseFloat(displayqty),
                                minquoteqty: parseInt(minquoteqty),
                                effective_time: parseInt(effectiveTime),
                                exptime: expTime
                                    ? parseInt(expTime)
                                    : undefined,
                            });
                        }}
                    >
                        Modify
                    </Button>
                )}
                <Button size="sm" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
