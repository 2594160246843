import { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { v4 as uuid } from "uuid";

import { CreateProfileForm } from "../../../components/admin/asset-builder/CreateProfileForm";
import { GoBackModal } from "../../../components/admin/asset-builder/GoBackModal";
import { FullScreenLoading } from "../../../components/Loading";
import {
    getAssetTemplatesProfiles,
    remapImageFields,
    setSymbolProfileExists,
    updateAssetTemplateProfile,
    uploadImageForProfile,
} from "../../../helpers/asset-builder";
import { submitEditSymbol } from "../../../helpers/market";
import { useStore } from "../../../Store";
import { useSocket } from "../../../Websocket";

const CreateProfilePage = () => {
    const ws = useSocket();
    const { state, dispatch } = useStore();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    const [template, setTemplate] = useState();
    const [copiedProfile, setCopiedProfile] = useState();

    const {
        asset_builder: { templates, profiles },
        market: { symbols },
        trade: { symbols: tradeSymbols },
    } = state;

    const createProfile = (newProfile, image) => {
        const identifier = uuid();

        // eslint-disable-next-line no-undef
        return uploadImageForProfile(image).then((image) => {
            const formattedProfile = {
                ...newProfile,
                id: identifier,
                image,
                updatedAt: new Date().getTime(),
            };

            return remapImageFields(newProfile.sections).then((sections) => {
                formattedProfile.sections = sections;

                return updateAssetTemplateProfile(ws, dispatch, {
                    templates: templates,
                    profiles: profiles.concat([formattedProfile]),
                })
                    .then(
                        () =>
                            newProfile?.symbol &&
                            submitEditSymbol(
                                ws,
                                dispatch,
                                setSymbolProfileExists(
                                    symbols,
                                    newProfile,
                                    newProfile
                                )[0],
                                tradeSymbols
                            )
                    )
                    .then(() => {
                        toast.success("Profile successfully created.");

                        navigate("/admin/asset-profiles");
                    });
            });
            // .catch(() => toast.error("Image Upload Error!"));
        });
    };

    useEffect(() => {
        // If template param exists, then fetch the existing
        // profile from state and use it as the base.
        if (templates.length > 0) {
            for (const entry of searchParams.entries()) {
                const [param, value] = entry;

                if (param === "template") {
                    const temp = JSON.parse(
                        JSON.stringify(
                            templates.find((temp) => temp.id === value)
                        )
                    );

                    setTemplate(temp);
                } else if (param === "copy" && profiles.length > 0) {
                    const copyProfile = JSON.parse(
                        JSON.stringify(
                            profiles.find((temp) => temp.id === value)
                        )
                    );

                    if (Object.keys(copyProfile).length > 0) {
                        delete copyProfile.id;
                        delete copyProfile.image;
                        delete copyProfile.symbol;
                        delete copyProfile.isPublished;

                        for (let section of copyProfile.sections) {
                            for (let field of section.fields) {
                                if (
                                    ["Image Upload", "File Upload"].includes(
                                        field.fieldType
                                    )
                                ) {
                                    delete field.value;
                                } else if (field.fieldType === "Subsection") {
                                    for (let subfield of field.subfields) {
                                        delete subfield.value;
                                    }
                                }
                            }
                        }

                        toast.success("Profile successfully copied.");

                        setCopiedProfile(copyProfile);
                    }
                }
            }
        }
    }, [searchParams, profiles, templates]);

    useEffect(() => {
        if (profiles.length === 0) {
            getAssetTemplatesProfiles(ws, dispatch).finally(() =>
                setLoading(false)
            );
        } else {
            setLoading(false);
        }

        return () => setLoading(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [showGoBackModal, setShowGoBackModal] = useState(false);
    const handleHideGoBackModal = () => setShowGoBackModal(false);
    const handleShowGoBackModal = () => setShowGoBackModal(true);

    return (
        <div className="m-4">
            <Row noGutters className="forms-header">
                <AiOutlineArrowLeft
                    className="asset-back-button"
                    onClick={handleShowGoBackModal}
                />
                <h4>Create Profile</h4>
            </Row>
            <GoBackModal
                show={showGoBackModal}
                handleClose={handleHideGoBackModal}
                handleConfirm={() => {
                    handleHideGoBackModal();
                    navigate(`/admin/asset-profiles/`);
                }}
            />
            {loading ? (
                <FullScreenLoading />
            ) : (
                <CreateProfileForm
                    template={template}
                    symbols={symbols}
                    submitData={createProfile}
                    existingProfile={copiedProfile}
                />
            )}
        </div>
    );
};

export default CreateProfilePage;
