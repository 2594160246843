import { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { CreateTemplateForm } from "../../../components/admin/asset-builder/CreateTemplateForm";
import { GoBackModal } from "../../../components/admin/asset-builder/GoBackModal";
import { FullScreenLoading } from "../../../components/Loading";
import {
    getAssetTemplatesProfiles,
    updateAssetTemplateProfile,
} from "../../../helpers/asset-builder";
import { useStore } from "../../../Store";
import { useSocket } from "../../../Websocket";

const EditTemplatePage = () => {
    const { state, dispatch } = useStore();
    const ws = useSocket();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const { template: templateId } = useParams();

    const { templates, profiles } = state.asset_builder;
    const [existingTemplate, setExistingTemplate] = useState();
    const disableEditing = profiles.find(
        (prof) => prof.templateId.id === templateId
    );

    const editTemplate = (updatedTemplate) => {
        const newTemplates = templates.filter((temp) => temp.id !== templateId);

        return updateAssetTemplateProfile(ws, dispatch, {
            templates: newTemplates.concat([updatedTemplate]),
            profiles: profiles,
        }).then(() => {
            toast.success("Template succesfully edited.");
            navigate("/admin/asset-templates/");
        });
    };

    useEffect(() => {
        if (templates.length > 0)
            setExistingTemplate(
                templates.find((temp) => temp.id === templateId)
            );
    }, [templates, templateId]);

    useEffect(() => {
        if (templates.length === 0) {
            getAssetTemplatesProfiles(ws, dispatch).finally(() =>
                setLoading(false)
            );
        } else {
            setLoading(false);
        }

        return () => setLoading(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [showGoBackModal, setShowGoBackModal] = useState(false);
    const handleHideGoBackModal = () => setShowGoBackModal(false);
    const handleShowGoBackModal = () => setShowGoBackModal(true);

    return (
        <div className="m-4">
            <Row noGutters className="forms-header">
                <AiOutlineArrowLeft
                    className="asset-back-button"
                    onClick={handleShowGoBackModal}
                />
                <h4>Edit Template</h4>
            </Row>

            <GoBackModal
                show={showGoBackModal}
                handleClose={handleHideGoBackModal}
                handleConfirm={() => {
                    handleHideGoBackModal();
                    navigate("/admin/asset-templates");
                }}
            />

            {loading ? (
                <FullScreenLoading />
            ) : (
                <CreateTemplateForm
                    existingTemplate={existingTemplate}
                    type="edit"
                    submitData={editTemplate}
                    disableEditing={disableEditing}
                />
            )}
        </div>
    );
};

export default EditTemplatePage;
