import "react-datepicker/dist/react-datepicker.css";

import { cloneDeep } from "lodash";
import { Form, Row } from "react-bootstrap";

export const InputTemplate = (props) => {
    const { field, onChange, classes, onValidate = undefined } = props;
    const { fieldName, fieldType, required, value } = field;

    const otherProps = cloneDeep(props);

    delete otherProps.onChange;
    delete otherProps.field;
    delete otherProps.classes;
    delete otherProps.onValidate;

    return (
        <Row className={classes} style={{ position: "relative" }} noGutters>
            <Form.Control
                {...otherProps}
                name={`${fieldName}`}
                size="sm"
                onChange={({ target: { value: fieldValue } }) =>
                    onChange(fieldValue)
                }
                value={value || ""}
                required={required}
                isInvalid={onValidate ? onValidate(value) : false}
            />
            {required && (
                <span
                    className="required-icon"
                    style={{
                        top: fieldType === "Paragraph" ? "0.5em" : undefined,
                    }}
                >
                    *
                </span>
            )}
        </Row>
    );
};
