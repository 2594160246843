import { memo, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import Select from "react-select";

import { hideHaltedSymbols } from "../../../config";
import { storeOptions } from "../../../helpers/display-options";
import { subscribeSymbols } from "../../../helpers/trade";
import { useStore } from "../../../Store";
import { useSocket } from "../../../Websocket";

// eslint-disable-next-line react/display-name
export const AddSymbolToWatchListModal = memo(({ show, handleClose }) => {
    const ws = useSocket();
    const { state, dispatch } = useStore();
    const loggedInUser = state.account.account;

    const keyType = "trade_preferences";
    const optionKey = "market_watch";
    const symbolWatchList =
        loggedInUser?.data?.attr?.[keyType]?.[optionKey]?.watch_list || [];
    const { layoutConfigurationOptions } = state.trade;

    const symbols =
        state.trade?.symbols
            ?.filter(
                (symb) =>
                    !symbolWatchList.includes(symb.security) &&
                    (hideHaltedSymbols ? !symb?.attr?.isHidden : symb)
            )
            .map((symb) => ({
                label: symb.security,
                value: symb.security,
            })) || [];
    const [selectedSymbols, setSelectedSymbols] = useState([]);
    const [removedSymbols, setRemovedSymbols] = useState([]);

    // updatedSymbols can be a array of symbols to add or a single symbol to remove
    const updateSymbolInWatchList = (updatedSymbols, type) => {
        const oldSymbols =
            loggedInUser?.data?.attr?.[keyType]?.[optionKey]?.watch_list || [];

        return storeOptions(
            ws,
            dispatch,
            loggedInUser?.data,
            keyType,
            optionKey,
            type === "add"
                ? oldSymbols.concat(updatedSymbols)
                : oldSymbols.filter((symb) => symb !== updatedSymbols),
            "watch_list"
        );
    };

    const closeModal = (symbolsToSubscribe, symbolsToRemove) => {
        subscribeSymbols(
            ws,
            dispatch,
            symbolsToSubscribe,
            symbolsToRemove,
            layoutConfigurationOptions?.useExternalBookFeed,
            layoutConfigurationOptions?.useExternalTobFeed
        );

        setRemovedSymbols([]);

        handleClose();
    };

    const saveSymbols = () => {
        if (selectedSymbols.length !== 0) {
            updateSymbolInWatchList(
                selectedSymbols.map((s) => s.value),
                "add"
            );

            setSelectedSymbols([]);
        }

        closeModal(
            selectedSymbols
                ?.map((s) => ({ security: s.value }))
                .filter(
                    (s) => !removedSymbols.find((sy) => sy === s.security)
                ) || [],
            removedSymbols
                ?.map((s) => ({ security: s }))
                .filter(
                    (s) =>
                        !selectedSymbols.find((sy) => sy.value === s.security)
                ) || []
        );
    };

    return (
        <Modal
            show={show}
            onHide={() =>
                closeModal(
                    [],
                    removedSymbols.map((s) => ({ security: s }))
                )
            }
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title>Add Symbol to Watch List</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row noGutters className="mb-2">
                    <Select
                        isSearchable
                        isMulti
                        className="w-100"
                        placeholder="Search for a symbol to add..."
                        options={symbols}
                        onChange={(selected) => setSelectedSymbols(selected)}
                    />
                </Row>
                <Row noGutters className="ml-1 mb-2">
                    <div>
                        <b>Watchlisted Symbols</b>
                    </div>
                </Row>
                <Row noGutters className="px-1">
                    {symbolWatchList.map((symb, i) => (
                        <Col
                            sm={6}
                            md={4}
                            lg={3}
                            xl={2}
                            key={i}
                            className="watchlist-symbol-minicard"
                            title="Click to remove"
                            onClick={() => {
                                setRemovedSymbols([...removedSymbols, symb]);
                                updateSymbolInWatchList(symb, "remove");
                            }}
                        >
                            {symb.length > 9 ? `${symb.slice(0, 9)}...` : symb}
                        </Col>
                    ))}
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    onClick={saveSymbols}
                    className="px-4"
                    disabled={selectedSymbols.length === 0}
                >
                    Add selected symbols
                </Button>
                <Button
                    onClick={() =>
                        closeModal(
                            [],
                            removedSymbols.map((s) => ({ security: s }))
                        )
                    }
                    variant="light"
                    className="px-3"
                >
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
});
