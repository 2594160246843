import { useEffect } from "react";
import { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";

import {
    AddEditFirmModal,
    DeleteFirmModal,
} from "../../../components/admin/firm/firms";
import { TableRenderer } from "../../../components/TableRenderer";
import { enableFirmAdditionalInfo } from "../../../config";
import { createFirm, deleteFirm, getFirms } from "../../../helpers/firm";
import { useStore } from "../../../Store";
import { useSocket } from "../../../Websocket";

const Firms = () => {
    const ws = useSocket();
    const { dispatch, state } = useStore();
    const [loading, setLoading] = useState(true);

    const { firms } = state.firm;

    const [selectedFirm, setSelectedFirm] = useState({
        isEdit: false,
    });

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showAddEditModal, setShowAddEditModal] = useState(false);

    const handleOpenDeleteModal = () => setShowDeleteModal(true);
    const handleCloseDeleteModal = () => setShowDeleteModal(false);
    const handleOpenAddEditModal = () => setShowAddEditModal(true);
    const handleCloseAddEditModal = () => {
        setShowAddEditModal(false);
        setSelectedFirm({ isEdit: false });
    };

    useEffect(() => {
        if (firms.length === 0) {
            getFirms(ws, dispatch).finally(() => setLoading(false));
        } else {
            setLoading(false);
        }

        return () => setLoading(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const actionColumn = {
        dataField: "action",
        text: "",
        isDummyField: true,
        // eslint-disable-next-line no-unused-vars
        formatter: (cell, firm) => (
            <Row noGutters>
                <Button
                    size="sm"
                    className="mr-1"
                    onClick={() => {
                        setSelectedFirm({ ...firm, isEdit: true });
                        handleOpenAddEditModal();
                    }}
                >
                    Edit
                </Button>
                <Button
                    size="sm"
                    variant="danger"
                    onClick={() => {
                        setSelectedFirm(firm);
                        handleOpenDeleteModal();
                    }}
                >
                    Delete
                </Button>
            </Row>
        ),
    };

    const handleAddEditSubmit = (firm) =>
        createFirm(
            ws,
            dispatch,
            {
                firm: firm.firm,
                description: enableFirmAdditionalInfo
                    ? JSON.stringify({
                          ...firm,
                          description: undefined,
                          firm: undefined,
                      })
                    : firm.description,
            },
            selectedFirm?.isEdit
        ).then(handleCloseAddEditModal);

    const handleDelete = (firm) =>
        deleteFirm(ws, dispatch, firm.firm).then(handleCloseDeleteModal);

    const defaultColumns = enableFirmAdditionalInfo
        ? [
              "firm",
              "firmName",
              "firmID",
              "firmType",
              "firmState",
              "firmDescription",
          ]
        : ["firm", "description"];

    const blacklistedColumns = enableFirmAdditionalInfo ? ["description"] : [];

    return (
        <div className="m-4">
            <Row noGutters className="forms-header justify-content-between">
                <Col>
                    <h4>Firms</h4>
                </Col>
                <Col className="d-flex justify-content-end">
                    <Button onClick={handleOpenAddEditModal} size="sm">
                        Add Firm
                    </Button>
                </Col>
            </Row>

            {showAddEditModal && (
                <AddEditFirmModal
                    show={showAddEditModal}
                    handleCloseModal={handleCloseAddEditModal}
                    firm={selectedFirm}
                    handleSubmit={handleAddEditSubmit}
                    type={selectedFirm?.isEdit ? "edit" : "add"}
                />
            )}

            <DeleteFirmModal
                show={showDeleteModal}
                handleCloseModal={handleCloseDeleteModal}
                firm={selectedFirm}
                handleDelete={handleDelete}
            />

            <TableRenderer
                keyField="firm"
                data={firms}
                settingsColumn={[actionColumn]}
                loading={loading}
                noDataIndication="No Firms Found"
                pagination
                striped
                wrapperClasses="size-sm"
                keyType={"firm_preferences"}
                optionKey={"firms"}
                blacklistAttr={blacklistedColumns}
                defaultOptions={defaultColumns}
                hideConfigureButtons={!enableFirmAdditionalInfo}
            />
        </div>
    );
};

export default Firms;
