import { useEffect, useState } from "react";
import { Button, Col, FormControl, FormLabel, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";

import { AssetProfileTable } from "../../../components/admin/asset-builder/AssetProfileTable";
import { SelectTemplateModal } from "../../../components/admin/asset-builder/SelectTemplateModal";
import {
    deleteAllFieldImages,
    deleteImageForProfile,
    getAssetTemplatesProfiles,
    updateAssetTemplateProfile,
} from "../../../helpers/asset-builder";
import { submitEditSymbol } from "../../../helpers/market";
import { useStore } from "../../../Store";
import { useSocket } from "../../../Websocket";

const AssetProfiles = () => {
    const { state, dispatch } = useStore();
    const ws = useSocket();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const {
        asset_builder: { templates, profiles },
        market: { symbols },
        trade: { symbols: tradeSymbols },
    } = state;

    const statusOptions = [
        { value: null, label: "All" },
        { value: true, label: "Published" },
        { value: false, label: "Unverified" },
    ];
    const templateOptions = [
        { value: null, label: "All" },
        ...templates
            .filter((template) => template.isPublished === true)
            .map((temp) => ({
                value: temp.id,
                label: temp.name,
            })),
    ];

    const [searchValue, setSearchValue] = useState("");
    const [filterStatus, setFilterStatus] = useState(statusOptions[0]);
    const [filterType, setFilterType] = useState(templateOptions[0]);
    const [filteredProfiles, setFilteredProfiles] = useState(profiles);

    const onSearch = () => {
        const showProfiles = profiles.filter(
            (prof) =>
                prof.name.toLowerCase().includes(searchValue.toLowerCase()) &&
                (filterStatus.value === true || filterStatus.value === false
                    ? prof.isPublished === filterStatus.value
                    : true) &&
                (filterType.value
                    ? prof.templateId.id === filterType.value
                    : true)
        );

        setFilteredProfiles(showProfiles);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(onSearch, [filterStatus, filterType, searchValue]);

    const deleteAssetProfile = ({ id }) => {
        const profileToDelete = profiles.find((profile) => profile.id === id);

        profileToDelete && deleteAllFieldImages(profileToDelete.sections);

        return updateAssetTemplateProfile(ws, dispatch, {
            templates: templates,
            profiles: profiles.filter((prof) => prof.id !== id),
        })
            .then(() => deleteImageForProfile(profileToDelete.image))
            .then(() => {
                const toEditSymbol = symbols.find(
                    (sym) => sym.security === profileToDelete.symbol
                );

                return submitEditSymbol(
                    ws,
                    dispatch,
                    {
                        ...toEditSymbol,
                        fractionbase:
                            toEditSymbol?.fractionbase &&
                            `${toEditSymbol?.fractionbase}`,
                        priceprecision:
                            toEditSymbol?.priceprecision &&
                            `${toEditSymbol?.priceprecision}`,
                        attr: {
                            ...toEditSymbol?.attr,
                            profile_exists: false,
                        },
                    },
                    tradeSymbols
                );
            })
            .then(() => {
                toast.success("Profile successfully deleted.");
            });
    };
    useEffect(() => {
        setFilteredProfiles(profiles);
    }, [profiles]);

    useEffect(() => {
        if (profiles.length === 0) {
            getAssetTemplatesProfiles(ws, dispatch).finally(() =>
                setLoading(false)
            );
        } else {
            setLoading(false);
        }

        return () => setLoading(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [showTemplateSelectModal, setShowTemplateSelectModal] =
        useState(false);
    const handleHideTemplateSelectModal = () =>
        setShowTemplateSelectModal(false);
    const handleShowTemplateSelectModal = () =>
        setShowTemplateSelectModal(true);

    return (
        <div className="m-4">
            <Row noGutters className="forms-header">
                <h4>Asset Profiles</h4>
            </Row>
            <SelectTemplateModal
                show={showTemplateSelectModal}
                handleClose={handleHideTemplateSelectModal}
                handleConfirm={(template) =>
                    navigate(
                        `/admin/asset-profiles/create?template=${template}`
                    )
                }
                templateOptions={templateOptions}
            />
            <Row noGutters className="pb-2">
                <Col xs={12} md={3} className="pr-2 pb-2">
                    <FormLabel>Filter by Asset Type:</FormLabel>
                    <Select
                        value={filterType}
                        onChange={(e) => {
                            setFilterType({ value: e.value, label: e.label });
                        }}
                        options={templateOptions}
                    />
                </Col>
                <Col xs={12} md={3} className="pr-2 pb-2">
                    <FormLabel>Filter by Verification:</FormLabel>
                    <Select
                        value={filterStatus}
                        onChange={(e) => {
                            setFilterStatus({ value: e.value, label: e.label });
                        }}
                        options={statusOptions}
                    />
                </Col>
                <Col xs={12} md={3} className="pr-2 pb-2">
                    <FormLabel>Search:</FormLabel>
                    <FormControl
                        placeholder="Search..."
                        value={searchValue}
                        onChange={(e) => {
                            setSearchValue(e.target.value);
                        }}
                    />
                </Col>
                <Col
                    xs={12}
                    md={3}
                    className="pb-2 d-flex justify-content-end align-items-end"
                >
                    <Button
                        variant="primary"
                        onClick={handleShowTemplateSelectModal}
                        size="sm"
                    >
                        Create Profile
                    </Button>
                </Col>
            </Row>
            <hr />
            <AssetProfileTable
                deleteAssetProfile={deleteAssetProfile}
                profiles={filteredProfiles}
                loading={loading}
            />
        </div>
    );
};

export default AssetProfiles;
