/* eslint-disable react/display-name */
import { memo, useEffect, useMemo, useState } from "react";
import { Button, FormControl, Table } from "react-bootstrap";
// eslint-disable-next-line no-unused-vars
import {
    AiOutlineArrowDown,
    AiOutlineArrowUp,
    AiOutlineDelete,
} from "react-icons/ai";

export const TableConfigureInput = memo((props) => {
    const { field, onChange, classes, disabled } = props;

    const [rows, setRows] = useState(
        field?.value || [field.headers.map(() => "")]
    );

    const addRow = () => setRows([...rows, field.headers.map(() => "")]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => onChange(rows), [rows]);

    return (
        <>
            <Table responsive="sm" className={classes}>
                <thead>
                    <tr>
                        <th>#</th>
                        {field.headers.map((header, i) => (
                            <th key={i} className="size-sm">
                                {header}
                            </th>
                        ))}

                        <th>
                            <Button
                                size="sm"
                                onClick={addRow}
                                disabled={disabled}
                            >
                                +
                            </Button>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {useMemo(
                        () =>
                            rows.map((row, i) => (
                                <tr key={i}>
                                    <td>{i + 1}</td>
                                    {row.map((cell, j) => (
                                        <td key={j}>
                                            <FormControl
                                                size="sm"
                                                value={cell}
                                                onChange={({
                                                    target: { value },
                                                }) => {
                                                    const newRows = [...rows];

                                                    newRows[i][j] = value;

                                                    setRows(newRows);
                                                }}
                                                disabled={disabled}
                                            />
                                        </td>
                                    ))}
                                    <td>
                                        <div className="d-flex mt-1 justify-content-between">
                                            <AiOutlineArrowUp
                                                className={` ${
                                                    !disabled && i !== 0
                                                        ? " cursor-pointer"
                                                        : "text-gray"
                                                }`}
                                                style={{
                                                    height: "18px",
                                                    width: "18px",
                                                }}
                                                onClick={() => {
                                                    if (i > 0) {
                                                        const newRows =
                                                            Array.from(rows);

                                                        newRows.splice(
                                                            i - 1,
                                                            0,
                                                            [...rows[i]]
                                                        );
                                                        newRows.splice(
                                                            i + 1,
                                                            1
                                                        );

                                                        setRows(newRows);
                                                    }
                                                }}
                                            />
                                            <AiOutlineArrowDown
                                                className={` ${
                                                    !disabled &&
                                                    i !== rows.length - 1
                                                        ? " cursor-pointer"
                                                        : "text-gray"
                                                }`}
                                                style={{
                                                    height: "18px",
                                                    width: "18px",
                                                }}
                                                onClick={() => {
                                                    if (i != rows.length) {
                                                        const newRows =
                                                            Array.from(rows);

                                                        newRows.splice(
                                                            i + 2,
                                                            0,
                                                            [...rows[i]]
                                                        );
                                                        newRows.splice(i, 1);

                                                        setRows(newRows);
                                                    }
                                                }}
                                            />
                                            <AiOutlineDelete
                                                className={` ${
                                                    !disabled
                                                        ? "text-danger cursor-pointer"
                                                        : "text-gray"
                                                }`}
                                                style={{
                                                    height: "18px",
                                                    width: "18px",
                                                }}
                                                onClick={
                                                    !disabled
                                                        ? () => {
                                                              const newRows = [
                                                                  ...rows,
                                                              ];

                                                              newRows.splice(
                                                                  i,
                                                                  1
                                                              );

                                                              setRows(newRows);
                                                          }
                                                        : () => {}
                                                }
                                            />
                                        </div>
                                    </td>
                                </tr>
                            )),
                        [rows, disabled]
                    )}
                </tbody>
            </Table>
        </>
    );
});
