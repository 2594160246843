import { Col, Container } from "react-bootstrap";

import { AdminSidebar } from "../../components/admin/AdminSidebar";
import { AdminTopbar } from "../../components/admin/AdminTopbar";

const AdminLayout = ({ children }) => (
    <>
        <Col className="flex-grow-0">
            <AdminSidebar />
        </Col>
        <Col className="d-flex flex-column overflow-hidden">
            <AdminTopbar />
            <div className="content-pane">
                <Container fluid>{children}</Container>
            </div>
        </Col>
    </>
);

export default AdminLayout;
