import { merge } from "lodash";

import { removeBrokenTrades } from "../../helpers/trade";
import * as MARKET_CONSTANTS from "../constants/market";
import * as CONSTANTS from "../constants/trade";

const DEFAULT_MARKET_STATISTIC_ITEM = {
    i: "market-statistic-0",
    x: 0,
    y: 0,
    w: 11,
    h: 1,
    moved: false,
    isResizable: true,
};

const DEFAULT_HOTKEY_SELECT_ITEM = {
    i: "hotkey-select-0",
    x: 0,
    y: 11,
    w: 6,
    h: 4,
    moved: false,
    isResizable: true,
};
const DEFAULT_SYMBOL_SELECT_ITEM = {
    i: "symbol-select-0",
    x: 20,
    y: 0,
    w: 6,
    h: 1,
    moved: false,
    isResizable: false,
};
const DEFAULT_ORDER_TICKET_ITEM = {
    i: "order-ticket-0",
    x: 0,
    y: 1,
    w: 6,
    minW: 6,
    h: 10,
    moved: false,
    isResizable: true,
};
const DEFAULT_TRADE_CHART_ITEM = {
    i: "trade-chart-0",
    x: 6,
    y: 1,
    w: 14,
    h: 8,
    moved: false,
    isResizable: true,
};
const DEFAULT_ORDERS_TABLE_ITEM = {
    i: "orders-table-0",
    x: 6,
    y: 9,
    w: 14,
    h: 6,
    moved: false,
    isResizable: true,
};
const DEFAULT_BOOKS_TABLE_ITEM = {
    i: "books-table-0",
    x: 20,
    y: 1,
    w: 6,
    h: 14,
    moved: false,
    isResizable: true,
};
const DEFAULT_MARKET_WATCH_ITEM = {
    i: "market-watch-0",
    x: 0,
    y: 18,
    w: 6,
    h: 8,
    moved: false,
    isResizable: true,
};

export const initialTradeState = {
    symbolsSubscribed: false,
    subscribedBookSymbols: [],
    orders: {
        pending: [],
        fill: [],
        cancelled: [],
        negotiate: [],
    },
    books: [],
    positions: [],
    symbols: [],
    auctionInformation: [],
    portalAuctionInformation: [],
    scheduledSymbolStatuses: [],
    activeOrder: null,
    subscribedSecurity: "",
    tradeHistory: {
        security: "",
        data: [],
    },
    transaction: {
        orders: [],
        cancels: [],
        fills: [],
        balances: [],
        positions: [],
    },
    lsHistory: [], // This is the query
    itradeLsHistory: {}, // This is the sub
    currentSymbol: {},
    layoutConfigurationOptions: {
        // trade, preset1, preset2, preset3
        pageSelected: "trade",
        styling: {
            primaryBackgroundColor: "--DarkModePortal-background",
            fontColor: "--White",
            fontSize: 2,
            rowSizing: "normal",
            backgroundColor: "--DarkModePortalComponent-background",
            headerFontColor: "--White",
            button: {
                primaryBGColor: "--MainButtonBG",
                primaryFontColor: "--White",
                secondaryBGColor: "--warning",
                secondaryFontColor: "#212529",
                cancelBGColor: "--danger",
                cancelFontColor: "--White",
            },
            bookGradient: {
                bookGradientActive: false,
                buyBidColor: "#00008B",
                sellAskColor: "#8B0000",
                buyBidTextColor: "--White",
                sellAskTextColor: "--White",
            },
            tradeHistory: {
                positiveChangeColor: "#00FF00",
                negativeChangeColor: "#FF0000",
                neutralChangeColor: "#FFFFFF",
            },
        },
        layout: [
            DEFAULT_MARKET_STATISTIC_ITEM,
            DEFAULT_HOTKEY_SELECT_ITEM,
            DEFAULT_SYMBOL_SELECT_ITEM,
            DEFAULT_ORDER_TICKET_ITEM,
            DEFAULT_TRADE_CHART_ITEM,
            DEFAULT_ORDERS_TABLE_ITEM,
            DEFAULT_BOOKS_TABLE_ITEM,
            DEFAULT_MARKET_WATCH_ITEM,
        ],
        itemLimits: {
            "market-statistic": 1,
            "hotkey-select": 1,
            "symbol-select": 1,
            "order-ticket": 1,
            "trade-chart": 1,
            "orders-table": 2,
            "books-table": 1,
            "market-watch": 1,
        },
        ordersTableTabs: {
            "orders-table-0": [
                "orders",
                "cancelled",
                "fills",
                "negotiate",
                "positions",
            ],
        },
        configureEnabled: {
            "orders-table-0": true,
            "books-table-0": true,
        },
        booksTableTabs: {
            "books-table-0": ["books", "history", "qms"],
        },
        lastSelectedTabs: {},
        spacing: [2, 2],
        padding: 16,
        playSoundOnTrade: false,
        useExternalBookFeed: false,
        showConfigureOnMarketWatch: false,
        componentBorders: true,
        expandedOrderTicket: {
            "order-ticket-0": false,
            "order-ticket-1": false,
        },
        destinationFieldOnMain: {
            "order-ticket-0": false,
            "order-ticket-1": false,
        },
        activeOrderTableFiltering: {
            "orders-table-0": {
                symbol: "",
                destination: "",
                side: "",
            },
        },
        activeBooksTableFiltering: {
            "books-table-0": {
                symbol: "",
            },
        },
    },
};

// Only runs on type:"trade", meaning it will always have a matchid
const getLsHistoryToUpdate = (histories, newTrade) => {
    return histories.some(
        (history) =>
            ((newTrade.matchid && history.matchid === newTrade.matchid) ||
                (history.time === newTrade.time &&
                    history.qty === newTrade.qty &&
                    history.price === newTrade.price)) &&
            history.status === newTrade.status
    )
        ? histories
        : [
              ...histories,
              {
                  ...newTrade,
                  execqty: newTrade.qty,
                  execprice: newTrade.price,
                  rec_no: histories.length + 1,
              },
          ];
};

export const tradeReducer = (state, action) => {
    switch (action.type) {
        case CONSTANTS.SUBSCRIBED_TO_SYMBOLS:
            return {
                ...state,
                symbolsSubscribed: true,
            };
        case CONSTANTS.ADD_ORDER: {
            if (action.order?.inbound) {
                return {
                    ...state,
                    orders: {
                        ...state.orders,
                        negotiate: [...state.orders.negotiate, action.order],
                    },
                };
            }

            const isOrderExist = state.orders.pending.some(
                (order) => action.order.refno === order.refno
            );

            const orders = isOrderExist
                ? state.orders.pending.map((order) =>
                      order.refno === action.order.refno ? action.order : order
                  )
                : state.orders.pending.concat(action.order);

            return {
                ...state,
                orders: {
                    ...state.orders,
                    pending: orders,
                },
            };
        }
        case CONSTANTS.CANCEL_ORDER: {
            if (action.order?.inbound) {
                let negotiateOrdersToUpdate = [...state.orders.negotiate];
                const index = negotiateOrdersToUpdate.findIndex(
                    (order) => order.refno === action.order.refno
                );
                negotiateOrdersToUpdate.splice(index, 1);
                return {
                    ...state,
                    orders: {
                        ...state.orders,
                        negotiate: negotiateOrdersToUpdate,
                    },
                };
            }
            return {
                ...state,
                orders: {
                    ...state.orders,
                    pending: state.orders.pending.filter(
                        (order) => order.refno !== action.order.refno
                    ),
                    cancelled: state.orders.cancelled.concat(action.order),
                },
            };
        }
        case CONSTANTS.REJECT_ORDER: {
            if (action.order?.inbound) {
                let negotiateOrdersToUpdate = [...state.orders.negotiate];
                const index = negotiateOrdersToUpdate.findIndex(
                    (order) => order.refno === action.order.refno
                );
                negotiateOrdersToUpdate.splice(index, 1);
                return {
                    ...state,
                    orders: {
                        ...state.orders,
                        negotiate: negotiateOrdersToUpdate,
                    },
                };
            }
            return {
                ...state,
                orders: {
                    ...state.orders,
                    pending: state.orders.pending.filter(
                        (order) => order.refno !== action.order.refno
                    ),
                },
            };
        }
        case CONSTANTS.EXECUTE_ORDER: {
            if (action.order.type === "sale") {
                if (
                    state.orders.fill.find(
                        (saleMessage) =>
                            saleMessage.traderefno === action.order.traderefno
                    )
                ) {
                    return {
                        ...state,
                        orders: {
                            ...state.orders,
                            fill: Array.from(
                                state.orders.fill.map((saleMessage) =>
                                    saleMessage.traderefno ===
                                    action.order.traderefno
                                        ? action.order
                                        : saleMessage
                                )
                            ),
                        },
                    };
                }
                return {
                    ...state,
                    orders: {
                        ...state.orders,
                        fill: state.orders.fill.concat(action.order),
                    },
                };
            }
            if (action.order?.inbound) {
                let negotiateOrdersToUpdate = [...state.orders.negotiate];
                const index = negotiateOrdersToUpdate.findIndex(
                    (order) => order.refno === action.order.refno
                );
                negotiateOrdersToUpdate.splice(index, 1);
                return {
                    ...state,
                    orders: {
                        ...state.orders,
                        negotiate: negotiateOrdersToUpdate,
                    },
                };
            }
            return {
                ...state,
                orders: {
                    ...state.orders,
                    pending: state.orders.pending.filter(
                        (order) => order.refno !== action.order.refno
                    ),
                },
            };
        }
        case CONSTANTS.QUERY_TRADE:
            return {
                ...state,
                orders: {
                    ...state.orders,
                    fill: action.data,
                },
            };
        case CONSTANTS.RESET:
            return initialTradeState;
        case CONSTANTS.ADD_BOOK: {
            let books;

            const isBookExist = state.books.some(
                (book) => action.book?.id === book?.id
            );
            if (isBookExist) {
                books = state.books.map((book) =>
                    book?.id === action.book?.id ? action.book : book
                );
            } else {
                books = state.books.concat(action.book);
            }

            const replaceWithNumberIfNegotiable = (price, side) =>
                price === "Negotiable"
                    ? side === "S"
                        ? 999999999999
                        : -1
                    : price;

            const buyBooks = books
                .filter((book) => book.side === "B")
                .sort(
                    (a, b) =>
                        replaceWithNumberIfNegotiable(b.price, "B") -
                            replaceWithNumberIfNegotiable(a.price, "B") ||
                        (a.price !== "Negotiable" &&
                            b.price !== "Negotiable" &&
                            (a.priority && b.priority
                                ? a.priority - b.priority
                                : a.time - b.time))
                );
            const sellBooks = books
                .filter((book) => book.side === "S")
                .sort(
                    (a, b) =>
                        replaceWithNumberIfNegotiable(b.price, "S") -
                            replaceWithNumberIfNegotiable(a.price, "S") ||
                        (a.price !== "Negotiable" &&
                            b.price !== "Negotiable" &&
                            (a.priority && b.priority
                                ? b.priority - a.priority
                                : b.time - a.time))
                );

            return {
                ...state,
                books: sellBooks.concat([...buyBooks]),
            };
        }
        case CONSTANTS.REMOVE_BOOK:
            return {
                ...state,
                books: state.books.filter((book) => book.key !== action.key),
            };
        case CONSTANTS.RESET_BOOKS:
            return {
                ...state,
                books: initialTradeState.books,
                lsHistory: initialTradeState.lsHistory,
            };
        case CONSTANTS.ADD_POSITION: {
            // Check if position exists before adding
            const isPositionExist = state.positions.some(
                (position) =>
                    action.position.security === position.security &&
                    position.account === action.position.account
            );
            if (isPositionExist) {
                // If position exists, overwrite.
                return {
                    ...state,
                    positions: state.positions.map((position) =>
                        position.security === action.position.security &&
                        position.account === action.position.account
                            ? action.position
                            : position
                    ),
                };
            } else {
                // If position doesnt exist, concat to existing positions
                return {
                    ...state,
                    positions: state.positions.concat(action.position),
                };
            }
        }
        case CONSTANTS.ADD_TRADE_SYMBOLS:
            return {
                ...state,
                symbols: action.symbols,
            };
        case MARKET_CONSTANTS.DELETE_SYMBOL:
            return {
                ...state,
                symbols: state.symbols.filter(
                    (existing) => existing.security !== action.security
                ),
            };
        case CONSTANTS.UPDATE_SYMBOL_STATUS:
            return {
                ...state,
                symbols: Array.from(
                    state.symbols.map((symb) => {
                        const foundSymb = action.symbols.find(
                            (found) => found.security === symb.security
                        );

                        if (foundSymb) {
                            symb.status = foundSymb.status;
                        }

                        return symb;
                    })
                ),
            };
        case CONSTANTS.ADD_SYMBOL_PREV_CLOSE_PRICE: {
            return {
                ...state,
                symbols: Array.from(
                    state.symbols.map((symbol) => {
                        if (symbol.security === action.security) {
                            symbol["prevcloseprice"] = action.prevcloseprice;
                            symbol["high"] = action.high;
                            symbol["low"] = action.low;
                            symbol["last"] = action.last;
                            symbol["lastPricePair"] = {
                                ...symbol["lastPricePair"],
                                prevcloseprice: action.prevcloseprice,
                                last: action.last,
                            };
                            symbol["percentChangePair"] = {
                                ...symbol["percentChangePair"],
                                prevcloseprice: action.prevcloseprice,
                                last: action.last,
                            };
                        }
                        return JSON.parse(JSON.stringify(symbol));
                    })
                ),
            };
        }
        case CONSTANTS.ADD_SYMBOL_BID_OFFER: {
            return {
                ...state,
                symbols: state.symbols.map((symbol) => {
                    if (symbol.security === action.security) {
                        symbol["bid"] = action.bid;
                        symbol["offer"] = action.ask;
                    }
                    return symbol;
                }),
            };
        }
        case CONSTANTS.ACTIVE_ORDER:
            return {
                ...state,
                activeOrder: action.order,
            };
        case CONSTANTS.CLEAR_ACTIVE_ORDER:
            return {
                ...state,
                activeOrder: initialTradeState.activeOrder,
            };
        case CONSTANTS.SUBSCRIBED_SECURITY:
            return {
                ...state,
                subscribedSecurity: action.security,
            };
        case CONSTANTS.ADD_TRADE_HISTORY: {
            const data = action?.history
                ? action?.history.map((item) => ({
                      time: parseFloat(item.blocktime) / 1000,
                      open: parseFloat(item.startprice),
                      high: parseFloat(item.highprice),
                      low: parseFloat(item.lowprice),
                      close: parseFloat(item.lastprice),
                      value: parseFloat(item.volume),
                  }))
                : [];

            return {
                ...state,
                tradeHistory: {
                    security: action.security,
                    data:
                        // action.security === state.tradeHistory.security
                        //     ? state.tradeHistory.data.concat(data)
                        //     : data,
                        data,
                },
            };
        }
        case CONSTANTS.TRADE_QUERY_MULTIPLE_ORDERS:
            return {
                ...state,
                transaction: {
                    ...state.transaction,
                    orders: action.orders,
                },
            };
        case CONSTANTS.TRADE_QUERY_CANCELS:
            return {
                ...state,
                transaction: {
                    ...state.transaction,
                    cancels: action.cancels,
                },
            };
        case CONSTANTS.TRADE_QUERY_ACTIVITY_TRADE:
            return {
                ...state,
                transaction: {
                    ...state.transaction,
                    fills: action.fills,
                },
            };
        case CONSTANTS.TRADE_QUERY_DEPOSIT:
            return {
                ...state,
                transaction: {
                    ...state.transaction,
                    balances: action.balances,
                },
            };
        case CONSTANTS.TRADE_QUERY_POSITION:
            return {
                ...state,
                transaction: {
                    ...state.transaction,
                    positions: action.positions,
                },
            };
        case CONSTANTS.QUERY_LS_HISTORY:
            // THIS IS ONLY ON QUERY
            return {
                ...state,
                lsHistory: state.lsHistory.concat(
                    removeBrokenTrades(action.lsHistory)
                ),
            };
        case CONSTANTS.ADD_LS_HISTORY: {
            // We will add the broken trade into history here
            // AND filter both trades out whenever we want to display
            // we might need to revisit in the future depends on type+"trade" incoming messages

            // We will move the above to the respective components
            // WE CANNOT PUT removeBrokenTrades here
            // we can inside QUERY_LS_HISTORY because that is a static array, and if its broken
            // The original matching trade will be there already
            // We cannot do that here because entire array could just be broken trades
            // If that was the case and we called removeBrokenTrades we would get an empty array

            const newSymbolObj = Array.from(
                state.symbols.map((symbol) => {
                    return symbol.security === action.data.security &&
                        action.data.status === "Open"
                        ? JSON.parse(
                              JSON.stringify(
                                  Object.assign(symbol, {
                                      last: action.data.price,
                                      lastPricePair: {
                                          ...symbol["lastPricePair"],
                                          last: action.data.price,
                                      },
                                      percentChangePair: {
                                          ...symbol["percentChangePair"],
                                          last: action.data.price,
                                      },
                                  })
                              )
                          )
                        : symbol;
                })
            );

            if (state.itradeLsHistory[action.data.security]) {
                return {
                    ...state,
                    itradeLsHistory: {
                        ...state.itradeLsHistory,
                        [action.data.security]: getLsHistoryToUpdate(
                            state.itradeLsHistory[action.data.security],
                            action.data
                        ).sort((x, y) => parseInt(x.time) > parseInt(y.time)),
                    },
                    symbols: newSymbolObj,
                };
            } else {
                return {
                    ...state,
                    itradeLsHistory: {
                        ...state.itradeLsHistory,
                        [action.data.security]: [action.data],
                    },
                    symbols: newSymbolObj,
                };
            }
        }
        case CONSTANTS.SET_CURRENT_SYMBOL:
            return {
                ...state,
                currentSymbol: action.symbol,
            };
        case CONSTANTS.UPDATE_LAYOUT:
            return {
                ...state,
                layoutConfigurationOptions: {
                    ...state.layoutConfigurationOptions,
                    layout: action.layout,
                },
            };

        case CONSTANTS.REMOVE_COMPONENT_FROM_LAYOUT:
            return {
                ...state,
                layoutConfigurationOptions: {
                    ...state.layoutConfigurationOptions,
                    layout: state.layoutConfigurationOptions.layout.filter(
                        (elem) => elem.i !== action.componentName
                    ),
                },
            };
        case CONSTANTS.RESET_LAYOUT_CONFIGURATION:
            return {
                ...state,
                layoutConfigurationOptions:
                    initialTradeState.layoutConfigurationOptions,
            };
        case CONSTANTS.UPDATE_LAYOUT_CONFIGURATION: {
            const parseJsonFromConfig = () => {
                try {
                    const parsedAttributes =
                        action.newConfig?.config_setting?.attr &&
                        Object.keys(action.newConfig?.config_setting?.attr)
                            .length > 0
                            ? JSON.parse(action.newConfig?.config_setting?.attr)
                            : null;

                    return parsedAttributes &&
                        parsedAttributes?.trade_screen_configuration
                        ? JSON.parse(
                              parsedAttributes?.trade_screen_configuration
                          )
                        : initialTradeState.layoutConfigurationOptions;
                } catch (err) {
                    console.log(err);

                    return initialTradeState.layoutConfigurationOptions;
                }
            };

            const defaultFirmLayout = parseJsonFromConfig();

            const savedLayout =
                action.newConfig?.attr?.trade_preferences
                    ?.trade_screen_configuration || defaultFirmLayout;

            const ordersTablesWithoutTabs =
                savedLayout?.ordersTableTabs &&
                savedLayout.layout?.filter(
                    (item) =>
                        item.i.startsWith("orders-table") &&
                        (savedLayout.ordersTableTabs[item.i] === undefined ||
                            savedLayout.ordersTableTabs[item.i].length === 0)
                );
            const mappedOrdersTablesWithoutTabs = ordersTablesWithoutTabs
                ? Object.assign(
                      {},
                      ...ordersTablesWithoutTabs.flatMap((item) => ({
                          [item.i]: [
                              "orders",
                              "cancelled",
                              "fills",
                              "negotiate",
                          ],
                      }))
                  )
                : {
                      "orders-table-0": [
                          "orders",
                          "cancelled",
                          "fills",
                          "negotiate",
                      ],
                  };

            const booksTablesWithoutTabs =
                savedLayout?.booksTableTabs &&
                savedLayout.layout?.filter(
                    (item) =>
                        item.i.startsWith("books-table") &&
                        (savedLayout.booksTableTabs[item.i] === undefined ||
                            savedLayout.booksTableTabs[item.i].length === 0)
                );
            const mappedBooksTablesWithoutTabs = booksTablesWithoutTabs
                ? Object.assign(
                      {},
                      ...booksTablesWithoutTabs.flatMap((item) => ({
                          [item.i]: ["books", "history", "qms"],
                      }))
                  )
                : {
                      "books-table-0": ["books", "history", "qms"],
                  };

            return {
                ...state,
                layoutConfigurationOptions: {
                    ...JSON.parse(
                        JSON.stringify(
                            initialTradeState.layoutConfigurationOptions
                        )
                    ),
                    // ...initialTradeState.layoutConfigurationOptions,
                    ...savedLayout,
                    ordersTableTabs: {
                        ...savedLayout?.ordersTableTabs,
                        ...mappedOrdersTablesWithoutTabs,
                    },
                    booksTableTabs: {
                        ...savedLayout?.booksTableTabs,
                        ...mappedBooksTablesWithoutTabs,
                    },
                },
                defaultLayoutConfigurationOptions: merge(
                    JSON.parse(
                        JSON.stringify(
                            initialTradeState.layoutConfigurationOptions
                        )
                    ),
                    defaultFirmLayout
                ),
            };
        }
        case CONSTANTS.ADD_AUCTION_INFORMATION: {
            return {
                ...state,
                auctionInformation:
                    state.auctionInformation.find(
                        (auction) =>
                            auction.security ===
                            action.auctionInformation.security
                    ) === undefined
                        ? state.auctionInformation.concat(
                              action.auctionInformation
                          )
                        : state.auctionInformation.map((auction) =>
                              auction.security ===
                              action.auctionInformation.security
                                  ? action.auctionInformation
                                  : auction
                          ),
            };
        }
        case CONSTANTS.ADD_PORTAL_AUCTION_INFORMATION: {
            return {
                ...state,
                portalAuctionInformation: action.auctionInformation,
            };
        }
        case CONSTANTS.CHANGE_SUBSCRIBED_BOOK_SYMBOL: {
            const updatedSymbols = state.previousSymbol
                ? state.subscribedBookSymbols.map((symbol) =>
                      symbol === action.previousSymbol
                          ? action.newSymbol
                          : symbol
                  )
                : state.subscribedBookSymbols.concat(action.newSymbol);

            return {
                ...state,
                subscribedBookSymbols: updatedSymbols,
            };
        }
        default:
            return state;
    }
};
