/* eslint-disable react/display-name */
import { useCallback, useMemo, useState } from "react";
import { memo, useEffect } from "react";
import { Col, Row } from "react-bootstrap";

import {
    formatKeyArray,
    getStatePreferences,
} from "../helpers/display-options";
import { formatTableColumn } from "../helpers/other";
import { useInterval } from "../hooks/useInterval";
import { useStore } from "../Store";
import { useSocket } from "../Websocket";
import { ConfigureDisplayButton } from "./ConfigureDisplayButton";
import { ConfigureSequenceButton } from "./ConfigureSequenceButton";
import { ConfigureStylingButton } from "./ConfigureStylingButton";

const ConfigureButtons = memo(
    ({
        ws,
        dispatch,
        keys,
        tableKeyOptions,
        keyType,
        optionKey,
        toggleLimitOff,
        enableNewTextFormatting,
        loggedInUser,
        minimumOptions,
    }) => {
        return (
            <>
                <ConfigureDisplayButton
                    ws={ws}
                    dispatch={dispatch}
                    user={loggedInUser?.data}
                    keys={keys}
                    preexisting={tableKeyOptions}
                    type={keyType}
                    optionKey={optionKey}
                    toggleLimitOff={toggleLimitOff}
                    enableNewTextFormatting={enableNewTextFormatting}
                    minimumOptions={minimumOptions}
                />
                <ConfigureStylingButton
                    ws={ws}
                    dispatch={dispatch}
                    user={loggedInUser?.data}
                    keys={keys}
                    type={keyType}
                    optionKey={optionKey}
                    enableNewTextFormatting={enableNewTextFormatting}
                />
                <ConfigureSequenceButton
                    ws={ws}
                    dispatch={dispatch}
                    user={loggedInUser?.data}
                    keys={keys}
                    preexisting={tableKeyOptions}
                    type={keyType}
                    optionKey={optionKey}
                    enableNewTextFormatting={enableNewTextFormatting}
                />
            </>
        );
    }
);

export const ConfigureDisplayWrapper = memo(
    /*
        render - Child table
        keyType - firm, trade, etc. that optionKey is stored under. ex: [keyType][optionKey]
        defaultOptions - default options to fallback to if user doesn't have any preferences set in their account.
        blacklistedAttr - blacklisted attributes such as objects, or data we just dont want to show.
        whitelistedAttr - Attributes that we want to show. DONT USE BOTH BLACKLIST AND WHITELIST.
        data - table data to get our keys from to display options in modal.
        returnButtons - returns the buttons for manual styling.
        overrideColumns - will stop from looping through to fetch existing columns, helpful for large datasets.
        customStyling - will apply styling to each column
        customFormatter - returns the formatter function and allows you to edit the default function.
        manualAdditionalKeys - keys that need to be able to be configured but may not always exist on the first object
        toggleLimitOff - Allows less than 3 keys to be selected.
        enableNewTextFormatting - Allows for the new formatting for keys in the configure display modal. 
        intervalBased - Allows for new keys to refresh based on a interval instead of changing data.
        minimumOptions - Minimum amount of options that must be selected.
        */
    ({
        render,
        keyType,
        optionKey,
        defaultOptions = [],
        blacklistedAttr = [],
        whiteListedAttr = [],
        data,
        returnButtons,
        overrideColumns = undefined,
        customStyling = undefined,
        customFormatter = undefined,
        manualAdditionalKeys = [],
        toggleLimitOff = false,
        sortSetting = true,
        tableOrigin = "",
        enableNewTextFormatting = false,
        intervalBased = false,
        minimumOptions = 3,
    }) => {
        const ws = useSocket();
        const { dispatch, state } = useStore();
        const loggedInUser = state.account.account;

        const styles =
            loggedInUser?.data?.attr?.[keyType]?.[optionKey]?.styles || [];

        const statePreferences = getStatePreferences(
            loggedInUser,
            keyType,
            optionKey
        );

        const filterOutBlacklist = useCallback(
            (opt) => opt.filter((x) => !blacklistedAttr.includes(x)),
            [blacklistedAttr]
        );

        /* Filter out blacklisted attributes to prevent errors */
        const tableKeyOptions = useMemo(
            () =>
                filterOutBlacklist(
                    statePreferences && statePreferences.length !== 0
                        ? statePreferences
                        : defaultOptions
                ),
            [defaultOptions, filterOutBlacklist, statePreferences]
        );

        const preferredColumns = useMemo(() => {
            if (tableKeyOptions.length > 0) {
                return tableKeyOptions.map((col) => {
                    let colStyle = {};

                    if (styles.length > 0) {
                        colStyle = styles.filter(
                            (style) => style.key === col
                        )[0];
                    }

                    return formatTableColumn(
                        col,
                        colStyle,
                        customStyling,
                        customFormatter,
                        sortSetting,
                        tableOrigin
                    );
                });
            }
            return [];
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [tableKeyOptions]);

        const [keys, setKeys] = useState(overrideColumns || []);
        const updateKeys = useCallback(
            () =>
                // Create keys to show options in the modal.
                setKeys(
                    data.length > 0 && !overrideColumns
                        ? formatKeyArray(
                              blacklistedAttr,
                              whiteListedAttr,
                              data,
                              manualAdditionalKeys
                          )
                        : overrideColumns || []
                ),
            [
                blacklistedAttr,
                data,
                manualAdditionalKeys,
                overrideColumns,
                whiteListedAttr,
            ]
        );
        useEffect(() => {
            if (!intervalBased) {
                updateKeys();
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [data, intervalBased]);

        useInterval(
            () => {
                if (intervalBased) {
                    updateKeys();
                }
            },
            intervalBased ? 1000 : null
        );

        return (
            <>
                {!returnButtons && keys.length > 0 && (
                    <Row noGutters>
                        <Col className="text-right pb-2">
                            <ConfigureButtons
                                ws={ws}
                                dispatch={dispatch}
                                keys={keys}
                                tableKeyOptions={tableKeyOptions}
                                keyType={keyType}
                                optionKey={optionKey}
                                toggleLimitOff={toggleLimitOff}
                                enableNewTextFormatting={
                                    enableNewTextFormatting
                                }
                                loggedInUser={loggedInUser}
                            />
                        </Col>
                    </Row>
                )}

                {render(
                    preferredColumns,
                    returnButtons ? (
                        <ConfigureButtons
                            ws={ws}
                            dispatch={dispatch}
                            keys={keys}
                            tableKeyOptions={tableKeyOptions}
                            keyType={keyType}
                            optionKey={optionKey}
                            toggleLimitOff={toggleLimitOff}
                            enableNewTextFormatting={enableNewTextFormatting}
                            loggedInUser={loggedInUser}
                            minimumOptions={minimumOptions}
                        />
                    ) : (
                        <></>
                    )
                )}
            </>
        );
    }
);
