import { cloneDeep } from "lodash";
import { Col, Row } from "react-bootstrap";

import { InputTemplate } from "./InputTemplate";

// platform

const socialOptions = [
    "Website",
    "Facebook",
    "Twitter",
    "LinkedIn",
    "Telegram",
    "Medium",
];

export const SocialInput = (props) => {
    const { field, onChange, setPlatform, classes, disabled } = props;
    const { required } = field;

    const otherProps = cloneDeep(props);
    delete otherProps.onChange;
    delete otherProps.field;
    delete otherProps.socialOptions;
    delete otherProps.setPlatform;

    return (
        <Row className={classes} noGutters>
            <Col style={{ position: "relative" }}>
                <select
                    {...otherProps}
                    defaultValue={field?.platform}
                    className="custom-select custom-select-sm"
                    onChange={({ target: { value } }) => setPlatform(value)}
                    name="platform"
                >
                    <option value="">Select an platform...</option>
                    {socialOptions.map((social, i) => (
                        <option value={social} key={i}>
                            {social}
                        </option>
                    ))}
                </select>
                {required && <span className="required-icon">*</span>}
            </Col>
            <Col>
                <InputTemplate
                    field={field}
                    placeholder="Link URL..."
                    disabled={disabled}
                    onChange={onChange}
                />
            </Col>
        </Row>
    );
};
