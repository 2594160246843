/* eslint-disable react/display-name */
import { memo } from "react";
import { Button, Modal } from "react-bootstrap";

export const DeleteSymbolModal = memo(
    ({ show = false, handleCloseModal, handleDelete, security }) => {
        return (
            <Modal show={show} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Deny Request</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete {security}?
                    <br />
                    <br />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleDelete}>
                        Delete
                    </Button>
                    <Button variant="light" onClick={handleCloseModal}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
);
