import * as CONSTANTS from "../constants/account";

export const authRequest = () => ({
    type: CONSTANTS.AUTH_REQUEST,
});

export const authSuccess = (data) => ({
    type: CONSTANTS.AUTH_SUCCESS,
    data,
});

export const authError = (error, account) => ({
    type: CONSTANTS.AUTH_ERROR,
    error,
    account,
});

export const addPublicKey = (publicKey) => ({
    type: CONSTANTS.ADD_PUBLIC_KEY,
    publicKey,
});

export const addWebsocketData = (wsdata) => ({
    type: CONSTANTS.ADD_WS_DATA,
    wsdata,
});

export const updateWebsocket2FaData = (use2fa) => ({
    type: CONSTANTS.UPDATE_WS_2FA_DATA,
    use2fa,
});

export const parseRoles = (roles) => ({
    type: CONSTANTS.PARSE_ROLES,
    roles,
});

export const updateSystemPreferences = (preferences) => ({
    type: CONSTANTS.UPDATE_SYSTEM_PREFERENCES,
    preferences,
});

export const getRoleList = (roles) => ({
    type: CONSTANTS.GET_ROLE_LIST,
    roles,
});

export const setFontURL = (url) => ({
    type: CONSTANTS.SET_FONT_URL,
    url,
});

export const clearState = () => ({
    type: CONSTANTS.CLEAR_STATE,
});
