import moment from "moment";
import { Fragment, useEffect, useMemo, useState } from "react";
import { Button, Col, Row, Tab, Table, Tabs } from "react-bootstrap";
import {
    FaFacebookF,
    FaLinkedin,
    FaMedium,
    FaTelegram,
    FaTwitter,
} from "react-icons/fa";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Select from "react-select";

import { AnnouncementTable } from "../../components/assets/AnnouncementTable";
import { FullScreenLoading } from "../../components/Loading";
import { TableRenderer } from "../../components/portal/TableRenderer";
import { LastSaleChart } from "../../components/portal/trade";
import {
    assetProfilePageTradeHistoryLink,
    clientDateFormat,
    clientName,
    enableNewMarketWatch,
    enableUserAnnouncement,
    hideHaltedSymbols,
    hideTradeScreenWhenInactive,
    isMarkerAsMarketMaker,
    marketCapCurrencyEnabled,
    showAnnouncments,
    showMPIDWithSameFirm,
} from "../../config";
import * as ACTIONS from "../../contexts/actions/trade";
import {
    createAnnouncementTemplate,
    deleteAnnouncementTemplate,
    getAnnouncementTemplates,
} from "../../helpers/announcement-builder";
import {
    getPublishedAssetProfiles,
    getSymbolLastPrice,
} from "../../helpers/asset-builder";
import {
    addCommas,
    correctFieldType,
    formatNumber,
    formatTableColumn,
    getZeroes,
} from "../../helpers/other";
import {
    getLsHistory,
    getLsHistoryWithoutDispatch,
    mergelsHistoryAnditradeLsHistory,
    removeBrokenTrades,
    subscribeCurrentSymbol,
    subscribeSymbols,
} from "../../helpers/trade";
import { validateURL } from "../../helpers/validate";
import { useStore } from "../../Store";
import { useSubStore } from "../../SubscriptionData"; // Import your DataContext
import { useSocket } from "../../Websocket";

const AssetProfilePage = () => {
    const ws = useSocket();
    const { state, dispatch } = useStore();
    const {
        asset_builder: { publicProfiles: assets },
        announcement_builder: { templates },
        account: { account: loggedInUser },
        trade: {
            // symbols,
            lsHistory,
            // itradeLsHistory,
            // books,
            currentSymbol,
            subscribedSecurity,
            symbolsSubscribed,
            layoutConfigurationOptions,
        },
    } = state;

    const subscriptionContext = useSubStore();
    const { refChangeEmitter } = subscriptionContext;

    const isUserActive = state.account.account.data.active === "Y";
    const symbolWatchList =
        loggedInUser?.data?.attr?.["trade_preferences"]?.["market_watch"]
            ?.watch_list;
    const isIssersSymbol =
        currentSymbol?.attr?.issuerFirm === state.account.account.data.firm;

    const [searchParams] = useSearchParams();
    const defaultTab = searchParams.get("tab");

    const navigate = useNavigate();
    const [tradeSymbol, setTradeSymbol] = useState();

    const { symbol } = useParams();
    const [loading, setLoading] = useState(true);
    const [asset, setAsset] = useState();
    const [symbolLastPrice, setSymbolLastPrice] = useState(undefined);
    const [filteredTemplates, setFilteredTemplates] = useState();

    const [dailyRange, setDailyRange] = useState("-");
    const [volume, setVolume] = useState("-");
    const [avg30DayVolume, setAvg30DayVolume] = useState("-");

    const [last10Trades, setLast10Trades] = useState([]);
    const [recentTradesFromAPI, setRecentTradesFromAPI] = useState([]);
    const [fullLsHistory, setFullLsHistory] = useState([]);

    const [books, setBooks] = useState(
        subscriptionContext.bookDataRef.current?.[symbol] || []
    );
    const [itradeLsHistory, setitradeLsHistory] = useState(
        subscriptionContext.tradeDataRef.current
    );

    const [symbols, setSymbols] = useState(
        subscriptionContext.symbolsDataRef.current.sort((a, b) => {
            if (a.security > b.security) return 1;
            if (a.security < b.security) return -1;
            return 0;
        })
    );

    // lshistory is from the query only
    const [filteredLsHistory, setFilteredLsHistory] = useState(
        lsHistory.filter((hist) => hist.security === currentSymbol?.security)
    );

    useEffect(() => {
        // Function to handle updates when the ref changes
        const onBookChange = (updatedData) => {
            setBooks(updatedData?.[symbol] || []); // Trigger a re-render with the updated data
        };
        const onTradeChange = (updatedData) => {
            setitradeLsHistory(updatedData); // Trigger a re-render with the updated data
        };

        const onSymbolChange = (updatedData) => {
            setSymbols(
                updatedData.sort((a, b) => {
                    if (a.security > b.security) return 1;
                    if (a.security < b.security) return -1;
                    return 0;
                })
            );
        };

        refChangeEmitter.on("bookChange", onBookChange);
        refChangeEmitter.on("tradeChange", onTradeChange);
        refChangeEmitter.on("symbolChange", onSymbolChange);

        // Cleanup the event listener when the component unmounts
        return () => {
            refChangeEmitter.removeListener("bookChange", onBookChange);
            refChangeEmitter.removeListener("tradeChange", onTradeChange);
            refChangeEmitter.removeListener("symbolChange", onSymbolChange);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refChangeEmitter]);

    const deleteAnnouncementTemplateHandler = ({ _id }) => {
        const templateToDelete = templates.find(
            (template) => template._id === _id
        );

        if (
            templateToDelete &&
            templateToDelete.published === false &&
            templateToDelete.userid === state.account.account.data.userid
        ) {
            deleteAnnouncementTemplate(dispatch, _id);
        }
    };

    useEffect(() => {
        if (fullLsHistory.length > 0) {
            // Get last 10 trades
            setLast10Trades(fullLsHistory.slice(-10).reverse());

            let currentVolume = 0;
            let thirtyDayVolume = 0;
            const beginingOfToday = new Date().setHours(0, 0, 0, 0);
            const prices = [];

            fullLsHistory.forEach((element) => {
                if (parseInt(element.time) >= beginingOfToday) {
                    prices.push(element.execprice);
                    currentVolume += parseFloat(element.execqty);
                }
                thirtyDayVolume += parseFloat(element.execqty);
            });

            const fixedLength =
                ((currentSymbol?.priceprecision || 10000) + "").length - 1;

            setVolume(Math.round(currentVolume));

            setAvg30DayVolume(Math.round(thirtyDayVolume / 30));

            if (prices.length > 0) {
                const lowestPrice = Math.min(...prices).toFixed(fixedLength);
                const highestPrice = Math.max(...prices).toFixed(fixedLength);
                setDailyRange(`${lowestPrice} - ${highestPrice}`);
            } else setDailyRange("-");
        } else {
            setVolume("-");
            setAvg30DayVolume("-");
            setDailyRange("-");
        }

        if (
            fullLsHistory.length === 0 ||
            (fullLsHistory.length < 10 &&
                fullLsHistory.length < recentTradesFromAPI.length)
        ) {
            setLast10Trades(
                mergelsHistoryAnditradeLsHistory(
                    itradeLsHistory[subscribedSecurity]
                        ? itradeLsHistory[subscribedSecurity].slice().reverse()
                        : [],
                    recentTradesFromAPI
                ).slice(0, 10)
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fullLsHistory, currentSymbol, recentTradesFromAPI]);

    useEffect(() => {
        setFilteredLsHistory(
            lsHistory.filter(
                (hist) => hist.security === currentSymbol?.security
            )
        );
    }, [lsHistory, currentSymbol]);

    useEffect(() => {
        setFullLsHistory(
            mergelsHistoryAnditradeLsHistory(
                filteredLsHistory,
                itradeLsHistory[subscribedSecurity]
                    ? itradeLsHistory[subscribedSecurity]
                    : []
            )
        );
    }, [filteredLsHistory, itradeLsHistory, subscribedSecurity]);

    useEffect(() => {
        showAnnouncments &&
            setFilteredTemplates(
                templates?.filter(
                    (template) =>
                        template.securities.includes(symbol) &&
                        (template.published || isIssersSymbol)
                )
            );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [templates, isIssersSymbol]);

    useEffect(() => {
        if (symbols.length > 0) {
            setTradeSymbol(symbols.find((symb) => symb.pair_first === symbol));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [symbols]);

    const getDefaultTabFromSearchParams = () =>
        ["information", "market", "announcements"].includes(defaultTab)
            ? defaultTab
            : marketCapCurrencyEnabled
            ? "market"
            : showAnnouncments
            ? "announcements"
            : "information";

    const isFieldEmpty = (field) =>
        correctFieldType(field.fieldType) === "Subsection"
            ? field.subfields.length === 0
            : !field?.value || field?.value.length === 0;

    const removeEndingZeroes = (numberString) =>
        numberString.endsWith(".00") ? numberString.slice(0, -3) : numberString;

    const renderFieldPreview = (field, sectionType = "List") => {
        switch (correctFieldType(field.fieldType)) {
            case "Date":
                return moment(field.value).format(
                    clientDateFormat.toUpperCase()
                );
            case "Link":
                return (
                    <div>
                        <a href={`${field.value}`}>{field.displayText}</a>
                    </div>
                );
            case "Social":
                return (
                    <div>
                        <a href={`${field.value}`}>
                            {field.platform === "Facebook" ? (
                                <FaFacebookF />
                            ) : field.platform === "Twitter" ? (
                                <FaTwitter />
                            ) : field.platform === "LinkedIn" ? (
                                <FaLinkedin />
                            ) : field.platform === "Telegram" ? (
                                <FaTelegram />
                            ) : field.platform === "Medium" ? (
                                <FaMedium />
                            ) : (
                                field.displayText
                            )}
                        </a>
                    </div>
                );
            case "File Upload":
            case "Image Upload":
                return (
                    <>
                        <a
                            href={`${field.value}`}
                            target="_blank"
                            rel="noreferrer"
                            className="mr-1 mt-1"
                            style={{
                                top: "-1.75rem",
                                right: 0,
                                position: "absolute",
                            }}
                        >
                            Open File
                        </a>
                        <embed
                            src={`${field.value}`}
                            height="300px"
                            width="100%"
                            style={{
                                objectFit: "contain",
                            }}
                        />
                    </>
                );
            case "Subsection":
                return field.subfields.map(
                    (subfield, i) =>
                        !isFieldEmpty(subfield) && (
                            <Fragment key={i}>
                                {sectionType === "List" &&
                                subfield.fieldType !== "Table" ? (
                                    <Row
                                        noGutters
                                        className="py-1 ml-3"
                                        style={{ position: "relative" }}
                                    >
                                        <Col xs={12}>
                                            <b>{subfield.fieldName}</b>
                                        </Col>
                                        <Col xs={12}>
                                            {renderFieldPreview(subfield)}
                                        </Col>
                                    </Row>
                                ) : (
                                    <Col
                                        style={{
                                            flex: "1 0 45%",
                                        }}
                                        className="pt-2 pb-4 ml-3"
                                    >
                                        <b>{subfield.fieldName}</b>
                                        <Row
                                            noGutters
                                            style={{ position: "relative" }}
                                        >
                                            {renderFieldPreview(
                                                subfield,
                                                "tile"
                                            )}
                                        </Row>
                                    </Col>
                                )}
                                {field.subfields.length - 1 !== i &&
                                    sectionType === "List" &&
                                    subfield.fieldType !== "Table" && <hr />}
                            </Fragment>
                        )
                );
            case "Table":
                return (
                    <Table response="sm" striped>
                        <thead>
                            <tr>
                                {field.headers.map((header, i) => (
                                    <th key={i} className="size-sm">
                                        {header}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {field.value.map((row, i) => (
                                <tr key={i}>
                                    {row.map((cell, j) => (
                                        <td key={j}>
                                            {validateURL(cell) ? (
                                                <a href={cell}>{cell}</a>
                                            ) : (
                                                cell
                                            )}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                );
            default:
                return field.value;
        }
    };

    const ListRender = ({ section }) => (
        <>
            {useMemo(
                () =>
                    section.fields.map(
                        (field, i) =>
                            !isFieldEmpty(field) && (
                                <Fragment key={i}>
                                    {field.fieldType !== "Subsection" &&
                                    field.fieldType !== "Table" ? (
                                        <Row
                                            noGutters
                                            className="py-1 mx-2 custom-text-break"
                                            style={{
                                                position: "relative",
                                                overflowX:
                                                    correctFieldType(
                                                        field.fieldType
                                                    ) === "Table"
                                                        ? "scroll"
                                                        : "visible",
                                            }}
                                        >
                                            <Col>
                                                <b>{field?.fieldName}</b>
                                            </Col>
                                            <Col>
                                                {renderFieldPreview(field)}
                                            </Col>
                                        </Row>
                                    ) : (
                                        <>
                                            <Row
                                                noGutters
                                                className="pt-1 pb-3 mx-2 custom-text-break"
                                                style={{ position: "relative" }}
                                            >
                                                <Col>
                                                    <b>{field?.fieldName}</b>
                                                </Col>
                                            </Row>
                                            {renderFieldPreview(field)}
                                        </>
                                    )}
                                    <hr />
                                </Fragment>
                            )
                    ),
                [section]
            )}
        </>
    );

    const TileRender = ({ section }) => (
        <Row noGutters className="pb-4 custom-text-break">
            {useMemo(
                () =>
                    section.fields.map(
                        (field, i) =>
                            !isFieldEmpty(field) && (
                                <Fragment key={i}>
                                    {correctFieldType(field.fieldType) !==
                                    "Subsection" ? (
                                        <div
                                            className="mb-4 mx-2"
                                            style={{
                                                flex: "1 0 45%",
                                                position: "relative",
                                                overflowX:
                                                    correctFieldType(
                                                        field.fieldType
                                                    ) === "Table"
                                                        ? "scroll"
                                                        : "visible",
                                            }}
                                        >
                                            <div className="pb-2">
                                                <b>{field?.fieldName}</b>
                                            </div>

                                            <Row noGutters>
                                                {renderFieldPreview(
                                                    field,
                                                    "tile"
                                                )}
                                            </Row>
                                        </div>
                                    ) : (
                                        <Col xs={12} className="mx-2">
                                            <div className="pb-2">
                                                <b>{field?.fieldName}</b>
                                            </div>
                                            <Row
                                                noGutters
                                                className="justify-content-center"
                                                style={{ position: "relative" }}
                                            >
                                                {renderFieldPreview(
                                                    field,
                                                    "tile"
                                                )}
                                            </Row>
                                        </Col>
                                    )}
                                    <hr />
                                </Fragment>
                            )
                    ),
                [section]
            )}
        </Row>
    );

    useEffect(() => {
        setAsset(assets.find((ast) => ast.symbol === symbol));
    }, [symbol, assets]);

    useEffect(() => {
        getPublishedAssetProfiles(dispatch).finally(() => setLoading(false));

        if (!templates && showAnnouncments) {
            getAnnouncementTemplates(dispatch).finally(() => setLoading(false));
        }

        marketCapCurrencyEnabled &&
            getSymbolLastPrice(symbol).then((res) =>
                setSymbolLastPrice(res?.price || res?.prevcloseprice)
            );

        return () => setLoading(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (symbols.length > 0 && marketCapCurrencyEnabled)
            dispatch(
                ACTIONS.setCurrentSymbol(
                    symbols.find((symb) => symb.security === symbol)
                )
            );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [symbols]);

    const postSubDataCall = () => {
        const today = new Date();

        let thirtyDaysAgo = new Date(new Date().setDate(today.getDate() - 30));

        getLsHistory(ws, dispatch, {
            security: currentSymbol?.security,
            starttime: thirtyDaysAgo.setHours(0, 0, 0, 0),
            endtime: new Date().getTime(),
        });

        if (currentSymbol && currentSymbol.security) {
            // January 1st, 2010
            let starttime = new Date(1262304000000);
            const aug30 = new Date(new Date().setHours(0, 0, 0, 0));
            aug30.setMonth(7);
            aug30.setDate(30);
            aug30.setFullYear(2023);
            // We only want data after Aug 30th for USX
            // We had a problem with clearing trade data
            if (clientName === "USX") starttime = aug30;

            getLsHistoryWithoutDispatch(ws, {
                security: currentSymbol?.security,
                starttime: starttime.setHours(0, 0, 0, 0),
                endtime: new Date().getTime(),
                lastfirst: true,
                maxreturn: 30,
            }).then((result) => {
                const recentTrades = Array.isArray(result) ? result : [];
                setRecentTradesFromAPI(
                    removeBrokenTrades(recentTrades).slice(0, 10)
                );
            });
        }
    };

    useEffect(() => {
        if (
            currentSymbol?.security &&
            currentSymbol?.security !== subscribedSecurity &&
            symbols.length > 0 &&
            marketCapCurrencyEnabled
        ) {
            // Start subscriptions and get history for our currently selected symbol.
            // Loading screen is disabled after all messages have been sent
            // Regardless of responses
            subscribeCurrentSymbol(
                ws,
                dispatch,
                currentSymbol?.security,
                subscribedSecurity,
                layoutConfigurationOptions?.useExternalBookFeed,
                subscriptionContext.resetContextBook,
                false
            ).then(async () => {
                !symbolsSubscribed
                    ? subscribeSymbols(
                          ws,
                          dispatch,
                          symbols.filter(
                              (symb) =>
                                  (hideHaltedSymbols
                                      ? !symb?.attr?.isHidden
                                      : symb) &&
                                  (enableNewMarketWatch
                                      ? symbolWatchList.includes(symb.security)
                                      : true)
                          ),
                          [], // unsub symbols
                          layoutConfigurationOptions?.useExternalBookFeed,
                          layoutConfigurationOptions?.useExternalTobFeed
                      )
                    : () => {};

                postSubDataCall();
            });
        } else if (
            currentSymbol?.security &&
            currentSymbol?.security === subscribedSecurity &&
            symbols.length > 0 &&
            marketCapCurrencyEnabled
        ) {
            postSubDataCall();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSymbol?.security]);

    const { zeroCount } = getZeroes(
        "price",
        symbols.find((e) => e.security === symbol)
    );

    const marketFieldRenderer = (label, value) => {
        return (
            <Col sm={4}>
                <Row style={{ width: "100%" }} className="market-field m-0">
                    <Col
                        sm={6}
                        className="d-flex align-items-center justify-content-center market-field-label"
                    >
                        {label}
                    </Col>
                    <Col
                        sm={6}
                        className="d-flex align-items-center justify-content-center market-field-value"
                    >
                        {value}
                    </Col>
                </Row>
            </Col>
        );
    };

    const assetSelectProps = {
        value: {
            value: symbol,
            label: symbol,
        },
        options: assets
            .filter((asset) =>
                hideHaltedSymbols
                    ? !symbols.find((symb) => symb.security === asset.symbol)
                          ?.attr?.isHidden
                    : asset
            )
            .map((asset) => ({ value: asset.symbol, label: asset.symbol }))
            .sort((a, b) => a.value.localeCompare(b.value)),
        menuPortalTarget: document.body,
        menuPosition: "fixed",
        onChange: (symb) => {
            if (symb.value !== symbol) navigate(`/assets/${symb.value}`);
        },
    };

    const selectRenderer = () => {
        return (
            <Row noGutters className="h-100">
                <Col
                    md={0}
                    className="d-flex justify-content-center flex-column"
                ></Col>
                <Select {...assetSelectProps} className="h-100 w-50 mb-2" />
            </Row>
        );
    };

    return (
        <div className="mt-4 mx-2 pt-4">
            {loading ? (
                <FullScreenLoading />
            ) : (
                <>
                    {selectRenderer()}
                    <div className="bg-white p-4">
                        <Row noGutters>
                            <Col xs={9}>
                                <h1 className=" text-dark">
                                    {asset?.name || ""}
                                </h1>

                                <p>{asset?.description || ""}</p>
                            </Col>
                            <Col xs={3} className="text-center">
                                <img
                                    className="m-2"
                                    src={asset?.image}
                                    alt=""
                                    width="130px"
                                    height="130px"
                                    style={{ objectFit: "contain" }}
                                />
                            </Col>
                        </Row>
                        {marketCapCurrencyEnabled && (
                            <>
                                <hr />
                                <Row noGutters>
                                    <Col
                                        sm={6}
                                        className="d-flex justify-content-between pr-4"
                                    >
                                        <b>Number Of Shares Issued:</b>{" "}
                                        <span>
                                            {asset?.numberOfShares
                                                ? addCommas(
                                                      asset.numberOfShares
                                                  )
                                                : "N\\A"}
                                        </span>
                                    </Col>
                                    <Col
                                        sm={6}
                                        className="d-flex justify-content-between"
                                    >
                                        <b>Last Financial Year EPS:</b>
                                        <span>
                                            {asset?.lastFinancialYearEPS
                                                ? formatNumber(
                                                      asset.lastFinancialYearEPS
                                                  )
                                                : "N\\A"}
                                        </span>
                                    </Col>
                                </Row>
                                <Row noGutters>
                                    <Col
                                        sm={6}
                                        className="d-flex justify-content-between pr-4"
                                    >
                                        <b>Market Capitalization:</b>{" "}
                                        <span>
                                            {asset?.numberOfShares &&
                                            symbolLastPrice
                                                ? addCommas(
                                                      parseInt(
                                                          parseFloat(
                                                              asset?.numberOfShares
                                                          ) *
                                                              parseFloat(
                                                                  symbolLastPrice
                                                              )
                                                      )
                                                  )
                                                : "N\\A"}
                                        </span>
                                    </Col>
                                    <Col
                                        sm={6}
                                        className="d-flex justify-content-between"
                                    >
                                        <b>P/E Ratio:</b>
                                        <span>
                                            {symbolLastPrice &&
                                            asset?.lastFinancialYearEPS
                                                ? formatNumber(
                                                      parseFloat(
                                                          symbolLastPrice
                                                      ) /
                                                          parseFloat(
                                                              asset.lastFinancialYearEPS
                                                          )
                                                  )
                                                : "N\\A"}
                                        </span>
                                    </Col>
                                </Row>
                                <Row noGutters>
                                    <Col
                                        sm={6}
                                        className="d-flex justify-content-between pr-4"
                                    >
                                        <b>Last Price:</b>
                                        <span>
                                            {symbolLastPrice
                                                ? formatNumber(
                                                      symbolLastPrice,
                                                      zeroCount
                                                  )
                                                : "N\\A"}{" "}
                                        </span>
                                    </Col>
                                    <Col
                                        sm={6}
                                        className="d-flex justify-content-between"
                                    >
                                        <b>
                                            Last 12 Months Gross Dividend Yield:
                                        </b>
                                        <span>
                                            {asset?.last12MonthsGrossDividend &&
                                            symbolLastPrice
                                                ? `${formatNumber(
                                                      parseFloat(
                                                          asset.last12MonthsGrossDividend
                                                      ) /
                                                          parseFloat(
                                                              symbolLastPrice
                                                          )
                                                  )}%`
                                                : "N\\A"}
                                        </span>
                                    </Col>
                                    <Col
                                        sm={6}
                                        className="d-flex justify-content-between pr-4"
                                    >
                                        <b>ISIN Number</b>
                                        <span>
                                            {asset?.isinNumber || "N\\A"}
                                        </span>
                                    </Col>
                                </Row>
                            </>
                        )}
                    </div>
                    <div
                        className="bg-white p-4 my-2 asset-profile-tabs"
                        style={{ position: "relative" }}
                    >
                        <Tabs
                            defaultActiveKey={getDefaultTabFromSearchParams()}
                        >
                            {marketCapCurrencyEnabled && (
                                <Tab
                                    eventKey="market"
                                    title="Market"
                                    className="pt-4"
                                >
                                    <Row noGutters>
                                        {marketFieldRenderer(
                                            "Last Price",
                                            symbolLastPrice
                                                ? formatNumber(
                                                      symbolLastPrice,
                                                      zeroCount
                                                  )
                                                : "-"
                                        )}
                                        {marketFieldRenderer(
                                            "Previous Close",
                                            symbols.find(
                                                (s) => s.security === symbol
                                            )?.prevcloseprice
                                                ? formatNumber(
                                                      symbols.find(
                                                          (s) =>
                                                              s.security ===
                                                              symbol
                                                      )?.prevcloseprice,
                                                      zeroCount
                                                  )
                                                : "-"
                                        )}
                                        {marketFieldRenderer(
                                            "Daily Range",
                                            dailyRange
                                        )}
                                    </Row>
                                    <Row noGutters>
                                        {marketFieldRenderer(
                                            "Volume",
                                            isNaN(volume)
                                                ? volume
                                                : removeEndingZeroes(
                                                      formatNumber(volume)
                                                  )
                                        )}
                                        {marketFieldRenderer(
                                            "30 Day Average Volume",
                                            isNaN(avg30DayVolume)
                                                ? avg30DayVolume
                                                : removeEndingZeroes(
                                                      formatNumber(
                                                          avg30DayVolume
                                                      )
                                                  )
                                        )}
                                    </Row>
                                    <Row noGutters className="pt-4">
                                        <Col sm={4}>
                                            <div className="m-2 market-field-table-wrapper">
                                                <div
                                                    className="market-field-table-label"
                                                    style={{
                                                        background: "green",
                                                    }}
                                                >
                                                    BIDS
                                                </div>
                                                <TableRenderer
                                                    keyField="key"
                                                    data={books?.filter(
                                                        (book) =>
                                                            !book?.ioi &&
                                                            book?.side === "B"
                                                    )}
                                                    noDataIndication="No Bids Available"
                                                    columns={[
                                                        formatTableColumn(
                                                            "qty"
                                                        ),
                                                        {
                                                            dataField: "price",
                                                            text: "Price",
                                                            formatter: (
                                                                price
                                                            ) =>
                                                                formatNumber(
                                                                    price,
                                                                    zeroCount
                                                                ),
                                                        },
                                                        {
                                                            dataField: "mpid",
                                                            text: "MPID",
                                                            formatter: (
                                                                mpid,
                                                                row
                                                            ) =>
                                                                isMarkerAsMarketMaker &&
                                                                row?.ismarker ===
                                                                    true
                                                                    ? "MM"
                                                                    : showMPIDWithSameFirm
                                                                    ? state
                                                                          .account
                                                                          ?.account
                                                                          ?.data
                                                                          ?.firm ===
                                                                      mpid
                                                                        ? mpid
                                                                        : ""
                                                                    : mpid,
                                                        },
                                                    ]}
                                                    bordered={false}
                                                    hover={false}
                                                    wrapperClasses="size-sm"
                                                />
                                            </div>
                                        </Col>
                                        <Col sm={4}>
                                            <div className="m-2 market-field-table-wrapper">
                                                <div
                                                    className="market-field-table-label"
                                                    style={{
                                                        background: "red",
                                                    }}
                                                >
                                                    OFFERS
                                                </div>
                                                <TableRenderer
                                                    keyField="key"
                                                    data={books
                                                        .filter(
                                                            (book) =>
                                                                !book?.ioi &&
                                                                book?.side ===
                                                                    "S"
                                                        )
                                                        .reverse()}
                                                    noDataIndication="No Offers Available"
                                                    columns={[
                                                        formatTableColumn(
                                                            "qty"
                                                        ),
                                                        {
                                                            dataField: "price",
                                                            text: "Price",
                                                            formatter: (
                                                                price
                                                            ) =>
                                                                formatNumber(
                                                                    price,
                                                                    zeroCount
                                                                ),
                                                        },
                                                        {
                                                            dataField: "mpid",
                                                            text: "MPID",
                                                            formatter: (
                                                                mpid,
                                                                row
                                                            ) =>
                                                                isMarkerAsMarketMaker &&
                                                                row?.ismarker ===
                                                                    true
                                                                    ? "MM"
                                                                    : showMPIDWithSameFirm
                                                                    ? state
                                                                          .account
                                                                          ?.account
                                                                          ?.data
                                                                          ?.firm ===
                                                                      mpid
                                                                        ? mpid
                                                                        : ""
                                                                    : mpid,
                                                        },
                                                    ]}
                                                    bordered={false}
                                                    hover={false}
                                                    wrapperClasses="size-sm"
                                                />
                                            </div>
                                        </Col>
                                        <Col sm={4}>
                                            <div className="m-2 market-field-table-wrapper">
                                                <div
                                                    className="market-field-table-label"
                                                    style={{
                                                        background: "grey",
                                                    }}
                                                >
                                                    TRADES
                                                </div>
                                                <TableRenderer
                                                    keyField="i"
                                                    data={last10Trades.map(
                                                        (trade, i) => ({
                                                            ...trade,
                                                            i,
                                                        })
                                                    )}
                                                    noDataIndication="No Trades Available"
                                                    columns={[
                                                        formatTableColumn(
                                                            "execqty"
                                                        ),
                                                        {
                                                            dataField:
                                                                "execprice",
                                                            text: "Price",
                                                            formatter: (
                                                                execprice
                                                            ) =>
                                                                formatNumber(
                                                                    execprice,
                                                                    zeroCount
                                                                ),
                                                        },
                                                        formatTableColumn(
                                                            "time"
                                                        ),
                                                    ]}
                                                    bordered={false}
                                                    hover={false}
                                                    wrapperClasses="size-sm"
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row
                                        noGutters
                                        className="pt-4 pl-2 pr-2"
                                        style={{ height: "500px" }}
                                    >
                                        <LastSaleChart title="Historical Pricing Chart" />
                                    </Row>
                                </Tab>
                            )}
                            <Tab
                                eventKey="information"
                                title="Information"
                                className="pt-4"
                            >
                                {asset?.sections.map((section, i) => (
                                    <Fragment key={i}>
                                        <Row noGutters className="mb-2">
                                            <h4>{section?.name}</h4>
                                        </Row>
                                        {section.display === "List" ? (
                                            <ListRender section={section} />
                                        ) : (
                                            <TileRender section={section} />
                                        )}
                                        {asset.sections.length - 1 !== i && (
                                            <hr />
                                        )}
                                    </Fragment>
                                ))}
                            </Tab>
                            {showAnnouncments && (
                                <Tab
                                    eventKey="announcements"
                                    title="Announcements"
                                    className="pt-4"
                                >
                                    {isIssersSymbol && enableUserAnnouncement && (
                                        <Row noGutters className="pb-2">
                                            <Col
                                                xs={12}
                                                md={{ span: 3, offset: 9 }}
                                                className="pb-2 d-flex justify-content-end align-items-end"
                                            >
                                                <Button
                                                    variant="primary"
                                                    onClick={async () => {
                                                        const templateRes =
                                                            await createAnnouncementTemplate(
                                                                dispatch,
                                                                currentSymbol?.security
                                                            );
                                                        navigate(
                                                            `/assets/announcement-templates/${templateRes[0]._id}`
                                                        );
                                                    }}
                                                >
                                                    Create Template
                                                </Button>
                                            </Col>
                                        </Row>
                                    )}
                                    <AnnouncementTable
                                        templates={filteredTemplates}
                                        loading={loading}
                                        isIssersSymbol={isIssersSymbol}
                                        deleteAnnouncementTemplate={
                                            deleteAnnouncementTemplateHandler
                                        }
                                        userData={state.account.account.data}
                                    />
                                </Tab>
                            )}
                            {assetProfilePageTradeHistoryLink && (
                                <Tab
                                    eventKey="tradeHistory"
                                    selected={false}
                                    title="TradeHistory"
                                    className="pt-4"
                                >
                                    <div>
                                        <a
                                            href={`${assetProfilePageTradeHistoryLink}?security=${subscribedSecurity}`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Click here for trades archives
                                        </a>
                                    </div>
                                </Tab>
                            )}
                        </Tabs>
                        {(hideTradeScreenWhenInactive
                            ? isUserActive
                            : true) && (
                            <Button
                                style={{
                                    position: "absolute",
                                    top: "1rem",
                                    right: "1rem",
                                }}
                                className="px-4"
                                onClick={() =>
                                    navigate(
                                        `/portal/trade/${
                                            tradeSymbol?.security || symbol
                                        }`
                                    )
                                }
                            >
                                Trade
                            </Button>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default AssetProfilePage;
