import * as CONSTANTS from "../constants/asset-builder";

export const initialAssetBuilderState = {
    templates: [],
    profiles: [],
    publicProfiles: [],
};

export const assetBuilderReducer = (state, action) => {
    switch (action.type) {
        case CONSTANTS.QUERY_ASSET_TEMPLATES:
            return {
                ...state,
                templates: action.templates,
            };
        case CONSTANTS.QUERY_ASSET_PROFILES:
            return {
                ...state,
                profiles: action.profiles,
            };
        case CONSTANTS.GET_PUBLIC_PROFILES:
            return {
                ...state,
                publicProfiles: action.profiles,
            };
        case CONSTANTS.CLEAR_STATE:
            return initialAssetBuilderState;
        default:
            return state;
    }
};
