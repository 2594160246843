/* eslint-disable no-unused-vars */
import { createChart, CrosshairMode } from "lightweight-charts";
import { useEffect, useMemo, useRef, useState } from "react";
import { Form } from "react-bootstrap";

import { convertStylingVar, getStylingHex } from "../../../helpers/other";
import { useStore } from "../../../Store";

export const TradeChart = ({ layoutKey, title = "Chart" }) => {
    const { state } = useStore();
    const { layoutConfigurationOptions, tradeHistory } = state.trade;

    const tradeChartContainerRef = useRef();
    const tradeChartRef = useRef();

    const fontColor = getStylingHex(
        layoutConfigurationOptions.styling.fontColor
    );

    const [chartType, setChartType] = useState("candle");
    const [series, setSeries] = useState();
    const [ohlc, setOhlc] = useState({
        open: tradeHistory.data[0]?.open,
        high: tradeHistory.data[0]?.high,
        close: tradeHistory.data[0]?.close,
        low: tradeHistory.data[0]?.low,
        value: tradeHistory.data[0]?.value,
    });

    const getChartType = () => {
        switch (chartType) {
            case "candle":
                return tradeChartRef.current.addCandlestickSeries();
            case "bar":
                return tradeChartRef.current.addBarSeries();
            case "line":
                return tradeChartRef.current.addLineSeries();
            case "area":
                return tradeChartRef.current.addAreaSeries();
            case "baseline":
                return tradeChartRef.current.addBaselineSeries();
            default:
                break;
        }
    };

    const chartRenderer = useMemo(
        () => (
            <div ref={tradeChartContainerRef} className="chart-container"></div>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [tradeHistory, chartType]
    );

    const ohlcRenderer = useMemo(
        () => (
            <div className="">
                <p>
                    <b>{tradeHistory.security}</b> O: {ohlc.open} H: {ohlc.high}{" "}
                    L: {ohlc.low} C: {ohlc.close}
                </p>
                {/* <p>Volume: {ohlc.value}</p> */}
            </div>
        ),
        [ohlc, tradeHistory]
    );

    useEffect(() => {
        // Render chart on mount.
        tradeChartRef.current = createChart(tradeChartContainerRef.current, {
            autoSize: true,
            layout: {
                backgroundColor: "rgba(0, 0, 0, 0.1)",
                textColor: fontColor,
            },
            grid: {
                vertLines: {
                    color: "rgba(255, 255, 255, 0.25)",
                },
                horzLines: {
                    color: "rgba(255, 255, 255, 0.25)",
                },
            },
            crosshair: {
                mode: CrosshairMode.Normal,
            },
            priceScale: {
                borderColor: fontColor,
            },
            timeScale: {
                borderColor: fontColor,
                timeVisible: true,
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (series) {
            tradeChartRef.current.removeSeries(series);
            setSeries(null);
        }

        if (tradeHistory.data.length > 0) {
            const data = [...tradeHistory.data].reverse();
            const currentChartType = getChartType();

            currentChartType.setData(data);

            setSeries(currentChartType);

            tradeChartRef.current.subscribeCrosshairMove((param) => {
                const ohlc = param.seriesPrices.get(currentChartType);
                if (ohlc) {
                    setOhlc({
                        open: ohlc.open,
                        high: ohlc.high,
                        low: ohlc.low,
                        close: ohlc.close,
                        value: ohlc.value,
                    });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tradeHistory, chartType]);

    const resizeObserver = useRef();
    useEffect(() => {
        resizeObserver.current = new ResizeObserver((entries) => {
            const { width, height } = entries[0].contentRect;
            tradeChartRef.current.applyOptions({ width, height });
            setTimeout(() => {
                tradeChartRef.current.timeScale().fitContent();
            }, 0);
        });

        resizeObserver.current.observe(tradeChartContainerRef.current);

        return () => resizeObserver.current.disconnect();
    }, []);

    return (
        <div
            style={{
                padding: layoutConfigurationOptions?.padding,
                overflow: "hidden",
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                background: convertStylingVar(
                    layoutConfigurationOptions.styling.backgroundColor
                ),
                color: fontColor,
                border: layoutConfigurationOptions?.componentBorders
                    ? `1px solid ${fontColor}`
                    : 0,
            }}
        >
            <div className="d-flex justify-content-between">
                <h6
                    style={{
                        color: convertStylingVar(
                            layoutConfigurationOptions.styling.headerFontColor
                        ),
                    }}
                >
                    <b>{title}</b>
                </h6>
                {tradeHistory.data.length > 0 && (
                    <Form.Control
                        size="sm"
                        as="select"
                        onChange={({ target: { value } }) =>
                            setChartType(value)
                        }
                        value={chartType}
                        className="series-select w-25"
                    >
                        <option value="candle">Candle</option>
                        <option value="bar">Bar</option>
                        <option value="line">Line</option>
                        <option value="area">Area</option>
                        <option value="baseline">Baseline</option>
                    </Form.Control>
                )}
            </div>
            {tradeHistory.data.length > 0 ? (
                ohlcRenderer
            ) : (
                <p>No History Found</p>
            )}
            {chartRenderer}
        </div>
    );
};
