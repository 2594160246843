import { Col, FormControl, Row } from "react-bootstrap";

export const AccountSelect = ({ options = [], account = "", setAccount }) => (
    <Row className="d-flex order-ticket-flex-item pb-2 account-field">
        <Col xs={12}>
            <FormControl
                value={account}
                size="sm"
                placeholder="Account"
                onChange={(e) => setAccount(e.target.value)}
                as="select"
            >
                <option value="">Account</option>
                {options.map((opt, i) => (
                    <option key={i} value={opt.account}>
                        {opt.account}
                    </option>
                ))}
            </FormControl>
        </Col>
    </Row>
);
