import { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { AiOutlineForm } from "react-icons/ai";
import { toast } from "react-toastify";

import { DeleteModal } from "../../../components/admin/firm/users";
import { EditUserModal } from "../../../components/admin/onboarding/EditUserModal";
import { TableRenderer } from "../../../components/portal/TableRenderer";
import { enableAdminCreateOnboardingUser } from "../../../config";
import {
    addUser,
    deleteUser,
    getUsersFromWebsocket,
} from "../../../helpers/firm";
import { createUser, getUserRoles } from "../../../helpers/onboarding";
import { formatTableColumn } from "../../../helpers/other";
import { useStore } from "../../../Store";
import { useSocket } from "../../../Websocket";

const ManageUsersPage = () => {
    const { state, dispatch } = useStore();
    const ws = useSocket();
    const [loading, setLoading] = useState(true);

    const {
        firm: { existing_users: users, firms },
        onboarding: { roles },
    } = state;

    const [filteredUsers, setFilteredUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState();

    const [showEditUserModal, setShowEditUserModal] = useState(false);
    const handleShowEditUserModal = (user) => {
        setSelectedUser(user);
        setShowEditUserModal(true);
    };
    const handleHideEditUserModal = () => {
        setShowEditUserModal(false);
        setSelectedUser(undefined);
    };

    const handleEditUser = (user) => {
        selectedUser
            ? addUser(ws, dispatch, state.account.publicKey, user, "edit").then(
                  () => {
                      handleHideEditUserModal();
                  }
              )
            : createUser(user.attr).then(() => {
                  handleHideEditUserModal();
                  setLoading(true);
                  getUsersFromWebsocket(ws, dispatch).finally(() =>
                      setLoading(false)
                  );
              });
    };

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const handleOpenDeleteModal = () => {
        setShowEditUserModal(false);
        setShowDeleteModal(true);
    };
    const handleHideDeleteModal = () => {
        setShowDeleteModal(false);
        setShowEditUserModal(true);
    };

    useEffect(() => {
        if (users.length > 0) {
            setFilteredUsers(
                users
                    .filter((usr) => usr?.attr?.status)
                    .map((usr) => {
                        // This is for OLD users who were migrated
                        let role = usr?.attr?.role ? usr.attr.role : "";
                        try {
                            role = JSON.parse(usr.attr.role);
                        } catch (err) {
                            // Do nothing
                        }
                        return {
                            ...usr,
                            attr: {
                                ...usr.attr,
                                role: role,
                            },
                        };
                    })
            );
        }
    }, [users]);

    useEffect(() => {
        if (roles.length === 0) {
            getUserRoles(ws, dispatch);
        }

        if (users.length === 0) {
            getUsersFromWebsocket(ws, dispatch).finally(() =>
                setLoading(false)
            );
        } else {
            setLoading(false);
        }

        return () => setLoading(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [filterEmail, setFilterEmail] = useState("");
    const [filterRole, setFilterRole] = useState("");
    const [filterStatus, setFilterStatus] = useState("");

    const [filterAppliedUsers, setFilterAppliedUsers] = useState(filteredUsers);

    const onFilter = () => {
        const filterByEmail =
            filterEmail.length > 0
                ? filteredUsers.filter((usr) => usr.attr.email === filterEmail)
                : filteredUsers;

        const filterByRole =
            filterRole.length > 0
                ? filterByEmail.filter(
                      (usr) => usr.attr?.role?.id === filterRole
                  )
                : filterByEmail;

        const filterByStatus =
            filterStatus.length > 0
                ? filterByRole.filter((usr) => usr.attr.status === filterStatus)
                : filterByRole;

        setFilterAppliedUsers(filterByStatus);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(onFilter, [filterEmail, filterRole, filterStatus]);
    useEffect(() => setFilterAppliedUsers(filteredUsers), [filteredUsers]);

    return (
        <div className="m-4">
            <Row noGutters className="forms-header justify-content-between">
                <Col>
                    <h4>Manage Users</h4>
                </Col>
                {enableAdminCreateOnboardingUser && (
                    <Col>
                        <Button
                            variant="outline-dark"
                            className="add-user-button"
                            onClick={() => {
                                handleShowEditUserModal();
                            }}
                            size="sm"
                        >
                            Add User
                        </Button>
                    </Col>
                )}
            </Row>

            <Row className="size-sm mb-2">
                <Col xs={4}>
                    <Form.Label className="size-sm">User:</Form.Label>
                    <Typeahead
                        id="user"
                        labelKey="userid"
                        onChange={(val) =>
                            setFilterEmail(val[0]?.attr?.email || "")
                        }
                        options={filteredUsers}
                        placeholder="All"
                        size="sm"
                        disabled={users.length === 0}
                    />
                </Col>
                <Col xs={4}>
                    <Form.Label className="size-sm">Role:</Form.Label>
                    <Form.Control
                        as="select"
                        size="sm"
                        disabled={roles.length === 0}
                        onChange={({ target: { value } }) =>
                            setFilterRole(value)
                        }
                    >
                        <option value="">Choose a role...</option>
                        {roles
                            .filter((role) => role?.isPublished)
                            .map((role, i) => (
                                <option key={i} value={role.id}>
                                    {role.name}
                                </option>
                            ))}
                    </Form.Control>
                </Col>
                <Col xs={4}>
                    <Form.Label className="size-sm">Status:</Form.Label>
                    <Form.Control
                        as="select"
                        size="sm"
                        onChange={({ target: { value } }) =>
                            setFilterStatus(value)
                        }
                    >
                        <option value="">Choose a status...</option>
                        <option value="approved">Approved</option>
                        <option value="rejected">Rejected</option>
                        <option value="open">Open</option>
                    </Form.Control>
                </Col>
            </Row>
            <br />
            {showEditUserModal && (
                <EditUserModal
                    show={showEditUserModal}
                    handleClose={handleHideEditUserModal}
                    user={selectedUser}
                    deleteUser={handleOpenDeleteModal}
                    handleSubmit={handleEditUser}
                    firms={firms}
                    roles={roles.filter((role) => role.isPublished)}
                />
            )}
            {selectedUser && (
                <DeleteModal
                    show={showDeleteModal}
                    handleDelete={() =>
                        deleteUser(ws, dispatch, selectedUser).then(() => {
                            setShowDeleteModal(false);
                            setShowEditUserModal(false);

                            setSelectedUser(undefined);

                            toast.success("User successfully deleted.");
                        })
                    }
                    handleCloseModal={handleHideDeleteModal}
                    user={selectedUser}
                />
            )}
            <TableRenderer
                data={filterAppliedUsers}
                wrapperClasses="size-sm"
                columns={[
                    ...["attr.email", "attr.role.name", "attr.status"].map(
                        (col) => formatTableColumn(col)
                    ),
                    {
                        dataField: "action",
                        isDummyField: true,
                        text: "",
                        formatter: (cell, rowContent) => (
                            <AiOutlineForm
                                className="cursor-pointer"
                                onClick={() =>
                                    handleShowEditUserModal(rowContent)
                                }
                            />
                        ),
                    },
                ]}
                loading={loading}
                keyField="userid"
                pagination
                striped
            />
        </div>
    );
};

export default ManageUsersPage;
