import { Col, Row } from "react-bootstrap";
import Select from "react-select";

import { SELECT_SMALL_SIZE_STYLE } from "../../../../helpers/other";

export const Broker = ({ orderTicket, tabKey, broker, setBroker, firms }) => {
    return (
        <Row className="d-flex pb-2 order-ticket-flex-item broker-field">
            <Col xs={4}>{orderTicket[tabKey]?.brokers?.displayName}:</Col>
            <Col xs={8}>
                <Select
                    defaultValue={{
                        value: broker,
                        label: broker,
                    }}
                    onChange={(e) => {
                        setBroker(e.value);
                    }}
                    options={firms.map((firm) => ({
                        value: firm.firm,
                        label: firm.firm,
                    }))}
                    styles={{
                        ...SELECT_SMALL_SIZE_STYLE,
                        menu: (styles) => ({
                            ...styles,
                            color: "black",
                        }),
                        menuPortal: (styles) => ({
                            ...styles,
                            zIndex: 9999,
                        }),
                    }}
                />
            </Col>
        </Row>
    );
};
