import * as CONSTANTS from "../constants/risk";

export const addCurrentRisk = (data) => ({
    type: CONSTANTS.ADD_CURRENT_RISK,
    data,
});

export const addRiskSetting = (data) => ({
    type: CONSTANTS.ADD_RISK_SETTING,
    data,
});

export const getRiskCases = (cases) => ({
    type: CONSTANTS.GET_RISK_CASES,
    cases,
});

export const clearState = () => ({
    type: CONSTANTS.CLEAR_STATE,
});
