import { useState } from "react";
import { Button, Col, FormControl, Row } from "react-bootstrap";

import {
    ConfirmSymbolStatusModal,
    SymbolStatusTable,
} from "../../../components/admin/market/symbol";
import { submitSymbolStatus } from "../../../helpers/market";
import { useStore } from "../../../Store";
import { useSocket } from "../../../Websocket";

const Symbols = () => {
    const { state } = useStore();
    const ws = useSocket();

    // This is halted symbols only, populated based on symbol status messages
    const {
        market: { symbolStatuses: marketSymbols },
    } = state;

    const [confirmSymbolStatusSymbol, setConfirmSymbolStatusSymbol] = useState(
        {}
    );
    const [statusTobe, setStatusTobe] = useState("");
    const [haltSymbolString, setHaltSymbolString] = useState("");
    const [unhaltSymbolString, setUnhaltSymbolString] = useState("");

    const [showSymbolStatusModal, setShowSymbolStatusModal] = useState(false);
    const handleCloseSymbolStatusModal = () => setShowSymbolStatusModal(false);
    const handleOpenSymbolStatusModal = (symbol, statusString) => {
        setStatusTobe(statusString);
        setConfirmSymbolStatusSymbol(symbol);
        setShowSymbolStatusModal(true);
    };

    const handleStatus = (status) => {
        submitSymbolStatus(ws, confirmSymbolStatusSymbol.security, status).then(
            () => {
                handleCloseSymbolStatusModal();
                if (status.charAt(0) === "H") setHaltSymbolString("");
                if (status.charAt(0) === "O") setUnhaltSymbolString("");
            }
        );
    };

    const onHaltSymbolChange = ({ target: { value: searchValue } }) => {
        setHaltSymbolString(searchValue.toUpperCase());
    };

    const onUnhaltSymbolChange = ({ target: { value: searchValue } }) => {
        setUnhaltSymbolString(searchValue.toUpperCase());
    };

    return (
        <div className="m-4">
            <Row noGutters className="forms-header">
                <h4>Symbol Statuses</h4>
            </Row>

            <div className="mb-2">
                <Row noGutters>
                    <Col xs={9} className="pr-4">
                        <FormControl
                            onChange={onHaltSymbolChange}
                            placeholder={"Halt Symbol..."}
                            value={haltSymbolString}
                        />
                    </Col>
                    <Col xs={3} className="pr-4">
                        <Button
                            size="sm"
                            onClick={() =>
                                handleOpenSymbolStatusModal(
                                    { security: haltSymbolString },
                                    "Halted"
                                )
                            }
                            disabled={!haltSymbolString}
                        >
                            Halt Symbol
                        </Button>
                    </Col>
                </Row>
            </div>

            <div className="mb-2">
                <Row noGutters>
                    <Col xs={9} className="pr-4">
                        <FormControl
                            onChange={onUnhaltSymbolChange}
                            placeholder={"Unhalt Symbol..."}
                            value={unhaltSymbolString}
                        />
                    </Col>
                    <Col xs={3} className="pr-4">
                        <Button
                            size="sm"
                            onClick={() =>
                                handleOpenSymbolStatusModal(
                                    { security: unhaltSymbolString },
                                    "Open"
                                )
                            }
                            disabled={!unhaltSymbolString}
                        >
                            Unhalt Symbol
                        </Button>
                    </Col>
                </Row>
            </div>

            <ConfirmSymbolStatusModal
                show={showSymbolStatusModal}
                handleCloseModal={handleCloseSymbolStatusModal}
                handleSubmit={handleStatus}
                security={confirmSymbolStatusSymbol?.security}
                statusString={statusTobe}
            />

            <SymbolStatusTable
                symbols={marketSymbols}
                showStatusPopup={handleOpenSymbolStatusModal}
            />
        </div>
    );
};

export default Symbols;
