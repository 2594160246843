import queryString from "query-string";

import { baseUrl, cookieName } from "./config";

function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
}

const request = async (
    endpoint,
    method = "GET",
    data = {},
    isToken = false,
    formData = false
) => {
    // eslint-disable-next-line no-undef
    return new Promise((resolve, reject) => {
        let qs = "",
            body;
        let headers = {
            Accept: "application/json",
        };

        if (["GET", "DELETE"].indexOf(method) > -1) {
            qs = `${queryString.stringify(data)}`;
        } else if (formData) {
            body = data;
        } else {
            body = queryString.stringify(data);
        }

        // If formData is false, change content type.
        if (!formData)
            headers["Content-Type"] = "application/x-www-form-urlencoded";

        // Load balancer logic
        // Load balanced server will not be able to access cookies
        if (cookieName && getCookie(cookieName)) {
            headers.Authorization = decodeURIComponent(getCookie(cookieName));
            // This header is used to route our load balancer
            headers.Location = getCookie("location");
        }

        // Build requestUrl and options
        const requestUrl = `${baseUrl}${endpoint}${qs}`;
        const options = {
            method,
            headers,
            credentials: "include",
            body,
        };

        if (isToken) {
            options.headers.Authorization = `Bearer ${localStorage.getItem(
                "accessToken"
            )}`;
        }

        fetch(requestUrl, options)
            .then(async (resp) => {
                const contentType = resp.headers.get("content-type");
                let formattedResp;

                if (contentType && contentType.includes("application/json")) {
                    formattedResp = await resp.json();
                    if (formattedResp.message === "error")
                        reject(formattedResp);
                } else {
                    formattedResp = await resp.text();
                }

                resolve(formattedResp);
            })
            .catch((err) => reject(err));
    });
};

export default request;
