import { Button, Modal } from "react-bootstrap";

export const ConfirmCopyProfileModal = ({
    show = false,
    handleCopy,
    handleClose,
}) => (
    <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
            <Modal.Title>Copy Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            Are you sure you want to copy this profile? <br />
            <br />
            <span className="text-danger">
                The following will not be copied:
            </span>
            <br />
            <br />
            <ul>
                <li>Profile Image(s)</li>
                <li>Profile Symbol</li>
                <li>Published Status</li>
            </ul>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="primary" onClick={handleCopy}>
                Copy
            </Button>
            <Button variant="light" onClick={handleClose}>
                Close
            </Button>
        </Modal.Footer>
    </Modal>
);
