/* eslint-disable react/display-name */
import "react-quill/dist/quill.snow.css";

import { memo } from "react";
import { Collapse, Form, Row } from "react-bootstrap";
import ReactQuill from "react-quill";

export const FieldDescriptionComponent = memo(({ data, setData, disabled }) => {
    return (
        <>
            <Row noGutters className="mt-2">
                <Form.Label
                    onClick={() =>
                        setData({
                            ...data,
                            showFieldDescription: !data.showFieldDescription,
                        })
                    }
                    className="size-sm cursor-pointer"
                >
                    Click here to show/hide the Field Description
                </Form.Label>
            </Row>
            <Collapse in={data.showFieldDescription}>
                <div className="size-sm">
                    <ReactQuill
                        readOnly={disabled}
                        value={data.fieldDescription}
                        onChange={(content, delta, source) =>
                            source === "user" &&
                            setData({ ...data, fieldDescription: content })
                        }
                        className="field-description-react-quill w-100"
                        style={{ height: "150px" }}
                        modules={{
                            toolbar: [["bold", "italic", "underline", "link"]],
                        }}
                        formats={["bold", "italic", "underline", "link"]}
                    />
                </div>
            </Collapse>
        </>
    );
});
