import { createContext, useContext, useMemo, useReducer } from "react";

import { initialUserState, userReducers } from "./contexts";

export const AppContext = createContext();

export const useStore = () => useContext(AppContext);

export const StoreProvider = ({ children }) => {
    const [state, dispatch] = useReducer(userReducers, initialUserState);

    const contextValue = useMemo(
        () => ({ state, dispatch }),
        [state, dispatch]
    );

    return (
        <AppContext.Provider value={contextValue}>
            {children}
        </AppContext.Provider>
    );
};
