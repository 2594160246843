import { debounce } from "lodash";
import { useCallback } from "react";
import { useEffect, useState } from "react";
import { Col, FormControl, Row, Tab, Tabs } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { AiOutlineArrowLeft, AiOutlineEdit } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";

import {
    AlertsInThisCaseTab,
    AttachmentsTab,
    CaseActivityList,
    InvestigateTab,
} from "../../../components/admin/risk";
import { FullScreenLoading } from "../../../components/Loading";
import { getUsersFromWebsocket } from "../../../helpers/firm";
import { formatResultString, formatTimestamp } from "../../../helpers/other";
import { getRiskCases, uploadRiskCase } from "../../../helpers/risk";
import { useStore } from "../../../Store";
import { useSocket } from "../../../Websocket";

const CaseBuilder = () => {
    const ws = useSocket();
    const { state, dispatch } = useStore();
    const [loading, setLoading] = useState(true);

    const { id: caseId } = useParams();

    const {
        firm: { existing_users: users },
        account: {
            account: { data: loggedInUser },
        },
        risk: { cases },
    } = state;

    const [riskCase, setRiskCase] = useState({});
    const [editedOnce, setEditedOnce] = useState(false);
    const [tradersMentioned, setTradersMentioned] = useState([]);

    const [notFound, setNotFound] = useState(false);
    const navigate = useNavigate();

    const [editNameVisibility, setEditNameVisibility] = useState(false);
    const [editDescriptionVisibility, setEditDescriptionVisibility] =
        useState(false);

    const handleOnChange = (
        { target: { name, value } },
        triggerActivityType
    ) => {
        const details = {
            ...riskCase.details,
            activity: ["status", "severity"].includes(triggerActivityType)
                ? riskCase.details.activity.concat([
                      {
                          date_created: new Date().getTime(),
                          action_by:
                              loggedInUser?.userid || loggedInUser?.userid,
                          action_type: `${triggerActivityType}_change`,
                          action_data:
                              triggerActivityType === "status"
                                  ? formatResultString(value)
                                  : triggerActivityType === "severity"
                                  ? formatResultString(value)
                                  : undefined,
                      },
                  ])
                : riskCase.details.activity,
        };

        setRiskCase({ ...riskCase, [name]: value, details });
    };

    useEffect(() => {
        if (Object.keys(riskCase).length > 0) {
            // eslint-disable-next-line no-undef
            const traders = new Set();

            riskCase.details.alerts.forEach((alrt) =>
                traders.add(alrt.account)
            );

            setTradersMentioned(Array.from(traders));
        }
    }, [riskCase]);

    useEffect(() => {
        getRiskCases(ws, dispatch);

        if (users.length === 0) {
            getUsersFromWebsocket(ws, dispatch).finally(() =>
                setLoading(false)
            );
        } else {
            setLoading(false);
        }

        return () => setLoading(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (cases.length > 0)
            setRiskCase(
                cases.find((riskCase) => riskCase.id === caseId) ??
                    setNotFound(true)
            );
    }, [caseId, cases]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => notFound && navigate("/admin/case-management"), [notFound]);

    const addNewActivity = (
        action_data,
        action_type,
        alert = false,
        attachment = false
    ) => {
        setRiskCase((prev) => ({
            ...prev,
            details: {
                ...prev.details,
                activity: prev.details.activity.concat([
                    {
                        date_created: new Date().getTime(),
                        action_by: loggedInUser?.userid || loggedInUser?.userid,
                        action_type,
                        action_data: alert
                            ? action_data.errordetails
                            : attachment
                            ? action_data.filename
                            : action_data,
                    },
                ]),
                alert_ids:
                    alert && action_type === "add_alert"
                        ? prev.details.alert_ids.concat([action_data.eventid])
                        : alert && action_type === "remove_alert"
                        ? prev.details.alert_ids.filter(
                              (alrt) => alrt !== action_data.eventid
                          )
                        : prev.details.alert_ids,
                alerts:
                    alert && action_type === "add_alert"
                        ? prev.details.alerts.concat([action_data])
                        : alert && action_type === "remove_alert"
                        ? prev.details.alerts.filter(
                              (alrt) => alrt.eventid !== action_data.eventid
                          )
                        : prev.details.alerts,
                attachments:
                    attachment && action_type === "add_attachment"
                        ? prev.details.attachments.concat([action_data])
                        : attachment && action_type === "remove_attachment"
                        ? prev.details.attachments.filter(
                              (attchmt, i) => i !== action_data
                          )
                        : prev.details.attachments,
            },
        }));

        setEditedOnce(true);
    };

    useEffect(() => {
        if (Object.keys(riskCase).length > 0 && !editedOnce) {
            setEditedOnce(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [riskCase.details]);

    useEffect(() => {
        if (Object.keys(riskCase).length > 0 && editedOnce) {
            uploadRiskCase(ws, dispatch, riskCase, "edit");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [riskCase]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedOnChange = useCallback(
        debounce(({ target: { name, value } }) =>
            setRiskCase({
                ...riskCase,
                details: {
                    ...riskCase.details,
                    [name]: value,
                },
            })
        ),
        [riskCase]
    );

    return (
        <div className="m-4">
            <Row noGutters className="forms-header">
                <AiOutlineArrowLeft
                    className="asset-back-button"
                    onClick={() => navigate("/admin/case-management")}
                />
                <h4>Case Management</h4>
            </Row>

            {loading ? (
                <FullScreenLoading />
            ) : (
                <div className="bg-white p-4 size-sm">
                    <Row className="mb-2">
                        <Col xs={3}>Name</Col>
                        <Col
                            xs={editNameVisibility ? 5 : 3}
                            className="d-flex justify-content-between"
                        >
                            {!editNameVisibility ? (
                                <div className="pb-2">
                                    {riskCase.details.name}
                                </div>
                            ) : (
                                <FormControl
                                    size="sm"
                                    value={riskCase.details.name}
                                    description="name"
                                    onChange={debouncedOnChange}
                                />
                            )}
                            <AiOutlineEdit
                                className="cursor-pointer mt-2 ml-2"
                                onClick={() =>
                                    setEditNameVisibility(!editNameVisibility)
                                }
                            />
                        </Col>
                    </Row>
                    <br />
                    <Row className="mb-2">
                        <Col xs={3}>Description</Col>
                        <Col
                            xs={editDescriptionVisibility ? 5 : 3}
                            className="d-flex justify-content-between"
                        >
                            {!editDescriptionVisibility ? (
                                <div className="pb-4">
                                    {riskCase.details.description}
                                </div>
                            ) : (
                                <FormControl
                                    size="sm"
                                    value={riskCase.details.description}
                                    as="textarea"
                                    rows="4"
                                    name="description"
                                    onChange={debouncedOnChange}
                                />
                            )}
                            <AiOutlineEdit
                                className="cursor-pointer  mt-2 ml-2"
                                onClick={() =>
                                    setEditDescriptionVisibility(
                                        !editDescriptionVisibility
                                    )
                                }
                            />
                        </Col>
                    </Row>
                    <br />
                    <Row className="mb-2">
                        <Col xs={3}>Date Created</Col>
                        <Col xs={3}>
                            {formatTimestamp(riskCase.details.date_created)}
                        </Col>
                    </Row>
                    <br />
                    <Row className="my-2">
                        <Col xs={4} className="d-flex justify-content-between ">
                            <div className="mr-3">Status:</div>
                            <FormControl
                                size="sm"
                                as="select"
                                name="status"
                                value={riskCase.status}
                                onChange={(e) => handleOnChange(e, "status")}
                            >
                                <option value="" disabled>
                                    Select an option...
                                </option>
                                <option value="open">Open</option>
                                <option value="investigating">
                                    Investigating
                                </option>
                                <option value="closed">Closed</option>
                                <option value="esclated">Esclated</option>
                            </FormControl>
                        </Col>
                        <Col xs={4} className="d-flex justify-content-between ">
                            <div className="mr-3">Severity:</div>
                            <FormControl
                                size="sm"
                                as="select"
                                name="severity"
                                value={riskCase.severity}
                                onChange={(e) => handleOnChange(e, "severity")}
                            >
                                <option value="" disabled>
                                    Select an option...
                                </option>
                                <option value="low">Low</option>
                                <option value="medium">Medium</option>
                                <option value="high">High</option>
                            </FormControl>
                        </Col>
                        <Col xs={4} className="d-flex justify-content-between ">
                            <div className="mr-3 text-nowrap">Assigned to:</div>
                            <Typeahead
                                className="w-100"
                                id="user"
                                labelKey="userid"
                                onChange={(e) =>
                                    setRiskCase({
                                        ...riskCase,
                                        assigned_to: e[0]?.userid,
                                    })
                                }
                                options={users}
                                defaultSelected={
                                    riskCase?.assigned_to &&
                                    users.filter(
                                        (usr) =>
                                            usr.userid === riskCase.assigned_to
                                    )
                                }
                                size="sm"
                            />
                        </Col>
                    </Row>
                    <div className="position-relative">
                        <Tabs defaultActiveKey="alerts" className="my-3">
                            <Tab eventKey="alerts" title="Alerts in this case">
                                <AlertsInThisCaseTab
                                    traders={users}
                                    addAlert={(alert) =>
                                        addNewActivity(alert, "add_alert", true)
                                    }
                                    removeAlert={(alert) =>
                                        addNewActivity(
                                            alert,
                                            "remove_alert",
                                            true
                                        )
                                    }
                                    caseAlerts={riskCase.details.alerts}
                                />
                            </Tab>
                            <Tab eventKey="activity" title="Activity">
                                <CaseActivityList
                                    activities={riskCase.details.activity}
                                    setActivity={(activity) =>
                                        setRiskCase({
                                            ...riskCase,
                                            details: {
                                                ...riskCase.details,
                                                activity,
                                            },
                                        })
                                    }
                                    addComment={(comment) =>
                                        addNewActivity(comment, "add_comment")
                                    }
                                />
                            </Tab>
                            <Tab eventKey="investigate" title="Investigate">
                                <InvestigateTab
                                    tradersMentioned={tradersMentioned}
                                />
                            </Tab>
                            <Tab eventKey="attachments" title="Attachments">
                                <AttachmentsTab
                                    attachments={riskCase.details.attachments}
                                    addAttachment={(attachment) =>
                                        addNewActivity(
                                            attachment,
                                            "add_attachment",
                                            false,
                                            true
                                        )
                                    }
                                    removeAttachment={(key) =>
                                        addNewActivity(
                                            key,
                                            "remove_attachment",
                                            false,
                                            true
                                        )
                                    }
                                />
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CaseBuilder;
