import { useState } from "react";
import { Col, Row, Tab, Tabs } from "react-bootstrap";

import {
    CreditsDebits,
    Fills,
    Orders,
} from "../../../components/admin/market/activity";
import { LoadFormSingle } from "../../../components/portal/LoadFormSingle";
import { BalanceTable } from "../../../components/portal/trade";
import { hideCreditsDebits, hideFillsTab, isQMSClient } from "../../../config";
import {
    getTradeDeposits,
    submitActivityBalance,
    submitActivityTrade,
    submitMultipleOrders,
} from "../../../helpers/trade";
import { useStore } from "../../../Store";
import { useSocket } from "../../../Websocket";

const Transactions = () => {
    const ws = useSocket();
    const { dispatch, state } = useStore();

    const { symbols } = state.market;
    const { orders, cancels, fills, balances, positions } =
        state.trade.transaction;
    const { account } = state.account;
    const [tab, setCurrentTab] = useState("orders");

    const getMultipleOrders = (symbol, fromtime, side) => {
        const options = {
            fromtime: fromtime ? fromtime : new Date(1262304000000),
            complianceid: account?.data.complianceid,
            security: symbol?.security,
            side: side,
        };

        state.market[tab] = [];

        if (["orders", "cancels"].includes(tab)) {
            submitMultipleOrders(ws, dispatch, tab, options);
        } else if (tab === "fills") {
            submitActivityTrade(ws, dispatch, options);
        } else if (tab === "credits/debits") {
            getTradeDeposits(ws, dispatch, options);
        } else if (tab === "balances") {
            submitActivityBalance(ws, dispatch, options);
        }
    };

    const LoadFormWrapper = () => {
        return <LoadFormSingle symbols={symbols} getData={getMultipleOrders} />;
    };

    return (
        <div className="m-4">
            <Row noGutters className="forms-header">
                <Col>
                    <h4>
                        {isQMSClient
                            ? "Review Indications of Interest"
                            : "Transactions"}
                    </h4>
                </Col>
            </Row>
            <Tabs
                className="mb-3"
                onSelect={(tab) => setCurrentTab(tab)}
                transition={false}
                unmountOnExit={true}
            >
                <Tab
                    eventKey="orders"
                    title={isQMSClient ? "IOIs" : "Orders"}
                    className=""
                >
                    {LoadFormWrapper()}
                    <Orders orders={orders} />
                </Tab>
                <Tab eventKey="cancels" title="Cancels" className="">
                    {LoadFormWrapper()}
                    <Orders orders={cancels} />
                </Tab>
                {!hideFillsTab && (
                    <Tab eventKey="fills" title="Fills" className="">
                        {LoadFormWrapper()}
                        <Fills fills={fills} isAdmin={false} />
                    </Tab>
                )}
                {!hideCreditsDebits && (
                    <Tab
                        eventKey="credits/debits"
                        title="Credits/Debits"
                        className=""
                    >
                        {LoadFormWrapper()}
                        <CreditsDebits balances={balances} />
                    </Tab>
                )}
                {!hideCreditsDebits && (
                    <Tab eventKey="balances" title="Balances" className="">
                        {LoadFormWrapper()}
                        <BalanceTable positions={positions} />
                    </Tab>
                )}
            </Tabs>
        </div>
    );
};

export default Transactions;
