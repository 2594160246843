/* eslint-disable react/display-name */

import { memo } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import {
    IoIosArrowDropdownCircle,
    IoIosArrowDropupCircle,
} from "react-icons/io";

import { Fields } from "../Fields";

const defaultFieldConfig = {
    fieldName: "",
    fieldType: "String",
    required: false,
};

export const Section = memo(
    ({
        section,
        setSection,
        deleteSection,
        disabled,
        index,
        length,
        setIndex,
    }) => {
        const moveUpDisabled = !disabled && index !== 0;
        const moveDownDisabled = !disabled && index !== length - 1;

        return (
            <div className="asset-section">
                <Row noGutters>
                    <Form.Label className="size-sm">Section Name</Form.Label>
                </Row>
                <Row noGutters>
                    <Col>
                        <Form.Control
                            placeholder="Section Name"
                            size="sm"
                            onChange={({ target: { value } }) =>
                                setSection({ ...section, name: value })
                            }
                            value={section.name}
                            required
                            disabled={disabled}
                        />
                    </Col>
                    <Col md={4} className="text-right">
                        <IoIosArrowDropupCircle
                            onClick={() =>
                                moveUpDisabled && setIndex(index, index - 1)
                            }
                            className={`${moveUpDisabled && "cursor-pointer"}`}
                            color={moveUpDisabled ? "grey" : "silver"}
                            style={{
                                height: "18px",
                                width: "18px",
                            }}
                        />
                        <IoIosArrowDropdownCircle
                            onClick={() =>
                                moveDownDisabled && setIndex(index, index + 1)
                            }
                            className={` ml-2 mr-3 ${
                                moveDownDisabled && "cursor-pointer"
                            }`}
                            color={moveDownDisabled ? "grey" : "silver"}
                            style={{
                                height: "18px",
                                width: "18px",
                            }}
                        />
                        <Button
                            size="sm"
                            onClick={() =>
                                setSection({
                                    ...section,
                                    display:
                                        section.display === "List"
                                            ? "Tile"
                                            : "List",
                                })
                            }
                            disabled={disabled}
                        >
                            {section.display}
                        </Button>
                        <Button
                            size="sm"
                            onClick={() => {
                                setSection({
                                    ...section,
                                    fields: section.fields.concat([
                                        defaultFieldConfig,
                                    ]),
                                });
                            }}
                            className="mx-1"
                            disabled={disabled}
                        >
                            Add Field
                        </Button>

                        <Button
                            size="sm"
                            variant="danger"
                            onClick={deleteSection}
                            disabled={disabled}
                        >
                            Delete Section
                        </Button>
                    </Col>
                </Row>
                {/* Fields */}
                {section.fields.length > 0 && (
                    <Fields
                        section={section}
                        setSection={setSection}
                        disabled={disabled}
                    />
                )}
            </div>
        );
    }
);
