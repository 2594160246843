import { useEffect } from "react";
import { Col, Container } from "react-bootstrap";
import { toast } from "react-toastify";

import { DimFullScreenLoading } from "../../components/Loading";
import { OnboardingSidebar } from "../../components/onboarding/OnboardingSidebar";
import { OnboardingTopbar } from "../../components/onboarding/OnboardingTopbar";
import { useStore } from "../../Store";

const OnboardingLayout = ({ children }) => {
    const { state } = useStore();

    const {
        other: { loading },
    } = state;

    useEffect(() => {
        toast.dismiss("incomplete-onboarding");
    }, []);

    return (
        <>
            {loading && <DimFullScreenLoading />}

            <Col className="flex-grow-0" style={{ zIndex: 1 }}>
                <OnboardingSidebar />
            </Col>
            <Col className="d-flex flex-column overflow-hidden">
                <OnboardingTopbar />
                <div className="content-pane">
                    <Container fluid>{children}</Container>
                </div>
            </Col>
        </>
    );
};

export default OnboardingLayout;
