/* eslint-disable react/display-name */
import { memo, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";

import { Sections } from "./Sections";

const defaultSectionConfig = {
    name: "",
    display: "List",
    fields: [],
};

export const CreateTemplateForm = memo(
    ({
        existingTemplate = undefined,
        submitData,
        type = "add",
        disableEditing,
    }) => {
        const [template, setTemplate] = useState({
            name: "",
            isPublished: false,
            sections: [],
            ...existingTemplate,
        });

        return (
            <>
                <Row noGutters>
                    <Form.Label className="size-sm">Asset Type</Form.Label>
                </Row>
                <Row noGutters>
                    <Col>
                        <Form.Control
                            name="name"
                            onChange={({ target: { value } }) =>
                                setTemplate({ ...template, name: value })
                            }
                            value={template.name}
                            size="sm"
                            disabled={template.isPublished}
                        />
                    </Col>
                    <Col className="text-right">
                        <Button
                            size="sm"
                            className="mx-1"
                            onClick={() =>
                                setTemplate({
                                    ...template,
                                    sections: template.sections.concat([
                                        defaultSectionConfig,
                                    ]),
                                })
                            }
                            disabled={
                                template.isPublished ||
                                template.sections.length === 10
                            }
                        >
                            Add Section
                        </Button>
                        <Button
                            size="sm"
                            onClick={() => {
                                // setTemplate({
                                //     ...template,
                                //     isPublished: !template.isPublished,
                                // });
                                submitData({
                                    ...template,
                                    isPublished: !template.isPublished,
                                });
                            }}
                            disabled={
                                template.sections.length === 0 ||
                                template.sections.length > 10 ||
                                disableEditing
                            }
                        >
                            {!template.isPublished ? "Publish" : "Unpublish"} &
                            Save
                        </Button>
                        <Button
                            size="sm"
                            className="ml-1"
                            onClick={() => submitData(template)}
                            disabled={
                                template.sections.length === 0 ||
                                template.sections.length > 10 ||
                                disableEditing
                            }
                        >
                            {type === "add" ? "Create" : "Save"} Template
                        </Button>
                    </Col>
                </Row>
                <hr />
                {template.sections.length > 0 && (
                    <Sections template={template} setTemplate={setTemplate} />
                )}
            </>
        );
    }
);
